import { FormHelperText } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  error?: boolean;
  children: React.ReactNode;
}

export const QcsFormHelperText: FC<Props> = ({
  className,
  error,
  children,
}) => {
  return (
    <FormHelperText className={className} error={error}>
      {children}
    </FormHelperText>
  );
};
