import { Form, FormikHelpers } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validations } from '../../utils/validations';
import { LoginContainer, LoginWrapper } from '../auth/LoginStyles';
import { SectionHeader } from '../common/SectionHeader';
import * as Yup from 'yup';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { SaveError } from '../common/SaveError';
import {
  VisitationInvitationResponseTypeEnum,
  VisitationPinDto,
} from '@qcs/safety-client';
import { visitationApi } from '../../utils/api';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { getCustomError, setErrorState } from '../../utils/error';
import { TourniquetDialog } from './TourniquetDialog';
import { tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { ErrorStateType } from '../../models/common';
import { TourniquetLanguageSelect } from './TourniquetLanguageSelect';

interface VisitationAddForm {
  pin: string;
}

export const TourniquetEnter: FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<ErrorStateType>();
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const [exitModalIsOpen, setExitModalIsOpen] = useState(false);

  const handleSubmit = async (
    data: VisitationAddForm,
    formikHelpers: FormikHelpers<VisitationAddForm>
  ) => {
    formikHelpers.resetForm();

    setError('');
    try {
      const data2: VisitationPinDto = {
        invitation: '',
        pin: data.pin,
      };
      const res = await visitationApi.readPin(identity.companyId, data2);

      //No active invitations.
      if ((res.data.data?.length ?? 0) === 0) {
        throw getCustomError('noInvitations');
      }

      const exitItem = res.data.data!.find(
        (x) => x.type === VisitationInvitationResponseTypeEnum.Exit
      );

      //Exit visitation, just call exit and show modal information.
      if (exitItem) {
        const data3: VisitationPinDto = {
          invitation: exitItem.invitation!.id!,
          pin: data.pin,
        };

        await visitationApi.visit(identity.companyId, data3);
        setExitModalIsOpen(true);
        return;
      }

      //Get first invitation for "invitationPerson" property.
      const data4: VisitationPinDto = {
        invitation: res.data.data![0].invitation!.id!,
        pin: data.pin,
      };

      const incompleteTrainingsRes = await visitationApi.incompleteTrainings(
        identity.companyId,
        data4
      );

      dispatch(tourniquetTrainingsActions.success(incompleteTrainingsRes.data));

      dispatch(
        tourniquetIdentityActions.success({
          pin: data.pin,
          allData: res.data.data!,
          employeeId: incompleteTrainingsRes.data.invitationPerson.employeeId,
        })
      );
    } catch (err) {
      setErrorState(err, setError);
    }
  };

  const handleModalClose = () => {
    setExitModalIsOpen(false);
  };

  return (
    <>
      <TourniquetLanguageSelect />
      <TourniquetDialog
        mode={
          exitModalIsOpen
            ? VisitationInvitationResponseTypeEnum.Exit
            : undefined
        }
        onClose={handleModalClose}
      />

      <LoginWrapper>
        <SectionHeader title="tourniquet.title" />
        <FormikDebounce<VisitationAddForm>
          initialValues={{
            pin: '',
          }}
          validationSchema={Yup.object().shape({
            pin: validations.stringRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <LoginContainer>
              <Input name="pin" label={t('tourniquet.pin')} required />
              <SubmitButton text="common.confirm" />
              <SaveError error={error} />
            </LoginContainer>
          </Form>
        </FormikDebounce>
      </LoginWrapper>
    </>
  );
};
