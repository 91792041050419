import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CompanySettingsDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { companySettingsApi } from '../../utils/api';

type State = BaseType<CompanySettingsDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    smtpPort: undefined,
    smtpHost: '',
    smtpUsername: '',
    smtpPassword: '',
  },
};

export const companySettingsSlice = baseSlice({
  name: 'companySettings',
  initialState,
});

export const selectCompanySettingsState = (state: RootState) =>
  state.companySettings.state;
export const selectCompanySettings = (state: RootState) =>
  state.companySettings.data;
export const companySettingsActions = companySettingsSlice.actions;

export const getCompanySettings =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companySettingsApi.getSettings(companyId);
    };

    getItem({
      dispatch,
      actions: companySettingsActions,
      getData: handleGetData,
    });
  };
