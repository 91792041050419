import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import { selectTourniquetTrainingSlides } from '../../store/entities/tourniquetTrainingSlides';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { HtmlViewer } from '../common/html/HtmlViewer';
import { TourniquetSlideCard } from './TourniquetStyles';

interface Props {
  onEndTraining: () => void;
}

export const TourniquetTrainingSlides: FC<Props> = ({ onEndTraining }) => {
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const tourniquetTrainingSlides = useAppSelector(
    selectTourniquetTrainingSlides
  );
  const dispatch = useAppDispatch();

  const slide = tourniquetTrainingSlides.slides[tourniquetTraining.index];
  const canPrev = tourniquetTraining.index !== 0;

  const handlePrev = () => {
    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        index: tourniquetTraining.index - 1,
      })
    );
  };

  const handleNext = () => {
    if (
      tourniquetTraining.index ===
      tourniquetTrainingSlides.slides.length - 1
    ) {
      //Switch to sign.
      dispatch(
        tourniquetTrainingActions.success({
          ...tourniquetTraining,
          mode: 'trainingSign',
        })
      );
      return;
    }

    //Next index.
    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        index: tourniquetTraining.index + 1,
      })
    );
  };

  return (
    <TourniquetButtonContent
      backOnClick={handlePrev}
      backDisabled={!canPrev}
      onAbort={onEndTraining}
      nextOnClick={handleNext}
      mobileGap={true}
    >
      <TourniquetSlideCard>
        <QcsBox>
          <HtmlViewer html={slide.content} />
        </QcsBox>
      </TourniquetSlideCard>
    </TourniquetButtonContent>
  );
};
