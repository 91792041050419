import { SupplierEmployeeDto, UserDtoRolesEnum } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import {
  getSupplierEmployeeList,
  selectSupplierEmployeeList,
  supplierEmployeeListActions,
} from '../../store/entities/supplierEmployeeList';
import { getLangNameObj } from '../../utils/format';
import { hasRole } from '../../utils/roles';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { SupplierEmployeeFilter } from './SupplierEmployeeFilter';

interface Props {
  supplierId: string;
}

export const SupplierEmployee: FC<Props> = ({ supplierId }) => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const supplierEmployeeList = useAppSelector(selectSupplierEmployeeList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(supplierEmployeeListActions.reload(true));
  }, [dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getSupplierEmployeeList(supplierId, cancelToken));
  };

  const handleRenderData = (item: SupplierEmployeeDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t(`user.role.${item.role}`)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.certificateCount}</QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: SupplierEmployeeDto) => {
    if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
      navigate(`/supplier/employee/${item.id}`);
    } else {
      navigate(`/supplier/${supplierId}/employee/${item.id}`);
    }
  };

  const handleAddClick = () => {
    if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
      navigate(`/supplier/employee/new`);
    } else {
      navigate(`/supplier/${supplierId}/employee/new`);
    }
  };

  return (
    <div style={{ marginTop: '1rem' }}>
      <SectionHeader
        title="supplierEmployee.title"
        variant="h5"
        addText="supplierEmployee.add"
        handleAddClick={handleAddClick}
      />
      <Grid<SupplierEmployeeDto>
        headers={[
          { captionStr: 'supplierEmployee.name', orderName: 'name' },
          { captionStr: 'supplierEmployee.lastName', orderName: 'lastName' },
          {
            captionStr: 'supplierEmployee.phone',
            hideOnMobile: true,
          },
          {
            captionStr: 'supplierEmployee.email',
            orderName: 'email',
            hideOnMobile: true,
          },
          {
            captionStr: 'supplierEmployee.role',
            orderName: 'role',
            hideOnMobile: true,
          },
          {
            captionStr: 'supplierEmployee.certificateCount',
            orderName: 'certificateCount',
            hideOnMobile: true,
          },
        ]}
        data={supplierEmployeeList}
        gridActions={supplierEmployeeListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<SupplierEmployeeFilter query={supplierEmployeeList.query} />}
      />
    </div>
  );
};
