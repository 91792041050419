import { SupplierEmployeeDtoRoleEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelect } from './GridFilterSelect';

const options = [
  SupplierEmployeeDtoRoleEnum.ExternalManager,
  SupplierEmployeeDtoRoleEnum.ExternalWorker,
];

interface Props {
  value?: SupplierEmployeeDtoRoleEnum;
  onChange: (value?: SupplierEmployeeDtoRoleEnum) => void;
}

export const GridFilterSupplierEmployeeRole: FC<Props> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <GridFilterSelect
      value={value}
      options={options}
      onChange={onChange}
      getOptionId={(option: SupplierEmployeeDtoRoleEnum) => option}
      getOptionName={(option: SupplierEmployeeDtoRoleEnum) =>
        t(`user.role.${option}`)
      }
    />
  );
};
