import { FormControlLabel, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  control: React.ReactElement<any, any>;
  label: React.ReactNode;
  value?: any;
  sx?: SxProps<Theme>;
}

export const QcsFormControlLabel: FC<Props> = ({
  className,
  control,
  label,
  value,
  sx,
}) => {
  return (
    <FormControlLabel
      className={className}
      control={control}
      label={label}
      value={value}
      sx={sx}
    />
  );
};
