import { Checkbox } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  id?: string;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  disabled?: boolean;
}

export const QcsCheckbox: FC<Props> = ({
  className,
  id,
  name,
  checked,
  indeterminate,
  onChange,
  disabled,
}) => {
  return (
    <Checkbox
      className={className}
      id={id}
      name={name}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
