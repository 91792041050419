import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
  UserDto,
  UserDtoRolesEnum,
  UserDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import {
  getUser,
  userActions,
  selectUser,
  selectUserState,
} from '../../store/entities/user';
import { userListActions } from '../../store/entities/userList';
import { FetchState } from '../../store/fetchState';
import { qcUserApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { Select } from '../common/form/Select';
import { SubmitButton } from '../common/form/SubmitButton';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { UserRoleSelect } from '../common/form/UserRoleSelect';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CancelToken } from 'axios';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { Grid } from '../common/grid/Grid';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { EstablishmentFilter } from '../establishment/EstablishmentFilter';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { applicationInvitationApi } from '../../utils/api';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { employeeListActions } from '../../store/entities/employeeList';
import { PhoneInput } from '../common/form/PhoneInput';
import { setErrorSnacks, setErrorStateSnacks } from '../../utils/error';
import { getFirstLastNameObj, getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';
import { allEstablishmentListActions, getAllEstablishmentList, selectAllEstablishmentList } from '../../store/entities/allEstablishmentList';

export const UserDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/user');
  const { userId } = useParams();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const user = useAppSelector(selectUser);
  const userState = useAppSelector(selectUserState);
  const establishmentList = useAppSelector(selectAllEstablishmentList);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [resendInvitationLoading, setResendInvitationLoading] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    if (userId === 'new') {
      dispatch(userActions.default());
    } else {
      dispatch(getUser(userId!));
    }
    setLoading(false);
  }, [userId, dispatch]);

  const handleSubmit = async (data: UserDto) => {
    setSaveError('');

    try {
      await qcUserApi.updateUser(userId!, data);
      enqueueSuccessSnackbar(t('common.editSuccess'));
      dispatch(userListActions.reload(true));
      dispatch(employeeListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
  };

  const handleResendInvitation = async () => {
    try {
      setResendInvitationLoading(true);
      await applicationInvitationApi.resendApplicationInvitation(userId!);
      enqueueSuccessSnackbar(t('user.invitationSendOk'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'user.invitationSendError');
    } finally {
      setResendInvitationLoading(false);
    }
  };

  const handleEstablishmentGetData = (cancelToken: CancelToken) => {
    dispatch(getAllEstablishmentList(identityCompanyId, cancelToken));
  };

  const handleIsRowDisabled = (item: EstablishmentDto) =>
    item.state !== EstablishmentDtoStateEnum.Active;

  const handleEstablishmentRenderData = (item: EstablishmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getFirstLastNameObj(item.contact)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  if (loading || userState === FetchState.Loading) {
    return <Loader />;
  }

  if (userState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validate = (values: UserDto) => {
    const errors: Partial<Record<keyof UserDto, string>> = {};

    if (
      values.roles.some(
        (role) =>
          role === UserDtoRolesEnum.Tourniquet ||
          role === UserDtoRolesEnum.TourniquetTraining
      ) &&
      values.roles.length !== 1
    ) {
      errors.roles = t('user.errorInvalidRoleCombination') ?? undefined;
    }

    return errors;
  };

  return (
    <>
      <DetailHeader title="user.detailTitle" backTo={backTo} />
      <FormikDebounce<UserDto>
        initialValues={user}
        validationSchema={Yup.object().shape({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          roles: validations.arrayNotEmpty(t),
          language: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email: validations.emailRequired(t),
        })}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormContainer>
              <Input
                name="name"
                label={t('user.name')}
                maxLength={100}
                required
              />
              <Input
                name="lastName"
                label={t('user.lastName')}
                maxLength={100}
                required
              />
              <UserRoleSelect name="roles" label={t('user.roles')} required />
              <PhoneInput name="phone" label={t('user.phone')} />
              <Input
                name="email"
                label={t('user.email')}
                maxLength={100}
                required
              />

              {user.state === UserDtoStateEnum.Invited && (
                <QcsBox sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <QcsLoadingButton
                    variant="contained"
                    onClick={handleResendInvitation}
                    loading={resendInvitationLoading}
                  >
                    {t('user.inviteUser')}
                  </QcsLoadingButton>
                </QcsBox>
              )}

              <div>
                <QcsTypography variant="h6">
                  {t('user.detailEstablishmentSelect')}:
                </QcsTypography>
                <Grid<EstablishmentDto>
                  headers={[
                    { captionStr: 'establishment.name' },
                    {
                      captionStr: 'establishment.contactName',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.email',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.phone',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.state',
                      hideOnMobile: true,
                    },
                  ]}
                  //establishmentList is used by Establishment.tsx - must have same filter (actual) or split store
                  data={establishmentList}
                  gridActions={allEstablishmentListActions}
                  renderData={handleEstablishmentRenderData}
                  isRowDisabled={handleIsRowDisabled}
                  getData={handleEstablishmentGetData}
                  checkbox={true}
                  checkedItems={values.establishments}
                  changeChecked={(items) =>
                    setFieldValue('establishments', items)
                  }
                  filter={
                    <EstablishmentFilter query={establishmentList.query} />
                  }
                />
              </div>
              <Input name="office" label={t('user.office')} maxLength={100} />
              <LanguageSelect
                name="language"
                label={t('user.language')}
                languages={SUPPORTED_LANGUAGES}
                allowedLanguages={companyCustomization?.applicationLanguages}
                required
              />
              <Select name="state" label={t('user.state')}>
                <QcsMenuItem
                  value={UserDtoStateEnum.Active}
                  disabled={user.state === UserDtoStateEnum.Invited}
                >
                  {t('entityState.ACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={UserDtoStateEnum.Inactive}>
                  {t('entityState.INACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem
                  value={UserDtoStateEnum.Invited}
                  disabled={user.state !== UserDtoStateEnum.Invited}
                >
                  {t('entityState.INVITED')}
                </QcsMenuItem>
              </Select>
              <Input
                name="note"
                label={t('user.note')}
                maxLength={250}
                multiline={true}
                rows={4}
              />
              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
