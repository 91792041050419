import { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppInvitation } from './components/auth/AppInvitation';
import { ForgottenPassword } from './components/auth/ForgottenPassword';
import { ForgottenPasswordReq } from './components/auth/ForgottenPasswordReq';
import { ExternalLinkPage } from './components/external/ExternalLinkPage';
import { RemoteLinkPage } from './components/external/RemoteLinkPage';
import { Routes2 } from './Routes2';
import { useAppDispatch, useAppSelector } from './store';
import {
  getSubdomainCustomization,
  selectSubdomainCustomizationState,
} from './store/entities/subdomainCustomization';
import { FetchState } from './store/fetchState';
import { Backdrop, CircularProgress } from '@mui/material';
import usePwaManifest from './hooks/usePwaManifest';

const USE_SUBDOMAIN = import.meta.env.VITE_USE_SUBDOMAIN as string;

export const Routes1: FC = () => {
  const dispatch = useAppDispatch();
  const subdomainCustomizationState = useAppSelector(
    selectSubdomainCustomizationState
  );

  useEffect(() => {
    const subdomain = USE_SUBDOMAIN
      ? window.location.hostname.split('.')[0]
      : '';
    dispatch(getSubdomainCustomization(subdomain));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePwaManifest();

  if (
    subdomainCustomizationState === FetchState.None ||
    subdomainCustomizationState === FetchState.Loading
  ) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Routes>
      <Route
        path="forgotten-password-request"
        element={<ForgottenPasswordReq />}
      />
      <Route path="forgotten-password" element={<ForgottenPassword />} />
      <Route path="app-invitation" element={<AppInvitation />} />
      <Route path="trainining-worker" element={<ExternalLinkPage />} />
      <Route path="em/:token" element={<RemoteLinkPage />} />
      <Route path="*" element={<Routes2 />} />
    </Routes>
  );
};
