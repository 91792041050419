import { SxProps, Theme } from '@mui/material';
import { parseISO } from 'date-fns';
import { FC } from 'react';
import { DateFormat } from '../../../utils/date';
import { QcsTableCell } from '../basic/QcsTableCell';

interface Props {
  value?: string | null;
  hideOnMobile?: boolean;
  sx?: SxProps<Theme>;
  showWithTime?: boolean;
}

export const CellDate: FC<Props> = ({
  value,
  hideOnMobile,
  showWithTime,
  sx,
}) => {
  return (
    <QcsTableCell hideOnMobile={hideOnMobile} sx={sx}>
      {value ? (
        <DateFormat value={parseISO(value)} showWithTime={showWithTime} />
      ) : undefined}
    </QcsTableCell>
  );
};
