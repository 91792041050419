import { SxProps, TableCell, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  padding?: 'normal' | 'checkbox' | 'none';
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  hideOnMobile?: boolean;
  showOnlyOnMobile?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

export const QcsTableCell: FC<Props> = ({
  className,
  sx,
  padding,
  align,
  hideOnMobile,
  showOnlyOnMobile,
  children,
  onClick,
}) => {
  return (
    <TableCell
      className={className}
      align={align}
      padding={padding}
      sx={{
        display: {
          xs: hideOnMobile ? 'none' : 'table-cell',
          md: showOnlyOnMobile ? 'none' : 'table-cell',
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </TableCell>
  );
};
