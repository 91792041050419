import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { ActivityDto, ActivityDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { activityApi } from '../../utils/api';

type State = BaseType<ActivityDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    oneTimeEntry: false,
    riskRequired: true,
    riskPass: true,
    state: ActivityDtoStateEnum.Active,
    certificates: [],
    stateChangeEnabled: true,
  },
};

export const activitySlice = baseSlice({
  name: 'activity',
  initialState,
});

export const selectActivityState = (state: RootState) => state.activity.state;
export const selectActivity = (state: RootState) => state.activity.data;
export const activityActions = activitySlice.actions;

export const getActivity = (activityId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return activityApi.getActivity(activityId);
  };

  getItem({
    dispatch,
    actions: activityActions,
    getData: handleGetData,
  });
};
