import { EmployeeUserListResponse } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  userListActions,
  getUserList,
  selectUserList,
} from '../../store/entities/userList';
import { getLangNameObj, joinStrings2 } from '../../utils/format';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { UserFilter } from './UserFilter';

export const User: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const userList = useAppSelector(selectUserList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getUserList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: EmployeeUserListResponse) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell>{item.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.office}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.jobPosition}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {joinStrings2(item.roles?.map((role) => t(`user.role.${role}`)))}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: EmployeeUserListResponse) => {
    navigate('/user/' + item.id);
  };

  return (
    <>
      <SectionHeader title="user.title" />
      <Grid<EmployeeUserListResponse>
        headers={[
          { captionStr: 'user.name', orderName: 'name' },
          { captionStr: 'user.lastName', orderName: 'lastName' },
          {
            captionStr: 'user.phone',
            hideOnMobile: true,
          },
          {
            captionStr: 'user.email',
            hideOnMobile: true,
          },
          {
            captionStr: 'user.office',
            orderName: 'office',
            hideOnMobile: true,
          },
          {
            captionStr: 'user.jobPosition',
            hideOnMobile: true,
          },
          {
            captionStr: 'user.roles',
            orderName: 'roles',
          },
          {
            captionStr: 'user.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={userList}
        gridActions={userListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<UserFilter query={userList.query} />}
      />
    </>
  );
};
