import {
  InputBaseComponentProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import {
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  WheelEventHandler,
} from 'react';

interface Props {
  className?: string;
  inputRef?: any;
  variant?: 'standard' | 'filled' | 'outlined';
  required?: boolean;
  sx?: SxProps<Theme>;
  id?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  name?: string;
  label?: React.ReactNode;
  value?: unknown;
  defaultValue?: unknown;
  error?: boolean;
  helperText?: React.ReactNode;
  inputProps?: InputBaseComponentProps;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  fullWidth?: boolean;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onWheel?: WheelEventHandler<HTMLDivElement>;
}

export const QcsTextField: FC<Props> = ({
  className,
  inputRef,
  variant,
  required,
  sx,
  id,
  type,
  name,
  label,
  value,
  defaultValue,
  error,
  helperText,
  inputProps,
  multiline,
  disabled,
  rows,
  fullWidth,
  autoFocus,
  onChange,
  onBlur,
  onKeyDown,
  onWheel,
}) => {
  return (
    <TextField
      className={className}
      inputRef={inputRef}
      variant={variant}
      id={id}
      type={type}
      name={name}
      //Dont use required parameter.
      label={required ? <>{label} *</> : label}
      value={value}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onWheel={onWheel}
      InputProps={{ sx, autoFocus }}
    />
  );
};
