import { QuestionDto } from '@qcs/safety-client';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getQuestionnaireQuestions,
  selectQuestionnaireQuestions,
  selectQuestionnaireQuestionsState,
} from '../../store/entities/questionnaireQuestions';
import { mediaApi, questionnairesApi } from '../../utils/api';
import { DetailHeader } from '../common/DetailHeader';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorSnacks } from '../../utils/error';
import { FormContent } from './QuestionnaireQuestionsFormContent';
import { FetchState } from '../../store/fetchState';
import { ErrorAlert } from '../common/ErrorAlert';
import { selectQuestionnaire } from '../../store/entities/questionnaire';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getLangNameObj } from '../../utils/format';
import { useBack } from '../../hooks/useBack';

export interface QuestionsForm {
  questions: QuestionDto[];
}

export const QuestionnaireQuestions: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { questionnaireId, questionnaireVersionId, languageCode } = useParams();
  const { backTo, goBack } = useBack(
    `/questionnaire/${questionnaireId}/${questionnaireVersionId}`
  );
  const dispatch = useAppDispatch();
  const questions = useAppSelector(selectQuestionnaireQuestions);
  const questionnaire = useAppSelector(selectQuestionnaire);
  const questionsState = useAppSelector(selectQuestionnaireQuestionsState);
  const [loading, setLoading] = useState(false);
  const [oldFilesToRemove, setOldFilesToRemove] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getQuestionnaireQuestions(questionnaireVersionId!, languageCode!));
    setLoading(false);
  }, [dispatch, languageCode, questionnaireVersionId]);

  const handleSubmit = async (formData: QuestionsForm) => {
    try {
      await questionnairesApi.updateAllQuestions(
        questionnaireVersionId!,
        languageCode!,
        formData.questions
      );

      for (const oldFile of oldFilesToRemove) {
        try {
          await mediaApi.deleteFile(oldFile);
        } catch {
          //Ignore error.
        }
      }

      enqueueSuccessSnackbar(t('questionnaire.questions.saveSuccess'));
    } catch (err) {
      setErrorSnacks(
        err,
        enqueueErrorSnackbar,
        'questionnaire.questions.saveError'
      );
    }
  };

  const handleRemoveOldFile = (document: string) => {
    setOldFilesToRemove([...oldFilesToRemove, document]);
  };

  if (loading || questionsState === FetchState.Loading) {
    return <Loader />;
  }

  if (questionsState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader
        title={`${t('questionnaire.questions.title')}: ${getLangNameObj(
          i18n,
          questionnaire
        )}`}
        backTo={backTo}
      />
      <FormikDebounce<QuestionsForm>
        initialValues={{ questions }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          questions: Yup.array(
            Yup.object().shape({
              content: validations.stringRequired(t),
              answers: Yup.array(
                Yup.object().shape({
                  content: validations.stringRequired(t),
                })
              ).test({
                message: t('questionnaire.questions.errorAnswerRequired')!,
                test: (val: any) => {
                  return val.some((x: any) => x.correct);
                },
              }),
            })
          ),
        })}
      >
        <FormContent onRemoveOldFile={handleRemoveOldFile} />
      </FormikDebounce>
      <QcsButton
        sx={{ width: 'fit-content' }}
        variant="outlined"
        onClick={goBack}
      >
        <ArrowBackIcon />
        {t('common.back')}
      </QcsButton>
    </>
  );
};
