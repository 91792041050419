import { Form, useFormikContext } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SubmitButton } from '../common/form/SubmitButton';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { InvitationCreateForm } from '../../models/invitation';
import { SupplierAutocomplete } from '../common/form/SupplierAutocomplete';
import { SupplierEmployeeAutocomplete } from '../common/form/SupplierEmployeeAutocomplete';
import {
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';

interface FormContentProps {
  handleGoBack: () => void;
  onDelete: () => Promise<void>;
}

const FormContent: FC<FormContentProps> = ({ handleGoBack, onDelete }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<InvitationCreateForm>();
  const [firstCall, setFirstCall] = useState(true);

  useEffect(() => {
    if (values.supplierA?.id && !firstCall) {
      setFieldValue('responsiblePersonA', undefined);
    }
    setFirstCall(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.supplierA?.id]);

  return (
    <Form>
      <FormContainer>
        <SupplierAutocomplete
          name="supplierA"
          label={t('invitation.supplier')}
          required
        />

        <SupplierEmployeeAutocomplete
          name="responsiblePersonA"
          label={t('invitation.responsibleSupplierPerson')}
          supplierId={values.supplierA?.id}
          role={SupplierEmployeeDtoRoleEnum.ExternalManager}
          state={[
            SupplierEmployeeDtoStateEnum.Active,
            SupplierEmployeeDtoStateEnum.Invited,
          ]}
          disabled={!values.supplierA?.id}
          required
        />

        <InvitationButtonsContainer>
          <QcsButton variant="outlined" onClick={handleGoBack}>
            {t('common.back')}
          </QcsButton>
          <div style={{ width: '100%' }} />
          <InvitationButton type="DELETE" customEvent={onDelete} />
          <InvitationButton type="SAVE" />
          <SubmitButton text={t('common.nextStep')} />
        </InvitationButtonsContainer>
      </FormContainer>
    </Form>
  );
};

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep4A: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        supplierType: validations.stringRequired(t),
        supplierA: validations.objectRequired(t),
        responsiblePersonA: validations.objectRequired(t),
      })}
      onSubmit={handleSubmit}
    >
      <FormContent handleGoBack={handleGoBack} onDelete={onDelete} />
    </FormikDebounce>
  );
};
