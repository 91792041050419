import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { TrainingDto, TrainingDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { trainingsApi } from '../../utils/api';

export interface TrainingListFilter extends ListFilter {
  state?: TrainingDtoStateEnum;
}

export type TrainingListState = ListType<TrainingDto, TrainingListFilter>;

const initialState: TrainingListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: TrainingDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const trainingListSlice = listSlice({
  name: 'trainingList',
  initialState,
});

export const selectTrainingList = (state: RootState) => state.trainingList;
export const trainingListActions = trainingListSlice.actions;

export const getTrainingList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectTrainingList(storeState);

    const handleGetData = (size: number) => {
      return trainingsApi.queryTrainings(
        companyId,
        data.query.q,
        undefined, //skipId
        data.query.state,
        undefined, //nameOnly
        undefined, //ignoreWithoutQuestionnaireWithLanguage
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: trainingListActions,
      getData: handleGetData,
    });
  };
