import { RootState } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType } from '../baseHelper';
import { TourniquetIdentity } from '../../models/tourniquet';

type State = BaseType<TourniquetIdentity>;

const initialState: State = {
  state: FetchState.None,
  data: {},
};

export const tourniquetIdentitySlice = baseSlice({
  name: 'tourniquetIdentity',
  initialState,
});

export const selectTourniquetIdentity = (state: RootState) =>
  state.tourniquetIdentity.data;
export const tourniquetIdentityActions = tourniquetIdentitySlice.actions;
