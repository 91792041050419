import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import {
  getOptionsSafetyEquipment,
  selectOptionsSafetyEquipment,
  selectOptionsSafetyEquipmentState,
} from '../../../store/entities/optionsSafetyEquipment';
import { ReferenceDto } from '@qcs/safety-client';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import { getLangNameObj } from '../../../utils/format';

interface Props {
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

export const SafetyEquipmentAutocomplete: FC<Props> = ({
  multiple,
  name,
  label,
  disabled,
  required,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsSafetyEquipment = useAppSelector(selectOptionsSafetyEquipment);
  const optionsSafetyEquipmentState = useAppSelector(
    selectOptionsSafetyEquipmentState
  );

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(getOptionsSafetyEquipment(identityCompanyId, search, cancelToken));
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={optionsSafetyEquipment}
      loading={optionsSafetyEquipmentState === FetchState.Loading}
      loadingError={optionsSafetyEquipmentState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
    />
  );
};
