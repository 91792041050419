import {
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppDispatch } from '.';
import { storageResetAction } from './storeReset';
import { FetchState } from './fetchState';

export interface BaseType<T> {
  state: FetchState;
  data: T;
}

export interface BaseActions<T> {
  request: ActionCreatorWithPayload<void, string>;
  success: ActionCreatorWithPayload<T, string>;
  error: ActionCreatorWithPayload<void, string>;
  default: ActionCreatorWithPayload<void, string>;
  reset: ActionCreatorWithPayload<void, string>;
}

interface BaseSliceProps<T> {
  name: string;
  initialState: BaseType<T>;
  disableReset?: boolean;
}

export function baseSlice<T>({
  name,
  initialState,
  disableReset,
}: BaseSliceProps<T>) {
  return createSlice({
    name,
    initialState,
    reducers: {
      request: (state) => {
        state.state = FetchState.Loading;
      },
      success: (state, action: PayloadAction<T>) => {
        state.state = FetchState.Loaded;
        state.data = action.payload as any;
      },
      error: (state) => {
        state.state = FetchState.Error;
        state.data = initialState.data as any;
      },
      default: (state) => {
        state.state = FetchState.Loaded;
        state.data = initialState.data as any;
      },
      reset: (state) => {
        state.state = FetchState.None;
        state.data = initialState.data as any;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(storageResetAction, (state) => {
        if (disableReset) {
          return state;
        }

        return initialState;
      });
    },
  });
}

export async function getItem<T>({
  dispatch,
  actions,
  getData,
  changeData,
}: {
  dispatch: AppDispatch;
  actions: BaseActions<T>;
  getData: () => Promise<{ data: T }>;
  changeData?: (data: T) => Promise<T>;
}) {
  dispatch(actions.request());
  try {
    const response = await getData();
    const data = changeData ? await changeData(response.data) : response.data;
    dispatch(actions.success(data));
  } catch {
    dispatch(actions.error());
  }
}
