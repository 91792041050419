import { Dialog } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  open: boolean;
  onClose?: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void;
  children: React.ReactNode;
}

export const QcsDialog: FC<Props> = ({
  className,
  open,
  onClose,
  children,
}) => {
  return (
    <Dialog className={className} open={open} onClose={onClose}>
      {children}
    </Dialog>
  );
};
