import {
  SupplierDto,
  SupplierDtoStateEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { Form, FormikProps, useFormikContext } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getSupplier,
  supplierActions,
  selectSupplier,
  selectSupplierState,
  getSupplierFromUserId,
} from '../../store/entities/supplier';
import { supplierListActions } from '../../store/entities/supplierList';
import { FetchState } from '../../store/fetchState';
import { aresApi, supplierApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { selectIdentity } from '../../store/entities/identity';
import { FormGroup } from '../common/form/FormGroup';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { SupplierEmployee } from './SupplierEmployee';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorState, setErrorStateSnacks } from '../../utils/error';
import { hasRole } from '../../utils/roles';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsTypography } from '../common/basic/QcsTypography';
import { joinStrings } from '../../utils/format';
import { useTheme } from '@mui/material';
import { RepairLineBreaks } from '../common/RepairLineBreaks';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

interface SupplierDtoWithButton extends SupplierDto {
  addEmployeeMode?: boolean;
  saveAndNew?: boolean;
}

const SupplierDetailEmployeeModeButton: FC = () => {
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue, submitForm } =
    useFormikContext<SupplierDtoWithButton>();

  const handleClick = async () => {
    setFieldValue('addEmployeeMode', true);
    try {
      await submitForm();
    } finally {
      setFieldValue('addEmployeeMode', false);
    }
  };

  return (
    <QcsLoadingButton
      variant="contained"
      disabled={isSubmitting}
      loading={isSubmitting}
      onClick={handleClick}
    >
      {t('supplier.addWithEmployee')}
    </QcsLoadingButton>
  );
};

export const SupplierDetail: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { supplierId } = useParams();
  const identity = useAppSelector(selectIdentity);
  const { backTo, goBack, navigate } = useBack(
    hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])
      ? undefined
      : '/supplier'
  );
  const supplier = useAppSelector(selectSupplier);
  const supplierState = useAppSelector(selectSupplierState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [aresLoading, setAresLoading] = useState(false);
  const [aresError, setAresError] = useState<ErrorStateType>();
  const formikRef = useRef<FormikProps<SupplierDtoWithButton> | null>(null);

  useEffect(() => {
    if (supplierId === 'new') {
      dispatch(supplierActions.default());
    } else {
      if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
        dispatch(getSupplierFromUserId(identity.id));
      } else {
        dispatch(getSupplier(supplierId!));
      }
    }
    setLoading(false);
  }, [supplierId, dispatch, identity.roles, identity.id]);

  const handleSubmit = async (data: SupplierDtoWithButton) => {
    setSaveError('');
    try {
      let newSupplierResponse;
      if (supplierId === 'new') {
        newSupplierResponse = await supplierApi.createSupplier(
          identity.companyId,
          data
        );
        enqueueSuccessSnackbar(t('supplier.addSuccess'));
      } else {
        await supplierApi.updateSupplier(supplier.id!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(supplierListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else if (data.addEmployeeMode && newSupplierResponse) {
        navigate(`/supplier/${newSupplierResponse.data.id}/employee/new`);
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        supplierId === 'new' ? 'supplier.addError' : 'common.editError'
      );
    }
  };

  if (loading || supplierState === FetchState.Loading) {
    return <Loader />;
  }

  if (supplierState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="supplier.detailTitle" backTo={backTo} />
      {hasRole(identity.roles, [
        UserDtoRolesEnum.ExternalManager,
        UserDtoRolesEnum.ExternalWorker,
      ]) ? (
        <QcsBox
          sx={{
            pb: 3,
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', sm: 'auto max-content' },
            gridTemplateAreas: {
              xs: '"buttons" "title"',
              sm: '"title buttons"',
            },
            gap: '2rem',
            width: '100%',
          }}
        >
          <QcsBox sx={{ gridArea: 'title' }}>
            {/* Dodavatel */}
            <QcsTypography variant="subtitle1">
              <strong>{`${t('supplier.detailTitle')}:`}</strong> {supplier.name}
              , <strong>{`${t('supplier.ico')}:`}</strong> {supplier.ico},{' '}
              <strong>{t('supplier.vat')}:</strong> {supplier.vat}
            </QcsTypography>
            {/* Adresa dodavatele */}
            <QcsTypography variant="subtitle1">
              <strong>{`${t('address.title')}:`}</strong>{' '}
              {joinStrings(
                supplier.address.street,
                supplier.address.city,
                supplier.address.postalCode,
                supplier.address.country
              )}
            </QcsTypography>
            {supplier.note && (
              <>
                <QcsTypography variant="subtitle1">
                  <strong>{`${t('supplier.note')}:`}</strong>
                </QcsTypography>
                <QcsTypography variant="body1" sx={{ lineHeight: '1.2em' }}>
                  <RepairLineBreaks text={supplier.note} />
                </QcsTypography>
              </>
            )}
          </QcsBox>
          <QcsBox
            sx={{
              gridArea: 'buttons',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              textAlign: 'center',
            }}
          >
            <QcsTypography
              variant="subtitle1"
              sx={{ color: theme.palette.primary.main }}
            >
              <strong>{t(`entityState.${supplier.state}`)}</strong>
            </QcsTypography>
          </QcsBox>
        </QcsBox>
      ) : (
        <FormikDebounce<SupplierDtoWithButton>
          initialValues={supplier}
          validationSchema={Yup.object().shape({
            name: validations.stringRequired(t),
            ico: validations.companyNoRequired(t),
            vat: validations.companyVatNoOptional(t),
            address: Yup.object({
              street: validations.stringRequired(t),
              city: validations.stringRequired(t),
              postalCode: validations.postalCodeRequired(t),
              country: validations.stringRequired(t),
            }),
          })}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({ values, setValues }) => {
            const handleLoadFromAres = async () => {
              setAresLoading(true);
              setAresError('');

              try {
                const res = await aresApi.fetchSubjectDetailsFromAres(
                  values.ico!
                );

                // filter out null values
                const data = Object.fromEntries(
                  Object.entries(res.data).filter(([, value]) => value !== null)
                );
                setValues({ ...values, ...data });
              } catch (err) {
                setErrorState(err, setAresError, 'admin.company.aresError');
              }

              setAresLoading(false);
            };

            return (
              <Form>
                <FormContainer>
                  <QcsBox
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: {
                        xs: 'auto',
                        sm: 'auto max-content',
                      },
                      gridTemplateRows: { xs: 'auto auto', sm: 'auto' },
                      rowGap: { xs: supplierId === 'new' ? '1rem' : 0, sm: 0 },
                      columnGap: {
                        xs: 0,
                        sm: supplierId === 'new' ? '1rem' : 0,
                      },
                    }}
                  >
                    <Input
                      name="ico"
                      label={t('supplier.ico')}
                      maxLength={14}
                      required
                    />
                    {supplierId === 'new' && (
                      <QcsLoadingButton
                        onClick={handleLoadFromAres}
                        disabled={!values.ico}
                        loading={aresLoading}
                        variant="contained"
                        sx={{ height: '3.5rem' }}
                      >
                        {t('admin.company.loadFromAres')}
                      </QcsLoadingButton>
                    )}
                  </QcsBox>
                  <SaveError error={aresError} />

                  <Input
                    name="name"
                    label={t('supplier.name')}
                    maxLength={100}
                    required
                  />
                  <Input name="vat" label={t('supplier.vat')} maxLength={14} />

                  <FormGroup name="address.title">
                    <Input
                      name="address.street"
                      label={t('address.street')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="address.city"
                      label={t('address.city')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="address.postalCode"
                      label={t('address.postalCode')}
                      maxLength={128}
                      required
                    />
                    <Input
                      name="address.country"
                      label={t('address.country')}
                      maxLength={100}
                      required
                    />
                  </FormGroup>

                  <Select name="state" label={t('supplier.state')}>
                    <QcsMenuItem value={SupplierDtoStateEnum.Active}>
                      {t('entityState.ACTIVE')}
                    </QcsMenuItem>
                    <QcsMenuItem value={SupplierDtoStateEnum.Inactive}>
                      {t('entityState.INACTIVE')}
                    </QcsMenuItem>
                  </Select>
                  <Input
                    name="note"
                    label={t('supplier.note')}
                    maxLength={250}
                    multiline={true}
                    rows={4}
                  />

                  <SubmitAndNewButtons id={supplierId} error={saveError}>
                    {supplierId === 'new' && (
                      <SupplierDetailEmployeeModeButton />
                    )}
                  </SubmitAndNewButtons>
                </FormContainer>
              </Form>
            );
          }}
        </FormikDebounce>
      )}
      {supplierId !== 'new' && <SupplierEmployee supplierId={supplier.id!} />}
    </>
  );
};
