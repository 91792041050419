import { Backdrop, CircularProgress } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthRoute } from './components/auth/AuthRoute';
import { Login } from './components/auth/Login';
import { Dashboard } from './components/dashboard/Dashboard';
import { EmergencyDetailRemote } from './components/emergency/EmergencyDetailRemote';
import { Invitation } from './components/invitation/Invitation';
import {
  mainMenuItems,
  qcsAdminMenuItems,
} from './components/layout/menuItems';
import { WelcomeMessageModal } from './components/layout/WelcomeMessageModal';
import { QcsAdminCompany } from './components/qcsAdmin/company/QcsAdminCompany';
import { TourniquetRoutes } from './components/tourniquet/TourniquetRoutes';
import { useAppDispatch, useAppSelector } from './store';
import { selectCompanyCustomizationState } from './store/entities/companyCustomization';
import {
  getIdentity,
  selectIdentity,
  selectIdentityState,
} from './store/entities/identity';
import { FetchState } from './store/fetchState';
import {
  hasRole,
  isExternal,
  isQcsAdminMode,
  isRemote,
  isTourniquet,
} from './utils/roles';
import { getAccessToken } from './utils/storage';

export const Routes2: FC = () => {
  const token = getAccessToken();
  const identityState = useAppSelector(selectIdentityState);
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const companyCustomizationState = useAppSelector(
    selectCompanyCustomizationState
  );

  useEffect(() => {
    if (token && identityState === FetchState.None) {
      dispatch(getIdentity(token, false));
    }
  }, [dispatch, identityState, token]);

  const mainMenuItemsMemo = useMemo(() => {
    return (isQcsAdminMode(identity) ? qcsAdminMenuItems : mainMenuItems)
      .filter((x) => hasRole(identity.roles, x.roles))
      .map((x) => (
        <Route
          key={x.id}
          path={x.link}
          element={<AuthRoute roles={x.roles}>{x.component}</AuthRoute>}
        />
      ));
  }, [identity]);

  if (!token || identityState === FetchState.Error) {
    return <Login />;
  }

  if (
    identityState === FetchState.None ||
    identityState === FetchState.Loading ||
    companyCustomizationState === FetchState.None ||
    companyCustomizationState === FetchState.Loading
  ) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <>
      <WelcomeMessageModal />

      <Routes>
        <Route path="login" element={<Login />} />
        {isTourniquet(identity) ? (
          <>
            <Route
              path="*"
              element={
                <AuthRoute>
                  <TourniquetRoutes />
                </AuthRoute>
              }
            />
          </>
        ) : (
          <>
            {mainMenuItemsMemo}
            <Route
              path="*"
              element={
                <AuthRoute>
                  {isQcsAdminMode(identity) ? (
                    <QcsAdminCompany />
                  ) : isExternal(identity) ? (
                    <Invitation />
                  ) : isRemote(identity) ? (
                    <EmergencyDetailRemote />
                  ) : (
                    <Dashboard />
                  )}
                </AuthRoute>
              }
            />
          </>
        )}
      </Routes>
    </>
  );
};
