import { FileUploadMediaResponse } from '@qcs/safety-client';
import { FC } from 'react';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { GridList } from '../../../common/grid/GridList';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { invitationsApi } from '../../../../utils/api';
import { AxiosResponse } from 'axios';
import { downloadBlob } from '../../../../utils/download';
import { setErrorSnacks } from '../../../../utils/error';
import { useInvitation } from './invitationFunctions';

export const AttachmentTable: FC = () => {
  const { t, invitation, enqueueErrorSnackbar, isSaving, setIsSaving } =
    useInvitation();

  const handleRenderData = (item: FileUploadMediaResponse) => {
    return (
      <>
        <QcsTableCell>
          {decodeURIComponent((item.fileName ?? '').replace(/\+/g, ' '))}
        </QcsTableCell>
      </>
    );
  };

  const handleDownload = async () => {
    setIsSaving(true);
    try {
      const res = await invitationsApi.generateZipFile(
        invitation.id!,
        //Download all.
        {},
        false,
        'ATTACHMENT',
        {
          responseType: 'blob',
        }
      );
      const blobResponse = res as AxiosResponse<Blob>;
      await downloadBlob(blobResponse);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
  };

  return (
    <>
      <GridList<FileUploadMediaResponse>
        headers={[{ captionStr: 'invitation.attachment.name' }]}
        data={invitation.attachments ?? []}
        renderData={handleRenderData}
        search={false}
        hidePagination={true}
      />
      <QcsLoadingButton
        variant="contained"
        onClick={handleDownload}
        loading={isSaving}
        sx={{ mt: '1.5rem' }}
      >
        {t('invitation.attachment.download')}
      </QcsLoadingButton>
    </>
  );
};
