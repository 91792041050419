import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateStartEndFromTo } from '../../../models/common';
import { QcsDatePicker } from '../basic/QcsDatePicker';
import { FormContainer } from '../form/FormContainer';

interface Props {
  value?: DateStartEndFromTo;
  onChange: (value?: DateStartEndFromTo) => void;
}

export const GridFilterDateStartEndFromTo: FC<Props> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChangeStartFrom = (date?: Date | null) => {
    onChange({ ...value, startFrom: date ? date : undefined });
  };

  const handleChangeStartTo = (date?: Date | null) => {
    onChange({ ...value, startTo: date ? date : undefined });
  };

  const handleChangeEndFrom = (date?: Date | null) => {
    onChange({ ...value, endFrom: date ? date : undefined });
  };

  const handleChangeEndTo = (date?: Date | null) => {
    onChange({ ...value, endTo: date ? date : undefined });
  };

  return (
    <FormContainer>
      <QcsDatePicker
        label={t('common.startFrom')}
        value={value?.startFrom ? value.startFrom : null}
        onChange={handleChangeStartFrom}
      />
      <QcsDatePicker
        label={t('common.startTo')}
        value={value?.startTo ? value.startTo : null}
        onChange={handleChangeStartTo}
      />
      <QcsDatePicker
        label={t('common.endFrom')}
        value={value?.endFrom ? value.endFrom : null}
        onChange={handleChangeEndFrom}
      />
      <QcsDatePicker
        label={t('common.endTo')}
        value={value?.endTo ? value.endTo : null}
        onChange={handleChangeEndTo}
      />
    </FormContainer>
  );
};
