import { InputLabel } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  htmlFor?: string;
  required?: boolean;
  shrink?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const QcsInputLabel: FC<Props> = ({
  className,
  htmlFor,
  required,
  shrink,
  disabled,
  children,
}) => {
  return (
    <InputLabel
      className={className}
      htmlFor={htmlFor}
      shrink={shrink}
      disabled={disabled}
      //Dont use required parameter.
    >
      {children}
      {required && <> *</>}
    </InputLabel>
  );
};
