import { FC, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { CreateRiskRequest, RiskDtoStateEnum } from '@qcs/safety-client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { DetailHeader } from '../common/DetailHeader';
import { RiskVersion } from './RiskVersion';
import {
  getRisk,
  riskActions,
  selectRisk,
  selectRiskState,
} from '../../store/entities/risk';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { DatePicker } from '../common/form/DatePicker';
import { DocumentInput } from './DocumentInput';
import { mediaApi, riskApi } from '../../utils/api';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import styled from '@emotion/styled';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { riskListActions } from '../../store/entities/riskList';
import { ErrorAlert } from '../common/ErrorAlert';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { getDateForApi } from '../../utils/date';
import { setErrorStateSnacks } from '../../utils/error';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType } from '../../models/common';
import { fixNameInitValue } from '../../utils/format';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

type Validator = {
  name?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameEn?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameRu?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameDe?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  namePl?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  validFrom: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  document?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
};
interface RiskDetailForm extends CreateRiskRequest {
  saveAndNew?: boolean;
}

const VersionListWrapper = styled.div(() => ({ marginTop: '1rem' }));

export const RiskDetail: FC = () => {
  const { i18n, t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const { riskId } = useParams();
  const risk = useAppSelector(selectRisk);
  const riskState = useAppSelector(selectRiskState);
  const dispatch = useAppDispatch();
  const { backTo, goBack } = useBack('/risk');
  const formikRef = useRef<FormikProps<RiskDetailForm> | null>(null);
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [oldFilesToRemove, setOldFilesToRemove] = useState<string[]>([]);

  useEffect(() => {
    if (riskId === 'new') {
      dispatch(riskActions.default());
    } else {
      dispatch(getRisk(riskId!));
    }
    setLoading(false);
  }, [riskId, dispatch]);

  const handleSubmit = async (data: RiskDetailForm) => {
    setSaveError('');
    try {
      if (riskId === 'new') {
        await riskApi.createRisk(identityCompanyId, data);
        enqueueSuccessSnackbar(t('risk.addSuccess'));
      } else {
        await riskApi.updateRisk(riskId!, data);
        enqueueSuccessSnackbar(t('risk.editSuccess'));
      }

      for (const oldFile of oldFilesToRemove) {
        try {
          await mediaApi.deleteFile(oldFile);
        } catch {
          //Ignore error.
        }
      }

      dispatch(riskListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'risk.addError'
      );
    }
  };

  const handleRemoveOldFile = (document: string) => {
    setOldFilesToRemove([...oldFilesToRemove, document]);
  };

  if (loading || riskState === FetchState.Loading) {
    return <Loader />;
  }

  if (riskState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {
    validFrom: validations.stringDateNotPastRequired(t),
  };

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }
  if (riskId === 'new') {
    validator['document'] = validations.stringRequired(t);
  }

  return (
    <>
      <DetailHeader title="risk.detailTitle" backTo={backTo} />
      <FormikDebounce<RiskDetailForm>
        initialValues={{
          ...risk,
          ...fixNameInitValue(i18n, risk),
          note: '',
          document: '',
          validFrom: getDateForApi(new Date())!,
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={riskId === 'new'}
              label={t('risk.name')}
              maxLength={100}
              required
            />

            <Select name="state" label={t('risk.state')}>
              <QcsMenuItem value={RiskDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={RiskDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>

            {riskId === 'new' && (
              <>
                <Input
                  name="note"
                  label={t('user.note')}
                  maxLength={250}
                  multiline={true}
                  rows={4}
                />
                <DatePicker
                  name="validFrom"
                  label={t('risk.validFrom')}
                  disablePast
                  disabled
                  required
                />
                <DocumentInput required onRemoveOldFile={handleRemoveOldFile} />
              </>
            )}

            <SubmitAndNewButtons id={riskId} error={saveError} />
          </FormContainer>
        </Form>
      </FormikDebounce>
      {riskId !== 'new' && (
        <VersionListWrapper>
          <RiskVersion />
        </VersionListWrapper>
      )}
    </>
  );
};
