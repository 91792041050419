import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { VisitationVisitDetailForm } from './VisitationVisitDetailForm';
import { VisitationVisitDetailPresentation } from './VisitationVisitDetailPresentation';

export const VisitationVisitDetail: FC = () => {
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //Use goBack in browser navigator.
    return () => {
      dispatch(tourniquetTrainingActions.default());
      dispatch(tourniquetTrainingsActions.default());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((tourniquetTrainings?.trainings?.length ?? 0) > 0) {
    return <VisitationVisitDetailPresentation />;
  }

  return <VisitationVisitDetailForm />;
};
