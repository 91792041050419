import { SupplierDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  supplierListActions,
  getSupplierList,
  selectSupplierList,
} from '../../store/entities/supplierList';
import { getLangNameObj } from '../../utils/format';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTooltipContent } from '../common/basic/QcsTooltipContent';
import { QcsTypography } from '../common/basic/QcsTypography';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { SupplierFilter } from './SupplierFilter';

export const Supplier: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const supplierList = useAppSelector(selectSupplierList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getSupplierList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: SupplierDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.ico}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: SupplierDto) => {
    navigate(`/supplier/${item.id}`);
  };

  const handleAddClick = () => {
    navigate('/supplier/new');
  };

  const handleGetRowTooltip = (item: SupplierDto) => {
    return (
      <QcsTooltipContent>
        {item.note && (
          <>
            <QcsTypography variant="h6">{t('supplier.note')}</QcsTypography>
            <QcsTypography variant="body2">{item.note}</QcsTypography>
          </>
        )}
        <QcsTypography variant="h6">{t('address.title')}</QcsTypography>
        <QcsBox>
          <QcsTypography variant="body2">{item.address.street}</QcsTypography>
          <QcsTypography variant="body2">{item.address.city}</QcsTypography>
          <QcsTypography variant="body2">{`${item.address.postalCode}, ${item.address.country}`}</QcsTypography>
        </QcsBox>
      </QcsTooltipContent>
    );
  };

  return (
    <>
      <SectionHeader
        title="supplier.title"
        addText="supplier.add"
        handleAddClick={handleAddClick}
      />
      <Grid<SupplierDto>
        headers={[
          { captionStr: 'supplier.name', orderName: 'name' },
          {
            captionStr: 'supplier.ico',
            hideOnMobile: true,
          },
          {
            captionStr: 'supplier.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={supplierList}
        gridActions={supplierListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        getRowTooltip={handleGetRowTooltip}
        filter={<SupplierFilter query={supplierList.query} />}
      />
    </>
  );
};
