import {
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';

import {
  getSupplier,
  getSupplierFromUserId,
  selectSupplierState,
} from '../../store/entities/supplier';
import { supplierEmployeeListActions } from '../../store/entities/supplierEmployeeList';
import { FetchState } from '../../store/fetchState';
import { supplierEmployeeApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { SubmitButton } from '../common/form/SubmitButton';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import {
  getSupplierEmployee,
  selectSupplierEmployee,
  selectSupplierEmployeeState,
  supplierEmployeeActions,
} from '../../store/entities/supplierEmployee';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import styled from '@emotion/styled';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { PhoneInput } from '../common/form/PhoneInput';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import { setErrorState } from '../../utils/error';
import { hasRole } from '../../utils/roles';
import { selectIdentity } from '../../store/entities/identity';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

export const SupplierEmployeeFromVisitorDetailRole = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  overflow: 'hidden',
}));

export const SupplierEmployeeFromVisitorDetail: FC = () => {
  const { t } = useTranslation();
  const { supplierId, supplierEmployeeId } = useParams();
  const identity = useAppSelector(selectIdentity);
  const { backTo, goBack } = useBack(
    hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])
      ? '/supplier'
      : `/supplier/${supplierId}`
  );
  const supplierState = useAppSelector(selectSupplierState);
  const supplierEmployee = useAppSelector(selectSupplierEmployee);
  const supplierEmployeeState = useAppSelector(selectSupplierEmployeeState);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [currentRole, setRole] = useState(supplierEmployee.role);

  useEffect(() => {
    setRole(supplierEmployee.role as any);
  }, [supplierEmployee.role]);

  useEffect(() => {
    if (supplierEmployeeId === 'new') {
      dispatch(supplierEmployeeActions.default());
      setRole(supplierEmployee.role as any);
    } else {
      dispatch(getSupplierEmployee(supplierEmployeeId!));
      setRole(supplierEmployee.role as any);
    }

    if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
      dispatch(getSupplierFromUserId(identity.id));
    } else {
      dispatch(getSupplier(supplierId!));
    }

    setLoading(false);
  }, [supplierEmployeeId, supplierId, dispatch, identity.roles, identity.id]);

  const handleSubmit = async (data: SupplierEmployeeDto) => {
    console.log(data);
    setSaveError('');

    if (currentRole === UserDtoRolesEnum.ExternalManager && data.email === '') {
      data.email = null as any as string;
    }
    try {
      await supplierEmployeeApi.updateVisitorToSupplierEmployee(
        supplierId as string,
        supplierEmployeeId as string,
        data
      );

      dispatch(supplierEmployeeListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorState(err, setSaveError);
    }
  };

  if (
    loading ||
    supplierEmployeeState === FetchState.Loading ||
    supplierState === FetchState.Loading
  ) {
    return <Loader />;
  }

  if (
    supplierEmployeeState === FetchState.Error ||
    supplierState === FetchState.Error
  ) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="supplierEmployee.detailTitle" backTo={backTo} />
      <FormikDebounce<SupplierEmployeeDto>
        initialValues={{
          ...supplierEmployee,
          language:
            supplierEmployeeId === 'new'
              ? companyCustomization?.defaultLanguage ?? ''
              : supplierEmployee.language,
        }}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email:
            currentRole === UserDtoRolesEnum.ExternalManager
              ? validations.emailRequired(t)
              : validations.emailOptional(t),
          language: validations.stringRequired(t),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <Input
              name="name"
              label={t('supplierEmployee.name')}
              maxLength={100}
              required
            />
            <Input
              name="lastName"
              label={t('supplierEmployee.lastName')}
              maxLength={100}
              required
            />

            <Select
              onChange={(e: any) => {
                setRole(e.target.value);
              }}
              name="role"
              label={t('supplierEmployee.role')}
            >
              <QcsMenuItem value={SupplierEmployeeDtoRoleEnum.ExternalManager}>
                <SupplierEmployeeFromVisitorDetailRole>
                  <EngineeringIcon />
                  <div>
                    {t(
                      'user.role.' + SupplierEmployeeDtoRoleEnum.ExternalManager
                    )}
                  </div>
                </SupplierEmployeeFromVisitorDetailRole>
              </QcsMenuItem>
              <QcsMenuItem value={SupplierEmployeeDtoRoleEnum.ExternalWorker}>
                <SupplierEmployeeFromVisitorDetailRole>
                  <ConstructionIcon />
                  <div>
                    {t(
                      'user.role.' + SupplierEmployeeDtoRoleEnum.ExternalWorker
                    )}
                  </div>
                </SupplierEmployeeFromVisitorDetailRole>
              </QcsMenuItem>
            </Select>
            <PhoneInput name="phone" label={t('supplierEmployee.phone')} />
            <Input
              name="email"
              label={t('supplierEmployee.email')}
              maxLength={100}
              required={currentRole === UserDtoRolesEnum.ExternalManager}
            />
            <LanguageSelect
              name="language"
              label={t('user.language')}
              languages={SUPPORTED_LANGUAGES}
              allowedLanguages={companyCustomization?.applicationLanguages}
              required
            />

            <SaveError error={saveError} />
            <SubmitButton />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
