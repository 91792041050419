import {
  InvitationDtoStateEnum,
  InvitationActivityDto,
  InvitedPersonDto,
  ReferenceDto,
  UserDtoRolesEnum,
  UpdateInvitedPersonRequest,
} from '@qcs/safety-client';
import { FC, useMemo, useState } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { invitationsApi } from '../../../../utils/api';
import { useAppSelector } from '../../../../store';
import {
  InvitedPersonsAddModal,
  InvitedPersonsAddModalData,
} from './InvitedPersonsAddModal';
import { WorkplacePersonDeleteModal } from './WorkplacePersonDeleteModal';
import { hasRole } from '../../../../utils/roles';
import { useNavigate } from 'react-router';
import { selectIdentity } from '../../../../store/entities/identity';
import { getWorkplaceNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';

interface Props {
  item?: InvitationActivityDto;
  onClose: () => void;
}

export const ActivityPersonModal: FC<Props> = ({ item, onClose }) => {
  const { t, i18n, invitation, reloadInvitation, enqueueSuccessSnackbar } =
    useInvitation();

  const identity = useAppSelector(selectIdentity);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [personToDelete, setPersonToDelete] = useState<InvitedPersonDto>();
  const navigate = useNavigate();

  const list = useMemo(
    () =>
      invitation.persons?.filter((x) =>
        x.activities.some((x) => x.id === item?.activity?.id)
      ) ?? [],
    [invitation.persons, item?.activity?.id]
  );

  const handleDeletePerson = (item: InvitedPersonDto) => {
    setPersonToDelete(item);
  };

  const handleRenderData = (item: InvitedPersonDto) => {
    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell align="right">
          <QcsButton
            disabled={invitation.state === InvitationDtoStateEnum.Archived}
            onClick={(event) => {
              event.stopPropagation();
              handleDeletePerson(item);
            }}
          >
            <DeleteForeverIcon color="error" />
          </QcsButton>
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: InvitedPersonDto) => {
    if (
      hasRole(identity.roles, [
        UserDtoRolesEnum.AdminQcs,
        UserDtoRolesEnum.AdminCompany,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.Receptionist,
      ])
    ) {
      navigate(
        `/supplier/${invitation.supplier?.id}/employee/${item.employeeId}`,
        {
          state: { back: `/invitation/${invitation.id}/activity` },
        }
      );
    } else if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
      navigate(`/supplier/employee/${item.employeeId}`, {
        state: { back: `/invitation/${invitation.id}/activity` },
      });
    }
  };

  const handleAddClick = () => {
    setAddModalIsOpen(true);
  };

  const handleAddClose = async (data?: InvitedPersonsAddModalData) => {
    if (data) {
      if (!data.activities.find((x) => x.id === item?.activity?.id)) {
        data.activities.push(item!.activity as ReferenceDto);
      }

      const existingPerson = invitation.persons?.find(
        (x) => x.employeeId === data.supplierEmployee.id
      );

      if (existingPerson) {
        const existingActivities = existingPerson.activities.map((x) => x.id);
        const existingWorkplaces = existingPerson.workplaces.map((x) => x.id);

        const data2: UpdateInvitedPersonRequest = {
          activities: [
            ...existingActivities,
            ...data.activities
              .map((x) => x.id)
              .filter((x) => !existingActivities.includes(x)),
          ],
          workplaces: [
            ...existingWorkplaces,
            ...data.workplaces
              .map((x) => x.id)
              .filter((x) => !existingWorkplaces.includes(x)),
          ],
        };

        await invitationsApi.updatePerson(
          invitation.id!,
          existingPerson.id!,
          data2
        );
      } else {
        const data2: InvitedPersonDto = {
          ...data.supplierEmployee,
          id: undefined,
          activities: data.activities,
          workplaces: data.workplaces,
          employeeId: data.supplierEmployee.id,
        };
        await invitationsApi.addPerson(invitation.id!, data2);
      }
      await reloadInvitation();
    }

    setAddModalIsOpen(false);
  };

  const handleCloseDeletePersonModal = () => {
    setPersonToDelete(undefined);
  };

  const handleConfirmDelete = async () => {
    if (personToDelete!.activities.length < 2) {
      await invitationsApi.deletePerson(invitation.id!, personToDelete!.id!);
    } else {
      const data2: UpdateInvitedPersonRequest = {
        activities: personToDelete!.activities
          .map((x) => x.id)
          .filter((x) => x !== item?.activity?.id),
        workplaces: personToDelete!.workplaces.map((x) => x.id),
      };

      await invitationsApi.updatePerson(
        invitation.id!,
        personToDelete!.id!,
        data2
      );
    }

    await reloadInvitation();
    enqueueSuccessSnackbar(t('invitation.workplace.personDeleteSuccess'));
    setPersonToDelete(undefined);
  };

  return (
    <>
      <InvitedPersonsAddModal
        supplierId={invitation.supplier?.id}
        initActivities={item?.activity ? [item.activity as ReferenceDto] : []}
        initWorkplaces={
          invitation.workplaces?.map((x) => ({
            id: x.workplace!.id!,
            name: getWorkplaceNameObj(i18n, x.workplace, x.establishment),
          })) ?? []
        }
        open={addModalIsOpen}
        onClose={handleAddClose}
        excludeIds={list.map((x) => x.employeeId!)}
      />
      <WorkplacePersonDeleteModal
        onClose={handleCloseDeletePersonModal}
        open={!!personToDelete}
        confirmDelete={handleConfirmDelete}
      />

      <QcsModal open={!!item} onClose={onClose}>
        <QcsModalBox>
          <SectionHeader
            title="invitation.workplace.personTitle"
            addText="invitation.workplace.personAdd"
            handleAddClick={handleAddClick}
            addDisabled={invitation.state === InvitationDtoStateEnum.Archived}
          />
          <GridList<InvitedPersonDto>
            headers={[
              { captionStr: 'invitation.workplace.personName' },
              { captionStr: 'invitation.workplace.personLastName' },
              {},
            ]}
            data={list}
            renderData={handleRenderData}
            search={false}
            hidePagination={true}
            onRowClick={handleRowClick}
          />
        </QcsModalBox>
      </QcsModal>
    </>
  );
};
