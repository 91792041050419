import { RiskVersionDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { Grid } from '../common/grid/Grid';
import {
  getRiskVersionList,
  selectRiskVersionList,
  riskVersionListActions,
} from '../../store/entities/riskVersionList';
import { QcsTypography } from '../common/basic/QcsTypography';
import { useTranslation } from 'react-i18next';
import { CellDate } from '../common/grid/CellDate';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import styled from '@emotion/styled';
import { FaIcon } from '../common/FaIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RiskVersionDeleteModal } from './RiskVersionDeleteModal';
import { useTheme } from '@mui/material';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const RiskVersion: FC = () => {
  const { t } = useTranslation();
  const { riskId } = useParams();
  const riskVersionList = useAppSelector(selectRiskVersionList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [riskVersionToDelete, setRiskVersionToDelete] = useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    dispatch(riskVersionListActions.reload(true));
  }, [dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getRiskVersionList(riskId!, cancelToken));
  };

  const handleRenderData = (item: RiskVersionDto) => {
    return (
      <>
        <QcsTableCell>
          {item.active && (
            <FaIcon icon={faCalendarCheck} color={theme.palette.primary.main} />
          )}
        </QcsTableCell>
        <QcsTableCell>{item.validFrom}</QcsTableCell>
        <CellDate value={item.validFrom} />
        <CellDate value={item.validTo} />
        <QcsTableCell align="center">
          <QcsButton
            disabled={!item.editable || !!item.active}
            onClick={handleDeleteVersion(item.id!)}
          >
            <DeleteForeverIcon
              color={!item.editable || !!item.active ? undefined : 'error'}
            />
          </QcsButton>
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: RiskVersionDto) => {
    navigate(`/risk/${riskId}/${item.validFrom}`);
  };

  const handleAddClick = () => {
    navigate(`/risk/${riskId}/new`);
  };

  const handleDeleteVersion =
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setRiskVersionToDelete(id);
    };

  const handleCloseDeleteVersionModal = () => {
    setRiskVersionToDelete('');
  };

  const reloadRiskVersionList = () => {
    dispatch(riskVersionListActions.reload(true));
  };

  return (
    <>
      <RiskVersionDeleteModal
        open={!!riskVersionToDelete}
        onClose={handleCloseDeleteVersionModal}
        riskVersionId={riskVersionToDelete}
        reloadRiskList={reloadRiskVersionList!}
      />
      <HeaderContainer>
        <QcsTypography variant="h5">{t('risk.version')}</QcsTypography>
        <QcsButton variant="contained" onClick={handleAddClick}>
          {t('risk.addVersion')}
        </QcsButton>
      </HeaderContainer>
      <Grid<RiskVersionDto>
        headers={[
          { sx: { width: '56px' } },
          { captionStr: 'risk.version' },
          { captionStr: 'risk.validFrom', orderName: 'validFrom' },
          { captionStr: 'risk.validTo', orderName: 'validTo' },
          {
            captionStr: 'risk.deleteVersion',
            sx: { textAlign: 'center' },
          },
        ]}
        data={riskVersionList}
        gridActions={riskVersionListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
      />
    </>
  );
};
