import { FC, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getOptionsEstablishment,
  selectOptionsEstablishment,
  selectOptionsEstablishmentState,
} from '../../../store/entities/optionsEstablishment';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { SxProps, Theme } from '@mui/material';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

interface Props {
  sx?: SxProps<Theme>;
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  selectFirst?: boolean;
  showEmptyOption?: boolean;
  emptyOptionLabel?: string;
}

export const EstablishmentAutocomplete: FC<Props> = ({
  sx,
  multiple,
  name,
  label,
  disabled,
  selectFirst,
  required,
  showEmptyOption,
  emptyOptionLabel,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsEstablishment = useAppSelector(selectOptionsEstablishment);
  const optionsEstablishmentState = useAppSelector(
    selectOptionsEstablishmentState
  );

  const loadOptions = useCallback(
    (search: string | undefined, cancelToken: CancelToken) => {
      dispatch(getOptionsEstablishment(identityCompanyId, search, cancelToken));
    },
    [dispatch, identityCompanyId]
  );

  const options = useMemo(() => {
    return showEmptyOption
      ? [
          {
            id: '',
            name: emptyOptionLabel ?? i18n.t('common.showAll'),
          },
          ...optionsEstablishment,
        ]
      : optionsEstablishment;
  }, [showEmptyOption, emptyOptionLabel, i18n, optionsEstablishment]);

  return (
    <QcsAutocomplete
      sx={sx}
      multiple={multiple}
      name={name}
      label={label}
      labelWhenEmpty={emptyOptionLabel ? emptyOptionLabel : undefined}
      required={required}
      selectFirst={selectFirst}
      disabled={disabled}
      options={options}
      loading={optionsEstablishmentState === FetchState.Loading}
      loadingError={optionsEstablishmentState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option?.id ?? ''}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
      getValueIfNotFound={(option: ReferenceDto) => {
        return {
          id: '',
          name: emptyOptionLabel ?? i18n.t('common.showAll'),
        };
      }}
    />
  );
};
