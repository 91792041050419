import { UserDtoRolesEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { hasOtherRoleThan } from '../../utils/roles';
import { SectionHeader } from '../common/SectionHeader';
import { ContentWrapperColumn } from '../layout/commonLayout';
import { QcsCompanySettingsNotification } from '../qcsCompanySettings/QcsCompanySettingsNotification';
import { CompanyPages } from './CompanyPages';

export const Settings: FC = () => {
  const identity = useAppSelector(selectIdentity);
  return (
    <ContentWrapperColumn>
      <SectionHeader title="settings.title" />
      <CompanyPages />
      {hasOtherRoleThan(identity.roles, [UserDtoRolesEnum.ManagerOsah]) && (
        <QcsCompanySettingsNotification />
      )}
    </ContentWrapperColumn>
  );
};
