import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CompanyPageDto } from '@qcs/safety-client';
import { companyPagesApi } from '../../utils/api';
import { baseSlice, BaseType, getItem } from '../baseHelper';

type State = BaseType<CompanyPageDto[] | undefined>;

const initialState: State = {
  state: FetchState.None,
  data: undefined,
};

export const companyPagesSlice = baseSlice({
  name: 'companyPages',
  initialState,
});

export const selectCompanyPagesState = (state: RootState) =>
  state.companyPages.state;
export const selectCompanyPages = (state: RootState) => state.companyPages.data;
export const companyPagesActions = companyPagesSlice.actions;

export const getCompanyPages =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companyPagesApi.queryCompanyPages(
        companyId,
        undefined, //type
        undefined //language
      );
    };

    getItem({
      dispatch,
      actions: companyPagesActions,
      getData: handleGetData,
    });
  };
