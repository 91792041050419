import styled from '@emotion/styled';
import { Button, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface ButtonContentContainerProps {
  ellipsisText?: boolean;
}

const ButtonContentContainer = styled.div(
  {
    display: 'flex',
    gap: '0.35rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ ellipsisText }: ButtonContentContainerProps) =>
    ellipsisText && {
      gap: 0,
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
);

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  startIcon?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  autoFocus?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  ellipsisText?: boolean;
  children: React.ReactNode;
}

export const QcsButton: FC<Props> = ({
  className,
  sx,
  fullWidth,
  variant,
  color,
  startIcon,
  type = 'button',
  disabled,
  autoFocus,
  href,
  onClick,
  children,
  ellipsisText,
}) => {
  return (
    <Button
      className={className}
      sx={sx}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      startIcon={startIcon}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}
      href={href}
      onClick={onClick}
    >
      <ButtonContentContainer ellipsisText={ellipsisText}>
        {children}
      </ButtonContentContainer>
    </Button>
  );
};
