import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { QcsPaper } from '../common/basic/QcsPaper';

export const LoginWrapper = styled.div(() => ({
  width: '100%',
  maxWidth: '400px',
  height: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const LoginContainer = styled(QcsPaper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem',
  gap: '1.5rem',
  height: 'fit-content',
}));

export const LoginLinkContainer = styled.div(() => ({
  marginTop: '0.5rem',
}));

export const LoginLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
