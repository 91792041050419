import { CompanySettingsDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { companySettingsApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import {
  getCompanySettings,
  selectCompanySettings,
  selectCompanySettingsState,
} from '../../store/entities/companySettings';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

export const QcsCompanySettingsNotification: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companySettings = useAppSelector(selectCompanySettings);
  const companySettingsState = useAppSelector(selectCompanySettingsState);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    dispatch(getCompanySettings(identityCompanyId));
    setLoading(false);
  }, [dispatch, identityCompanyId]);

  const handleSubmit = async (data: CompanySettingsDto) => {
    setSaveError('');
    try {
      await companySettingsApi.updateSettings(identityCompanyId, data);
      enqueueSuccessSnackbar(t('common.editSuccess'));
      dispatch(getCompanySettings(identityCompanyId));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
  };

  if (loading || companySettingsState === FetchState.Loading) {
    return <Loader />;
  }

  if (companySettingsState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <SectionHeader title="qcsCompanySettings.notification" />
      <FormikDebounce<CompanySettingsDto>
        initialValues={companySettings}
        validationSchema={Yup.object().shape({
          smtpPort: validations.numberOptional(),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <Input name="smtpHost" label={t('qcsCompanySettings.smtpHost')} />
            <Input
              name="smtpPort"
              label={t('qcsCompanySettings.smtpPort')}
              type="number"
            />
            <Input
              name="smtpUsername"
              label={t('qcsCompanySettings.smtpUsername')}
            />
            <Input
              name="smtpPassword"
              type="password"
              label={t('qcsCompanySettings.smtpPassword')}
            />
            <Input name="sender" label={t('qcsCompanySettings.sender')} />

            <SaveError error={saveError} />
            <SubmitButton />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
