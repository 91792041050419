import { MenuItem } from '@mui/material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  className?: string;
  selected?: boolean;
  autoFocus?: boolean;
  href?: string;
  useNavLink?: boolean;
  value?: any;
  navLinkClassName?: string;
  disabled?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLAnchorElement>;
  onClick?: () => void;
  children: React.ReactNode;
}

export const QcsMenuItem: FC<Props> = ({
  className,
  selected,
  autoFocus,
  href,
  useNavLink,
  value,
  navLinkClassName,
  disabled,
  onKeyUp,
  onClick,
  children,
}) => {
  return (
    <MenuItem
      className={className}
      selected={selected}
      autoFocus={autoFocus}
      onKeyUp={onKeyUp}
      onClick={onClick}
      href={href}
      value={value}
      disabled={disabled}
      component={
        useNavLink
          ? React.forwardRef<HTMLAnchorElement, any>(
              ({ href, children, className, ...rest }, ref) => {
                return (
                  <NavLink
                    to={href}
                    ref={ref}
                    className={classNames(className, navLinkClassName)}
                    end={!href}
                    {...rest}
                  >
                    {children}
                  </NavLink>
                );
              }
            )
          : 'a'
      }
    >
      {children}
    </MenuItem>
  );
};
