import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  QuestionnaireDto,
  QuestionnaireDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { questionnairesApi } from '../../utils/api';

export interface QuestionnaireListFilter extends ListFilter {
  state?: QuestionnaireDtoStateEnum;
}

export type QuestionnaireListState = ListType<
  QuestionnaireDto,
  QuestionnaireListFilter
>;

const initialState: QuestionnaireListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: QuestionnaireDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const questionnaireListSlice = listSlice({
  name: 'questionnaireList',
  initialState,
});

export const selectQuestionnaireList = (state: RootState) =>
  state.questionnaireList;
export const questionnaireListActions = questionnaireListSlice.actions;

export const getQuestionnaireList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectQuestionnaireList(storeState);

    const handleGetData = (size: number) => {
      return questionnairesApi.queryQuestionnaires(
        companyId,
        data.query.q,
        data.query.state,
        undefined, //skipId
        undefined, //nameOnly
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: questionnaireListActions,
      getData: handleGetData,
    });
  };
