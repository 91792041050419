import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { TrainingDto, TrainingDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { trainingsApi } from '../../utils/api';

type State = BaseType<TrainingDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    state: TrainingDtoStateEnum.Active,
    workplaces: [],
    note: '',
    duration: 0,
  },
};

export const trainingSlice = baseSlice({
  name: 'training',
  initialState,
});

export const selectTrainingState = (state: RootState) => state.training.state;
export const selectTraining = (state: RootState) => state.training.data;
export const trainingActions = trainingSlice.actions;

export const getTraining = (trainingId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return trainingsApi.getById(trainingId);
  };

  getItem({
    dispatch,
    actions: trainingActions,
    getData: handleGetData,
  });
};
