import { useFormikContext } from 'formik';
import { FC } from 'react';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormControl } from '../basic/QcsFormControl';
import _ from 'lodash';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { QcsRadioGroup } from '../basic/QcsRadioGroup';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  children: React.ReactNode;
}

export const RadioGroup: FC<Props> = ({ className, name, label, children }) => {
  const { values, errors, handleChange } = useFormikContext<any>();

  const value = _.get(values, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  return (
    <QcsFormControl error={hasError}>
      <QcsInputLabel>{label}</QcsInputLabel>
      <QcsRadioGroup
        className={className}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {children}
      </QcsRadioGroup>
      <QcsFormHelperText>{errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
