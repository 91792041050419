import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelect } from './GridFilterSelect';

const options = [true, false];

interface Props {
  value?: boolean;
  onChange: (value?: boolean) => void;
}

export const GridFilterBoolean: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <GridFilterSelect
      value={value}
      options={options}
      onChange={onChange}
      getOptionId={(option: boolean) => (option ? 'yes' : 'no')}
      getOptionName={(option: boolean) => t(`common.${option ? 'yes' : 'no'}`)}
    />
  );
};
