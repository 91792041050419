import { FC } from 'react';
import { useAppSelector } from '../../store';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import { selectTourniquetTraining } from '../../store/entities/tourniquetTraining';
import { TourniquetHeaderTraining } from './TourniquetHeaderTraining';

export const TourniquetHeader: FC = () => {
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);

  if (!tourniquetIdentity.data) {
    return null;
  }

  if (tourniquetTraining.training.id) {
    return <TourniquetHeaderTraining />;
  }

  return null;
};
