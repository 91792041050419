import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import { TourniquetEnter } from './TourniquetEnter';
import { TourniquetInvitation } from './TourniquetInvitation';
import { TourniquetInvitationList } from './TourniquetInvitationList';
import { TourniquetTraining } from './TourniquetTraining';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';

export const TourniquetRoutes: FC = () => {
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const dispatch = useAppDispatch();

  if (!tourniquetIdentity.allData) {
    return <TourniquetEnter />;
  }

  if (!tourniquetIdentity.data) {
    return <TourniquetInvitationList />;
  }

  if (!tourniquetTraining.training.id) {
    return <TourniquetInvitation />;
  }

  const handleEndTraining = (success?: boolean) => {
    dispatch(tourniquetTrainingActions.default());

    if (success) {
      dispatch(tourniquetTrainingsActions.default());
    }
  };

  return <TourniquetTraining onEndTraining={handleEndTraining} />;
};
