import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { DashboardResponse } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { dashboardApi } from '../../utils/api';

type State = BaseType<DashboardResponse>;

const initialState: State = {
  state: FetchState.None,
  data: {},
};

export const dashboardSlice = baseSlice({
  name: 'dashboard',
  initialState,
});

export const selectDashboardState = (state: RootState) => state.dashboard.state;
export const selectDashboard = (state: RootState) => state.dashboard.data;
export const dashboardActions = dashboardSlice.actions;

export const getDashboard =
  (companyId: string, establishmentId: string | undefined) =>
  (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return dashboardApi.getData(
        companyId,
        establishmentId, //establishmentForStats
        establishmentId //establishmentForAllowedToEnter
      );
    };

    getItem({
      dispatch,
      actions: dashboardActions,
      getData: handleGetData,
    });
  };
