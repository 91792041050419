import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getOptionsTraining,
  selectOptionsTraining,
  selectOptionsTrainingState,
} from '../../../store/entities/optionsTraining';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { TrainingDto } from '@qcs/safety-client';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import { getLangNameObj } from '../../../utils/format';

interface Props {
  skipId?: string;
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  excludeIds?: string[];
  ignoreWithoutQuestionnaireWithLanguage?: boolean;
}

export const TrainingAutocomplete: FC<Props> = ({
  skipId,
  multiple,
  name,
  label,
  disabled,
  required,
  excludeIds: filterIds,
  ignoreWithoutQuestionnaireWithLanguage = false,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsTraining = useAppSelector(selectOptionsTraining);
  const optionsTrainingState = useAppSelector(selectOptionsTrainingState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsTraining(
        identityCompanyId,
        search,
        skipId,
        cancelToken,
        ignoreWithoutQuestionnaireWithLanguage
      )
    );
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={
        filterIds
          ? optionsTraining.filter((x) => !filterIds?.includes(x.id!))
          : optionsTraining
      }
      loading={optionsTrainingState === FetchState.Loading}
      loadingError={optionsTrainingState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: TrainingDto) => option.id!}
      getOptionName={(option: TrainingDto) => getLangNameObj(i18n, option)}
    />
  );
};
