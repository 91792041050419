import { UserDtoRolesEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelectMultiple } from './GridFilterSelectMultiple';

const exludedRoles: UserDtoRolesEnum[] = [
  UserDtoRolesEnum.AdminQcs,
  UserDtoRolesEnum.ExternalManager,
  UserDtoRolesEnum.ExternalWorker,
  UserDtoRolesEnum.ExternalOneTime,
  UserDtoRolesEnum.RemoteToken,
];

const options = Object.values(UserDtoRolesEnum).filter(
  (x) => !exludedRoles.includes(x)
);

interface Props {
  value?: UserDtoRolesEnum[];
  onChange: (value?: UserDtoRolesEnum) => void;
}

export const GridFilterUserRole: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  if (!value) return null;

  return (
    <GridFilterSelectMultiple
      value={value}
      options={options}
      onChange={onChange}
      getOptionId={(option: UserDtoRolesEnum) => option}
      getOptionName={(option: UserDtoRolesEnum) => t(`user.role.${option}`)}
    />
  );
};
