import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { InvitationDto, InvitationDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { invitationsApi } from '../../utils/api';

type State = BaseType<InvitationDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    state: InvitationDtoStateEnum.InProgress,
    validFrom: '',
  },
};

export const invitationSlice = baseSlice({
  name: 'invitation',
  initialState,
});

export const selectInvitationState = (state: RootState) =>
  state.invitation.state;
export const selectInvitation = (state: RootState) => state.invitation.data;
export const invitationActions = invitationSlice.actions;

export const getInvitation =
  (
    invitationId: string,
    changeData: (data: InvitationDto) => Promise<InvitationDto>
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return invitationsApi.getInvitationById(invitationId);
    };

    getItem({
      dispatch,
      actions: invitationActions,
      getData: handleGetData,
      changeData,
    });
  };
