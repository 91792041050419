import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { supplierEmployeeApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<SupplierEmployeeDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsSupplierEmployeeSlice = baseSlice({
  name: 'optionsSupplierEmployee',
  initialState,
});

export const selectOptionsSupplierEmployeeState = (state: RootState) =>
  state.optionsSupplierEmployee.state;
export const selectOptionsSupplierEmployee = (state: RootState) =>
  state.optionsSupplierEmployee.data;
export const optionsSupplierEmployeeActions =
  optionsSupplierEmployeeSlice.actions;

export const getOptionsSupplierEmployee =
  (
    supplierId: string,
    search: string | undefined,
    role: SupplierEmployeeDtoRoleEnum | undefined,
    state: SupplierEmployeeDtoStateEnum[] | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      //Load whole entity for create invitation.
      const response = await supplierEmployeeApi.querySupplierEmployees(
        supplierId,
        search,
        state,
        role,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsSupplierEmployeeActions,
      getData: handleGetData,
    });
  };
