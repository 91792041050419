import {
  EmployeeDto,
  EstablishmentDto,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  establishmentActions,
  getEstablishment,
  selectEstablishment,
  selectEstablishmentState,
} from '../../store/entities/establishment';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { Input } from '../common/form/Input';
import { establishmentApi } from '../../utils/api';
import { establishmentListActions } from '../../store/entities/establishmentList';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { FormContainer } from '../common/form/FormContainer';
import { DetailHeader } from '../common/DetailHeader';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormGroup } from '../common/form/FormGroup';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateEstablishment,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { PhoneInput } from '../common/form/PhoneInput';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { EstablishmentDetailFromEmployeeModal } from './EstablishmentDetailFromEmployeeModal';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType } from '../../models/common';
import { fixNameInitValue } from '../../utils/format';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

type Validator = {
  name?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameEn?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameRu?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameDe?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  namePl?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  address: Yup.ObjectSchema<
    {
      street: string;
      city: string;
      postalCode: string;
      country: string;
    },
    any,
    {
      street: undefined;
      city: undefined;
      postalCode: undefined;
      country: undefined;
    },
    ''
  >;
  contact: Yup.ObjectSchema<
    {
      name: string;
      lastName: string;
      phone: string;
      email: string;
    },
    any,
    {
      name: undefined;
      lastName: undefined;
      phone: undefined;
      email: undefined;
    },
    ''
  >;
};
interface EstablishmentDetailForm extends EstablishmentDto {
  saveAndNew?: boolean;
}

export const EstablishmentDetail: FC = () => {
  const { i18n, t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/establishment');
  const { establishmentId } = useParams();
  const establishment = useAppSelector(selectEstablishment);
  const establishmentState = useAppSelector(selectEstablishmentState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateEstablishment = useAppSelector(
    selectCompanyChecksCanCreateEstablishment
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const [loadFromEmployee, setLoadFromEmployee] = useState(false);
  const formikRef = useRef<FormikProps<EstablishmentDto> | null>(null);
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    if (establishmentId === 'new') {
      dispatch(establishmentActions.default());
      dispatch(getCompanyChecks(identityCompanyId!));
    } else {
      dispatch(getEstablishment(establishmentId!));
    }
    setLoading(false);
  }, [establishmentId, identityCompanyId, dispatch]);

  const handleSubmit = async (data: EstablishmentDetailForm) => {
    setError('');

    try {
      if (establishmentId === 'new') {
        await establishmentApi.createEstablishment(identityCompanyId, data);
        enqueueSuccessSnackbar(t('establishment.addSuccess'));
      } else {
        await establishmentApi.updateEstablishment(establishmentId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(establishmentListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
        dispatch(getCompanyChecks(identityCompanyId!));
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setError,
        enqueueErrorSnackbar,
        establishmentId === 'new'
          ? 'establishment.addError'
          : 'common.editError'
      );
    }
  };

  const handleShowLoadFromEmployee = () => {
    setLoadFromEmployee(true);
  };

  const handleCloseLoadFromEmployee = (employee?: EmployeeDto) => {
    if (employee) {
      formikRef.current?.setFieldValue('contact.name', employee.name);
      formikRef.current?.setFieldValue('contact.lastName', employee.lastName);
      formikRef.current?.setFieldValue('contact.phone', employee.phone);
      formikRef.current?.setFieldValue('contact.email', employee.email);
    }

    setLoadFromEmployee(false);
  };

  if (
    loading ||
    establishmentState === FetchState.Loading ||
    (establishmentId === 'new' && companyChecksState === FetchState.Loading)
  ) {
    return <Loader />;
  }

  if (
    establishmentState === FetchState.Error ||
    (establishmentId === 'new' && companyChecksState === FetchState.Error)
  ) {
    return <ErrorAlert />;
  }

  const validator: Validator = {
    address: Yup.object({
      street: validations.stringRequired(t),
      city: validations.stringRequired(t),
      postalCode: validations.postalCodeRequired(t),
      country: validations.stringRequired(t),
    }),
    contact: Yup.object({
      name: validations.stringRequired(t),
      lastName: validations.stringRequired(t),
      phone: validations.phoneRequired(t),
      email: validations.emailRequired(t),
    }),
  };

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }

  return (
    <>
      <EstablishmentDetailFromEmployeeModal
        open={loadFromEmployee}
        onClose={handleCloseLoadFromEmployee}
      />

      <DetailHeader title={t('establishment.detailTitle')} backTo={backTo} />
      <FormikDebounce<EstablishmentDetailForm>
        innerRef={formikRef}
        initialValues={{
          ...establishment,
          ...fixNameInitValue(i18n, establishment),
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={establishmentId === 'new'}
              label={t('establishment.name')}
              required
            />
            <Select
              name="state"
              label={t('establishment.state')}
              disabled={!establishment.stateChangeEnabled}
            >
              <QcsMenuItem value={EstablishmentDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={EstablishmentDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>
            <FormGroup name="address.title">
              <Input
                name="address.street"
                label={t('address.street')}
                maxLength={100}
                required
              />
              <Input
                name="address.city"
                label={t('address.city')}
                maxLength={100}
                required
              />
              <Input
                name="address.postalCode"
                label={t('address.postalCode')}
                maxLength={128}
                required
              />
              <Input
                name="address.country"
                label={t('address.country')}
                maxLength={100}
                required
              />
            </FormGroup>

            <FormGroup name="establishment.contactPerson">
              <QcsBox
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'auto',
                    sm: 'auto max-content',
                  },
                  gridTemplateRows: { xs: 'auto auto', sm: 'auto' },
                  rowGap: { xs: '1rem', sm: 0 },
                  columnGap: { xs: 0, sm: '1rem' },
                }}
              >
                <Input
                  name="contact.name"
                  label={t('establishment.contactPersonName')}
                  maxLength={100}
                  required
                />
                <QcsButton
                  onClick={handleShowLoadFromEmployee}
                  variant="contained"
                  sx={{ height: '3.5rem' }}
                >
                  {t('establishment.loadFromEmployeeButton')}
                </QcsButton>
              </QcsBox>

              <Input
                name="contact.lastName"
                label={t('establishment.contactPersonLastName')}
                maxLength={100}
                required
              />
              <PhoneInput
                name="contact.phone"
                label={t('establishment.phone')}
                required
              />
              <Input
                name="contact.email"
                label={t('establishment.email')}
                maxLength={100}
                required
              />
            </FormGroup>
            <Input
              name="note"
              label={t('establishment.note')}
              multiline={true}
              rows={4}
            />
            <SubmitAndNewButtons
              id={establishmentId}
              error={error}
              disabled={
                establishmentId === 'new' &&
                !companyChecksCanCreateEstablishment
              }
            />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
