import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { SlideDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { trainingsApi } from '../../utils/api';

type State = BaseType<Partial<SlideDto[]>>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const trainingSlidesSlice = baseSlice({
  name: 'trainingSlides',
  initialState,
});

export const selectTrainingSlidesState = (state: RootState) =>
  state.trainingSlides.state;
export const selectTrainingSlides = (state: RootState) =>
  state.trainingSlides.data;
export const trainingSlidesActions = trainingSlidesSlice.actions;

export const getTrainingSlides =
  (trainingVersionId: string, languageCode: string) =>
  (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return trainingsApi.getSlides(trainingVersionId, languageCode);
    };

    getItem({
      dispatch,
      actions: trainingSlidesActions,
      getData: handleGetData,
    });
  };
