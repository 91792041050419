import { QuestionnaireDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  questionnaireListActions,
  getQuestionnaireList,
  selectQuestionnaireList,
} from '../../store/entities/questionnaireList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { QuestionnaireFilter } from './QuestionnaireFilter';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsTooltipContent } from '../common/basic/QcsTooltipContent';
import { getLangNameObj } from '../../utils/format';

export const Questionnaire: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const questionnaireList = useAppSelector(selectQuestionnaireList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getQuestionnaireList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: QuestionnaireDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: QuestionnaireDto) => {
    navigate(`/questionnaire/${item.id}`);
  };

  const handleAddClick = () => {
    navigate('/questionnaire/new');
  };

  const handleGetRowTooltip = (item: QuestionnaireDto) => {
    if (item.trainings?.length === 0 && !item.note) return '';
    return (
      <QcsTooltipContent>
        {item.trainings?.length !== 0 && (
          <>
            <QcsTypography variant="h6">
              {t('questionnaire.trainings')}
            </QcsTypography>
            {item.trainings?.map((training) => (
              <QcsTypography variant="body2">
                {getLangNameObj(i18n, training)}
              </QcsTypography>
            ))}
          </>
        )}
        {item.note && (
          <>
            <QcsTypography variant="h6">
              {t('questionnaire.note')}
            </QcsTypography>
            <QcsTypography variant="body2">{item.note}</QcsTypography>
          </>
        )}
      </QcsTooltipContent>
    );
  };

  return (
    <>
      <SectionHeader
        title="questionnaire.title"
        addText="questionnaire.add"
        handleAddClick={handleAddClick}
      />
      <Grid<QuestionnaireDto>
        headers={[
          { captionStr: 'questionnaire.name', orderName: 'name' },
          {
            captionStr: 'questionnaire.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={questionnaireList}
        gridActions={questionnaireListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        getRowTooltip={handleGetRowTooltip}
        filter={<QuestionnaireFilter query={questionnaireList.query} />}
      />
    </>
  );
};
