import { WorkplaceDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  workplaceListActions,
  getWorkplaceList,
  selectWorkplaceList,
} from '../../store/entities/workplaceList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CellYesNo } from '../common/grid/CellYesNo';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { WorkplaceFilter } from './WorkplaceFilter';
import { QcsTooltipContent } from '../common/basic/QcsTooltipContent';
import { QcsTypography } from '../common/basic/QcsTypography';
import { getLangNameObj } from '../../utils/format';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

export const Workplace: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const workplaceList = useAppSelector(selectWorkplaceList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getWorkplaceList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: WorkplaceDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>{getLangNameObj(i18n, item.establishment)}</QcsTableCell>
        {companyCustomization?.passRisksEnabled && (
          <CellYesNo value={item.riskRequired} hideOnMobile={true} />
        )}
        <QcsTableCell hideOnMobile={true}>
          {item.numberOfTrainings}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: WorkplaceDto) => {
    navigate(`/workplace/${item.id}`);
  };

  const handleAddClick = () => {
    navigate('/workplace/new');
  };

  const handleGetRowTooltip = (item: WorkplaceDto) => {
    if (!item.note) return '';
    return (
      <QcsTooltipContent>
        <QcsTypography variant="h6">{t('workplace.note')}</QcsTypography>
        <QcsTypography variant="body2">{item.note}</QcsTypography>
      </QcsTooltipContent>
    );
  };

  return (
    <>
      <SectionHeader
        title="workplace.title"
        addText="workplace.add"
        handleAddClick={handleAddClick}
      />
      <Grid<WorkplaceDto>
        headers={[
          { captionStr: 'workplace.name', orderName: 'name' },
          {
            captionStr: 'workplace.establishment',
            orderName: 'establishment',
          },
          companyCustomization?.passRisksEnabled
            ? {
                captionStr: 'workplace.riskRequired',
                orderName: 'riskRequired',
                hideOnMobile: true,
              }
            : undefined,
          {
            captionStr: 'workplace.numberOfTrainings',
            hideOnMobile: true,
          },
          {
            captionStr: 'workplace.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={workplaceList}
        gridActions={workplaceListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        getRowTooltip={handleGetRowTooltip}
        filter={<WorkplaceFilter query={workplaceList.query} />}
      />
    </>
  );
};
