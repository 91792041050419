import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorStateType } from '../../../models/common';
import { QcsLoadingButton } from '../basic/QcsLoadingButton';
import { SaveError } from '../SaveError';
import { SubmitButton } from './SubmitButton';
import { SubmitButtonsContainer } from './SubmitButtonsContainer';

interface SubmitAndNewButtonsModeButtonProps {
  disabled?: boolean;
}

const SubmitAndNewButtonsModeButton: FC<SubmitAndNewButtonsModeButtonProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue, submitForm } = useFormikContext();

  const handleClick = async () => {
    setFieldValue('saveAndNew', true);
    try {
      await submitForm();
    } finally {
      setFieldValue('saveAndNew', false);
    }
  };

  return (
    <QcsLoadingButton
      variant="contained"
      disabled={disabled || isSubmitting}
      loading={isSubmitting}
      onClick={handleClick}
    >
      {t('common.saveAndNew')}
    </QcsLoadingButton>
  );
};

interface Props {
  id?: string;
  error?: ErrorStateType;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const SubmitAndNewButtons: FC<Props> = ({
  id,
  error,
  disabled,
  children,
}) => {
  return (
    <>
      <SaveError error={error} />
      <SubmitButtonsContainer>
        <SubmitButton disabled={disabled} />
        {id === 'new' && <SubmitAndNewButtonsModeButton disabled={disabled} />}
        {children}
      </SubmitButtonsContainer>
    </>
  );
};
