import { ListItemText } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  hidden?: boolean;
  children: React.ReactNode;
}

export const QcsListItemText: FC<Props> = ({ className, hidden, children }) => {
  return (
    <ListItemText className={className} hidden={hidden}>
      {children}
    </ListItemText>
  );
};
