import { CancelToken } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { SectionHeader } from '../common/SectionHeader';
import { Grid } from '../common/grid/Grid';
import {
  getInvitationTypeVisitList,
  invitationTypeVisitListActions,
  selectInvitationTypeVisitList,
} from '../../store/entities/invitationTypeVisitList';
import { InvitationTypeVisitListResponse } from '@qcs/safety-client';
import { CellDate } from '../common/grid/CellDate';
import { InvitationModal } from './InvitationModal';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FaIcon } from '../common/FaIcon';
import { supplierEmployeeApi } from '../../utils/api';

interface InvitationTypeVisitData extends InvitationTypeVisitListResponse {
  id?: string;
}

export const InvitationTypeVisit = () => {
  const [open, setOpen] = useState(false);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const invitationTypeVisitList = useAppSelector(selectInvitationTypeVisitList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedInvitationData, setSelectedInvitationData] =
    useState<InvitationTypeVisitData | null>(null);
  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getInvitationTypeVisitList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: InvitationTypeVisitData) => {
    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell>{item.company}</QcsTableCell>
        <QcsTableCell>{item.email}</QcsTableCell>
        <QcsTableCell>{item.phone}</QcsTableCell>
        <CellDate value={item.lastEntry} showWithTime={true} />

        <QcsTableCell>
          <QcsButton
            onClick={(e) => {
              e.stopPropagation();
              setSelectedInvitationData(item);
              console.log('item', item);
              setOpen(true);
            }}
          >
            <FaIcon icon={faUserPlus} />
          </QcsButton>
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: InvitationTypeVisitData) => {
    navigate(`/visit/${item.supplierId}/employee/${item.supplierEmployeeId}`);
  };
  const handleCreateSupplierEmployee = async (supplier: string) => {
    const data = await supplierEmployeeApi.getSupplierEmployee(
      selectedInvitationData?.supplierEmployeeId || ''
    );

    if (data.data.supplier && !data.data.oneTime) {
      alert('This user is already a supplier employee.');
    } else {
      const url = `/supplier/${supplier}/employee-from-visitor/${selectedInvitationData?.supplierEmployeeId}`;

      const params = new URLSearchParams();
      selectedInvitationData?.name &&
        params.append('name', selectedInvitationData?.name);
      selectedInvitationData?.lastName &&
        params.append('lastName', selectedInvitationData?.lastName);
      selectedInvitationData?.email &&
        params.append('email', selectedInvitationData?.email);
      selectedInvitationData?.phone &&
        params.append('phone', selectedInvitationData?.phone);
      navigate(url + '?' + params.toString());
    }
  };

  return (
    <>
      <SectionHeader title="invitationTypeVisit.title" />
      <InvitationModal
        open={open}
        onClose={() => setOpen(false)}
        onSupplierSelectClick={(supplierEmployee) => {
          handleCreateSupplierEmployee(supplierEmployee);
        }}
      />
      <Grid<InvitationTypeVisitData>
        headers={[
          {
            captionStr: 'invitationTypeVisit.name',
            orderName: 'responsiblePerson.name',
          },
          {
            captionStr: 'invitationTypeVisit.lastName',
            orderName: 'responsiblePerson.lastName',
          },
          {
            captionStr: 'invitationTypeVisit.company',
            orderName: 'supplier',
          },
          {
            captionStr: 'invitationTypeVisit.email',
            orderName: 'responsiblePerson.email',
          },
          {
            captionStr: 'invitationTypeVisit.phone',
            orderName: 'responsiblePerson.phone',
          },
          {
            captionStr: 'invitationTypeVisit.lastEntry',
            orderName: 'responsiblePerson.lastEntry',
          },
          {
            captionStr: '',
          },
        ]}
        data={invitationTypeVisitList}
        gridActions={invitationTypeVisitListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
      />
    </>
  );
};
