import {
  QuestionnaireDto,
  QuestionnaireVersionDto,
  ReferenceDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { questionnairesApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { DatePicker } from '../common/form/DatePicker';
import { questionnaireVersionListActions } from '../../store/entities/questionnaireVersionList';
import { QcsTextField } from '../common/basic/QcsTextField';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { QuestionnaireLanguages } from './QuestionnaireLanguages';
import {
  getCustomError,
  setErrorSnacks,
  setErrorStateSnacks,
} from '../../utils/error';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { isDateDisabled } from '../../utils/date';
import styled from '@emotion/styled';
import { selectIdentity } from '../../store/entities/identity';
import { TourniquetTraining } from '../../models/tourniquet';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import {
  tourniquetGetAfterTrainingContent,
  tourniquetGetInvitationPerson,
  tourniquetGetLang,
} from '../tourniquet/tourniquetFunctions';
import { tourniquetQuestionnaireActions } from '../../store/entities/tourniquetQuestionnaire';
import { tourniquetTrainingSlidesActions } from '../../store/entities/tourniquetTrainingSlides';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

interface Props {
  questionnaire: QuestionnaireDto;
  questionnaireVersion: QuestionnaireVersionDto;
  questionnaireVersionId: string;
}

export const QuestionnaireVersionDetailData: FC<Props> = ({
  questionnaire,
  questionnaireVersion,
  questionnaireVersionId,
}) => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack(`/questionnaire/${questionnaire.id}`);
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const dispatch = useAppDispatch();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [isPlaygroundLoading, setIsPlaygroundLoading] = useState(false);

  const handleSubmit = async (data: QuestionnaireVersionDto) => {
    setSaveError('');

    try {
      if (questionnaireVersionId === 'new') {
        await questionnairesApi.createQuestionnaireVersion(
          questionnaire.id!,
          data
        );
        enqueueSuccessSnackbar(t('training.addVersionSuccess'));
      } else {
        await questionnairesApi.updateVersionOfQuestionnaire(
          questionnaireVersionId!,
          data
        );
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(questionnaireVersionListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        questionnaireVersionId === 'new'
          ? 'training.addVersionError'
          : 'common.editError'
      );
    }
  };

  const handleRunPlayground = async () => {
    setIsPlaygroundLoading(true);
    try {
      const invitation: ReferenceDto = { id: '', name: '', nameEn: '' };
      const invitationPerson: ReferenceDto = {
        id: identity.id,
        name: identity.name,
      };
      const invitationPerson2 = tourniquetGetInvitationPerson({
        ...invitationPerson,
        email: identity.email,
      });
      const allPersons: ReferenceDto[] = [invitationPerson];

      const trainingData: TourniquetTraining = {
        training: {
          id: questionnaireVersion.id!,
          name: '',
          nameEn: '',
          languages: SUPPORTED_LANGUAGES.filter(
            (x) => x.code === i18n.resolvedLanguage
          ),
        },
        questionnaire: {
          id: questionnaireVersion.id!,
          name: questionnaire.name,
          nameEn: questionnaire.nameEn,
          languages: questionnaireVersion.languages ?? [],
        },
        index: 0,
        mode: 'questionnaire',
        answerResponse: undefined,
        allPersons: allPersons.map((x) => x.id),
        signQuestionnaire: allPersons,
      };

      const trainingLang: any = tourniquetGetLang(
        'noLanguageSlides',
        companyCustomization!,
        trainingData.training,
        undefined
      );

      const afterTrainingContent = await tourniquetGetAfterTrainingContent(
        identity.companyId,
        trainingLang
      );

      //Load questionnaire.
      const questionnaireLang: any = tourniquetGetLang(
        'noLanguageQuestionnaire',
        companyCustomization!,
        trainingData.questionnaire!,
        trainingLang
      );

      const resQuestionnaireQuestions: any =
        await questionnairesApi.getQuestions(
          questionnaireVersion.id!,
          questionnaireLang
        );

      //No questions - error.
      if (resQuestionnaireQuestions.data.length === 0) {
        throw getCustomError('noQuestions');
      }

      dispatch(
        tourniquetQuestionnaireActions.success({
          language: trainingData.questionnaire!.languages.find(
            (x) => x.code === questionnaireLang
          ),
          successRate: questionnaireVersion.successRate,
          questions: resQuestionnaireQuestions.data
            .sort(
              (a: { position: any }, b: { position: any }) =>
                (a.position ?? 0) - (b.position ?? 0)
            )
            .map((x: any) => ({ ...x, answerResponse: undefined })),
        })
      );

      dispatch(
        tourniquetTrainingSlidesActions.success({
          language: trainingData.training.languages.find(
            (x) => x.code === trainingLang
          ),
          slides: [],
          afterTrainingContent,
        })
      );
      dispatch(tourniquetTrainingActions.success(trainingData));

      dispatch(
        tourniquetIdentityActions.success({
          allData: [],
          data: {
            invitation,
          },
          playground: true,
        })
      );

      dispatch(
        tourniquetTrainingsActions.success({
          invitationPerson: invitationPerson2,
          trainings: [
            {
              id: questionnaireVersion.id!,
            },
          ],
        })
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsPlaygroundLoading(false);
  };

  return (
    <>
      <DetailHeader title="questionnaire.version" backTo={backTo} />
      <FormikDebounce<QuestionnaireVersionDto>
        initialValues={questionnaireVersion}
        validationSchema={
          questionnaireVersion.editable
            ? Yup.object().shape({
                validFrom: questionnaireVersion.first
                  ? validations.dateRequired(t)
                  : validations.stringDateNotPastOrTodayRequired(t),
                successRate: validations.intRequiredMinMax(1, 100, t),
              })
            : undefined
        }
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <QcsTextField
              sx={{ backgroundColor: (theme) => theme.palette.common.white }}
              value={getLangNameObj(i18n, questionnaire)}
              label={t('questionnaire.detailTitle')}
              disabled
            />

            <DatePicker
              name="validFrom"
              label={t('questionnaire.validFrom')}
              disablePast
              disableHighlightToday
              shouldDisableDate={isDateDisabled}
              required
              disabled={
                !questionnaireVersion.editable || !!questionnaireVersion.first
              }
            />
            <DatePicker
              name="validTo"
              label={t('questionnaire.validTo')}
              disabled
            />

            <Input
              name="successRate"
              label={t('questionnaire.minSuccessRate')}
              type="number"
              disabled={!questionnaireVersion.editable}
            />

            <Input
              name="note"
              label={t('questionnaire.versionNote')}
              maxLength={250}
              multiline={true}
              rows={4}
              disabled={!questionnaireVersion.editable}
            />

            <SaveError error={saveError} />
            <ButtonsContainer>
              {questionnaireVersionId !== 'new' && (
                <QcsLoadingButton
                  variant="outlined"
                  onClick={handleRunPlayground}
                  loading={isPlaygroundLoading}
                  disabled={(questionnaireVersion.languages?.length ?? 0) < 1}
                >
                  {t('questionnaire.runPlayground')}
                </QcsLoadingButton>
              )}
              <SubmitButton disabled={!questionnaireVersion.editable} />
            </ButtonsContainer>
          </FormContainer>
        </Form>
      </FormikDebounce>

      {questionnaireVersionId !== 'new' && <QuestionnaireLanguages />}
    </>
  );
};
