import styled from '@emotion/styled';
import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface DraggableContainerProps {
  isDragging: boolean;
  selected?: boolean;
}

const DraggableContainer = styled.div<DraggableContainerProps>(
  ({ theme, isDragging, selected }) => ({
    userSelect: 'none',
    padding: '1rem',
    marginBottom: '1rem',
    backgroundColor: selected
      ? isDragging
        ? theme.palette.primary.dark
        : theme.palette.primary.main
      : isDragging
      ? theme.palette.secondary.dark
      : theme.palette.secondary.main,
    color: selected
      ? theme.palette.primary.contrastText
      : theme.palette.secondary.contrastText,
    fontFamily: theme.typography.fontFamily,
  })
);

interface Props {
  id: string;
  title: string;
  index: number;
  selected?: boolean;
  isDragDisabled?: boolean;
  onClick: (id: string) => void;
}

export const SlideListItem: FC<Props> = ({
  index,
  id,
  title,
  selected,
  isDragDisabled,
  onClick,
}) => {
  const handleItemClick = (id: string) => () => onClick(id);

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <DraggableContainer
          key={id}
          isDragging={snapshot.isDragging}
          selected={selected}
          onClick={handleItemClick(id)}
          ref={provided.innerRef}
          style={provided.draggableProps.style}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {title}
        </DraggableContainer>
      )}
    </Draggable>
  );
};
