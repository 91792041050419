import { AnswerDto, AnswerResponseTypeEnum } from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { ErrorStateType } from '../../models/common';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import {
  selectTourniquetQuestionnaire,
  tourniquetQuestionnaireActions,
} from '../../store/entities/tourniquetQuestionnaire';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import { invitationsApi } from '../../utils/api';
import { setErrorStateSnacks } from '../../utils/error';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { SaveError } from '../common/SaveError';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import {
  TourniquetAnswersContainer,
  TourniquetCard,
  TourniquetContent,
  TourniquetTrainingRow,
} from './TourniquetStyles';

interface Props {
  onEndTraining: () => void;
}

export const TourniquetTrainingQuestionnaire: FC<Props> = ({
  onEndTraining,
}) => {
  const { t } = useTranslation();
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const tourniquetQuestionnaire = useAppSelector(selectTourniquetQuestionnaire);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorStateType>();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();

  const question = tourniquetQuestionnaire.questions[tourniquetTraining.index];

  const handleClick = async (item: AnswerDto) => {
    setLoading(true);
    setError('');
    try {
      let answerResponse: AnswerResponseTypeEnum;
      if (tourniquetIdentity.playground) {
        answerResponse = item.correct
          ? AnswerResponseTypeEnum.Correct
          : AnswerResponseTypeEnum.Incorrect;
      } else {
        const res = await invitationsApi.checkAnswer(
          tourniquetIdentity.data!.invitation!.id!,
          {
            answer: item.id!,
            persons: tourniquetTraining.allPersons,
            question: question.id!,
          }
        );
        answerResponse = res.data.type ?? AnswerResponseTypeEnum.Incorrect;
      }

      dispatch(
        tourniquetTrainingActions.success({
          ...tourniquetTraining,
          answerResponse,
        })
      );

      dispatch(
        tourniquetQuestionnaireActions.success({
          ...tourniquetQuestionnaire,
          questions: tourniquetQuestionnaire.questions.map((x) => {
            if (x.id === question.id) {
              return { ...x, answerResponse };
            }

            return x;
          }),
        })
      );
    } catch (err) {
      setErrorStateSnacks(err, setError, enqueueErrorSnackbar);
    }
    setLoading(false);
  };

  const handleNext = () => {
    if (
      tourniquetTraining.index ===
      tourniquetQuestionnaire.questions.length - 1
    ) {
      //Switch to overview page.
      dispatch(
        tourniquetTrainingActions.success({
          ...tourniquetTraining,
          index: 0,
          mode: 'overview',
        })
      );
      return;
    }

    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        index: tourniquetTraining.index + 1,
        answerResponse: undefined,
      })
    );
  };

  return (
    <TourniquetButtonContent onAbort={onEndTraining}>
      <TourniquetCard>
        <TourniquetContent>
          <QcsTypography variant="h4" align="center" sx={{ m: 2 }}>
            {question.content}
          </QcsTypography>
          <TourniquetAnswersContainer>
            {question.answers?.map((x) => (
              <TourniquetTrainingRow key={x.id}>
                <QcsTypography variant="h6">{x.content}</QcsTypography>
                <QcsLoadingButton
                  variant="contained"
                  disabled={tourniquetTraining.answerResponse !== undefined}
                  loading={loading}
                  onClick={() => handleClick(x)}
                  sx={{ flex: '0 0 auto' }}
                >
                  {t('html.select')}
                </QcsLoadingButton>
              </TourniquetTrainingRow>
            ))}
          </TourniquetAnswersContainer>
          <SaveError error={error} />
          {tourniquetTraining.answerResponse && (
            <>
              <QcsTypography sx={{ m: 2 }}>
                {tourniquetTraining.answerResponse ===
                AnswerResponseTypeEnum.Correct ? (
                  <QcsAlert severity="success">
                    {t('tourniquet.correctAnswer')}
                  </QcsAlert>
                ) : (
                  <QcsAlert severity="error">
                    {t('tourniquet.wrongAnswer')}
                  </QcsAlert>
                )}
              </QcsTypography>
              <QcsButton variant="contained" onClick={handleNext}>
                {t('common.nextStep')}
              </QcsButton>
            </>
          )}
        </TourniquetContent>
      </TourniquetCard>
    </TourniquetButtonContent>
  );
};
