import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { ErrorOutline, LightbulbOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';

interface Props {
  icon?: 'error' | 'tip';
  children: React.ReactNode;
  buttonNameT?: string;
  buttonLoading?: boolean;
  onButtonClick?: () => void;
}

const IconDesktop = memo(({ icon }: { icon?: 'error' | 'tip' }) => {
  switch (icon) {
    case 'error':
      return (
        <ErrorOutline color="error" sx={{ width: '80px', height: '80px' }} />
      );
    case 'tip':
      return (
        <LightbulbOutlined
          color="error"
          sx={{ width: '80px', height: '80px' }}
        />
      );
    default:
      return <></>;
  }
});

const IconMobile = memo(({ icon }: { icon?: 'error' | 'tip' }) => {
  switch (icon) {
    case 'error':
      return (
        <ErrorOutline
          color="error"
          sx={{ float: 'left', margin: '0 0.5rem' }}
        />
      );
    case 'tip':
      return (
        <LightbulbOutlined
          color="error"
          sx={{ float: 'left', margin: '0 0.4rem' }}
        />
      );
    default:
      return <></>;
  }
});

const MobileContainer = styled.div(({ theme }) => ({
  display: 'block',
  marginBottom: '1rem',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const MobileContainerButton = styled.div(() => ({
  width: 'max-content',
  marginLeft: 'auto',
  marginTop: '0.2rem',
}));

const DesktopContainer = styled.div(({ theme }) => ({
  display: 'none',
  width: '100%',
  gridTemplateColumns: '80px auto 150px',
  gap: '1rem',
  marginBottom: '1rem',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    display: 'grid',
  },
}));

export const TourniquetInvitationContainer: FC<Props> = ({
  icon,
  children,
  buttonNameT,
  buttonLoading,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <MobileContainer>
        <IconMobile icon={icon} />
        {children}
        <MobileContainerButton>
          {buttonNameT && (
            <QcsLoadingButton
              variant="contained"
              onClick={onButtonClick}
              loading={buttonLoading}
            >
              {t(buttonNameT)}
            </QcsLoadingButton>
          )}
        </MobileContainerButton>
      </MobileContainer>

      <DesktopContainer>
        <div>
          <IconDesktop icon={icon} />
        </div>
        <div>{children}</div>
        <div>
          {buttonNameT && (
            <QcsLoadingButton
              variant="contained"
              onClick={onButtonClick}
              loading={buttonLoading}
              sx={{ width: '100%' }}
            >
              {t(buttonNameT)}
            </QcsLoadingButton>
          )}
        </div>
      </DesktopContainer>
    </>
  );
};
