import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  EmployeeUserListResponse,
  ReferenceDto,
  UserDtoRolesEnum,
  UserDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { qcUserApi } from '../../utils/api';

export interface UserListFilter extends ListFilter {
  state?: Array<UserDtoStateEnum>;
  roles?: Array<UserDtoRolesEnum>;
  establishments: ReferenceDto[];
}

export type UserListState = ListType<EmployeeUserListResponse, UserListFilter>;

const initialState: UserListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: [UserDtoStateEnum.Active, UserDtoStateEnum.Invited],
    sortBy: 'lastName',
    sortDesc: false,
    roles: [],
    establishments: [],
  },
};

export const userListSlice = listSlice({
  name: 'userList',
  initialState,
});

export const selectUserList = (state: RootState) => state.userList;
export const userListActions = userListSlice.actions;

export const getUserList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectUserList(storeState);

    const handleGetData = (size: number) => {
      return qcUserApi.queryQcsUsers(
        companyId,
        data.query.q,
        data.query.state,
        data.query.roles,
        data.query.establishments.map((x) => x.id),
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: userListActions,
      getData: handleGetData,
    });
  };
