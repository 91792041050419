import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaIcon } from '../../common/FaIcon';
import { useTheme } from '@mui/material';

export enum InvitationIconType {
  Info = 'info',
  Ok = 'ok',
  Warning = 'warning',
}

interface Props {
  value: string;
  type: InvitationIconType;
}

export const InvitationEditTabLabel: FC<Props> = ({ value, type }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const icon =
    type === InvitationIconType.Ok
      ? faCircleCheck
      : type === InvitationIconType.Warning
      ? faCircleExclamation
      : faCircleInfo;

  const color =
    type === InvitationIconType.Ok
      ? theme.palette.success.main
      : type === InvitationIconType.Warning
      ? theme.palette.warning.main
      : theme.palette.common.black;

  return (
    <div style={{ display: 'flex', gap: '0.35rem', alignItems: 'center' }}>
      <FaIcon icon={icon} color={color} />
      <span>{t('invitation.tabs.' + value)}</span>
    </div>
  );
};
