import { FC } from 'react';
import { Header } from './Header';
import {
  ContentContainerWrapper,
  ContentContainer,
  LayoutContainer,
  LayoutWrapper,
} from './LayoutStyles';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';

interface Props {
  children: React.ReactNode;
}

export const Layout: FC<Props> = ({ children }) => (
  <LayoutWrapper>
    <Header />
    <LayoutContainer>
      <Sidebar />
      <ContentContainerWrapper>
        <ContentContainer>
          {children}
          <Footer />
        </ContentContainer>
      </ContentContainerWrapper>
    </LayoutContainer>
  </LayoutWrapper>
);
