import React, { FC, useEffect, useMemo, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../images/logo.svg';
import {
  HeaderContainer,
  HeaderLogo,
  HeaderLogoNavLink,
  HeaderMobileMenuButton,
  HeaderUserMenuButton,
  StyledQcsMenuItem,
} from './HeaderStyles';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  selectIdentity,
  selectIdentityState,
} from '../../store/entities/identity';
import { mainMenuItems, qcsAdminMenuItems, userMenuItems } from './menuItems';
import { useTranslation } from 'react-i18next';
import { Menu as QcsMenu } from '@mui/material';

import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { QcsListItemIcon } from '../common/basic/QcsListItemIcon';
import { QcsListItemText } from '../common/basic/QcsListItemText';
import {
  getMenuNavLinkClassName,
  getMenuNavLinkId,
  hasRole,
  isQcsAdminCompanyMode,
  isQcsAdminMode,
  isRemote,
  isTourniquet,
} from '../../utils/roles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FetchState } from '../../store/fetchState';
import { useLocation, useNavigate } from 'react-router';
import { setQcsAdminLastCompany } from '../../utils/storage';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import {
  selectCompanyCustomization,
  selectCompanyCustomizationState,
} from '../../store/entities/companyCustomization';
import { Loader } from '../common/Loader';
import { BASE_URL_WITH_VERSION } from '../../utils/api';
import { FaIcon } from '../common/FaIcon';
import { faSliders, faUser } from '@fortawesome/free-solid-svg-icons';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { ChangeLangModal } from './ChangeLangModal';
import { TourniquetHeader } from '../tourniquet/TourniquetHeader';
import { UserDtoRolesEnum } from '@qcs/safety-client';
import { tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { selectSubdomainCustomization } from '../../store/entities/subdomainCustomization';

const ADMIN_URL = import.meta.env.VITE_ADMIN_URL as string;

export const Header: FC = () => {
  const identity = useAppSelector(selectIdentity);
  const identityState = useAppSelector(selectIdentityState);
  const { t, i18n } = useTranslation();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] =
    useState<HTMLElement | null>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const subdomainCustomization = useAppSelector(selectSubdomainCustomization);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const companyCustomizationState = useAppSelector(
    selectCompanyCustomizationState
  );
  const [changeLangOpen, setChangeLangOpen] = useState(false);

  const isAdminQcs = hasRole(identity.roles, [UserDtoRolesEnum.AdminQcs]);
  const isAdminCompany = hasRole(identity.roles, [
    UserDtoRolesEnum.AdminCompany,
  ]);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));

  const activeLang = SUPPORTED_LANGUAGES.find(
    (x) => x.code === i18n.resolvedLanguage
  );

  useEffect(() => {
    if (isNotMobile) {
      handleCloseMobileMenu();
    }
  }, [isNotMobile]);

  const handleOpenMobileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMobileMenu = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleQcsAdminBack = () => {
    setQcsAdminLastCompany('');

    window.location.replace(ADMIN_URL);
  };

  const handleMyProfile = () => {
    if (isAdminQcs) {
      if (isQcsAdminMode(identity)) {
        navigate(`/qcsuser/${identity.id}`);
      } else {
        navigate(`/user/${identity.id}`);
      }
    } else if (isAdminCompany) {
      navigate(`/user/${identity.id}`);
    } else {
      navigate(`/profile`);
    }

    handleCloseUserMenu();
  };

  const handleChangeLang = () => {
    handleCloseUserMenu();
    setChangeLangOpen(true);
  };

  const handleChangLangClose = () => {
    setChangeLangOpen(false);
  };

  const handleHeaderLogoClick = () => {
    dispatch(tourniquetIdentityActions.default());
    dispatch(tourniquetTrainingsActions.default());
    dispatch(tourniquetTrainingActions.default());
  };

  const mainMenuItemsMemo = useMemo(() => {
    return (isQcsAdminMode(identity) ? qcsAdminMenuItems : mainMenuItems)
      .filter((x) => !x.isDetail)
      .filter((x) => hasRole(identity.roles, x.roles))
      .map((x) => {
        const id = getMenuNavLinkId(x.id, identity);

        return (
          <StyledQcsMenuItem
            key={id}
            onClick={handleCloseMobileMenu}
            href={x.link}
            useNavLink={true}
            navLinkClassName={getMenuNavLinkClassName(id, location, identity)}
          >
            <QcsListItemIcon>{x.icon}</QcsListItemIcon>
            <QcsListItemText>{t('mainMenu.' + id)}</QcsListItemText>
          </StyledQcsMenuItem>
        );
      });
  }, [identity, location, t]);

  const userMenuItemsMemo = useMemo(() => {
    return userMenuItems
      .filter((x) => hasRole(identity.roles, x.roles))
      .map((x) => (
        <QcsMenuItem
          key={x.id}
          onClick={handleCloseUserMenu}
          href={x.link}
          useNavLink={true}
        >
          <QcsListItemIcon>{x.icon}</QcsListItemIcon>
          <QcsListItemText>{t('userMenu.' + x.id)}</QcsListItemText>
        </QcsMenuItem>
      ));
  }, [identity.roles, t]);

  const logoSrc = useMemo(() => {
    if (companyCustomizationState === FetchState.Loaded) {
      if (companyCustomization?.bannerUrl) {
        return `${BASE_URL_WITH_VERSION}${companyCustomization.bannerUrl}`;
      }
    } else {
      if (subdomainCustomization) {
        return subdomainCustomization;
      }
    }

    return Logo;
  }, [
    companyCustomization?.bannerUrl,
    companyCustomizationState,
    subdomainCustomization,
  ]);

  return (
    <HeaderContainer>
      <ChangeLangModal open={changeLangOpen} onClose={handleChangLangClose} />

      {identityState === FetchState.Loaded && (
        <>
          <HeaderMobileMenuButton onClick={handleOpenMobileMenu}>
            <MenuIcon />
          </HeaderMobileMenuButton>
          <QcsMenu
            anchorEl={mobileMenuAnchorEl}
            open={!!mobileMenuAnchorEl}
            onClose={handleCloseMobileMenu}
          >
            {mainMenuItemsMemo}
          </QcsMenu>
        </>
      )}

      <HeaderLogoNavLink to="/">
        {companyCustomizationState === FetchState.Loading ? (
          <Loader />
        ) : (
          <HeaderLogo src={logoSrc} onClick={handleHeaderLogoClick} />
        )}
      </HeaderLogoNavLink>

      <TourniquetHeader />

      {identityState === FetchState.Loaded && (
        <>
          <HeaderUserMenuButton
            onClick={handleOpenUserMenu}
            ellipsisText={true}
          >
            <QcsBox
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
              }}
            >
              {isRemote(identity) ? t('userMenu.remote') : identity.name}
              {isAdminQcs && companyCustomization?.companyName && (
                <>
                  <br />
                  {companyCustomization.companyName}
                </>
              )}
            </QcsBox>
            <ArrowDropDownIcon />
          </HeaderUserMenuButton>
          <QcsMenu
            anchorEl={userMenuAnchorEl}
            open={!!userMenuAnchorEl}
            onClose={handleCloseUserMenu}
          >
            {!isTourniquet(identity) && (
              <QcsMenuItem onClick={handleChangeLang}>
                <QcsListItemIcon>
                  <img
                    src={'/flags/' + activeLang?.flag + '.svg'}
                    height="16"
                  />
                </QcsListItemIcon>
                <QcsListItemText>{activeLang?.name}</QcsListItemText>
              </QcsMenuItem>
            )}
            {isQcsAdminCompanyMode(identity) && (
              <QcsMenuItem onClick={handleQcsAdminBack}>
                <QcsListItemIcon>
                  <FaIcon icon={faSliders} />
                </QcsListItemIcon>
                <QcsListItemText>{t('userMenu.backToAdmin')}</QcsListItemText>
              </QcsMenuItem>
            )}
            {!isQcsAdminCompanyMode(identity) &&
              !isTourniquet(identity) &&
              !isRemote(identity) && (
                <QcsMenuItem onClick={handleMyProfile}>
                  <QcsListItemIcon>
                    <FaIcon icon={faUser} />
                  </QcsListItemIcon>
                  <QcsListItemText>{t('userMenu.myProfile')}</QcsListItemText>
                </QcsMenuItem>
              )}
            {userMenuItemsMemo}
          </QcsMenu>
        </>
      )}
    </HeaderContainer>
  );
};
