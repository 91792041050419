import styled from '@emotion/styled';
import { AxiosResponse } from 'axios';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { ErrorStateType } from '../../models/common';
import { supplierEmployeeApi } from '../../utils/api';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { Modal as QcsModal } from '@mui/material';

import { QcsTypography } from '../common/basic/QcsTypography';
import { SaveError } from '../common/SaveError';
import {
  QcsProtocolIframe,
  QcsProtocolModalBox,
} from '../layout/QcsProtocolStyles';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
}));

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const SupplierEmployeeProtocol: FC = () => {
  const { t } = useTranslation();
  const { supplierEmployeeId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorStateType>();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const dataUrl = useRef<string>();
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    return () => {
      if (dataUrl.current) {
        window.URL.revokeObjectURL(dataUrl.current);
        dataUrl.current = undefined;
      }
    };
  }, []);

  const handleShow = async () => {
    await load();
    if (!dataUrl.current) {
      return;
    }

    setIframeSrc(dataUrl.current);
  };

  const handleClose = () => {
    setIframeSrc('');
  };

  const handlePrint = async () => {
    await load();
    if (!dataUrl.current) {
      return;
    }

    const pdfWindow = window.open(dataUrl.current);
    pdfWindow?.print();
  };

  const handleExport = async () => {
    await load();
    if (!dataUrl.current) {
      return;
    }

    const link = document.createElement('a');
    link.href = dataUrl.current;
    link.download = 'protocol.pdf';
    link.click();
  };

  const load = async () => {
    if (dataUrl.current) {
      return;
    }

    setError('');
    setIsLoading(true);
    try {
      const res = await supplierEmployeeApi.getSupplierEmployeeProtocol(
        supplierEmployeeId!,
        {
          responseType: 'blob',
        }
      );
      const blobResponse = res as AxiosResponse<Blob>;
      const data = new Blob([blobResponse.data], { type: 'application/pdf' });
      dataUrl.current = window.URL.createObjectURL(data);
    } catch (err) {
      setErrorStateSnacks(err, setError, enqueueErrorSnackbar);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <QcsModal open={!!iframeSrc} onClose={handleClose}>
        <QcsProtocolModalBox disableBorder={true}>
          {iframeSrc && (
            <QcsProtocolIframe
              src={iframeSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </QcsProtocolModalBox>
      </QcsModal>

      <HeaderContainer>
        <QcsTypography variant="h5">
          {t('supplierEmployeeProtocol.title')}
        </QcsTypography>
      </HeaderContainer>
      <SaveError error={error} sx={{ marginBottom: '0.5rem' }} />
      <ButtonsContainer>
        <QcsLoadingButton
          variant="outlined"
          onClick={handleShow}
          loading={isLoading}
        >
          {t('supplierEmployeeProtocol.show')}
        </QcsLoadingButton>
        <QcsLoadingButton
          variant="outlined"
          onClick={handlePrint}
          loading={isLoading}
        >
          {t('supplierEmployeeProtocol.print')}
        </QcsLoadingButton>
        <QcsLoadingButton
          variant="outlined"
          onClick={handleExport}
          loading={isLoading}
        >
          {t('supplierEmployeeProtocol.export')}
        </QcsLoadingButton>
      </ButtonsContainer>
    </div>
  );
};
