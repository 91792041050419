import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import {
  emergencyActions,
  getEmergency,
  selectEmergency,
  selectEmergencyState,
} from '../../store/entities/emergency';
import { GridList } from '../common/grid/GridList';
import { EmergencyDto, EmergencyPersonDto } from '@qcs/safety-client';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FaIcon } from '../common/FaIcon';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Checkbox } from '../common/form/Checkbox';
import { DateTimePicker } from '../common/form/DateTimePicker';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { emergencyApi } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { downloadBlob } from '../../utils/download';
import { emergencyListActions } from '../../store/entities/emergencyList';
import { SaveError } from '../common/SaveError';
import { StyledLoadingButton, SubmitButton } from '../common/form/SubmitButton';
import { selectCompanyCustomizationSmsEnabled } from '../../store/entities/companyCustomization';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { setErrorSnacks, setErrorStateSnacks } from '../../utils/error';
import { QcsTextField } from '../common/basic/QcsTextField';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { InnerTooltipWrapper, QcsTooltip } from '../common/basic/QcsTooltip';
import { getLangNameInputNameObj, getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { PhoneInput } from '../common/form/PhoneInput';
import { validations } from '../../utils/validations';
import * as Yup from 'yup';
import { useBack } from '../../hooks/useBack';
import { copyToClipboard } from '../../utils/clipboard';

const RowQcsBox = memo(({ children }: { children: React.ReactNode }) => (
  <QcsBox
    sx={{
      display: 'grid',
      gridTemplateColumns: {
        xs: 'auto',
        sm: 'auto max-content',
      },
      gridTemplateRows: { xs: 'auto auto', sm: 'auto' },
      rowGap: { xs: '1rem', sm: 0 },
      columnGap: { xs: 0, sm: '1rem' },
    }}
  >
    {children}
  </QcsBox>
));

interface EmergencyEmailFormData {
  email?: string;
  type: 'email';
}
interface EmergencySmsFormData {
  sms?: string;
  type: 'sms';
}

export const EmergencyDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/emergency');
  const { emergencyId, scrollTo } = useParams();
  const emergency = useAppSelector(selectEmergency);
  const emergencyState = useAppSelector(selectEmergencyState);
  const companyCustomizationSmsEnabled = useAppSelector(
    selectCompanyCustomizationSmsEnabled
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [personSaving, setPersonSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [endError, setEndError] = useState<ErrorStateType>();
  const [isEnding, setIsEnding] = useState(false);
  const personsContainerRef = useRef<HTMLDivElement>(null);
  const [emergencyLink, setEmergencyLink] = useState('');

  useEffect(() => {
    dispatch(getEmergency(emergencyId!));
    setLoading(false);
  }, [emergencyId, dispatch]);

  useEffect(() => {
    if (emergencyState !== FetchState.Loaded && loading) {
      return;
    }

    if (scrollTo !== 'person') {
      return;
    }

    //Call scroll after render.
    setTimeout(() => personsContainerRef.current?.scrollIntoView(), 0);
  }, [emergencyState, loading, scrollTo]);

  const handleSubmit = async (data: EmergencyDto) => {
    setSaveError('');
    try {
      if (!companyCustomizationSmsEnabled) {
        data.sendSMS = false;
      }

      await emergencyApi.updateEmergency(emergencyId!, data);
      enqueueSuccessSnackbar(t('common.saveSuccess'));
      dispatch(emergencyListActions.reload(true));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  const handleEnd = async () => {
    setEndError('');
    setIsEnding(true);
    try {
      await emergencyApi.endEmergency(emergencyId!, emergency);
      enqueueSuccessSnackbar(t('common.saveSuccess'));
      dispatch(emergencyListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setEndError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    setIsEnding(false);
  };

  const handlePersonRemove = async (item: EmergencyPersonDto) => {
    setIsRemoving(true);
    try {
      await emergencyApi.foundPerson(emergencyId!, item.id!);
      const newData = await emergencyApi.findById(emergencyId!);
      dispatch(emergencyActions.success(newData.data));
      dispatch(emergencyListActions.reload(true));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsRemoving(false);
  };

  const handleRenderData = (item: EmergencyPersonDto) => {
    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.workplaces}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getLangNameObj(i18n, item.invitedBy)}
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsTooltip
            title={t('emergency.persons.removeTooltip')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsButton
                onClick={() => handlePersonRemove(item)}
                disabled={isRemoving}
              >
                <FaIcon icon={faUserShield} />
              </QcsButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleExport = async () => {
    setPersonSaving(true);
    try {
      const res = await emergencyApi.exportEmployeesEmergency(emergencyId!, {
        responseType: 'blob',
      });
      const blobResponse = res as AxiosResponse<Blob>;
      await downloadBlob(blobResponse);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setPersonSaving(false);
  };

  const handleCopyLink = async () => {
    setPersonSaving(true);
    setEmergencyLink('');
    try {
      let link;
      try {
        const res = await emergencyApi.getEmergencyLink(emergencyId!, {
          target: '',
          withSend: false,
        });
        link = res.data.value;

        if (!link) {
          throw new Error();
        }
      } catch (err) {
        setErrorSnacks(err, enqueueErrorSnackbar);
        return;
      }

      setEmergencyLink(link);
      setTimeout(async () => {
        try {
          await copyToClipboard(link);
          enqueueSuccessSnackbar(t('emergency.persons.linkSuccess'));
        } catch (err) {
          setErrorSnacks(
            err,
            enqueueErrorSnackbar,
            t('emergency.persons.linkError')
          );
          return;
        } finally {
          setPersonSaving(false);
        }
      }, 100);
    } finally {
      setPersonSaving(false);
    }
  };

  const handleSendEmailOrSms = async (
    values: EmergencyEmailFormData | EmergencySmsFormData
  ) => {
    setPersonSaving(true);
    setEmergencyLink('');
    try {
      await emergencyApi.getEmergencyLink(emergencyId!, {
        target: values.type === 'sms' ? values.sms : values.email,
        withSend: true,
      });
      enqueueSuccessSnackbar(t('emergency.persons.sendSuccess'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setPersonSaving(false);
  };

  if (loading || emergencyState === FetchState.Loading) {
    return <Loader />;
  }

  if (emergencyState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="emergency.detailTitle" backTo={backTo} />
      <FormContainer>
        <FormikDebounce<EmergencyDto>
          initialValues={emergency}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <FormContainer>
                <Input
                  name={`establishment.${getLangNameInputNameObj(
                    i18n,
                    values.establishment
                  )}`}
                  label={t('emergency.establishment')}
                  disabled={true}
                />
                <WorkplaceAutocomplete
                  name="workplaces"
                  label={t('emergency.workplaces')}
                  multiple={true}
                  disabled={true}
                />
                <DateTimePicker
                  name="createdOn"
                  label={t('emergency.createdOn')}
                  disabled={true}
                />
                <Input
                  name="detail"
                  label={t('emergency.detail')}
                  maxLength={250}
                  multiline={true}
                  rows={4}
                />
                <Checkbox
                  name="sendEmail"
                  label={t('emergency.sendEmail')}
                  disabled={true}
                />
                {companyCustomizationSmsEnabled && (
                  <Checkbox
                    name="sendSMS"
                    label={t('emergency.sendSMS')}
                    disabled={true}
                  />
                )}

                <SaveError error={saveError} />
                <SubmitButton text="emergency.saveDetail" />

                <div ref={personsContainerRef}>
                  <QcsTypography variant="h6">
                    {t('emergency.persons.title')}:
                  </QcsTypography>
                  <GridList<EmergencyPersonDto>
                    headers={[
                      { captionStr: 'emergency.persons.name' },
                      { captionStr: 'emergency.persons.lastName' },
                      {
                        captionStr: 'emergency.persons.phone',
                        hideOnMobile: true,
                      },
                      {
                        captionStr: 'emergency.persons.workplace',
                        hideOnMobile: true,
                      },
                      {
                        captionStr: 'emergency.persons.invitedBy',
                        hideOnMobile: true,
                      },
                      { captionStr: 'emergency.persons.remove' },
                    ]}
                    data={emergency.emergencyPersons ?? []}
                    renderData={handleRenderData}
                    search={false}
                    hidePagination={true}
                  />

                  <QcsLoadingButton
                    variant="outlined"
                    sx={{ marginTop: '0.5rem' }}
                    onClick={handleExport}
                    loading={personSaving}
                  >
                    {t('emergency.persons.export')}
                  </QcsLoadingButton>
                </div>

                {!emergency.endedOn && (
                  <div>
                    <QcsLoadingButton
                      variant="outlined"
                      onClick={handleCopyLink}
                      loading={personSaving}
                    >
                      {t('emergency.persons.copyLink')}
                    </QcsLoadingButton>
                    {emergencyLink && (
                      <div style={{ marginTop: '1rem' }}>
                        <QcsTextField
                          disabled={true}
                          value={emergencyLink}
                          label={t('emergency.persons.link')}
                          fullWidth={true}
                        />
                      </div>
                    )}
                  </div>
                )}
              </FormContainer>
            </Form>
          )}
        </FormikDebounce>
        {!emergency.endedOn && (
          <>
            <FormikDebounce<EmergencySmsFormData>
              initialValues={{
                sms: '',
                type: 'sms',
              }}
              validationSchema={Yup.object().shape({
                sms: validations.phoneOptional(t),
              })}
              validateOnChange={false}
              onSubmit={handleSendEmailOrSms}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <RowQcsBox>
                    <PhoneInput
                      name="sms"
                      label={t('emergency.persons.phone')}
                    />
                    <QcsLoadingButton
                      variant="contained"
                      disabled={!values.sms}
                      loading={isSubmitting}
                      sx={{ height: '3.5rem' }}
                      type="submit"
                    >
                      {t('emergency.persons.sendSms')}
                    </QcsLoadingButton>
                  </RowQcsBox>
                </Form>
              )}
            </FormikDebounce>

            <FormikDebounce<EmergencyEmailFormData>
              initialValues={{
                email: '',
                type: 'email',
              }}
              validationSchema={Yup.object().shape({
                email: validations.emailOptional(t),
              })}
              validateOnChange={false}
              onSubmit={handleSendEmailOrSms}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <RowQcsBox>
                    <Input name="email" label={t('emergency.persons.email')} />
                    <QcsLoadingButton
                      variant="contained"
                      disabled={!values.email}
                      loading={isSubmitting}
                      sx={{ height: '3.5rem' }}
                      type="submit"
                    >
                      {t('emergency.persons.sendEmail')}
                    </QcsLoadingButton>
                  </RowQcsBox>
                </Form>
              )}
            </FormikDebounce>
          </>
        )}

        <SaveError error={endError} />
        {!emergency.endedOn && (
          <StyledLoadingButton
            variant="contained"
            color="error"
            onClick={handleEnd}
            loading={isEnding}
          >
            {t('emergency.end')}
          </StyledLoadingButton>
        )}
      </FormContainer>
    </>
  );
};
