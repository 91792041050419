import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsTableCell } from '../basic/QcsTableCell';

interface Props {
  value?: boolean | null;
  hideOnMobile?: boolean;
}

export const CellYesNo: FC<Props> = ({ value, hideOnMobile }) => {
  const { t } = useTranslation();
  return (
    <QcsTableCell hideOnMobile={hideOnMobile}>
      {value ? t('common.yes') : t('common.no')}
    </QcsTableCell>
  );
};
