export async function copyToClipboard(text: string) {
  try {
    // Check if the Clipboard API is supported
    if (
      navigator.clipboard &&
      typeof navigator.clipboard.writeText === 'function'
    ) {
      // Modern approach with the Clipboard API
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Blob([text], { type: 'text/plain' }),
        }),
      ]);
      console.log('Text copied to clipboard successfully.');
    } else {
      // Fallback approach unsupported browsers
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'absolute';
      textArea.style.left = '-9999px';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy', true);
        console.log('Text copied to clipboard using fallback method.');
      } catch (err) {
        console.error('Fallback copy to clipboard failed:', err);
      }
      document.body.removeChild(textArea);
    }
  } catch (err) {
    console.error('Failed to copy text to clipboard:', err);
  }
}
