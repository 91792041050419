import { InvitedPersonDto } from '@qcs/safety-client';
import { FC, useEffect, useState } from 'react';
import { supplierEmployeeApi } from '../../../../utils/api';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { QcsTypography } from '../../../common/basic/QcsTypography';
import { SaveError } from '../../../common/SaveError';
import { Loader } from '../../../common/Loader';
import { setErrorState } from '../../../../utils/error';
import { getFirstLastNameObj } from '../../../../utils/format';
import { invitationHistoryListActions } from '../../../../store/entities/invitationHistoryList';
import { useInvitation } from './invitationFunctions';

interface Props {
  invitedPerson?: InvitedPersonDto;
  onClose: () => void;
}

export const InvitedPersonsPinModal: FC<Props> = ({
  invitedPerson,
  onClose,
}) => {
  const {
    t,
    invitation,
    dispatch,
    isSaving,
    setIsSaving,
    saveError,
    setSaveError,
  } = useInvitation();

  const [pin, setPin] = useState('');

  useEffect(() => {
    (async () => {
      if (!invitedPerson) {
        return;
      }

      setPin('');
      setIsSaving(true);
      setSaveError('');
      try {
        const pin = await supplierEmployeeApi.revealPin(
          invitedPerson.employeeId!,
          invitation.id!
        );
        setPin(pin.data.pin!);
        dispatch(invitationHistoryListActions.reload(true));
      } catch (err) {
        setErrorState(err, setSaveError);
      }
      setIsSaving(false);
    })();
  }, [dispatch, invitation.id, invitedPerson, setIsSaving, setSaveError]);

  return (
    <QcsModal open={!!invitedPerson} onClose={onClose}>
      <QcsModalBox>
        <QcsTypography variant="h4" sx={{ mb: '1.5rem' }}>
          {t('invitation.persons.pinTitle')}{' '}
          {getFirstLastNameObj(invitedPerson)}
        </QcsTypography>
        {isSaving && <Loader />}
        <SaveError error={saveError} />
        {pin && <QcsTypography variant="h4">{pin}</QcsTypography>}
      </QcsModalBox>
    </QcsModal>
  );
};
