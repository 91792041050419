import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getAppTrainingLangList,
  selectAppTrainingLangList,
} from '../../store/entities/appTrainingLangList';
import { selectCompanyCustomizationTrainingLangCodes } from '../../store/entities/companyCustomization';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FetchState } from '../../store/fetchState';
import { LanguageContainer } from './TourniquetStyles';

export const TourniquetLanguageSelect: FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const appTrainingLangList = useAppSelector(selectAppTrainingLangList);
  const companyCustomizationTrainingLangCodes = useAppSelector(
    selectCompanyCustomizationTrainingLangCodes
  );

  useEffect(() => {
    if (appTrainingLangList.state === FetchState.None) {
      dispatch(getAppTrainingLangList());
    }
  }, [appTrainingLangList.state, dispatch]);

  const handleChangeLang = useCallback(
    (flag: string) => {
      const foundLanguage = SUPPORTED_LANGUAGES.find((x) => x.flag === flag);
      if (
        foundLanguage?.code === 'de' ||
        foundLanguage?.code === 'ru' ||
        foundLanguage?.code === 'pl'
      ) {
        import(`./locales/${foundLanguage.code}.json`).then((translation) => {
          i18n.addResourceBundle(
            `${foundLanguage.code}`,
            'translation',
            translation
          );
          i18n.changeLanguage(`${foundLanguage.code}`);
        });
      } else {
        i18n.changeLanguage(foundLanguage?.code ?? 'cs');
      }
    },
    [i18n]
  );

  const trainingLanguages = useMemo(
    () =>
      appTrainingLangList.data?.content.filter((x) =>
        companyCustomizationTrainingLangCodes?.includes(x.code)
      ),
    [appTrainingLangList.data?.content, companyCustomizationTrainingLangCodes]
  );

  const flagsMemo = useMemo(
    () =>
      trainingLanguages?.map((x) => (
        <QcsButton key={x.flag} onClick={() => handleChangeLang(x.flag)}>
          <img src={'/flags/' + x.flag + '.svg'} height="30" loading="lazy" />
        </QcsButton>
      )),
    [handleChangeLang, trainingLanguages]
  );

  return <LanguageContainer>{flagsMemo}</LanguageContainer>;
};
