import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../common/form/Input';
import { FormContainer } from '../common/form/FormContainer';
import { DatePicker } from '../common/form/DatePicker';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { EmployeeAutocomplete } from '../common/form/EmployeeAutocomplete';
import { InvitationCreateForm } from '../../models/invitation';
import { ActivityAutocomplete } from '../common/form/ActivityAutocomplete';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { SupplierAutocomplete } from '../common/form/SupplierAutocomplete';
import { SupplierEmployeeAutocomplete } from '../common/form/SupplierEmployeeAutocomplete';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { FormGroup } from '../common/form/FormGroup';
import { QcsTypography } from '../common/basic/QcsTypography';
import { PhoneInput } from '../common/form/PhoneInput';
import {
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { InvitationAttachments } from './InvitationAttachments';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep5: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  const handleRemoveOldFile = () => {
    //Nothing.
  };

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <FormContainer>
            <FormGroup name="invitation.create.step1">
              <Input
                name="name"
                label={t('invitation.name')}
                required
                disabled
              />
              <Input
                name="note"
                label={t('invitation.note')}
                multiline={true}
                rows={4}
                disabled
              />
              <EmployeeAutocomplete
                name="employee"
                label={t('invitation.employee')}
                required
                disabled
                forInvite={true}
              />
              <DatePicker
                name="validFrom"
                label={t('training.validFrom')}
                required
                disabled
              />
              <DatePicker
                name="validTo"
                label={t('training.validTo')}
                disabled
              />
              <Input
                name="noteInternal"
                label={t('invitation.noteInternal')}
                multiline={true}
                rows={4}
                disabled
              />
              <InvitationAttachments
                titleVariant="h6"
                disabled
                onRemoveOldFile={handleRemoveOldFile}
              />
            </FormGroup>

            <FormGroup name="invitation.create.step2">
              <EstablishmentAutocomplete
                multiple
                name="establishments"
                label={t('invitation.establishments')}
                required
                disabled
              />

              <WorkplaceAutocomplete
                multiple
                name="workplaces"
                label={t('invitation.workplaces')}
                required
                showEstabilishmentName={true}
                disabled
              />

              <ActivityAutocomplete
                multiple
                name="activities"
                label={t('invitation.activities')}
                required
                disabled
              />
            </FormGroup>

            <FormGroup name="invitation.create.step4">
              {invitationData.supplierType === 'select' && (
                <>
                  <SupplierAutocomplete
                    name="supplierA"
                    label={t('invitation.supplier')}
                    required
                    disabled
                  />

                  <SupplierEmployeeAutocomplete
                    name="responsiblePersonA"
                    label={t('invitation.responsiblePerson')}
                    supplierId={values.supplierA?.id}
                    role={SupplierEmployeeDtoRoleEnum.ExternalManager}
                    state={[
                      SupplierEmployeeDtoStateEnum.Active,
                      SupplierEmployeeDtoStateEnum.Invited,
                    ]}
                    required
                    disabled
                  />
                </>
              )}

              {invitationData.supplierType === 'create' && (
                <>
                  <Input
                    name="supplierB.ico"
                    label={t('supplier.ico')}
                    maxLength={14}
                    required
                    disabled
                  />
                  <Input
                    name="supplierB.name"
                    label={t('supplier.name')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="supplierB.vat"
                    label={t('supplier.vat')}
                    maxLength={14}
                    disabled
                  />

                  <QcsTypography variant="body1">
                    {t('address.title')}
                  </QcsTypography>
                  <Input
                    name="supplierB.address.street"
                    label={t('address.street')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="supplierB.address.city"
                    label={t('address.city')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="supplierB.address.postalCode"
                    label={t('address.postalCode')}
                    maxLength={128}
                    required
                    disabled
                  />
                  <Input
                    name="supplierB.address.country"
                    label={t('address.country')}
                    maxLength={100}
                    required
                    disabled
                  />

                  <QcsTypography variant="body1">
                    {t('invitation.responsiblePerson')}
                  </QcsTypography>
                  <Input
                    name="responsiblePersonB.name"
                    label={t('user.name')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="responsiblePersonB.lastName"
                    label={t('user.lastName')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="responsiblePersonB.jobPosition"
                    label={t('user.jobPosition')}
                    disabled
                  />
                  <PhoneInput
                    name="responsiblePersonB.phone"
                    label={t('user.phone')}
                    disabled
                  />
                  <Input
                    name="responsiblePersonB.email"
                    label={t('user.email')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <LanguageSelect
                    name="responsiblePersonB.language"
                    label={t('user.language')}
                    languages={SUPPORTED_LANGUAGES}
                    allowedLanguages={
                      companyCustomization?.applicationLanguages
                    }
                    required
                    disabled
                  />
                </>
              )}

              {invitationData.supplierType === 'once' && (
                <>
                  <Input
                    name="responsiblePersonC.name"
                    label={t('user.name')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <Input
                    name="responsiblePersonC.lastName"
                    label={t('user.lastName')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <PhoneInput name="phone" label={t('user.phone')} disabled />
                  <Input
                    name="responsiblePersonC.email"
                    label={t('user.email')}
                    maxLength={100}
                    required
                    disabled
                  />
                  <LanguageSelect
                    name="responsiblePersonC.language"
                    label={t('user.language')}
                    languages={SUPPORTED_LANGUAGES}
                    allowedLanguages={
                      companyCustomization?.applicationLanguages
                    }
                    required
                    disabled
                  />
                </>
              )}
            </FormGroup>

            <InvitationButtonsContainer>
              <QcsButton variant="outlined" onClick={handleGoBack}>
                {t('common.back')}
              </QcsButton>
              <div style={{ width: '100%' }} />
              <InvitationButton type="DELETE" customEvent={onDelete} />
              <InvitationButton type="SAVE" showAlways={true} />
              <InvitationButton type="SEND" showAlways={true} />
            </InvitationButtonsContainer>
          </FormContainer>
        </Form>
      )}
    </FormikDebounce>
  );
};
