import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { emergencyApi } from '../../utils/api';
import { SingleValueResponseUUID } from '@qcs/safety-client';

type State = BaseType<SingleValueResponseUUID>;

const initialState: State = {
  state: FetchState.None,
  data: {
    value: undefined,
  },
};

export const emergencyActiveSlice = baseSlice({
  name: 'emergencyActive',
  initialState,
});

export const selectEmergencyActiveState = (state: RootState) =>
  state.emergencyActive.state;
export const selectEmergencyActive = (state: RootState) =>
  state.emergencyActive.data;
export const emergencyActiveActions = emergencyActiveSlice.actions;

export const getEmergencyActive =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return emergencyApi.isEnabledToCreate(companyId);
    };

    getItem({
      dispatch,
      actions: emergencyActiveActions,
      getData: handleGetData,
    });
  };
