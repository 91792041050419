import { FC, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';

import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { CheckboxInMenuItem, MenuItemWithCheckbox } from '../form/formStyles';
import { joinStrings2 } from '../../../utils/format';

interface Props {
  className?: string;
  value: unknown[];
  options: unknown[];
  onChange: (value: any) => void;
  getOptionId: (option: any) => string;
  getOptionName: (option: any) => string;
}

export const GridFilterSelectMultiple: FC<Props> = ({
  className,
  value,
  options,
  onChange,
  getOptionId,
  getOptionName,
}) => {
  const { t } = useTranslation();

  const items = useMemo(
    () =>
      options.map((option) => (
        <MenuItemWithCheckbox
          key={getOptionId(option)}
          value={getOptionId(option)}
        >
          <CheckboxInMenuItem
            checked={value?.some((id) => id === getOptionId(option))}
          />
          {getOptionName(option)}
        </MenuItemWithCheckbox>
      )),
    [getOptionId, getOptionName, options, value]
  );

  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value);
  };

  return (
    <QcsSelect
      className={className}
      multiple
      fullWidth={true}
      value={value}
      onChange={handleChange}
      displayEmpty
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>{t('common.nonSelected')}</em>;
        }

        return joinStrings2(
          selected.map((item: string) => getOptionName(item))
        );
      }}
    >
      {items}
    </QcsSelect>
  );
};
