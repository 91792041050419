import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { ReferenceDto, RiskDto, RiskDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { riskApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<RiskDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsRiskSlice = baseSlice({
  name: 'optionsRisk',
  initialState,
});

export const selectOptionsRiskState = (state: RootState) =>
  state.optionsRisk.state;
export const selectOptionsRisk = (state: RootState) =>
  state.optionsRisk.data.map(
    (x) => ({ id: x.id, name: x.name, nameEn: x.nameEn } as ReferenceDto)
  );
export const optionsRiskActions = optionsRiskSlice.actions;

export const getOptionsRisk =
  (companyId: string, search: string | undefined, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await riskApi.queryRisks(
        companyId,
        search,
        RiskDtoStateEnum.Active,
        true,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsRiskActions,
      getData: handleGetData,
    });
  };
