import {
  UserDto,
  UserDtoRolesEnum,
  UserDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getUser,
  userActions,
  selectUser,
  selectUserState,
} from '../../../store/entities/user';
import { qcsAdminUserListActions } from '../../../store/entities/qcsAdminUserList';
import { FetchState } from '../../../store/fetchState';
import { qcUserApi } from '../../../utils/api';
import { SaveError } from '../../common/SaveError';
import { QcsMenuItem } from '../../common/basic/QcsMenuItem';
import { DetailHeader } from '../../common/DetailHeader';
import { ErrorAlert } from '../../common/ErrorAlert';
import { FormContainer } from '../../common/form/FormContainer';
import { Input } from '../../common/form/Input';
import { LanguageSelect } from '../../common/form/LanguageSelect';
import { Select } from '../../common/form/Select';
import { SubmitButton } from '../../common/form/SubmitButton';
import { Loader } from '../../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../../utils/validations';
import { SUPPORTED_LANGUAGES } from '../../../utils/i18n';
import { UserRoleSelect } from '../../common/form/UserRoleSelect';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';
import { PhoneInput } from '../../common/form/PhoneInput';
import { setErrorStateSnacks } from '../../../utils/error';
import { ErrorStateType } from '../../../models/common';
import { useBack } from '../../../hooks/useBack';

export const QcsAdminUserDetail: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/qcsuser');
  const { userId } = useParams();
  const user = useAppSelector(selectUser);
  const userState = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    if (userId === 'new') {
      dispatch(userActions.default());
    } else {
      dispatch(getUser(userId!));
    }
    setLoading(false);
  }, [userId, dispatch]);

  const handleSubmit = async (data: UserDto) => {
    setSaveError('');

    try {
      if (userId === 'new') {
        await qcUserApi.createQcsUser(data);
        enqueueSuccessSnackbar(t('admin.user.addSuccess'));
      } else {
        await qcUserApi.updateUser(userId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(qcsAdminUserListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'admin.user.addError'
      );
    }
  };

  if (loading || userState === FetchState.Loading) {
    return <Loader />;
  }

  if (userState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validate = (values: UserDto) => {
    const errors: Partial<Record<keyof UserDto, string>> = {};

    if (
      values.roles.some(
        (role) =>
          role === UserDtoRolesEnum.Tourniquet ||
          role === UserDtoRolesEnum.TourniquetTraining
      ) &&
      values.roles.length !== 1
    ) {
      errors.roles = t('user.errorInvalidRoleCombination') ?? undefined;
    }

    return errors;
  };

  return (
    <>
      <DetailHeader title="admin.user.detailTitle" backTo={backTo} />
      <FormikDebounce<UserDto>
        initialValues={{
          ...user,
          roles: userId === 'new' ? [UserDtoRolesEnum.AdminQcs] : user.roles,
        }}
        validationSchema={Yup.object().shape({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          email: validations.emailRequired(t),
          phone: validations.phoneOptional(t),
          roles: validations.arrayNotEmpty(t),
        })}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <Input
              name="name"
              label={t('admin.user.name')}
              maxLength={100}
              required
            />
            <Input
              name="lastName"
              label={t('admin.user.lastName')}
              maxLength={100}
              required
            />
            <PhoneInput name="phone" label={t('admin.company.phone')} />
            <Input
              name="email"
              label={t('admin.user.email')}
              maxLength={100}
              required
            />
            <LanguageSelect
              name="language"
              label={t('admin.user.language')}
              languages={SUPPORTED_LANGUAGES}
            />

            <UserRoleSelect
              name="roles"
              label={t('admin.user.roles')}
              disabled={true}
              required
            />

            <Select name="state" label={t('admin.user.state')}>
              <QcsMenuItem
                value={UserDtoStateEnum.Active}
                disabled={user.state !== UserDtoStateEnum.Active}
              >
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={UserDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={UserDtoStateEnum.Invited}>
                {t('entityState.INVITED')}
              </QcsMenuItem>
            </Select>

            <SaveError error={saveError} />
            <SubmitButton />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
