import {
  CompanyBusinessSettings,
  CreateUpdateInvitation,
  InvitationDto,
  InvitationDtoTypeEnum,
  SupplierDtoStateEnum,
  SupplierEmployeeDtoRoleEnum,
} from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { DetailHeader } from '../common/DetailHeader';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { FormContainer } from '../common/form/FormContainer';
import { invitationsApi, mediaApi } from '../../utils/api';
import { InvitationCreateStep1 } from './InvitationCreateStep1';
import { InvitationCreateStep2 } from './InvitationCreateStep2';
import { InvitationCreateStep3 } from './InvitationCreateStep3';
import { InvitationCreateStep4A } from './InvitationCreateStep4A';
import { InvitationCreateStep4B } from './InvitationCreateStep4B';
import { InvitationCreateStep4C } from './InvitationCreateStep4C';
import { InvitationCreateStep5 } from './InvitationCreateStep5';
import { QcsTypography } from '../common/basic/QcsTypography';
import { invitationListActions } from '../../store/entities/invitationList';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorSnacks } from '../../utils/error';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { getFirstLastNameObj } from '../../utils/format';
import { Identity } from '../../models/auth';
import { InvitationCreateForm } from '../../models/invitation';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';
import { useBack } from '../../hooks/useBack';

const getDefaultData = (
  identity: Identity,
  companyCustomization: CompanyBusinessSettings
): InvitationCreateForm => ({
  employee: { id: identity.id, name: identity.name },
  establishments: [],
  workplaces: [],
  activities: [],
  supplierType: 'select',
  supplierB: {
    state: SupplierDtoStateEnum.Active,
    name: '',
    ico: '',
    address: {
      street: '',
      city: '',
      country: '',
      postalCode: '',
    },
  },
  responsiblePersonB: {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    language: companyCustomization?.defaultLanguage,
    role: SupplierEmployeeDtoRoleEnum.ExternalManager,
    state: SupplierDtoStateEnum.Active,
  },
  responsiblePersonC: {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    language: companyCustomization?.defaultLanguage,
    role: SupplierEmployeeDtoRoleEnum.ExternalManager,
    state: SupplierDtoStateEnum.Active,
  },
  inProgress: false,
  disableVisitationNotification: false,
});

interface Props {
  invitationInProgress?: InvitationDto;
}

export const InvitationCreate: FC<Props> = ({ invitationInProgress }) => {
  const { t } = useTranslation();
  const { backTo, goBack } = useBack('/invitation');
  const dispatch = useAppDispatch();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();

  const getData = () => {
    if (invitationInProgress) {
      const supplierType =
        invitationInProgress.type === InvitationDtoTypeEnum.OneTime ||
        invitationInProgress.type === InvitationDtoTypeEnum.OneTimeEntry
          ? 'once'
          : invitationInProgress.temporarySupplier?.id
          ? 'select'
          : 'create';

      const data: InvitationCreateForm = {
        name: invitationInProgress.name,
        employee: invitationInProgress.employee
          ? {
              id: invitationInProgress.employee.id!,
              name: getFirstLastNameObj(invitationInProgress.employee),
            }
          : undefined,
        validFrom: invitationInProgress.validFrom,
        validTo: invitationInProgress.validTo,
        workplaces: invitationInProgress.workplaces?.map((x) => ({
          id: x.workplace!.id!,
          name: x.workplace?.name,
          nameEn: x.workplace?.nameEn,
          establishment: x.establishment,
        })),
        activities: invitationInProgress.activities?.map((x) => ({
          id: x.activity!.id!,
          name: x.activity?.name,
          nameEn: x.activity?.nameEn,
        })),
        establishments: [
          ...new Set(
            invitationInProgress.workplaces?.map((x) => ({
              id: x.establishment!.id!,
              name: x.establishment?.name,
              nameEn: x.establishment?.nameEn,
            }))
          ),
        ],
        responsiblePersonA:
          supplierType === 'select'
            ? invitationInProgress.temporarySupplierEmployee
            : undefined,
        responsiblePersonB:
          supplierType === 'create'
            ? invitationInProgress.temporarySupplierEmployee
            : undefined,
        responsiblePersonC:
          supplierType === 'once'
            ? invitationInProgress.temporarySupplierEmployee
            : undefined,
        supplierA:
          supplierType === 'select'
            ? invitationInProgress.temporarySupplier
            : undefined,
        supplierB:
          supplierType === 'create'
            ? invitationInProgress.temporarySupplier
            : undefined,
        note: invitationInProgress.note,
        noteInternal: invitationInProgress.noteInternal,
        supplierType: supplierType,
        attachments: invitationInProgress.attachments,
        inProgress: true,
        disableVisitationNotification: invitationInProgress.disableVisitationNotification ?? false,
      };

      return data;
    }

    return getDefaultData(identity, companyCustomization!);
  };

  const [stepNumber, setStepNumber] = useState(1);
  const [data, setData] = useState<InvitationCreateForm>(getData());
  const [oldFilesToRemove, setOldFilesToRemove] = useState<string[]>([]);

  const getResponsiblePerson = (formData: InvitationCreateForm) => {
    if (formData.supplierType === 'select') {
      return formData.responsiblePersonA!;
    }

    if (formData.supplierType === 'create') {
      return formData.responsiblePersonB!;
    }

    return formData.responsiblePersonC!;
  };

  const getResponsibleSupplier = (formData: InvitationCreateForm) => {
    if (formData.supplierType === 'select') {
      return formData.supplierA;
    }

    if (formData.supplierType === 'create') {
      return formData.supplierB;
    }

    return undefined;
  };

  const isLastStep = (formData: InvitationCreateForm) =>
    //Last step for variant A and B.
    stepNumber === 5 ||
    //Last step for variant C.
    (stepNumber === 4 && formData.supplierType === 'once');

  const handleSubmit = async (formData: InvitationCreateForm) => {
    if (!isLastStep(formData) && !formData.mode) {
      setData((prev) => ({ ...prev, ...formData }));
      setStepNumber((prev) => prev + 1);
      return;
    }

    const createData: CreateUpdateInvitation = {
      name: formData.name!,
      employee: formData.employee!,
      validFrom: formData.validFrom!,
      validTo: formData.validTo,
      workplaces: formData.workplaces!,
      activities: formData.activities!,
      //establishments: formData.establishments,
      responsiblePerson: getResponsiblePerson(formData),
      supplier: getResponsibleSupplier(formData),
      note: formData.note,
      noteInternal: formData.noteInternal,
      attachments: formData.attachments?.map((x) => x.id!),
      disableVisitationNotification: formData.disableVisitationNotification ?? false,
    };

    try {
      let invitationId;
      if (invitationInProgress) {
        await invitationsApi.updateInvitation(
          invitationInProgress.id!,
          createData
        );
        invitationId = invitationInProgress.id!;
      } else {
        const res = await invitationsApi.createInvitation(
          identity.companyId,
          createData
        );
        invitationId = res.data.id;
      }

      if (formData.mode === 'SEND') {
        await invitationsApi.markAsSend(invitationId);
      }

      for (const oldFile of oldFilesToRemove) {
        try {
          await mediaApi.deleteFile(oldFile);
        } catch {
          //Ignore error.
        }
      }

      enqueueSuccessSnackbar(t('invitation.create.success'));
      dispatch(invitationListActions.reload(true));
      dispatch(invitationTypeVisitListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'invitation.create.error');
    }
  };

  const handleGoBack = () => {
    setStepNumber((prev) => prev - 1);
  };

  const handleRemoveOldFile = (document: string) => {
    setOldFilesToRemove([...oldFilesToRemove, document]);
  };

  const handleDelete = async () => {
    if (!invitationInProgress) {
      return;
    }

    try {
      await invitationsApi.deleteInvitation(invitationInProgress.id!);
      enqueueSuccessSnackbar(t('invitation.create.deleteSucess'));
      dispatch(invitationListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorSnacks(
        err,
        enqueueErrorSnackbar,
        'invitation.create.deleteError'
      );
    }
  };

  const getTitleBySupplierType = (supplierType: string) => {
    if (supplierType === 'select')
      return t('invitation.create.step4SelectTitle');
    if (supplierType === 'create')
      return t('invitation.create.step4CreateTitle');
    if (supplierType === 'once') return t('invitation.create.step4OnceTitle');
  };

  return (
    <>
      <DetailHeader
        title={
          invitationInProgress
            ? 'invitation.create.inProgress'
            : 'invitation.create.title'
        }
        backText={invitationInProgress ? undefined : 'invitation.cancel'}
        backTo={backTo}
      />

      <FormContainer>
        <Box sx={{ width: '100%', marginBottom: '1rem' }}>
          <Stepper activeStep={stepNumber - 1} alternativeLabel>
            <Step>
              <StepLabel>{t('invitation.create.step1')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('invitation.create.step2')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('invitation.create.step3')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('invitation.create.step4')}</StepLabel>
            </Step>
            {data.supplierType !== 'once' && (
              <Step>
                <StepLabel>{t('invitation.create.step5')}</StepLabel>
              </Step>
            )}
          </Stepper>
        </Box>
      </FormContainer>

      <QcsTypography variant="h5" sx={{ marginBottom: '1rem' }}>
        {stepNumber === 1 && t('invitation.create.step1')}
        {stepNumber === 2 && t('invitation.create.step2')}
        {stepNumber === 3 && t('invitation.create.step3')}
        {stepNumber === 4 && getTitleBySupplierType(data.supplierType)}
        {stepNumber === 5 && t('invitation.create.step5title')}
      </QcsTypography>

      {stepNumber === 1 && (
        <InvitationCreateStep1
          invitationData={data}
          handleSubmit={handleSubmit}
          onRemoveOldFile={handleRemoveOldFile}
          onDelete={handleDelete}
        />
      )}

      {stepNumber === 2 && (
        <InvitationCreateStep2
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}

      {stepNumber === 3 && (
        <InvitationCreateStep3
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}

      {stepNumber === 4 && data.supplierType === 'select' && (
        <InvitationCreateStep4A
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
      {stepNumber === 4 && data.supplierType === 'create' && (
        <InvitationCreateStep4B
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
      {stepNumber === 4 && data.supplierType === 'once' && (
        <InvitationCreateStep4C
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}

      {stepNumber === 5 && (
        <InvitationCreateStep5
          invitationData={data}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};
