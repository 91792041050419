import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { store, useAppSelector } from '../../../store';
import { DetailHeader } from '../../common/DetailHeader';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DetailForm } from './Tabs/DetailForm';
import { WorkplaceTable } from './Tabs/WorkplaceTable';
import { QcsTypography } from '../../common/basic/QcsTypography';
import { parseISO } from 'date-fns';
import { DateFormat } from '../../../utils/date';
import { RepairLineBreaks } from '../../common/RepairLineBreaks';
import { selectIdentity } from '../../../store/entities/identity';
import { hasRole, hasOtherRoleThan, isExternal } from '../../../utils/roles';
import {
  InvitationDtoStateEnum,
  InvitationDtoTypeEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { ActivityTable } from './Tabs/ActivityTable';
import { InvitedPersonsTable } from './Tabs/InvitedPersonsTable';
import { AttachmentTable } from './Tabs/AttachmentTable';
import {
  getFirstLastNameObj,
  getLangNameObj,
  invitationCertificateIsApproved,
  invitationTrainingIsCompleted,
  joinStrings,
} from '../../../utils/format';
import { QcsLoadingButton } from '../../common/basic/QcsLoadingButton';
import { invitationsApi } from '../../../utils/api';
import { setErrorSnacks } from '../../../utils/error';
import { invitationListActions } from '../../../store/entities/invitationList';
import { LogTable } from './Tabs/LogTable';
import {
  InvitationEditTabLabel,
  InvitationIconType,
} from './InvitationEditTabLabel';
import { SafetyEquipmentTable } from './Tabs/SafetyEquipmentTable';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { InvitationEditGeneralModal } from './InvitationEditGeneralModal';
import { useInvitation, invitationLock } from './Tabs/invitationFunctions';
import { INVITATION_AUDIT_TIMEOUT_MS } from '../../../utils/constants';
import {
  invitationStateAudActions,
  selectInvitationStateAud,
} from '../../../store/entities/invitationStateAud';
import { invitationActions } from '../../../store/entities/invitation';
import { invitationHistoryListActions } from '../../../store/entities/invitationHistoryList';
import axios, { CancelTokenSource } from 'axios';
import { useBack } from '../../../hooks/useBack';

export const InvitationEdit: FC = () => {
  const {
    t,
    i18n,
    invitation,
    dispatch,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const identity = useAppSelector(selectIdentity);
  const { backTo, goBack, navigate } = useBack('/invitation');
  const { invitationId, tabId = 'detail' } = useParams();
  const theme = useTheme();
  const [editIsOpen, setEditIsOpen] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    timeoutRef.current = setTimeout(handleTimer, INVITATION_AUDIT_TIMEOUT_MS);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelToken.current!.cancel();
      clearTimeout(timeoutRef.current!);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimer = async () => {
    try {
      await handleTimer2();
    } catch (err) {
      //Ignore.
    }

    if (cancelToken.current!.token.reason) {
      return;
    }

    timeoutRef.current = setTimeout(handleTimer, INVITATION_AUDIT_TIMEOUT_MS);
  };

  const handleTimer2 = async () => {
    if (invitationLock.locked) {
      return;
    }

    const auditResponse = await invitationsApi.getInvitationAudit(
      invitationId!,
      0,
      1,
      undefined,
      { cancelToken: cancelToken.current!.token }
    );

    if (invitationLock.locked) {
      return;
    }

    const data = auditResponse.data.content?.[0];
    if (!data) {
      return;
    }

    const oldData = selectInvitationStateAud(store.getState());
    if (oldData?.timestamp === data.timestamp) {
      return;
    }

    cancelToken.current!.token.throwIfRequested();
    dispatch(invitationStateAudActions.success(data));

    const dataResponse = await invitationsApi.getInvitationById(invitationId!, {
      cancelToken: cancelToken.current!.token,
    });

    cancelToken.current!.token.throwIfRequested();
    dispatch(invitationActions.success(dataResponse.data));
    dispatch(invitationListActions.reload(true));
    dispatch(invitationHistoryListActions.reload(true));
  };

  const handleOpenEdit = () => {
    setEditIsOpen(true);
  };

  const handleCloseEdit = () => {
    setEditIsOpen(false);
  };

  const handleArchive = async () => {
    setIsSaving(true);
    try {
      await invitationsApi.deleteInvitation(invitation.id!);
      enqueueSuccessSnackbar(t('invitation.archiveSucess'));
      dispatch(invitationListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'invitation.archiveError');
    }
    setIsSaving(false);
  };

  const handleActiveTab = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/invitation/${invitationId}/${newValue}`);
  };

  const formatDate = (value?: string | null) =>
    value ? <DateFormat value={parseISO(value)} /> : undefined;

  const isOneTime =
    invitation.type === InvitationDtoTypeEnum.OneTime ||
    invitation.type === InvitationDtoTypeEnum.OneTimeEntry ||
    hasRole(identity.roles, [UserDtoRolesEnum.ExternalWorker]);

  const isExternalManager = hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
  ]);

  const tabRights = hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
    UserDtoRolesEnum.ExternalOneTime,
    UserDtoRolesEnum.AdminQcs,
    UserDtoRolesEnum.AdminCompany,
    UserDtoRolesEnum.ManagerOsah,
    UserDtoRolesEnum.ManagerWorkplace,
    UserDtoRolesEnum.Receptionist,
    UserDtoRolesEnum.ExternalWorker,
  ]);
  const enableTabs =
    tabRights &&
    //One time has no "checked" state.
    (isOneTime ||
      !isExternalManager ||
      //Supplier details already checked - NOT IN.
      (invitation.state !== InvitationDtoStateEnum.Sent &&
        invitation.state !== InvitationDtoStateEnum.Viewed));
  const enableAttachmentsTab =
    invitation.attachments && invitation.attachments.length > 0;

  const allPersonAllTrainingCompleted = useMemo(
    () =>
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.Receptionist,
        UserDtoRolesEnum.ExternalManager,
      ]) ||
      (invitation.persons ?? []).every((person) =>
        (person.trainings ?? []).every((training) =>
          invitationTrainingIsCompleted(training)
        )
      ),
    [identity.roles, invitation.persons]
  );

  const allPersonAllDocumentUploaded = useMemo(
    () =>
      (invitation.persons ?? []).every((person) =>
        (person.documents ?? []).every((document) =>
          isExternal(identity)
            ? !!document.document
            : !!document.document && invitationCertificateIsApproved(document)
        )
      ),
    [identity, invitation.persons]
  );

  const anyActivityWithoutPerson = useMemo(
    () =>
      (invitation.activities ?? []).some(
        (activity) =>
          (invitation.persons ?? []).length === 0 ||
          !invitation.persons!.some((person) =>
            person.activities.some(
              (personActivity) => personActivity.id === activity.activity?.id
            )
          )
      ),
    [invitation.persons, invitation.activities]
  );

  const anyWorkplaceWithoutPerson = useMemo(
    () =>
      (invitation.workplaces ?? []).some(
        (workplace) =>
          (invitation.persons ?? []).length === 0 ||
          !invitation.persons!.some((person) =>
            person.workplaces.some(
              (personWorkplace) =>
                personWorkplace.id === workplace.workplace?.id
            )
          )
      ),
    [invitation.persons, invitation.workplaces]
  );

  const allWorkplaceAllRiskApproved = useMemo(
    () =>
      (invitation.workplaces ?? []).every((workplace) =>
        (workplace.risks ?? []).every((risk) => !!risk.approvedOn)
      ),
    [invitation.workplaces]
  );

  const allActivityAllApproved = useMemo(
    () =>
      (invitation.activities ?? []).every(
        (activity) => !activity.activity?.riskRequired || !!activity.approvedOn
      ),
    [invitation.activities]
  );

  const allActivityAllDocument = useMemo(
    () =>
      (invitation.activities ?? []).every(
        (activity) => !activity.activity?.riskRequired || !!activity.document
      ),
    [invitation.activities]
  );

  return (
    <>
      <InvitationEditGeneralModal open={editIsOpen} onClose={handleCloseEdit} />

      <DetailHeader title={getLangNameObj(i18n, invitation)} backTo={backTo} />

      <QcsBox
        sx={{
          pb: 3,
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', sm: 'auto max-content' },
          gridTemplateAreas: { xs: '"buttons" "title"', sm: '"title buttons"' },
          gap: '2rem',
          width: '100%',
          maxWidth: theme.breakpoints.values.md + 24,
        }}
      >
        <QcsBox sx={{ gridArea: 'title' }}>
          {/* Note */}
          {!!invitation.note && (
            <div style={{ marginBottom: '1rem' }}>
              <QcsTypography variant="subtitle1">
                <strong>{`${t('invitation.note')}:`}</strong>
              </QcsTypography>
              <QcsTypography variant="subtitle1">
                <RepairLineBreaks text={invitation.note} />
              </QcsTypography>
            </div>
          )}
          {/* Platnost od - do */}
          <QcsTypography variant="subtitle1">
            <strong>{`${t('invitation.validFromDetail')}:`}</strong>{' '}
            {formatDate(invitation.validFrom)}
            {invitation.validTo && (
              <>
                {' '}
                {t('invitation.validToDetail')} {formatDate(invitation.validTo)}
              </>
            )}
          </QcsTypography>
          {/* Zodpovedna osoba - jmeno, prijmeni, e-mail, telefon */}
          <QcsTypography variant="subtitle1">
            <strong>{`${t('invitation.responsiblePerson')}:`}</strong>{' '}
            {joinStrings(
              getFirstLastNameObj(invitation.employee),
              invitation.employee?.email,
              invitation.employee?.phone
            )}
          </QcsTypography>
          {/* Zodpovedna osoba dodavatele - jmeno, prijmeni, e-mail, telefon */}
          <QcsTypography variant="subtitle1">
            <strong>{`${t(
              isOneTime
                ? 'invitation.visit'
                : 'invitation.responsibleSupplierPerson'
            )}:`}</strong>{' '}
            {joinStrings(
              getFirstLastNameObj(invitation.responsiblePerson),
              invitation.responsiblePerson?.email,
              invitation.responsiblePerson?.phone
            )}
          </QcsTypography>
          {!!invitation.noteInternal && (
            <div style={{ marginTop: '1rem' }}>
              <QcsTypography variant="subtitle1">
                <strong>{`${t('invitation.noteInternal')}:`}</strong>
              </QcsTypography>
              <QcsTypography variant="subtitle1">
                <RepairLineBreaks text={invitation.noteInternal} />
              </QcsTypography>
            </div>
          )}
        </QcsBox>
        <QcsBox
          sx={{
            gridArea: 'buttons',
            pb: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            textAlign: 'center',
          }}
        >
          <QcsTypography
            variant="subtitle1"
            sx={{ color: theme.palette.primary.main }}
          >
            <strong>{t(`invitation.states.${invitation.state}`)}</strong>
          </QcsTypography>
          {invitation.state !== InvitationDtoStateEnum.Deleted &&
            invitation.state !== InvitationDtoStateEnum.Archived &&
            hasRole(identity.roles, [
              UserDtoRolesEnum.AdminCompany,
              UserDtoRolesEnum.Receptionist,
              UserDtoRolesEnum.ManagerOsah,
              UserDtoRolesEnum.ManagerWorkplace,
            ]) && (
              <QcsButton onClick={handleOpenEdit} variant="outlined">
                {t('invitation.edit')}
              </QcsButton>
            )}
          {invitation.state !== InvitationDtoStateEnum.Deleted &&
            invitation.state !== InvitationDtoStateEnum.InProgress &&
            invitation.state !== InvitationDtoStateEnum.Archived &&
            hasOtherRoleThan(identity.roles, [
              UserDtoRolesEnum.ExternalManager,
            ]) && (
              <QcsLoadingButton
                loading={isSaving}
                onClick={handleArchive}
                variant="outlined"
                color="error"
              >
                {t('invitation.archive')}
              </QcsLoadingButton>
            )}
        </QcsBox>
      </QcsBox>
      <QcsBox>
        <TabContext value={tabId}>
          <QcsBox>
            <TabList
              onChange={handleActiveTab}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              {!isOneTime && (
                <Tab
                  key="tab1"
                  label={
                    <InvitationEditTabLabel
                      value="detail"
                      type={
                        invitation.state === InvitationDtoStateEnum.Sent ||
                        invitation.state === InvitationDtoStateEnum.Viewed
                          ? InvitationIconType.Warning
                          : InvitationIconType.Ok
                      }
                    />
                  }
                  value="detail"
                />
              )}
              {enableTabs && (
                <Tab
                  key="tab2"
                  label={
                    <InvitationEditTabLabel
                      value={isOneTime ? 'visit' : 'person'}
                      type={
                        allPersonAllTrainingCompleted &&
                        allPersonAllDocumentUploaded &&
                        !anyActivityWithoutPerson &&
                        !anyWorkplaceWithoutPerson
                          ? InvitationIconType.Ok
                          : InvitationIconType.Warning
                      }
                    />
                  }
                  value="person"
                />
              )}
              {enableTabs && !isOneTime && (
                <Tab
                  key="tab3"
                  label={
                    <InvitationEditTabLabel
                      value="workplace"
                      type={
                        allWorkplaceAllRiskApproved &&
                        !anyWorkplaceWithoutPerson
                          ? InvitationIconType.Ok
                          : InvitationIconType.Warning
                      }
                    />
                  }
                  value="workplace"
                />
              )}
              {enableTabs && !isOneTime && (
                <Tab
                  key="tab4"
                  label={
                    <InvitationEditTabLabel
                      value="activity"
                      type={
                        (isExternal(identity) || allActivityAllApproved) &&
                        allActivityAllDocument &&
                        !anyActivityWithoutPerson &&
                        allPersonAllDocumentUploaded
                          ? InvitationIconType.Ok
                          : InvitationIconType.Warning
                      }
                    />
                  }
                  value="activity"
                />
              )}
              {enableAttachmentsTab && (
                <Tab
                  key="tab5"
                  label={
                    <InvitationEditTabLabel
                      value="attachment"
                      type={InvitationIconType.Info}
                    />
                  }
                  value="attachment"
                />
              )}
              <Tab
                key="tab6"
                label={
                  <InvitationEditTabLabel
                    value="safetyEquipment"
                    type={InvitationIconType.Info}
                  />
                }
                value="safetyEquipment"
              />
              <Tab
                key="tab7"
                label={
                  <InvitationEditTabLabel
                    value="log"
                    type={InvitationIconType.Info}
                  />
                }
                value="log"
              />
            </TabList>
          </QcsBox>
          {!isOneTime && (
            <TabPanel value="detail">
              <DetailForm />
            </TabPanel>
          )}
          {enableTabs && (
            <TabPanel value="person">
              <InvitedPersonsTable />
            </TabPanel>
          )}
          {enableTabs && !isOneTime && (
            <TabPanel value="workplace">
              <WorkplaceTable />
            </TabPanel>
          )}
          {enableTabs && !isOneTime && (
            <TabPanel value="activity">
              <ActivityTable />
            </TabPanel>
          )}
          {enableAttachmentsTab && (
            <TabPanel value="attachment">
              <AttachmentTable />
            </TabPanel>
          )}
          <TabPanel value="safetyEquipment">
            <SafetyEquipmentTable />
          </TabPanel>
          <TabPanel value="log">
            <LogTable />
          </TabPanel>
        </TabContext>
      </QcsBox>
    </>
  );
};
