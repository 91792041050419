export const generateManifest = (
  userId: string,
  icon: string,
  appName: string
) => ({
  short_name: appName,
  name: appName,
  icons: [
    {
      src: icon,
      type: 'image/png',
      sizes: '192x192',
    },
    {
      src: icon,
      type: 'image/png',
      sizes: '512x512',
    },
  ],
  start_url: `${window.location.href}?userId=${userId}`,
  id: userId,
  background_color: '#ffffff',
  display: 'standalone',
  scope: `${window.location.href}?userId=${userId}`,
  theme_color: '#000000',

  // Each application has it's own independent session
  // This is needed to prevent session conflicts
  incognito: 'split',
});
