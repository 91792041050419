import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { TrainingDto } from '@qcs/safety-client';
import { workplaceApi } from '../../utils/api';
import { baseSlice, BaseType, getItem } from '../baseHelper';

export type WorkplaceTrainingsState = BaseType<TrainingDto[]>;

const initialState: WorkplaceTrainingsState = {
  state: FetchState.None,
  data: [],
};

export const workplaceTrainingsSlice = baseSlice({
  name: 'workplaceTrainings',
  initialState,
});

export const selectWorkplaceTrainingsState = (state: RootState) =>
  state.workplaceTrainings.state;
export const selectWorkplaceTrainings = (state: RootState) =>
  state.workplaceTrainings.data;
export const workplaceTrainingsActions = workplaceTrainingsSlice.actions;

export const getWorkplaceTrainings =
  (workplaceId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return workplaceApi.getTrainingsByWorkplace(workplaceId);
    };

    getItem({
      dispatch,
      actions: workplaceTrainingsActions,
      getData: handleGetData,
    });
  };
