import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { QuestionnaireVersionDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { questionnairesApi } from '../../utils/api';

type State = BaseType<QuestionnaireVersionDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    languages: [],
    validFrom: '',
    validTo: '',
    note: '',
    successRate: 0,
    editable: true,
    first: false,
  },
};

export const questionnaireVersionSlice = baseSlice({
  name: 'questionnaireVersion',
  initialState,
});

export const selectQuestionnaireVersionState = (state: RootState) =>
  state.questionnaireVersion.state;
export const selectQuestionnaireVersion = (state: RootState) =>
  state.questionnaireVersion.data;
export const selectQuestionnaireVersionLanguages = (state: RootState) =>
  state.questionnaireVersion.data.languages;

export const questionnaireVersionActions = questionnaireVersionSlice.actions;

export const getQuestionnaireVersion =
  (questionnaireVersionId: string) => (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      return questionnairesApi.findVersionById(questionnaireVersionId);
    };

    getItem({
      dispatch,
      actions: questionnaireVersionActions,
      getData: handleGetData,
    });
  };
