import { ActivityDtoStateEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelect } from './GridFilterSelect';

const options = Object.values(ActivityDtoStateEnum);

interface Props {
  value?: ActivityDtoStateEnum;
  onChange: (value?: ActivityDtoStateEnum) => void;
}

export const GridFilterState: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <GridFilterSelect
      value={value}
      options={options}
      onChange={onChange}
      getOptionId={(option: ActivityDtoStateEnum) => option}
      getOptionName={(option: ActivityDtoStateEnum) =>
        t(`entityState.${option}`)
      }
    />
  );
};
