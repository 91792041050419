import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SubmitButton } from '../common/form/SubmitButton';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { Radio } from '@mui/material';
import { RadioGroup } from '../common/form/RadioGroup';
import { InvitationCreateForm } from '../../models/invitation';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';
import { RepairLineBreaks } from '../common/RepairLineBreaks';

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep3: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        supplierType: validations.stringRequired(t),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormContainer>
          <RadioGroup name="supplierType">
            <QcsFormControlLabel
              value="select"
              control={<Radio />}
              label={t('invitation.create.supplierType.select')}
            />
            <QcsFormControlLabel
              value="create"
              control={<Radio />}
              label={t('invitation.create.supplierType.create')}
            />
            <QcsFormControlLabel
              value="once"
              control={<Radio />}
              label={
                <RepairLineBreaks
                  text={t('invitation.create.supplierType.once')}
                />
              }
            />
          </RadioGroup>

          <InvitationButtonsContainer>
            <QcsButton variant="outlined" onClick={handleGoBack}>
              {t('common.back')}
            </QcsButton>
            <div style={{ width: '100%' }} />
            <InvitationButton type="DELETE" customEvent={onDelete} />
            <InvitationButton type="SAVE" />
            <SubmitButton text={t('common.nextStep')} />
          </InvitationButtonsContainer>
        </FormContainer>
      </Form>
    </FormikDebounce>
  );
};
