import {
  ReferenceDto,
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../../common/form/FormikDebounce';
import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { validations } from '../../../../utils/validations';
import { SaveError } from '../../../common/SaveError';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { FormContainer } from '../../../common/form/FormContainer';
import { SubmitButton } from '../../../common/form/SubmitButton';
import { SupplierEmployeeAutocomplete } from '../../../common/form/SupplierEmployeeAutocomplete';
import { SectionHeader } from '../../../common/SectionHeader';
import { setErrorStateSnacks } from '../../../../utils/error';
import { QcsAutocomplete } from '../../../common/form/QcsAutocomplete';
import { QcsMenuItem } from '../../../common/basic/QcsMenuItem';
import { Input } from '../../../common/form/Input';
import { PhoneInput } from '../../../common/form/PhoneInput';
import { LanguageSelect } from '../../../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../../../utils/i18n';
import { useAppSelector } from '../../../../store';
import { selectCompanyCustomization } from '../../../../store/entities/companyCustomization';
import { Select } from '../../../common/form/Select';
import { supplierEmployeeApi } from '../../../../utils/api';
import { getLangNameObj, getWorkplaceNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';

export interface InvitedPersonsAddModalData {
  supplierEmployee: SupplierEmployeeDto;
  activities: ReferenceDto[];
  workplaces: ReferenceDto[];
}

export interface InvitedPersonsAddModalForm {
  variant: 'exists' | 'new';
  existsSupplierEmployee?: SupplierEmployeeDto | null;
  newSupplierEmployee: SupplierEmployeeDto;
  activities: ReferenceDto[];
  workplaces: ReferenceDto[];
}

interface Props {
  supplierId?: string;
  initActivities: ReferenceDto[];
  initWorkplaces: ReferenceDto[];
  open: boolean;
  onClose: (data?: InvitedPersonsAddModalData) => Promise<void>;
  excludeIds: string[];
}

export const InvitedPersonsAddModal: FC<Props> = ({
  supplierId,
  initActivities,
  initWorkplaces,
  open,
  onClose,
  excludeIds,
}) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    saveError,
    setSaveError,
  } = useInvitation();

  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    setSaveError('');
  }, [open, setSaveError]);

  const handleSubmit = async (data: InvitedPersonsAddModalForm) => {
    setSaveError('');
    lockInvitation();
    try {
      if (data.variant === 'exists') {
        const data2: InvitedPersonsAddModalData = {
          supplierEmployee: data.existsSupplierEmployee!,
          activities: data.activities,
          workplaces: data.workplaces,
        };

        await onClose(data2);
      } else {
        const res = await supplierEmployeeApi.createSupplierEmployee(
          invitation.supplier!.id!,
          data.newSupplierEmployee
        );
        const data2: InvitedPersonsAddModalData = {
          supplierEmployee: { ...data.newSupplierEmployee, id: res.data.id },
          activities: data.activities,
          workplaces: data.workplaces,
        };

        await onClose(data2);
      }

      enqueueSuccessSnackbar(t('common.saveSuccess'));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    unlockInvitation();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <QcsModal open={open} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.persons.add" />
        <FormikDebounce<InvitedPersonsAddModalForm>
          initialValues={{
            variant: 'exists',
            existsSupplierEmployee: null,
            newSupplierEmployee: {
              id: '',
              name: '',
              lastName: '',
              jobPosition: '',
              phone: '',
              email: '',
              language: '',
              note: '',
              role: SupplierEmployeeDtoRoleEnum.ExternalWorker,
              state: SupplierEmployeeDtoStateEnum.Active,
            },
            activities: initActivities,
            workplaces: initWorkplaces,
          }}
          validationSchema={Yup.object().shape({
            existsSupplierEmployee: Yup.object()
              .nullable()
              .when('variant', {
                is: (variant: string) => variant === 'exists',
                then: () => validations.objectRequired(t),
              }),
            newSupplierEmployee: Yup.object().when('variant', {
              is: (variant: string) => variant === 'new',
              then: () =>
                Yup.object({
                  name: validations.stringRequired(t),
                  lastName: validations.stringRequired(t),
                  phone: validations.phoneOptional(t),
                  email: validations.emailOptional(t),
                  language: validations.stringRequired(t),
                }),
            }),
            activities: validations.arrayNotEmpty(t),
            workplaces: validations.arrayNotEmpty(t),
          })}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <FormContainer>
                <Select name="variant">
                  <QcsMenuItem value="exists">
                    {t('invitation.persons.addExists')}
                  </QcsMenuItem>
                  <QcsMenuItem value="new">
                    {t('invitation.persons.addNew')}
                  </QcsMenuItem>
                </Select>

                {values.variant === 'exists' && (
                  <SupplierEmployeeAutocomplete
                    name="existsSupplierEmployee"
                    label={t('invitation.persons.person')}
                    required
                    supplierId={supplierId}
                    excludeIds={excludeIds}
                    state={[
                      SupplierEmployeeDtoStateEnum.Active,
                      SupplierEmployeeDtoStateEnum.Invited,
                    ]}
                  />
                )}

                {values.variant === 'new' && (
                  <>
                    <Input
                      name="newSupplierEmployee.name"
                      label={t('supplierEmployee.name')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="newSupplierEmployee.lastName"
                      label={t('supplierEmployee.lastName')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="newSupplierEmployee.jobPosition"
                      label={t('supplierEmployee.jobPosition')}
                      maxLength={100}
                    />
                    <PhoneInput
                      name="newSupplierEmployee.phone"
                      label={t('supplierEmployee.phone')}
                    />
                    <Input
                      name="newSupplierEmployee.email"
                      label={t('supplierEmployee.email')}
                      maxLength={100}
                    />
                    <LanguageSelect
                      name="newSupplierEmployee.language"
                      label={t('user.language')}
                      languages={SUPPORTED_LANGUAGES}
                      allowedLanguages={
                        companyCustomization?.applicationLanguages
                      }
                      required
                    />
                    <Input
                      name="newSupplierEmployee.note"
                      label={t('supplierEmployee.note')}
                      maxLength={250}
                      multiline={true}
                      rows={4}
                    />
                  </>
                )}

                <QcsAutocomplete
                  name="activities"
                  label={t('invitation.persons.activities')}
                  required={true}
                  multiple={true}
                  options={invitation.activities?.map((x) => ({
                    id: x.activity?.id,
                    name: x.activity?.name,
                    nameEn: x.activity?.nameEn,
                  }))}
                  getOptionId={(option) => option.id!}
                  getOptionName={(option) => getLangNameObj(i18n, option)}
                  loading={false}
                  loadingError={false}
                />
                <QcsAutocomplete
                  name="workplaces"
                  label={t('invitation.persons.workplaces')}
                  required={true}
                  multiple={true}
                  options={invitation.workplaces?.map((x) => ({
                    id: x.workplace?.id,
                    name: getWorkplaceNameObj(
                      i18n,
                      x.workplace,
                      x.establishment
                    ),
                  }))}
                  getOptionId={(option) => option.id!}
                  getOptionName={(option) => getLangNameObj(i18n, option)}
                  loading={false}
                  loadingError={false}
                />

                <SaveError error={saveError} />
                <SubmitButton />
              </FormContainer>
            </Form>
          )}
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
