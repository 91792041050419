import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';
import { QcsModalBox } from '../common/basic/QcsModalBox';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { qcUserApi, refreshToken } from '../../utils/api';
import { selectIdentity } from '../../store/entities/identity';
import { useAppSelector } from '../../store';
import { setErrorStateSnacks } from '../../utils/error';
import { isRemote } from '../../utils/roles';
import { ErrorStateType } from '../../models/common';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

interface LangForm {
  lang: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ChangeLangModal: FC<Props> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (data: LangForm) => {
    setSaveError('');
    try {
      if (!isRemote(identity)) {
        //Load and save selected language to user preference.
        const preferences = await qcUserApi.getUserPreferences(identity.id);

        const data2 = {
          ...preferences.data,
          language: data.lang,
        };

        await qcUserApi.updateUserPreferences(identity.id, data2);

        //Get new token with new language. If user refresh page, its load with new language.
        await refreshToken();
      }

      //Change language in UI now.
      i18n.changeLanguage(data.lang);

      enqueueSuccessSnackbar(t('changeLang.changeSuccess'));
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'changeLang.changeError'
      );
    }
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="changeLang.title" />
        <FormikDebounce<LangForm>
          initialValues={{ lang: i18n.resolvedLanguage! }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <LanguageSelect
                name={'lang'}
                label={t('changeLang.lang')}
                languages={SUPPORTED_LANGUAGES}
                allowedLanguages={companyCustomization?.applicationLanguages}
                hideNonSelected={true}
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
