import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QcsPaper } from '../common/basic/QcsPaper';

export const DashboardContainer = styled.div(({ theme }) => ({
  maxWidth: '1090px',
  display: 'grid',
  gap: '1rem',

  gridTemplateAreas:
    '"header" "company" "establishment" "myPersonsOnPremises" "personsOnPremises" "totalWorkers" "validInvitations"',
  gridTemplateColumns: '1fr',

  [theme.breakpoints.up('md')]: {
    gridTemplateAreas:
      '"header header" "company establishment" "myPersonsOnPremises personsOnPremises" "totalWorkers validInvitations"',
    gridTemplateColumns: '1fr 1fr',
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateAreas:
      '"header header header header" "company company establishment establishment" "myPersonsOnPremises personsOnPremises totalWorkers validInvitations"',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },

  canvas: {
    width: '100% !important',
  },
}));

export const DashboardHeader = styled.div(({ theme }) => ({
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const DashboardItemContainer = styled(QcsPaper)(() => ({
  padding: '1rem',
  position: 'relative',
}));

export const DashboardItemIcon = styled(FontAwesomeIcon)(() => ({
  position: 'absolute',
  bottom: '1rem',
  right: '1rem',
  height: '50%',
  opacity: 0.2,
}));
