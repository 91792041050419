import { useFormikContext } from 'formik';
import { FC, useRef, useState } from 'react';
import _ from 'lodash';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { BannerInputUploadModal } from './BannerInputUploadModal';
import { HeaderLogo } from '../layout/HeaderStyles';
import { QcsTypography } from '../common/basic/QcsTypography';
import styled from '@emotion/styled';
import { BASE_URL_WITH_VERSION } from '../../utils/api';
import { getMediaToken } from '../../utils/storage';

const BannerWrapper = styled.div(() => ({
  height: '5rem',
}));

interface Props {
  onRemoveOldFile: (document: string) => void;
}

export const BannerInput: FC<Props> = ({ onRemoveOldFile }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const mediaToken = getMediaToken();

  const banner = _.get(values, 'banner');
  const bannerUrl = _.get(values, 'bannerUrl');

  const handleUploadFile = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);

    fileRef.current.value = '';
  };

  const handleFileUploaded = (url: string, id: string) => {
    if (!selectedFile) {
      return;
    }

    if (banner) {
      onRemoveOldFile(banner);
    }

    setFieldValue('banner', id);
    setFieldValue('bannerUrl', url);

    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  return (
    <>
      <QcsTypography variant="h6">
        {t('qcsCompanySettings.banner')}
      </QcsTypography>
      {!!bannerUrl && (
        <BannerWrapper>
          <HeaderLogo
            src={`${BASE_URL_WITH_VERSION}${bannerUrl}?token=${mediaToken}`}
          />
        </BannerWrapper>
      )}
      <BannerInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />
      <div>
        <QcsButton onClick={handleUploadFile}>
          {t('common.uploadFile')}
        </QcsButton>
      </div>
    </>
  );
};
