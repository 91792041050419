import { FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { QcsFormControl } from './basic/QcsFormControl';
import { QcsOutlinedInput } from './basic/QcsOutlinedInput';
import { InputAdornment as QcsInputAdornment } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { debounce } from '@mui/material';
import { DEBOUNCE_TIMEOUT_MS } from '../../utils/constants';
import styled from '@emotion/styled';

const StyledQcsOutlinedInput = styled(QcsOutlinedInput)(() => ({
  maxHeight: '2.5rem',
}));

interface Props {
  className?: string;
  defaultValue: string;
  onChanged: (value: string) => void;
}

export const Search: FC<Props> = ({ className, defaultValue, onChanged }) => {
  const { t } = useTranslation();

  return (
    <QcsFormControl className={className}>
      <StyledQcsOutlinedInput
        type="text"
        placeholder={t('common.search')}
        autoComplete="off"
        defaultValue={defaultValue}
        onChange={debounce(
          (event) => onChanged(event.target.value as string),
          DEBOUNCE_TIMEOUT_MS
        )}
        endAdornment={
          <QcsInputAdornment position="end">
            <SearchIcon />
          </QcsInputAdornment>
        }
      />
    </QcsFormControl>
  );
};
