import React, { FC, forwardRef, useState } from 'react';
import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import ReactPhoneInput from 'react-phone-input-2';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { QcsOutlinedInput } from '../basic/QcsOutlinedInput';

//import 'react-phone-input-2/lib/high-res.css';
import './PhoneInput.css';

//Fix for minified build: https://github.com/bl00mber/react-phone-input-2/issues/533
const PhoneInputComponent: typeof ReactPhoneInput = (ReactPhoneInput as any)
  .default
  ? (ReactPhoneInput as any).default
  : ReactPhoneInput;

const MyComponent = forwardRef<HTMLInputElement, any>(
  (
    { className, onAnimationStart, onBlur, onFocus, onChange, value, disabled },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    return (
      <PhoneInputComponent
        value={value}
        onChange={onChange}
        inputProps={{
          autoComplete: 'off',
          className,
          onAnimationStart,
        }}
        country="cz"
        preferredCountries={['cz', 'sk']}
        countryCodeEditable={false}
        specialLabel=""
        containerStyle={{ width: '100%' }}
        inputStyle={{ width: 'calc(100% - 74px)' }}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  }
);

export const PhoneInput: FC<{
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}> = ({ name, label, disabled, required }) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<any>();
  const [lastDialCode, setLastDialCode] = useState('+420');

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const theme = useTheme();

  const inputValue = value ? value : lastDialCode;

  const handleChange = (
    value: string,
    country: { dialCode?: string },
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const dialCode = '+' + country.dialCode;
    setLastDialCode(dialCode);

    if (formattedValue === dialCode) {
      formattedValue = '';
    }

    setFieldValue(name, formattedValue);
  };

  const handleBlur = () => {
    setFieldTouched(name);
  };

  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel required={required} shrink={true} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsOutlinedInput
        label={label}
        error={hasError}
        sx={{ backgroundColor: theme.palette.common.white }}
        onChange={handleChange as any}
        value={inputValue}
        disabled={disabled}
        inputComponent={MyComponent}
        onBlur={handleBlur}
        notched={true}
      />
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
