import { TablePagination } from '@mui/material';
import { FC } from 'react';
import { PAGINATION_OPTIONS } from '../../../utils/constants';

interface Props {
  className?: string;
  count: number;
  rowsPerPage: number;
  page: number;
  rowsPerPageDisabled?: boolean;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const QcsTablePagination: FC<Props> = ({
  className,
  count,
  rowsPerPage,
  page,
  rowsPerPageDisabled,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onPageChange(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      className={className}
      rowsPerPageOptions={PAGINATION_OPTIONS}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      SelectProps={{
        disabled: rowsPerPageDisabled,
      }}
    />
  );
};
