import i18n from 'i18next';
import { SetErrorStateType, SnackMessageType } from '../models/common';

export const getErrorMessage = (err: any, defaultMessage?: string | null) => {
  if (err?.response?.data?.type?.match(/errors\/\d{3}$/g)) {
    const code = err.response.data.type.slice(-3);
    const trans = 'errorCodes.' + code;

    if (i18n.exists(trans)) {
      //CSV special error.
      if (code === '007' && err?.response?.data?.column) {
        return `${i18n.t(trans)} ${i18n
          .t(trans + '_detail')
          .replaceAll('{column}', err.response.data.column)
          .replaceAll('{row}', err.response.data.row)}`;
      }

      //PIN special error.
      if (code === '035' && err?.response?.data?.exceptions) {
        const detail: string[] = err.response.data.exceptions
          .map((x: { code: string }) => i18n.t('errorCodes.' + x.code))
          .filter((x: string | null | undefined) => !!x);

        if (detail.length) {
          return (
            <div>
              {i18n.t(trans)}
              <ul style={{ paddingLeft: '2rem' }}>
                {detail.map((x, i) => (
                  <li key={i}>{x}</li>
                ))}
              </ul>
            </div>
          );
        }
      }

      //Missing column in csv.
      if (code === '036' && err?.response?.data?.row) {
        return `${i18n.t('errorCodes.007')} ${i18n
          .t(trans)
          .replaceAll('{row}', err.response.data.row)}`;
      }

      return i18n.t(trans);
    }
  }

  if (err?.qcsCustomError) {
    const code = err?.qcsCustomError;
    const trans = 'errorCustomCodes.' + code;

    if (i18n.exists(trans)) {
      return i18n.t(trans);
    }
  }

  return i18n.t(defaultMessage ?? 'common.unknownError');
};

export const setErrorState = (
  err: any,
  setState: SetErrorStateType,
  defaultMessage?: string | null
) => {
  const res = getErrorMessage(err, defaultMessage);
  setState(res);
  return res;
};

export const setErrorSnacks = (
  err: any,
  enqueueErrorSnackbar: (message: SnackMessageType) => void,
  defaultMessage?: string | null
) => {
  const res = getErrorMessage(err, defaultMessage);
  enqueueErrorSnackbar(res);
  return res;
};

export const setErrorStateSnacks = (
  err: any,
  setState: SetErrorStateType,
  enqueueErrorSnackbar: (message: SnackMessageType) => void,
  defaultMessage?: string | null
) => {
  const res = getErrorMessage(err, defaultMessage);
  setState(res);
  enqueueErrorSnackbar(res);
  return res;
};

export const getCustomError = (code: string) => ({
  qcsCustomError: code,
});
