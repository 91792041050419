import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { setErrorStateSnacks } from '../../utils/error';
import { SupplierAutocomplete } from '../common/form/SupplierAutocomplete';
import { ErrorStateType } from '../../models/common';

interface Props {
  open: boolean;
  onClose: () => void;
  onSupplierSelectClick: (supplier: string) => void;
}

export const CreateSupplierEmployee: FC<Props> = ({
  open,
  onClose,
  onSupplierSelectClick,
}) => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (values: any) => {
    setSaveError('');

    try {
      onSupplierSelectClick(values.supplier.id);

      onClose();
      return;
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  return (
    <FormikDebounce<any>
      initialValues={{
        supplier: undefined,
        supplierEmployee: undefined,
      }}
      validationSchema={Yup.object({
        supplier: Yup.object().required(),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormContainer>
          <FormContainer>
            <SupplierAutocomplete
              name="supplier"
              label={t('visitation.supplier')}
              required={true}
              disabled={false}
            />
          </FormContainer>

          <SaveError error={saveError} />
          <SubmitButton />
        </FormContainer>
      </Form>
    </FormikDebounce>
  );
};
