import { TrainingDto, UserDtoRolesEnum } from '@qcs/safety-client';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { SectionHeader } from '../common/SectionHeader';
import {
  getWorkplaceTrainings,
  selectWorkplaceTrainings,
  selectWorkplaceTrainingsState,
} from '../../store/entities/workplaceTrainings';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { TableRow, TableBody } from '@mui/material';
import { QcsPaper } from '../common/basic/QcsPaper';
import { GridLoading } from '../common/grid/GridLoading';
import { FetchState } from '../../store/fetchState';
import { GridError } from '../common/grid/GridError';
import { GridEmpty } from '../common/grid/GridEmpty';
import { AddTrainingModal } from './AddTrainingModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { workplaceApi } from '../../utils/api';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import styled from '@emotion/styled';
import { QcsTableContainer } from '../common/basic/QcsTableContainer';
import { QcsTable } from '../common/basic/QcsTable';
import { QcsTableHead } from '../common/basic/QcsTableHead';
import { QcsTableRow } from '../common/basic/QcsTableRow';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTableBody } from '../common/basic/QcsTableBody';
import { CellDate } from '../common/grid/CellDate';
import { SaveError } from '../common/SaveError';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorSnacks, setErrorStateSnacks } from '../../utils/error';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { hasRole } from '../../utils/roles';
import { selectIdentity } from '../../store/entities/identity';

const SaveButtonWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '1rem',
  gap: '1rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const DroppableComponent =
  (onDragEnd: (result: DropResult, provided: ResponderProvided) => void) =>
  (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={'workplaceTrainingDraggable'}
          direction="vertical"
        >
          {(provided) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

const DraggableComponent = (id: string, index: number) => (props: any) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            userSelect: 'none',
            backgroundColor: (theme) =>
              snapshot.isDragging ? theme.palette.secondary.light : undefined,
            ...provided.draggableProps.style,
          }}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

export const WorkplaceTraining: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { workplaceId } = useParams();
  const identity = useAppSelector(selectIdentity);
  const workplaceTrainings = useAppSelector(selectWorkplaceTrainings);
  const workplaceTrainingsState = useAppSelector(selectWorkplaceTrainingsState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [isSavingOrder, setIsSavingOrder] = useState(false);
  const [errorSavingOrder, setErrorSavingOrder] = useState<ErrorStateType>();
  const [removingId, setRemovingId] = useState<string>('');

  const loadTrainings = useCallback(() => {
    dispatch(getWorkplaceTrainings(workplaceId!));
  }, [dispatch, workplaceId]);

  useEffect(() => {
    loadTrainings();
    setLoading(false);
  }, [loadTrainings]);

  const [items, setItems] = useState<TrainingDto[]>([]);
  useEffect(() => {
    setItems(workplaceTrainings);
  }, [workplaceTrainings]);

  const handleSaveOrder = async () => {
    setIsSavingOrder(true);
    setErrorSavingOrder('');
    try {
      await workplaceApi.changeTrainingOrder(workplaceId!, {
        entries: items.map((x) => x.id!),
      });
      enqueueSuccessSnackbar(t('common.editSuccess'));
      loadTrainings();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setErrorSavingOrder,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
    setIsSavingOrder(false);
  };

  const handleRemove = (trainingId: string) => async (event: any) => {
    event.stopPropagation();
    setRemovingId(trainingId);
    try {
      await workplaceApi.removeTrainingFromWorkplace(workplaceId!, trainingId);
      loadTrainings();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setRemovingId('');
  };

  const handleRowClick = (trainingId: string) => () => {
    if (
      hasRole(identity.roles, [
        UserDtoRolesEnum.AdminQcs,
        UserDtoRolesEnum.ManagerOsah,
      ])
    ) {
      navigate(`/training/${trainingId}`, {
        state: { back: `/workplace/${workplaceId}` },
      });
    }
  };

  const handleAddClick = () => {
    setAdding(true);
  };

  const handleCloseAdding = () => {
    setAdding(false);
  };

  const reorder = (
    list: TrainingDto[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    const result = reorder(
      items,
      dropResult.source.index,
      dropResult.destination.index
    );

    setItems(result);
  };

  const workplaceTrainingIds = useMemo(
    () => workplaceTrainings.map((x) => x.id!),
    [workplaceTrainings]
  );

  return (
    <>
      <AddTrainingModal
        open={adding}
        onClose={handleCloseAdding}
        reloadTrainings={loadTrainings}
        excludeIds={workplaceTrainingIds}
      />

      <SectionHeader
        title="workplaceTraining.title"
        addText="workplaceTraining.add"
        handleAddClick={handleAddClick}
      />

      <QcsPaper>
        <QcsTableContainer sx={{ overflow: 'unset' }}>
          <QcsTable>
            <QcsTableHead>
              <QcsTableRow>
                <QcsTableCell>{t('workplaceTraining.name')}</QcsTableCell>
                <QcsTableCell>{t('workplaceTraining.state')}</QcsTableCell>
                <QcsTableCell align="center" hideOnMobile>
                  {t('workplaceTraining.slideCount')}
                </QcsTableCell>
                <QcsTableCell hideOnMobile>
                  {t('workplaceTraining.latestValidFrom')}
                </QcsTableCell>
                <QcsTableCell hideOnMobile>
                  {t('workplaceTraining.latestValidTo')}
                </QcsTableCell>
                <QcsTableCell>{t('workplaceTraining.remove')}</QcsTableCell>
              </QcsTableRow>
            </QcsTableHead>
            <QcsTableBody component={DroppableComponent(onDragEnd)}>
              {loading || workplaceTrainingsState !== FetchState.Loaded
                ? null
                : items.map((item, index) => (
                    <QcsTableRow
                      key={index}
                      component={DraggableComponent(item.id!, index)}
                      onClick={handleRowClick(item.id!)}
                    >
                      <QcsTableCell sx={{ width: '20rem' }}>
                        {getLangNameObj(i18n, item)}
                      </QcsTableCell>
                      <QcsTableCell>
                        {t(`entityState.${item.state}`)}
                      </QcsTableCell>
                      <QcsTableCell align="center" hideOnMobile>
                        {item.slideCount}
                      </QcsTableCell>
                      <CellDate value={item.latestValidFrom} hideOnMobile />
                      <CellDate value={item.latestValidTo} hideOnMobile />

                      <QcsTableCell sx={{ width: '2rem' }}>
                        <QcsLoadingButton
                          onClick={handleRemove(item.id!)}
                          loading={removingId === item.id}
                        >
                          <DeleteForeverIcon color="error" />
                        </QcsLoadingButton>
                      </QcsTableCell>
                    </QcsTableRow>
                  ))}
            </QcsTableBody>
          </QcsTable>
          {loading || workplaceTrainingsState === FetchState.Loading ? (
            <GridLoading />
          ) : workplaceTrainingsState === FetchState.Error ? (
            <GridError />
          ) : workplaceTrainings.length === 0 ? (
            <GridEmpty />
          ) : null}
        </QcsTableContainer>
      </QcsPaper>

      <SaveButtonWrapper>
        <SaveError error={errorSavingOrder} />
        <QcsLoadingButton
          variant="contained"
          onClick={handleSaveOrder}
          loading={isSavingOrder}
        >
          {t('workplaceTraining.saveOrder')}
        </QcsLoadingButton>
      </SaveButtonWrapper>
    </>
  );
};
