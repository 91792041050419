import axios, { CancelToken, CancelTokenSource } from 'axios';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { GridActions, ListFilter, ListType } from '../../../store/listHelper';
import { FetchState } from '../../../store/fetchState';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import { QcsTable } from '../basic/QcsTable';
import { QcsTableBody } from '../basic/QcsTableBody';
import { QcsTableCell } from '../basic/QcsTableCell';
import { QcsTableContainer } from '../basic/QcsTableContainer';
import { QcsTableHead } from '../basic/QcsTableHead';
import { QcsTablePagination } from '../basic/QcsTablePagination';
import { QcsTableRow } from '../basic/QcsTableRow';
import { QcsTableSortLabel } from '../basic/QcsTableSortLabel';
import {
  GridContainer,
  GridToolbarContainer,
  StyledSearch,
} from './GridStyles';
import { GridLoading } from './GridLoading';
import { GridError } from './GridError';
import styled from '@emotion/styled';
import { GridEmpty } from './GridEmpty';
import { SxProps, Theme } from '@mui/material';
import { QcsTooltip } from '../basic/QcsTooltip';
import { qcUserApi } from '../../../utils/api';
import {
  identityActions,
  selectIdentity,
} from '../../../store/entities/identity';
import { setErrorSnacks } from '../../../utils/error';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';

export const StyledQcsTable = styled(QcsTable)(() => ({
  '.MuiTableBody-root': {
    '&.MuiTableBody-root': {
      cursor: 'pointer',
    },
  },
}));

export enum CheckboxState {
  Checked = 1,
  Unchecked = 2,
  Indeterminate = 3,
}

export interface GridData {
  id?: string;
}

export interface HeaderItem {
  captionStr?: string;
  captionEl?: ReactElement;
  orderName?: string;
  hideOnMobile?: boolean;
  sx?: SxProps<Theme>;
}

export interface GridProps<T extends GridData, U> {
  headers: Array<HeaderItem | undefined>;
  data: ListType<T, ListFilter>;
  gridActions: GridActions;
  reload?: boolean;
  checkbox?: boolean;
  checkedItems?: U[];
  filter?: React.ReactNode;
  search?: boolean;
  hidePagination?: boolean;
  renderData: (item: T) => ReactElement;
  onRowClick?: (item: T) => void;
  //Disable row (style) and check.
  isRowDisabled?: (item: T) => boolean;
  //Disable only check.
  isCheckDisabled?: (item: T) => boolean;
  getData: (cancelToken: CancelToken) => void;
  changeChecked?: (items: U[]) => void;
  getSelected?: (item: T) => boolean;
  getRowTooltip?: (item: T) => string | ReactElement;
  // itemToSelectObject and selectObjectGetId must be defined if we are selecting objects. If selecting string ids, they can be undefined.
  itemToSelectObject?: (item: T) => U;
  selectObjectGetId?: (x: U) => string;
}

export function Grid<T extends GridData, U = string>({
  headers,
  data,
  gridActions,
  reload,
  checkbox,
  checkedItems,
  filter,
  search = true,
  hidePagination = false,
  renderData,
  onRowClick,
  isRowDisabled,
  isCheckDisabled,
  getData,
  changeChecked,
  getSelected,
  getRowTooltip,
  // default functions for selecting ids
  itemToSelectObject = (item: any) => item.id!,
  selectObjectGetId = (x: any) => x,
}: GridProps<T, U>): JSX.Element {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tooltip, setTooltip] = useState<string | ReactElement>('');
  const dataCancelToken = useRef<CancelTokenSource | null>(null);
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const [savingRowsPerPage, setSavingRowsPerPage] = useState(false);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();

  const isCheckDisabled2 = useCallback(
    (item: T) =>
      isRowDisabled?.(item) === true || isCheckDisabled?.(item) === true,
    [isRowDisabled, isCheckDisabled]
  );

  let checkboxHeaderState: CheckboxState = CheckboxState.Unchecked;
  if (checkbox && checkedItems && data.data) {
    const items = data.data.content
      .filter((item) => !isCheckDisabled2(item))
      .map(
        (x) =>
          !!x.id &&
          !!checkedItems.map((item) => selectObjectGetId(item)).includes(x.id)
      );

    if (items.every((x) => !!x)) {
      checkboxHeaderState = CheckboxState.Checked;
    } else if (items.every((x) => !x)) {
      checkboxHeaderState = CheckboxState.Unchecked;
    } else {
      checkboxHeaderState = CheckboxState.Indeterminate;
    }
  }

  useEffect(() => {
    dispatch(gridActions.reload(!!reload));

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dataCancelToken.current?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.state !== FetchState.None) {
      if (loading) {
        setLoading(false);
      }
      return;
    }

    dataCancelToken.current?.cancel();
    dataCancelToken.current = axios.CancelToken.source();

    getData(dataCancelToken.current.token);

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.query, data.state, getData]);

  useEffect(() => {
    if (loading) {
      return;
    }

    dispatch(gridActions.updatePage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity.rowsCount, dispatch, gridActions]);

  const handlePageChange = (page: number) => {
    dispatch(gridActions.updatePage(page));
  };

  const handleRowsPerPageChange = async (rowsPerPage: number) => {
    setSavingRowsPerPage(true);
    try {
      const preferences = await qcUserApi.getUserPreferences(identity.id);
      const data = {
        ...preferences.data,
        pageSize: rowsPerPage,
      };
      await qcUserApi.updateUserPreferences(identity.id, data);

      dispatch(
        identityActions.success({
          ...identity,
          rowsCount: rowsPerPage,
        })
      );
      enqueueSuccessSnackbar(t('common.editSuccess'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'common.editError');
    }
    setSavingRowsPerPage(false);
  };

  const handleSearchChanged = (q: string) => {
    dispatch(gridActions.updateSearch(q));
  };

  const handleSelectAll = () => {
    if (!checkbox || !checkedItems || !data.data || !changeChecked) {
      return;
    }

    if (checkboxHeaderState === CheckboxState.Checked) {
      //Uncheck all.
      const allSelectItems = data.data.content.map((x) =>
        itemToSelectObject?.(x)
      );
      changeChecked(
        checkedItems.filter(
          (x) =>
            !allSelectItems
              .map((y) => selectObjectGetId(y))
              .includes(selectObjectGetId(x))
        )
      );
    } else {
      //Check all.
      const allSelectItems = data.data.content
        .filter((item) => !isCheckDisabled2(item))
        .map((x) => itemToSelectObject?.(x));
      changeChecked([
        ...checkedItems,
        ...allSelectItems.filter((x) => !checkedItems.includes(x)),
      ]);
    }
  };

  const tableHeader = useMemo(() => {
    const caption = (col: HeaderItem) =>
      col.captionStr ? t(col.captionStr) : col.captionEl;

    const handleSort = (sortBy: string) => {
      dispatch(
        gridActions.updateOrder({
          sortBy,
          sortDesc: data.query.sortBy === sortBy ? !data.query.sortDesc : false,
        })
      );
    };

    return headers
      .filter((x) => !!x)
      .map((x) => x!)
      .map((col, i) => (
        <QcsTableCell key={i} hideOnMobile={col.hideOnMobile} sx={col.sx}>
          {col.orderName ? (
            <QcsTableSortLabel
              active={data.query.sortBy === col.orderName}
              direction={
                data.query.sortBy === col.orderName
                  ? data.query.sortDesc
                    ? 'desc'
                    : 'asc'
                  : 'asc'
              }
              onClick={() => handleSort(col.orderName!)}
            >
              {caption(col)}
            </QcsTableSortLabel>
          ) : (
            caption(col)
          )}
        </QcsTableCell>
      ));
  }, [
    data.query.sortBy,
    data.query.sortDesc,
    dispatch,
    gridActions,
    headers,
    t,
  ]);

  const tableBody = useMemo(() => {
    const handleRowClick = (item: T) => {
      onRowClick?.(item);
    };

    const handleCheckChanged = (item: T, checkDisabled: boolean) => {
      if (
        checkbox &&
        item.id &&
        changeChecked &&
        checkedItems &&
        !checkDisabled
      ) {
        if (checkedItems.map((x) => selectObjectGetId(x)).includes(item.id)) {
          changeChecked(
            checkedItems.filter((x) => selectObjectGetId(x) !== item.id)
          );
        } else {
          changeChecked([...checkedItems, itemToSelectObject(item)]);
        }
      }
    };

    const handleRowMouseEnter = (item: T) => {
      setTooltip(getRowTooltip?.(item) ?? '');
    };

    const handleRowMouseLeave = () => {
      setTooltip('');
    };

    return data.data?.content.map((row, i) => {
      const rowDisabled = isRowDisabled?.(row) === true;
      const checkDisabled = isCheckDisabled2(row);

      return (
        <QcsTableRow
          isDisabled={rowDisabled}
          isSelected={getSelected?.(row)}
          key={i}
          hover={true}
          onClick={() => handleRowClick(row)}
          onMouseEnter={() => handleRowMouseEnter(row)}
          onMouseLeave={handleRowMouseLeave}
        >
          {checkbox && (
            <QcsTableCell padding="checkbox">
              <QcsCheckbox
                disabled={checkDisabled}
                checked={
                  !!row.id &&
                  checkedItems
                    ?.map((x) => selectObjectGetId(x))
                    .includes(row.id)
                }
                onChange={() => handleCheckChanged(row, checkDisabled)}
              />
            </QcsTableCell>
          )}
          {renderData(row)}
        </QcsTableRow>
      );
    });
  }, [
    data.data?.content,
    checkbox,
    changeChecked,
    checkedItems,
    onRowClick,
    selectObjectGetId,
    itemToSelectObject,
    isRowDisabled,
    isCheckDisabled2,
    getSelected,
    renderData,
    getRowTooltip,
  ]);

  const isLoading =
    loading ||
    data.state === FetchState.Loading ||
    data.state === FetchState.None ||
    data.state === FetchState.Cancel;
  const isError = data.state === FetchState.Error;

  return (
    <GridContainer>
      <GridToolbarContainer>
        {filter}
        {search && (
          <StyledSearch
            defaultValue={data.query.q ?? ''}
            onChanged={handleSearchChanged}
          />
        )}
      </GridToolbarContainer>

      <QcsTooltip title={tooltip} followCursor={true}>
        <div>
          <QcsTableContainer>
            <StyledQcsTable>
              <QcsTableHead>
                <QcsTableRow>
                  {checkbox && (
                    <QcsTableCell padding="checkbox">
                      <QcsCheckbox
                        indeterminate={
                          checkboxHeaderState === CheckboxState.Indeterminate
                        }
                        checked={checkboxHeaderState === CheckboxState.Checked}
                        onChange={handleSelectAll}
                      />
                    </QcsTableCell>
                  )}
                  {tableHeader}
                </QcsTableRow>
              </QcsTableHead>
              {!isLoading && !isError && (
                <QcsTableBody>{tableBody}</QcsTableBody>
              )}
            </StyledQcsTable>
            {isLoading ? (
              <GridLoading />
            ) : isError ? (
              <GridError />
            ) : data.data?.content.length === 0 ? (
              <GridEmpty />
            ) : null}
            {!hidePagination && (
              <QcsTablePagination
                count={data.data?.totalElements ?? 0}
                rowsPerPage={identity.rowsCount}
                page={data.data?.number ?? 0}
                rowsPerPageDisabled={savingRowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </QcsTableContainer>
        </div>
      </QcsTooltip>
    </GridContainer>
  );
}
