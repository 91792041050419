import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { establishmentApi } from '../../utils/api';

export interface AllEstablishmentListFilter extends ListFilter {
  state?: EstablishmentDtoStateEnum;
}

export type AllEstablishmentListState = ListType<
  EstablishmentDto,
  AllEstablishmentListFilter
>;

const initialState: AllEstablishmentListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: EstablishmentDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const allEstablishmentListSlice = listSlice({
  name: 'allEstablishmentList',
  initialState,
});

export const selectAllEstablishmentList = (state: RootState) =>
  state.allEstablishmentList;
export const allEstablishmentListActions = allEstablishmentListSlice.actions;

export const getAllEstablishmentList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectAllEstablishmentList(storeState);

    const handleGetData = (size: number) => {
      return establishmentApi.queryAllEstablishments(
        companyId,
        data.query.q,
        data.query.state,
        undefined, //nameOnly
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: allEstablishmentListActions,
      getData: handleGetData,
    });
  };
