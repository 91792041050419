import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  EmployeeDto,
  EmployeeDtoStateEnum,
  ReferenceDto,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { employeeApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';
import { getFirstLastNameObj } from '../../utils/format';

type State = BaseType<EmployeeDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsEmployeeSlice = baseSlice({
  name: 'optionsEmployee',
  initialState,
});

export const selectOptionsEmployeeState = (state: RootState) =>
  state.optionsEmployee.state;
export const selectOptionsEmployee = (state: RootState) =>
  state.optionsEmployee.data.map(
    (x) => ({ id: x.id, name: getFirstLastNameObj(x) } as ReferenceDto)
  );
export const optionsEmployeeActions = optionsEmployeeSlice.actions;

export const getOptionsEmployee =
  (
    companyId: string,
    forInvite: boolean | undefined,
    search: string | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await employeeApi.queryEmployees(
        companyId,
        search,
        EmployeeDtoStateEnum.Active,
        forInvite,
        undefined, //establishments
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsEmployeeActions,
      getData: handleGetData,
    });
  };
