import { LoginRequest } from '@qcs/safety-client';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ErrorStateType } from '../../models/common';
import { useAppDispatch } from '../../store';
import { getIdentity } from '../../store/entities/identity';
import { storageResetAction } from '../../store/storeReset';
import { authenticationApi } from '../../utils/api';
import { setErrorState } from '../../utils/error';
import { logout, setMediaToken, setRefreshToken } from '../../utils/storage';
import { Loader } from '../common/Loader';
import { SaveError } from '../common/SaveError';

export const ExternalLinkPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const paramEmail = params.get('email');
  const paramUsername = params.get('username');
  const username = paramUsername ? paramUsername : paramEmail;
  //I dont need invitation.
  //const invitation = params.get('invitation');

  useEffect(() => {
    (async () => {
      logout();
      dispatch(storageResetAction());

      setError('');

      try {
        if (!username || !token) {
          throw new Error();
        }

        const data: LoginRequest = {
          username,
          password: token,
        };

        const res = await authenticationApi.accessToken(data, {
          _dontRedirect: true,
        } as any);

        dispatch(getIdentity(res.data.accessToken!, true));

        navigate('/training');

        setRefreshToken(res.data.refreshToken!);
        setMediaToken(res.data.mediaToken!);
      } catch (err) {
        setErrorState(err, setError, 'auth.badLink');
      }
      setLoading(false);
    })();

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <SaveError error={error} />;
  }

  return null;
};
