import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { EmergencyDto } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { emergencyApi } from '../../utils/api';

export type EmergencyListState = ListType<EmergencyDto, ListFilter>;

const initialState: EmergencyListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'createdOn',
    sortDesc: true,
  },
};

export const emergencyListSlice = listSlice({
  name: 'emergencyList',
  initialState,
});

export const selectEmergencyList = (state: RootState) => state.emergencyList;
export const emergencyListActions = emergencyListSlice.actions;

export const getEmergencyList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectEmergencyList(storeState);

    const handleGetData = (size: number) => {
      return emergencyApi.queryEmergency(
        companyId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: emergencyListActions,
      getData: handleGetData,
    });
  };
