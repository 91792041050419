import { styled, SxProps, TableRow, Theme } from '@mui/material';
import { FC, MouseEventHandler } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  hover?: boolean;
  onClick?: () => void;
  onMouseEnter?: MouseEventHandler<HTMLTableRowElement>;
  onMouseLeave?: MouseEventHandler<HTMLTableRowElement>;
  ref?:
    | ((instance: HTMLTableRowElement | null) => void)
    | React.RefObject<HTMLTableRowElement>
    | null;
  style?: React.CSSProperties;
  component?: any;
  isDisabled?: boolean;
  isSelected?: boolean;
  children: React.ReactNode;
}

const StyledTableRow = styled(TableRow)<{
  styleprops?: {
    isDisabled?: boolean;
    isSelected?: boolean;
  };
  component: React.ReactNode;
}>(({ styleprops, theme }) => ({
  '&.MuiTableRow-root': {
    cursor: styleprops?.isDisabled && 'default',
    '> td': {
      color: styleprops?.isDisabled
        ? theme.palette.grey[400]
        : styleprops?.isSelected
        ? theme.palette.common.white
        : undefined,
      backgroundColor:
        !styleprops?.isDisabled && styleprops?.isSelected
          ? theme.palette.primary.dark
          : undefined,
    },
  },
}));

export const QcsTableRow: FC<Props> = ({
  className,
  hover,
  ref,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  component,
  sx,
  children,
  isDisabled = false,
  isSelected = false,
}) => {
  return (
    <StyledTableRow
      className={className}
      sx={sx}
      hover={hover}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
      style={style}
      component={component}
      styleprops={{ isDisabled, isSelected }}
    >
      {children}
    </StyledTableRow>
  );
};
