import { Popover, PopoverOrigin } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  anchorOrigin?: PopoverOrigin;
  anchorEl?: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export const QcsPopover: FC<Props> = ({
  className,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  anchorEl,
  open,
  onClose,
  children,
}) => {
  return (
    <Popover
      className={className}
      anchorOrigin={anchorOrigin}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {children}
    </Popover>
  );
};
