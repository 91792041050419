import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { QcsToggleButton } from '../common/basic/QcsToggleButton';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useAppDispatch } from '../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { GridFilterItemContainer } from '../common/grid/GridFilterStyles';
import {
  visitationListActions,
  VisitationListFilter,
} from '../../store/entities/visitationList';
import { FormContainer } from '../common/form/FormContainer';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { QcsDateTimePicker } from '../common/basic/QcsDateTimePicker';
import { StyledQcsPopover } from '../common/grid/GridFilterItem';

interface Props {
  query: VisitationListFilter;
}

export const VisitationFilterExitTime: FC<Props> = ({ query }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeHasExited = () => {
    dispatch(
      visitationListActions.updateFilter(
        query.hasExited === true
          ? {
              hasExited: undefined,
            }
          : {
              exitTime: undefined,
              hasExited: true,
            }
      )
    );
  };

  const handleChangeFrom = (date?: Date | null) => {
    dispatch(
      visitationListActions.updateFilter({
        exitTime: { from: date ? date : undefined, to: query.exitTime?.to },
      })
    );
  };

  const handleChangeTo = (date?: Date | null) => {
    dispatch(
      visitationListActions.updateFilter({
        exitTime: { from: query.exitTime?.from, to: date ? date : undefined },
      })
    );
  };

  const handleClear = () => {
    dispatch(
      visitationListActions.updateFilter({
        exitTime: undefined,
        hasExited: undefined,
      })
    );
  };

  const selected = query.exitTime !== undefined || query.hasExited === true;

  return (
    <>
      <QcsToggleButton value="" selected={selected} onChange={handleOpenMenu}>
        {t('visitation.exitTime')}
        <ArrowDropDownIcon />
      </QcsToggleButton>
      <StyledQcsPopover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <GridFilterItemContainer>
          <FormContainer>
            <QcsFormControlLabel
              control={
                <QcsCheckbox
                  checked={!!query.hasExited}
                  onChange={handleChangeHasExited}
                />
              }
              label={t('visitation.filterHasExited')}
            />
            <QcsDateTimePicker
              label={t('common.from')}
              value={query.exitTime?.from ? query.exitTime.from : null}
              onChange={handleChangeFrom}
              disabled={query.hasExited === true}
            />
            <QcsDateTimePicker
              label={t('common.to')}
              value={query.exitTime?.to ? query.exitTime.to : null}
              onChange={handleChangeTo}
              disabled={query.hasExited === true}
            />
          </FormContainer>
        </GridFilterItemContainer>
        <GridFilterItemContainer>
          <QcsButton disabled={!selected} onClick={handleClear}>
            {t('common.clearFilter')}
          </QcsButton>
        </GridFilterItemContainer>
      </StyledQcsPopover>
    </>
  );
};
