import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CompanyBusinessSettings } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { companySettingsApi } from '../../utils/api';

export type CompanyCustomizationState = BaseType<
  CompanyBusinessSettings | undefined
>;

const initialState: CompanyCustomizationState = {
  state: FetchState.None,
  data: undefined,
};

export const companyCustomizationSlice = baseSlice({
  name: 'companyCustomization',
  initialState,
});

export const selectCompanyCustomizationState = (state: RootState) =>
  state.companyCustomization.state;
export const selectCompanyCustomization = (state: RootState) =>
  state.companyCustomization.data;
export const selectCompanyCustomizationTrainingLangCodes = (state: RootState) =>
  state.companyCustomization.data?.testLanguages;
export const selectCompanyCustomizationSmsEnabled = (state: RootState) =>
  state.companyCustomization.data?.smsEnabled;
export const companyCustomizationActions = companyCustomizationSlice.actions;

export const getCompanyCustomization =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companySettingsApi.getBusinessSettings(companyId);
    };

    getItem({
      dispatch,
      actions: companyCustomizationActions,
      getData: handleGetData,
    });
  };
