import styled from '@emotion/styled';
import { FC } from 'react';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';

const TooltipContent = styled(QcsBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '.5rem',
}));

interface Props {
  children: React.ReactNode;
}

export const QcsTooltipContent: FC<Props> = ({ children }) => {
  return <TooltipContent>{children}</TooltipContent>;
};
