import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import {
  selectTourniquetQuestionnaire,
  tourniquetQuestionnaireActions,
} from '../../store/entities/tourniquetQuestionnaire';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import {
  selectTourniquetTrainingSlides,
  tourniquetTrainingSlidesActions,
} from '../../store/entities/tourniquetTrainingSlides';
import {
  invitationTrainingResourceApi,
  questionnairesApi,
  trainingsApi,
} from '../../utils/api';
import { getCustomError, setErrorSnacks } from '../../utils/error';
import {
  tourniquetGetAfterTrainingContent,
  tourniquetGetSlides,
} from './tourniquetFunctions';
import { FlagButton, LanguageSwitchWrapper } from './TourniquetStyles';
import { Menu as QcsMenu } from '@mui/material';

import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';

export const TourniquetHeaderTraining: FC = () => {
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const tourniquetTrainingSlides = useAppSelector(
    selectTourniquetTrainingSlides
  );
  const tourniquetQuestionnaire = useAppSelector(selectTourniquetQuestionnaire);
  const [loading, setIsLoading] = useState(false);
  const [langMeuAnchorEl, setLangMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [showAlternateDiv, setShowAlternateDiv] = useState(false);
  const langWrapperRef = useRef<HTMLDivElement>(null);
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLangMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setLangMenuAnchorEl(null);
  };

  const languages =
    tourniquetTraining.mode === 'questionnaire'
      ? tourniquetTraining.questionnaire?.languages
      : tourniquetTraining.mode === 'training'
      ? tourniquetTraining.training.languages
      : undefined;

  const selectedLang =
    tourniquetTraining.mode === 'questionnaire'
      ? tourniquetQuestionnaire.language
      : tourniquetTraining.mode === 'training'
      ? tourniquetTrainingSlides.language
      : undefined;

  useEffect(() => {
    if (
      langWrapperRef.current &&
      langWrapperRef.current.offsetWidth > screen.width * 0.8
    ) {
      setShowAlternateDiv(true);
    }
  }, [langWrapperRef]);

  const languagesMemo = useMemo(() => {
    const renderFlag = (flag: string) => (
      <img
        src={'/flags/' + flag + '.svg'}
        height="20"
        loading="lazy"
        alt="language flag"
      />
    );

    const handleSwitch = async (code: string, flag: string) => {
      setIsLoading(true);
      let languageCode = 'cs';
      switch (flag) {
        case 'gb':
          languageCode = 'en';
          break;
        case 'pl':
          languageCode = 'pl';
          break;
        case 'ru':
          languageCode = 'ru';
          break;
        case 'de':
          languageCode = 'de';
          break;
      }
      try {
        //Load slides.
        let trainingData;
        if (
          tourniquetTrainingSlides.language?.code !== code &&
          tourniquetTraining.training.languages.find((x) => x.code === code)
        ) {
          if (tourniquetIdentity.playground) {
            const resTraining = await trainingsApi.getSlides(
              tourniquetTraining.training.id,
              code
            );

            //No slides - error.
            if (resTraining.data.length === 0) {
              throw getCustomError('noSlides');
            }

            const allSlides = await tourniquetGetSlides(resTraining.data);
            const afterTrainingContent =
              await tourniquetGetAfterTrainingContent(
                identityCompanyId,
                languageCode
              );

            trainingData = {
              language: tourniquetTraining.training.languages.find(
                (x) => x.code === code
              ),
              slides: allSlides,
              afterTrainingContent,
            };
          } else {
            const resTraining =
              await invitationTrainingResourceApi.getSpecificTrainingForInvitation(
                tourniquetIdentity.data!.invitation!.id!,
                tourniquetTraining.training.id,
                code
              );

            //No slides - error.
            if (
              !resTraining.data.slides ||
              resTraining.data.slides.length === 0
            ) {
              throw getCustomError('noSlides');
            }

            const allSlides = await tourniquetGetSlides(
              resTraining.data.slides
            );
            const afterTrainingContent =
              await tourniquetGetAfterTrainingContent(identityCompanyId, code);

            trainingData = {
              ...resTraining.data,
              slides: allSlides,
              afterTrainingContent,
            };
          }
        }

        //Load questionnaire.
        let questionnaireData;
        if (
          tourniquetQuestionnaire.language?.code !== code &&
          tourniquetTraining.questionnaire?.languages.find(
            (x) => x.code === code
          )
        ) {
          if (tourniquetIdentity.playground) {
            const resQuestionnaireQuestions =
              await questionnairesApi.getQuestions(
                tourniquetTraining.questionnaire.id,
                code
              );

            //No questions - error.
            if (resQuestionnaireQuestions.data.length === 0) {
              throw getCustomError('noQuestions');
            }

            questionnaireData = {
              language: tourniquetTraining.questionnaire.languages.find(
                (x) => x.code === code
              ),
              successRate: tourniquetQuestionnaire.successRate,
              questions: resQuestionnaireQuestions.data
                .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
                .map((x) => ({ ...x, answerResponse: undefined })),
            };
          } else {
            const resQuestionnaireQuestions =
              await invitationTrainingResourceApi.getSpecificQuestionnaireForInvitation(
                tourniquetIdentity.data!.invitation!.id!,
                tourniquetTraining.questionnaire!.id,
                code
              );

            //No questions - error.
            if (
              !resQuestionnaireQuestions.data.questions ||
              resQuestionnaireQuestions.data.questions.length === 0
            ) {
              throw getCustomError('noQuestions');
            }

            questionnaireData = {
              ...resQuestionnaireQuestions.data,
              questions: resQuestionnaireQuestions.data
                .questions!.sort(
                  (a, b) => (a.position ?? 0) - (b.position ?? 0)
                )
                .map((x) => ({ ...x, answerResponse: undefined })),
            };
          }
        }

        if (!trainingData && !questionnaireData) {
          return;
        }

        //Switch to first page.
        dispatch(
          tourniquetTrainingActions.success({
            ...tourniquetTraining,
            index: 0,
            answerResponse: undefined,
          })
        );

        if (trainingData) {
          dispatch(tourniquetTrainingSlidesActions.success(trainingData));
        }

        if (questionnaireData) {
          dispatch(tourniquetQuestionnaireActions.success(questionnaireData));
        }
      } catch (err) {
        setErrorSnacks(err, enqueueErrorSnackbar);
      } finally {
        setIsLoading(false);
      }
    };

    return languages?.map((x) =>
      showAlternateDiv ? (
        <QcsMenuItem
          key={x.code}
          onClick={() => {
            handleSwitch(x.code, x.flag);
            handleClose();
          }}
        >
          {renderFlag(x.flag)}
        </QcsMenuItem>
      ) : (
        <FlagButton
          selected={x.code === selectedLang?.code}
          key={x.code}
          loading={loading}
          onClick={() => handleSwitch(x.code, x.flag)}
        >
          {renderFlag(x.flag)}
        </FlagButton>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, loading, showAlternateDiv]);

  if (showAlternateDiv && languages) {
    return (
      <>
        <LoadingButton
          sx={{ marginLeft: 'auto' }}
          loading={loading}
          onClick={handleOpen}
        >
          <img
            src={'/flags/' + selectedLang?.flag + '.svg'}
            height="25"
            loading="lazy"
            alt="language flag"
          />
          <ArrowDropDownIcon />
        </LoadingButton>
        <QcsMenu
          anchorEl={langMeuAnchorEl}
          open={!!langMeuAnchorEl}
          onClose={handleClose}
        >
          {languagesMemo}
        </QcsMenu>
      </>
    );
  }
  return (
    <LanguageSwitchWrapper ref={langWrapperRef}>
      {languagesMemo}
    </LanguageSwitchWrapper>
  );
};
