import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  activityListActions,
  ActivityListFilter,
} from '../../store/entities/activityList';
import { GridFilterBoolean } from '../common/grid/GridFilterBoolean';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: ActivityListFilter;
}

export const ActivityFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={activityListActions}
        name="riskRequired"
        title="activity.riskRequired"
        Component={GridFilterBoolean}
      />
      <GridFilterItem
        query={query}
        gridActions={activityListActions}
        name="certificateRequired"
        title="activity.certificateRequired"
        Component={GridFilterBoolean}
      />
      <GridFilterItem
        query={query}
        gridActions={activityListActions}
        name="state"
        title="activity.state"
        Component={GridFilterState}
      />
    </>
  );
};
