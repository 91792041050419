import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { WorkplaceDto, WorkplaceDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { workplaceApi } from '../../utils/api';

type State = BaseType<Partial<WorkplaceDto>>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    establishment: undefined,
    riskRequired: false,
    risks: [],
    safetyEquipments: [],
    state: WorkplaceDtoStateEnum.Active,
    note: '',
  },
};

export const workplaceSlice = baseSlice({
  name: 'workplace',
  initialState,
});

export const selectWorkplaceState = (state: RootState) => state.workplace.state;
export const selectWorkplace = (state: RootState) => state.workplace.data;
export const workplaceActions = workplaceSlice.actions;

export const getWorkplace =
  (workplaceId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return workplaceApi.getWorkplace(workplaceId);
    };

    getItem({
      dispatch,
      actions: workplaceActions,
      getData: handleGetData,
    });
  };
