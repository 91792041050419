import { EmergencyDto } from '@qcs/safety-client';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { emergencyListActions } from '../../store/entities/emergencyList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { emergencyApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsDialog } from '../common/basic/QcsDialog';
import { QcsDialogActions } from '../common/basic/QcsDialogActions';
import { DialogContent as QcsDialogContent } from '@mui/material';

import { QcsDialogContentText } from '../common/basic/QcsDialogContentText';
import { DialogTitle as QcsDialogTitle } from '@mui/material';

import { QcsTypography } from '../common/basic/QcsTypography';
import { setErrorStateSnacks } from '../../utils/error';
import { HtmlViewer } from '../common/html/HtmlViewer';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

interface Props {
  emailMessage?: string;
  smsMessage?: string;
  data?: EmergencyDto;
  onClose: () => void;
}

export const EmergencyNewModal: FC<Props> = ({
  emailMessage,
  smsMessage,
  data,
  onClose,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { goBack } = useBack('/emergency');
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [data]);

  const handleConfirm = async () => {
    setSaveError('');

    try {
      await emergencyApi.createEmergency(identityCompanyId, data!);
      dispatch(emergencyListActions.reload(true));
      enqueueSuccessSnackbar(t('common.saveSuccess'));

      onClose();
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  return (
    <QcsDialog open={!!data} onClose={onClose}>
      <QcsDialogTitle>{t('emergency.addTitle')}</QcsDialogTitle>
      <QcsDialogContent>
        <QcsDialogContentText>
          {emailMessage && (
            <>
              <QcsTypography variant="h6">
                {t('emergency.emailMessage')}
              </QcsTypography>
              <HtmlViewer html={emailMessage} />
            </>
          )}
          {emailMessage && smsMessage && <div style={{ height: '1rem' }} />}
          {smsMessage && (
            <>
              <QcsTypography variant="h6">
                {t('emergency.smsMessage')}
              </QcsTypography>
              <HtmlViewer html={smsMessage} />
            </>
          )}
          <SaveError error={saveError} />
        </QcsDialogContentText>
      </QcsDialogContent>
      <QcsDialogActions>
        <QcsButton color="secondary" onClick={onClose}>
          {t('common.close')}
        </QcsButton>
        <QcsButton color="primary" onClick={handleConfirm}>
          {t('emergency.confirm')}
        </QcsButton>
      </QcsDialogActions>
    </QcsDialog>
  );
};
