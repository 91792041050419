import { EmployeeDto, ReferenceDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { validations } from '../../utils/validations';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { EmployeeAutocomplete } from '../common/form/EmployeeAutocomplete';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import * as Yup from 'yup';
import { employeeApi } from '../../utils/api';
import { ErrorStateType } from '../../models/common';

interface EstablishmentDetailFromEmployeeModalForm {
  supplierEmployee?: ReferenceDto | null;
}

interface Props {
  open: boolean;
  onClose: (employee?: EmployeeDto) => void;
}

export const EstablishmentDetailFromEmployeeModal: FC<Props> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (
    data: EstablishmentDetailFromEmployeeModalForm
  ) => {
    setSaveError('');
    try {
      const res = await employeeApi.getEmployee(data.supplierEmployee!.id!);
      onClose(res.data);
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <QcsModal open={open} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title="establishment.loadFromEmployeeTitle" />
        <FormikDebounce<EstablishmentDetailFromEmployeeModalForm>
          initialValues={{
            supplierEmployee: null,
          }}
          validationSchema={Yup.object().shape({
            supplierEmployee: validations.objectRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <EmployeeAutocomplete
                name="supplierEmployee"
                label={t('establishment.loadFromEmployeeLabel')}
                forInvite={true}
                required={true}
              />

              <SaveError error={saveError} />
              <SubmitButton text="establishment.loadFromEmployeeSelect" />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
