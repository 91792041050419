import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import { riskListActions, RiskListFilter } from '../../store/entities/riskList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: RiskListFilter;
}

export const RiskFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={riskListActions}
        name="state"
        title="risk.state"
        Component={GridFilterState}
      />
    </>
  );
};
