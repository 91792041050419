import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import {
  emergencyActions,
  getEmergency,
  selectEmergency,
  selectEmergencyState,
} from '../../store/entities/emergency';
import { GridList } from '../common/grid/GridList';
import { EmergencyDto, EmergencyPersonDto } from '@qcs/safety-client';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FaIcon } from '../common/FaIcon';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { DateTimePicker } from '../common/form/DateTimePicker';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { emergencyApi } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { downloadBlob } from '../../utils/download';
import { emergencyListActions } from '../../store/entities/emergencyList';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { setErrorSnacks } from '../../utils/error';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { InnerTooltipWrapper, QcsTooltip } from '../common/basic/QcsTooltip';
import { getLangNameInputNameObj, getLangNameObj } from '../../utils/format';

export const EmergencyDetailRemote: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const emergency = useAppSelector(selectEmergency);
  const emergencyState = useAppSelector(selectEmergencyState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [personSaving, setPersonSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await emergencyApi.isEnabledToCreate(identityCompanyId);
        dispatch(getEmergency(res.data.value!));
        setLoading(false);
      } catch {
        setError(true);
      }
    })();

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    //Nothing.
  };

  const handlePersonRemove = async (item: EmergencyPersonDto) => {
    setIsRemoving(true);
    try {
      await emergencyApi.foundPerson(emergency.id!, item.id!);
      const newData = await emergencyApi.findById(emergency.id!);
      dispatch(emergencyActions.success(newData.data));
      dispatch(emergencyListActions.reload(true));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsRemoving(false);
  };

  const handleRenderData = (item: EmergencyPersonDto) => {
    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.workplaces}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getLangNameObj(i18n, item.invitedBy)}
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsTooltip
            title={t('emergency.persons.removeTooltip')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsButton
                onClick={() => handlePersonRemove(item)}
                disabled={isRemoving}
              >
                <FaIcon icon={faUserShield} />
              </QcsButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleExport = async () => {
    setPersonSaving(true);
    try {
      const res = await emergencyApi.exportEmployeesEmergency(emergency.id!, {
        responseType: 'blob',
      });
      const blobResponse = res as AxiosResponse<Blob>;
      await downloadBlob(blobResponse);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setPersonSaving(false);
  };

  if (loading || emergencyState === FetchState.Loading) {
    return <Loader />;
  }

  if (error || emergencyState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="emergency.detailTitle" />
      <FormikDebounce<EmergencyDto>
        initialValues={emergency}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <Input
                name={`establishment.${getLangNameInputNameObj(
                  i18n,
                  values.establishment
                )}`}
                label={t('emergency.establishment')}
                disabled={true}
              />
              <WorkplaceAutocomplete
                name="workplaces"
                label={t('emergency.workplaces')}
                multiple={true}
                disabled={true}
              />
              <DateTimePicker
                name="createdOn"
                label={t('emergency.createdOn')}
                disabled={true}
              />
              <Input
                name="detail"
                label={t('emergency.detail')}
                multiline={true}
                rows={4}
                disabled={true}
              />

              <div>
                <QcsTypography variant="h6">
                  {t('emergency.persons.title')}:
                </QcsTypography>
                <GridList<EmergencyPersonDto>
                  headers={[
                    { captionStr: 'emergency.persons.name' },
                    { captionStr: 'emergency.persons.lastName' },
                    {
                      captionStr: 'emergency.persons.phone',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'emergency.persons.workplace',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'emergency.persons.invitedBy',
                      hideOnMobile: true,
                    },
                    { captionStr: 'emergency.persons.remove' },
                  ]}
                  data={emergency.emergencyPersons ?? []}
                  renderData={handleRenderData}
                  search={false}
                  hidePagination={true}
                />

                <QcsLoadingButton
                  variant="outlined"
                  sx={{ marginTop: '0.5rem' }}
                  onClick={handleExport}
                  loading={personSaving}
                >
                  {t('emergency.persons.export')}
                </QcsLoadingButton>
              </div>
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
