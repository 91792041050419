import { RiskDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  riskListActions,
  getRiskList,
  selectRiskList,
} from '../../store/entities/riskList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { RiskFilter } from './RiskFilter';
import { getLangNameObj } from '../../utils/format';

export const Risk: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const riskList = useAppSelector(selectRiskList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getRiskList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: RiskDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: RiskDto) => {
    navigate('/risk/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/risk/new');
  };

  return (
    <>
      <SectionHeader
        title="risk.title"
        addText="risk.add"
        handleAddClick={handleAddClick}
      />
      <Grid<RiskDto>
        headers={[
          { captionStr: 'risk.name', orderName: 'name' },
          {
            captionStr: 'risk.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={riskList}
        gridActions={riskListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<RiskFilter query={riskList.query} />}
      />
    </>
  );
};
