import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { RiskDto, RiskDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { riskApi } from '../../utils/api';

type State = BaseType<RiskDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    state: RiskDtoStateEnum.Active,
  },
};

export const riskSlice = baseSlice({
  name: 'risk',
  initialState,
});

export const selectRiskState = (state: RootState) => state.risk.state;
export const selectRisk = (state: RootState) => state.risk.data;
export const riskActions = riskSlice.actions;

export const getRisk = (riskId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return riskApi.getRisk(riskId);
  };

  getItem({
    dispatch,
    actions: riskActions,
    getData: handleGetData,
  });
};
