import { useFormikContext } from 'formik';
import { FC, useRef, useState } from 'react';
import _ from 'lodash';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { HeaderLogo } from '../layout/HeaderStyles';
import { QcsTypography } from '../common/basic/QcsTypography';
import styled from '@emotion/styled';
import { BASE_URL_WITH_VERSION } from '../../utils/api';
import { BannerInputUploadModal } from '../qcsCompanySettings/BannerInputUploadModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getMediaToken } from '../../utils/storage';

const ImageWrapper = styled.div(() => ({
  height: '10rem',
  maxWidth: '100%',
}));

interface Props {
  questionIndex: number;
  onRemoveOldFile: (document: string) => void;
  disabled?: boolean;
}

export const ImageInput: FC<Props> = ({
  questionIndex,
  onRemoveOldFile,
  disabled,
}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const imageUrl = _.get(values, `questions.${questionIndex}.document.path`);
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const mediaToken = getMediaToken();

  const documentId = _.get(values, `questions.${questionIndex}.document.id`);

  const handleUploadFile = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);

    fileRef.current.value = '';
  };

  const handleFileUploaded = (
    url: string,
    id: string,
    filename?: string,
    mediaType?: string
  ) => {
    if (!selectedFile) {
      return;
    }

    setFieldValue(`questions.${questionIndex}.document.id`, id);
    setFieldValue(`questions.${questionIndex}.document.path`, url);
    setFieldValue(`questions.${questionIndex}.document.fileName`, filename);
    setFieldValue(`questions.${questionIndex}.document.mediaType`, mediaType);

    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const handleRemoveImage = () => {
    onRemoveOldFile(documentId);
    setFieldValue(`questions.${questionIndex}.document`, undefined);
  };

  return (
    <>
      <QcsTypography variant="h6">
        {t('questionnaire.questions.image')}
      </QcsTypography>
      {!!imageUrl && (
        <ImageWrapper>
          <HeaderLogo
            src={`${BASE_URL_WITH_VERSION}${imageUrl}?token=${mediaToken}`}
          />
        </ImageWrapper>
      )}
      <BannerInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />
      <div>
        {!!imageUrl ? (
          <QcsButton disabled={disabled} onClick={handleRemoveImage}>
            <DeleteForeverIcon color={disabled ? undefined : 'error'} />
          </QcsButton>
        ) : (
          <QcsButton disabled={disabled} onClick={handleUploadFile}>
            {t('common.uploadFile')}
          </QcsButton>
        )}
      </div>
    </>
  );
};
