import { WorkplaceDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import {
  getOptionsWorkplace,
  selectOptionsWorkplace,
  selectOptionsWorkplaceState,
} from '../../../store/entities/optionsWorkplace';
import { FetchState } from '../../../store/fetchState';
import { getLangNameObj } from '../../../utils/format';
import { QcsAutocompleteWithoutFormik } from '../form/QcsAutocompleteWithoutFormik';

interface Props {
  estabilishments?: string[];
  value?: WorkplaceDto;
  onChange: (value?: WorkplaceDto) => void;
}

export const GridFilterWorkplace: FC<Props> = ({
  estabilishments,
  value,
  onChange,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsWorkplace = useAppSelector(selectOptionsWorkplace);
  const optionsWorkplaceState = useAppSelector(selectOptionsWorkplaceState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsWorkplace(
        identityCompanyId,
        search,
        estabilishments,
        cancelToken
      )
    );
  };

  const handleChange = (value?: WorkplaceDto | WorkplaceDto[] | null) => {
    onChange((value as WorkplaceDto) ?? undefined);
  };

  return (
    <QcsAutocompleteWithoutFormik
      options={optionsWorkplace}
      loading={optionsWorkplaceState === FetchState.Loading}
      loadingError={optionsWorkplaceState === FetchState.Error}
      loadOptions={loadOptions}
      value={value}
      getOptionId={(option: WorkplaceDto) => option.id!}
      getOptionName={(option: WorkplaceDto) => getLangNameObj(i18n, option)}
      onChange={handleChange}
    />
  );
};
