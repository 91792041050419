import { CircularProgress, SxProps } from '@mui/material';
import { FC } from 'react';
import { CenterLayoutWrapper } from '../layout/LayoutStyles';

interface Props {
  sx?: SxProps | undefined;
}

export const Loader: FC<Props> = ({ sx }) => {
  return (
    <CenterLayoutWrapper>
      <CircularProgress sx={sx} />
    </CenterLayoutWrapper>
  );
};
