import { FC, useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { history } from '../../utils/history';

interface Props {
  children: React.ReactNode;
}

export const BrowserRouterWithHistory: FC<Props> = ({ children }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => {
    const unlisten = history.listen(setState);
    return unlisten;
  }, []);

  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};
