import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { establishmentApi } from '../../utils/api';

type State = BaseType<EstablishmentDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    note: '',
    state: EstablishmentDtoStateEnum.Active,
    contact: {
      name: '',
      lastName: '',
      phone: '',
      email: '',
    },
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
    },
    stateChangeEnabled: true,
  },
};

export const establishmentSlice = baseSlice({
  name: 'establishment',
  initialState,
});

export const selectEstablishmentState = (state: RootState) =>
  state.establishment.state;
export const selectEstablishment = (state: RootState) =>
  state.establishment.data;
export const establishmentActions = establishmentSlice.actions;

export const getEstablishment =
  (establishmentId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return establishmentApi.getEstablishment(establishmentId);
    };

    getItem({
      dispatch,
      actions: establishmentActions,
      getData: handleGetData,
    });
  };
