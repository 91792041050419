import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { QuestionnaireVersionDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getQuestionnaireVersionList,
  selectQuestionnaireVersionList,
  questionnaireVersionListActions,
} from '../../store/entities/questionnaireVersionList';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { CellDate } from '../common/grid/CellDate';
import { Grid } from '../common/grid/Grid';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { FaIcon } from '../common/FaIcon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CopyVersionToQuestionnaireModal } from './CopyVersionToQuestionnaireModal';
import { CopyVersionModal } from './CopyVersionModal';
import { questionnaireListActions } from '../../store/entities/questionnaireList';
import { QuestionnaireVersionDeleteModal } from './QuestionnaireVersionDeleteModal';
import { useTheme } from '@mui/material';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
}));

export const QuestionnaireVersion: FC = () => {
  const { t } = useTranslation();
  const questionnaireVersionList = useAppSelector(
    selectQuestionnaireVersionList
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { questionnaireId } = useParams();
  const [versionToCopy, setVersionToCopy] =
    useState<QuestionnaireVersionDto | null>(null);
  const [versionToCopyToQuestionnaire, setVersionToCopyToQuestionnaire] =
    useState<QuestionnaireVersionDto | null>(null);
  const [questionnaireVersionToDelete, setQuestionnaireVersionToDelete] =
    useState<string>('');

  useEffect(() => {
    dispatch(questionnaireVersionListActions.reload(true));
  }, [dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getQuestionnaireVersionList(questionnaireId!, cancelToken));
  };

  const handleAddVersionCopy =
    (version: QuestionnaireVersionDto) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setVersionToCopy(version);
    };

  const handleAddVersionCopyToQuestionnaire =
    (version: QuestionnaireVersionDto) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setVersionToCopyToQuestionnaire(version);
    };

  const handleDeleteVersion =
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setQuestionnaireVersionToDelete(id);
    };

  const handleRenderData = (item: QuestionnaireVersionDto) => {
    return (
      <>
        <QcsTableCell>
          {item.active && (
            <FaIcon icon={faCalendarCheck} color={theme.palette.primary.main} />
          )}
        </QcsTableCell>
        <CellDate value={item.validFrom} />
        <CellDate value={item.validTo} />
        <QcsTableCell align="center">
          <QcsButton onClick={handleAddVersionCopy(item)}>
            <FaIcon icon={faCopy} />
          </QcsButton>
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsButton onClick={handleAddVersionCopyToQuestionnaire(item)}>
            <FaIcon icon={faCopy} />
          </QcsButton>
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsButton
            disabled={!item.editable}
            onClick={handleDeleteVersion(item.id!)}
          >
            <DeleteForeverIcon color={!item.editable ? undefined : 'error'} />
          </QcsButton>
        </QcsTableCell>
      </>
    );
  };

  const handleReloadQuestionnaireVersionList = () => {
    dispatch(questionnaireVersionListActions.reload(true));
    dispatch(questionnaireListActions.reload(true));
  };

  const handleRowClick = (item: QuestionnaireVersionDto) => {
    navigate(`/questionnaire/${questionnaireId}/${item.id}`);
  };

  const handleAddClick = () => {
    navigate(`/questionnaire/${questionnaireId}/new`);
  };

  const handleCloseDeleteVersionModal = () => {
    setQuestionnaireVersionToDelete('');
  };

  return (
    <>
      <CopyVersionModal
        versionToCopy={versionToCopy}
        onClose={() => setVersionToCopy(null)}
        reloadQuestionnaireVersionList={handleReloadQuestionnaireVersionList}
      />
      <CopyVersionToQuestionnaireModal
        questionnaireId={questionnaireId}
        versionToCopy={versionToCopyToQuestionnaire}
        onClose={() => setVersionToCopyToQuestionnaire(null)}
        reloadQuestionnaireVersionList={handleReloadQuestionnaireVersionList}
      />
      <QuestionnaireVersionDeleteModal
        open={!!questionnaireVersionToDelete}
        onClose={handleCloseDeleteVersionModal}
        questionnaireVersionId={questionnaireVersionToDelete}
        reloadQuestionnaireList={handleReloadQuestionnaireVersionList!}
      />
      <HeaderContainer>
        <QcsTypography variant="h5">{t('questionnaire.version')}</QcsTypography>
        <QcsButton variant="contained" onClick={handleAddClick}>
          {t('questionnaire.addVersion')}
        </QcsButton>
      </HeaderContainer>
      <Grid<QuestionnaireVersionDto>
        headers={[
          { sx: { width: '56px' } },
          { captionStr: 'questionnaire.validFrom', orderName: 'validFrom' },
          { captionStr: 'questionnaire.validTo', orderName: 'validTo' },
          {
            captionStr: 'questionnaire.copy.addVersion',
            sx: { textAlign: 'center' },
          },
          {
            captionStr: 'questionnaire.copy.versionToQuestionnaire',
            sx: { textAlign: 'center' },
          },
          {
            captionStr: 'questionnaire.deleteVersion',
            sx: { textAlign: 'center' },
          },
        ]}
        data={questionnaireVersionList}
        gridActions={questionnaireVersionListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
      />
    </>
  );
};
