import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { QcsSelect } from '../../common/basic/QcsSelect';
import _ from 'lodash';
import { QcsMenuItem } from '../../common/basic/QcsMenuItem';
import { QcsFormControl } from '../../common/basic/QcsFormControl';
import { QcsInputLabel } from '../../common/basic/QcsInputLabel';
import { QcsFormHelperText } from '../../common/basic/QcsFormHelperText';
import { FLAGS } from '../../../utils/i18n';
import { SelectItem } from '../../common/form/Select';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  notAllowedFlags?: string[];
}

export const FlagSelect: FC<Props> = ({
  className,
  name,
  label,
  disabled,
  required,
  notAllowedFlags,
}) => {
  const { values, errors, touched, submitCount, handleChange, handleBlur } =
    useFormikContext<any>();

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const flagsMemo = useMemo(() => {
    const flags = FLAGS.filter((x) =>
      notAllowedFlags ? !notAllowedFlags?.includes(x.code) : true
    ).sort((a, b) => a.name.localeCompare(b.name));

    return flags.map((flag) => (
      <QcsMenuItem key={flag.code} value={flag.code}>
        <SelectItem>
          <img
            src={'/flags/' + flag.code + '.svg'}
            height="20"
            loading="lazy"
          />
          <div>{flag.name}</div>
        </SelectItem>
      </QcsMenuItem>
    ));
  }, [notAllowedFlags]);

  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel required={required} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        required={required}
        className={className}
        sx={{ backgroundColor: (theme) => theme.palette.common.white }}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        disabled={disabled}
      >
        {flagsMemo}
      </QcsSelect>
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
