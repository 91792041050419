import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { SupplierCertDto } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { supplierEmployeeApi } from '../../utils/api';

export type SupplierEmployeeDocumentListState = ListType<
  SupplierCertDto,
  ListFilter
>;

const initialState: SupplierEmployeeDocumentListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'created',
    sortDesc: true,
  },
};

export const supplierEmployeeDocumentListSlice = listSlice({
  name: 'supplierEmployeeDocumentList',
  initialState,
});

export const selectSupplierEmployeeDocumentList = (state: RootState) =>
  state.supplierEmployeeDocumentList;
export const supplierEmployeeDocumentListActions =
  supplierEmployeeDocumentListSlice.actions;

export const getSupplierEmployeeDocumentList =
  (employeeId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectSupplierEmployeeDocumentList(storeState);

    const handleGetData = (size: number) => {
      return supplierEmployeeApi.getCertificates(
        employeeId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: supplierEmployeeDocumentListActions,
      getData: handleGetData,
    });
  };
