import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { WorkplaceDto } from '@qcs/safety-client';
import {
  getOptionsWorkplace,
  selectOptionsWorkplace,
  selectOptionsWorkplaceState,
} from '../../../store/entities/optionsWorkplace';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

interface Props {
  estabilishments?: string[];
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  excludeIds?: string[];
  showEstabilishmentName?: boolean;
}

export const WorkplaceAutocomplete: FC<Props> = ({
  estabilishments,
  multiple,
  name,
  label,
  disabled,
  required,
  excludeIds,
  showEstabilishmentName,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsWorkplace = useAppSelector(selectOptionsWorkplace);
  const optionsWorkplaceState = useAppSelector(selectOptionsWorkplaceState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsWorkplace(
        identityCompanyId,
        search,
        estabilishments,
        cancelToken
      )
    );
  };

  const handleOptionName = (option: WorkplaceDto) => {
    if (showEstabilishmentName && getLangNameObj(i18n, option.establishment)) {
      return `${getLangNameObj(i18n, option)} | ${getLangNameObj(
        i18n,
        option.establishment
      )}`;
    }

    return getLangNameObj(i18n, option);
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={
        excludeIds
          ? optionsWorkplace.filter((x) => !excludeIds?.includes(x.id!))
          : optionsWorkplace
      }
      loading={optionsWorkplaceState === FetchState.Loading}
      loadingError={optionsWorkplaceState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: WorkplaceDto) => option.id!}
      getOptionName={handleOptionName}
    />
  );
};
