import { RiskVersionDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { DetailHeader } from '../common/DetailHeader';
import * as Yup from 'yup';
import { FormContainer } from '../common/form/FormContainer';
import { useTranslation } from 'react-i18next';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { SaveError } from '../common/SaveError';
import { DatePicker } from '../common/form/DatePicker';
import { DocumentInput } from './DocumentInput';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getRiskVersion,
  riskVersionActions,
  selectRiskVersion,
  selectRiskVersionState,
} from '../../store/entities/riskVersion';
import { mediaApi, riskApi } from '../../utils/api';
import { riskVersionListActions } from '../../store/entities/riskVersionList';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { validations } from '../../utils/validations';
import { QcsTextField } from '../common/basic/QcsTextField';
import {
  getRisk,
  selectRisk,
  selectRiskState,
} from '../../store/entities/risk';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { isDateDisabled } from '../../utils/date';
import { useBack } from '../../hooks/useBack';

export const RiskVersionDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { riskId, riskVersionId } = useParams();
  const { backTo, goBack } = useBack(`/risk/${riskId}`);
  const dispatch = useAppDispatch();
  const riskVersion = useAppSelector(selectRiskVersion);
  const riskVersionState = useAppSelector(selectRiskVersionState);
  const risk = useAppSelector(selectRisk);
  const riskState = useAppSelector(selectRiskState);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [oldFilesToRemove, setOldFilesToRemove] = useState<string[]>([]);

  useEffect(() => {
    if (riskVersionId === 'new') {
      dispatch(riskVersionActions.default());
    } else {
      dispatch(getRiskVersion(riskId!, riskVersionId!));
    }
    dispatch(getRisk(riskId!));

    setLoading(false);
  }, [dispatch, riskId, riskVersionId]);

  const handleSubmit = async (data: RiskVersionDto) => {
    setSaveError('');
    try {
      if (riskVersionId === 'new') {
        await riskApi.createRiskVersion(riskId!, data);

        enqueueSuccessSnackbar(t('risk.addVersionSuccess'));
      } else {
        await riskApi.updateRiskVersion(riskId!, riskVersionId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      for (const oldFile of oldFilesToRemove) {
        try {
          await mediaApi.deleteFile(oldFile);
        } catch {
          //Ignore error.
        }
      }

      dispatch(riskVersionListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        riskVersionId === 'new' ? 'risk.addVersionError' : 'common.editError'
      );
    }
  };

  const handleRemoveOldFile = (document: string) => {
    setOldFilesToRemove([...oldFilesToRemove, document]);
  };

  if (
    loading ||
    riskVersionState === FetchState.Loading ||
    riskState === FetchState.Loading
  ) {
    return <Loader />;
  }

  if (riskVersionState === FetchState.Error || riskState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <div>
      <DetailHeader title="risk.version" backTo={backTo} />
      <FormikDebounce<RiskVersionDto>
        initialValues={riskVersion}
        validationSchema={
          riskVersion.editable
            ? Yup.object().shape({
                validFrom: !!riskVersion.first
                  ? validations.dateRequired(t)
                  : validations.stringDateNotPastOrTodayRequired(t),
                document: validations.stringRequired(t),
              })
            : undefined
        }
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <QcsTextField
                label={t('risk.name')}
                value={getLangNameObj(i18n, risk)}
                sx={{ backgroundColor: (theme) => theme.palette.common.white }}
                disabled
              />
              <QcsTextField
                label={t('risk.version')}
                value={values.validFrom || ''}
                sx={{ backgroundColor: (theme) => theme.palette.common.white }}
                disabled
              />

              <DatePicker
                name="validFrom"
                label={t('risk.validFrom')}
                disablePast
                disableHighlightToday
                shouldDisableDate={isDateDisabled}
                required
                disabled={!riskVersion.editable || !!riskVersion.first}
              />
              {riskVersionId !== 'new' && (
                <DatePicker name="validTo" label={t('risk.validTo')} disabled />
              )}
              <DocumentInput
                disabled={!riskVersion.editable}
                required
                onRemoveOldFile={handleRemoveOldFile}
              />
              <Input
                name="note"
                label={t('user.note')}
                maxLength={250}
                multiline={true}
                rows={4}
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </div>
  );
};
