import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CompanyDto, CompanyDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { companyApi } from '../../utils/api';

type State = BaseType<CompanyDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    ico: '',
    vat: '',
    note: '',
    state: CompanyDtoStateEnum.PreActivated,
    subDomain: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
    },
    contact: {
      name: '',
      lastName: '',
      phone: '',
      email: '',
    },
  },
};

export const qcsAdminCompanySlice = baseSlice({
  name: 'qcsAdminCompany',
  initialState,
});

export const selectQcsAdminCompanyState = (state: RootState) =>
  state.qcsAdminCompany.state;
export const selectQcsAdminCompany = (state: RootState) =>
  state.qcsAdminCompany.data;
export const qcsAdminCompanyActions = qcsAdminCompanySlice.actions;

export const getQcsAdminCompany =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companyApi.getCompany(companyId);
    };

    getItem({
      dispatch,
      actions: qcsAdminCompanyActions,
      getData: handleGetData,
    });
  };
