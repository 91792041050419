import {
  CopyVersionRequest,
  QuestionnaireVersionDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { DatePicker } from '../common/form/DatePicker';
import { Input } from '../common/form/Input';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { questionnairesApi } from '../../utils/api';
import { isDateDisabled } from '../../utils/date';
import { ErrorStateType } from '../../models/common';

interface Props {
  versionToCopy?: QuestionnaireVersionDto | null;
  onClose: () => void;
  reloadQuestionnaireVersionList: () => void;
}

export const CopyVersionModal: FC<Props> = ({
  versionToCopy,
  onClose,
  reloadQuestionnaireVersionList: reloadTrainingVersionList,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [versionToCopy]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (data: CopyVersionRequest) => {
    setSaveError('');

    try {
      await questionnairesApi.copyQuestionnaireVersion(
        versionToCopy!.id!,
        data
      );
      enqueueSuccessSnackbar(t('questionnaire.copy.success'));
      reloadTrainingVersionList();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'questionnaire.copy.error'
      );
    }
  };

  return (
    <QcsModal open={!!versionToCopy} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={'questionnaire.copy.addVersion'} />
        <FormikDebounce<CopyVersionRequest>
          initialValues={{
            validFrom: '',
            versionNote: '',
          }}
          validationSchema={Yup.object().shape({
            validFrom: validations.stringDateNotPastOrTodayRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <DatePicker
                name="validFrom"
                label={t('questionnaire.validFrom')}
                disablePast
                disableHighlightToday
                shouldDisableDate={isDateDisabled}
                required
              />
              <Input
                name="versionNote"
                label={t('questionnaire.versionNote')}
                maxLength={250}
                multiline={true}
                rows={4}
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
