import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from './basic/QcsTypography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const DetailHeaderContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

interface Props {
  title?: string | null;
  backText?: string;
  backTo?: string | null;
}

export const DetailHeader: FC<Props> = ({ title, backText, backTo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(backTo!);
  };

  return (
    <DetailHeaderContainer>
      {!!backTo && (
        <QcsButton variant="outlined" onClick={handleBackClick}>
          <ArrowBackIcon />
          {backText ? t(backText) : t('common.back')}
        </QcsButton>
      )}
      {!!title && <QcsTypography variant="h4">{t(title)}</QcsTypography>}
    </DetailHeaderContainer>
  );
};
