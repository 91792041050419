import { format, formatISO } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateLang } from './i18n';

export const getDateForApi = (date?: Date | null) =>
  date ? format(date, 'yyyy-MM-dd') : undefined;

export const getDateTimeForApi = (date?: Date | null) =>
  date ? formatISO(date) : undefined;

export const isDateDisabled = (day: Date) => {
  const today = new Date();
  return day <= today;
};

interface Props {
  value?: Date | null;
  showWithTime?: boolean;
}

export const DateFormat: FC<Props> = ({ value, showWithTime }) => {
  const { i18n } = useTranslation();

  if (!value) {
    return null;
  }

  return (
    <>
      {format(value, showWithTime ? 'P p' : 'P', {
        locale: getDateLang(i18n),
      })}
    </>
  );
};
