import {
  InvitationDtoStateEnum,
  InvitationRelationResponse,
  SafetyEquipmentDtoTypeEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useMemo, useState } from 'react';
import { selectIdentity } from '../../../../store/entities/identity';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { GridList } from '../../../common/grid/GridList';
import { useAppSelector } from '../../../../store';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { invitationsApi } from '../../../../utils/api';
import { downloadBlob } from '../../../../utils/download';
import { AxiosResponse } from 'axios';
import { setErrorSnacks } from '../../../../utils/error';
import { WorkplaceRiskModal } from './WorkplaceRiskModal';
import { WorkplaceSafetyEquipmentModal } from './WorkplaceSafetyEquipmentModal';
import { WorkplacePersonModal } from './WorkplacePersonModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { WorkplaceDeleteModal } from './WorkplaceDeleteModal';
import { WorkplaceAddModal } from './WorkplaceAddModal';
import {
  faHelmetSafety,
  faRoadBarrier,
} from '@fortawesome/free-solid-svg-icons';
import { FaIcon } from '../../../common/FaIcon';
import { useTheme } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { hasOtherRoleThan, hasRole } from '../../../../utils/roles';
import { getLangNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';

interface WorkplaceTableData extends InvitationRelationResponse {
  id?: string;
}

export const WorkplaceTable: FC = () => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const identity = useAppSelector(selectIdentity);
  const [riskModal, setRiskModal] = useState<WorkplaceTableData>();
  const [workplaceAddModal, setWorkplaceAddModal] = useState<boolean>(false);
  const [workplaceToDelete, setWorkplaceToDelete] =
    useState<WorkplaceTableData>();
  const [safetyEquipmentModal, setSafetyEquipmentModal] =
    useState<WorkplaceTableData>();
  const [personModal, setPersonModal] = useState<WorkplaceTableData>();
  const [checkedItems, setCheckedItems] = useState<WorkplaceTableData[]>([]);
  const theme = useTheme();

  const list = useMemo(
    (): WorkplaceTableData[] =>
      (invitation.workplaces ?? []).map((x) => ({ ...x, id: x.workplace?.id })),
    [invitation.workplaces]
  );

  const checkedItemsForDownload = useMemo(
    () =>
      checkedItems.filter((item) =>
        (item.risks ?? []).some((risk) => !!risk.file?.id)
      ),
    [checkedItems]
  );

  const checkedItemsForApprove = useMemo(
    () =>
      checkedItems.filter((item) =>
        (item.risks ?? []).some((risk) => !risk.approvedOn)
      ),
    [checkedItems]
  );

  const handleRenderData = (item: WorkplaceTableData) => {
    const showRiskDisabled = (item.risks?.length ?? 0) < 1;
    const showRiskWarning =
      !showRiskDisabled && (item.risks ?? []).some((risk) => !risk.approvedOn);
    const showSafetyEquipmentDisabled =
      (item.safetyEquipments?.length ?? 0) < 1;
    const showSafetyEquipmentOk =
      !showSafetyEquipmentDisabled &&
      !(item.safetyEquipments ?? []).some(
        (x) => x.type === SafetyEquipmentDtoTypeEnum.Owned
      );
    const personOk =
      invitation.persons?.some(
        (person) =>
          person.workplaces?.some(
            (workplace) => workplace.id === item.workplace?.id
          ) === true
      ) === true;
    const deleteDisabled =
      !hasOtherRoleThan(identity.roles, [
        UserDtoRolesEnum.ExternalManager,
        UserDtoRolesEnum.ExternalWorker,
      ]) ||
      //At least one workplace must left.
      (invitation.workplaces ?? []).length < 2 ||
      //After delete no person with no workplace.
      invitation.persons?.some(
        (person) =>
          person.workplaces?.some(
            (workplace) => workplace.id === item.workplace?.id
          ) === true && person.workplaces.length === 1
      ) === true;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.workplace)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getLangNameObj(i18n, item.establishment)}
        </QcsTableCell>
        <QcsTableCell align="right">
          {/* SHOW RISK WORKPLACE */}
          <QcsTooltip
            title={t('invitation.workplace.riskTitle')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleShowRisk(item)}
                loading={isSaving}
                disabled={showRiskDisabled}
              >
                <FaIcon
                  icon={faRoadBarrier}
                  color={
                    showRiskDisabled
                      ? undefined
                      : showRiskWarning
                      ? theme.palette.warning.main
                      : theme.palette.success.main
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          {/* SAFETY EQUIPMENT */}
          <QcsTooltip
            title={t('invitation.workplace.safetyEquipmentTitle')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={showSafetyEquipment(item)}
                loading={isSaving}
                disabled={showSafetyEquipmentDisabled}
              >
                <FaIcon
                  icon={faHelmetSafety}
                  color={
                    showSafetyEquipmentDisabled
                      ? undefined
                      : showSafetyEquipmentOk
                      ? theme.palette.success.main
                      : theme.palette.warning.main
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          {/* INVITED PERSONS */}
          <QcsTooltip
            title={t('invitation.workplace.personTitle')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleShowPerson(item)}
                loading={isSaving}
              >
                <ManageAccountsOutlinedIcon
                  color={personOk ? 'success' : 'warning'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          {/* DELETE WORKPLACE */}
          <QcsTooltip title={t('workplace.deleteWorkplace')} placement="top">
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDeleteWorkplace(item)}
                loading={isSaving}
                disabled={deleteDisabled}
              >
                <DeleteForeverIcon
                  color={deleteDisabled ? undefined : 'error'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleChangeChecked = (items: WorkplaceTableData[]) => {
    setCheckedItems(items);
  };

  const handleItemToSelectObject = (item: WorkplaceTableData) => item;

  const handleSelectObjectGetId = (item: WorkplaceTableData) => item.id!;

  const handleIsCheckDisabled = (item: WorkplaceTableData) =>
    !(item.risks ?? []).some((risk) => !risk.approvedOn || !!risk.file?.id);

  const handleDownload = async () => {
    const items = checkedItemsForDownload;
    if (!items) {
      return;
    }

    setIsSaving(true);
    try {
      const res = await invitationsApi.generateZipFile(
        invitation.id!,
        { entries: items.map((x) => x.id!) },
        true,
        'RISKS',
        {
          responseType: 'blob',
        }
      );
      const blobResponse = res as AxiosResponse<Blob>;
      await downloadBlob(blobResponse);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
  };

  const handleApprove = async () => {
    const items = checkedItemsForApprove;
    if (!items) {
      return;
    }

    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveRisks(invitation.id!, {
        workplacesWithRisks: Object.fromEntries(
          items.map((x) => [x.id, x.risks?.map((risk) => risk.risk?.id) ?? []])
        ),
      });
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleShowRisk =
    (item: WorkplaceTableData) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setRiskModal(item);
    };

  const handleCloseRisk = () => {
    setRiskModal(undefined);
  };

  const showSafetyEquipment =
    (item: WorkplaceTableData) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setSafetyEquipmentModal(item);
    };

  const handleCloseSafetyEquipment = () => {
    setSafetyEquipmentModal(undefined);
  };

  const handleShowPerson =
    (item: WorkplaceTableData) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setPersonModal(item);
    };

  const handleClosePerson = () => {
    setPersonModal(undefined);
  };

  const handleDeleteWorkplace =
    (item: WorkplaceTableData) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setWorkplaceToDelete(item);
    };

  const handleCloseDeleteWorkplace = () => {
    setWorkplaceToDelete(undefined);
  };

  const handleAddWorkplace = () => {
    setWorkplaceAddModal(true);
  };

  const handleCloseAddWorkplace = () => {
    setWorkplaceAddModal(false);
  };

  const showDownloadButton = true;

  const showAddButton = hasOtherRoleThan(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
  ]);

  return (
    <>
      <WorkplaceRiskModal
        workplaceId={riskModal?.workplace?.id}
        onClose={handleCloseRisk}
      />
      <WorkplaceSafetyEquipmentModal
        item={safetyEquipmentModal}
        onClose={handleCloseSafetyEquipment}
      />
      <WorkplacePersonModal item={personModal} onClose={handleClosePerson} />
      <WorkplaceDeleteModal
        workplace={workplaceToDelete}
        onClose={handleCloseDeleteWorkplace}
      />
      <WorkplaceAddModal
        open={workplaceAddModal}
        onClose={handleCloseAddWorkplace}
      />

      <QcsBox
        sx={{
          display: 'flex',
          justifyContent:
            showDownloadButton && showAddButton
              ? 'space-between'
              : showDownloadButton
              ? 'flex-start'
              : 'flex-end',
        }}
      >
        {showDownloadButton && (
          <QcsLoadingButton
            variant="contained"
            onClick={handleDownload}
            disabled={checkedItemsForDownload.length === 0}
            loading={isSaving}
            sx={{ mb: '1rem' }}
          >
            {t('invitation.workplace.download')}
          </QcsLoadingButton>
        )}
        {showAddButton && (
          <QcsLoadingButton
            variant="contained"
            onClick={handleAddWorkplace}
            loading={isSaving}
            sx={{ mb: '1rem' }}
          >
            {t('workplace.addWorkplace')}
          </QcsLoadingButton>
        )}
      </QcsBox>
      <GridList<WorkplaceTableData, WorkplaceTableData>
        headers={[
          { captionStr: 'invitation.workplace.name' },
          {
            captionStr: 'invitation.workplace.establishment',
            hideOnMobile: true,
          },
          {},
        ]}
        data={list}
        renderData={handleRenderData}
        search={false}
        hidePagination={true}
        checkbox={true}
        checkedItems={checkedItems}
        changeChecked={handleChangeChecked}
        itemToSelectObject={handleItemToSelectObject}
        selectObjectGetId={handleSelectObjectGetId}
        isCheckDisabled={handleIsCheckDisabled}
      />
      <QcsLoadingButton
        variant="contained"
        onClick={handleApprove}
        disabled={
          !hasRole(identity.roles, [
            UserDtoRolesEnum.ExternalManager,
            UserDtoRolesEnum.ManagerWorkplace,
          ]) ||
          invitation.state === InvitationDtoStateEnum.Archived ||
          checkedItemsForApprove.length === 0
        }
        loading={isSaving}
        sx={{ mt: '1.5rem' }}
      >
        {t('invitation.workplace.approve')}
      </QcsLoadingButton>
    </>
  );
};
