import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '../../utils/constants';
import { QcsListItemIcon } from '../common/basic/QcsListItemIcon';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { mediaForFixedHeader } from './LayoutStyles';

export const SidebarContainer = styled.div(({ theme }) => ({
  display: 'none',
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const MenuListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  flexDirection: 'column',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '14px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&::-webkit-scrollbar-thumb': {
    border: 'solid 3px transparent',
    borderRadius: '10px',
    boxShadow: `inset 0 0 10px 10px ${theme.palette.secondary.dark}`,
  },

  [mediaForFixedHeader(theme)]: {
    height: `calc(100vh - ${HEADER_HEIGHT})`,
  },
}));

export const StyledQcsListItemIcon = styled(QcsListItemIcon)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    color: theme.palette.secondary.contrastText,
  },
}));

interface HiddenProps {
  isHidden: boolean;
}

export const StyledQcsMenuItem = styled(QcsMenuItem)<HiddenProps>(
  ({ theme, isHidden }) => `
  padding: 0.75rem;
  padding-left: 2rem;
  padding-right: ${isHidden ? '1.5rem' : '2rem'};
  &.active {
    color: ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};
    border-right: 0.4rem solid;
    border-color: ${theme.palette.primary.main};
    padding-right: ${isHidden ? '1.1rem' : '1.6rem'};
    ${StyledQcsListItemIcon} {
      color: ${theme.palette.primary.main};
    }
  }
`
);

export const HideMenuButtonWrapper = styled.div<HiddenProps>(
  ({ isHidden }) => ({
    marginLeft: 'auto',
    marginRight: isHidden ? 'auto' : '0',
    marginTop: '0.25rem',
    marginBottom: '-0.25rem',
  })
);
