import { SubmitForgottenPassword } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../store';
import { storageResetAction } from '../../store/storeReset';
import { forgottenPasswordApi } from '../../utils/api';
import { logout } from '../../utils/storage';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { LoginContainer, LoginWrapper } from './LoginStyles';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

export const ForgottenPassword: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorStateType>();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token')!;
  const email = params.get('email')!;

  useEffect(() => {
    logout();
    dispatch(storageResetAction());

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (data: SubmitForgottenPassword) => {
    setError('');
    try {
      await forgottenPasswordApi.submitForgottenPassword(data);
      enqueueSuccessSnackbar(t('auth.changePasswordSucess'));
      navigate('/');
    } catch (err) {
      setErrorStateSnacks(
        err,
        setError,
        enqueueErrorSnackbar,
        'auth.changePasswordError'
      );
    }
  };

  return (
    <LoginWrapper>
      <SectionHeader title="auth.passwordReset" />
      <FormikDebounce<SubmitForgottenPassword>
        initialValues={{
          id: token,
          email,
          password: '',
          passwordAgain: '',
        }}
        validationSchema={Yup.object({
          passwordAgain: validations
            .stringRequired(t)
            .oneOf([Yup.ref('password')], t('auth.passwordMismatch')!),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <LoginContainer>
            <Input name="password" type="password" label={t('auth.password')} />
            <Input
              name="passwordAgain"
              type="password"
              label={t('auth.passwordAgain')}
            />

            <SubmitButton text="auth.changePassword" />
            <SaveError error={error} />
          </LoginContainer>
        </Form>
      </FormikDebounce>
    </LoginWrapper>
  );
};
