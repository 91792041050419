import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType } from '../baseHelper';
import { mediaApi } from '../../utils/api';
import { AxiosResponse } from 'axios';

export type SubdomainCustomizationState = BaseType<string>;

const initialState: SubdomainCustomizationState = {
  state: FetchState.None,
  data: '',
};

export const subdomainCustomizationSlice = baseSlice({
  name: 'subdomainCustomization',
  initialState,
  disableReset: true,
});

export const selectSubdomainCustomizationState = (state: RootState) =>
  state.subdomainCustomization.state;
export const selectSubdomainCustomization = (state: RootState) =>
  state.subdomainCustomization.data;
const subdomainCustomizationActions = subdomainCustomizationSlice.actions;

export const getSubdomainCustomization =
  (subdomain: string | null | undefined) => (dispatch: AppDispatch) => {
    const subdomainState = selectSubdomainCustomizationState(store.getState());
    if (subdomainState !== FetchState.None) {
      return;
    }

    getData(subdomain, dispatch);
  };

const getData = async (
  subdomain: string | null | undefined,
  dispatch: AppDispatch
) => {
  dispatch(subdomainCustomizationActions.request());
  try {
    if (subdomain) {
      const response = await mediaApi.getBannerForSubdomain(subdomain, {
        responseType: 'blob',
      });
      const blobResponse = response as AxiosResponse<Blob>;
      const data = new Blob([blobResponse.data], {
        type: blobResponse.data.type,
      });
      const blobURL = window.URL.createObjectURL(data);

      dispatch(subdomainCustomizationActions.success(blobURL));
    } else {
      dispatch(subdomainCustomizationActions.success(''));
    }
  } catch {
    dispatch(subdomainCustomizationActions.error());
  }
};
