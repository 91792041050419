import { FC, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import { QcsMenuItem } from '../basic/QcsMenuItem';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';

interface Props {
  className?: string;
  value: unknown;
  options: unknown[];
  onChange: (value: any) => void;
  getOptionId: (option: any) => string;
  getOptionName: (option: any) => string;
}

export const GridFilterSelect: FC<Props> = ({
  className,
  value,
  options,
  onChange,
  getOptionId,
  getOptionName,
}) => {
  const { t } = useTranslation();

  const selectedValue = value === undefined ? 'undefined' : value;

  const items = useMemo(() => {
    return [
      <QcsMenuItem key={'undefined'} value={'undefined'}>
        <em>{t('common.nonSelected')}</em>
      </QcsMenuItem>,
      ...options.map((option) => (
        <QcsMenuItem key={getOptionId(option)} value={getOptionId(option)}>
          {getOptionName(option)}
        </QcsMenuItem>
      )),
    ];
  }, [getOptionId, getOptionName, options, t]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const val = event.target.value;
    const newVal = val === 'undefined' ? undefined : val;
    onChange(newVal);
  };

  return (
    <QcsSelect
      className={className}
      fullWidth={true}
      value={selectedValue}
      onChange={handleChange}
      displayEmpty
      renderValue={(selected) => {
        if (selected === 'undefined') {
          return <em>{t('common.nonSelected')}</em>;
        }

        return getOptionName(selected);
      }}
    >
      {items}
    </QcsSelect>
  );
};
