import {
  CreateUserFromEmployeeRequest,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { qcUserApi } from '../../utils/api';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { UserRoleSelect } from '../common/form/UserRoleSelect';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { ErrorStateType } from '../../models/common';

interface Props {
  open: boolean;
  onClose: () => void;
  reloadData?: () => void;
  employeeId?: string;
}

export const CreateUserModal: FC<Props> = ({
  open,
  onClose,
  reloadData,
  employeeId: employeeIdProp,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { employeeId } = useParams();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (data: CreateUserFromEmployeeRequest) => {
    setSaveError('');
    try {
      await qcUserApi.createUser(employeeIdProp ?? employeeId!, data);
      enqueueSuccessSnackbar(t('employee.createUserSuccess'));
      reloadData?.();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'employee.createUserError'
      );
    }
  };

  const validate = (values: CreateUserFromEmployeeRequest) => {
    const errors: Partial<Record<keyof CreateUserFromEmployeeRequest, string>> =
      {};

    if (
      values.roles.some(
        (role) =>
          role === UserDtoRolesEnum.Tourniquet ||
          role === UserDtoRolesEnum.TourniquetTraining
      ) &&
      values.roles.length !== 1
    ) {
      errors.roles = t('user.errorInvalidRoleCombination') ?? undefined;
    }

    return errors;
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="employee.createUserTitle" />
        <FormikDebounce<CreateUserFromEmployeeRequest>
          initialValues={{
            roles: [],
            language: companyCustomization?.defaultLanguage ?? '',
          }}
          validationSchema={Yup.object().shape({
            roles: validations.arrayNotEmpty(t),
            language: validations.stringRequired(t),
          })}
          validate={validate}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <UserRoleSelect name="roles" label={t('user.roles')} required />
              <LanguageSelect
                name={'language'}
                label={t('user.defaultLanguage')}
                languages={SUPPORTED_LANGUAGES}
                required
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
