import styled from '@emotion/styled';
import { QcsModalBox } from '../common/basic/QcsModalBox';

export const QcsProtocolImg = styled.img(() => ({
  maxWidth: 'calc(100vw - 5rem)',
  maxHeight: 'calc(100vh - 5rem)',
}));

export const QcsProtocolIframe = styled.iframe(() => ({
  width: 'calc(100vw - 5rem)',
  height: 'calc(100vh - 5rem)',
  border: 'none',
}));

export const QcsProtocolModalBox = styled(QcsModalBox)<{
  disableBorder: boolean;
}>(({ disableBorder }) => ({
  padding: disableBorder ? 0 : undefined,
  border: disableBorder ? 'none' : undefined,
  backgroundColor: disableBorder ? 'transparent' : undefined,
}));
