import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../../utils/i18n';
import { QcsCard } from '@s4e/design-system/molecules/blocks/QcsCard';
import { QcsCardContent } from '@s4e/design-system/molecules/blocks/QcsCardContent';
import { QcsTypography } from '../../common/basic/QcsTypography';
import { DetailHeader } from '../../common/DetailHeader';
import { SelectItem } from '../../common/form/Select';
import { TrainingLanguages } from './TrainingLanguages';

export const QcsAdminSettings: FC = () => {
  const { t } = useTranslation();

  const appSupportedLanguagesMemo = useMemo(
    () =>
      SUPPORTED_LANGUAGES.map((x) => (
        <SelectItem key={x.code}>
          <img src={'/flags/' + x.flag + '.svg'} height="20" />
          <QcsTypography>{x.name}</QcsTypography>
        </SelectItem>
      )),
    []
  );

  return (
    <>
      <QcsCard>
        <QcsCardContent>
          <DetailHeader title={t('admin.settings.appLang.title')} />
          {appSupportedLanguagesMemo}
        </QcsCardContent>
      </QcsCard>
      <div style={{ height: '1rem' }} />
      <TrainingLanguages />
    </>
  );
};
