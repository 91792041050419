import { FC } from 'react';
import _ from 'lodash';
import {
  ReferenceDto,
  SafetyEquipmentDto,
  SafetyEquipmentDtoStateEnum,
} from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  getSafetyEquipmentList,
  safetyEquipmentListActions,
  selectSafetyEquipmentList,
} from '../../store/entities/safetyEquipmentList';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { getLangNameObj } from '../../utils/format';

interface Props {
  name: string;
  open: boolean;
  onClose: () => void;
}

export const SafetyEquipmentSelectTableModal: FC<Props> = ({
  name,
  open,
  onClose,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const safetyEquipmentList = useAppSelector(selectSafetyEquipmentList);

  const handleSafetyEquipmentGetData = (cancelToken: CancelToken) => {
    dispatch(getSafetyEquipmentList(identityCompanyId, cancelToken));
  };

  const handleIsRowDisabled = (item: SafetyEquipmentDto) =>
    item.state !== SafetyEquipmentDtoStateEnum.Active;

  const handleSafetyEquipmentRenderData = (item: SafetyEquipmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('safetyEquipment.types.' + item.type)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const safetyEquipments: ReferenceDto[] = _.get(values, name)!;

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox
        sx={{ width: '90%', maxWidth: (theme) => theme.breakpoints.values.md }}
      >
        <Grid<SafetyEquipmentDto, ReferenceDto>
          headers={[
            {
              captionStr: 'safetyEquipment.name',
            },
            {
              captionStr: 'safetyEquipment.type',
              hideOnMobile: true,
            },
            {
              captionStr: 'safetyEquipment.state',
              hideOnMobile: true,
            },
          ]}
          //safetyEquipmentList is used by SafetyEquipment.tsx - must have same filter (actual) or split store
          data={safetyEquipmentList}
          gridActions={safetyEquipmentListActions}
          renderData={handleSafetyEquipmentRenderData}
          isRowDisabled={handleIsRowDisabled}
          getData={handleSafetyEquipmentGetData}
          search={false}
          checkbox={true}
          checkedItems={safetyEquipments ?? []}
          changeChecked={(items) => setFieldValue(name, items)}
          selectObjectGetId={(x: ReferenceDto) => x.id}
          itemToSelectObject={(item: SafetyEquipmentDto) =>
            ({
              id: item.id,
              name: item.name,
              nameEn: item.nameEn,
            } as ReferenceDto)
          }
        />
      </QcsModalBox>
    </QcsModal>
  );
};
