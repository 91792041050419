import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CertificateDto, CertificateDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { certificateApi } from '../../utils/api';

type State = BaseType<CertificateDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    expiryDateRequired: false,
    state: CertificateDtoStateEnum.Active,
    stateChangeEnabled: true,
  },
};

export const documentSlice = baseSlice({
  name: 'document',
  initialState,
});

export const selectDocumentState = (state: RootState) => state.document.state;
export const selectDocument = (state: RootState) => state.document.data;
export const documentActions = documentSlice.actions;

export const getDocument = (documentId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return certificateApi.getCertificate(documentId);
  };

  getItem({
    dispatch,
    actions: documentActions,
    getData: handleGetData,
  });
};
