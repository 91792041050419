import { EmployeeDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  employeeListActions,
  getEmployeeList,
  selectEmployeeList,
} from '../../store/entities/employeeList';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { EmployeeFilter } from './EmployeeFilter';
import { EmployeeImportExport } from './EmployeeImportExport';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FaIcon } from '../common/FaIcon';
import { faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { CreateUserModal } from './CreateUserModal';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateUser,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { userListActions } from '../../store/entities/userList';
import { getLangNameObj } from '../../utils/format';

export const Employee: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const employeeList = useAppSelector(selectEmployeeList);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateUser = useAppSelector(
    selectCompanyChecksCanCreateUser
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createUserEmployeeId, setCreateUserEmployeeId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCompanyChecks(identityCompanyId!));
    setLoading(false);
  }, [identityCompanyId, dispatch]);

  const handleGoToUser = (userId: string) => (event: any) => {
    event.stopPropagation();
    navigate(`/user/${userId}`, {
      state: { back: `/employee` },
    });
  };

  const handleCreateUser = (employeeId: string) => (event: any) => {
    event.stopPropagation();
    setCreateUserEmployeeId(employeeId);
  };

  const handleReloadData = () => {
    dispatch(employeeListActions.reload(true));
    dispatch(userListActions.reload(true));
  };

  const handleCloseCreatingUser = () => {
    setCreateUserEmployeeId('');
  };

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getEmployeeList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: EmployeeDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.office}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.jobPosition}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
        <QcsTableCell align="center">
          {item.user ? (
            <QcsButton onClick={handleGoToUser(item.user)}>
              <FaIcon icon={faUserPen} />
            </QcsButton>
          ) : (
            <QcsButton
              onClick={handleCreateUser(item.id!)}
              disabled={!companyChecksCanCreateUser}
            >
              <FaIcon icon={faUserPlus} />
            </QcsButton>
          )}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: EmployeeDto) => {
    navigate('/employee/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/employee/new');
  };

  if (loading || companyChecksState === FetchState.Loading) {
    return <Loader />;
  }

  if (companyChecksState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <CreateUserModal
        open={!!createUserEmployeeId}
        employeeId={createUserEmployeeId}
        onClose={handleCloseCreatingUser}
        reloadData={handleReloadData}
      />

      <SectionHeader
        title="employee.title"
        addText="employee.add"
        handleAddClick={handleAddClick}
      />
      <EmployeeImportExport />
      <Grid<EmployeeDto>
        headers={[
          { captionStr: 'employee.name', orderName: 'name' },
          { captionStr: 'employee.lastName', orderName: 'lastName' },
          {
            captionStr: 'employee.phone',
            orderName: 'phone',
            hideOnMobile: true,
          },
          {
            captionStr: 'employee.email',
            orderName: 'email',
            hideOnMobile: true,
          },
          {
            captionStr: 'employee.office',
            orderName: 'office',
            hideOnMobile: true,
          },
          {
            captionStr: 'employee.jobPosition',
            orderName: 'jobPosition',
            hideOnMobile: true,
          },
          {
            captionStr: 'employee.state',
            orderName: 'state',
            hideOnMobile: true,
          },
          {
            captionStr: 'employee.user',
          },
        ]}
        data={employeeList}
        gridActions={employeeListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<EmployeeFilter query={employeeList.query} />}
      />
    </>
  );
};
