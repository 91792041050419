import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFromTo } from '../../../models/common';
import { QcsDatePicker } from '../basic/QcsDatePicker';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { FormContainer } from '../form/FormContainer';

interface Props {
  value?: DateFromTo;
  onChange: (value?: DateFromTo) => void;
}

export const GridFilterDateFromTo: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const handleChangeFrom = (date?: Date | null) => {
    onChange({ from: date ? date : undefined, to: value?.to });
  };

  const handleChangeTo = (date?: Date | null) => {
    onChange({ from: value?.from, to: date ? date : undefined });
  };

  const isValid = !value?.from || !value.to || value.from < value.to;

  return (
    <FormContainer>
      <QcsDatePicker
        label={t('common.from')}
        value={value?.from ? value.from : null}
        onChange={handleChangeFrom}
        error={!isValid}
      />
      <QcsDatePicker
        label={t('common.to')}
        value={value?.to ? value.to : null}
        onChange={handleChangeTo}
        error={!isValid}
      />
      {!isValid && (
        <QcsFormHelperText error={true}>
          {t('errors.dateToBeforeFrom')}
        </QcsFormHelperText>
      )}
    </FormContainer>
  );
};
