import { FetchState } from '../../../store/fetchState';
import { GridActions, ListFilter, ListType } from '../../../store/listHelper';
import { Grid, GridData, GridProps } from './Grid';

interface Props<T extends GridData, U>
  extends Omit<GridProps<T, U>, 'data' | 'getData' | 'gridActions'> {
  data: T[];
}

export function GridList<T extends GridData, U = string>({
  data,
  ...otherProps
}: Props<T, U>) {
  const data2: ListType<T, ListFilter> = {
    state: FetchState.Loaded,
    data: {
      totalPages: 0,
      totalElements: 0,
      size: 0,
      content: data,
      number: 0,
      sort: {},
      pageable: {},
      numberOfElements: 0,
      first: false,
      last: false,
      empty: false,
    },
    query: {},
  };

  const gridAction = () => ({ type: '' });
  const gridActions: GridActions = {
    request: gridAction as any,
    success: gridAction as any,
    error: gridAction as any,
    updatePage: gridAction as any,
    updateOrder: gridAction as any,
    updateSearch: gridAction as any,
    updateFilter: gridAction as any,
    reload: gridAction as any,
  };

  const getData = () => {
    //Nothing.
  };

  return (
    <Grid<T, U>
      {...otherProps}
      data={data2}
      getData={getData}
      gridActions={gridActions}
    />
  );
}
