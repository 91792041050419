import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFromTo } from '../../../models/common';
import { QcsDateTimePicker } from '../basic/QcsDateTimePicker';
import { FormContainer } from '../form/FormContainer';

interface Props {
  value?: DateFromTo;
  onChange: (value?: DateFromTo) => void;
}

export const GridFilterDateTimeFromTo: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const handleChangeFrom = (date?: Date | null) => {
    onChange({ from: date ? date : undefined, to: value?.to });
  };

  const handleChangeTo = (date?: Date | null) => {
    onChange({ from: value?.from, to: date ? date : undefined });
  };

  return (
    <FormContainer>
      <QcsDateTimePicker
        label={t('common.from')}
        value={value?.from ? value.from : null}
        onChange={handleChangeFrom}
      />
      <QcsDateTimePicker
        label={t('common.to')}
        value={value?.to ? value.to : null}
        onChange={handleChangeTo}
      />
    </FormContainer>
  );
};
