import { RootState } from '../index';
import { FetchState } from '../fetchState';
import { InvitationStateAud } from '@qcs/safety-client';
import { baseSlice, BaseType } from '../baseHelper';

type State = BaseType<InvitationStateAud | undefined>;

const initialState: State = {
  state: FetchState.None,
  data: undefined,
};

export const invitationStateAudSlice = baseSlice({
  name: 'invitationStateAud',
  initialState,
});

export const selectInvitationStateAudState = (state: RootState) =>
  state.invitationStateAud.state;
export const selectInvitationStateAud = (state: RootState) =>
  state.invitationStateAud.data;
export const invitationStateAudActions = invitationStateAudSlice.actions;
