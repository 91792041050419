import {
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { addMinutes, isAfter } from 'date-fns';
import { AppDispatch, store } from '.';
import { Page } from '../models/page';
import { CACHE_MINUTES } from '../utils/constants';
import { selectIdentityRowsCount } from './entities/identity';
import { storageResetAction } from './storeReset';
import { FetchState } from './fetchState';

export interface ListFilter {
  q?: string;
  page?: number;
  sortBy?: string;
  sortDesc?: boolean;
}

export interface ListType<T, U extends ListFilter> {
  state: FetchState;
  data?: Page<T>;
  query: U;
  lastLoaded?: Date;
}

export interface GridActions {
  request: ActionCreatorWithPayload<void, string>;
  success: ActionCreatorWithPayload<any, string>;
  error: ActionCreatorWithPayload<boolean, string>;
  updatePage: ActionCreatorWithPayload<number, string>;
  updateOrder: ActionCreatorWithPayload<
    { sortBy: string; sortDesc: boolean },
    string
  >;
  updateSearch: ActionCreatorWithPayload<string, string>;
  updateFilter: ActionCreatorWithPayload<any, string>;
  reload: ActionCreatorWithPayload<boolean, string>;
}

interface ListSliceProps<T, U extends ListFilter> {
  name: string;
  initialState: ListType<T, U>;
}

export function listSlice<T, U extends ListFilter>({
  name,
  initialState,
}: ListSliceProps<T, U>) {
  return createSlice({
    name,
    initialState,
    reducers: {
      request: (state) => {
        state.state = FetchState.Loading;
      },
      success: (state, action: PayloadAction<Page<T>>) => {
        state.state = FetchState.Loaded;
        state.lastLoaded = new Date();
        state.data = action.payload as any;
      },
      error: (state, action: PayloadAction<boolean>) => {
        state.state = action.payload ? FetchState.Cancel : FetchState.Error;
        if (!action.payload) {
          state.data = initialState.data as any;
        }
      },
      updateOrder: (
        state,
        action: PayloadAction<{ sortBy: string; sortDesc: boolean }>
      ) => {
        state.state = FetchState.None;
        state.query.sortBy = action.payload.sortBy;
        state.query.sortDesc = action.payload.sortDesc;
        state.query.page = 0;
      },
      updatePage: (state, action: PayloadAction<number>) => {
        state.state = FetchState.None;
        state.query.page = action.payload;
      },
      updateSearch: (state, action: PayloadAction<string>) => {
        state.state = FetchState.None;
        state.query.q = action.payload ? action.payload : undefined;
      },
      updateFilter: (state, action: PayloadAction<U>) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { q, page, sortBy, sortDesc, ...restFilter } = action.payload;
        state.state = FetchState.None;
        state.query = { ...state.query, ...restFilter, page: undefined };
      },
      reload: (state, action: PayloadAction<boolean>) => {
        const reload =
          action.payload ||
          !state.lastLoaded ||
          isAfter(new Date(), addMinutes(state.lastLoaded, CACHE_MINUTES));

        if (reload) {
          //Reload, keep user filter.
          state.state = initialState.state;
          state.data = initialState.data as any;
          state.query = { ...state.query, page: initialState.query.page };
          state.lastLoaded = initialState.lastLoaded;
        } else {
          if (
            state.state === FetchState.Loading ||
            state.state === FetchState.Loaded
          ) {
            return;
          }

          state.state = FetchState.None;
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(storageResetAction, () => {
        return initialState;
      });
    },
  });
}

export async function getList({
  dispatch,
  cancelToken,
  state,
  actions,
  getData,
}: {
  dispatch: AppDispatch;
  cancelToken: CancelToken;
  state: FetchState;
  actions: GridActions;
  getData: (size: number) => Promise<{ data: any }>;
}) {
  if (state !== FetchState.None && state !== FetchState.Cancel) {
    return;
  }

  dispatch(actions.request());
  try {
    const size = selectIdentityRowsCount(store.getState());
    const response = await getData(size);
    cancelToken.throwIfRequested();

    dispatch(actions.success(response.data));
  } catch {
    dispatch(actions.error(cancelToken.reason !== undefined));
  }
}

export const getListSort = (data: {
  query: { sortBy?: string; sortDesc?: boolean };
}) => {
  if (data.query.sortBy) {
    return [data.query.sortBy + ',' + (data.query.sortDesc ? 'desc' : 'asc')];
  }

  return undefined;
};
