import { ListItemIcon } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const QcsListItemIcon: FC<Props> = ({ className, children }) => {
  return <ListItemIcon className={className}>{children}</ListItemIcon>;
};
