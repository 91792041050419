import { CertificateDto, CertificateDtoStateEnum } from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  documentActions,
  getDocument,
  selectDocument,
  selectDocumentState,
} from '../../store/entities/document';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { certificateApi } from '../../utils/api';
import { documentListActions } from '../../store/entities/documentList';
import { Checkbox } from '../common/form/Checkbox';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { DetailHeader } from '../common/DetailHeader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { ErrorStateType, Validator } from '../../models/common';
import { fixNameInitValue } from '../../utils/format';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

interface DocumentDetailForm extends CertificateDto {
  saveAndNew?: boolean;
}

export const DocumentDetail: FC = () => {
  const { i18n, t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/documents');
  const { documentId } = useParams();
  const document = useAppSelector(selectDocument);
  const documentState = useAppSelector(selectDocumentState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const formikRef = useRef<FormikProps<DocumentDetailForm> | null>(null);
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    if (documentId === 'new') {
      dispatch(documentActions.default());
    } else {
      dispatch(getDocument(documentId!));
    }
    setLoading(false);
  }, [documentId, dispatch]);

  const handleSubmit = async (data: DocumentDetailForm) => {
    setSaveError('');

    try {
      if (documentId === 'new') {
        await certificateApi.createCertificate(identityCompanyId, data);
        enqueueSuccessSnackbar(t('document.addSuccess'));
      } else {
        await certificateApi.updateCertificate(documentId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(documentListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        documentId === 'new' ? 'document.addError' : 'common.editError'
      );
    }
  };

  if (loading || documentState === FetchState.Loading) {
    return <Loader />;
  }

  if (documentState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {};

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }

  return (
    <>
      <DetailHeader title="document.detailTitle" backTo={backTo} />
      <FormikDebounce<DocumentDetailForm>
        initialValues={{
          ...document,
          ...fixNameInitValue(i18n, document),
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={documentId === 'new'}
              label={t('document.name')}
              required
            />
            <Checkbox
              name="expiryDateRequired"
              label={t('document.expiryDateRequired')}
            />

            <Select
              name="state"
              label={t('document.state')}
              disabled={!document.stateChangeEnabled}
            >
              <QcsMenuItem value={CertificateDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={CertificateDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>
            <SubmitAndNewButtons id={documentId} error={saveError} />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
