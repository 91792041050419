import styled from '@emotion/styled';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import { QcsMenuItem } from '../basic/QcsMenuItem';

export const MenuItemWithCheckbox = styled(QcsMenuItem)(() => ({
  display: 'flex',
  gap: '0.5rem',
  paddingLeft: '0.5rem',
}));

export const CheckboxInMenuItem = styled(QcsCheckbox)(() => ({
  padding: '0.2rem',
}));

export const InputWithButtonContainer = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto max-content',
  gap: '0.5rem',
}));
