import styled from '@emotion/styled';
import { AxiosResponse } from 'axios';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { employeeListActions } from '../../store/entities/employeeList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { employeeApi } from '../../utils/api';
import { downloadBlob } from '../../utils/download';
import { setErrorSnacks } from '../../utils/error';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';

const EmployeeImportExportContainer = styled.div(() => ({
  display: 'flex',
  gap: '0.5rem',
  marginTop: '-0.5rem',
  marginBottom: '1rem',
  marginLeft: 'auto',
}));

export const EmployeeImportExport: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const fileRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const handleImport = () => {
    fileRef.current?.click();
  };

  const handleFileChange = async (files: FileList | null) => {
    if (!files) {
      return;
    }
    try {
      const file = files[0];
      await employeeApi.importEmployees(identityCompanyId, file);
      enqueueSuccessSnackbar(t('employee.importSuccess'));
      dispatch(dispatch(employeeListActions.reload(true)));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'employee.importError');
    } finally {
      if (fileRef.current) {
        fileRef.current.value = '';
      }
    }
  };

  const handleExport = async () => {
    try {
      const response = await employeeApi.exportEmployees(identityCompanyId, {
        responseType: 'blob',
      });
      const blobResponse = response as AxiosResponse<Blob>;
      await downloadBlob(blobResponse);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'employee.exportError');
    }
  };

  return (
    <EmployeeImportExportContainer>
      <input
        ref={fileRef}
        hidden
        type="file"
        onChange={({ target }) => handleFileChange(target.files)}
      />
      <QcsLoadingButton variant="outlined" onClick={handleImport}>
        {t('employee.import')}
      </QcsLoadingButton>
      <QcsLoadingButton variant="outlined" onClick={handleExport}>
        {t('employee.export')}
      </QcsLoadingButton>
    </EmployeeImportExportContainer>
  );
};
