import { InvitationActivityDto } from '@qcs/safety-client';
import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { setErrorSnacks } from '../../../../utils/error';
import { invitationsApi } from '../../../../utils/api';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { invitationGetUpdateDto, useInvitation } from './invitationFunctions';

interface Props {
  activity?: InvitationActivityDto;
  onClose: () => void;
}

export const ActivityDeleteModal: FC<Props> = ({ activity, onClose }) => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const confirmDeleteActivity = async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      const data2 = invitationGetUpdateDto(invitation);
      const data3 = {
        ...data2,
        activities: data2.activities.filter(
          (x) => x.id !== activity?.activity?.id
        ),
      };

      await invitationsApi.updateInvitation(invitation.id!, data3);
      await reloadInvitation();

      enqueueSuccessSnackbar(t('common.saveSuccess'));
      onClose();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'common.saveError');
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsModal open={!!activity} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={t('invitation.tabs.askDeleteActivity')} />
        <QcsBox sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <QcsLoadingButton
            loading={isSaving}
            variant="contained"
            onClick={confirmDeleteActivity}
          >
            {t('common.yes')}
          </QcsLoadingButton>
          <QcsLoadingButton
            loading={isSaving}
            variant="outlined"
            onClick={onClose}
          >
            {t('common.no')}
          </QcsLoadingButton>
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
