import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { EmergencyDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { emergencyApi } from '../../utils/api';

type State = BaseType<EmergencyDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    establishment: {
      id: '',
    },
  },
};

export const emergencySlice = baseSlice({
  name: 'emergency',
  initialState,
});

export const selectEmergencyState = (state: RootState) => state.emergency.state;
export const selectEmergency = (state: RootState) => state.emergency.data;
export const emergencyActions = emergencySlice.actions;

export const getEmergency =
  (emergencyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return emergencyApi.findById(emergencyId);
    };

    getItem({
      dispatch,
      actions: emergencyActions,
      getData: handleGetData,
    });
  };
