import {
  CompanyBusinessSettings,
  CompanyPageDtoTypeEnum,
  SlideDto,
  MissingReferenceData,
  ReferenceDto,
  InvitedPersonDto,
} from '@qcs/safety-client';
import { AnyAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { TourniquetData, TourniquetTraining } from '../../models/tourniquet';
import { tourniquetQuestionnaireActions } from '../../store/entities/tourniquetQuestionnaire';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { tourniquetTrainingSlidesActions } from '../../store/entities/tourniquetTrainingSlides';
import {
  companyPagesApi,
  invitationTrainingResourceApi,
} from '../../utils/api';
import { getCustomError } from '../../utils/error';

export const tourniquetLoadData = async (
  invitationId: string,
  trainingId: string,
  companyCustomization: CompanyBusinessSettings,
  identityCompanyId: string,
  allPersons: ReferenceDto[],
  dispatch: (action: AnyAction) => void,
  tourniquet?: boolean
) => {
  const resTrainingsData =
    await invitationTrainingResourceApi.getTrainingsOverviewForInvitation(
      invitationId,
      {
        traininigs: [trainingId],
      }
    );

  const trainingData = resTrainingsData.data[0];
  if (!trainingData.training?.entity?.id) {
    throw getCustomError('noTraining');
  }

  const training: TourniquetTraining = {
    training: getTourniquetData(trainingData.training!),
    questionnaire: trainingData.questionnaire?.entity?.id
      ? getTourniquetData(trainingData.questionnaire)
      : undefined,
    index: 0,
    mode: 'training',
    answerResponse: undefined,
    allPersons: allPersons.map((x) => x.id),
    signQuestionnaire: allPersons,
  };

  const trainingLang: any = tourniquetGetLang(
    'noLanguageSlides',
    companyCustomization,
    training.training,
    undefined,
    tourniquet
  );

  //Load slides.
  const resTraining: any =
    await invitationTrainingResourceApi.getSpecificTrainingForInvitation(
      invitationId,
      training.training.id,
      trainingLang
    );

  //No slides - error.
  if (!resTraining.data.slides || resTraining.data.slides.length === 0) {
    throw getCustomError('noSlides');
  }

  const allSlides = await tourniquetGetSlides(resTraining.data.slides);
  const afterTrainingContent = await tourniquetGetAfterTrainingContent(
    identityCompanyId,
    trainingLang
  );

  //Load questionnaire.
  if (training.questionnaire) {
    const questionnaireLang: any = tourniquetGetLang(
      'noLanguageQuestionnaire',
      companyCustomization,
      training.questionnaire,
      trainingLang
    );

    const resQuestionnaireQuestions =
      await invitationTrainingResourceApi.getSpecificQuestionnaireForInvitation(
        invitationId,
        training.questionnaire.id,
        questionnaireLang
      );

    //No questions - error.
    if (
      !resQuestionnaireQuestions.data.questions ||
      resQuestionnaireQuestions.data.questions.length === 0
    ) {
      throw getCustomError('noQuestions');
    }

    dispatch(
      tourniquetQuestionnaireActions.success({
        ...resQuestionnaireQuestions.data,
        questions: resQuestionnaireQuestions.data.questions
          .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
          .map((x) => ({ ...x, answerResponse: undefined })),
      })
    );
  } else {
    dispatch(tourniquetQuestionnaireActions.default());
  }

  dispatch(
    tourniquetTrainingSlidesActions.success({
      ...resTraining.data,
      slides: allSlides,
      afterTrainingContent,
    })
  );
  dispatch(tourniquetTrainingActions.success(training));
};

export const getTourniquetData = (
  data: MissingReferenceData
): TourniquetData => {
  const res: TourniquetData = {
    id: data.entity!.id,
    name: data.entity?.name,
    nameEn: data.entity?.nameEn,
    languages: data.languages ?? [],
  };
  return res;
};

export const tourniquetGetLang = (
  emptyError: string,
  companyCustomization: CompanyBusinessSettings,
  data: TourniquetData,
  preferred?: string,
  tourniquet?: boolean
) => {
  //No languages - error.
  if (!data.languages || data.languages.length === 0) {
    throw getCustomError(emptyError);
  }

  //Is tourniquet
  if (
    tourniquet &&
    data.languages.find((x) => x.flag === i18n.resolvedLanguage)
  ) {
    if (i18n.resolvedLanguage === 'en') {
      return 'en';
    } else {
      const langCode = data.languages.find(
        (x) => x.flag === i18n.resolvedLanguage
      );
      return langCode!.code;
    }
  }

  //Preferred language.
  if (preferred && data.languages.find((x) => x.code === preferred)) {
    return preferred;
  }

  if (data.languages.find((x) => x.code === i18n.resolvedLanguage)) {
    //Use actual UI language.
    return i18n.resolvedLanguage;
  } else if (
    data.languages.find((x) => x.code === companyCustomization!.defaultLanguage)
  ) {
    //Use default company language.
    return companyCustomization!.defaultLanguage;
  }

  //First language.
  return data.languages[0].code;
};

export const tourniquetGetSlides = async (
  slides: SlideDto[]
): Promise<SlideDto[]> =>
  slides.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

export const tourniquetGetAfterTrainingContent = async (
  companyId: string,
  lang: string
): Promise<string | undefined> => {
  const pages = await companyPagesApi.queryCompanyPages(
    companyId!,
    CompanyPageDtoTypeEnum.AfterTraining,
    lang
  );

  const page = pages.data[0]?.content;
  return page ? page : undefined;
};

export const tourniquetGetInvitationPerson = (data?: {
  id?: string | null;
  name?: string | null;
  email?: string | null;
}): InvitedPersonDto => ({
  id: data?.id ? data.id : '',
  name: data?.name ? data.name : '',
  email: data?.email ? data.email : '',
  lastName: '',
  activities: [],
  workplaces: [],
});
