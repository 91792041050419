import { FC } from 'react';
import {
  qcsAdminCompanyListActions,
  QcsAdminCompanyListFilter,
} from '../../../store/entities/qcsAdminCompanyList';
import { GridFilterCompanyState } from '../../common/grid/GridFilterCompanyState';
import { GridFilterDateStartEndFromTo } from '../../common/grid/GridFilterDateStartEndFromTo';
import { GridFilterItem } from '../../common/grid/GridFilterItem';

interface Props {
  query: QcsAdminCompanyListFilter;
}

export const QcsAdminCompanyFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={qcsAdminCompanyListActions}
        name="licenseValid"
        title="admin.company.licenseValid"
        disableAutoClose={true}
        Component={GridFilterDateStartEndFromTo}
      />
      <GridFilterItem
        query={query}
        gridActions={qcsAdminCompanyListActions}
        name="state"
        title="admin.company.state"
        Component={GridFilterCompanyState}
      />
    </>
  );
};
