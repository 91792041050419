import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsTypography } from '../common/basic/QcsTypography';
import { DashboardItemContainer, DashboardItemIcon } from './DashboardStyles';

interface Props {
  name: string;
  value?: number;
  icon: IconProp;
  color: string;
}

export const DashboardNumber: FC<Props> = ({ name, value, icon, color }) => {
  const { t } = useTranslation();

  return (
    <DashboardItemContainer sx={{ gridArea: name }}>
      <QcsTypography variant="h6">{t('dashboard.' + name)}</QcsTypography>
      <QcsTypography variant="h4" sx={{ color }}>
        {value ?? 0}
      </QcsTypography>
      <DashboardItemIcon icon={icon} style={{ color }} />
    </DashboardItemContainer>
  );
};
