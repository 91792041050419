import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { CompanyListResponse, CompanyDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { companyApi } from '../../utils/api';
import { DateStartEndFromTo } from '../../models/common';
import { getDateForApi } from '../../utils/date';

export interface QcsAdminCompanyListFilter extends ListFilter {
  licenseValid?: DateStartEndFromTo;
  state?: CompanyDtoStateEnum;
}

export type QcsAdminCompanyListState = ListType<
  CompanyListResponse,
  QcsAdminCompanyListFilter
>;

const initialState: QcsAdminCompanyListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'name',
    sortDesc: false,
  },
};

export const qcsAdminCompanyListSlice = listSlice({
  name: 'qcsAdminCompanyList',
  initialState,
});

export const selectQcsAdminCompanyList = (state: RootState) =>
  state.qcsAdminCompanyList;
export const qcsAdminCompanyListActions = qcsAdminCompanyListSlice.actions;

export const getQcsAdminCompanyList =
  (cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectQcsAdminCompanyList(storeState);

    const handleGetData = (size: number) => {
      return companyApi.queryCompanies(
        data.query.q,
        getDateForApi(data.query.licenseValid?.startFrom),
        getDateForApi(data.query.licenseValid?.startTo),
        getDateForApi(data.query.licenseValid?.endFrom),
        getDateForApi(data.query.licenseValid?.endTo),
        data.query.state,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: qcsAdminCompanyListActions,
      getData: handleGetData,
    });
  };
