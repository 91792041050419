import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { setErrorStateSnacks } from '../../../../utils/error';
import { SaveError } from '../../../common/SaveError';
import { useInvitation } from './invitationFunctions';

interface Props {
  open: boolean;
  onClose: () => void;
  confirmDelete: () => Promise<void>;
}

export const WorkplacePersonDeleteModal: FC<Props> = ({
  open,
  onClose,
  confirmDelete,
}) => {
  const {
    t,
    lockInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
    saveError,
    setSaveError,
  } = useInvitation();

  const handleDelete = async () => {
    setSaveError('');
    setIsSaving(true);
    lockInvitation();
    try {
      await confirmDelete();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'invitation.workplace.personDeleteError'
      );
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={t('invitation.tabs.askDeletePerson')} />
        <QcsBox sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <SaveError error={saveError} />
          <QcsLoadingButton
            loading={isSaving}
            variant="contained"
            onClick={handleDelete}
          >
            {t('common.yes')}
          </QcsLoadingButton>
          <QcsLoadingButton
            loading={isSaving}
            variant="outlined"
            onClick={onClose}
          >
            {t('common.no')}
          </QcsLoadingButton>
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
