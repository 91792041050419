import { InvitationDtoWorkerStatusEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelectMultiple } from './GridFilterSelectMultiple';

interface Props {
  value?: InvitationDtoWorkerStatusEnum[];
  onChange: (value?: InvitationDtoWorkerStatusEnum[]) => void;
}

export const GridFilterInvitationWorkerStatus: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const options = Object.values(InvitationDtoWorkerStatusEnum);

  return (
    <GridFilterSelectMultiple
      value={value ?? []}
      options={options}
      onChange={onChange}
      getOptionId={(option: InvitationDtoWorkerStatusEnum) => option}
      getOptionName={(option: InvitationDtoWorkerStatusEnum) =>
        t(`invitation.workerStatus.${option}`)
      }
    />
  );
};
