import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { initSentry } from './utils/initSentry';
import { App } from './App';

initSentry(import.meta.env.VITE_SENTRY_DNS, import.meta.env.VITE_APP_ENV);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
