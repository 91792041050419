import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getTraining,
  selectTraining,
  selectTrainingState,
} from '../../store/entities/training';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import {
  getTrainingVersion,
  selectTrainingVersion,
  selectTrainingVersionState,
  trainingVersionActions,
} from '../../store/entities/trainingVersion';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { TrainingVersionDetailPresentation } from './TrainingVersionDetailPresentation';
import { TrainingVersionDetailData } from './TrainingVersionDetailData';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';

export const TrainingVersionDetail: FC = () => {
  const { trainingId, trainingVersionId } = useParams();
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const trainingVersion = useAppSelector(selectTrainingVersion);
  const trainingVersionState = useAppSelector(selectTrainingVersionState);
  const training = useAppSelector(selectTraining);
  const trainingState = useAppSelector(selectTrainingState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Use goBack in browser navigator.
    return () => {
      dispatch(tourniquetTrainingActions.default());
      dispatch(tourniquetTrainingsActions.default());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trainingVersionId === 'new') {
      dispatch(trainingVersionActions.default());
    } else {
      dispatch(getTrainingVersion(trainingVersionId!));
    }
    dispatch(getTraining(trainingId!));

    setLoading(false);
  }, [trainingId, dispatch, trainingVersionId]);

  if (
    loading ||
    trainingVersionState === FetchState.Loading ||
    trainingState === FetchState.Loading
  ) {
    return <Loader />;
  }

  if (
    trainingState === FetchState.Error ||
    trainingVersionState === FetchState.Error
  ) {
    return <ErrorAlert />;
  }

  if ((tourniquetTrainings?.trainings?.length ?? 0) > 0) {
    return <TrainingVersionDetailPresentation />;
  }

  return (
    <TrainingVersionDetailData
      training={training}
      trainingVersion={trainingVersion}
      trainingVersionId={trainingVersionId!}
    />
  );
};
