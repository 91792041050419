import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { ReferenceDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { establishmentApi } from '../../utils/api';

type State = BaseType<ReferenceDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const establishmentLatestSlice = baseSlice({
  name: 'establishmentLatest',
  initialState,
  disableReset: true,
});

export const selectEstablishmentLatestState = (state: RootState) =>
  state.establishmentLatest.state;
export const selectEstablishmentLatest = (state: RootState) =>
  state.establishmentLatest.data;
export const establishmentLatestActions = establishmentLatestSlice.actions;

export const getEstablishmentLatest =
  (companyId: string) => (dispatch: AppDispatch) => {
    const establishmentLatestState = selectEstablishmentLatestState(
      store.getState()
    );
    if (establishmentLatestState !== FetchState.None) {
      return;
    }

    const handleGetData = () => {
      return establishmentApi.getLatestEstablishmentsUsedBy(
        companyId,
        //If undefined, use logged user.
        undefined //userId
      );
    };

    getItem({
      dispatch,
      actions: establishmentLatestActions,
      getData: handleGetData,
    });
  };
