import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  ReferenceDto,
  WorkplaceDto,
  WorkplaceDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { workplaceApi } from '../../utils/api';

export interface WorkplaceListFilter extends ListFilter {
  state?: WorkplaceDtoStateEnum;
  establishment?: ReferenceDto;
}

export type WorkplaceListState = ListType<WorkplaceDto, WorkplaceListFilter>;

const initialState: WorkplaceListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: WorkplaceDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const workplaceListSlice = listSlice({
  name: 'workplaceList',
  initialState,
});

export const selectWorkplaceList = (state: RootState) => state.workplaceList;
export const workplaceListActions = workplaceListSlice.actions;

export const getWorkplaceList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectWorkplaceList(storeState);

    const handleGetData = (size: number) => {
      return workplaceApi.queryWorkplace(
        companyId,
        data.query.q,
        data.query.state,
        undefined,
        data.query.establishment ? [data.query.establishment.id] : undefined,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: workplaceListActions,
      getData: handleGetData,
    });
  };
