import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

const GridLoadingContainer = styled.div(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

interface Props {
  className?: string;
}

export const GridLoading: FC<Props> = ({ className }) => {
  return (
    <GridLoadingContainer className={className}>
      <CircularProgress />
    </GridLoadingContainer>
  );
};
