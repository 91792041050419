import styled from '@emotion/styled';
import { SlideDto } from '@qcs/safety-client';
import { FC, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { SlideListItem } from './SlideListItem';
import AddIcon from '@mui/icons-material/Add';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import CloseIcon from '@mui/icons-material/Close';
import { QcsLoadingButton } from '../../common/basic/QcsLoadingButton';
import { setErrorStateSnacks } from '../../../utils/error';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';
import { SaveError } from '../../common/SaveError';
import { ErrorStateType } from '../../../models/common';

const DroppableContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0.5rem',
  overflow: 'visible',
}));

const AddSlideButtonWrapper = styled.div(() => ({
  marginTop: 'auto',
  display: 'flex',
  justifyContent: 'center',
}));

interface Props {
  slides: SlideDto[];
  selectedId?: string;
  isDragDisabled?: boolean;
  onItemClick: (id: string) => void;
  onDragEnd: (dropResult: DropResult) => void;
  addSlide: () => void;
  onCloseEditor: () => void;
}

export const SlideList: FC<Props> = ({
  slides,
  selectedId,
  isDragDisabled,
  onItemClick,
  onDragEnd,
  addSlide,
  onCloseEditor,
}) => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [isClosingEditor, setIsClosingEditor] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  const handleCloseEditor = () => {
    setIsClosingEditor(true);
    try {
      onCloseEditor();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    setIsClosingEditor(false);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="slide-list">
          {(provided) => (
            <DroppableContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <QcsBox
                sx={{
                  display: 'flex',
                  mb: '1rem',
                  justifyContent: 'center',
                }}
              >
                <QcsLoadingButton
                  variant="contained"
                  onClick={handleCloseEditor}
                  disabled={isClosingEditor}
                  loading={isClosingEditor}
                >
                  <CloseIcon />
                  {t('common.back')}
                </QcsLoadingButton>
              </QcsBox>
              <QcsBox
                sx={{
                  display: 'flex',
                  mb: '1rem',
                  justifyContent: 'center',
                }}
              >
                <QcsButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addSlide}
                  disabled={isDragDisabled}
                >
                  {t('training.addSlide')}
                </QcsButton>
              </QcsBox>
              <SaveError error={saveError} />
              {slides.map((slide, index) => (
                <SlideListItem
                  key={index}
                  index={index}
                  id={slide.id!}
                  title={slide.title}
                  onClick={onItemClick}
                  selected={selectedId === slide.id}
                  isDragDisabled={isDragDisabled}
                />
              ))}

              {provided.placeholder}

              <AddSlideButtonWrapper>
                <QcsButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addSlide}
                  disabled={isDragDisabled}
                >
                  {t('training.addSlide')}
                </QcsButton>
              </AddSlideButtonWrapper>
            </DroppableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
