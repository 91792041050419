import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import { GridFilterState } from '../common/grid/GridFilterState';
import {
  employeeListActions,
  EmployeeListFilter,
} from '../../store/entities/employeeList';
import { GridFilterEstablishmentMultiple } from '../common/grid/GridFilterEstablishmentMultiple';

interface Props {
  query: EmployeeListFilter;
}

export const EmployeeFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={employeeListActions}
        name="state"
        title="employee.state"
        Component={GridFilterState}
      />
      <GridFilterItem
        query={query}
        gridActions={employeeListActions}
        name="establishments"
        title="employee.establishments"
        disableAutoClose={true}
        Component={GridFilterEstablishmentMultiple}
      />
    </>
  );
};
