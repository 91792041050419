import { CompanyBusinessSettings } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  appTrainingLangListActions,
  getAppTrainingLangList,
  selectAppTrainingLangList,
} from '../../store/entities/appTrainingLangList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { companySettingsApi, mediaApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { Checkbox } from '../common/form/Checkbox';
import { DatePicker } from '../common/form/DatePicker';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { LanguageSelectMultiple } from '../common/form/LanguageSelectMultiple';
import {
  getBusinessSettings,
  selectBusinessSettings,
  selectBusinessSettingsState,
} from '../../store/entities/bussinesSettings';
import { getCompanyCustomization } from '../../store/entities/companyCustomization';
import { BannerInput } from './BannerInput';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';
import { Link as  QcsLink } from '@mui/material';

import { useTheme } from '@mui/material';

const COMPANY_URL = import.meta.env.VITE_COMPANY_URL as string;

export const QcsCompanySettings: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const appTrainingLangList = useAppSelector(selectAppTrainingLangList);
  const businessSettings = useAppSelector(selectBusinessSettings);
  const businessSettingsState = useAppSelector(selectBusinessSettingsState);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [oldFilesToRemove, setOldFilesToRemove] = useState<string[]>([]);

  useEffect(() => {
    dispatch(appTrainingLangListActions.reload(false));
    dispatch(getBusinessSettings(identityCompanyId));
    setLoading(false);
  }, [dispatch, identityCompanyId]);

  useEffect(() => {
    if (appTrainingLangList.state === FetchState.None) {
      dispatch(getAppTrainingLangList());
    }
  }, [appTrainingLangList.state, dispatch]);

  const handleSubmit = async (data: CompanyBusinessSettings) => {
    setSaveError('');
    try {
      await companySettingsApi.updateBusinessSettings(identityCompanyId, data);

      for (const oldFile of oldFilesToRemove) {
        try {
          await mediaApi.deleteFile(oldFile);
        } catch {
          //Ignore error.
        }
      }

      enqueueSuccessSnackbar(t('common.editSuccess'));
      dispatch(getCompanyCustomization(identityCompanyId));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
  };

  const handleRemoveOldFile = (document: string) => {
    setOldFilesToRemove([...oldFilesToRemove, document]);
  };

  const getSubDomainUrl = useMemo(
    () => (domain?: string) =>
      COMPANY_URL.replaceAll('{subdomain}', domain ?? ''),
    []
  );

  if (loading || businessSettingsState === FetchState.Loading) {
    return <Loader />;
  }

  if (businessSettingsState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <SectionHeader title="qcsCompanySettings.title" />
      <FormikDebounce<CompanyBusinessSettings>
        initialValues={businessSettings}
        validationSchema={Yup.object().shape({
          licenseValidFrom: validations.stringDateNotPastIfChangedRequired(
            t,
            businessSettings.licenseValidFrom
          ),
          licenseValidTo: Yup.string()
            .nullable()
            .when('licenseValidFrom', ([licenseValidFrom], schema) =>
              schema.test({
                test: (licenseValidTo: string | null | undefined) => {
                  return !licenseValidTo || licenseValidFrom <= licenseValidTo;
                },
                message: t('errors.dateToBeforeFrom')!,
              })
            ),
          maxEstablishments: validations.intPositive(t),
          maxUsers: validations.intPositive(t),
          maxTraining: validations.intPositive(t),
          applicationLanguages: validations.arrayNotEmpty(t),
          defaultLanguage: validations.stringRequired(t),
          testLanguages: validations.arrayNotEmpty(t),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form>
              <FormContainer>
                <DatePicker
                  name="licenseValidFrom"
                  label={t('admin.company.licenseValidFrom')}
                  disablePast
                  required
                />
                <DatePicker
                  name="licenseValidTo"
                  label={t('admin.company.licenseValidTo')}
                  disablePast
                />
                <Input
                  name="maxEstablishments"
                  label={t('admin.company.maxEstablishments')}
                  type="number"
                  min={1}
                  required
                />
                <Input
                  name="maxUsers"
                  label={t('admin.company.maxUsers')}
                  type="number"
                  min={1}
                  required
                />
                <Input
                  name="maxTraining"
                  label={t('admin.company.maxTraining')}
                  type="number"
                  min={1}
                  required
                />
                <QcsLink
                  href={getSubDomainUrl(values.subDomain)}
                  variant="body1"
                  target="_blank"
                  sx={{
                    color: theme.palette.text.primary,
                    textDecoration: 'none',
                  }}
                >
                  {getSubDomainUrl(values.subDomain)}
                </QcsLink>
                <Checkbox
                  name="passRisksEnabled"
                  label={t('admin.company.passRisksEnabled')}
                />
                <Checkbox
                  name="smsEnabled"
                  label={t('admin.company.smsEnabled')}
                />
                <BannerInput onRemoveOldFile={handleRemoveOldFile} />
                <Input
                  name="secondaryColor"
                  type="color"
                  label={t('qcsCompanySettings.primaryColor')}
                />
                <Input
                  name="primaryColor"
                  type="color"
                  label={t('qcsCompanySettings.secondaryColor')}
                />
                <LanguageSelectMultiple
                  name="applicationLanguages"
                  label={t('admin.company.applicationLanguages')}
                  languages={SUPPORTED_LANGUAGES}
                  required
                />
                <LanguageSelect
                  name="defaultLanguage"
                  label={t('admin.company.defaultApplicationLanguage')}
                  languages={SUPPORTED_LANGUAGES}
                  allowedLanguages={values.applicationLanguages}
                  required
                />
                <LanguageSelectMultiple
                  name="testLanguages"
                  label={t('admin.company.testLanguages')}
                  languages={appTrainingLangList.data?.content}
                  required
                />
                <SaveError error={saveError} />
                <SubmitButton />
              </FormContainer>
            </Form>
          );
        }}
      </FormikDebounce>
    </>
  );
};
