import { EstablishmentDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  establishmentListActions,
  getEstablishmentList,
  selectEstablishmentList,
} from '../../store/entities/establishmentList';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { EstablishmentFilter } from './EstablishmentFilter';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateEstablishment,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsTooltipContent } from '../common/basic/QcsTooltipContent';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { getFirstLastNameObj, getLangNameObj } from '../../utils/format';

export const Establishment: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const establishmentList = useAppSelector(selectEstablishmentList);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateEstablishment = useAppSelector(
    selectCompanyChecksCanCreateEstablishment
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCompanyChecks(identityCompanyId!));
    setLoading(false);
  }, [identityCompanyId, dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getEstablishmentList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: EstablishmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getFirstLastNameObj(item.contact)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleGetRowTooltip = (item: EstablishmentDto) => {
    return (
      <QcsTooltipContent>
        <QcsTypography variant="h6">{t('address.title')}</QcsTypography>
        <QcsBox>
          <QcsTypography variant="body2">{item.address.street}</QcsTypography>
          <QcsTypography variant="body2">{item.address.city}</QcsTypography>
          <QcsTypography variant="body2">{`${item.address.postalCode}, ${item.address.country}`}</QcsTypography>
        </QcsBox>
        {item.note && (
          <>
            <QcsTypography variant="h6">
              {t('establishment.note')}
            </QcsTypography>
            <QcsTypography variant="body2">{item.note}</QcsTypography>
          </>
        )}
      </QcsTooltipContent>
    );
  };

  const handleRowClick = (item: EstablishmentDto) => {
    navigate('/establishment/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/establishment/new');
  };

  if (loading || companyChecksState === FetchState.Loading) {
    return <Loader />;
  }

  if (companyChecksState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <SectionHeader
        title="establishment.title"
        addText="establishment.add"
        addDisabled={!companyChecksCanCreateEstablishment}
        handleAddClick={handleAddClick}
      />
      <Grid<EstablishmentDto>
        headers={[
          { captionStr: 'establishment.name', orderName: 'name' },
          {
            captionStr: 'establishment.contactName',
            orderName: 'contact.name',
            hideOnMobile: true,
          },
          {
            captionStr: 'establishment.contactEmail',
            orderName: 'contact.email',
            hideOnMobile: true,
          },
          {
            captionStr: 'establishment.contactPhone',
            orderName: 'contact.phone',
            hideOnMobile: true,
          },
          {
            captionStr: 'establishment.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={establishmentList}
        gridActions={establishmentListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        getRowTooltip={handleGetRowTooltip}
        filter={<EstablishmentFilter query={establishmentList.query} />}
      />
    </>
  );
};
