import { FC } from 'react';
import { TourniquetTraining } from '../tourniquet/TourniquetTraining';
import { useAppDispatch } from '../../store';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';

export const TrainingVersionDetailPresentation: FC = () => {
  const dispatch = useAppDispatch();

  const handleEndTraining = () => {
    dispatch(tourniquetTrainingActions.default());
    dispatch(tourniquetTrainingsActions.default());
  };

  return <TourniquetTraining onEndTraining={handleEndTraining} />;
};
