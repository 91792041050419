import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { LanguageDto } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../../common/basic/QcsTypography';
import { FetchState } from '../../../store/fetchState';
import { QcsTableBody } from '../../common/basic/QcsTableBody';
import { QcsTableCell } from '../../common/basic/QcsTableCell';
import { QcsTableHead } from '../../common/basic/QcsTableHead';
import { QcsTableRow } from '../../common/basic/QcsTableRow';
import { QcsPaper } from '../../common/basic/QcsPaper';
import { QcsTableContainer } from '../../common/basic/QcsTableContainer';
import { GridEmpty } from '../../common/grid/GridEmpty';
import { GridError } from '../../common/grid/GridError';
import { GridLoading } from '../../common/grid/GridLoading';
import {
  appTrainingLangListActions,
  getAppTrainingLangList,
  selectAppTrainingLangList,
} from '../../../store/entities/appTrainingLangList';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FaIcon } from '../../common/FaIcon';
import { QcsTable } from '../../common/basic/QcsTable';
import { TrainingLanguageDetailModal } from './TrainingLanguageDetailModal';
import { codeListApi } from '../../../utils/api';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';
import { setErrorSnacks } from '../../../utils/error';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  marginBottom: '0.5rem',
}));

export const TrainingLanguages: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const appTrainingLangList = useAppSelector(selectAppTrainingLangList);
  const [adding, setAdding] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageDto | null>(
    null
  );
  const [confirmDeleteFunction, setConfirmDeleteFunction] = useState<
    (() => void) | null
  >(null);

  useEffect(() => {
    if (appTrainingLangList.state === FetchState.None) {
      dispatch(appTrainingLangListActions.reload(false));
    }
  }, [appTrainingLangList.state, dispatch]);

  useEffect(() => {
    if (appTrainingLangList.state === FetchState.None) {
      dispatch(getAppTrainingLangList());
    }
  }, [appTrainingLangList.state, dispatch]);

  const reloadTrainingLangList = () => {
    dispatch(appTrainingLangListActions.reload(true));
  };

  const handleAddClick = () => {
    setAdding(true);
  };

  const handleClose = () => {
    setAdding(false);
    setSelectedLanguage(null);
  };

  const handleEdit =
    (language: LanguageDto) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setSelectedLanguage(language);
    };

  const handleDelete =
    (language: LanguageDto) =>
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      setConfirmDeleteFunction(() => async () => {
        try {
          await codeListApi.deleteLanguage(language.code);
          enqueueSuccessSnackbar(
            t('admin.settings.trainingLang.deleteSuccess')
          );
          reloadTrainingLangList();
        } catch (err) {
          setErrorSnacks(
            err,
            enqueueErrorSnackbar,
            'admin.settings.trainingLang.deleteError'
          );
        }
      });
    };

  return (
    <>
      <TrainingLanguageDetailModal
        open={adding || !!selectedLanguage}
        onClose={handleClose}
        selectedLanguage={selectedLanguage}
        reloadTrainingLangList={reloadTrainingLangList}
      />
      <ConfirmDialog
        onClose={() => setConfirmDeleteFunction(null)}
        onConfirm={confirmDeleteFunction}
        title={t('admin.settings.trainingLang.confirmDeleteDialog.title')}
        message={t('admin.settings.trainingLang.confirmDeleteDialog.message')}
      />

      <HeaderContainer>
        <QcsTypography variant="h5">
          {t('admin.settings.trainingLang.title')}
        </QcsTypography>
        <QcsButton variant="contained" onClick={handleAddClick}>
          {t('admin.settings.trainingLang.addLanguage')}
        </QcsButton>
      </HeaderContainer>

      <QcsPaper>
        <QcsTableContainer>
          <QcsTable>
            <QcsTableHead>
              <QcsTableRow>
                <QcsTableCell>
                  {t('admin.settings.trainingLang.language')}
                </QcsTableCell>
                <QcsTableCell sx={{ width: '5rem' }}>
                  {t('admin.settings.trainingLang.edit')}
                </QcsTableCell>
                <QcsTableCell sx={{ width: '5rem' }}>
                  {t('admin.settings.trainingLang.delete')}
                </QcsTableCell>
              </QcsTableRow>
            </QcsTableHead>
            <QcsTableBody>
              {appTrainingLangList.state === FetchState.Loaded &&
                appTrainingLangList.data?.content.map((item, index) => (
                  <QcsTableRow key={index} hover={true}>
                    <QcsTableCell>
                      <img src={'/flags/' + item.flag + '.svg'} height="20" />{' '}
                      {item.name}
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton onClick={handleEdit(item)}>
                        <FaIcon icon={faEdit} />
                      </QcsButton>
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton onClick={handleDelete(item)}>
                        <DeleteForeverIcon color="error" />
                      </QcsButton>
                    </QcsTableCell>
                  </QcsTableRow>
                ))}
            </QcsTableBody>
          </QcsTable>
          {appTrainingLangList.state === FetchState.Loading ? (
            <GridLoading />
          ) : appTrainingLangList.state === FetchState.Error ? (
            <GridError />
          ) : appTrainingLangList.data?.content.length === 0 ? (
            <GridEmpty />
          ) : null}
        </QcsTableContainer>
      </QcsPaper>
    </>
  );
};
