import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  QuestionnaireDto,
  QuestionnaireDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { questionnairesApi } from '../../utils/api';

type State = BaseType<QuestionnaireDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    state: QuestionnaireDtoStateEnum.Active,
    note: '',
    trainings: [],
  },
};

export const questionnaireSlice = baseSlice({
  name: 'questionnaire',
  initialState,
});

export const selectQuestionnaireState = (state: RootState) =>
  state.questionnaire.state;
export const selectQuestionnaire = (state: RootState) =>
  state.questionnaire.data;
export const questionnaireActions = questionnaireSlice.actions;

export const getQuestionnaire =
  (questionnaireId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return questionnairesApi.getQuestionnaireById(questionnaireId);
    };

    getItem({
      dispatch,
      actions: questionnaireActions,
      getData: handleGetData,
    });
  };
