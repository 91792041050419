import { WorkplaceDto } from '@qcs/safety-client';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { Typography } from '@mui/material';
import { QcsTable } from '../common/basic/QcsTable';
import { QcsTableHead } from '../common/basic/QcsTableHead';
import { QcsTableRow } from '../common/basic/QcsTableRow';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTableBody } from '../common/basic/QcsTableBody';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';

interface Props {
  listUsedWorkplace: WorkplaceDto[];
  onClose: (update: boolean) => Promise<void>;
}

export const SafetyEquipmentUpdateModal: FC<Props> = ({
  listUsedWorkplace,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaveError('');
  }, [listUsedWorkplace]);

  const handleClose = () => {
    onClose(false);
  };

  const confirmUpdate = async () => {
    setSaving(true);
    try {
      await onClose(true);
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'risk.versionDeleteError'
      );
    }
    setSaving(false);
  };

  return (
    <QcsModal open={listUsedWorkplace.length > 0} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title={t('safetyEquipment.askUpdateWhenUsed')} />
        <QcsBox sx={{ pb: 2 }}>
          <QcsAlert severity="warning">
            <Typography variant="body1">
              {t('safetyEquipment.warningEquipIsUsedInWorkplaces')}
            </Typography>
          </QcsAlert>
        </QcsBox>
        <QcsTable>
          <QcsTableHead>
            <QcsTableRow>
              <QcsTableCell>{t('workplace.title')}</QcsTableCell>
              <QcsTableCell>{t('workplace.establishment')}</QcsTableCell>
            </QcsTableRow>
          </QcsTableHead>
          <QcsTableBody>
            {listUsedWorkplace.map((workplace) => (
              <QcsTableRow>
                <QcsTableCell>{getLangNameObj(i18n, workplace)}</QcsTableCell>
                <QcsTableCell>
                  {getLangNameObj(i18n, workplace.establishment)}
                </QcsTableCell>
              </QcsTableRow>
            ))}
          </QcsTableBody>
        </QcsTable>
        <QcsBox
          sx={{ display: 'flex', justifyContent: 'center', gap: 2, pt: 2 }}
        >
          <QcsLoadingButton
            variant="contained"
            onClick={confirmUpdate}
            loading={saving}
          >
            {t('common.yes')}
          </QcsLoadingButton>
          <QcsLoadingButton
            variant="outlined"
            onClick={handleClose}
            loading={saving}
          >
            {t('common.no')}
          </QcsLoadingButton>
        </QcsBox>
        <QcsBox sx={{ display: 'flex', m: 1, justifyContent: 'center' }}>
          <SaveError error={saveError} />
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
