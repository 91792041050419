import { SubmitApplicationInvitation } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../store';
import { storageResetAction } from '../../store/storeReset';
import { applicationInvitationApi } from '../../utils/api';
import { logout } from '../../utils/storage';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { LoginContainer, LoginWrapper } from './LoginStyles';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

export const AppInvitation: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorStateType>();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get('token')!;
  const email = params.get('email')!;

  useEffect(() => {
    logout();
    dispatch(storageResetAction());

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (data: SubmitApplicationInvitation) => {
    setError('');
    try {
      await applicationInvitationApi.submitApplicationInvitation(data);
      enqueueSuccessSnackbar(t('auth.setPasswordSuccess'));
      navigate('/');
    } catch (err) {
      setErrorStateSnacks(
        err,
        setError,
        enqueueErrorSnackbar,
        'auth.setPasswordError'
      );
    }
  };

  return (
    <LoginWrapper>
      <SectionHeader title="auth.invitation" />
      <FormikDebounce<SubmitApplicationInvitation>
        initialValues={{
          id: token,
          email,
          password: '',
          repeatedPassword: '',
        }}
        validationSchema={Yup.object({
          repeatedPassword: validations
            .stringRequired(t)
            .oneOf([Yup.ref('password')], t('auth.passwordMismatch')!),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <LoginContainer>
            <Input name="password" type="password" label={t('auth.password')} />
            <Input
              name="repeatedPassword"
              type="password"
              label={t('auth.passwordAgain')}
            />

            <SubmitButton text="auth.setPassword" />
            <SaveError error={error} />
          </LoginContainer>
        </Form>
      </FormikDebounce>
    </LoginWrapper>
  );
};
