import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { TrainingVersionDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { trainingsApi } from '../../utils/api';

type State = BaseType<TrainingVersionDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    languageVariants: [],
    training: '',
    validFrom: '',
    validTo: '',
    duration: 0,
    editable: true,
    first: false,
  },
};

export const trainingVersionSlice = baseSlice({
  name: 'trainingVersion',
  initialState,
});

export const selectTrainingVersionState = (state: RootState) =>
  state.trainingVersion.state;
export const selectTrainingVersion = (state: RootState) =>
  state.trainingVersion.data;
export const selectTrainingVersionLanguages = (state: RootState) =>
  state.trainingVersion.data.languageVariants;

export const trainingVersionActions = trainingVersionSlice.actions;

export const getTrainingVersion =
  (trainingVersionId: string) => (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      return trainingsApi.getTrainingVersion(trainingVersionId);
    };

    getItem({
      dispatch,
      actions: trainingVersionActions,
      getData: handleGetData,
    });
  };
