import {
  InvitationDto,
  InvitedPersonDto,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import { invitationsApi } from '../../utils/api';
import { setErrorSnacks } from '../../utils/error';
import { hasRole, isTourniquet } from '../../utils/roles';
import { QcsTypography } from '../common/basic/QcsTypography';
import { TourniquetDialog } from './TourniquetDialog';
import { TourniquetInvitationContainer } from './TourniquetInvitationContainer';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { selectTourniquetTrainings } from '../../store/entities/tourniquetTrainings';
import { tourniquetLoadData } from './tourniquetFunctions';

interface Props {
  invitation: InvitationDto;
  invitationPerson: InvitedPersonDto;
}

export const TourniquetInvitationTrainings: FC<Props> = ({
  invitation,
  invitationPerson,
}) => {
  const { t } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const dispatch = useAppDispatch();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sentModalIsVisible, setSentModalIsVisible] = useState(false);

  const m3 = t('tourniquet.issueTrainings3')?.split('{0}');

  const handleRunTraining = async () => {
    setLoading(true);
    try {
      await tourniquetLoadData(
        tourniquetIdentity.data!.invitation!.id!,
        tourniquetTrainings.trainings![0].training!.id,
        companyCustomization!,
        identity.companyId,
        tourniquetIdentity.allPersons!,
        dispatch,
        isTourniquet(identity)
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setLoading(false);
  };

  const handleSend = async () => {
    setSending(true);
    try {
      await invitationsApi.resentNotification(
        invitation.id!,
        invitationPerson.id!
      );
      setSentModalIsVisible(true);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setSending(false);
  };

  const handleModalClose = () => {
    setSentModalIsVisible(false);
  };

  if (hasRole(identity.roles, [UserDtoRolesEnum.TourniquetTraining])) {
    return (
      <>
        <TourniquetDialog
          mode={sentModalIsVisible ? 'EMAIL' : undefined}
          onClose={handleModalClose}
        />

        <TourniquetInvitationContainer
          icon="error"
          buttonNameT="tourniquet.runTraining"
          buttonLoading={loading}
          onButtonClick={handleRunTraining}
        >
          <QcsTypography>{t('tourniquet.issueTrainings1')}</QcsTypography>
        </TourniquetInvitationContainer>

        <TourniquetInvitationContainer
          icon="tip"
          buttonNameT="tourniquet.sendLink"
          buttonLoading={sending}
          onButtonClick={handleSend}
        >
          <QcsTypography>
            {t('tourniquet.issueTrainings2')} {m3?.[0]}
            <strong>{invitationPerson.email}</strong>
            {m3?.[1]}
          </QcsTypography>
          <QcsTypography>{t('tourniquet.issueTrainings4')}</QcsTypography>
        </TourniquetInvitationContainer>
      </>
    );
  }

  return (
    <>
      <TourniquetDialog
        mode={sentModalIsVisible ? 'EMAIL' : undefined}
        onClose={handleModalClose}
      />
      <TourniquetInvitationContainer icon="error">
        <QcsTypography>{t('tourniquet.issueTrainings1')}</QcsTypography>
      </TourniquetInvitationContainer>
      <TourniquetInvitationContainer
        icon="tip"
        buttonNameT="tourniquet.sendLink"
        buttonLoading={sending}
        onButtonClick={handleSend}
      >
        <QcsTypography>
          {t('tourniquet.issueTrainings2')} {m3?.[0]}
          <strong>{invitationPerson.email}</strong>
          {m3?.[1]}
        </QcsTypography>
        <QcsTypography>{t('tourniquet.issueTrainings4')}</QcsTypography>
      </TourniquetInvitationContainer>
    </>
  );
};
