import { ToggleButton } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  value: NonNullable<unknown>;
  selected?: boolean;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  children: React.ReactNode;
}

export const QcsToggleButton: FC<Props> = ({
  className,
  value,
  selected,
  onChange,
  children,
}) => {
  return (
    <ToggleButton
      className={className}
      value={value}
      selected={selected}
      onChange={onChange}
    >
      {children}
    </ToggleButton>
  );
};
