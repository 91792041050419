import { DialogActions } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const QcsDialogActions: FC<Props> = ({ className, children }) => {
  return <DialogActions className={className}>{children}</DialogActions>;
};
