import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { RiskDto, RiskDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { riskApi } from '../../utils/api';

export interface RiskListFilter extends ListFilter {
  state?: RiskDtoStateEnum;
}

export type RiskListState = ListType<RiskDto, RiskListFilter>;

const initialState: RiskListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: RiskDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const riskListSlice = listSlice({
  name: 'riskList',
  initialState,
});

export const selectRiskList = (state: RootState) => state.riskList;
export const riskListActions = riskListSlice.actions;

export const getRiskList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectRiskList(storeState);

    const handleGetData = (size: number) => {
      return riskApi.queryRisks(
        companyId,
        data.query.q,
        data.query.state,
        undefined,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: riskListActions,
      getData: handleGetData,
    });
  };
