import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import _ from 'lodash';
import { CheckboxInMenuItem, MenuItemWithCheckbox } from './formStyles';
import styled from '@emotion/styled';
import { Chip, useTheme } from '@mui/material';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { LanguageDto } from '@qcs/safety-client';

const StyledQcsBox = styled(QcsBox)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

const StyledChip = styled(Chip)(() => ({
  height: '2.25rem',
  '&	.MuiChip-label': {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
  },
}));

export const LanguageSelectItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
}));

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  languages?: LanguageDto[];
  allowedLanguages?: string[];
  notAllowedLanguages?: string[];
}

export const LanguageSelectMultiple: FC<Props> = ({
  className,
  name,
  label,
  disabled,
  required,
  languages,
  allowedLanguages,
  notAllowedLanguages,
}) => {
  const { values, errors, touched, submitCount, handleChange, handleBlur } =
    useFormikContext<any>();

  const value: string[] = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const flagsMemo = useMemo(() => {
    if (!languages) {
      return null;
    }

    let lang = [...languages].sort((a, b) => a.name.localeCompare(b.name));

    if (allowedLanguages) {
      lang = lang.filter((x) => allowedLanguages.includes(x.code));
    }

    if (notAllowedLanguages) {
      lang = lang.filter((x) => !notAllowedLanguages.includes(x.code));
    }

    return lang.map((l) => (
      <MenuItemWithCheckbox key={l.code} value={l.code}>
        <CheckboxInMenuItem checked={value.some((code) => code === l.code)} />
        <LanguageSelectItem>
          <img src={'/flags/' + l.flag + '.svg'} height="20" loading="lazy" />
          <div>{l.name}</div>
        </LanguageSelectItem>
      </MenuItemWithCheckbox>
    ));
  }, [languages, allowedLanguages, notAllowedLanguages, value]);

  const getFlag = (code: string) => languages?.find((x) => x.code === code);

  const theme = useTheme();

  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel required={required} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        multiple
        required={required}
        className={className}
        sx={{ backgroundColor: theme.palette.common.white }}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        disabled={disabled}
        renderValue={(selected) => (
          <StyledQcsBox>
            {selected.map((value: string) => (
              <StyledChip
                key={value}
                label={
                  <>
                    <img
                      src={'/flags/' + getFlag(value)?.flag + '.svg'}
                      height="20"
                    />
                    {getFlag(value)?.name}
                  </>
                }
              ></StyledChip>
            ))}
          </StyledQcsBox>
        )}
      >
        {flagsMemo}
      </QcsSelect>
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
