import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { identityActions, selectIdentity } from '../../store/entities/identity';
import { useAppDispatch, useAppSelector } from '../../store';
import { companyPagesApi, qcUserApi } from '../../utils/api';
import { CompanyPageDtoTypeEnum, UserDtoRolesEnum } from '@qcs/safety-client';
import { Loader } from '../common/Loader';
import { setErrorSnacks, setErrorState } from '../../utils/error';
import { SaveError } from '../common/SaveError';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { firstUpper } from '../../utils/format';
import { hasRole } from '../../utils/roles';
import { HtmlViewer } from '../common/html/HtmlViewer';
import { ErrorStateType } from '../../models/common';

export const WelcomeMessageModal: FC = () => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const [message, setMessage] = useState('');
  const [dontShow, setDontShow] = useState(false);
  const dispatch = useAppDispatch();
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const allowed = !!identity.welcomeLangPages[i18n.resolvedLanguage!];
  const isExternalManager = hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
  ]);
  const isExternalWorker = hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalWorker,
  ]);
  const isExternalOneTime = hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalOneTime,
  ]);

  const getPageType = () => {
    if (isExternalManager) {
      return CompanyPageDtoTypeEnum.SupplierManager;
    } else if (isExternalWorker) {
      return CompanyPageDtoTypeEnum.SupplierEmployee;
    }

    return CompanyPageDtoTypeEnum.Visit;
  };

  useEffect(() => {
    (async () => {
      if (allowed) {
        setIsLoading(true);

        try {
          const pages = await companyPagesApi.queryCompanyPages(
            identity.companyId,
            getPageType(),
            i18n.resolvedLanguage
          );

          if (pages.data[0]?.content) {
            setMessage(pages.data[0].content);
          } else {
            //No message, set dont show.
            await saveDontShow();
            saveStore();
          }
        } catch (err) {
          setErrorState(err, setError);
        }
      }

      setDontShow(false);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowed, i18n.resolvedLanguage, identity.companyId]);

  const handleClose = async () => {
    if (loading) {
      return;
    }

    if (!error && dontShow) {
      setIsLoading(true);
      try {
        await saveDontShow();
      } catch (err) {
        setErrorSnacks(err, enqueueErrorSnackbar);
      }
      setIsLoading(false);
    }

    saveStore();
  };

  const saveDontShow = async () => {
    const sett = 'settings' + firstUpper(i18n.resolvedLanguage!);

    const preferences = await qcUserApi.getUserPreferences(identity.id);
    const prefSett = (preferences.data as any)[sett];

    const data = {
      ...preferences.data,
      [sett]: {
        ...prefSett,
        supplierEmployeeSeen: isExternalManager
          ? true
          : prefSett.supplierEmployeeSeen,
        supplierEmployeeWorkerSeen: isExternalWorker
          ? true
          : prefSett.supplierEmployeeWorkerSeen,
        visitationSeen: isExternalOneTime ? true : prefSett.visitationSeen,
      },
    };
    await qcUserApi.updateUserPreferences(identity.id, data);
  };

  const saveStore = () => {
    dispatch(
      identityActions.success({
        ...identity,
        welcomeLangPages: {
          ...identity.welcomeLangPages,
          [i18n.resolvedLanguage!]: false,
        },
      })
    );
  };

  const handleChange = () => {
    setDontShow(!dontShow);
  };

  return (
    <QcsModal open={allowed} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title="welcomeMessage.title" />
        {loading ? (
          <Loader />
        ) : error ? (
          <SaveError error={error} />
        ) : (
          <>
            <HtmlViewer html={message} />
            <div>
              <QcsFormControlLabel
                control={
                  <QcsCheckbox checked={dontShow} onChange={handleChange} />
                }
                label={t('welcomeMessage.check')}
              />
              <div>
                <QcsButton onClick={handleClose}>{t('common.close')}</QcsButton>
              </div>
            </div>
          </>
        )}
      </QcsModalBox>
    </QcsModal>
  );
};
