import {
  CopyVersionHolderToVersionablesRequest,
  QuestionnaireDto,
  QuestionnaireVersionDto,
} from '@qcs/safety-client';
import { Form, useFormikContext } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { DatePicker } from '../common/form/DatePicker';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { QuestionnaireAutocomplete } from '../common/form/QuestionnaireAutocomplete';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { questionnairesApi } from '../../utils/api';
import { getDateForApi, isDateDisabled } from '../../utils/date';
import { ErrorStateType } from '../../models/common';

interface CopyVersionToQuestionnaireForm {
  variant: 'toQuestionnaires' | 'toNewQuestionnaire';
  questionnaires: QuestionnaireDto[];
  validFrom: string;
  versionNote: string;
  name: string;
  note: string;
}

interface CopyVersionToQuestionnaireModalFormProps {
  questionnaireId?: string;
}

const CopyVersionToQuestionnaireModalForm: FC<
  CopyVersionToQuestionnaireModalFormProps
> = ({ questionnaireId }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<CopyVersionToQuestionnaireForm>();

  useEffect(() => {
    if (values.variant === 'toNewQuestionnaire') {
      setFieldValue('validFrom', getDateForApi(new Date()));
    }
  }, [setFieldValue, values.variant]);

  return (
    <>
      <Select name="variant">
        <QcsMenuItem value={'toQuestionnaires'}>
          {t('questionnaire.copy.toQuestionnaires')}
        </QcsMenuItem>
        <QcsMenuItem value={'toNewQuestionnaire'}>
          {t('questionnaire.copy.toNewQuestionnaire')}
        </QcsMenuItem>
      </Select>

      {values.variant === 'toQuestionnaires' && (
        <QuestionnaireAutocomplete
          skipId={questionnaireId}
          multiple
          name="questionnaires"
          label={t('questionnaire.copy.questionnaires')}
          required
        />
      )}

      {values.variant === 'toNewQuestionnaire' && (
        <>
          <Input
            name="name"
            label={t('questionnaire.name')}
            maxLength={50}
            required
          />

          <Input
            name="note"
            label={t('questionnaire.copy.questionnaireNote')}
            maxLength={250}
            multiline={true}
            rows={2}
          />
        </>
      )}

      <DatePicker
        name="validFrom"
        label={t('questionnaire.validFrom')}
        disabled={values.variant === 'toNewQuestionnaire'}
        disablePast
        disableHighlightToday={values.variant === 'toQuestionnaires'}
        shouldDisableDate={
          values.variant === 'toQuestionnaires' ? isDateDisabled : undefined
        }
        required
      />
      <Input
        name="versionNote"
        label={t('questionnaire.versionNote')}
        maxLength={250}
        multiline={true}
        rows={2}
      />
    </>
  );
};

interface Props {
  questionnaireId?: string;
  versionToCopy?: QuestionnaireVersionDto | null;
  onClose: () => void;
  reloadQuestionnaireVersionList: () => void;
}

export const CopyVersionToQuestionnaireModal: FC<Props> = ({
  questionnaireId,
  versionToCopy,
  onClose,
  reloadQuestionnaireVersionList,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [versionToCopy]);

  const handleSubmit = async (data: CopyVersionToQuestionnaireForm) => {
    setSaveError('');

    try {
      if (data.variant === 'toQuestionnaires') {
        const dataToCopy: CopyVersionHolderToVersionablesRequest = {
          ...data,
          versionables: data.questionnaires.map(
            (questionnaire) => questionnaire.id!
          ),
        };
        await questionnairesApi.copyVersionToQuestionnaires(
          versionToCopy!.id!,
          dataToCopy
        );
      } else {
        await questionnairesApi.copyVersionToNewQuestionnaire(
          versionToCopy!.id!,
          data
        );
      }
      enqueueSuccessSnackbar(t('questionnaire.copy.success'));
      reloadQuestionnaireVersionList();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'questionnaire.copy.error'
      );
    }
  };

  return (
    <QcsModal open={!!versionToCopy} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={'questionnaire.copy.versionToQuestionnaire'} />
        <FormikDebounce<CopyVersionToQuestionnaireForm>
          initialValues={{
            variant: 'toQuestionnaires',
            questionnaires: [],
            validFrom: '',
            versionNote: '',
            name: '',
            note: '',
          }}
          validationSchema={Yup.object().shape({
            validFrom: Yup.string().when('variant', {
              is: (variant: string) => variant === 'toQuestionnaires',
              then: () => validations.stringDateNotPastOrTodayRequired(t),
              otherwise: () => validations.stringDateNotPastRequired(t),
            }),
            questionnaires: Yup.array().when('variant', {
              is: (variant: string) => variant === 'toQuestionnaires',
              then: () => validations.arrayNotEmpty(t),
            }),
            name: Yup.string().when('variant', {
              is: (variant: string) => variant === 'toNewQuestionnaire',
              then: () => validations.stringRequired(t),
            }),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <CopyVersionToQuestionnaireModalForm
                questionnaireId={questionnaireId}
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
