import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  ReferenceDto,
  SupplierDto,
  SupplierDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { supplierApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<SupplierDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsSupplierSlice = baseSlice({
  name: 'optionsSupplier',
  initialState,
});

export const selectOptionsSupplierState = (state: RootState) =>
  state.optionsSupplier.state;
export const selectOptionsSupplier = (state: RootState) =>
  state.optionsSupplier.data.map(
    (x) => ({ id: x.id, name: x.name } as ReferenceDto)
  );
export const optionsSupplierActions = optionsSupplierSlice.actions;

export const getOptionsSupplier =
  (companyId: string, search: string | undefined, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await supplierApi.querySuppliers(
        companyId,
        search,
        SupplierDtoStateEnum.Active,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsSupplierActions,
      getData: handleGetData,
    });
  };
