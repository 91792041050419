import { TableSortLabel } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  active: boolean;
  direction?: 'asc' | 'desc';
  onClick?: () => void;
  children: React.ReactNode;
}

export const QcsTableSortLabel: FC<Props> = ({
  className,
  active,
  direction = 'asc',
  onClick,
  children,
}) => {
  return (
    <TableSortLabel
      className={className}
      active={active}
      direction={direction}
      onClick={onClick}
    >
      {children}
    </TableSortLabel>
  );
};
