import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  ReferenceDto,
  SafetyEquipmentDto,
  SafetyEquipmentDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { safetyEquipmentApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<SafetyEquipmentDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsSafetyEquipmentSlice = baseSlice({
  name: 'optionsSafetyEquipment',
  initialState,
});

export const selectOptionsSafetyEquipmentState = (state: RootState) =>
  state.optionsSafetyEquipment.state;
export const selectOptionsSafetyEquipment = (state: RootState) =>
  state.optionsSafetyEquipment.data.map(
    (x) => ({ id: x.id, name: x.name, nameEn: x.nameEn } as ReferenceDto)
  );
export const optionsSafetyEquipmentActions =
  optionsSafetyEquipmentSlice.actions;

export const getOptionsSafetyEquipment =
  (companyId: string, search: string | undefined, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await safetyEquipmentApi.querySafetyEquipment(
        companyId,
        search,
        SafetyEquipmentDtoStateEnum.Active,
        true,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsSafetyEquipmentActions,
      getData: handleGetData,
    });
  };
