import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { ReferenceDto, InvitationDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { invitationsApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<InvitationDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsInvitationSlice = baseSlice({
  name: 'optionsInvitation',
  initialState,
});

export const selectOptionsInvitationState = (state: RootState) =>
  state.optionsInvitation.state;
export const selectOptionsInvitation = (state: RootState) =>
  state.optionsInvitation.data.map(
    (x) => ({ id: x.id, name: x.name } as ReferenceDto)
  );
export const optionsInvitationActions = optionsInvitationSlice.actions;

export const getOptionsInvitation =
  (companyId: string, search: string | undefined, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await invitationsApi.queryInvitations(
        companyId,
        search,
        undefined, //supplier
        undefined, //responsiblePerson
        undefined, //personOnInvitation
        undefined, //employee
        undefined, //invitationStartFrom
        undefined, //invitationStartTo
        undefined, //invitationEndFrom
        undefined, //invitationEndTo
        undefined, //state
        undefined, //trainingState
        undefined, //invitationType
        undefined, //worker status
        0, //page
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsInvitationActions,
      getData: handleGetData,
    });
  };
