import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';

const MobileContent = styled.div<{ mobileGap?: boolean }>(
  ({ theme, mobileGap }) => ({
    display: 'grid',
    gridTemplateRows: 'max-content auto max-content',
    width: '100%',
    height: '100%',
    gap: mobileGap ? '1rem' : undefined,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  })
);

const MobileButtons = styled.div(() => ({
  display: 'grid',
  gap: '1rem',
}));

const DesktopContent = styled.div(({ theme }) => ({
  display: 'none',
  gridTemplateColumns: 'max-content auto max-content',
  width: '100%',
  height: '100%',
  gap: '0.5rem',

  [theme.breakpoints.up('md')]: {
    display: 'grid',
  },
}));

const DesktopButtons = styled.div(() => ({
  display: 'grid',
  alignItems: 'center',
  position: 'relative',
}));

interface Props {
  backOnClick?: () => void;
  backOnClickMobile?: () => void;
  backDisabled?: boolean;
  onAbort?: () => void;
  nextOnClick?: () => void;
  nextDisabled?: boolean;
  mobileGap?: boolean;
  children: React.ReactNode;
}

export const TourniquetButtonContent: FC<Props> = ({
  backOnClick,
  backOnClickMobile,
  backDisabled,
  onAbort,
  nextOnClick,
  nextDisabled,
  mobileGap,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <MobileContent mobileGap={mobileGap}>
        <MobileButtons>
          <QcsButton
            variant="outlined"
            color="error"
            onClick={onAbort}
            sx={{ display: onAbort ? undefined : 'none' }}
          >
            {t('common.abort')}
          </QcsButton>

          <QcsButton
            variant="outlined"
            onClick={backOnClick || backOnClickMobile}
            disabled={backDisabled}
            sx={{
              display: backOnClick || backOnClickMobile ? undefined : 'none',
            }}
          >
            <ArrowBack />
            {t('common.back')}
          </QcsButton>
        </MobileButtons>
        {children}
        <MobileButtons>
          <QcsButton
            variant="contained"
            onClick={nextOnClick}
            disabled={nextDisabled}
            sx={{ display: nextOnClick ? undefined : 'none' }}
          >
            {t('common.nextStep')}
            <ArrowForward />
          </QcsButton>
        </MobileButtons>
      </MobileContent>

      <DesktopContent>
        <DesktopButtons>
          <QcsButton
            variant="contained"
            onClick={backOnClick}
            disabled={backDisabled}
            sx={{ visibility: backOnClick ? undefined : 'hidden' }}
          >
            <ArrowBack />
            {t('common.back')}
          </QcsButton>
        </DesktopButtons>
        {children}
        <DesktopButtons>
          <QcsButton
            variant="outlined"
            color="error"
            onClick={onAbort}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              visibility: onAbort ? undefined : 'hidden',
            }}
          >
            {t('common.abort')}
          </QcsButton>
          <QcsButton
            variant="contained"
            onClick={nextOnClick}
            disabled={nextDisabled}
            sx={{ visibility: nextOnClick ? undefined : 'hidden' }}
          >
            {t('common.nextStep')}
            <ArrowForward />
          </QcsButton>
        </DesktopButtons>
      </DesktopContent>
    </div>
  );
};
