import { Table } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  ref?:
    | ((instance: HTMLTableElement | null) => void)
    | React.RefObject<HTMLTableElement>
    | null;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const QcsTable: FC<Props> = ({ className, ref, style, children }) => {
  return (
    <Table className={className} ref={ref} style={style}>
      {children}
    </Table>
  );
};
