import { FC } from 'react';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { SxProps, Theme } from '@mui/material';
import { ErrorStateType } from '../../models/common';

interface Props {
  error?: ErrorStateType;
  sx?: SxProps<Theme>;
}

export const SaveError: FC<Props> = ({ error, sx }) => {
  if (!error) {
    return null!;
  }

  return (
    <QcsAlert severity="error" sx={sx}>
      {error}
    </QcsAlert>
  );
};
