import { CancelToken } from 'axios';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  trainingExternalListActions,
  getTrainingExternalListByUserId,
  selectTrainingExternalList,
} from '../../store/entities/trainingExternalList';
import { selectIdentity } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { TrainingExternalItem } from '../../models/external';
import { CellYesNo } from '../common/grid/CellYesNo';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { useTranslation } from 'react-i18next';
import { getCustomError, setErrorSnacks } from '../../utils/error';
import {
  getFirstLastNameObj,
  getLangNameObj,
  joinStrings2,
} from '../../utils/format';
import { ReferenceDto } from '@qcs/safety-client';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import {
  tourniquetGetInvitationPerson,
  tourniquetLoadData,
} from '../tourniquet/tourniquetFunctions';
import _ from 'lodash';
import { tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';

export const TrainingExternalList: FC = () => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const trainingExternalList = useAppSelector(selectTrainingExternalList);
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getTrainingExternalListByUserId(identity.id, cancelToken));
  };

  const handleRenderData = (item: TrainingExternalItem) => {
    const trainingCompleted =
      (item.missingTrainingsOnInvitations?.length ?? 0) === 0;
    const questionnaireCompleted =
      trainingCompleted &&
      (item.missingQuestionnairesOnInvitations?.length ?? 0) === 0;
    const showButton = item.supplierEmployeeUserId === identity.id;

    const allMissingNames = _.union(
      item.missingTrainingsOnInvitations?.map((x) => getLangNameObj(i18n, x)) ??
        [],
      item.missingQuestionnairesOnInvitations?.map((x) =>
        getLangNameObj(i18n, x)
      ) ?? []
    );
    const anyInvitation =
      (item.missingTrainingsOnInvitations?.length ?? 0) !== 0 ||
      (item.missingQuestionnairesOnInvitations?.length ?? 0) !== 0 ||
      (item.completedTrainingsOnInvitations?.length ?? 0) !== 0;

    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell>{getLangNameObj(i18n, item.training)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {joinStrings2(allMissingNames)}
        </QcsTableCell>
        <CellYesNo value={trainingCompleted} />
        <CellYesNo value={questionnaireCompleted} />
        <QcsTableCell align="right">
          {showButton && (
            <QcsLoadingButton
              onClick={handleClick(item)}
              variant={questionnaireCompleted ? 'outlined' : 'contained'}
              loading={loading}
              disabled={!anyInvitation}
            >
              {t(
                questionnaireCompleted
                  ? 'trainingExternal.runTrainingAgain'
                  : 'trainingExternal.runTraining'
              )}
            </QcsLoadingButton>
          )}
        </QcsTableCell>
      </>
    );
  };

  const handleClick = (item: TrainingExternalItem) => async () => {
    setLoading(true);
    try {
      const invitationPerson: ReferenceDto = {
        id: item.supplierEmployeeId!,
        name: getFirstLastNameObj(item),
      };
      const invitationPerson2 = tourniquetGetInvitationPerson({
        ...invitationPerson,
        email: item.email,
      });
      const anyInvitation =
        item.missingTrainingsOnInvitations?.[0] ??
        item.missingQuestionnairesOnInvitations?.[0] ??
        item.completedTrainingsOnInvitations?.[0];

      //No invitation - error.
      if (!anyInvitation) {
        throw getCustomError('noInvitation');
      }

      await tourniquetLoadData(
        anyInvitation.id,
        item.training!.id,
        companyCustomization!,
        identity.companyId,
        [invitationPerson],
        dispatch
      );

      dispatch(
        tourniquetIdentityActions.success({
          allData: [],
          data: {
            invitation: anyInvitation,
          },
        })
      );

      dispatch(
        tourniquetTrainingsActions.success({
          invitationPerson: invitationPerson2,
          trainings: [
            {
              id: item.training!.id,
            },
          ],
        })
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'common.unknownError');
    }
    setLoading(false);
  };

  return (
    <>
      <SectionHeader title="trainingExternal.title" />
      <Grid<TrainingExternalItem>
        headers={[
          { captionStr: 'trainingExternal.name' },
          { captionStr: 'trainingExternal.lastName' },
          { captionStr: 'trainingExternal.training' },
          { captionStr: 'trainingExternal.invitation', hideOnMobile: true },
          { captionStr: 'trainingExternal.trainingCompleted' },
          { captionStr: 'trainingExternal.questionnaireCompleted' },
          {},
        ]}
        data={trainingExternalList}
        gridActions={trainingExternalListActions}
        renderData={handleRenderData}
        getData={handleGetData}
        search={false}
      />
    </>
  );
};
