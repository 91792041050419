import {
  FileUploadMediaResponse,
  InvitationDocumentResponse,
  InvitationDtoStateEnum,
  InvitedPersonDto,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useRef, useState } from 'react';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { CellDate } from '../../../common/grid/CellDate';
import { GridList } from '../../../common/grid/GridList';
import { SectionHeader } from '../../../common/SectionHeader';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  BASE_URL_WITH_VERSION,
  invitationsApi,
  mediaApi,
} from '../../../../utils/api';
import { DocumentInputUploadModal } from '../../../risk/DocumentInputUploadModal';
import { useAppSelector } from '../../../../store';
import { getMediaToken } from '../../../../utils/storage';
import { InvitedPersonsDocumentDateModal } from './InvitedPersonsDocumentDateModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { setErrorSnacks } from '../../../../utils/error';
import { hasRole, isExternal } from '../../../../utils/roles';
import { selectIdentity } from '../../../../store/entities/identity';
import {
  getLangNameObj,
  invitationCertificateIsExpired,
} from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';
import { useTheme } from '@mui/material';
import { ConfirmDialog } from '../../../common/ConfirmDialog';

interface Props {
  data?: InvitedPersonDto;
  onClose: () => void;
}

export const InvitedPersonsDocumentModal: FC<Props> = ({ data, onClose }) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const [uploadModal, setUploadModal] = useState<InvitationDocumentResponse>();
  const [dateModal, setDateModal] = useState(false);
  const [validTo, setValidTo] = useState<string>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  
  const mediaToken = getMediaToken();
  const identity = useAppSelector(selectIdentity);
  const theme = useTheme();

  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleRenderData = (item: InvitationDocumentResponse) => {
    const expired = !invitationCertificateIsExpired(item);
    const uploadDisabled =
      invitation.state === InvitationDtoStateEnum.Archived ||
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ExternalManager,
        UserDtoRolesEnum.ExternalWorker,
        UserDtoRolesEnum.ExternalOneTime,
      ]);
    const downloadDisabled = !item.document;
    const approveDisabled =
      !item.document ||
      isExternal(identity) ||
      invitation.state === InvitationDtoStateEnum.Archived ||
      expired;
    const alreadyApproved = !!item.approvedOn;
    const deleteDisabled =
      !item.document || invitation.state === InvitationDtoStateEnum.Archived;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.certificate)}</QcsTableCell>
        <CellDate
          sx={{
            color: expired ? theme.palette.error.main : undefined,
          }}
          value={item.validTo}
        />
        <QcsTableCell align="right">
          <QcsTooltip
            title={`${t('invitation.tabs.tooltips.uploadCertificate')} ${t(
              'common.fileMaxSize'
            )}`}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleUpload(item)}
                loading={isSaving}
                disabled={uploadDisabled}
              >
                <CloudUploadIcon
                  color={
                    uploadDisabled
                      ? undefined
                      : !!item.document && !expired
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.downloadCertififace')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDownload(item)}
                disabled={downloadDisabled}
                loading={isSaving}
              >
                <CloudDownloadIcon
                  color={downloadDisabled ? undefined : 'success'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
          <QcsTooltip
            title={t('invitation.tabs.tooltips.approveCertificate')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleApprove(item)}
                disabled={approveDisabled || alreadyApproved}
                loading={isSaving}
              >
                <CheckCircleIcon
                  color={
                    approveDisabled
                      ? undefined
                      : alreadyApproved
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.deleteCertificate')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={() => setShowConfirmModal(true)}
                disabled={deleteDisabled}
                loading={isSaving}
              >
                <DeleteForeverIcon
                  color={deleteDisabled ? undefined : 'error'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
        <ConfirmDialog
          onClose={() => setShowConfirmModal(false)}
          onConfirm={showConfirmModal ? handleDelete(item) : null}
          title={t('document.certificateDeleteDialog.title')}
          message={t('document.certificateDeleteDialog.message')}
        />
      </>
    );
  };

  const handleUpload = (item: InvitationDocumentResponse) => () => {
    setUploadModal(item);

    if (item.certificate?.expiryDateRequired) {
      setDateModal(true);
    } else {
      setValidTo(undefined);
      fileRef.current?.click();
    }
  };

  const handleDateModalClose = (date?: string) => {
    setValidTo(date);
    setDateModal(false);

    if (date) {
      fileRef.current?.click();
    }
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);
    fileRef.current.value = '';
  };

  const handleFileUploaded = async (document: FileUploadMediaResponse) => {
    if (!selectedFile || !uploadModal) {
      return;
    }

    lockInvitation();
    try {
      await invitationsApi.updateDocument(invitation.id!, uploadModal.id!, {
        document: document.id!,
        validTo,
      });
      await reloadInvitation();
    } finally {
      unlockInvitation();
    }

    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const handleDownload = (item: InvitationDocumentResponse) => () => {
    const url = `${BASE_URL_WITH_VERSION}${item.document?.path}?token=${mediaToken}`;
    const link = document.createElement('a');
    link.href = url;
    // Taget for no "same-origin" - some browser can block download and redirect to file.
    link.target = '_blank';
    link.download = item.document!.fileName!;
    link.click();
  };

  const handleApprove = (item: InvitationDocumentResponse) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveDocumentCertificate(invitation.id!, {
        entries: [item.document!.id!],
      });
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleDelete = (item: InvitationDocumentResponse) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await mediaApi.unpairDocumentFromEntity(item.id!, 'FROM_INVITATION');
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    finally {
      setShowConfirmModal(false);
      setIsSaving(false);
      unlockInvitation();
    }
  };

  return (
    <>
      <InvitedPersonsDocumentDateModal
        open={dateModal}
        onClose={handleDateModalClose}
      />
      <DocumentInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />

      <QcsModal open={!!data} onClose={onClose}>
        <QcsModalBox>
          <SectionHeader title="invitation.persons.documentTitle" />
          <GridList<InvitationDocumentResponse>
            headers={[
              { captionStr: 'invitation.persons.documentName' },
              { captionStr: 'invitation.persons.documentValidTo' },
              {},
            ]}
            data={data?.documents ?? []}
            renderData={handleRenderData}
            search={false}
            hidePagination={true}
          />
        </QcsModalBox>
      </QcsModal>
    </>
  );
};
