import { Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { FC, FocusEventHandler, ReactNode } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  id?: string;
  name?: string;
  label?: React.ReactNode;
  value?: any;
  error?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  displayEmpty?: boolean;
  autoFocus?: boolean;
  onChange?: (event: SelectChangeEvent<any>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  renderValue?: (value: any) => ReactNode;
  children: React.ReactNode;
}

export const QcsSelect: FC<Props> = ({
  className,
  sx,
  id,
  name,
  label,
  value,
  error,
  required,
  fullWidth,
  disabled,
  multiple,
  displayEmpty,
  autoFocus,
  onChange,
  onBlur,
  renderValue,
  children,
}) => {
  return (
    <Select
      className={className}
      sx={sx}
      id={id}
      name={name}
      //Dont use required parameter.
      label={required ? <>{label} *</> : label}
      value={value}
      error={error}
      disabled={disabled}
      multiple={multiple}
      displayEmpty={displayEmpty}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth={fullWidth}
      renderValue={renderValue}
      autoFocus={autoFocus}
    >
      {children}
    </Select>
  );
};
