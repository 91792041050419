import {
  ReferenceDto,
  TrainingDto,
  TrainingVersionDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { questionnairesApi, trainingsApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { DatePicker } from '../common/form/DatePicker';
import { QuestionnaireAutocomplete } from '../common/form/QuestionnaireAutocomplete';
import { trainingVersionListActions } from '../../store/entities/trainingVersionList';
import { QcsTextField } from '../common/basic/QcsTextField';
import { TrainingSlideLanguages } from './TrainingSlideLanguages';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import {
  getCustomError,
  setErrorSnacks,
  setErrorStateSnacks,
} from '../../utils/error';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import styled from '@emotion/styled';
import { tourniquetIdentityActions } from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { TourniquetTraining } from '../../models/tourniquet';
import { selectIdentity } from '../../store/entities/identity';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { tourniquetTrainingSlidesActions } from '../../store/entities/tourniquetTrainingSlides';
import { tourniquetQuestionnaireActions } from '../../store/entities/tourniquetQuestionnaire';
import {
  tourniquetGetAfterTrainingContent,
  tourniquetGetInvitationPerson,
  tourniquetGetLang,
  tourniquetGetSlides,
} from '../tourniquet/tourniquetFunctions';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { isDateDisabled } from '../../utils/date';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { TrainingVersion } from '../../models/training';
import { useBack } from '../../hooks/useBack';

const ButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: { flexDirection: 'row' },
}));

interface Props {
  training: TrainingDto;
  trainingVersion: TrainingVersionDto;
  trainingVersionId: string;
}

const Alert = memo(({ text }: { text: string }) => {
  return (
    <QcsBox sx={{ pb: '1rem' }}>
      <QcsAlert severity="warning">{text}</QcsAlert>
    </QcsBox>
  );
});

export const TrainingVersionDetailData: FC<Props> = ({
  training,
  trainingVersion,
  trainingVersionId,
}) => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack(`/training/${training.id}`);
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  const handleSubmit = async (data: TrainingVersionDto) => {
    setSaveError('');

    try {
      const data2: TrainingVersion = {
        ...data,
        validTo: undefined,
      };

      if (trainingVersionId === 'new') {
        await trainingsApi.createVersion(training.id!, data2);
        enqueueSuccessSnackbar(t('training.addVersionSuccess'));
      } else {
        await trainingsApi.updateVersion(trainingVersionId, data2);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(trainingVersionListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        trainingVersionId === 'new'
          ? 'training.addVersionError'
          : 'common.editError'
      );
    }
  };

  const handleRunPlayground = async () => {
    setIsSaving(true);
    try {
      const invitation: ReferenceDto = { id: '', name: '', nameEn: '' };
      const invitationPerson: ReferenceDto = {
        id: identity.id,
        name: identity.name,
      };
      const invitationPerson2 = tourniquetGetInvitationPerson({
        ...invitationPerson,
        email: identity.email,
      });
      const allPersons: ReferenceDto[] = [invitationPerson];

      let questionnaireData;
      const questionnaire = training.questionnaire?.[0];
      if (questionnaire) {
        const questionnaireRes = await questionnairesApi.getQuestionnaireById(
          questionnaire.id
        );
        const questionnaireVersionsRes = await questionnairesApi.getVersions(
          questionnaire.id,
          true //activeOnly
        );
        const questionnaireActiveVersion =
          questionnaireVersionsRes.data.content?.find((x) => !!x.active);

        if (questionnaireActiveVersion) {
          questionnaireData = {
            id: questionnaireActiveVersion.id!,
            name: questionnaireRes.data.name,
            nameEn: questionnaireRes.data.nameEn,
            languages: questionnaireActiveVersion.languages ?? [],
            successRate: questionnaireActiveVersion.successRate,
          };
        }
      }

      const trainingData: TourniquetTraining = {
        training: {
          id: trainingVersion.id!,
          name: training.name,
          nameEn: training.nameEn,
          languages: trainingVersion.languageVariants!.map((x) => x.language),
        },
        questionnaire: questionnaireData
          ? {
              id: questionnaireData.id,
              name: questionnaireData.name,
              nameEn: questionnaireData.nameEn,
              languages: questionnaireData.languages,
            }
          : undefined,
        index: 0,
        mode: 'training',
        answerResponse: undefined,
        allPersons: allPersons.map((x) => x.id),
        signQuestionnaire: allPersons,
      };

      const trainingLang: any = tourniquetGetLang(
        'noLanguageSlides',
        companyCustomization!,
        trainingData.training,
        undefined
      );

      //Load slides.
      const resTraining: any = await trainingsApi.getSlides(
        trainingVersion.id!,
        trainingLang
      );

      //No slides - error.
      if (resTraining.data.length === 0) {
        throw getCustomError('noSlides');
      }

      const allSlides = await tourniquetGetSlides(resTraining.data);
      const afterTrainingContent = await tourniquetGetAfterTrainingContent(
        identity.companyId,
        trainingLang
      );

      //Load questionnaire.
      if (trainingData.questionnaire) {
        const questionnaireLang: any = tourniquetGetLang(
          'noLanguageQuestionnaire',
          companyCustomization!,
          trainingData.questionnaire,
          trainingLang
        );

        const resQuestionnaireQuestions = await questionnairesApi.getQuestions(
          trainingData.questionnaire.id,
          questionnaireLang
        );

        //No questions - error.
        if (resQuestionnaireQuestions.data.length === 0) {
          throw getCustomError('noQuestions');
        }

        dispatch(
          tourniquetQuestionnaireActions.success({
            language: trainingData.questionnaire.languages.find(
              (x) => x.code === questionnaireLang
            ),
            successRate: questionnaireData?.successRate,
            questions: resQuestionnaireQuestions.data
              .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
              .map((x) => ({ ...x, answerResponse: undefined })),
          })
        );
      } else {
        dispatch(tourniquetQuestionnaireActions.default());
      }

      dispatch(
        tourniquetTrainingSlidesActions.success({
          language: trainingData.training.languages.find(
            (x) => x.code === trainingLang
          ),
          slides: allSlides,
          afterTrainingContent,
        })
      );
      dispatch(tourniquetTrainingActions.success(trainingData));

      dispatch(
        tourniquetIdentityActions.success({
          allData: [],
          data: {
            invitation,
          },
          playground: true,
        })
      );

      dispatch(
        tourniquetTrainingsActions.success({
          invitationPerson: invitationPerson2,
          trainings: [
            {
              id: training.id,
            },
          ],
        })
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
  };

  return (
    <>
      <DetailHeader title="training.version" backTo={backTo} />
      <FormikDebounce<TrainingVersion>
        initialValues={trainingVersion}
        validationSchema={
          trainingVersion.editable
            ? Yup.object().shape({
                validFrom: trainingVersion.first
                  ? validations.dateRequired(t)
                  : validations.stringDateNotPastOrTodayRequired(t),
                duration: validations.intPositive(t),
              })
            : undefined
        }
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            {!values.questionnaire ? (
              <Alert text={t('training.noTestJoined')} />
            ) : (
              values.questionnaire.missingLanguageVariant && (
                <Alert text={t('training.missingLanguageVariant')} />
              )
            )}

            <FormContainer>
              <QcsTextField
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                }}
                value={getLangNameObj(i18n, training)}
                label={t('training.detailTitle')}
                disabled
              />

              <DatePicker
                name="validFrom"
                label={t('training.validFrom')}
                disablePast
                disableHighlightToday
                shouldDisableDate={isDateDisabled}
                required
                disabled={!trainingVersion.editable || !!trainingVersion.first}
              />
              <DatePicker
                name="validTo"
                label={t('training.validTo')}
                disabled
              />

              <QuestionnaireAutocomplete
                name="questionnaire"
                label={t('training.questionnaire')}
                disabled={!trainingVersion.editable}
              />

              <Input
                name="duration"
                label={t('training.duration')}
                type="number"
                disabled={!trainingVersion.editable}
                required
              />

              <Input
                name="note"
                label={t('training.versionNote')}
                maxLength={250}
                multiline={true}
                rows={4}
                disabled={!trainingVersion.editable}
              />

              <SaveError error={saveError} />
              <ButtonsContainer>
                {trainingVersionId !== 'new' && (
                  <QcsLoadingButton
                    variant="outlined"
                    onClick={handleRunPlayground}
                    loading={isSaving}
                    disabled={
                      (trainingVersion.languageVariants?.length ?? 0) < 1
                    }
                  >
                    {t('training.runPlayground')}
                  </QcsLoadingButton>
                )}
                <SubmitButton
                  disabled={!trainingVersion.editable || isSaving}
                />
              </ButtonsContainer>
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>

      {trainingVersionId !== 'new' && <TrainingSlideLanguages />}
    </>
  );
};
