import { EmergencyDto, ReferenceDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { emergencyApi } from '../../utils/api';
import { Checkbox } from '../common/form/Checkbox';
import { SaveError } from '../common/SaveError';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { DateTimePicker } from '../common/form/DateTimePicker';
import { getDateTimeForApi } from '../../utils/date';
import { selectCompanyCustomizationSmsEnabled } from '../../store/entities/companyCustomization';
import {
  getEmergencyActive,
  selectEmergencyActive,
  selectEmergencyActiveState,
} from '../../store/entities/emergencyActive';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { EmergencyNewModal } from './EmergencyNewModal';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

interface EmergencyNewForm
  extends Omit<EmergencyDto, 'establishment' | 'workplaces'> {
  establishment: ReferenceDto | null;
  workplaces: ReferenceDto[];
}

export const EmergencyNew: FC = () => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo } = useBack('/emergency');
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomizationSmsEnabled = useAppSelector(
    selectCompanyCustomizationSmsEnabled
  );
  const emergencyActive = useAppSelector(selectEmergencyActive);
  const emergencyActiveState = useAppSelector(selectEmergencyActiveState);
  const dispatch = useAppDispatch();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState<EmergencyDto>();
  const [savingEmailMessage, setSavingEmailMessage] = useState('');
  const [savingSmsMessage, setSavingSmsMessage] = useState('');

  useEffect(() => {
    dispatch(getEmergencyActive(identityCompanyId!));
    setLoading(false);
  }, [identityCompanyId, dispatch]);

  const handleSubmit = async (data: EmergencyNewForm) => {
    setSaveError('');

    try {
      const data2: EmergencyDto = {
        ...data,
        establishment: data.establishment!,
        sendSMS: companyCustomizationSmsEnabled ? data.sendSMS : false,
      };

      const message = await emergencyApi.getMessage(identityCompanyId, data2);
      setSavingEmailMessage(message.data.emailContent ?? '');
      setSavingSmsMessage(message.data.smsContent ?? '');
      setSavingData(data2);
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  const handleModalClose = () => {
    setSavingData(undefined);
  };

  if (loading || emergencyActiveState === FetchState.Loading) {
    return <Loader />;
  }

  if (emergencyActiveState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <EmergencyNewModal
        emailMessage={savingEmailMessage}
        smsMessage={savingSmsMessage}
        data={savingData}
        onClose={handleModalClose}
      />

      <DetailHeader title="emergency.addTitle" backTo={backTo} />
      <FormikDebounce<EmergencyNewForm>
        initialValues={{
          establishment: null,
          workplaces: [],
          sendEmail: true,
          sendSMS: companyCustomizationSmsEnabled,
          detail: '',
          createdOn: getDateTimeForApi(new Date()),
        }}
        validationSchema={Yup.object().shape({
          establishment: validations.objectRequired(t),
          createdOn: validations.dateRequired(t),
          sendEmail: Yup.string().when(['sendSMS'], (values, schema) =>
            schema.test({
              test: (sendEmail) => {
                const [sendSMS] = values;
                return sendEmail === 'true' || sendSMS;
              },
              message: () => t('errors.checkboxEmergency'),
            })
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <EstablishmentAutocomplete
                name="establishment"
                label={t('emergency.establishment')}
                required={true}
              />
              <WorkplaceAutocomplete
                name="workplaces"
                label={t('emergency.workplaces')}
                multiple={true}
                estabilishments={[values.establishment?.id ?? '']}
              />
              <DateTimePicker
                name="createdOn"
                label={t('emergency.createdOn')}
                required={true}
                disabled={true}
              />
              <Input
                name="detail"
                label={t('emergency.detail')}
                maxLength={250}
                multiline={true}
                rows={4}
              />
              <Checkbox name="sendEmail" label={t('emergency.sendEmail')} />
              {companyCustomizationSmsEnabled && (
                <Checkbox name="sendSMS" label={t('emergency.sendSMS')} />
              )}

              <SaveError error={saveError} />
              <SubmitButton disabled={!!emergencyActive.value} />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
