import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  safetyEquipmentListActions,
  SafetyEquipmentListFilter,
} from '../../store/entities/safetyEquipmentList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: SafetyEquipmentListFilter;
}

export const SafetyEquipmentFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={safetyEquipmentListActions}
        name="state"
        title="safetyEquipment.state"
        Component={GridFilterState}
      />
    </>
  );
};
