import { EmergencyDto, EmergencyDtoStateEnum } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { ErrorStateType } from '../../models/common';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  emergencyActiveActions,
  getEmergencyActive,
  selectEmergencyActive,
  selectEmergencyActiveState,
} from '../../store/entities/emergencyActive';
import {
  emergencyListActions,
  getEmergencyList,
  selectEmergencyList,
} from '../../store/entities/emergencyList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { emergencyApi } from '../../utils/api';
import { setErrorStateSnacks } from '../../utils/error';
import { getLangNameObj } from '../../utils/format';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { ErrorAlert } from '../common/ErrorAlert';
import { CellDate } from '../common/grid/CellDate';
import { Grid } from '../common/grid/Grid';
import { Loader } from '../common/Loader';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';

export const Emergency: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const emergencyList = useAppSelector(selectEmergencyList);
  const emergencyActive = useAppSelector(selectEmergencyActive);
  const emergencyActiveState = useAppSelector(selectEmergencyActiveState);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [endError, setEndError] = useState<ErrorStateType>();
  const [isEnding, setIsEnding] = useState(false);

  useEffect(() => {
    dispatch(getEmergencyActive(identityCompanyId!));
    setLoading(false);
  }, [identityCompanyId, dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getEmergencyList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: EmergencyDto) => {
    const handlePersonsClick = (
      event: React.SyntheticEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      navigate(`/emergency/${item.id}/person`);
    };

    return (
      <>
        <CellDate value={item.createdOn} showWithTime={true} />
        <CellDate value={item.endedOn} showWithTime={true} />
        <QcsTableCell>{getLangNameObj(i18n, item.establishment)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.detail}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t(`emergency.entityState.${item.state}`)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {item.found}{' '}
          {item.state === EmergencyDtoStateEnum.Active && (
            <QcsButton
              variant="contained"
              sx={{ ml: '1rem' }}
              onClick={handlePersonsClick}
            >
              {t('emergency.persons.title')}
            </QcsButton>
          )}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: EmergencyDto) => {
    navigate('/emergency/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/emergency/new');
  };

  const handleEnd = async () => {
    const emergencyId = emergencyActive.value;
    if (!emergencyId) {
      return;
    }

    setEndError('');
    setIsEnding(true);
    try {
      const data = await emergencyApi.findById(emergencyId);
      await emergencyApi.endEmergency(emergencyId, data.data);
      enqueueSuccessSnackbar(t('common.saveSuccess'));
      dispatch(emergencyListActions.reload(true));
      dispatch(emergencyActiveActions.success({ value: undefined }));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setEndError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    setIsEnding(false);
  };

  if (loading || emergencyActiveState === FetchState.Loading) {
    return <Loader />;
  }

  if (emergencyActiveState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <SectionHeader
        title="emergency.title"
        addText={emergencyActive.value ? 'emergency.end' : 'emergency.add'}
        isLoading={isEnding}
        handleAddClick={emergencyActive.value ? handleEnd : handleAddClick}
      />
      <SaveError error={endError} />
      <Grid<EmergencyDto>
        headers={[
          { captionStr: 'emergency.createdOn', orderName: 'createdOn' },
          { captionStr: 'emergency.endedOn', orderName: 'endedOn' },
          {
            captionStr: 'emergency.establishment',
            orderName: 'establishment',
          },
          {
            captionStr: 'emergency.detail',
            hideOnMobile: true,
          },
          {
            captionStr: 'emergency.state',
            orderName: 'state',
            hideOnMobile: true,
          },
          {
            captionStr: 'emergency.found',
            orderName: 'found',
            hideOnMobile: true,
          },
        ]}
        data={emergencyList}
        gridActions={emergencyListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
      />
    </>
  );
};
