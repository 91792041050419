import {
  InvitedPersonDto,
  SafetyEquipmentDto,
  SafetyEquipmentDtoTypeEnum,
} from '@qcs/safety-client';
import { FC, useMemo } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { useTheme } from '@mui/material';
import { getLangNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';

interface Props {
  data?: InvitedPersonDto;
  onClose: () => void;
}

export const InvitedPersonsSafetyEquipmentModal: FC<Props> = ({
  data,
  onClose,
}) => {
  const { t, i18n, invitation } = useInvitation();

  const theme = useTheme();

  const list = useMemo(
    () =>
      !data
        ? []
        : ([] as SafetyEquipmentDto[]).concat(
            ...(invitation.workplaces ?? [])
              .filter((workplace) =>
                data.workplaces.some((dw) => dw.id === workplace.workplace?.id)
              )
              .map((workplace) => workplace?.safetyEquipments ?? [])
          ),
    [invitation.workplaces, data]
  );

  const handleRenderData = (item: SafetyEquipmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell
          sx={{
            color:
              item.type === SafetyEquipmentDtoTypeEnum.Owned
                ? theme.palette.warning.main
                : undefined,
          }}
        >
          {t('safetyEquipment.types.' + item.type)}
        </QcsTableCell>
      </>
    );
  };

  return (
    <QcsModal open={!!data} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.persons.safetyEquipmentTitle" />
        <GridList<SafetyEquipmentDto>
          headers={[
            { captionStr: 'invitation.persons.safetyEquipmentName' },
            { captionStr: 'invitation.persons.safetyEquipmentType' },
          ]}
          data={list}
          renderData={handleRenderData}
          search={false}
          hidePagination={true}
        />
      </QcsModalBox>
    </QcsModal>
  );
};
