import { Identity, JwtData } from '../../models/auth';
import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { jwtDecode } from 'jwt-decode';
import {
  InvitationDtoStateEnum,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
  UserDtoRolesEnum,
  UserPreferences,
} from '@qcs/safety-client';
import i18n from 'i18next';
import { baseSlice, BaseType } from '../baseHelper';
import { hasRole, hasOtherRoleThan, isExternal } from '../../utils/roles';
import { getQcsAdminLastCompany, setAccessToken } from '../../utils/storage';
import { companySettingsApi, qcUserApi } from '../../utils/api';
import { companyCustomizationActions } from './companyCustomization';
import { PAGINATION_DEFAULT, PAGINATION_OPTIONS } from '../../utils/constants';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { firstUpper } from '../../utils/format';
import { invitationListActions } from './invitationList';

export type IdentityState = BaseType<Identity>;

const initialState: IdentityState = {
  state: FetchState.None,
  data: {
    id: '',
    email: '',
    name: '',
    roles: [],
    companyId: '',
    rowsCount: PAGINATION_DEFAULT,
    welcomeLangPages: {},
    supplier: '',
  },
};

export const identitySlice = baseSlice({
  name: 'identity',
  initialState,
});

export const selectIdentityState = (state: RootState) => state.identity.state;
export const selectIdentity = (state: RootState) => state.identity.data;
export const selectIdentityRoles = (state: RootState) =>
  state.identity.data.roles;
export const selectIdentityCompanyId = (state: RootState) =>
  state.identity.data.companyId;
export const selectIdentityRowsCount = (state: RootState) =>
  state.identity.data.rowsCount;
export const identityActions = identitySlice.actions;

export const getIdentity =
  (token: string, loggedNow: boolean) => async (dispatch: AppDispatch) => {
    const identityState = selectIdentityState(store.getState());
    if (identityState !== FetchState.None) {
      return;
    }

    dispatch(identityActions.request());
    try {
      if (loggedNow) {
        setAccessToken(token);
      }

      const data = jwtDecode<JwtData>(token);

      const roles = data.scope.split(' ') as UserDtoRolesEnum[];

      let preferences: UserPreferences | undefined = undefined;
      if (hasOtherRoleThan(roles, [UserDtoRolesEnum.RemoteToken])) {
        const preferencesRes = await qcUserApi.getUserPreferences(data.sub);
        preferences = preferencesRes.data;
      }

      const identity: Identity = {
        id: data.sub,
        email: data.email,
        name: data.name ? data.name : data.email,
        roles,
        companyId: data.company,
        rowsCount: PAGINATION_OPTIONS.includes(preferences?.pageSize ?? 0)
          ? preferences!.pageSize
          : PAGINATION_DEFAULT,
        welcomeLangPages: {},
        supplier: data.supplier,
      };

      if (loggedNow) {
        const isExternalManager = hasRole(identity.roles, [
          UserDtoRolesEnum.ExternalManager,
        ]);
        const isExternalWorker = hasRole(identity.roles, [
          UserDtoRolesEnum.ExternalWorker,
        ]);
        const isExternalOneTime = hasRole(identity.roles, [
          UserDtoRolesEnum.ExternalOneTime,
        ]);

        if (isExternalManager || isExternalWorker || isExternalOneTime) {
          for (const lang of SUPPORTED_LANGUAGES) {
            const settings = (preferences as any)[
              'settings' + firstUpper(lang.code)
            ];

            if (isExternalManager) {
              identity.welcomeLangPages[lang.code] =
                !settings?.supplierEmployeeSeen;
            } else if (isExternalWorker) {
              identity.welcomeLangPages[lang.code] =
                !settings?.supplierEmployeeWorkerSeen;
            } else {
              identity.welcomeLangPages[lang.code] = !settings?.visitationSeen;
            }
          }
        }
      }

      i18n.changeLanguage(preferences?.language ?? data.lang);

      if (hasRole(identity.roles, [UserDtoRolesEnum.AdminQcs])) {
        const lastCompanyId = getQcsAdminLastCompany();
        if (lastCompanyId) {
          identity.companyId = lastCompanyId;
        }
      }

      dispatch(setDefaultFilter(identity));

      if (identity.companyId) {
        dispatch(companyCustomizationActions.request());
        const companySettingsResponse =
          await companySettingsApi.getBusinessSettings(identity.companyId);
        dispatch(
          companyCustomizationActions.success(companySettingsResponse.data)
        );
      } else {
        dispatch(companyCustomizationActions.success(undefined));
      }

      dispatch(identityActions.success(identity));
    } catch (e) {
      dispatch(identityActions.error());
    }
  };

export const setDefaultFilter =
  (identity: Identity) => (dispatch: AppDispatch) => {
    const external = isExternal(identity);
    const isExternalManager = hasRole(identity.roles, [
      UserDtoRolesEnum.ExternalManager,
    ]);

    //Default invitation filter.
    dispatch(
      invitationListActions.updateFilter({
        employee: external
          ? undefined
          : { id: identity.id, name: identity.name },
        supplierEmployee: isExternalManager
          ? {
              id: identity.id,
              name: identity.name,
              email: '',
              language: '',
              lastName: '',
              role: SupplierEmployeeDtoRoleEnum.ExternalManager,
              state: SupplierEmployeeDtoStateEnum.Active,
            }
          : undefined,
        state: Object.values(InvitationDtoStateEnum).filter(
          (x) =>
            x !== InvitationDtoStateEnum.Deleted &&
            x !== InvitationDtoStateEnum.Archived &&
            (!external || x !== InvitationDtoStateEnum.InProgress)
        ),
      })
    );
  };
