import { AxiosResponse } from 'axios';

export function parseFilename(response: AxiosResponse<Blob>): string {
  const disposition = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition ?? '');

  if (!!matches && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }

  return 'unknown';
}

export const downloadBlob = async (response: AxiosResponse<Blob>) => {
  const fileName = parseFilename(response);
  const data = new Blob([response.data]);
  const blobURL = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = blobURL;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(blobURL);
};
