import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { SupplierDto, SupplierDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { supplierApi } from '../../utils/api';

export interface SupplierListFilter extends ListFilter {
  state?: SupplierDtoStateEnum;
}

export type SupplierListState = ListType<SupplierDto, SupplierListFilter>;

const initialState: SupplierListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: SupplierDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const supplierListSlice = listSlice({
  name: 'supplierList',
  initialState,
});

export const selectSupplierList = (state: RootState) => state.supplierList;
export const supplierListActions = supplierListSlice.actions;

export const getSupplierList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectSupplierList(storeState);

    const handleGetData = (size: number) => {
      return supplierApi.querySuppliers(
        companyId,
        data.query.q,
        data.query.state,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: supplierListActions,
      getData: handleGetData,
    });
  };
