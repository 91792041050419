import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

export const QcsLoadingButton: FC<Props> = ({
  className,
  sx,
  variant,
  color,
  type = 'button',
  loading,
  disabled,
  onClick,
  children,
}) => {
  return (
    <LoadingButton
      className={className}
      sx={sx}
      variant={variant}
      color={color}
      type={type}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </LoadingButton>
  );
};
