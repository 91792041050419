import styled from '@emotion/styled';

export const SubmitButtonsContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    width: 'fit-content',
    marginLeft: 'auto',
    flexDirection: 'row',
  },
}));
