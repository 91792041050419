import { FC } from 'react';
import { TourniquetTraining } from '../tourniquet/TourniquetTraining';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { useAppDispatch } from '../../store';
import { visitationListActions } from '../../store/entities/visitationList';
import { invitationListActions } from '../../store/entities/invitationList';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';

export const VisitationVisitDetailPresentation: FC = () => {
  const dispatch = useAppDispatch();

  const handleEndTraining = async () => {
    dispatch(tourniquetTrainingActions.default());
    dispatch(tourniquetTrainingsActions.default());

    dispatch(visitationListActions.reload(true));
    dispatch(invitationListActions.reload(true));
    dispatch(invitationTypeVisitListActions.reload(true));
  };

  return <TourniquetTraining onEndTraining={handleEndTraining} />;
};
