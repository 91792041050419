import { FC, useState } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { useTranslation } from 'react-i18next';
import {
  InvitationTypeVisitRequest,
  SupplierEmployeeDto,
} from '@qcs/safety-client';
import { SectionHeader } from '../common/SectionHeader';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { Form } from 'formik';
import { FormContainer } from '../common/form/FormContainer';
import { RadioGroup } from '../common/form/RadioGroup';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { Radio } from '@mui/material';
import { Input } from '../common/form/Input';
import { PhoneInput } from '../common/form/PhoneInput';
import { ErrorStateType } from '../../models/common';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { SaveError } from '../common/SaveError';
import { SubmitButton } from '../common/form/SubmitButton';

export interface VisitationAddOneTimeConflictData {
  form: InvitationTypeVisitRequest;
  db: SupplierEmployeeDto;
}

export enum VisitationAddOneTimeConflictResult {
  None = 1,
  UseDb = 2,
  UseForm = 3,
}

interface VisitationAddOneTimeConflictForm {
  type: 'db' | 'form';
  formName: string;
  formLastName: string;
  formPhone: string;
  formCompany: string;
  dbName: string;
  dbLastName: string;
  dbPhone: string;
  dbCompany: string;
}

interface Props {
  data: VisitationAddOneTimeConflictData | undefined;
  onClose: (result: VisitationAddOneTimeConflictResult) => Promise<void>;
}

export const VisitationAddModalOneTimeConflict: FC<Props> = ({
  data,
  onClose,
}) => {
  const { t } = useTranslation();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const handleClose = () => {
    onClose(VisitationAddOneTimeConflictResult.None);
  };

  const handleSubmit = async (data: VisitationAddOneTimeConflictForm) => {
    setSaveError('');
    try {
      await onClose(
        data.type === 'form'
          ? VisitationAddOneTimeConflictResult.UseForm
          : VisitationAddOneTimeConflictResult.UseDb
      );
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  return (
    <QcsModal open={!!data} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title="visitation.oneTimeConflictTitle" />
        <FormikDebounce<VisitationAddOneTimeConflictForm>
          initialValues={{
            type: 'db',
            formName: data?.form.name ?? '',
            formLastName: data?.form.lastName ?? '',
            formPhone: data?.form.phone ?? '',
            formCompany: data?.form.company ?? '',
            dbName: data?.db.name ?? '',
            dbLastName: data?.db.lastName ?? '',
            dbPhone: data?.db.phone ?? '',
            dbCompany: data?.db.supplier?.name ?? '',
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <RadioGroup name="type">
                <FormContainer>
                  <QcsFormControlLabel
                    value="db"
                    control={<Radio />}
                    label={t('visitation.oneTimeConflictUseDb')}
                  />
                  <Input
                    name="dbName"
                    label={t('visitation.oneTimeName')}
                    required={true}
                    disabled={true}
                  />
                  <Input
                    name="dbLastName"
                    label={t('visitation.oneTimeLastName')}
                    required={true}
                    disabled={true}
                  />
                  <PhoneInput
                    name="dbPhone"
                    label={t('visitation.oneTimePhone')}
                    disabled={true}
                  />
                  {data?.db.oneTime && (
                    <Input
                      name="dbCompany"
                      label={t('visitation.oneTimeCompany')}
                      disabled={true}
                    />
                  )}

                  <QcsFormControlLabel
                    value="form"
                    control={<Radio />}
                    label={t('visitation.oneTimeConflictUseForm')}
                  />
                  <Input
                    name="formName"
                    label={t('visitation.oneTimeName')}
                    required={true}
                    disabled={true}
                  />
                  <Input
                    name="formLastName"
                    label={t('visitation.oneTimeLastName')}
                    required={true}
                    disabled={true}
                  />
                  <PhoneInput
                    name="formPhone"
                    label={t('visitation.oneTimePhone')}
                    disabled={true}
                  />
                  {data?.db.oneTime && (
                    <Input
                      name="formCompany"
                      label={t('visitation.oneTimeCompany')}
                      disabled={true}
                    />
                  )}

                  <SaveError error={saveError} />
                  <SubmitButton />
                </FormContainer>
              </RadioGroup>
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
