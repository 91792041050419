import {
  FileUploadMediaResponse,
  InvitationActivityDto,
  InvitationDtoStateEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC, useMemo, useRef, useState } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useAppSelector } from '../../../../store';
import { getMediaToken } from '../../../../utils/storage';
import {
  BASE_URL_WITH_VERSION,
  invitationsApi,
  mediaApi,
} from '../../../../utils/api';
import { DocumentInputUploadModal } from '../../../risk/DocumentInputUploadModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { selectIdentity } from '../../../../store/entities/identity';
import { hasRole } from '../../../../utils/roles';
import { setErrorSnacks } from '../../../../utils/error';
import { getLangNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';
import { ConfirmDialog } from '../../../common/ConfirmDialog';

interface Props {
  itemId?: string;
  onClose: () => void;
}

export const ActivityRiskModal: FC<Props> = ({ itemId, onClose }) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const [uploadModal, setUploadModal] = useState<InvitationActivityDto>();
  const mediaToken = getMediaToken();
  const identity = useAppSelector(selectIdentity);

  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  const item = invitation.activities?.find((x) => x.id === itemId);
  const list = useMemo(() => (item ? [item] : []), [item]);

  const handleRenderData = (item: InvitationActivityDto) => {
    const alreadyApproved = !!item.approvedOn;
    const uploadDisabled =
      invitation.state === InvitationDtoStateEnum.Archived ||
      alreadyApproved ||
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerWorkplace,
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ExternalManager,
      ]);
    const downloadDisabled = !item.document;
    const approveDisabled =
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ManagerWorkplace,
      ]) || !item.document;
    const deleteDisabled =
      !item.document ||
      alreadyApproved ||
      invitation.state === InvitationDtoStateEnum.Archived;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.activity)}</QcsTableCell>
        <QcsTableCell align="right">
          <QcsTooltip
            title={`${t('invitation.tabs.tooltips.uploadRiskDocument')} ${t(
              'common.fileMaxSize'
            )}`}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleUpload(item)}
                loading={isSaving}
                disabled={uploadDisabled}
              >
                <CloudUploadIcon
                  color={
                    uploadDisabled
                      ? undefined
                      : item.document
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.downloadRiskDocument')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDownload(item)}
                disabled={downloadDisabled}
                loading={isSaving}
              >
                <CloudDownloadIcon
                  color={downloadDisabled ? undefined : 'success'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.approveRisk')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleApprove(item)}
                disabled={approveDisabled || alreadyApproved}
                loading={isSaving}
              >
                <CheckCircleIcon
                  color={
                    approveDisabled
                      ? undefined
                      : alreadyApproved
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.deleteRisk')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={() => setShowConfirmModal(true)}
                disabled={deleteDisabled}
                loading={isSaving}
              >
                <DeleteForeverIcon
                  color={deleteDisabled ? undefined : 'error'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
        <ConfirmDialog
          onClose={() => setShowConfirmModal(false)}
          onConfirm={showConfirmModal ? handleDelete(item) : null}
          title={t('document.riskDocumentDeleteDialog.title')}
          message={t('document.riskDocumentDeleteDialog.message')}
        />
      </>
    );
  };

  const handleUpload = (item: InvitationActivityDto) => () => {
    setUploadModal(item);
    fileRef.current?.click();
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);
    fileRef.current.value = '';
  };

  const handleFileUploaded = async (document: FileUploadMediaResponse) => {
    if (!selectedFile || !uploadModal) {
      return;
    }

    lockInvitation();
    try {
      await invitationsApi.addDocumentsForActivities(invitation.id!, {
        data: [
          { activityId: uploadModal.activity!.id!, documentId: document.id! },
        ],
      });
      await reloadInvitation();
    } finally {
      unlockInvitation();
    }

    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const handleDownload = (item: InvitationActivityDto) => () => {
    const url = `${BASE_URL_WITH_VERSION}${item.document?.path}?token=${mediaToken}`;
    const link = document.createElement('a');
    link.href = url;
    // Taget for no "same-origin" - some browser can block download and redirect to file.
    link.target = '_blank';
    link.download = item.document!.fileName!;
    link.click();
  };

  const handleApprove = (item: InvitationActivityDto) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveActivities(invitation.id!, {
        entries: [item.id!],
      });
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleDelete = (item: InvitationActivityDto) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await mediaApi.unpairDocumentFromEntity(
        item.id!,
        'FROM_INVITATION_ACTIVITY'
      );
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <>
      <DocumentInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />

      <QcsModal open={!!item} onClose={onClose}>
        <QcsModalBox>
          <SectionHeader title="invitation.activity.riskTitle" />
          <GridList<InvitationActivityDto>
            headers={[{ captionStr: 'invitation.activity.name' }, {}]}
            data={list}
            renderData={handleRenderData}
            search={false}
            hidePagination={true}
          />
        </QcsModalBox>
      </QcsModal>
    </>
  );
};
