import styled from '@emotion/styled';

export const InvitationButtonsContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gap: '0.5rem',
  gridTemplateColumns: 'auto',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'min-content auto min-content min-content min-content',
  },
}));
