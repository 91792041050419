import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { QuestionnaireVersionDto } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { questionnairesApi } from '../../utils/api';

export type QuestionnaireVersionListState = ListType<
  QuestionnaireVersionDto,
  ListFilter
>;

const initialState: QuestionnaireVersionListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'validFrom',
    sortDesc: false,
  },
};

export const questionnaireVersionListSlice = listSlice({
  name: 'questionnaireVersionList',
  initialState,
});

export const selectQuestionnaireVersionList = (state: RootState) =>
  state.questionnaireVersionList;
export const questionnaireVersionListActions =
  questionnaireVersionListSlice.actions;

export const getQuestionnaireVersionList =
  (questionnaireId: string, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectQuestionnaireVersionList(storeState);

    const handleGetData = (size: number) => {
      return questionnairesApi.getVersions(
        questionnaireId,
        undefined, //activeOnly
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: questionnaireVersionListActions,
      getData: handleGetData,
    });
  };
