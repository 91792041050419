import {
  ActivityDto,
  ActivityDtoStateEnum,
  CertificateDto,
} from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  activityActions,
  getActivity,
  selectActivity,
  selectActivityState,
} from '../../store/entities/activity';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { Input } from '../common/form/Input';
import { activityApi } from '../../utils/api';
import { activityListActions } from '../../store/entities/activityList';
import { Checkbox } from '../common/form/Checkbox';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import {
  documentListActions,
  getDocumentList,
  selectDocumentList,
} from '../../store/entities/documentList';
import { Grid } from '../common/grid/Grid';
import { CancelToken } from 'axios';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CellYesNo } from '../common/grid/CellYesNo';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import { QcsTypography } from '../common/basic/QcsTypography';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { DocumentFilter } from '../documents/DocumentFilter';
import { setErrorStateSnacks } from '../../utils/error';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { fixNameInitValue, getLangNameObj } from '../../utils/format';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType, Validator } from '../../models/common';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { useBack } from '../../hooks/useBack';

interface ActivityDetailForm extends ActivityDto {
  saveAndNew?: boolean;
}

export const ActivityDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/activity');
  const { activityId } = useParams();
  const activity = useAppSelector(selectActivity);
  const activityState = useAppSelector(selectActivityState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const documentList = useAppSelector(selectDocumentList);
  const formikRef = useRef<FormikProps<ActivityDetailForm> | null>(null);

  useEffect(() => {
    if (activityId === 'new') {
      dispatch(activityActions.default());
    } else {
      dispatch(getActivity(activityId!));
    }
    setLoading(false);
  }, [activityId, dispatch]);

  const handleSubmit = async (data: ActivityDetailForm) => {
    setSaveError('');

    try {
      if (activityId === 'new') {
        await activityApi.createActivity(identityCompanyId, data);
        enqueueSuccessSnackbar(t('activity.addSuccess'));
      } else {
        await activityApi.updateActivity(activityId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(activityListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        activityId === 'new' ? 'activity.addError' : 'common.editError'
      );
    }
  };

  const handleDocumentGetData = (cancelToken: CancelToken) => {
    dispatch(getDocumentList(identityCompanyId, cancelToken));
  };

  const handleIsRowDisabled = (item: CertificateDto) =>
    item.state !== ActivityDtoStateEnum.Active;

  const handleDocumentRenderData = (item: CertificateDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <CellYesNo value={item.expiryDateRequired} hideOnMobile={true} />
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  if (loading || activityState === FetchState.Loading) {
    return <Loader />;
  }

  if (activityState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {};

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }

  return (
    <>
      <DetailHeader title="activity.detailTitle" backTo={backTo} />
      <FormikDebounce<ActivityDetailForm>
        initialValues={{
          ...activity,
          ...fixNameInitValue(i18n, activity),
          riskRequired: companyCustomization?.passRisksEnabled
            ? activity.riskRequired
            : false,
          riskPass: companyCustomization?.passRisksEnabled
            ? activity.riskPass
            : false,
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormContainer>
              <InputTextWithLang
                isNew={activityId === 'new'}
                label={t('activity.name')}
                required
              />
              <Checkbox
                name="oneTimeEntry"
                label={t('activity.oneTimeEntry')}
              />
              <Checkbox
                name="riskRequired"
                label={t('activity.riskRequired')}
                disabled={!companyCustomization?.passRisksEnabled}
              />
              <Checkbox
                name="riskPass"
                label={t('activity.riskPass')}
                disabled={!companyCustomization?.passRisksEnabled}
              />

              <Select
                name="state"
                label={t('activity.state')}
                disabled={!activity.stateChangeEnabled}
              >
                <QcsMenuItem value={ActivityDtoStateEnum.Active}>
                  {t('entityState.ACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={ActivityDtoStateEnum.Inactive}>
                  {t('entityState.INACTIVE')}
                </QcsMenuItem>
              </Select>

              <div>
                <QcsTypography variant="h6">
                  {t('activity.certificateRequiredGrid')}:
                </QcsTypography>
                <Grid<CertificateDto>
                  headers={[
                    { captionStr: 'document.name' },
                    {
                      captionStr: 'document.expiryDateRequired',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'document.state',
                      hideOnMobile: true,
                    },
                  ]}
                  //documentList is used by Documents.tsx - must have same filter (actual) or split store
                  data={documentList}
                  gridActions={documentListActions}
                  renderData={handleDocumentRenderData}
                  isRowDisabled={handleIsRowDisabled}
                  getData={handleDocumentGetData}
                  filter={<DocumentFilter query={documentList.query} />}
                  checkbox={true}
                  checkedItems={values.certificates}
                  changeChecked={(items) =>
                    setFieldValue('certificates', items)
                  }
                />
              </div>

              <Input
                name="note"
                label={t('activity.note')}
                maxLength={250}
                multiline={true}
                rows={4}
              />

              <SubmitAndNewButtons id={activityId} error={saveError} />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
