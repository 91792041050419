import { DesktopDatePicker } from '@mui/x-date-pickers';
import { SxProps, TextField, Theme } from '@mui/material';
import { format } from 'date-fns';
import { FC, FocusEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateLang } from '../../../utils/i18n';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  id?: string;
  name?: string;
  label?: React.ReactNode;
  value?: Date | null;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  disablePast?: boolean;
  onChange: (value?: Date | null) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  shouldDisableDate?: (day: Date) => boolean;
  disableHighlightToday?: boolean;
}

export const QcsDatePicker: FC<Props> = ({
  className,
  sx,
  id,
  name,
  label,
  value,
  error,
  required,
  helperText,
  disabled,
  disablePast,
  onChange,
  onBlur,
  shouldDisableDate,
  disableHighlightToday,
}) => {
  const { i18n } = useTranslation();

  const dateMask = useMemo(
    () =>
      format(new Date(), 'P', { locale: getDateLang(i18n) }).replace(
        /[0-9]/g,
        '_'
      ),
    [i18n]
  );

  return (
    <DesktopDatePicker
      mask={dateMask}
      className={className}
      //Dont use required parameter.
      label={required ? <>{label} *</> : label}
      value={value}
      disabled={disabled}
      disablePast={disablePast}
      onChange={onChange}
      InputProps={{ sx }}
      shouldDisableDate={shouldDisableDate}
      disableHighlightToday={disableHighlightToday}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          onBlur={onBlur}
          name={name}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
