import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  trainingListActions,
  TrainingListFilter,
} from '../../store/entities/trainingList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: TrainingListFilter;
}

export const TrainingFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={trainingListActions}
        name="state"
        title="training.state"
        Component={GridFilterState}
      />
    </>
  );
};
