import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  EmployeeDto,
  EmployeeDtoStateEnum,
  ReferenceDto,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { employeeApi } from '../../utils/api';

export interface EmployeeListFilter extends ListFilter {
  state?: EmployeeDtoStateEnum;
  establishments: ReferenceDto[];
}

export type EmployeeListState = ListType<EmployeeDto, EmployeeListFilter>;

const initialState: EmployeeListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: EmployeeDtoStateEnum.Active,
    sortBy: 'lastName',
    sortDesc: false,
    establishments: [],
  },
};

export const employeeListSlice = listSlice({
  name: 'employeeList',
  initialState,
});

export const selectEmployeeList = (state: RootState) => state.employeeList;
export const employeeListActions = employeeListSlice.actions;

export const getEmployeeList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectEmployeeList(storeState);

    const handleGetData = (size: number) => {
      return employeeApi.queryEmployees(
        companyId,
        data.query.q,
        data.query.state,
        undefined, //forInvite
        data.query.establishments.map((x) => x.id),
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: employeeListActions,
      getData: handleGetData,
    });
  };
