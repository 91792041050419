import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { ActivityDto, ActivityDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { activityApi } from '../../utils/api';

export interface ActivityListFilter extends ListFilter {
  riskRequired?: boolean;
  certificateRequired?: boolean;
  state?: ActivityDtoStateEnum;
}

export type ActivityListState = ListType<ActivityDto, ActivityListFilter>;

const initialState: ActivityListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: ActivityDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const activityListSlice = listSlice({
  name: 'activityList',
  initialState,
});

export const selectActivityList = (state: RootState) => state.activityList;
export const activityListActions = activityListSlice.actions;

export const getActivityList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectActivityList(storeState);

    const handleGetData = (size: number) => {
      return activityApi.queryActivities(
        companyId,
        data.query.q,
        data.query.riskRequired,
        data.query.certificateRequired,
        data.query.state,
        undefined, //oneTimeEntry
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: activityListActions,
      getData: handleGetData,
    });
  };
