import { FC, useEffect, useState } from 'react';
import { BASE_URL_WITH_VERSION, riskApi } from '../../utils/api';
import {
  PROTOCOL,
  PROTOCOL_MEDIA,
  PROTOCOL_RISK,
  SECURED_MEDIA,
  YOUTUBE_PARAMETERS,
} from '../../utils/constants';
import { imageRegex } from '../../utils/regex';
import { getMediaToken } from '../../utils/storage';
import { Modal as QcsModal } from '@mui/material';

import { QcsProtocolLoader } from './QcsProtocolLoader';
import {
  QcsProtocolIframe,
  QcsProtocolImg,
  QcsProtocolModalBox,
} from './QcsProtocolStyles';

export const qcsProtocolReplaceToEditor = (html: string) => {
  const mediaToken = getMediaToken();
  const regex = new RegExp(`src="${PROTOCOL_MEDIA}(.*?)"`, 'gi');
  const res = html.replace(
    regex,
    `src="${BASE_URL_WITH_VERSION}/$1?token=${mediaToken}"`
  );

  return res;
};

export const qcsProtocolReplaceFromEditor = (html: string) => {
  const regex = new RegExp(
    `src="${BASE_URL_WITH_VERSION}/(.*?)\\?token=(.*?)"`,
    'gi'
  );
  const res = html.replace(regex, `src="${PROTOCOL_MEDIA}$1"`);

  return res;
};

export const QcsProtocol: FC = () => {
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [loaderLoading, setLoaderLoading] = useState(false);
  const [loaderError, setLoaderError] = useState(false);

  useEffect(() => {
    addListener();

    return () => {
      removeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addListener = () => {
    window.addEventListener('click', onClickListener, true);
  };

  const removeListener = () => {
    window.removeEventListener('click', onClickListener);
  };

  const onClickListener = (event: MouseEvent) => {
    const anchor = (event?.target as any)?.closest('a');
    if (!anchor) {
      return;
    }

    let href = anchor.getAttribute('href') as string;
    const hrefL = href?.toLowerCase();
    let showInframe = false;

    if (!hrefL) return;

    if (hrefL?.startsWith(PROTOCOL) !== true) {
      if (hrefL.startsWith('https://www.youtube.com/watch?')) {
        const url = new URL(href);
        const params = new URLSearchParams(url.search);
        const v = params.get('v');

        if (v) {
          href = 'https://www.youtube.com/embed/' + v + YOUTUBE_PARAMETERS;
          showInframe = true;
        }
      } else if (hrefL.startsWith('https://www.youtube.com/embed/')) {
        showInframe = true;
        href = href + YOUTUBE_PARAMETERS;
      } else if (hrefL.startsWith('https://youtu.be/')) {
        const url = new URL(href);
        const v = url.pathname?.substring(1);

        if (v) {
          href = 'https://www.youtube.com/embed/' + v + YOUTUBE_PARAMETERS;
          showInframe = true;
        }
      }

      if (!showInframe) {
        return;
      }
    }

    //Dont catch in html editor.
    if (!event.ctrlKey && anchor.closest('.ck-content')) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    if (showInframe) {
      showFrame(href);
    } else if (href.startsWith(PROTOCOL_MEDIA)) {
      showQcsMedia(href.substring(PROTOCOL_MEDIA.length));
    } else if (href.startsWith(PROTOCOL_RISK)) {
      showLoading();
      showQcsRisk(href.substring(PROTOCOL_RISK.length));
    }
  };

  const showFrame = (url: string) => {
    setImgSrc(null);
    setLoaderLoading(false);
    setLoaderError(false);
    setIframeSrc(url);
    setOpen(true);
  };

  const showQcsMedia = (url: string) => {
    if (url.match(imageRegex) !== null) {
      setIframeSrc(null);
      setLoaderLoading(false);
      setLoaderError(false);
      setImgSrc(getMediaUrl(url));
      setOpen(true);
    } else if (url.match(/\.(pdf)$/i) !== null) {
      showFrame(getMediaUrl(url));
    } else {
      const link = document.createElement('a');
      link.href = getMediaUrl(url);
      // Taget for no "same-origin" - some browser can block download and redirect to file.
      link.target = '_blank';
      link.download = url.substring(url.lastIndexOf('/') + 1);
      link.click();
    }
  };

  const getMediaUrl = (url: string) => {
    const mediaToken = getMediaToken();
    const res = `${BASE_URL_WITH_VERSION}/${url}?token=${mediaToken}`;
    return res;
  };

  const showLoading = () => {
    setImgSrc(null);
    setIframeSrc(null);
    setLoaderError(false);
    setLoaderLoading(true);
    setOpen(true);
  };

  const showQcsRisk = async (inputRiskId: string) => {
    try {
      const riskRes = await riskApi.getRisk(inputRiskId);
      const versionRes = await riskApi.getRiskVersion(
        inputRiskId,
        riskRes.data.currentVersion!
      );

      const url = `${SECURED_MEDIA}${versionRes.data.document}/${versionRes.data.documentName}`;
      showQcsMedia(url);
    } catch {
      setLoaderError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <QcsModal open={open} onClose={handleClose}>
      <QcsProtocolModalBox disableBorder={!!iframeSrc}>
        {imgSrc && <QcsProtocolImg src={imgSrc} />}
        {iframeSrc && (
          <QcsProtocolIframe
            src={iframeSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        {(loaderLoading || loaderError) && (
          <QcsProtocolLoader loading={loaderLoading} error={loaderError} />
        )}
      </QcsProtocolModalBox>
    </QcsModal>
  );
};
