import { Paper, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const QcsPaper: FC<Props> = ({ className, sx, children }) => {
  return (
    <Paper className={className} sx={sx}>
      {children}
    </Paper>
  );
};
