import { FC } from 'react';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { QcsDialog } from '../../common/basic/QcsDialog';
import { QcsDialogActions } from '../../common/basic/QcsDialogActions';
import { DialogContent as QcsDialogContent } from '@mui/material';

import { DialogTitle as QcsDialogTitle } from '@mui/material';

import { QcsDialogContentText } from '../../common/basic/QcsDialogContentText';

interface Props {
  onClose: () => void;
  onConfirm: (() => void) | null;
  onSaveSlide: () => Promise<'ok' | 'error'>;
}

export const UnsavedChangesDialog: FC<Props> = ({
  onClose,
  onConfirm,
  onSaveSlide,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleSaveSlide = async () => {
    try {
      const returnState = await onSaveSlide();
      if (returnState === 'ok') {
        handleConfirm();
      } else {
        onClose();
      }
    } catch {}
  };

  return (
    <QcsDialog open={!!onConfirm} onClose={onClose}>
      <QcsDialogTitle>
        {t('training.unsavedChangesDialog.title')}
      </QcsDialogTitle>
      <QcsDialogContent>
        <QcsDialogContentText>
          {t('training.unsavedChangesDialog.message')}
        </QcsDialogContentText>
      </QcsDialogContent>
      <QcsDialogActions>
        <QcsButton onClick={onClose}>
          {t('training.unsavedChangesDialog.cancel')}
        </QcsButton>
        <QcsButton onClick={handleConfirm}>
          {t('training.unsavedChangesDialog.continueWithoutSaving')}
        </QcsButton>
        <QcsButton onClick={handleSaveSlide}>
          {t('training.unsavedChangesDialog.saveAndContinue')}
        </QcsButton>
      </QcsDialogActions>
    </QcsDialog>
  );
};
