import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  supplierEmployeeListActions,
  SupplierEmployeeListFilter,
} from '../../store/entities/supplierEmployeeList';
import { GridFilterSupplierEmployeeState } from '../common/grid/GridFilterSupplierEmployeeState';
import { GridFilterSupplierEmployeeRole } from '../common/grid/GridFilterSupplierEmployeeRole';

interface Props {
  query: SupplierEmployeeListFilter;
}

export const SupplierEmployeeFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={supplierEmployeeListActions}
        name="state"
        title="supplierEmployee.state"
        Component={GridFilterSupplierEmployeeState}
      />
      <GridFilterItem
        query={query}
        gridActions={supplierEmployeeListActions}
        name="role"
        title="supplierEmployee.role"
        Component={GridFilterSupplierEmployeeRole}
      />
    </>
  );
};
