import { useFormikContext } from 'formik';
import { FC } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormControl } from '../basic/QcsFormControl';
import _ from 'lodash';
import { useTheme } from '@mui/material';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import styled from '@emotion/styled';

export const SelectItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  overflow: 'hidden',
}));

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  children: React.ReactNode;
  onChange?: any;
}

export const Select: FC<Props> = ({
  className,
  name,
  label,
  children,
  disabled,
  required,
  autoFocus,
  onChange,
}) => {
  const { values, errors, touched, submitCount, handleChange, handleBlur } =
    useFormikContext<any>();

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const theme = useTheme();
  const handle = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    handleChange(e);
  };
  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel disabled={disabled} required={required}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        className={className}
        sx={{ backgroundColor: theme.palette.common.white }}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handle}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
      >
        {children}
      </QcsSelect>
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
