import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  establishmentListActions,
  EstablishmentListFilter,
} from '../../store/entities/establishmentList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: EstablishmentListFilter;
}

export const EstablishmentFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={establishmentListActions}
        name="state"
        title="activity.state"
        Component={GridFilterState}
      />
    </>
  );
};
