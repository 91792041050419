import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  QuestionnaireDto,
  QuestionnaireDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { questionnairesApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';
import { Questionnaire } from '../../models/training';

type State = BaseType<QuestionnaireDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsQuestionnaireSlice = baseSlice({
  name: 'optionsQuestionnaire',
  initialState,
});

export const selectOptionsQuestionnaireState = (state: RootState) =>
  state.optionsQuestionnaire.state;

export const selectOptionsQuestionnaire = (state: RootState) =>
  state.optionsQuestionnaire.data.map(
    (x) =>
      ({
        id: x.id,
        name: x.name,
        nameEn: x.nameEn,
        missingLanguageVariant: x.missingLanguageVariant,
      } as Questionnaire)
  );

export const optionsQuestionnaireActions = optionsQuestionnaireSlice.actions;

export const getOptionsQuestionnaire =
  (
    companyId: string,
    search: string | undefined,
    skipId: string | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await questionnairesApi.queryQuestionnaires(
        companyId,
        search,
        QuestionnaireDtoStateEnum.Active,
        skipId,
        true,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsQuestionnaireActions,
      getData: handleGetData,
    });
  };
