import { useLocation, useNavigate } from 'react-router';
import { LocationStateBack } from '../models/router';

export const useBack = (defaultPath?: string | null) => {
  const location = useLocation();
  const navigate = useNavigate();

  const stateBack = (location.state as LocationStateBack)?.back;
  const backTo = stateBack ? stateBack : defaultPath;

  const goBack = () => {
    if (backTo) {
      navigate(backTo);
    }
  };

  return { backTo, goBack, navigate };
};
