import { SupplierEmployeeDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { FetchState } from '../../store/fetchState';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Loader } from '../common/Loader';
import {
  getSupplierEmployee,
  selectSupplierEmployee,
  selectSupplierEmployeeState,
} from '../../store/entities/supplierEmployee';
import { PhoneInput } from '../common/form/PhoneInput';
import { SupplierEmployeeProtocol } from '../supplier/SupplierEmployeeProtocol';
import { QcsTextField } from '../common/basic/QcsTextField';
import styled from '@emotion/styled';
import { ErrorStateType } from '../../models/common';
import { supplierEmployeeApi } from '../../utils/api';
import { setErrorState } from '../../utils/error';
import { SaveError } from '../common/SaveError';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { useBack } from '../../hooks/useBack';

const PinContainer = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto max-content',
  gap: '1rem',
}));

export const InvitationTypeVisitDetail: FC = () => {
  const { t } = useTranslation();
  const { backTo } = useBack('/visit');
  const { supplierEmployeeId } = useParams();
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const supplierEmployee = useAppSelector(selectSupplierEmployee);
  const supplierEmployeeState = useAppSelector(selectSupplierEmployeeState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [pin, setPin] = useState('');
  const [pinLoading, setPinLoading] = useState(false);
  const [pinError, setPinError] = useState<ErrorStateType>();

  useEffect(() => {
    dispatch(getSupplierEmployee(supplierEmployeeId!));
    setLoading(false);
  }, [supplierEmployeeId, dispatch]);

  const handleSubmit = () => {
    //Nothing.
  };

  const handleRevealOrHidePin = async () => {
    if (pin) {
      setPin('');
      return;
    }

    setPinLoading(true);
    setPinError('');
    try {
      const pin = await supplierEmployeeApi.revealPin(supplierEmployeeId!);
      setPin(pin.data.pin!);
    } catch (err) {
      setErrorState(err, setPinError);
    }
    setPinLoading(false);
  };

  if (loading || supplierEmployeeState === FetchState.Loading) {
    return <Loader />;
  }

  if (supplierEmployeeState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="invitationTypeVisit.detailTitle" backTo={backTo} />
      <FormikDebounce<SupplierEmployeeDto>
        initialValues={supplierEmployee}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <Input
              name="name"
              label={t('invitationTypeVisit.name')}
              maxLength={100}
              required={true}
              disabled={true}
            />
            <Input
              name="lastName"
              label={t('invitationTypeVisit.lastName')}
              maxLength={100}
              required={true}
              disabled={true}
            />
            <Input
              name="supplier.name"
              label={t('invitationTypeVisit.company')}
              disabled={true}
            />
            <Input
              name="email"
              label={t('invitationTypeVisit.email')}
              maxLength={100}
              disabled={true}
            />
            <PhoneInput
              name="phone"
              label={t('invitationTypeVisit.phone')}
              disabled={true}
            />
            <LanguageSelect
              name="language"
              label={t('invitationTypeVisit.language')}
              languages={SUPPORTED_LANGUAGES}
              allowedLanguages={companyCustomization?.applicationLanguages}
              required={true}
              disabled={true}
            />

            <PinContainer>
              <QcsTextField
                label={t('supplierEmployee.pin')}
                value={pin || '******'}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                }}
                disabled={true}
              />
              <QcsLoadingButton
                onClick={handleRevealOrHidePin}
                loading={pinLoading}
              >
                {pin
                  ? t('supplierEmployee.hidePin')
                  : t('supplierEmployee.revealPin')}
              </QcsLoadingButton>
            </PinContainer>
            <SaveError error={pinError} />
          </FormContainer>
        </Form>
      </FormikDebounce>
      <SupplierEmployeeProtocol />
    </>
  );
};
