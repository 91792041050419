import { FC } from 'react';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';

interface Props {
  loading: boolean;
  error: boolean;
}

export const QcsProtocolLoader: FC<Props> = ({ loading, error }) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert />;
  }

  return null;
};
