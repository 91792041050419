import { RadioGroup, SxProps, Theme } from '@mui/material';
import { FC, FocusEventHandler } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  id?: string;
  name?: string;
  value?: any;
  defaultValue?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

export const QcsRadioGroup: FC<Props> = ({
  className,
  sx,
  id,
  name,
  value,
  defaultValue,
  onChange,
  children,
}) => {
  return (
    <RadioGroup
      className={className}
      sx={sx}
      id={id}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {children}
    </RadioGroup>
  );
};
