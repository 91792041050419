import { InvitationDto, InvitationDtoStateEnum } from '@qcs/safety-client';
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getInvitation,
  selectInvitation,
  selectInvitationState,
} from '../../store/entities/invitation';
import { invitationHistoryListActions } from '../../store/entities/invitationHistoryList';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { FetchState } from '../../store/fetchState';
import { ErrorAlert } from '../common/ErrorAlert';
import { Loader } from '../common/Loader';
import { InvitationEdit } from './Detail/InvitationEdit';
import { InvitationCreate } from './InvitationCreate';
import { InvitationPresentation } from './InvitationPresentation';
import {
  invitationListActions,
  selectInvitationList,
} from '../../store/entities/invitationList';
import { invitationsApi } from '../../utils/api';
import { invitationStateAudActions } from '../../store/entities/invitationStateAud';

export const InvitationDetail: FC = () => {
  const { invitationId } = useParams();
  const invitation = useAppSelector(selectInvitation);
  const invitationState = useAppSelector(selectInvitationState);
  const invitationList = useAppSelector(selectInvitationList);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Use goBack in browser navigator.
    return () => {
      dispatch(tourniquetTrainingActions.default());
      dispatch(tourniquetTrainingsActions.default());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getInvitation(invitationId!, changeData));
    dispatch(invitationHistoryListActions.reload(true));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  const changeData = async (data: InvitationDto) => {
    try {
      const auditResponse = await invitationsApi.getInvitationAudit(
        data.id!,
        0,
        1
      );
      dispatch(
        invitationStateAudActions.success(auditResponse.data.content?.[0])
      );
    } catch (err) {
      dispatch(invitationStateAudActions.error());
    }

    //Reload if found in grid with old state.
    //BE can change state if invitation is first open.
    const itemInList = invitationList.data?.content.find(
      (x) => x.id === data.id
    );
    if (itemInList) {
      if (
        itemInList.state !== data.state ||
        itemInList.invitationTrainingState !== data.invitationTrainingState
      ) {
        dispatch(invitationListActions.reload(true));
      }
    }

    return data;
  };

  if (loading || invitationState === FetchState.Loading) {
    return <Loader />;
  }

  if (invitationState === FetchState.Error) {
    return <ErrorAlert />;
  }

  if ((tourniquetTrainings?.trainings?.length ?? 0) > 0) {
    return <InvitationPresentation />;
  }

  if (invitation.state === InvitationDtoStateEnum.InProgress) {
    return <InvitationCreate invitationInProgress={invitation} />;
  }

  return <InvitationEdit />;
};
