import { Theme } from '@mui/material';
import { css, Global } from '@emotion/react';
import { FC } from 'react';
import { mediaForFixedHeader } from './LayoutStyles';

const styles = (theme: Theme) => css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  ${mediaForFixedHeader(theme)} {
    #root {
      position: fixed;
      height: 100%;
      width: 100%;
    }
  }

  /* Hide arrows on number type. */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Hide arrows on number type. */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const GlobalStyles: FC = () => {
  return <Global styles={styles} />;
};
