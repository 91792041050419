import { UserDtoRolesEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import {
  hasRole,
  isExternal,
  isQcsAdminMode,
  isRemote,
  isTourniquet,
} from '../../utils/roles';
import { getAccessToken } from '../../utils/storage';
import { Dashboard } from '../dashboard/Dashboard';
import { EmergencyDetailRemote } from '../emergency/EmergencyDetailRemote';
import { Invitation } from '../invitation/Invitation';
import { QcsAdminCompany } from '../qcsAdmin/company/QcsAdminCompany';
import { TourniquetRoutes } from '../tourniquet/TourniquetRoutes';
import { Login } from './Login';

interface Props {
  roles?: UserDtoRolesEnum[];
  children: React.ReactNode;
}

export const AuthRoute: FC<Props> = ({ roles, children }) => {
  const identity = useAppSelector(selectIdentity);
  const token = getAccessToken();

  if (!token) {
    return <Login />;
  }

  if (!hasRole(identity.roles, roles)) {
    if (isQcsAdminMode(identity)) {
      return <QcsAdminCompany />;
    }

    if (isTourniquet(identity)) {
      return <TourniquetRoutes />;
    }

    if (isExternal(identity)) {
      return <Invitation />;
    }

    if (isRemote(identity)) {
      return <EmergencyDetailRemote />;
    }

    return <Dashboard />;
  }

  return <>{children}</>;
};
