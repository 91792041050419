import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { qcsProtocolReplaceToEditor } from '../../layout/QcsProtocol';

const HtmlContent = styled.div(() => ({
  '.text-tiny': {
    fontSize: '.7em',
  },
  '.text-small': {
    fontSize: '.85em',
  },
  '.text-big': {
    fontSize: '1.4em',
  },
  '.text-huge': {
    fontSize: '1.8em',
  },
  ul: {
    paddingLeft: '2rem',
  },
  ol: {
    paddingLeft: '2rem',
  },
  blockquote: {
    overflow: 'hidden',
    paddingaRight: '1.5em',
    paddingLeft: '1.5em',
    marginLeft: 0,
    marginRight: 0,
    fontStyle: 'italic',
    borderLeft: 'solid 5px hsl(0, 0%, 80%)',
  },
  '.image': {
    display: 'table',
    clear: 'both',
    textAlign: 'center',
    margin: '0.9em auto',
    minWidth: '50px',
  },
  '.image img': {
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
    minWidth: '100%',
  },
  '.image-inline': {
    display: 'inline-flex',
    maxWidth: '100%',
    alignItems: 'flex-start',
  },
  '.image-inline picture': {
    display: 'flex',
  },
  '.image-inline picture, .image-inline img': {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '100%',
  },
  '.image.image_resized': {
    maxWidth: '100%',
    display: 'block',
    boxSizing: 'border-box',
  },
  '.image.image_resized img': {
    width: '100%',
  },
  '.image.image_resized > figcaption': {
    display: 'block',
  },
  '.image > figcaption': {
    display: 'table-caption',
    captionSide: 'bottom',
    wordBreak: 'break-word',
    backgroundColor: '#f7f7f7',
    padding: '.6em',
    fontSize: '.75em',
    outlineOffset: '-1px',
  },
  '.image-style-block-align-left, .image-style-block-align-right': {
    maxWidth: 'calc(100% - 1.5em)',
  },
  '.image-style-align-left, .image-style-align-right': {
    clear: 'none',
  },
  '.image-style-side': {
    float: 'right',
    marginLeft: '1.5em',
    maxWidth: '50%',
  },
  '.image-style-align-left': {
    float: 'left',
    marginRight: '1.5em',
  },
  '.image-style-align-center': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.image-style-align-right': {
    float: 'right',
    marginLeft: '1.5em',
  },
  '.image-style-block-align-right': {
    marginRight: 0,
    marginLeft: 'auto',
  },
  '.image-style-block-align-left': {
    marginLeft: 0,
    marginRight: 'auto',
  },
  'p + .image-style-align-left, p + .image-style-align-right, p + .image-style-side':
    {
      marginTop: 0,
    },
  '.image-inline.image-style-align-left, .image-inline.image-style-align-right':
    {
      marginTop: '.75em',
      marginBottom: '.75em',
    },
  '.image-inline.image-style-align-left': {
    marginRight: '.75em',
  },
  '.image-inline.image-style-align-right': {
    marginLeft: '.75em',
  },
  '.table': {
    margin: '0.9em auto',
    display: 'table',
  },
  '.table table': {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
    height: '100%',
    border: '1px double hsl(0, 0%, 70%)',
    overflow: 'hidden',
  },
  '.table table td, .table table th': {
    minWidth: '2em',
    padding: '.4em',
    border: '1px solid hsl(0, 0%, 75%)',
  },
  '.table table th': {
    fontWeight: 'bold',
    background: 'hsla(0, 0%, 0%, 5%)',
    textAlign: 'left',
  },
  '.table .ck-table-resized': {
    tableLayout: 'fixed',
  },
  '.table td, .table th': {
    position: 'relative',
  },
}));

interface Props {
  html: string;
}

export const HtmlViewer: FC<Props> = ({ html }) => {
  const htmlMemo = useMemo(() => {
    //Repair all img src.
    const fixedHtml = qcsProtocolReplaceToEditor(html);

    return <HtmlContent dangerouslySetInnerHTML={{ __html: fixedHtml }} />;
  }, [html]);

  return htmlMemo;
};
