import React, { useState } from 'react';
import styled from '@emotion/styled';
import { LanguageDto } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { StyledQcsTable } from '../common/grid/Grid';
import {
  getQuestionnaireVersion,
  selectQuestionnaireVersion,
  selectQuestionnaireVersionLanguages,
  selectQuestionnaireVersionState,
} from '../../store/entities/questionnaireVersion';
import { AddLanguageModal } from './AddLanguageModal';
import { FetchState } from '../../store/fetchState';
import { QcsTableBody } from '../common/basic/QcsTableBody';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTableHead } from '../common/basic/QcsTableHead';
import { QcsTableRow } from '../common/basic/QcsTableRow';
import { QcsPaper } from '../common/basic/QcsPaper';
import { QcsTableContainer } from '../common/basic/QcsTableContainer';
import { GridEmpty } from '../common/grid/GridEmpty';
import { GridError } from '../common/grid/GridError';
import { GridLoading } from '../common/grid/GridLoading';
import { FaIcon } from '../common/FaIcon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LanguageDeleteModal } from './LanguageDeleteModal';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  marginBottom: '0.5rem',
}));

export const QuestionnaireLanguages: FC = () => {
  const { t } = useTranslation();
  const questionnaireVersionLanguages = useAppSelector(
    selectQuestionnaireVersionLanguages
  );
  const questionnaireVersionState = useAppSelector(
    selectQuestionnaireVersionState
  );
  const questionnaireVersion = useAppSelector(selectQuestionnaireVersion);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { questionnaireId, questionnaireVersionId } = useParams();
  const [adding, setAdding] = useState(false);
  const [languageToCopy, setLanguageToCopy] = useState<LanguageDto | null>(
    null
  );
  const [languageToDelete, setLanguageToDelete] = useState<string>('');

  const loadQuestionnaireVersion = () => {
    dispatch(getQuestionnaireVersion(questionnaireVersionId!));
  };

  const handleRowClick = (item: LanguageDto) => () => {
    navigate(
      `/questionnaire/${questionnaireId}/${questionnaireVersionId}/${item.code}`
    );
  };

  const handleAddClick = () => {
    setAdding(true);
  };

  const handleCloseAdding = () => {
    setAdding(false);
    setLanguageToCopy(null);
  };

  const handleAddVersionCopy =
    (language: LanguageDto) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setLanguageToCopy(language);
    };

  const deleteLanguage =
    (languageCode: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setLanguageToDelete(languageCode);
    };

  const handleCloseDeleteLanguageModal = () => {
    setLanguageToDelete('');
  };
  if (!questionnaireVersionLanguages) {
    return null;
  }

  return (
    <>
      <AddLanguageModal
        open={adding || !!languageToCopy}
        languageToCopy={languageToCopy}
        onClose={handleCloseAdding}
        reloadQuestionnaireVersion={loadQuestionnaireVersion}
      />
      <LanguageDeleteModal
        open={!!languageToDelete}
        onClose={handleCloseDeleteLanguageModal}
        languageCode={languageToDelete}
        reloadTrainingVersionList={loadQuestionnaireVersion}
      />
      <HeaderContainer>
        <QcsTypography variant="h5">
          {t('questionnaire.languages')}
        </QcsTypography>
        <QcsButton
          disabled={!questionnaireVersion.editable}
          variant="contained"
          onClick={handleAddClick}
        >
          {t('questionnaire.addLanguage')}
        </QcsButton>
      </HeaderContainer>

      <QcsPaper>
        <QcsTableContainer>
          <StyledQcsTable>
            <QcsTableHead>
              <QcsTableRow>
                <QcsTableCell>{t('questionnaire.language')}</QcsTableCell>
                <QcsTableCell sx={{ width: '15rem' }}>
                  {t('questionnaire.copy.addLanguage')}
                </QcsTableCell>
                <QcsTableCell sx={{ width: '15rem' }} align="center">
                  {t('training.deleteLanguage')}
                </QcsTableCell>
              </QcsTableRow>
            </QcsTableHead>
            <QcsTableBody>
              {questionnaireVersionState === FetchState.Loaded &&
                questionnaireVersionLanguages.map((item, index) => (
                  <QcsTableRow
                    key={index}
                    hover={true}
                    onClick={handleRowClick(item)}
                  >
                    <QcsTableCell>
                      <img src={'/flags/' + item.flag + '.svg'} height="20" />{' '}
                      {item.name}
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton
                        disabled={!questionnaireVersion.editable}
                        onClick={handleAddVersionCopy(item)}
                      >
                        <FaIcon icon={faCopy} />
                      </QcsButton>
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton
                        disabled={!questionnaireVersion.editable}
                        onClick={deleteLanguage(item.code)}
                      >
                        <DeleteForeverIcon
                          color={
                            !questionnaireVersion.editable ? undefined : 'error'
                          }
                        />
                      </QcsButton>
                    </QcsTableCell>
                  </QcsTableRow>
                ))}
            </QcsTableBody>
          </StyledQcsTable>
          {questionnaireVersionState === FetchState.Loading ? (
            <GridLoading />
          ) : questionnaireVersionState === FetchState.Error ? (
            <GridError />
          ) : questionnaireVersionLanguages.length === 0 ? (
            <GridEmpty />
          ) : null}
        </QcsTableContainer>
      </QcsPaper>
    </>
  );
};
