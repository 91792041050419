import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../common/ErrorAlert';
import { Box, Button } from '@mui/material';

const ErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ErrorAlert />
      <Button sx={{ mt: 2 }} onClick={() => (window.location.href = '/')}>
        {t('common.backToHome')}
      </Button>
    </Box>
  );
};

export default ErrorFallback;
