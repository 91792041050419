import { FC, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import { QcsMenuItem } from '../basic/QcsMenuItem';
import { SelectChangeEvent, SxProps, useTheme } from '@mui/material';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { LanguageDto } from '@qcs/safety-client';
import { SelectItem } from './Select';

interface Props {
  className?: string;
  sx?: SxProps;
  value?: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  languages?: LanguageDto[];
  allowedLanguages?: string[];
  notAllowedLanguages?: string[];
  hideName?: boolean;
  onChange: (event: SelectChangeEvent<any>) => void;
}

export const LanguageSelectWithoutFormik: FC<Props> = ({
  className,
  sx,
  value,
  label,
  disabled,
  required,
  languages,
  allowedLanguages,
  notAllowedLanguages,
  hideName,
  onChange,
}) => {
  const flagsMemo = useMemo(() => {
    if (!languages) {
      return null;
    }

    let flags = languages.slice().sort((a, b) => a.name.localeCompare(b.name));

    if (allowedLanguages) {
      flags = flags.filter((x) => allowedLanguages.includes(x.code));
    }

    if (notAllowedLanguages) {
      flags = flags.filter((x) => !notAllowedLanguages.includes(x.code));
    }

    return flags.map((flag) => (
      <QcsMenuItem key={flag.code} value={flag.code}>
        <SelectItem>
          <img
            src={'/flags/' + flag.flag + '.svg'}
            height="20"
            loading="lazy"
          />
          <div>{hideName ? <>&nbsp;</> : flag.name}</div>
        </SelectItem>
      </QcsMenuItem>
    ));
  }, [languages, allowedLanguages, notAllowedLanguages, hideName]);

  const theme = useTheme();

  return (
    <QcsFormControl>
      <QcsInputLabel required={required} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        required={required}
        className={className}
        sx={{ backgroundColor: theme.palette.common.white, ...sx }}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {flagsMemo}
      </QcsSelect>
    </QcsFormControl>
  );
};
