import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CheckOptionsResponse } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { companySettingsApi } from '../../utils/api';

type State = BaseType<CheckOptionsResponse>;

const initialState: State = {
  state: FetchState.None,
  data: {
    canCreateEstablishment: false,
    canCreateUser: false,
    canCreateTraining: false,
  },
};

export const companyChecksSlice = baseSlice({
  name: 'companyChecks',
  initialState,
});

export const selectCompanyChecksState = (state: RootState) =>
  state.companyChecks.state;
export const selectCompanyChecksCanCreateEstablishment = (state: RootState) =>
  state.companyChecks.data.canCreateEstablishment ?? false;
export const selectCompanyChecksCanCreateUser = (state: RootState) =>
  state.companyChecks.data.canCreateUser ?? false;
export const selectCompanyChecksCanCreateTraining = (state: RootState) =>
  state.companyChecks.data.canCreateTraining ?? false;
export const companyChecksActions = companyChecksSlice.actions;

export const getCompanyChecks =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companySettingsApi.checkOptions(companyId);
    };

    getItem({
      dispatch,
      actions: companyChecksActions,
      getData: handleGetData,
    });
  };
