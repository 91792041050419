import {
  SafetyEquipmentDto,
  SafetyEquipmentDtoTypeEnum,
} from '@qcs/safety-client';
import { FC, useMemo } from 'react';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { GridList } from '../../../common/grid/GridList';
import { useTheme } from '@mui/material';
import { getLangNameObj } from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';

interface SafetyEquipmentGridData extends SafetyEquipmentDto {
  personsCount: number;
}

export const SafetyEquipmentTable: FC = () => {
  const { t, i18n, invitation } = useInvitation();

  const theme = useTheme();

  const list = useMemo(
    (): SafetyEquipmentGridData[] =>
      ([] as SafetyEquipmentDto[])
        .concat(
          ...(invitation.workplaces ?? []).map(
            (workplace) => workplace.safetyEquipments ?? []
          )
        )
        .map((safetyEquipment) => {
          const workplaces = (invitation.workplaces ?? [])
            .filter((workplace) =>
              workplace?.safetyEquipments?.some(
                (se) => se.id === safetyEquipment.id
              )
            )
            .map((workplace) => workplace?.workplace?.id);
          const personsCount = (invitation.persons ?? []).filter((person) =>
            person.workplaces?.some((workplace) =>
              workplaces.includes(workplace.id)
            )
          ).length;

          return {
            ...safetyEquipment,
            personsCount,
          };
        }),
    [invitation.workplaces, invitation.persons]
  );

  const handleRenderData = (item: SafetyEquipmentGridData) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell
          sx={{
            color:
              item.type === SafetyEquipmentDtoTypeEnum.Owned
                ? theme.palette.warning.main
                : undefined,
          }}
        >
          {t('safetyEquipment.types.' + item.type)}
        </QcsTableCell>
        <QcsTableCell>{item.personsCount}</QcsTableCell>
      </>
    );
  };

  return (
    <GridList<SafetyEquipmentGridData>
      headers={[
        { captionStr: 'invitation.safetyEquipment.name' },
        { captionStr: 'invitation.safetyEquipment.type' },
        { captionStr: 'invitation.safetyEquipment.count' },
      ]}
      data={list}
      renderData={handleRenderData}
      search={false}
      hidePagination={true}
    />
  );
};
