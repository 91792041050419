import styled from '@emotion/styled';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsLoadingButton } from './basic/QcsLoadingButton';
import { QcsTypography } from './basic/QcsTypography';

const SectionHeaderContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '0.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

interface Props {
  title?: string | null;
  addText?: string;
  addDisabled?: boolean;
  handleAddClick?: () => void;
  isLoading?: boolean;
  variant?: Variant;
}

export const SectionHeader: FC<Props> = ({
  title,
  addText,
  addDisabled,
  handleAddClick,
  isLoading,
  variant = 'h4',
}) => {
  const { t } = useTranslation();

  return (
    <SectionHeaderContainer>
      {!!title && (
        <QcsTypography variant={variant}>
          {t(title)}
        </QcsTypography>
      )}
      {!!handleAddClick && !!addText && (
        <QcsLoadingButton
          variant="contained"
          onClick={handleAddClick}
          disabled={addDisabled}
          loading={isLoading}
        >
          {t(addText)}
        </QcsLoadingButton>
      )}
    </SectionHeaderContainer>
  );
};
