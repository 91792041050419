import { TableContainer, SxProps } from '@mui/material';
import { FC } from 'react';
import { QcsPaper } from './QcsPaper';

interface Props {
  className?: string;
  sx?: SxProps;
  children: React.ReactNode;
}

export const QcsTableContainer: FC<Props> = ({ className, sx, children }) => {
  return (
    <QcsPaper>
      <TableContainer className={className} sx={sx}>
        {children}
      </TableContainer>
    </QcsPaper>
  );
};
