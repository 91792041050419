import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { CompanyBusinessSettings } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { companySettingsApi } from '../../utils/api';

type State = BaseType<CompanyBusinessSettings>;

const initialState: State = {
  state: FetchState.None,
  data: {
    maxEstablishments: undefined,
    maxUsers: undefined,
    maxTraining: undefined,
    passRisksEnabled: false,
    smsEnabled: false,
    banner: '',
    bannerUrl: '',
    primaryColor: '',
    secondaryColor: '',
    licenseValidFrom: '',
    licenseValidTo: '',
    applicationLanguages: [],
    testLanguages: [],
    defaultLanguage: '',
  },
};

export const businessSettingsSlice = baseSlice({
  name: 'businessSettings',
  initialState,
});

export const selectBusinessSettingsState = (state: RootState) =>
  state.businessSettings.state;
export const selectBusinessSettings = (state: RootState) =>
  state.businessSettings.data;
export const businessSettingsActions = businessSettingsSlice.actions;

export const getBusinessSettings =
  (companyId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return companySettingsApi.getBusinessSettings(companyId);
    };

    getItem({
      dispatch,
      actions: businessSettingsActions,
      getData: handleGetData,
    });
  };
