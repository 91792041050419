import { AnswerResponseTypeEnum } from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectTourniquetIdentity } from '../../store/entities/tourniquetIdentity';
import { selectTourniquetQuestionnaire } from '../../store/entities/tourniquetQuestionnaire';
import {
  selectTourniquetTraining,
  tourniquetTrainingActions,
} from '../../store/entities/tourniquetTraining';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { selectTourniquetTrainingSlides } from '../../store/entities/tourniquetTrainingSlides';
import { invitationsApi } from '../../utils/api';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { HtmlViewer } from '../common/html/HtmlViewer';
import { SignUser } from '../common/SignUser';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import { TourniquetCaption } from './TourniquetCaption';
import { setErrorSnacks } from '../../utils/error';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { tourniquetLoadData } from './tourniquetFunctions';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { TourniquetSlideCard } from './TourniquetStyles';

interface Props {
  onEndTraining: (success?: boolean) => void;
}

export const TourniquetTrainingOverview: FC<Props> = ({ onEndTraining }) => {
  const { t } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);
  const tourniquetTrainingSlides = useAppSelector(
    selectTourniquetTrainingSlides
  );
  const tourniquetQuestionnaire = useAppSelector(selectTourniquetQuestionnaire);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const correctCount = tourniquetQuestionnaire.questions.filter(
    (x) => x.answerResponse === AnswerResponseTypeEnum.Correct
  ).length;
  const totalCount = tourniquetQuestionnaire.questions.length;
  const successRate = Math.floor((correctCount / totalCount) * 100);
  const isOk = successRate >= (tourniquetQuestionnaire.successRate ?? 0);

  const handleClose = async () => {
    if ((tourniquetTrainings.trainings?.length ?? 0) < 2) {
      onEndTraining(true);
      return;
    }

    setLoading(true);
    try {
      const newTrainings = tourniquetTrainings.trainings!.filter(
        (x) => x.training?.id !== tourniquetTraining.training.id
      );

      dispatch(
        tourniquetTrainingsActions.success({
          ...tourniquetTrainings,
          trainings: newTrainings,
        })
      );

      await tourniquetLoadData(
        tourniquetIdentity.data!.invitation!.id!,
        newTrainings[0].training!.id!,
        companyCustomization!,
        identityCompanyId,
        tourniquetIdentity.allPersons!,
        dispatch
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setLoading(false);
  };

  const handleGoToTraining = () => {
    //Switch to questionnaire.
    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        index: 0,
        mode: 'training',
      })
    );
  };

  const handleGoToQuestionnaire = () => {
    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        index: 0,
        mode: 'questionnaire',
        answerResponse: undefined,
      })
    );
  };

  const handleSignSave = async (userId: string, documentId: string) => {
    if (!tourniquetIdentity.playground) {
      await invitationsApi.signQuestionnaire(
        tourniquetIdentity.data!.invitation!.id!,
        {
          person: userId,
          signatureDocumentId: documentId,
          training: tourniquetTraining.training.id,
          trainingLanguageVariant: tourniquetTrainingSlides.language!.code,
          questionnaireLanguageVariant: tourniquetQuestionnaire.language?.code,
        }
      );
    }

    dispatch(
      tourniquetTrainingActions.success({
        ...tourniquetTraining,
        signQuestionnaire: tourniquetTraining.signQuestionnaire.filter(
          (x) => x.id !== userId
        ),
      })
    );
  };

  return (
    <TourniquetButtonContent
      onAbort={
        isOk && tourniquetTraining.signQuestionnaire.length === 0
          ? undefined
          : onEndTraining
      }
      mobileGap={true}
    >
      <TourniquetSlideCard>
        <QcsBox>
          <TourniquetCaption title={t('tourniquet.trainingSignTitle')} />
          <QcsTypography sx={{ mb: 1 }} align="center">
            {t('tourniquet.yourScore')}
            {': '}
            {successRate}%
          </QcsTypography>
          {!!tourniquetQuestionnaire.successRate && (
            <QcsTypography
              variant="caption"
              sx={{ mb: 1, display: 'block' }}
              align="center"
            >
              {t('tourniquet.minimalRequiredScore')}
              {': '}
              {tourniquetQuestionnaire.successRate}%
            </QcsTypography>
          )}
          {isOk ? (
            <>
              <QcsAlert
                severity="success"
                sx={{ margin: '0 auto', marginBottom: '1rem' }}
              >
                {t('tourniquet.successQuestionnaire')}
              </QcsAlert>
              {!!tourniquetTrainingSlides.afterTrainingContent && (
                <QcsBox sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <HtmlViewer
                    html={tourniquetTrainingSlides.afterTrainingContent}
                  />
                </QcsBox>
              )}
              {tourniquetTraining.signQuestionnaire.length !== 0 && (
                <SignUser
                  playground={tourniquetIdentity.playground}
                  userList={tourniquetTraining.signQuestionnaire}
                  save={handleSignSave}
                />
              )}
              {tourniquetTraining.signQuestionnaire.length === 0 && (
                <QcsLoadingButton
                  variant="contained"
                  sx={{ margin: '0 auto', display: 'block' }}
                  onClick={handleClose}
                  loading={loading}
                >
                  {t('common.close')}
                </QcsLoadingButton>
              )}
            </>
          ) : (
            <>
              <QcsAlert severity="error" sx={{ margin: '0 auto' }}>
                {t('tourniquet.wrongQuestionaire')}
              </QcsAlert>
              <QcsBox
                sx={{
                  width: 'max-content',
                  margin: '0 auto',
                  marginTop: '1rem',
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                {
                  //No training exists if you run questionnaire as playground.
                  tourniquetTrainingSlides.slides.length > 0 && (
                    <QcsButton variant="outlined" onClick={handleGoToTraining}>
                      {t('tourniquet.retryTraining')}
                    </QcsButton>
                  )
                }
                <QcsButton variant="outlined" onClick={handleGoToQuestionnaire}>
                  {t('tourniquet.retryQuestionnaire')}
                </QcsButton>
              </QcsBox>
            </>
          )}
        </QcsBox>
      </TourniquetSlideCard>
    </TourniquetButtonContent>
  );
};
