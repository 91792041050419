import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SubmitButton } from '../common/form/SubmitButton';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { ActivityAutocomplete } from '../common/form/ActivityAutocomplete';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { WorkplaceAutocomplete } from '../common/form/WorkplaceAutocomplete';
import { InvitationCreateForm } from '../../models/invitation';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep2: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        establishments: validations.arrayNotEmpty(t),
        workplaces: validations.arrayNotEmpty(t),
        activities: validations.arrayNotEmpty(t),
      })}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <FormContainer>
            <EstablishmentAutocomplete
              multiple
              name="establishments"
              label={t('invitation.establishments')}
              required
            />

            <WorkplaceAutocomplete
              multiple
              name="workplaces"
              label={t('invitation.workplaces')}
              required
              estabilishments={values.establishments?.map((x) => x.id)}
              showEstabilishmentName={true}
            />

            <ActivityAutocomplete
              multiple
              name="activities"
              label={t('invitation.activities')}
              required
            />

            <InvitationButtonsContainer>
              <QcsButton variant="outlined" onClick={handleGoBack}>
                {t('common.back')}
              </QcsButton>
              <div style={{ width: '100%' }} />
              <InvitationButton type="DELETE" customEvent={onDelete} />
              <InvitationButton type="SAVE" />
              <SubmitButton text={t('common.nextStep')} />
            </InvitationButtonsContainer>
          </FormContainer>
        </Form>
      )}
    </FormikDebounce>
  );
};
