import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { HEADER_HEIGHT } from '../../utils/constants';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';

export const HeaderContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 1rem',
  height: HEADER_HEIGHT,
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[3],
}));

export const HeaderLogoNavLink = styled(NavLink)(({ theme }) => ({
  height: '20px',
  marginRight: '.5rem',
  [theme.breakpoints.up('sm')]: {
    height: '40px',
  },
  [theme.breakpoints.up('md')]: {
    height: '50px',
  },
}));

export const HeaderLogo = styled.img(() => ({
  height: '100%',
}));

export const HeaderMobileMenuButton = styled(QcsButton)(({ theme }) => ({
  marginLeft: '-0.5rem',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const HeaderUserMenuButton = styled(QcsButton)(() => ({
  marginLeft: 'auto',
}));

export const StyledQcsMenuItem = styled(QcsMenuItem)(({ theme }) => ({
  paddingRight: '2rem',
  '&.active': {
    borderRight: '0.4rem solid',
    borderColor: theme.palette.primary.main,
    paddingRight: '1.6rem',
    backgroundColor: theme.palette.grey[200],
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));
