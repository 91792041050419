import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { InvitationTypeVisitListResponse } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { invitationTypeVisitsApi } from '../../utils/api';

export type InvitationTypeVisitListState = ListType<
  InvitationTypeVisitListResponse,
  ListFilter
>;

const initialState: InvitationTypeVisitListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'responsiblePerson.lastName',
    sortDesc: false,
  },
};

export const invitationTypeVisitListSlice = listSlice({
  name: 'invitationTypeVisitList',
  initialState,
});

export const selectInvitationTypeVisitList = (state: RootState) =>
  state.invitationTypeVisitList;
export const invitationTypeVisitListActions =
  invitationTypeVisitListSlice.actions;

export const getInvitationTypeVisitList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectInvitationTypeVisitList(storeState);

    const handleGetData = (size: number) => {
      return invitationTypeVisitsApi.queryInvitationTypeVisit(
        companyId,
        data.query.q,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: invitationTypeVisitListActions,
      getData: handleGetData,
    });
  };
