export const MIN_HEIGHT_FOR_FIXED_HEADER = '800px';
export const HEADER_HEIGHT = '90px';

export const CACHE_MINUTES = 5;
export const DEBOUNCE_TIMEOUT_MS = 500;
export const QUICK_DEBOUNCE_TIMEOUT_MS = 250;
export const TOURNIQUET_DIALOG_AUTOCLOSE_MS = 5000;
export const INVITATION_AUDIT_TIMEOUT_MS = 5000;

export const PAGINATION_OPTIONS = [10, 25, 50];
export const PAGINATION_DEFAULT = 10;
export const AUTOCOMPLETE_MAX_ITEMS = 50;

export const SNACKBAR_MAX_SNACK = 3;
export const SNACKBAR_AUTO_HIDE_DURATION = 3000;

export const PROTOCOL = 'https://qcs/';
export const PROTOCOL_MEDIA = PROTOCOL + 'media/';
export const SECURED_MEDIA = 'secured-media/';
export const PROTOCOL_SECURED_MEDIA = PROTOCOL_MEDIA + SECURED_MEDIA;
export const PROTOCOL_RISK = PROTOCOL + 'risk/';
export const YOUTUBE_PARAMETERS =
  '?autoplay=1&modestbranding=1&rel=0&iv_load_policy=3&fs=0';

export const QC_SOLUTIONS_URL = 'https://qcsolutions.cz/';

export const SUPPLIER_VISIT_FAKE_ID = 'VISIT';
