import {
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectIdentity } from '../../../store/entities/identity';
import {
  getOptionsSupplierEmployee,
  optionsSupplierEmployeeActions,
  selectOptionsSupplierEmployee,
  selectOptionsSupplierEmployeeState,
} from '../../../store/entities/optionsSupplierEmployee';
import { FetchState } from '../../../store/fetchState';
import {
  getFirstLastNameObj,
  getFirstLastNameSupplierEmployee,
} from '../../../utils/format';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsAutocompleteWithoutFormik } from '../form/QcsAutocompleteWithoutFormik';

interface Props {
  supplierId?: string;
  role?: SupplierEmployeeDtoRoleEnum;
  state?: SupplierEmployeeDtoStateEnum[];
  value?: SupplierEmployeeDto;
  onChange: (value?: SupplierEmployeeDto) => void;
}

export const GridFilterSupplierEmployee: FC<Props> = ({
  supplierId,
  role,
  state,
  value,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const optionsSupplierEmployee = useAppSelector(selectOptionsSupplierEmployee);
  const optionsSupplierEmployeeState = useAppSelector(
    selectOptionsSupplierEmployeeState
  );
  const identity = useAppSelector(selectIdentity);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    if (!!supplierId || !!identity.supplier) {
      dispatch(
        getOptionsSupplierEmployee(
          supplierId ? supplierId : identity.supplier,
          search,
          role,
          state,
          cancelToken
        )
      );
    } else {
      dispatch(optionsSupplierEmployeeActions.default());
    }
  };

  const handleChange = (
    value?: SupplierEmployeeDto | SupplierEmployeeDto[] | null
  ) => {
    onChange((value as SupplierEmployeeDto) ?? undefined);
  };

  if (!supplierId && !identity.supplier)
    return (
      <QcsBox sx={{ width: '100%', overflow: 'hidden' }}>
        <QcsAlert severity="info">
          {t('invitation.chooseSupplierFirst')}
        </QcsAlert>
      </QcsBox>
    );

  return (
    <QcsAutocompleteWithoutFormik
      options={optionsSupplierEmployee}
      loading={optionsSupplierEmployeeState === FetchState.Loading}
      loadingError={optionsSupplierEmployeeState === FetchState.Error}
      loadOptions={loadOptions}
      value={value}
      getOptionId={(option: SupplierEmployeeDto) => option.id!}
      getOptionName={getFirstLastNameObj}
      getValueIfNotFound={getFirstLastNameSupplierEmployee}
      onChange={handleChange}
    />
  );
};
