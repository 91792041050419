/* tslint:disable */
/* eslint-disable */
/**
 * qcs-be
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: local
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActivityDocumentReference
 */
export interface ActivityDocumentReference {
    /**
     * 
     * @type {string}
     * @memberof ActivityDocumentReference
     */
    'activityId': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDocumentReference
     */
    'documentId': string;
}
/**
 * 
 * @export
 * @interface ActivityDto
 */
export interface ActivityDto {
    /**
     * non empty indicates whether certificates are required or not
     * @type {Array<string>}
     * @memberof ActivityDto
     */
    'certificates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityDto
     */
    'oneTimeEntry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityDto
     */
    'riskPass': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityDto
     */
    'riskRequired': boolean;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'state': ActivityDtoStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityDto
     */
    'stateChangeEnabled'?: boolean;
}

export const ActivityDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ActivityDtoStateEnum = typeof ActivityDtoStateEnum[keyof typeof ActivityDtoStateEnum];

/**
 * 
 * @export
 * @interface AddLanguageVariant
 */
export interface AddLanguageVariant {
    /**
     * 
     * @type {LanguageDto}
     * @memberof AddLanguageVariant
     */
    'language': LanguageDto;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * Name of the city
     * @type {string}
     * @memberof AddressDto
     */
    'city'?: string;
    /**
     * Country of the address
     * @type {string}
     * @memberof AddressDto
     */
    'country'?: string;
    /**
     * Postal code of the given address
     * @type {string}
     * @memberof AddressDto
     */
    'postalCode'?: string;
    /**
     * Street + list number / orientation number
     * @type {string}
     * @memberof AddressDto
     */
    'street'?: string;
}
/**
 * 
 * @export
 * @interface AnswerDto
 */
export interface AnswerDto {
    /**
     * 
     * @type {string}
     * @memberof AnswerDto
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerDto
     */
    'correct': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnswerDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnswerDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface AnswerResponse
 */
export interface AnswerResponse {
    /**
     * 
     * @type {string}
     * @memberof AnswerResponse
     */
    'type'?: AnswerResponseTypeEnum;
}

export const AnswerResponseTypeEnum = {
    Correct: 'CORRECT',
    Incorrect: 'INCORRECT'
} as const;

export type AnswerResponseTypeEnum = typeof AnswerResponseTypeEnum[keyof typeof AnswerResponseTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationInfoResult
 */
export interface ApplicationInfoResult {
    /**
     * 
     * @type {BuildInfo}
     * @memberof ApplicationInfoResult
     */
    'build'?: BuildInfo;
    /**
     * 
     * @type {GitInfo}
     * @memberof ApplicationInfoResult
     */
    'git'?: GitInfo;
}
/**
 * 
 * @export
 * @interface ApproveRiskRequest
 */
export interface ApproveRiskRequest {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ApproveRiskRequest
     */
    'workplacesWithRisks'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface BatchPageUpdate
 */
export interface BatchPageUpdate {
    /**
     * 
     * @type {Array<CompanyPageDto>}
     * @memberof BatchPageUpdate
     */
    'pages': Array<CompanyPageDto>;
}
/**
 * 
 * @export
 * @interface BuildInfo
 */
export interface BuildInfo {
    /**
     * 
     * @type {string}
     * @memberof BuildInfo
     */
    'artifact'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildInfo
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface CertificateDto
 */
export interface CertificateDto {
    /**
     * 
     * @type {boolean}
     * @memberof CertificateDto
     */
    'expiryDateRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateDto
     */
    'state'?: CertificateDtoStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CertificateDto
     */
    'stateChangeEnabled'?: boolean;
}

export const CertificateDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CertificateDtoStateEnum = typeof CertificateDtoStateEnum[keyof typeof CertificateDtoStateEnum];

/**
 * 
 * @export
 * @interface CheckOptionsResponse
 */
export interface CheckOptionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckOptionsResponse
     */
    'canCreateEstablishment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CheckOptionsResponse
     */
    'canCreateTraining'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CheckOptionsResponse
     */
    'canCreateUser'?: boolean;
}
/**
 * 
 * @export
 * @interface CommitInfoResult
 */
export interface CommitInfoResult {
    /**
     * 
     * @type {string}
     * @memberof CommitInfoResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommitInfoResult
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface CompanyBusinessSettings
 */
export interface CompanyBusinessSettings {
    /**
     * currently supported values are \'cs\' and \'en\'
     * @type {Array<string>}
     * @memberof CompanyBusinessSettings
     */
    'applicationLanguages': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'banner'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'bannerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'defaultLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'licenseValidFrom': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'licenseValidTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyBusinessSettings
     */
    'maxEstablishments'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyBusinessSettings
     */
    'maxTraining'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyBusinessSettings
     */
    'maxUsers'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBusinessSettings
     */
    'passRisksEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'primaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'secondaryColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyBusinessSettings
     */
    'smsEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyBusinessSettings
     */
    'subDomain'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyBusinessSettings
     */
    'testLanguages': Array<string>;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof CompanyDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {ContactPersonDto}
     * @memberof CompanyDto
     */
    'contact': ContactPersonDto;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'ico': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'state'?: CompanyDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'subDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'vat'?: string;
}

export const CompanyDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    PreActivated: 'PRE_ACTIVATED'
} as const;

export type CompanyDtoStateEnum = typeof CompanyDtoStateEnum[keyof typeof CompanyDtoStateEnum];

/**
 * 
 * @export
 * @interface CompanyListResponse
 */
export interface CompanyListResponse {
    /**
     * 
     * @type {AddressDto}
     * @memberof CompanyListResponse
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {ContactPersonDto}
     * @memberof CompanyListResponse
     */
    'contact'?: ContactPersonDto;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'ico'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'licenseValidEnd'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'licenseValidFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'state'?: CompanyListResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'subDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyListResponse
     */
    'vat'?: string;
}

export const CompanyListResponseStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    PreActivated: 'PRE_ACTIVATED'
} as const;

export type CompanyListResponseStateEnum = typeof CompanyListResponseStateEnum[keyof typeof CompanyListResponseStateEnum];

/**
 * 
 * @export
 * @interface CompanyPageDto
 */
export interface CompanyPageDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyPageDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPageDto
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyPageDto
     */
    'type': CompanyPageDtoTypeEnum;
}

export const CompanyPageDtoTypeEnum = {
    SupplierManager: 'SUPPLIER_MANAGER',
    SupplierEmployee: 'SUPPLIER_EMPLOYEE',
    Visit: 'VISIT',
    AfterTraining: 'AFTER_TRAINING'
} as const;

export type CompanyPageDtoTypeEnum = typeof CompanyPageDtoTypeEnum[keyof typeof CompanyPageDtoTypeEnum];

/**
 * 
 * @export
 * @interface CompanySettingsDto
 */
export interface CompanySettingsDto {
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsDto
     */
    'sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsDto
     */
    'smtpHost'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsDto
     */
    'smtpPassword'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanySettingsDto
     */
    'smtpPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySettingsDto
     */
    'smtpUsername'?: string;
}
/**
 * 
 * @export
 * @interface ConfirmInvitationRequest
 */
export interface ConfirmInvitationRequest {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof ConfirmInvitationRequest
     */
    'responsiblePerson': ReferenceDto;
    /**
     * 
     * @type {SupplierDto}
     * @memberof ConfirmInvitationRequest
     */
    'supplier': SupplierDto;
}
/**
 * 
 * @export
 * @interface ConstraintError
 */
export interface ConstraintError {
    /**
     * 
     * @type {string}
     * @memberof ConstraintError
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConstraintError
     */
    'detail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConstraintError
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstraintError
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintError
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<Violation>}
     * @memberof ConstraintError
     */
    'violations'?: Array<Violation>;
}
/**
 * 
 * @export
 * @interface ContactPersonDto
 */
export interface ContactPersonDto {
    /**
     * 
     * @type {string}
     * @memberof ContactPersonDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonDto
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CopyVersionHolderToNewVersionableRequest
 */
export interface CopyVersionHolderToNewVersionableRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToNewVersionableRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToNewVersionableRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToNewVersionableRequest
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToNewVersionableRequest
     */
    'versionNote'?: string;
}
/**
 * 
 * @export
 * @interface CopyVersionHolderToVersionablesRequest
 */
export interface CopyVersionHolderToVersionablesRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToVersionablesRequest
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyVersionHolderToVersionablesRequest
     */
    'versionNote'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CopyVersionHolderToVersionablesRequest
     */
    'versionables'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CopyVersionRequest
 */
export interface CopyVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyVersionRequest
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyVersionRequest
     */
    'versionNote'?: string;
}
/**
 * 
 * @export
 * @interface CoreUserListResponse
 */
export interface CoreUserListResponse {
    /**
     * 
     * @type {string}
     * @memberof CoreUserListResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoreUserListResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoreUserListResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoreUserListResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoreUserListResponse
     */
    'state'?: CoreUserListResponseStateEnum;
}

export const CoreUserListResponseStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CoreUserListResponseStateEnum = typeof CoreUserListResponseStateEnum[keyof typeof CoreUserListResponseStateEnum];

/**
 * 
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * 
     * @type {AddressDto}
     * @memberof CreateCompanyRequest
     */
    'address': AddressDto;
    /**
     * 
     * @type {CompanyBusinessSettings}
     * @memberof CreateCompanyRequest
     */
    'companySettings': CompanyBusinessSettings;
    /**
     * 
     * @type {ContactPersonDto}
     * @memberof CreateCompanyRequest
     */
    'contact': ContactPersonDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'ico': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'state'?: CreateCompanyRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'subDomain': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'subDomainAgain': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'vat'?: string;
}

export const CreateCompanyRequestStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    PreActivated: 'PRE_ACTIVATED'
} as const;

export type CreateCompanyRequestStateEnum = typeof CreateCompanyRequestStateEnum[keyof typeof CreateCompanyRequestStateEnum];

/**
 * 
 * @export
 * @interface CreateQuestionnaireRequest
 */
export interface CreateQuestionnaireRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuestionnaireRequest
     */
    'minSuccessRate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'state'?: CreateQuestionnaireRequestStateEnum;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof CreateQuestionnaireRequest
     */
    'trainings'?: Array<ReferenceDto>;
    /**
     * Used only for create operation for versioning
     * @type {string}
     * @memberof CreateQuestionnaireRequest
     */
    'validFrom': string;
}

export const CreateQuestionnaireRequestStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CreateQuestionnaireRequestStateEnum = typeof CreateQuestionnaireRequestStateEnum[keyof typeof CreateQuestionnaireRequestStateEnum];

/**
 * 
 * @export
 * @interface CreateRiskRequest
 */
export interface CreateRiskRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'document': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'state': CreateRiskRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRiskRequest
     */
    'validFrom': string;
}

export const CreateRiskRequestStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CreateRiskRequestStateEnum = typeof CreateRiskRequestStateEnum[keyof typeof CreateRiskRequestStateEnum];

/**
 * 
 * @export
 * @interface CreateUpdateInvitation
 */
export interface CreateUpdateInvitation {
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof CreateUpdateInvitation
     */
    'activities': Array<ReferenceDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateInvitation
     */
    'attachments'?: Array<string>;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof CreateUpdateInvitation
     */
    'employee': ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvitation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvitation
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvitation
     */
    'noteInternal'?: string;
    /**
     * 
     * @type {SupplierEmployeeDto}
     * @memberof CreateUpdateInvitation
     */
    'responsiblePerson': SupplierEmployeeDto;
    /**
     * 
     * @type {SupplierDto}
     * @memberof CreateUpdateInvitation
     */
    'supplier'?: SupplierDto;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvitation
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInvitation
     */
    'validTo'?: string;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof CreateUpdateInvitation
     */
    'workplaces': Array<ReferenceDto>;

    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInvitation
     */
    'sendNotification'?: boolean;

     /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInvitation
     */
     'disableVisitationNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserFromEmployeeRequest
 */
export interface CreateUserFromEmployeeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromEmployeeRequest
     */
    'language': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserFromEmployeeRequest
     */
    'roles': Array<CreateUserFromEmployeeRequestRolesEnum>;
}

export const CreateUserFromEmployeeRequestRolesEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;

export type CreateUserFromEmployeeRequestRolesEnum = typeof CreateUserFromEmployeeRequestRolesEnum[keyof typeof CreateUserFromEmployeeRequestRolesEnum];

/**
 * Pozvánky ve stavu za celou firmu pracoviste
 * @export
 * @interface DashboardInvitationStatsResponse
 */
export interface DashboardInvitationStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof DashboardInvitationStatsResponse
     */
    'approved'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInvitationStatsResponse
     */
    'inProgress'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInvitationStatsResponse
     */
    'partiallyFilled'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInvitationStatsResponse
     */
    'sent'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardInvitationStatsResponse
     */
    'viewed'?: number;
}
/**
 * 
 * @export
 * @interface DashboardResponse
 */
export interface DashboardResponse {
    /**
     * Povolení ke vstupu
     * @type {number}
     * @memberof DashboardResponse
     */
    'allowedToEnter'?: number;
    /**
     * Povolení ke vstupu za firmu
     * @type {number}
     * @memberof DashboardResponse
     */
    'allowedToEnterCompany'?: number;
    /**
     * 
     * @type {DashboardInvitationStatsResponse}
     * @memberof DashboardResponse
     */
    'companyStats'?: DashboardInvitationStatsResponse;
    /**
     * 
     * @type {DashboardInvitationStatsResponse}
     * @memberof DashboardResponse
     */
    'establishmentStats'?: DashboardInvitationStatsResponse;
    /**
     * Mé návštěvy v areálu 
     * @type {number}
     * @memberof DashboardResponse
     */
    'myPersonsOnPremises'?: number;
    /**
     * Aktuální návštěvy v areálu
     * @type {number}
     * @memberof DashboardResponse
     */
    'personsOnPremises'?: number;
    /**
     * Počet pracovníků na platných pozvánkách
     * @type {number}
     * @memberof DashboardResponse
     */
    'totalWorkers'?: number;
    /**
     * Platná pozvánka
     * @type {number}
     * @memberof DashboardResponse
     */
    'validInvitations'?: number;
}
/**
 * 
 * @export
 * @interface EmergencyDto
 */
export interface EmergencyDto {
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'detail'?: string;
    /**
     * 
     * @type {Array<EmergencyPersonDto>}
     * @memberof EmergencyDto
     */
    'emergencyPersons'?: Array<EmergencyPersonDto>;
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'endDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'endedOn'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof EmergencyDto
     */
    'establishment': ReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof EmergencyDto
     */
    'found'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmergencyDto
     */
    'sendEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmergencyDto
     */
    'sendSMS'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmergencyDto
     */
    'state'?: EmergencyDtoStateEnum;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof EmergencyDto
     */
    'workplaces'?: Array<ReferenceDto>;
}

export const EmergencyDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type EmergencyDtoStateEnum = typeof EmergencyDtoStateEnum[keyof typeof EmergencyDtoStateEnum];

/**
 * 
 * @export
 * @interface EmergencyPersonDto
 */
export interface EmergencyPersonDto {
    /**
     * 
     * @type {string}
     * @memberof EmergencyPersonDto
     */
    'id'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof EmergencyPersonDto
     */
    'invitedBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof EmergencyPersonDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyPersonDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyPersonDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyPersonDto
     */
    'workplaces'?: string;
}
/**
 * 
 * @export
 * @interface EmergencyResponseTemplate
 */
export interface EmergencyResponseTemplate {
    /**
     * 
     * @type {string}
     * @memberof EmergencyResponseTemplate
     */
    'emailContent'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyResponseTemplate
     */
    'smsContent'?: string;
}
/**
 * 
 * @export
 * @interface EmployeeDto
 */
export interface EmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeDto
     */
    'establishments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'jobPosition'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'state'?: EmployeeDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDto
     */
    'user'?: string;
}

export const EmployeeDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type EmployeeDtoStateEnum = typeof EmployeeDtoStateEnum[keyof typeof EmployeeDtoStateEnum];

/**
 * 
 * @export
 * @interface EmployeeUserListResponse
 */
export interface EmployeeUserListResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'jobPosition'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmployeeUserListResponse
     */
    'roles'?: Array<EmployeeUserListResponseRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeUserListResponse
     */
    'state'?: EmployeeUserListResponseStateEnum;
}

export const EmployeeUserListResponseRolesEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;

export type EmployeeUserListResponseRolesEnum = typeof EmployeeUserListResponseRolesEnum[keyof typeof EmployeeUserListResponseRolesEnum];
export const EmployeeUserListResponseStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type EmployeeUserListResponseStateEnum = typeof EmployeeUserListResponseStateEnum[keyof typeof EmployeeUserListResponseStateEnum];

/**
 * 
 * @export
 * @interface EntryApprovedState
 */
export interface EntryApprovedState {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof EntryApprovedState
     */
    'approvedBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof EntryApprovedState
     */
    'approvedOn'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'detail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface EstablishmentDto
 */
export interface EstablishmentDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof EstablishmentDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {ContactPersonDto}
     * @memberof EstablishmentDto
     */
    'contact': ContactPersonDto;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstablishmentDto
     */
    'state': EstablishmentDtoStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EstablishmentDto
     */
    'stateChangeEnabled'?: boolean;
}

export const EstablishmentDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type EstablishmentDtoStateEnum = typeof EstablishmentDtoStateEnum[keyof typeof EstablishmentDtoStateEnum];

/**
 * 
 * @export
 * @interface FileUploadMediaResponse
 */
export interface FileUploadMediaResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadMediaResponse
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadMediaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadMediaResponse
     */
    'mediaType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadMediaResponse
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface ForgottenPasswordRequest
 */
export interface ForgottenPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GetIncompleteTrainingsData
 */
export interface GetIncompleteTrainingsData {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetIncompleteTrainingsData
     */
    'traininigs'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GitInfo
 */
export interface GitInfo {
    /**
     * 
     * @type {string}
     * @memberof GitInfo
     */
    'branch'?: string;
    /**
     * 
     * @type {CommitInfoResult}
     * @memberof GitInfo
     */
    'commit'?: CommitInfoResult;
}
/**
 * 
 * @export
 * @interface IncompleteTrainingDataResponse
 */
export interface IncompleteTrainingDataResponse {
    /**
     * 
     * @type {MissingReferenceData}
     * @memberof IncompleteTrainingDataResponse
     */
    'questionnaire'?: MissingReferenceData;
    /**
     * 
     * @type {MissingReferenceData}
     * @memberof IncompleteTrainingDataResponse
     */
    'training'?: MissingReferenceData;
}
/**
 * 
 * @export
 * @interface InvitationActivityDto
 */
export interface InvitationActivityDto {
    /**
     * 
     * @type {ActivityDto}
     * @memberof InvitationActivityDto
     */
    'activity'?: ActivityDto;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationActivityDto
     */
    'approvalRequired'?: boolean;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationActivityDto
     */
    'approvedBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationActivityDto
     */
    'approvedOn'?: string;
    /**
     * 
     * @type {FileUploadMediaResponse}
     * @memberof InvitationActivityDto
     */
    'document'?: FileUploadMediaResponse;
    /**
     * 
     * @type {string}
     * @memberof InvitationActivityDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface InvitationDocumentResponse
 */
export interface InvitationDocumentResponse {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationDocumentResponse
     */
    'approvedBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationDocumentResponse
     */
    'approvedOn'?: string;
    /**
     * 
     * @type {CertificateDto}
     * @memberof InvitationDocumentResponse
     */
    'certificate'?: CertificateDto;
    /**
     * 
     * @type {FileUploadMediaResponse}
     * @memberof InvitationDocumentResponse
     */
    'document'?: FileUploadMediaResponse;
    /**
     * 
     * @type {string}
     * @memberof InvitationDocumentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDocumentResponse
     */
    'validTo'?: string;
}
/**
 * 
 * @export
 * @interface InvitationDto
 */
export interface InvitationDto {
    /**
     * 
     * @type {Array<InvitationActivityDto>}
     * @memberof InvitationDto
     */
    'activities'?: Array<InvitationActivityDto>;
    /**
     * 
     * @type {Array<FileUploadMediaResponse>}
     * @memberof InvitationDto
     */
    'attachments'?: Array<FileUploadMediaResponse>;
    /**
     * 
     * @type {EmployeeDto}
     * @memberof InvitationDto
     */
    'employee'?: EmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'employeeUserId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationDto
     */
    'hasRiskForDownload'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'invitationTrainingState'?: InvitationDtoInvitationTrainingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'noteInternal'?: string;
    /**
     * 
     * @type {Array<InvitedPersonDto>}
     * @memberof InvitationDto
     */
    'persons'?: Array<InvitedPersonDto>;
    /**
     * 
     * @type {SupplierEmployeeDto}
     * @memberof InvitationDto
     */
    'responsiblePerson'?: SupplierEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'responsiblePersonUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'state'?: InvitationDtoStateEnum;
    /**
     * 
     * @type {SupplierDto}
     * @memberof InvitationDto
     */
    'supplier'?: SupplierDto;
    /**
     * 
     * @type {SupplierDto}
     * @memberof InvitationDto
     */
    'temporarySupplier'?: SupplierDto;
    /**
     * 
     * @type {SupplierEmployeeDto}
     * @memberof InvitationDto
     */
    'temporarySupplierEmployee'?: SupplierEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'type'?: InvitationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'validTo'?: string;
    /**
     * 
     * @type {Array<InvitationRelationResponse>}
     * @memberof InvitationDto
     */
    'workplaces'?: Array<InvitationRelationResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationDto
     */
    'disableVisitationNotification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'workerStatus'?: InvitationDtoWorkerStatusEnum;
}
export const InvitationDtoInvitationTrainingStateEnum = {
    Untrained: 'UNTRAINED',
    Partial: 'PARTIAL',
    Trained: 'TRAINED'
} as const;

export type InvitationDtoInvitationTrainingStateEnum = typeof InvitationDtoInvitationTrainingStateEnum[keyof typeof InvitationDtoInvitationTrainingStateEnum];

export const InvitationDtoWorkerStatusEnum = {
    ForApprovalByOwner: 'FOR_APPROVAL_BY_OWNER',
    NotTrained: 'NOT_TRAINED',
    PartiallyTrained: 'PARTIALLY_TRAINED',
    Trained: 'TRAINED'
} as const;

export type InvitationDtoWorkerStatusEnum = typeof InvitationDtoWorkerStatusEnum[keyof typeof InvitationDtoWorkerStatusEnum];

export const InvitationDtoStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Viewed: 'VIEWED',
    PartiallyFilled: 'PARTIALLY_FILLED',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Approved: 'APPROVED',
    Deleted: 'DELETED',
    Archived: 'ARCHIVED'
} as const;

export type InvitationDtoStateEnum = typeof InvitationDtoStateEnum[keyof typeof InvitationDtoStateEnum];
export const InvitationDtoTypeEnum = {
    Regular: 'REGULAR',
    OneTime: 'ONE_TIME',
    OneTimeEntry: 'ONE_TIME_ENTRY'
} as const;

export type InvitationDtoTypeEnum = typeof InvitationDtoTypeEnum[keyof typeof InvitationDtoTypeEnum];

/**
 * 
 * @export
 * @interface InvitationListResponse
 */
export interface InvitationListResponse {
    /**
     * 
     * @type {EmployeeDto}
     * @memberof InvitationListResponse
     */
    'employee'?: EmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'invitationTrainingState'?: InvitationListResponseInvitationTrainingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'name'?: string;
    /**
     * 
     * @type {SupplierEmployeeDto}
     * @memberof InvitationListResponse
     */
    'responsiblePerson'?: SupplierEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'state'?: InvitationListResponseStateEnum;
    /**
     * 
     * @type {SupplierDto}
     * @memberof InvitationListResponse
     */
    'supplier'?: SupplierDto;
    /**
     * 
     * @type {SupplierDto}
     * @memberof InvitationListResponse
     */
    'temporarySupplier'?: SupplierDto;
    /**
     * 
     * @type {SupplierEmployeeDto}
     * @memberof InvitationListResponse
     */
    'temporarySupplierEmployee'?: SupplierEmployeeDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'type'?: InvitationListResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationListResponse
     */
    'validTo'?: string;
}

export const InvitationListResponseInvitationTrainingStateEnum = {
    Untrained: 'UNTRAINED',
    Partial: 'PARTIAL',
    Trained: 'TRAINED'
} as const;

export type InvitationListResponseInvitationTrainingStateEnum = typeof InvitationListResponseInvitationTrainingStateEnum[keyof typeof InvitationListResponseInvitationTrainingStateEnum];
export const InvitationListResponseStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Viewed: 'VIEWED',
    PartiallyFilled: 'PARTIALLY_FILLED',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Approved: 'APPROVED',
    Deleted: 'DELETED',
    Archived: 'ARCHIVED'
} as const;

export type InvitationListResponseStateEnum = typeof InvitationListResponseStateEnum[keyof typeof InvitationListResponseStateEnum];
export const InvitationListResponseTypeEnum = {
    Regular: 'REGULAR',
    OneTime: 'ONE_TIME',
    OneTimeEntry: 'ONE_TIME_ENTRY'
} as const;

export type InvitationListResponseTypeEnum = typeof InvitationListResponseTypeEnum[keyof typeof InvitationListResponseTypeEnum];

/**
 * 
 * @export
 * @interface InvitationLogEntryResponse
 */
export interface InvitationLogEntryResponse {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationLogEntryResponse
     */
    'actionBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationLogEntryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InvitationLogEntryResponse
     */
    'params'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof InvitationLogEntryResponse
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationLogEntryResponse
     */
    'type'?: InvitationLogEntryResponseTypeEnum;
}

export const InvitationLogEntryResponseTypeEnum = {
    InvitationCreated: 'INVITATION_CREATED',
    InvitationSent: 'INVITATION_SENT',
    InvitationArchived: 'INVITATION_ARCHIVED',
    ResponsiblePersonChanged: 'RESPONSIBLE_PERSON_CHANGED',
    DetailsConfirmed: 'DETAILS_CONFIRMED',
    PersonEntryOverride: 'PERSON_ENTRY_OVERRIDE',
    SupplierRiskConfirmed: 'SUPPLIER_RISK_CONFIRMED',
    RisksConfirmed: 'RISKS_CONFIRMED',
    PersonAdded: 'PERSON_ADDED',
    PersonRemoved: 'PERSON_REMOVED',
    CertificateAdded: 'CERTIFICATE_ADDED',
    CertificateConfirmed: 'CERTIFICATE_CONFIRMED',
    PinViewed: 'PIN_VIEWED',
    ActivityDocumentUploaded: 'ACTIVITY_DOCUMENT_UPLOADED',
    ActivityDocumentApproved: 'ACTIVITY_DOCUMENT_APPROVED',
    EmployeeChanged: 'EMPLOYEE_CHANGED'
} as const;

export type InvitationLogEntryResponseTypeEnum = typeof InvitationLogEntryResponseTypeEnum[keyof typeof InvitationLogEntryResponseTypeEnum];

/**
 * 
 * @export
 * @interface InvitationPersonStatusResponse
 */
export interface InvitationPersonStatusResponse {
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof InvitationPersonStatusResponse
     */
    'completedTrainingsOnInvitations'?: Array<ReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof InvitationPersonStatusResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationPersonStatusResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof InvitationPersonStatusResponse
     */
    'missingQuestionnairesOnInvitations'?: Array<ReferenceDto>;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof InvitationPersonStatusResponse
     */
    'missingTrainingsOnInvitations'?: Array<ReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof InvitationPersonStatusResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationPersonStatusResponse
     */
    'supplierEmployeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationPersonStatusResponse
     */
    'supplierEmployeeUserId'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationPersonStatusResponse
     */
    'training'?: ReferenceDto;
}
/**
 * 
 * @export
 * @interface InvitationQuestionnaireResponse
 */
export interface InvitationQuestionnaireResponse {
    /**
     * 
     * @type {string}
     * @memberof InvitationQuestionnaireResponse
     */
    'id'?: string;
    /**
     * 
     * @type {LanguageDto}
     * @memberof InvitationQuestionnaireResponse
     */
    'language'?: LanguageDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationQuestionnaireResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationQuestionnaireResponse
     */
    'nameEn'?: string;
    /**
     * 
     * @type {Array<QuestionDto>}
     * @memberof InvitationQuestionnaireResponse
     */
    'questions'?: Array<QuestionDto>;
    /**
     * 
     * @type {number}
     * @memberof InvitationQuestionnaireResponse
     */
    'successRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvitationQuestionnaireResponse
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface InvitationReferenceDto
 */
export interface InvitationReferenceDto {
    /**
     * identifier of referenced entity
     * @type {string}
     * @memberof InvitationReferenceDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationReferenceDto
     */
    'invitationType'?: InvitationReferenceDtoInvitationTypeEnum;
    /**
     * name of the referenced entity
     * @type {string}
     * @memberof InvitationReferenceDto
     */
    'name'?: string;
    /**
     * english name of the referenced entity
     * @type {string}
     * @memberof InvitationReferenceDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationReferenceDto
     */
    'ref'?: ReferenceDto;
    /**
     * version of the referenced entity
     * @type {string}
     * @memberof InvitationReferenceDto
     */
    'version'?: string | null;
}

export const InvitationReferenceDtoInvitationTypeEnum = {
    Regular: 'REGULAR',
    OneTime: 'ONE_TIME',
    OneTimeEntry: 'ONE_TIME_ENTRY'
} as const;

export type InvitationReferenceDtoInvitationTypeEnum = typeof InvitationReferenceDtoInvitationTypeEnum[keyof typeof InvitationReferenceDtoInvitationTypeEnum];

/**
 * 
 * @export
 * @interface InvitationRelationResponse
 */
export interface InvitationRelationResponse {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationRelationResponse
     */
    'establishment'?: ReferenceDto;
    /**
     * 
     * @type {Array<RiskWithFileResponse>}
     * @memberof InvitationRelationResponse
     */
    'risks'?: Array<RiskWithFileResponse>;
    /**
     * 
     * @type {Array<SafetyEquipmentDto>}
     * @memberof InvitationRelationResponse
     */
    'safetyEquipments'?: Array<SafetyEquipmentDto>;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationRelationResponse
     */
    'workplace'?: ReferenceDto;
}
/**
 * 
 * @export
 * @interface InvitationStateAud
 */
export interface InvitationStateAud {
    /**
     * 
     * @type {string}
     * @memberof InvitationStateAud
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationStateAud
     */
    'state'?: InvitationStateAudStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationStateAud
     */
    'stateMod'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvitationStateAud
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationStateAud
     */
    'trainingState'?: InvitationStateAudTrainingStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationStateAud
     */
    'trainingStateMod'?: boolean;
}

export const InvitationStateAudStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Viewed: 'VIEWED',
    PartiallyFilled: 'PARTIALLY_FILLED',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Approved: 'APPROVED',
    Deleted: 'DELETED',
    Archived: 'ARCHIVED'
} as const;

export type InvitationStateAudStateEnum = typeof InvitationStateAudStateEnum[keyof typeof InvitationStateAudStateEnum];
export const InvitationStateAudTrainingStateEnum = {
    Untrained: 'UNTRAINED',
    Partial: 'PARTIAL',
    Trained: 'TRAINED'
} as const;

export type InvitationStateAudTrainingStateEnum = typeof InvitationStateAudTrainingStateEnum[keyof typeof InvitationStateAudTrainingStateEnum];

/**
 * 
 * @export
 * @interface InvitationTrainingDetailResponse
 */
export interface InvitationTrainingDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingDetailResponse
     */
    'id'?: string;
    /**
     * 
     * @type {LanguageDto}
     * @memberof InvitationTrainingDetailResponse
     */
    'language'?: LanguageDto;
    /**
     * 
     * @type {Array<SlideDto>}
     * @memberof InvitationTrainingDetailResponse
     */
    'slides'?: Array<SlideDto>;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingDetailResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingDetailResponse
     */
    'titleEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingDetailResponse
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface InvitationTrainingResponse
 */
export interface InvitationTrainingResponse {
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingResponse
     */
    'id'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationTrainingResponse
     */
    'questionnaire'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingResponse
     */
    'questionnaireCompleted'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof InvitationTrainingResponse
     */
    'training'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingResponse
     */
    'trainingCompleted'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTrainingResponse
     */
    'trainingValidUntil'?: string;
}
/**
 * 
 * @export
 * @interface InvitationTypeVisitListResponse
 */
export interface InvitationTypeVisitListResponse {
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'lastEntry'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'supplierEmployeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitListResponse
     */
    'supplierId'?: string;
}
/**
 * 
 * @export
 * @interface InvitationTypeVisitRequest
 */
export interface InvitationTypeVisitRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationTypeVisitRequest
     */
    'activities': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'supplierEmployeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'supplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'visitingPerson': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationTypeVisitRequest
     */
    'workplaces': Array<string>;
    /**
     *
     * @type {string}
     * @memberof InvitationTypeVisitRequest
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface InvitedPersonDto
 */
export interface InvitedPersonDto {
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof InvitedPersonDto
     */
    'activities': Array<ReferenceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof InvitedPersonDto
     * @deprecated
     */
    'agreedAfterTraining'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'conditionOverridden'?: string;
    /**
     * 
     * @type {Array<InvitationDocumentResponse>}
     * @memberof InvitedPersonDto
     */
    'documents'?: Array<InvitationDocumentResponse>;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'employeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvitedPersonDto
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<InvitationTrainingResponse>}
     * @memberof InvitedPersonDto
     */
    'trainings'?: Array<InvitationTrainingResponse>;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof InvitedPersonDto
     */
    'workplaces': Array<ReferenceDto>;
}
/**
 * 
 * @export
 * @interface IssueRemoteTokenRequest
 */
export interface IssueRemoteTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof IssueRemoteTokenRequest
     */
    'target'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IssueRemoteTokenRequest
     */
    'withSend'?: boolean;
}
/**
 * 
 * @export
 * @interface LanguageDto
 */
export interface LanguageDto {
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    'flag': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Password of the person trying to login. Password cannot be blank.
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
    /**
     * A valid email of person trying to login
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'mediaToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface MissingReferenceData
 */
export interface MissingReferenceData {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof MissingReferenceData
     */
    'entity'?: ReferenceDto;
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof MissingReferenceData
     */
    'languages'?: Array<LanguageDto>;
}
/**
 * 
 * @export
 * @interface MultipleIdRequest
 */
export interface MultipleIdRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MultipleIdRequest
     */
    'entries'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageActivityDto
 */
export interface PageActivityDto {
    /**
     * 
     * @type {Array<ActivityDto>}
     * @memberof PageActivityDto
     */
    'content'?: Array<ActivityDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageActivityDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageActivityDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageActivityDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageActivityDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageActivityDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCertificateDto
 */
export interface PageCertificateDto {
    /**
     * 
     * @type {Array<CertificateDto>}
     * @memberof PageCertificateDto
     */
    'content'?: Array<CertificateDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCertificateDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCertificateDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCertificateDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCertificateDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCertificateDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCertificateDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCertificateDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageCertificateDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageCertificateDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCertificateDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCompanyListResponse
 */
export interface PageCompanyListResponse {
    /**
     * 
     * @type {Array<CompanyListResponse>}
     * @memberof PageCompanyListResponse
     */
    'content'?: Array<CompanyListResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyListResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCompanyListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageCompanyListResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyListResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyListResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCoreUserListResponse
 */
export interface PageCoreUserListResponse {
    /**
     * 
     * @type {Array<CoreUserListResponse>}
     * @memberof PageCoreUserListResponse
     */
    'content'?: Array<CoreUserListResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoreUserListResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoreUserListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCoreUserListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCoreUserListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCoreUserListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCoreUserListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageCoreUserListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageCoreUserListResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageCoreUserListResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCoreUserListResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageEmergencyDto
 */
export interface PageEmergencyDto {
    /**
     * 
     * @type {Array<EmergencyDto>}
     * @memberof PageEmergencyDto
     */
    'content'?: Array<EmergencyDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmergencyDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmergencyDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmergencyDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEmergencyDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmergencyDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageEmergencyDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageEmergencyDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageEmergencyDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageEmergencyDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmergencyDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageEmployeeDto
 */
export interface PageEmployeeDto {
    /**
     * 
     * @type {Array<EmployeeDto>}
     * @memberof PageEmployeeDto
     */
    'content'?: Array<EmployeeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageEmployeeDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageEmployeeDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageEmployeeUserListResponse
 */
export interface PageEmployeeUserListResponse {
    /**
     * 
     * @type {Array<EmployeeUserListResponse>}
     * @memberof PageEmployeeUserListResponse
     */
    'content'?: Array<EmployeeUserListResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeUserListResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeUserListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEmployeeUserListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeUserListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeUserListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageEmployeeUserListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeUserListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageEmployeeUserListResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeUserListResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEmployeeUserListResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageEstablishmentDto
 */
export interface PageEstablishmentDto {
    /**
     * 
     * @type {Array<EstablishmentDto>}
     * @memberof PageEstablishmentDto
     */
    'content'?: Array<EstablishmentDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEstablishmentDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEstablishmentDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEstablishmentDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEstablishmentDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEstablishmentDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageEstablishmentDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageEstablishmentDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageEstablishmentDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageEstablishmentDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEstablishmentDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageInvitationListResponse
 */
export interface PageInvitationListResponse {
    /**
     * 
     * @type {Array<InvitationListResponse>}
     * @memberof PageInvitationListResponse
     */
    'content'?: Array<InvitationListResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationListResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInvitationListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageInvitationListResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationListResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationListResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageInvitationLogEntryResponse
 */
export interface PageInvitationLogEntryResponse {
    /**
     * 
     * @type {Array<InvitationLogEntryResponse>}
     * @memberof PageInvitationLogEntryResponse
     */
    'content'?: Array<InvitationLogEntryResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationLogEntryResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationLogEntryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationLogEntryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationLogEntryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationLogEntryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInvitationLogEntryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationLogEntryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageInvitationLogEntryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationLogEntryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationLogEntryResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageInvitationPersonStatusResponse
 */
export interface PageInvitationPersonStatusResponse {
    /**
     * 
     * @type {Array<InvitationPersonStatusResponse>}
     * @memberof PageInvitationPersonStatusResponse
     */
    'content'?: Array<InvitationPersonStatusResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationPersonStatusResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationPersonStatusResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationPersonStatusResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationPersonStatusResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationPersonStatusResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInvitationPersonStatusResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationPersonStatusResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageInvitationPersonStatusResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationPersonStatusResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationPersonStatusResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageInvitationStateAud
 */
export interface PageInvitationStateAud {
    /**
     * 
     * @type {Array<InvitationStateAud>}
     * @memberof PageInvitationStateAud
     */
    'content'?: Array<InvitationStateAud>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationStateAud
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationStateAud
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationStateAud
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationStateAud
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationStateAud
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInvitationStateAud
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationStateAud
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageInvitationStateAud
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationStateAud
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationStateAud
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageInvitationTypeVisitListResponse
 */
export interface PageInvitationTypeVisitListResponse {
    /**
     * 
     * @type {Array<InvitationTypeVisitListResponse>}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'content'?: Array<InvitationTypeVisitListResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvitationTypeVisitListResponse
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLanguageDto
 */
export interface PageLanguageDto {
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof PageLanguageDto
     */
    'content'?: Array<LanguageDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLanguageDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLanguageDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLanguageDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLanguageDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLanguageDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLanguageDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLanguageDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageLanguageDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageLanguageDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLanguageDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageQuestionnaireDto
 */
export interface PageQuestionnaireDto {
    /**
     * 
     * @type {Array<QuestionnaireDto>}
     * @memberof PageQuestionnaireDto
     */
    'content'?: Array<QuestionnaireDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageQuestionnaireDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageQuestionnaireDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageQuestionnaireVersionDto
 */
export interface PageQuestionnaireVersionDto {
    /**
     * 
     * @type {Array<QuestionnaireVersionDto>}
     * @memberof PageQuestionnaireVersionDto
     */
    'content'?: Array<QuestionnaireVersionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireVersionDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireVersionDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQuestionnaireVersionDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireVersionDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireVersionDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageQuestionnaireVersionDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireVersionDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageQuestionnaireVersionDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireVersionDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQuestionnaireVersionDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRiskDto
 */
export interface PageRiskDto {
    /**
     * 
     * @type {Array<RiskDto>}
     * @memberof PageRiskDto
     */
    'content'?: Array<RiskDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRiskDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRiskDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRiskDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRiskDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageRiskDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageRiskDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRiskDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRiskVersionDto
 */
export interface PageRiskVersionDto {
    /**
     * 
     * @type {Array<RiskVersionDto>}
     * @memberof PageRiskVersionDto
     */
    'content'?: Array<RiskVersionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskVersionDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskVersionDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRiskVersionDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRiskVersionDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRiskVersionDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRiskVersionDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRiskVersionDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageRiskVersionDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageRiskVersionDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRiskVersionDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageSafetyEquipmentDto
 */
export interface PageSafetyEquipmentDto {
    /**
     * 
     * @type {Array<SafetyEquipmentDto>}
     * @memberof PageSafetyEquipmentDto
     */
    'content'?: Array<SafetyEquipmentDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSafetyEquipmentDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSafetyEquipmentDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSafetyEquipmentDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSafetyEquipmentDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSafetyEquipmentDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSafetyEquipmentDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageSafetyEquipmentDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageSafetyEquipmentDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageSafetyEquipmentDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSafetyEquipmentDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageSupplierCertDto
 */
export interface PageSupplierCertDto {
    /**
     * 
     * @type {Array<SupplierCertDto>}
     * @memberof PageSupplierCertDto
     */
    'content'?: Array<SupplierCertDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierCertDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierCertDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierCertDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierCertDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierCertDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSupplierCertDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierCertDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageSupplierCertDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierCertDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierCertDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageSupplierDto
 */
export interface PageSupplierDto {
    /**
     * 
     * @type {Array<SupplierDto>}
     * @memberof PageSupplierDto
     */
    'content'?: Array<SupplierDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSupplierDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageSupplierDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageSupplierEmployeeDto
 */
export interface PageSupplierEmployeeDto {
    /**
     * 
     * @type {Array<SupplierEmployeeDto>}
     * @memberof PageSupplierEmployeeDto
     */
    'content'?: Array<SupplierEmployeeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierEmployeeDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierEmployeeDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierEmployeeDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierEmployeeDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierEmployeeDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSupplierEmployeeDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierEmployeeDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageSupplierEmployeeDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierEmployeeDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierEmployeeDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTrainingDto
 */
export interface PageTrainingDto {
    /**
     * 
     * @type {Array<TrainingDto>}
     * @memberof PageTrainingDto
     */
    'content'?: Array<TrainingDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrainingDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageTrainingDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTrainingVersionDto
 */
export interface PageTrainingVersionDto {
    /**
     * 
     * @type {Array<TrainingVersionDto>}
     * @memberof PageTrainingVersionDto
     */
    'content'?: Array<TrainingVersionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingVersionDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingVersionDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTrainingVersionDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingVersionDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingVersionDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTrainingVersionDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingVersionDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageTrainingVersionDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingVersionDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTrainingVersionDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageVisitationDto
 */
export interface PageVisitationDto {
    /**
     * 
     * @type {Array<VisitationDto>}
     * @memberof PageVisitationDto
     */
    'content'?: Array<VisitationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageVisitationDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageVisitationDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageVisitationDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageVisitationDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVisitationDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageVisitationDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageVisitationDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageVisitationDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageVisitationDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageVisitationDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageWorkplaceDto
 */
export interface PageWorkplaceDto {
    /**
     * 
     * @type {Array<WorkplaceDto>}
     * @memberof PageWorkplaceDto
     */
    'content'?: Array<WorkplaceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageWorkplaceDto
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWorkplaceDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWorkplaceDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageWorkplaceDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWorkplaceDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageWorkplaceDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageWorkplaceDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageWorkplaceDto
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageWorkplaceDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWorkplaceDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageableObject
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PinResponse
 */
export interface PinResponse {
    /**
     * 
     * @type {string}
     * @memberof PinResponse
     */
    'pin'?: string;
}
/**
 * 
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
    /**
     * 
     * @type {Array<AnswerDto>}
     * @memberof QuestionDto
     */
    'answers'?: Array<AnswerDto>;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'content': string;
    /**
     * 
     * @type {FileUploadMediaResponse}
     * @memberof QuestionDto
     */
    'document'?: FileUploadMediaResponse;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'id'?: string;
    /**
     * 
     * @type {LanguageDto}
     * @memberof QuestionDto
     */
    'language'?: LanguageDto;
    /**
     * 
     * @type {number}
     * @memberof QuestionDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface QuestionnaireDto
 */
export interface QuestionnaireDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireDto
     */
    'missingLanguageVariant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireDto
     */
    'state'?: QuestionnaireDtoStateEnum;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof QuestionnaireDto
     */
    'trainings'?: Array<ReferenceDto>;
}

export const QuestionnaireDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type QuestionnaireDtoStateEnum = typeof QuestionnaireDtoStateEnum[keyof typeof QuestionnaireDtoStateEnum];

/**
 * 
 * @export
 * @interface QuestionnaireVersionDto
 */
export interface QuestionnaireVersionDto {
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireVersionDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireVersionDto
     */
    'editable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionnaireVersionDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireVersionDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof QuestionnaireVersionDto
     */
    'languages'?: Array<LanguageDto>;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireVersionDto
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionnaireVersionDto
     */
    'successRate': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireVersionDto
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireVersionDto
     */
    'validTo'?: string;
}
/**
 * 
 * @export
 * @interface ReadPinResponse
 */
export interface ReadPinResponse {
    /**
     * 
     * @type {Array<VisitationInvitationResponse>}
     * @memberof ReadPinResponse
     */
    'data'?: Array<VisitationInvitationResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ReadPinResponse
     */
    'leftPremises'?: boolean;
}
/**
 * 
 * @export
 * @interface ReferenceDto
 */
export interface ReferenceDto {
    /**
     * identifier of referenced entity
     * @type {string}
     * @memberof ReferenceDto
     */
    'id': string;
    /**
     * name of the referenced entity
     * @type {string}
     * @memberof ReferenceDto
     */
    'name'?: string;
    /**
     * english name of the referenced entity
     * @type {string}
     * @memberof ReferenceDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof ReferenceDto
     */
    'ref'?: ReferenceDto;
    /**
     * version of the referenced entity
     * @type {string}
     * @memberof ReferenceDto
     */
    'version'?: string | null;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * Refresh token obtained via access token request
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RiskDto
 */
export interface RiskDto {
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'currentVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskDto
     */
    'state': RiskDtoStateEnum;
}

export const RiskDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type RiskDtoStateEnum = typeof RiskDtoStateEnum[keyof typeof RiskDtoStateEnum];

/**
 * 
 * @export
 * @interface RiskVersionDto
 */
export interface RiskVersionDto {
    /**
     * 
     * @type {boolean}
     * @memberof RiskVersionDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'document': string;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'documentName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RiskVersionDto
     */
    'editable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RiskVersionDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof RiskVersionDto
     */
    'validTo'?: string;
}
/**
 * 
 * @export
 * @interface RiskWithFileResponse
 */
export interface RiskWithFileResponse {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof RiskWithFileResponse
     */
    'approvedBy'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof RiskWithFileResponse
     */
    'approvedOn'?: string;
    /**
     * 
     * @type {FileUploadMediaResponse}
     * @memberof RiskWithFileResponse
     */
    'file'?: FileUploadMediaResponse;
    /**
     * 
     * @type {string}
     * @memberof RiskWithFileResponse
     */
    'id'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof RiskWithFileResponse
     */
    'risk'?: ReferenceDto;
}
/**
 * 
 * @export
 * @interface SafetyEquipmentDto
 */
export interface SafetyEquipmentDto {
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'state'?: SafetyEquipmentDtoStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SafetyEquipmentDto
     */
    'stateChangeEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafetyEquipmentDto
     */
    'type': SafetyEquipmentDtoTypeEnum;
}

export const SafetyEquipmentDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type SafetyEquipmentDtoStateEnum = typeof SafetyEquipmentDtoStateEnum[keyof typeof SafetyEquipmentDtoStateEnum];
export const SafetyEquipmentDtoTypeEnum = {
    Owned: 'OWNED',
    Borrowed: 'BORROWED',
    BorrowedReturnable: 'BORROWED_RETURNABLE'
} as const;

export type SafetyEquipmentDtoTypeEnum = typeof SafetyEquipmentDtoTypeEnum[keyof typeof SafetyEquipmentDtoTypeEnum];

/**
 * 
 * @export
 * @interface SetActivityDocuments
 */
export interface SetActivityDocuments {
    /**
     * 
     * @type {Array<ActivityDocumentReference>}
     * @memberof SetActivityDocuments
     */
    'data': Array<ActivityDocumentReference>;
}
/**
 * 
 * @export
 * @interface SignPersonRequest
 */
export interface SignPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     */
    'person': string;
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     * @deprecated
     */
    'questionnaire'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     */
    'questionnaireLanguageVariant'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     */
    'signatureDocumentId': string;
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     */
    'training'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignPersonRequest
     */
    'trainingLanguageVariant': string;
}
/**
 * 
 * @export
 * @interface SingleValueResponseString
 */
export interface SingleValueResponseString {
    /**
     * 
     * @type {string}
     * @memberof SingleValueResponseString
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface SingleValueResponseUUID
 */
export interface SingleValueResponseUUID {
    /**
     * 
     * @type {string}
     * @memberof SingleValueResponseUUID
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface SlideDto
 */
export interface SlideDto {
    /**
     * 
     * @type {string}
     * @memberof SlideDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof SlideDto
     */
    'id'?: string;
    /**
     * 
     * @type {LanguageDto}
     * @memberof SlideDto
     */
    'language'?: LanguageDto;
    /**
     * 
     * @type {number}
     * @memberof SlideDto
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof SlideDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ascending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'direction'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ignoreCase'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'nullHandling'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'property'?: string;
}
/**
 * 
 * @export
 * @interface SubmitAnswerRequest
 */
export interface SubmitAnswerRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitAnswerRequest
     */
    'answer': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitAnswerRequest
     */
    'persons': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmitAnswerRequest
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface SubmitApplicationInvitation
 */
export interface SubmitApplicationInvitation {
    /**
     * 
     * @type {string}
     * @memberof SubmitApplicationInvitation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitApplicationInvitation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitApplicationInvitation
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitApplicationInvitation
     */
    'repeatedPassword': string;
}
/**
 * 
 * @export
 * @interface SubmitForgottenPassword
 */
export interface SubmitForgottenPassword {
    /**
     * 
     * @type {string}
     * @memberof SubmitForgottenPassword
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitForgottenPassword
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitForgottenPassword
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitForgottenPassword
     */
    'passwordAgain': string;
}
/**
 * 
 * @export
 * @interface SupplierCertDto
 */
export interface SupplierCertDto {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof SupplierCertDto
     */
    'certificate'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SupplierCertDto
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCertDto
     */
    'documentUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCertDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierCertDto
     */
    'validUntil'?: string;
}
/**
 * if filled, responsible person has to be real responsible person (with manager role)
 * @export
 * @interface SupplierDto
 */
export interface SupplierDto {
    /**
     * 
     * @type {AddressDto}
     * @memberof SupplierDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'ico': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'state': SupplierDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'vat'?: string;
}

export const SupplierDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type SupplierDtoStateEnum = typeof SupplierDtoStateEnum[keyof typeof SupplierDtoStateEnum];

/**
 * if supplier is not filled this person is the person with one time entry. Matching 
 * @export
 * @interface SupplierEmployeeDto
 */
export interface SupplierEmployeeDto {
    /**
     * 
     * @type {number}
     * @memberof SupplierEmployeeDto
     */
    'certificateCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'jobPosition'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierEmployeeDto
     */
    'oneTime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'role': SupplierEmployeeDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof SupplierEmployeeDto
     */
    'state': SupplierEmployeeDtoStateEnum;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof SupplierEmployeeDto
     */
    'supplier'?: ReferenceDto;
}

export const SupplierEmployeeDtoRoleEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;

export type SupplierEmployeeDtoRoleEnum = typeof SupplierEmployeeDtoRoleEnum[keyof typeof SupplierEmployeeDtoRoleEnum];
export const SupplierEmployeeDtoStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type SupplierEmployeeDtoStateEnum = typeof SupplierEmployeeDtoStateEnum[keyof typeof SupplierEmployeeDtoStateEnum];

/**
 * 
 * @export
 * @interface TourniquetResponse
 */
export interface TourniquetResponse {
    /**
     * 
     * @type {string}
     * @memberof TourniquetResponse
     */
    'conditionOverridden'?: string;
    /**
     * 
     * @type {InvitedPersonDto}
     * @memberof TourniquetResponse
     */
    'invitationPerson': InvitedPersonDto;
    /**
     * 
     * @type {Array<InvitationTrainingResponse>}
     * @memberof TourniquetResponse
     */
    'trainings'?: Array<InvitationTrainingResponse>;
}
/**
 * 
 * @export
 * @interface TrainingDto
 */
export interface TrainingDto {
    /**
     * validity of training in days
     * @type {number}
     * @memberof TrainingDto
     */
    'duration': number;
    /**
     * Identifier of training. Only present in responses
     * @type {string}
     * @memberof TrainingDto
     */
    'id'?: string;
    /**
     * latest valid from value
     * @type {string}
     * @memberof TrainingDto
     */
    'latestValidFrom'?: string;
    /**
     * latest valid to value
     * @type {string}
     * @memberof TrainingDto
     */
    'latestValidTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDto
     */
    'nameRu'?: string;
    /**
     * note of the training
     * @type {string}
     * @memberof TrainingDto
     */
    'note'?: string;
    /**
     * used on test
     * @type {Array<ReferenceDto>}
     * @memberof TrainingDto
     */
    'questionnaire'?: Array<ReferenceDto>;
    /**
     * number of slides
     * @type {number}
     * @memberof TrainingDto
     */
    'slideCount'?: number;
    /**
     * state of entity
     * @type {string}
     * @memberof TrainingDto
     */
    'state': TrainingDtoStateEnum;
    /**
     * list of assigned workplaces
     * @type {Array<ReferenceDto>}
     * @memberof TrainingDto
     */
    'workplaces'?: Array<ReferenceDto>;
}

export const TrainingDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type TrainingDtoStateEnum = typeof TrainingDtoStateEnum[keyof typeof TrainingDtoStateEnum];

/**
 * List of languages assigned to this version. If passed in POST request, then application will create language variants automatically. 
 * @export
 * @interface TrainingLanguageVariantDto
 */
export interface TrainingLanguageVariantDto {
    /**
     * 
     * @type {LanguageDto}
     * @memberof TrainingLanguageVariantDto
     */
    'language': LanguageDto;
    /**
     * 
     * @type {number}
     * @memberof TrainingLanguageVariantDto
     */
    'slides'?: number;
}
/**
 * 
 * @export
 * @interface TrainingVersionDto
 */
export interface TrainingVersionDto {
    /**
     * 
     * @type {boolean}
     * @memberof TrainingVersionDto
     */
    'active'?: boolean;
    /**
     * validity of training in days
     * @type {number}
     * @memberof TrainingVersionDto
     */
    'duration': number;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingVersionDto
     */
    'editable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingVersionDto
     */
    'first'?: boolean;
    /**
     * Id of training version. Value is present only in response
     * @type {string}
     * @memberof TrainingVersionDto
     */
    'id'?: string;
    /**
     * List of languages assigned to this version. If passed in POST request, then application will create language variants automatically. 
     * @type {Array<TrainingLanguageVariantDto>}
     * @memberof TrainingVersionDto
     */
    'languageVariants'?: Array<TrainingLanguageVariantDto>;
    /**
     * note for given version
     * @type {string}
     * @memberof TrainingVersionDto
     */
    'note'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof TrainingVersionDto
     */
    'questionnaire'?: ReferenceDto;
    /**
     * Id of training to which version is assigned. Value is present only in response
     * @type {string}
     * @memberof TrainingVersionDto
     */
    'training'?: string;
    /**
     * Point in time specifying start of validity of version
     * @type {string}
     * @memberof TrainingVersionDto
     */
    'validFrom': string;
    /**
     * Point in time specifying end of validity of version
     * @type {string}
     * @memberof TrainingVersionDto
     */
    'validTo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContainerOrder
 */
export interface UpdateContainerOrder {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateContainerOrder
     */
    'entries'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateInvitationDocument
 */
export interface UpdateInvitationDocument {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationDocument
     */
    'document': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationDocument
     */
    'validTo'?: string;
}
/**
 * 
 * @export
 * @interface UpdateInvitedPersonRequest
 */
export interface UpdateInvitedPersonRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInvitedPersonRequest
     */
    'activities'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInvitedPersonRequest
     */
    'workplaces'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'establishments': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'office'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roles': Array<UserDtoRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'state': UserDtoStateEnum;
}

export const UserDtoRolesEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;

export type UserDtoRolesEnum = typeof UserDtoRolesEnum[keyof typeof UserDtoRolesEnum];
export const UserDtoStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UserDtoStateEnum = typeof UserDtoStateEnum[keyof typeof UserDtoStateEnum];

/**
 * 
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
    /**
     * 
     * @type {string}
     * @memberof UserPreferences
     */
    'language'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferences
     */
    'pageSize': number;
    /**
     * 
     * @type {UserPreferencesPages}
     * @memberof UserPreferences
     */
    'settingsCs': UserPreferencesPages;
    /**
     * 
     * @type {UserPreferencesPages}
     * @memberof UserPreferences
     */
    'settingsEn': UserPreferencesPages;
}
/**
 * 
 * @export
 * @interface UserPreferencesPages
 */
export interface UserPreferencesPages {
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesPages
     */
    'supplierEmployeeSeen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesPages
     */
    'supplierEmployeeWorkerSeen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreferencesPages
     */
    'visitationSeen'?: boolean;
}
/**
 * 
 * @export
 * @interface VersionLanguageResponse
 */
export interface VersionLanguageResponse {
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof VersionLanguageResponse
     */
    'languages'?: Array<LanguageDto>;
    /**
     * 
     * @type {string}
     * @memberof VersionLanguageResponse
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface VisitationDetailDto
 */
export interface VisitationDetailDto {
    /**
     * 
     * @type {EntryApprovedState}
     * @memberof VisitationDetailDto
     */
    'entryApprovedState'?: EntryApprovedState;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDetailDto
     */
    'entryUser'?: ReferenceDto;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDetailDto
     */
    'exitUser'?: ReferenceDto;
    /**
     * 
     * @type {VisitationDto}
     * @memberof VisitationDetailDto
     */
    'visitation'?: VisitationDto;
}
/**
 * 
 * @export
 * @interface VisitationDto
 */
export interface VisitationDto {
    /**
     * 
     * @type {string}
     * @memberof VisitationDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitationDto
     */
    'entryPlant'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitationDto
     */
    'entryTime'?: string;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDto
     */
    'establishment'?: ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VisitationDto
     */
    'exitTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitationDto
     */
    'id'?: string;
    /**
     * 
     * @type {InvitationReferenceDto}
     * @memberof VisitationDto
     */
    'invitation'?: InvitationReferenceDto;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDto
     */
    'invitationPerson'?: ReferenceDto;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDto
     */
    'invitedBy'?: ReferenceDto;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDto
     */
    'supplier'?: ReferenceDto;
    /**
     * 
     * @type {ReferenceDto}
     * @memberof VisitationDto
     */
    'supplierEmployee'?: ReferenceDto;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof VisitationDto
     */
    'workplaces'?: Array<ReferenceDto>;

    /**
     *
     * @type {string}
     * @memberof VisitationDto
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface VisitationInvitationResponse
 */
export interface VisitationInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof VisitationInvitationResponse
     */
    'conditionOverride'?: string;
    /**
     * 
     * @type {InvitationDto}
     * @memberof VisitationInvitationResponse
     */
    'invitation'?: InvitationDto;
    /**
     * 
     * @type {boolean}
     * @memberof VisitationInvitationResponse
     */
    'questionnaireIncomplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisitationInvitationResponse
     */
    'trainingIncomplete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitationInvitationResponse
     */
    'type'?: VisitationInvitationResponseTypeEnum;
}

export const VisitationInvitationResponseTypeEnum = {
    Enter: 'ENTER',
    Exit: 'EXIT'
} as const;

export type VisitationInvitationResponseTypeEnum = typeof VisitationInvitationResponseTypeEnum[keyof typeof VisitationInvitationResponseTypeEnum];

/**
 * 
 * @export
 * @interface VisitationPinDto
 */
export interface VisitationPinDto {
    /**
     * 
     * @type {string}
     * @memberof VisitationPinDto
     */
    'entryEstablishment'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitationPinDto
     */
    'invitation': string;
    /**
     * 
     * @type {string}
     * @memberof VisitationPinDto
     */
    'pin': string;
    /**
     * 
     * @type {string}
     * @memberof VisitationPinDto
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface WorkplaceDto
 */
export interface WorkplaceDto {
    /**
     * 
     * @type {ReferenceDto}
     * @memberof WorkplaceDto
     */
    'establishment': ReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'nameDe'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'nameEn'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'namePl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'nameRu'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkplaceDto
     */
    'numberOfTrainings'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkplaceDto
     */
    'riskRequired'?: boolean;
    /**
     * 
     * @type {Array<ReferenceDto>}
     * @memberof WorkplaceDto
     */
    'risks'?: Array<ReferenceDto>;
    /**
     * 
     * @type {Array<SafetyEquipmentDto>}
     * @memberof WorkplaceDto
     */
    'safetyEquipments'?: Array<SafetyEquipmentDto>;
    /**
     * 
     * @type {string}
     * @memberof WorkplaceDto
     */
    'state': WorkplaceDtoStateEnum;
}

export const WorkplaceDtoStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type WorkplaceDtoStateEnum = typeof WorkplaceDtoStateEnum[keyof typeof WorkplaceDtoStateEnum];


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create activity
         * @param {string} company 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (company: string, activityDto: ActivityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createActivity', 'company', company)
            // verify required parameter 'activityDto' is not null or undefined
            assertParamExists('createActivity', 'activityDto', activityDto)
            const localVarPath = `/api/v1/companies/{company}/activities`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide activity for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get activity
         * @param {string} activity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (activity: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activity' is not null or undefined
            assertParamExists('getActivity', 'activity', activity)
            const localVarPath = `/api/v1/activities/{activity}`
                .replace(`{${"activity"}}`, encodeURIComponent(String(activity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get workplaces where this activity is member of
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkplacesForSafetyEquipment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkplacesForSafetyEquipment', 'id', id)
            const localVarPath = `/api/v1/safety-equipments/{id}/workplaces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get all activities for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [riskRequired] Požadováno předání rizik
         * @param {boolean} [certificateRequired] Požadován doklad profesní způsobilosti
         * @param {QueryActivitiesStateEnum} [state] 
         * @param {boolean} [oneTimeEntry] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryActivities: async (company: string, q?: string, riskRequired?: boolean, certificateRequired?: boolean, state?: QueryActivitiesStateEnum, oneTimeEntry?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryActivities', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/activities`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (riskRequired !== undefined) {
                localVarQueryParameter['riskRequired'] = riskRequired;
            }

            if (certificateRequired !== undefined) {
                localVarQueryParameter['certificateRequired'] = certificateRequired;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (oneTimeEntry !== undefined) {
                localVarQueryParameter['oneTimeEntry'] = oneTimeEntry;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update activity
         * @param {string} activity 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (activity: string, activityDto: ActivityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activity' is not null or undefined
            assertParamExists('updateActivity', 'activity', activity)
            // verify required parameter 'activityDto' is not null or undefined
            assertParamExists('updateActivity', 'activityDto', activityDto)
            const localVarPath = `/api/v1/activities/{activity}`
                .replace(`{${"activity"}}`, encodeURIComponent(String(activity)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create activity
         * @param {string} company 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(company: string, activityDto: ActivityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(company, activityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.createActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide activity for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get activity
         * @param {string} activity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(activity: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(activity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.getActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get workplaces where this activity is member of
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkplacesForSafetyEquipment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkplaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkplacesForSafetyEquipment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.getWorkplacesForSafetyEquipment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get all activities for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [riskRequired] Požadováno předání rizik
         * @param {boolean} [certificateRequired] Požadován doklad profesní způsobilosti
         * @param {QueryActivitiesStateEnum} [state] 
         * @param {boolean} [oneTimeEntry] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryActivities(company: string, q?: string, riskRequired?: boolean, certificateRequired?: boolean, state?: QueryActivitiesStateEnum, oneTimeEntry?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageActivityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryActivities(company, q, riskRequired, certificateRequired, state, oneTimeEntry, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.queryActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update activity
         * @param {string} activity 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(activity: string, activityDto: ActivityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(activity, activityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.updateActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create activity
         * @param {string} company 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(company: string, activityDto: ActivityDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createActivity(company, activityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide activity for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get activity
         * @param {string} activity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(activity: string, options?: any): AxiosPromise<ActivityDto> {
            return localVarFp.getActivity(activity, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get workplaces where this activity is member of
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkplacesForSafetyEquipment(id: string, options?: any): AxiosPromise<Array<WorkplaceDto>> {
            return localVarFp.getWorkplacesForSafetyEquipment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get all activities for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [riskRequired] Požadováno předání rizik
         * @param {boolean} [certificateRequired] Požadován doklad profesní způsobilosti
         * @param {QueryActivitiesStateEnum} [state] 
         * @param {boolean} [oneTimeEntry] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryActivities(company: string, q?: string, riskRequired?: boolean, certificateRequired?: boolean, state?: QueryActivitiesStateEnum, oneTimeEntry?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageActivityDto> {
            return localVarFp.queryActivities(company, q, riskRequired, certificateRequired, state, oneTimeEntry, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update activity
         * @param {string} activity 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(activity: string, activityDto: ActivityDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivity(activity, activityDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * The purpose of this method is to create activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Create activity
     * @param {string} company 
     * @param {ActivityDto} activityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public createActivity(company: string, activityDto: ActivityDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).createActivity(company, activityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide activity for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Get activity
     * @param {string} activity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivity(activity: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivity(activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get workplaces where this activity is member of
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getWorkplacesForSafetyEquipment(id: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getWorkplacesForSafetyEquipment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Get all activities for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {boolean} [riskRequired] Požadováno předání rizik
     * @param {boolean} [certificateRequired] Požadován doklad profesní způsobilosti
     * @param {QueryActivitiesStateEnum} [state] 
     * @param {boolean} [oneTimeEntry] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public queryActivities(company: string, q?: string, riskRequired?: boolean, certificateRequired?: boolean, state?: QueryActivitiesStateEnum, oneTimeEntry?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).queryActivities(company, q, riskRequired, certificateRequired, state, oneTimeEntry, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update activity. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Update activity
     * @param {string} activity 
     * @param {ActivityDto} activityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public updateActivity(activity: string, activityDto: ActivityDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).updateActivity(activity, activityDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryActivitiesStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryActivitiesStateEnum = typeof QueryActivitiesStateEnum[keyof typeof QueryActivitiesStateEnum];


/**
 * ActuatorApi - axios parameter creator
 * @export
 */
export const ActuatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actuator/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActuatorApi - functional programming interface
 * @export
 */
export const ActuatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActuatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationInfoResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActuatorApi.applicationInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActuatorApi - factory interface
 * @export
 */
export const ActuatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActuatorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfo(options?: any): AxiosPromise<ApplicationInfoResult> {
            return localVarFp.applicationInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActuatorApi - object-oriented interface
 * @export
 * @class ActuatorApi
 * @extends {BaseAPI}
 */
export class ActuatorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActuatorApi
     */
    public applicationInfo(options?: RawAxiosRequestConfig) {
        return ActuatorApiFp(this.configuration).applicationInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ApplicationInvitationApi - axios parameter creator
 * @export
 */
export const ApplicationInvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendApplicationInvitation: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendApplicationInvitation', 'userId', userId)
            const localVarPath = `/app-invitation/{userId}/resend`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitApplicationInvitation} submitApplicationInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationInvitation: async (submitApplicationInvitation: SubmitApplicationInvitation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitApplicationInvitation' is not null or undefined
            assertParamExists('submitApplicationInvitation', 'submitApplicationInvitation', submitApplicationInvitation)
            const localVarPath = `/app-invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitApplicationInvitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationInvitationApi - functional programming interface
 * @export
 */
export const ApplicationInvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationInvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendApplicationInvitation(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendApplicationInvitation(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationInvitationApi.resendApplicationInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubmitApplicationInvitation} submitApplicationInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitApplicationInvitation(submitApplicationInvitation: SubmitApplicationInvitation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitApplicationInvitation(submitApplicationInvitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationInvitationApi.submitApplicationInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApplicationInvitationApi - factory interface
 * @export
 */
export const ApplicationInvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationInvitationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendApplicationInvitation(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendApplicationInvitation(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitApplicationInvitation} submitApplicationInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationInvitation(submitApplicationInvitation: SubmitApplicationInvitation, options?: any): AxiosPromise<void> {
            return localVarFp.submitApplicationInvitation(submitApplicationInvitation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationInvitationApi - object-oriented interface
 * @export
 * @class ApplicationInvitationApi
 * @extends {BaseAPI}
 */
export class ApplicationInvitationApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInvitationApi
     */
    public resendApplicationInvitation(userId: string, options?: RawAxiosRequestConfig) {
        return ApplicationInvitationApiFp(this.configuration).resendApplicationInvitation(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitApplicationInvitation} submitApplicationInvitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInvitationApi
     */
    public submitApplicationInvitation(submitApplicationInvitation: SubmitApplicationInvitation, options?: RawAxiosRequestConfig) {
        return ApplicationInvitationApiFp(this.configuration).submitApplicationInvitation(submitApplicationInvitation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AresApi - axios parameter creator
 * @export
 */
export const AresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to provide data for company or supplier creation process
         * @summary Get information about subject from ares based on ICO parameter\"
         * @param {string} ico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSubjectDetailsFromAres: async (ico: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ico' is not null or undefined
            assertParamExists('fetchSubjectDetailsFromAres', 'ico', ico)
            const localVarPath = `/api/v1/ares`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ico !== undefined) {
                localVarQueryParameter['ico'] = ico;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AresApi - functional programming interface
 * @export
 */
export const AresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AresApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to provide data for company or supplier creation process
         * @summary Get information about subject from ares based on ICO parameter\"
         * @param {string} ico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSubjectDetailsFromAres(ico: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSubjectDetailsFromAres(ico, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AresApi.fetchSubjectDetailsFromAres']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AresApi - factory interface
 * @export
 */
export const AresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AresApiFp(configuration)
    return {
        /**
         * The purpose of this method is to provide data for company or supplier creation process
         * @summary Get information about subject from ares based on ICO parameter\"
         * @param {string} ico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSubjectDetailsFromAres(ico: string, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.fetchSubjectDetailsFromAres(ico, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AresApi - object-oriented interface
 * @export
 * @class AresApi
 * @extends {BaseAPI}
 */
export class AresApi extends BaseAPI {
    /**
     * The purpose of this method is to provide data for company or supplier creation process
     * @summary Get information about subject from ares based on ICO parameter\"
     * @param {string} ico 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AresApi
     */
    public fetchSubjectDetailsFromAres(ico: string, options?: RawAxiosRequestConfig) {
        return AresApiFp(this.configuration).fetchSubjectDetailsFromAres(ico, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid credentials
         * @summary Issue access and refresh token
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessToken: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('accessToken', 'loginRequest', loginRequest)
            const localVarPath = `/api/v1/auth/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid refresh token
         * @summary Issue access and refresh token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest: RefreshTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('refreshToken', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary issue access token for remote access
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteToken: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('remoteToken', 'token', token)
            const localVarPath = `/api/v1/auth/remote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid credentials
         * @summary Issue access and refresh token
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accessToken(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accessToken(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.accessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid refresh token
         * @summary Issue access and refresh token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary issue access token for remote access
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteToken(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteToken(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.remoteToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid credentials
         * @summary Issue access and refresh token
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessToken(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.accessToken(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this endpoint is to grant access and refresh token for any available user with valid refresh token
         * @summary Issue access and refresh token
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary issue access token for remote access
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteToken(token: string, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.remoteToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * The purpose of this endpoint is to grant access and refresh token for any available user with valid credentials
     * @summary Issue access and refresh token
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public accessToken(loginRequest: LoginRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).accessToken(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this endpoint is to grant access and refresh token for any available user with valid refresh token
     * @summary Issue access and refresh token
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshToken(refreshTokenRequest: RefreshTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).refreshToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary issue access token for remote access
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public remoteToken(token: string, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).remoteToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CertificateApi - axios parameter creator
 * @export
 */
export const CertificateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create new certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create certificates for given company
         * @param {string} company 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCertificate: async (company: string, certificateDto: CertificateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createCertificate', 'company', company)
            // verify required parameter 'certificateDto' is not null or undefined
            assertParamExists('createCertificate', 'certificateDto', certificateDto)
            const localVarPath = `/api/v1/companies/{company}/certificates`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to fetch certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get certificate
         * @param {string} certificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate: async (certificate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificate' is not null or undefined
            assertParamExists('getCertificate', 'certificate', certificate)
            const localVarPath = `/api/v1/certificates/{certificate}`
                .replace(`{${"certificate"}}`, encodeURIComponent(String(certificate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all certificates. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all certificates for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryCertificatesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCertificates: async (company: string, q?: string, state?: QueryCertificatesStateEnum, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryCertificates', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/certificates`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update certificate
         * @param {string} certificate 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCertificate: async (certificate: string, certificateDto: CertificateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'certificate' is not null or undefined
            assertParamExists('updateCertificate', 'certificate', certificate)
            // verify required parameter 'certificateDto' is not null or undefined
            assertParamExists('updateCertificate', 'certificateDto', certificateDto)
            const localVarPath = `/api/v1/certificates/{certificate}`
                .replace(`{${"certificate"}}`, encodeURIComponent(String(certificate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(certificateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateApi - functional programming interface
 * @export
 */
export const CertificateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create new certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create certificates for given company
         * @param {string} company 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCertificate(company: string, certificateDto: CertificateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCertificate(company, certificateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateApi.createCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to fetch certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get certificate
         * @param {string} certificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificate(certificate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CertificateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificate(certificate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateApi.getCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all certificates. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all certificates for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryCertificatesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCertificates(company: string, q?: string, state?: QueryCertificatesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCertificateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCertificates(company, q, state, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateApi.queryCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update certificate
         * @param {string} certificate 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCertificate(certificate: string, certificateDto: CertificateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCertificate(certificate, certificateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CertificateApi.updateCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CertificateApi - factory interface
 * @export
 */
export const CertificateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create new certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create certificates for given company
         * @param {string} company 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCertificate(company: string, certificateDto: CertificateDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createCertificate(company, certificateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to fetch certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get certificate
         * @param {string} certificate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(certificate: string, options?: any): AxiosPromise<CertificateDto> {
            return localVarFp.getCertificate(certificate, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all certificates. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all certificates for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryCertificatesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCertificates(company: string, q?: string, state?: QueryCertificatesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCertificateDto> {
            return localVarFp.queryCertificates(company, q, state, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update certificate. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update certificate
         * @param {string} certificate 
         * @param {CertificateDto} certificateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCertificate(certificate: string, certificateDto: CertificateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCertificate(certificate, certificateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertificateApi - object-oriented interface
 * @export
 * @class CertificateApi
 * @extends {BaseAPI}
 */
export class CertificateApi extends BaseAPI {
    /**
     * The purpose of this method is to create new certificate. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Create certificates for given company
     * @param {string} company 
     * @param {CertificateDto} certificateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public createCertificate(company: string, certificateDto: CertificateDto, options?: RawAxiosRequestConfig) {
        return CertificateApiFp(this.configuration).createCertificate(company, certificateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to fetch certificate. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Get certificate
     * @param {string} certificate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public getCertificate(certificate: string, options?: RawAxiosRequestConfig) {
        return CertificateApiFp(this.configuration).getCertificate(certificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all certificates. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Get all certificates for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryCertificatesStateEnum} [state] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public queryCertificates(company: string, q?: string, state?: QueryCertificatesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return CertificateApiFp(this.configuration).queryCertificates(company, q, state, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update certificate. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Update certificate
     * @param {string} certificate 
     * @param {CertificateDto} certificateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public updateCertificate(certificate: string, certificateDto: CertificateDto, options?: RawAxiosRequestConfig) {
        return CertificateApiFp(this.configuration).updateCertificate(certificate, certificateDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryCertificatesStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryCertificatesStateEnum = typeof QueryCertificatesStateEnum[keyof typeof QueryCertificatesStateEnum];


/**
 * CodeListApi - axios parameter creator
 * @export
 */
export const CodeListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ${api.languages.create.detail
         * @summary Create new test language
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage: async (languageDto: LanguageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageDto' is not null or undefined
            assertParamExists('createLanguage', 'languageDto', languageDto)
            const localVarPath = `/api/v1/codelists/test-languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ${api.languages.delete.detail
         * @summary Delete existing language
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteLanguage', 'code', code)
            const localVarPath = `/api/v1/codelists/test-languages/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint lists all languages for company that can be used as test languages
         * @summary Get all test languages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryLanguages: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/codelists/test-languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ${api.languages.update.detail
         * @summary Update existing language
         * @param {string} code 
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage: async (code: string, languageDto: LanguageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateLanguage', 'code', code)
            // verify required parameter 'languageDto' is not null or undefined
            assertParamExists('updateLanguage', 'languageDto', languageDto)
            const localVarPath = `/api/v1/codelists/test-languages/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeListApi - functional programming interface
 * @export
 */
export const CodeListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeListApiAxiosParamCreator(configuration)
    return {
        /**
         * ${api.languages.create.detail
         * @summary Create new test language
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLanguage(languageDto: LanguageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLanguage(languageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeListApi.createLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ${api.languages.delete.detail
         * @summary Delete existing language
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLanguage(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLanguage(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeListApi.deleteLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint lists all languages for company that can be used as test languages
         * @summary Get all test languages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryLanguages(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLanguageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryLanguages(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeListApi.queryLanguages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ${api.languages.update.detail
         * @summary Update existing language
         * @param {string} code 
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLanguage(code: string, languageDto: LanguageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLanguage(code, languageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeListApi.updateLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CodeListApi - factory interface
 * @export
 */
export const CodeListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeListApiFp(configuration)
    return {
        /**
         * ${api.languages.create.detail
         * @summary Create new test language
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLanguage(languageDto: LanguageDto, options?: any): AxiosPromise<void> {
            return localVarFp.createLanguage(languageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * ${api.languages.delete.detail
         * @summary Delete existing language
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLanguage(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLanguage(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint lists all languages for company that can be used as test languages
         * @summary Get all test languages
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryLanguages(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLanguageDto> {
            return localVarFp.queryLanguages(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * ${api.languages.update.detail
         * @summary Update existing language
         * @param {string} code 
         * @param {LanguageDto} languageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage(code: string, languageDto: LanguageDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateLanguage(code, languageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeListApi - object-oriented interface
 * @export
 * @class CodeListApi
 * @extends {BaseAPI}
 */
export class CodeListApi extends BaseAPI {
    /**
     * ${api.languages.create.detail
     * @summary Create new test language
     * @param {LanguageDto} languageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeListApi
     */
    public createLanguage(languageDto: LanguageDto, options?: RawAxiosRequestConfig) {
        return CodeListApiFp(this.configuration).createLanguage(languageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ${api.languages.delete.detail
     * @summary Delete existing language
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeListApi
     */
    public deleteLanguage(code: string, options?: RawAxiosRequestConfig) {
        return CodeListApiFp(this.configuration).deleteLanguage(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint lists all languages for company that can be used as test languages
     * @summary Get all test languages
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeListApi
     */
    public queryLanguages(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return CodeListApiFp(this.configuration).queryLanguages(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ${api.languages.update.detail
     * @summary Update existing language
     * @param {string} code 
     * @param {LanguageDto} languageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeListApi
     */
    public updateLanguage(code: string, languageDto: LanguageDto, options?: RawAxiosRequestConfig) {
        return CodeListApiFp(this.configuration).updateLanguage(code, languageDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyRequest: CreateCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompany', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupplierEmployeeInCompany: async (companyId: string, email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('findSupplierEmployeeInCompany', 'companyId', companyId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('findSupplierEmployeeInCompany', 'email', email)
            const localVarPath = `/api/v1/companies/{companyId}/supplier-employee`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixCompanyIndex: async (company: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('fixCompanyIndex', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/index`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all companies
         * @summary Get all companies
         * @param {string} [q] value for fulltext search
         * @param {string} [licenseStartFrom] 
         * @param {string} [licenseStartTo] 
         * @param {string} [licenseEndFrom] 
         * @param {string} [licenseEndTo] 
         * @param {QueryCompaniesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCompanies: async (q?: string, licenseStartFrom?: string, licenseStartTo?: string, licenseEndFrom?: string, licenseEndTo?: string, state?: QueryCompaniesStateEnum, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (licenseStartFrom !== undefined) {
                localVarQueryParameter['licenseStartFrom'] = (licenseStartFrom as any instanceof Date) ?
                    (licenseStartFrom as any).toISOString().substring(0,10) :
                    licenseStartFrom;
            }

            if (licenseStartTo !== undefined) {
                localVarQueryParameter['licenseStartTo'] = (licenseStartTo as any instanceof Date) ?
                    (licenseStartTo as any).toISOString().substring(0,10) :
                    licenseStartTo;
            }

            if (licenseEndFrom !== undefined) {
                localVarQueryParameter['licenseEndFrom'] = (licenseEndFrom as any instanceof Date) ?
                    (licenseEndFrom as any).toISOString().substring(0,10) :
                    licenseEndFrom;
            }

            if (licenseEndTo !== undefined) {
                localVarQueryParameter['licenseEndTo'] = (licenseEndTo as any instanceof Date) ?
                    (licenseEndTo as any).toISOString().substring(0,10) :
                    licenseEndTo;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Update existing company
         * @param {string} companyId 
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: string, companyDto: CompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'companyDto' is not null or undefined
            assertParamExists('updateCompany', 'companyDto', companyDto)
            const localVarPath = `/api/v1/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyRequest: CreateCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSupplierEmployeeInCompany(companyId: string, email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierEmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSupplierEmployeeInCompany(companyId, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.findSupplierEmployeeInCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixCompanyIndex(company: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixCompanyIndex(company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.fixCompanyIndex']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all companies
         * @summary Get all companies
         * @param {string} [q] value for fulltext search
         * @param {string} [licenseStartFrom] 
         * @param {string} [licenseStartTo] 
         * @param {string} [licenseEndFrom] 
         * @param {string} [licenseEndTo] 
         * @param {QueryCompaniesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCompanies(q?: string, licenseStartFrom?: string, licenseStartTo?: string, licenseEndFrom?: string, licenseEndTo?: string, state?: QueryCompaniesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCompanyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCompanies(q, licenseStartFrom, licenseStartTo, licenseEndFrom, licenseEndTo, state, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.queryCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Update existing company
         * @param {string} companyId 
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: string, companyDto: CompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, companyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createCompany(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSupplierEmployeeInCompany(companyId: string, email: string, options?: any): AxiosPromise<SupplierEmployeeDto> {
            return localVarFp.findSupplierEmployeeInCompany(companyId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixCompanyIndex(company: string, options?: any): AxiosPromise<void> {
            return localVarFp.fixCompanyIndex(company, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update company out of given request body
         * @summary get existing company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: string, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.getCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all companies
         * @summary Get all companies
         * @param {string} [q] value for fulltext search
         * @param {string} [licenseStartFrom] 
         * @param {string} [licenseStartTo] 
         * @param {string} [licenseEndFrom] 
         * @param {string} [licenseEndTo] 
         * @param {QueryCompaniesStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCompanies(q?: string, licenseStartFrom?: string, licenseStartTo?: string, licenseEndFrom?: string, licenseEndTo?: string, state?: QueryCompaniesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCompanyListResponse> {
            return localVarFp.queryCompanies(q, licenseStartFrom, licenseStartTo, licenseEndFrom, licenseEndTo, state, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to create new company out of given request body
         * @summary Update existing company
         * @param {string} companyId 
         * @param {CompanyDto} companyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: string, companyDto: CompanyDto, options?: any): AxiosPromise<CompanyDto> {
            return localVarFp.updateCompany(companyId, companyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * The purpose of this method is to create new company out of given request body
     * @summary Create new company
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompany(createCompanyRequest: CreateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompany(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update company out of given request body
     * @summary get existing company
     * @param {string} companyId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public findSupplierEmployeeInCompany(companyId: string, email: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).findSupplierEmployeeInCompany(companyId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public fixCompanyIndex(company: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).fixCompanyIndex(company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update company out of given request body
     * @summary get existing company
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompany(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all companies
     * @summary Get all companies
     * @param {string} [q] value for fulltext search
     * @param {string} [licenseStartFrom] 
     * @param {string} [licenseStartTo] 
     * @param {string} [licenseEndFrom] 
     * @param {string} [licenseEndTo] 
     * @param {QueryCompaniesStateEnum} [state] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public queryCompanies(q?: string, licenseStartFrom?: string, licenseStartTo?: string, licenseEndFrom?: string, licenseEndTo?: string, state?: QueryCompaniesStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).queryCompanies(q, licenseStartFrom, licenseStartTo, licenseEndFrom, licenseEndTo, state, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to create new company out of given request body
     * @summary Update existing company
     * @param {string} companyId 
     * @param {CompanyDto} companyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(companyId: string, companyDto: CompanyDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompany(companyId, companyDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryCompaniesStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    PreActivated: 'PRE_ACTIVATED'
} as const;
export type QueryCompaniesStateEnum = typeof QueryCompaniesStateEnum[keyof typeof QueryCompaniesStateEnum];


/**
 * CompanyPagesApi - axios parameter creator
 * @export
 */
export const CompanyPagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add later
         * @summary get single page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyPage', 'id', id)
            const localVarPath = `/api/v1/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get all pages for companies
         * @param {string} companyId 
         * @param {QueryCompanyPagesTypeEnum} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCompanyPages: async (companyId: string, type?: QueryCompanyPagesTypeEnum, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('queryCompanyPages', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/pages`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * only content is updated, type and language is fixed property
         * @summary update company pages in batch
         * @param {BatchPageUpdate} batchPageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPage: async (batchPageUpdate: BatchPageUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchPageUpdate' is not null or undefined
            assertParamExists('updateCompanyPage', 'batchPageUpdate', batchPageUpdate)
            const localVarPath = `/api/v1/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchPageUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyPagesApi - functional programming interface
 * @export
 */
export const CompanyPagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyPagesApiAxiosParamCreator(configuration)
    return {
        /**
         * add later
         * @summary get single page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyPage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyPage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyPagesApi.getCompanyPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get all pages for companies
         * @param {string} companyId 
         * @param {QueryCompanyPagesTypeEnum} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryCompanyPages(companyId: string, type?: QueryCompanyPagesTypeEnum, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyPageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryCompanyPages(companyId, type, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyPagesApi.queryCompanyPages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * only content is updated, type and language is fixed property
         * @summary update company pages in batch
         * @param {BatchPageUpdate} batchPageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyPage(batchPageUpdate: BatchPageUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyPage(batchPageUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyPagesApi.updateCompanyPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyPagesApi - factory interface
 * @export
 */
export const CompanyPagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyPagesApiFp(configuration)
    return {
        /**
         * add later
         * @summary get single page
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPage(id: string, options?: any): AxiosPromise<CompanyPageDto> {
            return localVarFp.getCompanyPage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get all pages for companies
         * @param {string} companyId 
         * @param {QueryCompanyPagesTypeEnum} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCompanyPages(companyId: string, type?: QueryCompanyPagesTypeEnum, language?: string, options?: any): AxiosPromise<Array<CompanyPageDto>> {
            return localVarFp.queryCompanyPages(companyId, type, language, options).then((request) => request(axios, basePath));
        },
        /**
         * only content is updated, type and language is fixed property
         * @summary update company pages in batch
         * @param {BatchPageUpdate} batchPageUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPage(batchPageUpdate: BatchPageUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompanyPage(batchPageUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyPagesApi - object-oriented interface
 * @export
 * @class CompanyPagesApi
 * @extends {BaseAPI}
 */
export class CompanyPagesApi extends BaseAPI {
    /**
     * add later
     * @summary get single page
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyPagesApi
     */
    public getCompanyPage(id: string, options?: RawAxiosRequestConfig) {
        return CompanyPagesApiFp(this.configuration).getCompanyPage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get all pages for companies
     * @param {string} companyId 
     * @param {QueryCompanyPagesTypeEnum} [type] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyPagesApi
     */
    public queryCompanyPages(companyId: string, type?: QueryCompanyPagesTypeEnum, language?: string, options?: RawAxiosRequestConfig) {
        return CompanyPagesApiFp(this.configuration).queryCompanyPages(companyId, type, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * only content is updated, type and language is fixed property
     * @summary update company pages in batch
     * @param {BatchPageUpdate} batchPageUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyPagesApi
     */
    public updateCompanyPage(batchPageUpdate: BatchPageUpdate, options?: RawAxiosRequestConfig) {
        return CompanyPagesApiFp(this.configuration).updateCompanyPage(batchPageUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryCompanyPagesTypeEnum = {
    SupplierManager: 'SUPPLIER_MANAGER',
    SupplierEmployee: 'SUPPLIER_EMPLOYEE',
    Visit: 'VISIT',
    AfterTraining: 'AFTER_TRAINING'
} as const;
export type QueryCompanyPagesTypeEnum = typeof QueryCompanyPagesTypeEnum[keyof typeof QueryCompanyPagesTypeEnum];


/**
 * CompanySettingsApi - axios parameter creator
 * @export
 */
export const CompanySettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * used to check if operation can proceed
         * @summary check options for company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOptions: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('checkOptions', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/check`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary get business settings of company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessSettings: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBusinessSettings', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/business-settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary get basic settings available for company admin
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getSettings', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}/settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary update business settings of company
         * @param {string} companyId 
         * @param {CompanyBusinessSettings} companyBusinessSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessSettings: async (companyId: string, companyBusinessSettings: CompanyBusinessSettings, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateBusinessSettings', 'companyId', companyId)
            // verify required parameter 'companyBusinessSettings' is not null or undefined
            assertParamExists('updateBusinessSettings', 'companyBusinessSettings', companyBusinessSettings)
            const localVarPath = `/api/v1/companies/{companyId}/business-settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyBusinessSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary update basic settings available for company admin
         * @param {string} companyId 
         * @param {CompanySettingsDto} companySettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (companyId: string, companySettingsDto: CompanySettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateSettings', 'companyId', companyId)
            // verify required parameter 'companySettingsDto' is not null or undefined
            assertParamExists('updateSettings', 'companySettingsDto', companySettingsDto)
            const localVarPath = `/api/v1/companies/{companyId}/settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanySettingsApi - functional programming interface
 * @export
 */
export const CompanySettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanySettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * used to check if operation can proceed
         * @summary check options for company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkOptions(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkOptions(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanySettingsApi.checkOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary get business settings of company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessSettings(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyBusinessSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessSettings(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanySettingsApi.getBusinessSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary get basic settings available for company admin
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanySettingsApi.getSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary update business settings of company
         * @param {string} companyId 
         * @param {CompanyBusinessSettings} companyBusinessSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusinessSettings(companyId: string, companyBusinessSettings: CompanyBusinessSettings, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessSettings(companyId, companyBusinessSettings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanySettingsApi.updateBusinessSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary update basic settings available for company admin
         * @param {string} companyId 
         * @param {CompanySettingsDto} companySettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(companyId: string, companySettingsDto: CompanySettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(companyId, companySettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanySettingsApi.updateSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanySettingsApi - factory interface
 * @export
 */
export const CompanySettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanySettingsApiFp(configuration)
    return {
        /**
         * used to check if operation can proceed
         * @summary check options for company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOptions(companyId: string, options?: any): AxiosPromise<CheckOptionsResponse> {
            return localVarFp.checkOptions(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary get business settings of company
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessSettings(companyId: string, options?: any): AxiosPromise<CompanyBusinessSettings> {
            return localVarFp.getBusinessSettings(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary get basic settings available for company admin
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(companyId: string, options?: any): AxiosPromise<CompanySettingsDto> {
            return localVarFp.getSettings(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary update business settings of company
         * @param {string} companyId 
         * @param {CompanyBusinessSettings} companyBusinessSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessSettings(companyId: string, companyBusinessSettings: CompanyBusinessSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateBusinessSettings(companyId, companyBusinessSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary update basic settings available for company admin
         * @param {string} companyId 
         * @param {CompanySettingsDto} companySettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(companyId: string, companySettingsDto: CompanySettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSettings(companyId, companySettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanySettingsApi - object-oriented interface
 * @export
 * @class CompanySettingsApi
 * @extends {BaseAPI}
 */
export class CompanySettingsApi extends BaseAPI {
    /**
     * used to check if operation can proceed
     * @summary check options for company
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySettingsApi
     */
    public checkOptions(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanySettingsApiFp(this.configuration).checkOptions(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary get business settings of company
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySettingsApi
     */
    public getBusinessSettings(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanySettingsApiFp(this.configuration).getBusinessSettings(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary get basic settings available for company admin
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySettingsApi
     */
    public getSettings(companyId: string, options?: RawAxiosRequestConfig) {
        return CompanySettingsApiFp(this.configuration).getSettings(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary update business settings of company
     * @param {string} companyId 
     * @param {CompanyBusinessSettings} companyBusinessSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySettingsApi
     */
    public updateBusinessSettings(companyId: string, companyBusinessSettings: CompanyBusinessSettings, options?: RawAxiosRequestConfig) {
        return CompanySettingsApiFp(this.configuration).updateBusinessSettings(companyId, companyBusinessSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary update basic settings available for company admin
     * @param {string} companyId 
     * @param {CompanySettingsDto} companySettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanySettingsApi
     */
    public updateSettings(companyId: string, companySettingsDto: CompanySettingsDto, options?: RawAxiosRequestConfig) {
        return CompanySettingsApiFp(this.configuration).updateSettings(companyId, companySettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get dashboard data
         * @param {string} id 
         * @param {string} [establishmentForStats] Pozvánky ve stavu -  možnost filtrovat na konkrétní provozovnu
         * @param {string} [establishmentForAllowedToEnter] Povolení ke vstupu možnost filtrovat na konkrétní provozovnu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData: async (id: string, establishmentForStats?: string, establishmentForAllowedToEnter?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getData', 'id', id)
            const localVarPath = `/companies/{id}/dashboard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (establishmentForStats !== undefined) {
                localVarQueryParameter['establishmentForStats'] = establishmentForStats;
            }

            if (establishmentForAllowedToEnter !== undefined) {
                localVarQueryParameter['establishmentForAllowedToEnter'] = establishmentForAllowedToEnter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get dashboard data
         * @param {string} id 
         * @param {string} [establishmentForStats] Pozvánky ve stavu -  možnost filtrovat na konkrétní provozovnu
         * @param {string} [establishmentForAllowedToEnter] Povolení ke vstupu možnost filtrovat na konkrétní provozovnu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData(id: string, establishmentForStats?: string, establishmentForAllowedToEnter?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getData(id, establishmentForStats, establishmentForAllowedToEnter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.getData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary get dashboard data
         * @param {string} id 
         * @param {string} [establishmentForStats] Pozvánky ve stavu -  možnost filtrovat na konkrétní provozovnu
         * @param {string} [establishmentForAllowedToEnter] Povolení ke vstupu možnost filtrovat na konkrétní provozovnu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData(id: string, establishmentForStats?: string, establishmentForAllowedToEnter?: string, options?: any): AxiosPromise<DashboardResponse> {
            return localVarFp.getData(id, establishmentForStats, establishmentForAllowedToEnter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary get dashboard data
     * @param {string} id 
     * @param {string} [establishmentForStats] Pozvánky ve stavu -  možnost filtrovat na konkrétní provozovnu
     * @param {string} [establishmentForAllowedToEnter] Povolení ke vstupu možnost filtrovat na konkrétní provozovnu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getData(id: string, establishmentForStats?: string, establishmentForAllowedToEnter?: string, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getData(id, establishmentForStats, establishmentForAllowedToEnter, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmergencyApi - axios parameter creator
 * @export
 */
export const EmergencyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create emergency for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Create emergency
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergency: async (company: string, emergencyDto: EmergencyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createEmergency', 'company', company)
            // verify required parameter 'emergencyDto' is not null or undefined
            assertParamExists('createEmergency', 'emergencyDto', emergencyDto)
            const localVarPath = `/companies/{company}/emergencies`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to end emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary End emergency emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endEmergency: async (emergency: string, emergencyDto: EmergencyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('endEmergency', 'emergency', emergency)
            // verify required parameter 'emergencyDto' is not null or undefined
            assertParamExists('endEmergency', 'emergencyDto', emergencyDto)
            const localVarPath = `/emergencies/{emergency}/end-emergency`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export missing persons
         * @summary Export missing persons
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployeesEmergency: async (emergency: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('exportEmployeesEmergency', 'emergency', emergency)
            const localVarPath = `/emergencies/{emergency}/export`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get emergency
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (emergency: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('findById', 'emergency', emergency)
            const localVarPath = `/emergencies/{emergency}`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to delete missing person from the list for given emergency. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Delete missing person from the list
         * @param {string} emergency 
         * @param {string} emergencyPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundPerson: async (emergency: string, emergencyPerson: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('foundPerson', 'emergency', emergency)
            // verify required parameter 'emergencyPerson' is not null or undefined
            assertParamExists('foundPerson', 'emergencyPerson', emergencyPerson)
            const localVarPath = `/emergencies/{emergency}/{emergencyPerson}`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)))
                .replace(`{${"emergencyPerson"}}`, encodeURIComponent(String(emergencyPerson)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get shareable link for emergency
         * @param {string} emergency 
         * @param {IssueRemoteTokenRequest} issueRemoteTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmergencyLink: async (emergency: string, issueRemoteTokenRequest: IssueRemoteTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('getEmergencyLink', 'emergency', emergency)
            // verify required parameter 'issueRemoteTokenRequest' is not null or undefined
            assertParamExists('getEmergencyLink', 'issueRemoteTokenRequest', issueRemoteTokenRequest)
            const localVarPath = `/emergencies/{emergency}/link`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(issueRemoteTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ${api.emergency.getEmail.detail}
         * @summary ${api.emergency.getEmail.title}
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage: async (company: string, emergencyDto: EmergencyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('getMessage', 'company', company)
            // verify required parameter 'emergencyDto' is not null or undefined
            assertParamExists('getMessage', 'emergencyDto', emergencyDto)
            const localVarPath = `/companies/{company}/emergencies/email`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ${api.emergency.isEnableToCreate.detail}
         * @summary Check if there are not already active emergencies
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEnabledToCreate: async (company: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('isEnabledToCreate', 'company', company)
            const localVarPath = `/companies/{company}/emergencies/create-enable`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide emergencies for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all emergencies
         * @param {string} company 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEmergency: async (company: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryEmergency', 'company', company)
            const localVarPath = `/companies/{company}/emergencies`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Update emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmergency: async (emergency: string, emergencyDto: EmergencyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emergency' is not null or undefined
            assertParamExists('updateEmergency', 'emergency', emergency)
            // verify required parameter 'emergencyDto' is not null or undefined
            assertParamExists('updateEmergency', 'emergencyDto', emergencyDto)
            const localVarPath = `/emergencies/{emergency}`
                .replace(`{${"emergency"}}`, encodeURIComponent(String(emergency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emergencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmergencyApi - functional programming interface
 * @export
 */
export const EmergencyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmergencyApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create emergency for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Create emergency
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmergency(company: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmergency(company, emergencyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.createEmergency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to end emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary End emergency emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endEmergency(emergency: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endEmergency(emergency, emergencyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.endEmergency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Export missing persons
         * @summary Export missing persons
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEmployeesEmergency(emergency: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEmployeesEmergency(emergency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.exportEmployeesEmergency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get emergency
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(emergency: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findById(emergency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.findById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to delete missing person from the list for given emergency. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Delete missing person from the list
         * @param {string} emergency 
         * @param {string} emergencyPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async foundPerson(emergency: string, emergencyPerson: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.foundPerson(emergency, emergencyPerson, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.foundPerson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get shareable link for emergency
         * @param {string} emergency 
         * @param {IssueRemoteTokenRequest} issueRemoteTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmergencyLink(emergency: string, issueRemoteTokenRequest: IssueRemoteTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleValueResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmergencyLink(emergency, issueRemoteTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.getEmergencyLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ${api.emergency.getEmail.detail}
         * @summary ${api.emergency.getEmail.title}
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage(company: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmergencyResponseTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage(company, emergencyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.getMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ${api.emergency.isEnableToCreate.detail}
         * @summary Check if there are not already active emergencies
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isEnabledToCreate(company: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleValueResponseUUID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEnabledToCreate(company, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.isEnabledToCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide emergencies for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all emergencies
         * @param {string} company 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEmergency(company: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEmergencyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryEmergency(company, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.queryEmergency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Update emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmergency(emergency: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmergency(emergency, emergencyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmergencyApi.updateEmergency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmergencyApi - factory interface
 * @export
 */
export const EmergencyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmergencyApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create emergency for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Create emergency
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmergency(company: string, emergencyDto: EmergencyDto, options?: any): AxiosPromise<EmergencyDto> {
            return localVarFp.createEmergency(company, emergencyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to end emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary End emergency emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endEmergency(emergency: string, emergencyDto: EmergencyDto, options?: any): AxiosPromise<void> {
            return localVarFp.endEmergency(emergency, emergencyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Export missing persons
         * @summary Export missing persons
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployeesEmergency(emergency: string, options?: any): AxiosPromise<File> {
            return localVarFp.exportEmployeesEmergency(emergency, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get emergency
         * @param {string} emergency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById(emergency: string, options?: any): AxiosPromise<EmergencyDto> {
            return localVarFp.findById(emergency, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to delete missing person from the list for given emergency. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Delete missing person from the list
         * @param {string} emergency 
         * @param {string} emergencyPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        foundPerson(emergency: string, emergencyPerson: string, options?: any): AxiosPromise<void> {
            return localVarFp.foundPerson(emergency, emergencyPerson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get shareable link for emergency
         * @param {string} emergency 
         * @param {IssueRemoteTokenRequest} issueRemoteTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmergencyLink(emergency: string, issueRemoteTokenRequest: IssueRemoteTokenRequest, options?: any): AxiosPromise<SingleValueResponseString> {
            return localVarFp.getEmergencyLink(emergency, issueRemoteTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ${api.emergency.getEmail.detail}
         * @summary ${api.emergency.getEmail.title}
         * @param {string} company 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage(company: string, emergencyDto: EmergencyDto, options?: any): AxiosPromise<EmergencyResponseTemplate> {
            return localVarFp.getMessage(company, emergencyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * ${api.emergency.isEnableToCreate.detail}
         * @summary Check if there are not already active emergencies
         * @param {string} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEnabledToCreate(company: string, options?: any): AxiosPromise<SingleValueResponseUUID> {
            return localVarFp.isEnabledToCreate(company, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide emergencies for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all emergencies
         * @param {string} company 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEmergency(company: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEmergencyDto> {
            return localVarFp.queryEmergency(company, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Update emergency
         * @param {string} emergency 
         * @param {EmergencyDto} emergencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmergency(emergency: string, emergencyDto: EmergencyDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmergency(emergency, emergencyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmergencyApi - object-oriented interface
 * @export
 * @class EmergencyApi
 * @extends {BaseAPI}
 */
export class EmergencyApi extends BaseAPI {
    /**
     * The purpose of this method is to create emergency for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Create emergency
     * @param {string} company 
     * @param {EmergencyDto} emergencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public createEmergency(company: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).createEmergency(company, emergencyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to end emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary End emergency emergency
     * @param {string} emergency 
     * @param {EmergencyDto} emergencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public endEmergency(emergency: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).endEmergency(emergency, emergencyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export missing persons
     * @summary Export missing persons
     * @param {string} emergency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public exportEmployeesEmergency(emergency: string, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).exportEmployeesEmergency(emergency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Get emergency
     * @param {string} emergency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public findById(emergency: string, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).findById(emergency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to delete missing person from the list for given emergency. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Delete missing person from the list
     * @param {string} emergency 
     * @param {string} emergencyPerson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public foundPerson(emergency: string, emergencyPerson: string, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).foundPerson(emergency, emergencyPerson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get shareable link for emergency
     * @param {string} emergency 
     * @param {IssueRemoteTokenRequest} issueRemoteTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public getEmergencyLink(emergency: string, issueRemoteTokenRequest: IssueRemoteTokenRequest, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).getEmergencyLink(emergency, issueRemoteTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ${api.emergency.getEmail.detail}
     * @summary ${api.emergency.getEmail.title}
     * @param {string} company 
     * @param {EmergencyDto} emergencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public getMessage(company: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).getMessage(company, emergencyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ${api.emergency.isEnableToCreate.detail}
     * @summary Check if there are not already active emergencies
     * @param {string} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public isEnabledToCreate(company: string, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).isEnabledToCreate(company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide emergencies for given company. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Get all emergencies
     * @param {string} company 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public queryEmergency(company: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).queryEmergency(company, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update emergency for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Update emergency
     * @param {string} emergency 
     * @param {EmergencyDto} emergencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmergencyApi
     */
    public updateEmergency(emergency: string, emergencyDto: EmergencyDto, options?: RawAxiosRequestConfig) {
        return EmergencyApiFp(this.configuration).updateEmergency(emergency, emergencyDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add later
         * @summary create new employee
         * @param {string} company 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee: async (company: string, employeeDto: EmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createEmployee', 'company', company)
            // verify required parameter 'employeeDto' is not null or undefined
            assertParamExists('createEmployee', 'employeeDto', employeeDto)
            const localVarPath = `/api/v1/companies/{company}/employees`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export employees
         * @summary Export employees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployees: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEmployees', 'id', id)
            const localVarPath = `/api/v1/companies/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get emplozee
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployee: async (employee: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('getEmployee', 'employee', employee)
            const localVarPath = `/api/v1/employees/{employee}`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import employees from csv file
         * @summary Import employees from csv file
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEmployees: async (id: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importEmployees', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importEmployees', 'file', file)
            const localVarPath = `/api/v1/companies/{id}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary get all employees
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEmployeesStateEnum} [state] 
         * @param {boolean} [forInvite] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEmployees: async (company: string, q?: string, state?: QueryEmployeesStateEnum, forInvite?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryEmployees', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/employees`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (forInvite !== undefined) {
                localVarQueryParameter['forInvite'] = forInvite;
            }

            if (establishments) {
                localVarQueryParameter['establishments'] = establishments;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Update employee
         * @param {string} employee 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (employee: string, employeeDto: EmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('updateEmployee', 'employee', employee)
            // verify required parameter 'employeeDto' is not null or undefined
            assertParamExists('updateEmployee', 'employeeDto', employeeDto)
            const localVarPath = `/api/v1/employees/{employee}`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * add later
         * @summary create new employee
         * @param {string} company 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmployee(company: string, employeeDto: EmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmployee(company, employeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.createEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Export employees
         * @summary Export employees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEmployees(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEmployees(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.exportEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get emplozee
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployee(employee: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployee(employee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.getEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Import employees from csv file
         * @summary Import employees from csv file
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEmployees(id: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEmployees(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.importEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary get all employees
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEmployeesStateEnum} [state] 
         * @param {boolean} [forInvite] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEmployees(company: string, q?: string, state?: QueryEmployeesStateEnum, forInvite?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryEmployees(company, q, state, forInvite, establishments, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.queryEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Update employee
         * @param {string} employee 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(employee: string, employeeDto: EmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(employee, employeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EmployeeApi.updateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * add later
         * @summary create new employee
         * @param {string} company 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmployee(company: string, employeeDto: EmployeeDto, options?: any): AxiosPromise<EmployeeDto> {
            return localVarFp.createEmployee(company, employeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Export employees
         * @summary Export employees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEmployees(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.exportEmployees(id, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get emplozee
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployee(employee: string, options?: any): AxiosPromise<EmployeeDto> {
            return localVarFp.getEmployee(employee, options).then((request) => request(axios, basePath));
        },
        /**
         * Import employees from csv file
         * @summary Import employees from csv file
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEmployees(id: string, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.importEmployees(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary get all employees
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEmployeesStateEnum} [state] 
         * @param {boolean} [forInvite] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEmployees(company: string, q?: string, state?: QueryEmployeesStateEnum, forInvite?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEmployeeDto> {
            return localVarFp.queryEmployees(company, q, state, forInvite, establishments, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Update employee
         * @param {string} employee 
         * @param {EmployeeDto} employeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(employee: string, employeeDto: EmployeeDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEmployee(employee, employeeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * add later
     * @summary create new employee
     * @param {string} company 
     * @param {EmployeeDto} employeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public createEmployee(company: string, employeeDto: EmployeeDto, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).createEmployee(company, employeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export employees
     * @summary Export employees
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public exportEmployees(id: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).exportEmployees(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get emplozee
     * @param {string} employee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public getEmployee(employee: string, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).getEmployee(employee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import employees from csv file
     * @summary Import employees from csv file
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public importEmployees(id: string, file: File, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).importEmployees(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary get all employees
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryEmployeesStateEnum} [state] 
     * @param {boolean} [forInvite] 
     * @param {Array<string>} [establishments] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public queryEmployees(company: string, q?: string, state?: QueryEmployeesStateEnum, forInvite?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).queryEmployees(company, q, state, forInvite, establishments, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Update employee
     * @param {string} employee 
     * @param {EmployeeDto} employeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public updateEmployee(employee: string, employeeDto: EmployeeDto, options?: RawAxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).updateEmployee(employee, employeeDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryEmployeesStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryEmployeesStateEnum = typeof QueryEmployeesStateEnum[keyof typeof QueryEmployeesStateEnum];


/**
 * EstablishmentApi - axios parameter creator
 * @export
 */
export const EstablishmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create establishment
         * @param {string} company 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstablishment: async (company: string, establishmentDto: EstablishmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createEstablishment', 'company', company)
            // verify required parameter 'establishmentDto' is not null or undefined
            assertParamExists('createEstablishment', 'establishmentDto', establishmentDto)
            const localVarPath = `/api/v1/companies/{company}/establishments`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(establishmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstablishment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEstablishment', 'id', id)
            const localVarPath = `/api/v1/establishments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} company 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestEstablishmentsUsedBy: async (company: string, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('getLatestEstablishmentsUsedBy', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/establishments/last-used`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstablishments: async (company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryEstablishments', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/establishments`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAllEstablishments: async (company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryEstablishments', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/allEstablishments`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update establishment
         * @param {string} id 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstablishment: async (id: string, establishmentDto: EstablishmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEstablishment', 'id', id)
            // verify required parameter 'establishmentDto' is not null or undefined
            assertParamExists('updateEstablishment', 'establishmentDto', establishmentDto)
            const localVarPath = `/api/v1/establishments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(establishmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstablishmentApi - functional programming interface
 * @export
 */
export const EstablishmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstablishmentApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create establishment
         * @param {string} company 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstablishment(company: string, establishmentDto: EstablishmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstablishment(company, establishmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.createEstablishment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstablishment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstablishmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstablishment(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.getEstablishment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} company 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestEstablishmentsUsedBy(company: string, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestEstablishmentsUsedBy(company, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.getLatestEstablishmentsUsedBy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEstablishmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryEstablishments(company, q, state, nameOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.queryEstablishments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryAllEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEstablishmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryAllEstablishments(company, q, state, nameOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.queryEstablishments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update establishment
         * @param {string} id 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstablishment(id: string, establishmentDto: EstablishmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstablishment(id, establishmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstablishmentApi.updateEstablishment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EstablishmentApi - factory interface
 * @export
 */
export const EstablishmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstablishmentApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Create establishment
         * @param {string} company 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstablishment(company: string, establishmentDto: EstablishmentDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createEstablishment(company, establishmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstablishment(id: string, options?: any): AxiosPromise<EstablishmentDto> {
            return localVarFp.getEstablishment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Get establishment
         * @param {string} company 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestEstablishmentsUsedBy(company: string, userId?: string, options?: any): AxiosPromise<Array<ReferenceDto>> {
            return localVarFp.getLatestEstablishmentsUsedBy(company, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEstablishmentDto> {
            return localVarFp.queryEstablishments(company, q, state, nameOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
         * @summary Get all establishments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryEstablishmentsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryAllEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEstablishmentDto> {
            return localVarFp.queryAllEstablishments(company, q, state, nameOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
         * @summary Update establishment
         * @param {string} id 
         * @param {EstablishmentDto} establishmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstablishment(id: string, establishmentDto: EstablishmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateEstablishment(id, establishmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EstablishmentApi - object-oriented interface
 * @export
 * @class EstablishmentApi
 * @extends {BaseAPI}
 */
export class EstablishmentApi extends BaseAPI {
    /**
     * The purpose of this method is to create establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Create establishment
     * @param {string} company 
     * @param {EstablishmentDto} establishmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
    public createEstablishment(company: string, establishmentDto: EstablishmentDto, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).createEstablishment(company, establishmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Get establishment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
    public getEstablishment(id: string, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).getEstablishment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide establishment for given company. Method is restricted to MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Get establishment
     * @param {string} company 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
    public getLatestEstablishmentsUsedBy(company: string, userId?: string, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).getLatestEstablishmentsUsedBy(company, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all activities. Method is restricted to roles MANAGER_OSAH and MANAGER_WORKPLACE
     * This method filters establishments assigned to the user
     * @summary Get all establishments for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryEstablishmentsStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
    public queryEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).queryEstablishments(company, q, state, nameOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

     /**
     * The purpose of this method is to provide list of all activities.
     * This method is for Admins only and does not filter for assigned establishments
     * @summary Get all establishments for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryEstablishmentsStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
     public queryAllEstablishments(company: string, q?: string, state?: QueryEstablishmentsStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).queryAllEstablishments(company, q, state, nameOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update establishment. Method is restricted to roles MANAGER_OSAH, MANAGER_WORKPLACE
     * @summary Update establishment
     * @param {string} id 
     * @param {EstablishmentDto} establishmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstablishmentApi
     */
    public updateEstablishment(id: string, establishmentDto: EstablishmentDto, options?: RawAxiosRequestConfig) {
        return EstablishmentApiFp(this.configuration).updateEstablishment(id, establishmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryEstablishmentsStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryEstablishmentsStateEnum = typeof QueryEstablishmentsStateEnum[keyof typeof QueryEstablishmentsStateEnum];


/**
 * ForgottenPasswordApi - axios parameter creator
 * @export
 */
export const ForgottenPasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgottenPasswordRequest} forgottenPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestForgottenPassword: async (forgottenPasswordRequest: ForgottenPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgottenPasswordRequest' is not null or undefined
            assertParamExists('requestForgottenPassword', 'forgottenPasswordRequest', forgottenPasswordRequest)
            const localVarPath = `/forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitForgottenPassword} submitForgottenPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForgottenPassword: async (submitForgottenPassword: SubmitForgottenPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitForgottenPassword' is not null or undefined
            assertParamExists('submitForgottenPassword', 'submitForgottenPassword', submitForgottenPassword)
            const localVarPath = `/forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitForgottenPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgottenPasswordApi - functional programming interface
 * @export
 */
export const ForgottenPasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgottenPasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ForgottenPasswordRequest} forgottenPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestForgottenPassword(forgottenPasswordRequest: ForgottenPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestForgottenPassword(forgottenPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForgottenPasswordApi.requestForgottenPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SubmitForgottenPassword} submitForgottenPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitForgottenPassword(submitForgottenPassword: SubmitForgottenPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitForgottenPassword(submitForgottenPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForgottenPasswordApi.submitForgottenPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForgottenPasswordApi - factory interface
 * @export
 */
export const ForgottenPasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgottenPasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {ForgottenPasswordRequest} forgottenPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestForgottenPassword(forgottenPasswordRequest: ForgottenPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.requestForgottenPassword(forgottenPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitForgottenPassword} submitForgottenPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForgottenPassword(submitForgottenPassword: SubmitForgottenPassword, options?: any): AxiosPromise<void> {
            return localVarFp.submitForgottenPassword(submitForgottenPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForgottenPasswordApi - object-oriented interface
 * @export
 * @class ForgottenPasswordApi
 * @extends {BaseAPI}
 */
export class ForgottenPasswordApi extends BaseAPI {
    /**
     * 
     * @param {ForgottenPasswordRequest} forgottenPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgottenPasswordApi
     */
    public requestForgottenPassword(forgottenPasswordRequest: ForgottenPasswordRequest, options?: RawAxiosRequestConfig) {
        return ForgottenPasswordApiFp(this.configuration).requestForgottenPassword(forgottenPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitForgottenPassword} submitForgottenPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgottenPasswordApi
     */
    public submitForgottenPassword(submitForgottenPassword: SubmitForgottenPassword, options?: RawAxiosRequestConfig) {
        return ForgottenPasswordApiFp(this.configuration).submitForgottenPassword(submitForgottenPassword, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvitationTrainingResourceApi - axios parameter creator
 * @export
 */
export const InvitationTrainingResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} questionnaire 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificQuestionnaireForInvitation: async (id: string, questionnaire: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSpecificQuestionnaireForInvitation', 'id', id)
            // verify required parameter 'questionnaire' is not null or undefined
            assertParamExists('getSpecificQuestionnaireForInvitation', 'questionnaire', questionnaire)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSpecificQuestionnaireForInvitation', 'lang', lang)
            const localVarPath = `/v2/invitations/{id}/questionnaires/{questionnaire}/languages/{lang}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"questionnaire"}}`, encodeURIComponent(String(questionnaire)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} training 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificTrainingForInvitation: async (id: string, training: string, lang: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSpecificTrainingForInvitation', 'id', id)
            // verify required parameter 'training' is not null or undefined
            assertParamExists('getSpecificTrainingForInvitation', 'training', training)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSpecificTrainingForInvitation', 'lang', lang)
            const localVarPath = `/v2/invitations/{id}/trainings/{training}/languages/{lang}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"training"}}`, encodeURIComponent(String(training)))
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GetIncompleteTrainingsData} getIncompleteTrainingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingsOverviewForInvitation: async (id: string, getIncompleteTrainingsData: GetIncompleteTrainingsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrainingsOverviewForInvitation', 'id', id)
            // verify required parameter 'getIncompleteTrainingsData' is not null or undefined
            assertParamExists('getTrainingsOverviewForInvitation', 'getIncompleteTrainingsData', getIncompleteTrainingsData)
            const localVarPath = `/v2/invitations/{id}/trainings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getIncompleteTrainingsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationTrainingResourceApi - functional programming interface
 * @export
 */
export const InvitationTrainingResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationTrainingResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} questionnaire 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecificQuestionnaireForInvitation(id: string, questionnaire: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationQuestionnaireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecificQuestionnaireForInvitation(id, questionnaire, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationTrainingResourceApi.getSpecificQuestionnaireForInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} training 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecificTrainingForInvitation(id: string, training: string, lang: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationTrainingDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecificTrainingForInvitation(id, training, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationTrainingResourceApi.getSpecificTrainingForInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {GetIncompleteTrainingsData} getIncompleteTrainingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingsOverviewForInvitation(id: string, getIncompleteTrainingsData: GetIncompleteTrainingsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncompleteTrainingDataResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingsOverviewForInvitation(id, getIncompleteTrainingsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationTrainingResourceApi.getTrainingsOverviewForInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitationTrainingResourceApi - factory interface
 * @export
 */
export const InvitationTrainingResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationTrainingResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} questionnaire 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificQuestionnaireForInvitation(id: string, questionnaire: string, lang: string, options?: any): AxiosPromise<InvitationQuestionnaireResponse> {
            return localVarFp.getSpecificQuestionnaireForInvitation(id, questionnaire, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} training 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificTrainingForInvitation(id: string, training: string, lang: string, options?: any): AxiosPromise<InvitationTrainingDetailResponse> {
            return localVarFp.getSpecificTrainingForInvitation(id, training, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GetIncompleteTrainingsData} getIncompleteTrainingsData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingsOverviewForInvitation(id: string, getIncompleteTrainingsData: GetIncompleteTrainingsData, options?: any): AxiosPromise<Array<IncompleteTrainingDataResponse>> {
            return localVarFp.getTrainingsOverviewForInvitation(id, getIncompleteTrainingsData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitationTrainingResourceApi - object-oriented interface
 * @export
 * @class InvitationTrainingResourceApi
 * @extends {BaseAPI}
 */
export class InvitationTrainingResourceApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} questionnaire 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationTrainingResourceApi
     */
    public getSpecificQuestionnaireForInvitation(id: string, questionnaire: string, lang: string, options?: RawAxiosRequestConfig) {
        return InvitationTrainingResourceApiFp(this.configuration).getSpecificQuestionnaireForInvitation(id, questionnaire, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} training 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationTrainingResourceApi
     */
    public getSpecificTrainingForInvitation(id: string, training: string, lang: string, options?: RawAxiosRequestConfig) {
        return InvitationTrainingResourceApiFp(this.configuration).getSpecificTrainingForInvitation(id, training, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {GetIncompleteTrainingsData} getIncompleteTrainingsData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationTrainingResourceApi
     */
    public getTrainingsOverviewForInvitation(id: string, getIncompleteTrainingsData: GetIncompleteTrainingsData, options?: RawAxiosRequestConfig) {
        return InvitationTrainingResourceApiFp(this.configuration).getTrainingsOverviewForInvitation(id, getIncompleteTrainingsData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvitationTypeVisitsApi - axios parameter creator
 * @export
 */
export const InvitationTypeVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create inv type visit
         * @param {string} company 
         * @param {InvitationTypeVisitRequest} invitationTypeVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationTypeVisit: async (company: string, invitationTypeVisitRequest: InvitationTypeVisitRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createInvitationTypeVisit', 'company', company)
            // verify required parameter 'invitationTypeVisitRequest' is not null or undefined
            assertParamExists('createInvitationTypeVisit', 'invitationTypeVisitRequest', invitationTypeVisitRequest)
            const localVarPath = `/companies/{company}/invitation-visit`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationTypeVisitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get inv type visit
         * @param {string} company 
         * @param {string} [q] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInvitationTypeVisit: async (company: string, q?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryInvitationTypeVisit', 'company', company)
            const localVarPath = `/companies/{company}/invitation-visit`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationTypeVisitsApi - functional programming interface
 * @export
 */
export const InvitationTypeVisitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationTypeVisitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create inv type visit
         * @param {string} company 
         * @param {InvitationTypeVisitRequest} invitationTypeVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitationTypeVisit(company: string, invitationTypeVisitRequest: InvitationTypeVisitRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitationTypeVisit(company, invitationTypeVisitRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationTypeVisitsApi.createInvitationTypeVisit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get inv type visit
         * @param {string} company 
         * @param {string} [q] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryInvitationTypeVisit(company: string, q?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationTypeVisitListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryInvitationTypeVisit(company, q, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationTypeVisitsApi.queryInvitationTypeVisit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitationTypeVisitsApi - factory interface
 * @export
 */
export const InvitationTypeVisitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationTypeVisitsApiFp(configuration)
    return {
        /**
         * 
         * @summary create inv type visit
         * @param {string} company 
         * @param {InvitationTypeVisitRequest} invitationTypeVisitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitationTypeVisit(company: string, invitationTypeVisitRequest: InvitationTypeVisitRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createInvitationTypeVisit(company, invitationTypeVisitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get inv type visit
         * @param {string} company 
         * @param {string} [q] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInvitationTypeVisit(company: string, q?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationTypeVisitListResponse> {
            return localVarFp.queryInvitationTypeVisit(company, q, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitationTypeVisitsApi - object-oriented interface
 * @export
 * @class InvitationTypeVisitsApi
 * @extends {BaseAPI}
 */
export class InvitationTypeVisitsApi extends BaseAPI {
    /**
     * 
     * @summary create inv type visit
     * @param {string} company 
     * @param {InvitationTypeVisitRequest} invitationTypeVisitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationTypeVisitsApi
     */
    public createInvitationTypeVisit(company: string, invitationTypeVisitRequest: InvitationTypeVisitRequest, options?: RawAxiosRequestConfig) {
        return InvitationTypeVisitsApiFp(this.configuration).createInvitationTypeVisit(company, invitationTypeVisitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get inv type visit
     * @param {string} company 
     * @param {string} [q] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationTypeVisitsApi
     */
    public queryInvitationTypeVisit(company: string, q?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return InvitationTypeVisitsApiFp(this.configuration).queryInvitationTypeVisit(company, q, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvitationsApi - axios parameter creator
 * @export
 */
export const InvitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {SetActivityDocuments} setActivityDocuments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentsForActivities: async (id: string, setActivityDocuments: SetActivityDocuments, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addDocumentsForActivities', 'id', id)
            // verify required parameter 'setActivityDocuments' is not null or undefined
            assertParamExists('addDocumentsForActivities', 'setActivityDocuments', setActivityDocuments)
            const localVarPath = `/invitations/{id}/activity-documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setActivityDocuments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {InvitedPersonDto} invitedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerson: async (id: string, invitedPersonDto: InvitedPersonDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addPerson', 'id', id)
            // verify required parameter 'invitedPersonDto' is not null or undefined
            assertParamExists('addPerson', 'invitedPersonDto', invitedPersonDto)
            const localVarPath = `/invitations/{id}/persons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitedPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveActivities: async (id: string, multipleIdRequest: MultipleIdRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveActivities', 'id', id)
            // verify required parameter 'multipleIdRequest' is not null or undefined
            assertParamExists('approveActivities', 'multipleIdRequest', multipleIdRequest)
            const localVarPath = `/invitations/{id}/approve-activities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multipleIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDocumentCertificate: async (id: string, multipleIdRequest: MultipleIdRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveDocumentCertificate', 'id', id)
            // verify required parameter 'multipleIdRequest' is not null or undefined
            assertParamExists('approveDocumentCertificate', 'multipleIdRequest', multipleIdRequest)
            const localVarPath = `/invitations/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multipleIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRiskRequest} approveRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveRisks: async (id: string, approveRiskRequest: ApproveRiskRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveRisks', 'id', id)
            // verify required parameter 'approveRiskRequest' is not null or undefined
            assertParamExists('approveRisks', 'approveRiskRequest', approveRiskRequest)
            const localVarPath = `/invitations/{id}/approve-risks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveRiskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SubmitAnswerRequest} submitAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAnswer: async (invitation: string, submitAnswerRequest: SubmitAnswerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('checkAnswer', 'invitation', invitation)
            // verify required parameter 'submitAnswerRequest' is not null or undefined
            assertParamExists('checkAnswer', 'submitAnswerRequest', submitAnswerRequest)
            const localVarPath = `/invitations/{invitation}/submit-answer`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitAnswerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ConfirmInvitationRequest} confirmInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvitationDetails: async (id: string, confirmInvitationRequest: ConfirmInvitationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmInvitationDetails', 'id', id)
            // verify required parameter 'confirmInvitationRequest' is not null or undefined
            assertParamExists('confirmInvitationDetails', 'confirmInvitationRequest', confirmInvitationRequest)
            const localVarPath = `/invitations/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation: async (company: string, createUpdateInvitation: CreateUpdateInvitation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createInvitation', 'company', company)
            // verify required parameter 'createUpdateInvitation' is not null or undefined
            assertParamExists('createInvitation', 'createUpdateInvitation', createUpdateInvitation)
            const localVarPath = `/companies/{company}/invitations`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpdateInvitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInvitation', 'id', id)
            const localVarPath = `/invitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: string, person: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('deletePerson', 'person', person)
            const localVarPath = `/invitations/{id}/persons/{person}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"person"}}`, encodeURIComponent(String(person)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {boolean} [forSupplier] 
         * @param {GenerateZipFileExportTypeEnum} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateZipFile: async (invitation: string, multipleIdRequest: MultipleIdRequest, forSupplier?: boolean, exportType?: GenerateZipFileExportTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('generateZipFile', 'invitation', invitation)
            // verify required parameter 'multipleIdRequest' is not null or undefined
            assertParamExists('generateZipFile', 'multipleIdRequest', multipleIdRequest)
            const localVarPath = `/invitations/{invitation}/related-files`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forSupplier !== undefined) {
                localVarQueryParameter['forSupplier'] = forSupplier;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multipleIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationAudit: async (id: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvitationAudit', 'id', id)
            const localVarPath = `/invitations/{id}/audit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInvitationById', 'id', id)
            const localVarPath = `/invitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationHistory: async (invitation: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('getInvitationHistory', 'invitation', invitation)
            const localVarPath = `/invitations/{invitation}/history`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsForSupplierEmployeeUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invitations/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incompleteTrainingsForUser: async (invitation: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('incompleteTrainingsForUser', 'invitation', invitation)
            const localVarPath = `/invitations/{invitation}/trainings-incomplete`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSend: async (invitation: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('markAsSend', 'invitation', invitation)
            const localVarPath = `/invitations/{invitation}/mark-sent`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overridePersonRequirements: async (invitation: string, person: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('overridePersonRequirements', 'invitation', invitation)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('overridePersonRequirements', 'person', person)
            const localVarPath = `/invitations/{invitation}/persons/{person}/override`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)))
                .replace(`{${"person"}}`, encodeURIComponent(String(person)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ${api.invitation.query.detail}
         * @summary ${api.invitation.query.title}
         * @param {string} companyId 
         * @param {string} [q] this one is used only for name of the invitation
         * @param {string} [supplier] 
         * @param {string} [responsiblePerson] 
         * @param {string} [personOnInvitation] 
         * @param {Array<string>} [employee] 
         * @param {string} [invitationStartFrom] 
         * @param {string} [invitationStartTo] 
         * @param {string} [invitationEndFrom] 
         * @param {string} [invitationEndTo] 
         * @param {Array<QueryInvitationsStateEnum>} [state] 
         * @param {QueryInvitationsTrainingStateEnum} [trainingState] 
         * @param {Array<QueryInvitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInvitations: async (companyId: string, q?: string, supplier?: string, responsiblePerson?: string, personOnInvitation?: string, employee?: Array<string>, invitationStartFrom?: string, invitationStartTo?: string, invitationEndFrom?: string, invitationEndTo?: string, state?: Array<QueryInvitationsStateEnum>, trainingState?: QueryInvitationsTrainingStateEnum, invitationTypes?: Array<QueryInvitationsInvitationTypesEnum>, workerStatus?: Array<InvitationDtoWorkerStatusEnum>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('queryInvitations', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/invitations`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (supplier !== undefined) {
                localVarQueryParameter['supplier'] = supplier;
            }

            if (responsiblePerson !== undefined) {
                localVarQueryParameter['responsiblePerson'] = responsiblePerson;
            }

            if (personOnInvitation !== undefined) {
                localVarQueryParameter['personOnInvitation'] = personOnInvitation;
            }

            if (employee) {
                localVarQueryParameter['employee'] = employee;
            }

            if (invitationStartFrom !== undefined) {
                localVarQueryParameter['invitationStartFrom'] = (invitationStartFrom as any instanceof Date) ?
                    (invitationStartFrom as any).toISOString().substring(0,10) :
                    invitationStartFrom;
            }

            if (invitationStartTo !== undefined) {
                localVarQueryParameter['invitationStartTo'] = (invitationStartTo as any instanceof Date) ?
                    (invitationStartTo as any).toISOString().substring(0,10) :
                    invitationStartTo;
            }

            if (invitationEndFrom !== undefined) {
                localVarQueryParameter['invitationEndFrom'] = (invitationEndFrom as any instanceof Date) ?
                    (invitationEndFrom as any).toISOString().substring(0,10) :
                    invitationEndFrom;
            }

            if (invitationEndTo !== undefined) {
                localVarQueryParameter['invitationEndTo'] = (invitationEndTo as any instanceof Date) ?
                    (invitationEndTo as any).toISOString().substring(0,10) :
                    invitationEndTo;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (trainingState !== undefined) {
                localVarQueryParameter['trainingState'] = trainingState;
            }

            if (invitationTypes) {
                localVarQueryParameter['invitationTypes'] = invitationTypes;
            }

            if (workerStatus !== undefined) {
                localVarQueryParameter['workerStatuses'] = workerStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resentNotification: async (invitation: string, person: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('resentNotification', 'invitation', invitation)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('resentNotification', 'person', person)
            const localVarPath = `/invitations/{invitation}/persons/{person}/resend`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)))
                .replace(`{${"person"}}`, encodeURIComponent(String(person)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSupplierRisksForApproval: async (id: string, multipleIdRequest: MultipleIdRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendSupplierRisksForApproval', 'id', id)
            // verify required parameter 'multipleIdRequest' is not null or undefined
            assertParamExists('sendSupplierRisksForApproval', 'multipleIdRequest', multipleIdRequest)
            const localVarPath = `/invitations/{id}/send-for-approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(multipleIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SignPersonRequest} signPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signQuestionnaire: async (invitation: string, signPersonRequest: SignPersonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('signQuestionnaire', 'invitation', invitation)
            // verify required parameter 'signPersonRequest' is not null or undefined
            assertParamExists('signQuestionnaire', 'signPersonRequest', signPersonRequest)
            const localVarPath = `/invitations/{invitation}/sign-questionnaire`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signPersonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} documentId 
         * @param {UpdateInvitationDocument} updateInvitationDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (id: string, documentId: string, updateInvitationDocument: UpdateInvitationDocument, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDocument', 'id', id)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('updateDocument', 'documentId', documentId)
            // verify required parameter 'updateInvitationDocument' is not null or undefined
            assertParamExists('updateDocument', 'updateInvitationDocument', updateInvitationDocument)
            const localVarPath = `/invitations/{id}/documents/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvitationDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitation: async (invitation: string, createUpdateInvitation: CreateUpdateInvitation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('updateInvitation', 'invitation', invitation)
            // verify required parameter 'createUpdateInvitation' is not null or undefined
            assertParamExists('updateInvitation', 'createUpdateInvitation', createUpdateInvitation)
            const localVarPath = `/invitations/{invitation}`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpdateInvitation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {UpdateInvitedPersonRequest} updateInvitedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (invitation: string, person: string, updateInvitedPersonRequest: UpdateInvitedPersonRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitation' is not null or undefined
            assertParamExists('updatePerson', 'invitation', invitation)
            // verify required parameter 'person' is not null or undefined
            assertParamExists('updatePerson', 'person', person)
            // verify required parameter 'updateInvitedPersonRequest' is not null or undefined
            assertParamExists('updatePerson', 'updateInvitedPersonRequest', updateInvitedPersonRequest)
            const localVarPath = `/invitations/{invitation}/persons/{person}`
                .replace(`{${"invitation"}}`, encodeURIComponent(String(invitation)))
                .replace(`{${"person"}}`, encodeURIComponent(String(person)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvitedPersonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationsApi - functional programming interface
 * @export
 */
export const InvitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {SetActivityDocuments} setActivityDocuments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocumentsForActivities(id: string, setActivityDocuments: SetActivityDocuments, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocumentsForActivities(id, setActivityDocuments, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.addDocumentsForActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {InvitedPersonDto} invitedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPerson(id: string, invitedPersonDto: InvitedPersonDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitedPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPerson(id, invitedPersonDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.addPerson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveActivities(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveActivities(id, multipleIdRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.approveActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveDocumentCertificate(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveDocumentCertificate(id, multipleIdRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.approveDocumentCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRiskRequest} approveRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveRisks(id: string, approveRiskRequest: ApproveRiskRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveRisks(id, approveRiskRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.approveRisks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SubmitAnswerRequest} submitAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAnswer(invitation: string, submitAnswerRequest: SubmitAnswerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAnswer(invitation, submitAnswerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.checkAnswer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ConfirmInvitationRequest} confirmInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmInvitationDetails(id: string, confirmInvitationRequest: ConfirmInvitationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmInvitationDetails(id, confirmInvitationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.confirmInvitationDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} company 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitation(company: string, createUpdateInvitation: CreateUpdateInvitation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvitation(company, createUpdateInvitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.createInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvitation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvitation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.deleteInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: string, person: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, person, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.deletePerson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {boolean} [forSupplier] 
         * @param {GenerateZipFileExportTypeEnum} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateZipFile(invitation: string, multipleIdRequest: MultipleIdRequest, forSupplier?: boolean, exportType?: GenerateZipFileExportTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateZipFile(invitation, multipleIdRequest, forSupplier, exportType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.generateZipFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationAudit(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationStateAud>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationAudit(id, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.getInvitationAudit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.getInvitationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationHistory(invitation: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationLogEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationHistory(invitation, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.getInvitationHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationsForSupplierEmployeeUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationsForSupplierEmployeeUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.getInvitationsForSupplierEmployeeUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incompleteTrainingsForUser(invitation: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourniquetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incompleteTrainingsForUser(invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.incompleteTrainingsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsSend(invitation: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsSend(invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.markAsSend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overridePersonRequirements(invitation: string, person: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overridePersonRequirements(invitation, person, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.overridePersonRequirements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ${api.invitation.query.detail}
         * @summary ${api.invitation.query.title}
         * @param {string} companyId 
         * @param {string} [q] this one is used only for name of the invitation
         * @param {string} [supplier] 
         * @param {string} [responsiblePerson] 
         * @param {string} [personOnInvitation] 
         * @param {Array<string>} [employee] 
         * @param {string} [invitationStartFrom] 
         * @param {string} [invitationStartTo] 
         * @param {string} [invitationEndFrom] 
         * @param {string} [invitationEndTo] 
         * @param {Array<QueryInvitationsStateEnum>} [state] 
         * @param {QueryInvitationsTrainingStateEnum} [trainingState] 
         * @param {Array<QueryInvitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryInvitations(companyId: string, q?: string, supplier?: string, responsiblePerson?: string, personOnInvitation?: string, employee?: Array<string>, invitationStartFrom?: string, invitationStartTo?: string, invitationEndFrom?: string, invitationEndTo?: string, state?: Array<QueryInvitationsStateEnum>, trainingState?: QueryInvitationsTrainingStateEnum, invitationTypes?: Array<QueryInvitationsInvitationTypesEnum>, workerStatus?: Array<InvitationDtoWorkerStatusEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryInvitations(companyId, q, supplier, responsiblePerson, personOnInvitation, employee, invitationStartFrom, invitationStartTo, invitationEndFrom, invitationEndTo, state, trainingState, invitationTypes, workerStatus, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.queryInvitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resentNotification(invitation: string, person: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resentNotification(invitation, person, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.resentNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSupplierRisksForApproval(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSupplierRisksForApproval(id, multipleIdRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.sendSupplierRisksForApproval']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SignPersonRequest} signPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signQuestionnaire(invitation: string, signPersonRequest: SignPersonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signQuestionnaire(invitation, signPersonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.signQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} documentId 
         * @param {UpdateInvitationDocument} updateInvitationDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(id: string, documentId: string, updateInvitationDocument: UpdateInvitationDocument, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(id, documentId, updateInvitationDocument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.updateDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvitation(invitation: string, createUpdateInvitation: CreateUpdateInvitation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvitation(invitation, createUpdateInvitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.updateInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {UpdateInvitedPersonRequest} updateInvitedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(invitation: string, person: string, updateInvitedPersonRequest: UpdateInvitedPersonRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitedPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(invitation, person, updateInvitedPersonRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitationsApi.updatePerson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitationsApi - factory interface
 * @export
 */
export const InvitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {SetActivityDocuments} setActivityDocuments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocumentsForActivities(id: string, setActivityDocuments: SetActivityDocuments, options?: any): AxiosPromise<void> {
            return localVarFp.addDocumentsForActivities(id, setActivityDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {InvitedPersonDto} invitedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerson(id: string, invitedPersonDto: InvitedPersonDto, options?: any): AxiosPromise<InvitedPersonDto> {
            return localVarFp.addPerson(id, invitedPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveActivities(id: string, multipleIdRequest: MultipleIdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.approveActivities(id, multipleIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDocumentCertificate(id: string, multipleIdRequest: MultipleIdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.approveDocumentCertificate(id, multipleIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApproveRiskRequest} approveRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveRisks(id: string, approveRiskRequest: ApproveRiskRequest, options?: any): AxiosPromise<void> {
            return localVarFp.approveRisks(id, approveRiskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SubmitAnswerRequest} submitAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAnswer(invitation: string, submitAnswerRequest: SubmitAnswerRequest, options?: any): AxiosPromise<AnswerResponse> {
            return localVarFp.checkAnswer(invitation, submitAnswerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ConfirmInvitationRequest} confirmInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmInvitationDetails(id: string, confirmInvitationRequest: ConfirmInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.confirmInvitationDetails(id, confirmInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(company: string, createUpdateInvitation: CreateUpdateInvitation, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createInvitation(company, createUpdateInvitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInvitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: string, person: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, person, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {boolean} [forSupplier] 
         * @param {GenerateZipFileExportTypeEnum} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateZipFile(invitation: string, multipleIdRequest: MultipleIdRequest, forSupplier?: boolean, exportType?: GenerateZipFileExportTypeEnum, options?: any): AxiosPromise<File> {
            return localVarFp.generateZipFile(invitation, multipleIdRequest, forSupplier, exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationAudit(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationStateAud> {
            return localVarFp.getInvitationAudit(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationById(id: string, options?: any): AxiosPromise<InvitationDto> {
            return localVarFp.getInvitationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationHistory(invitation: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationLogEntryResponse> {
            return localVarFp.getInvitationHistory(invitation, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsForSupplierEmployeeUser(options?: any): AxiosPromise<Array<ReferenceDto>> {
            return localVarFp.getInvitationsForSupplierEmployeeUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incompleteTrainingsForUser(invitation: string, options?: any): AxiosPromise<TourniquetResponse> {
            return localVarFp.incompleteTrainingsForUser(invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsSend(invitation: string, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.markAsSend(invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overridePersonRequirements(invitation: string, person: string, options?: any): AxiosPromise<void> {
            return localVarFp.overridePersonRequirements(invitation, person, options).then((request) => request(axios, basePath));
        },
        /**
         * ${api.invitation.query.detail}
         * @summary ${api.invitation.query.title}
         * @param {string} companyId 
         * @param {string} [q] this one is used only for name of the invitation
         * @param {string} [supplier] 
         * @param {string} [responsiblePerson] 
         * @param {string} [personOnInvitation] 
         * @param {Array<string>} [employee] 
         * @param {string} [invitationStartFrom] 
         * @param {string} [invitationStartTo] 
         * @param {string} [invitationEndFrom] 
         * @param {string} [invitationEndTo] 
         * @param {Array<QueryInvitationsStateEnum>} [state] 
         * @param {QueryInvitationsTrainingStateEnum} [trainingState] 
         * @param {Array<QueryInvitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInvitations(companyId: string, q?: string, supplier?: string, responsiblePerson?: string, personOnInvitation?: string, employee?: Array<string>, invitationStartFrom?: string, invitationStartTo?: string, invitationEndFrom?: string, invitationEndTo?: string, state?: Array<QueryInvitationsStateEnum>, trainingState?: QueryInvitationsTrainingStateEnum, invitationTypes?: Array<QueryInvitationsInvitationTypesEnum>, workerStatus?: Array<InvitationDtoWorkerStatusEnum>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationListResponse> {
            return localVarFp.queryInvitations(companyId, q, supplier, responsiblePerson, personOnInvitation, employee, invitationStartFrom, invitationStartTo, invitationEndFrom, invitationEndTo, state, trainingState, invitationTypes, workerStatus, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resentNotification(invitation: string, person: string, options?: any): AxiosPromise<void> {
            return localVarFp.resentNotification(invitation, person, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MultipleIdRequest} multipleIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSupplierRisksForApproval(id: string, multipleIdRequest: MultipleIdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendSupplierRisksForApproval(id, multipleIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {SignPersonRequest} signPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signQuestionnaire(invitation: string, signPersonRequest: SignPersonRequest, options?: any): AxiosPromise<void> {
            return localVarFp.signQuestionnaire(invitation, signPersonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} documentId 
         * @param {UpdateInvitationDocument} updateInvitationDocument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(id: string, documentId: string, updateInvitationDocument: UpdateInvitationDocument, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocument(id, documentId, updateInvitationDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {CreateUpdateInvitation} createUpdateInvitation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvitation(invitation: string, createUpdateInvitation: CreateUpdateInvitation, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.updateInvitation(invitation, createUpdateInvitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitation 
         * @param {string} person 
         * @param {UpdateInvitedPersonRequest} updateInvitedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(invitation: string, person: string, updateInvitedPersonRequest: UpdateInvitedPersonRequest, options?: any): AxiosPromise<InvitedPersonDto> {
            return localVarFp.updatePerson(invitation, person, updateInvitedPersonRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitationsApi - object-oriented interface
 * @export
 * @class InvitationsApi
 * @extends {BaseAPI}
 */
export class InvitationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {SetActivityDocuments} setActivityDocuments 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public addDocumentsForActivities(id: string, setActivityDocuments: SetActivityDocuments, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).addDocumentsForActivities(id, setActivityDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {InvitedPersonDto} invitedPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public addPerson(id: string, invitedPersonDto: InvitedPersonDto, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).addPerson(id, invitedPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MultipleIdRequest} multipleIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public approveActivities(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).approveActivities(id, multipleIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MultipleIdRequest} multipleIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public approveDocumentCertificate(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).approveDocumentCertificate(id, multipleIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApproveRiskRequest} approveRiskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public approveRisks(id: string, approveRiskRequest: ApproveRiskRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).approveRisks(id, approveRiskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {SubmitAnswerRequest} submitAnswerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public checkAnswer(invitation: string, submitAnswerRequest: SubmitAnswerRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).checkAnswer(invitation, submitAnswerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ConfirmInvitationRequest} confirmInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public confirmInvitationDetails(id: string, confirmInvitationRequest: ConfirmInvitationRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).confirmInvitationDetails(id, confirmInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {CreateUpdateInvitation} createUpdateInvitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public createInvitation(company: string, createUpdateInvitation: CreateUpdateInvitation, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).createInvitation(company, createUpdateInvitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public deleteInvitation(id: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).deleteInvitation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} person 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public deletePerson(id: string, person: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).deletePerson(id, person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {MultipleIdRequest} multipleIdRequest 
     * @param {boolean} [forSupplier] 
     * @param {GenerateZipFileExportTypeEnum} [exportType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public generateZipFile(invitation: string, multipleIdRequest: MultipleIdRequest, forSupplier?: boolean, exportType?: GenerateZipFileExportTypeEnum, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).generateZipFile(invitation, multipleIdRequest, forSupplier, exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public getInvitationAudit(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).getInvitationAudit(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public getInvitationById(id: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).getInvitationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public getInvitationHistory(invitation: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).getInvitationHistory(invitation, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public getInvitationsForSupplierEmployeeUser(options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).getInvitationsForSupplierEmployeeUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public incompleteTrainingsForUser(invitation: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).incompleteTrainingsForUser(invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public markAsSend(invitation: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).markAsSend(invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {string} person 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public overridePersonRequirements(invitation: string, person: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).overridePersonRequirements(invitation, person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ${api.invitation.query.detail}
     * @summary ${api.invitation.query.title}
     * @param {string} companyId 
     * @param {string} [q] this one is used only for name of the invitation
     * @param {string} [supplier] 
     * @param {string} [responsiblePerson] 
     * @param {string} [personOnInvitation] 
     * @param {Array<string>} [employee] 
     * @param {string} [invitationStartFrom] 
     * @param {string} [invitationStartTo] 
     * @param {string} [invitationEndFrom] 
     * @param {string} [invitationEndTo] 
     * @param {Array<QueryInvitationsStateEnum>} [state] 
     * @param {QueryInvitationsTrainingStateEnum} [trainingState] 
     * @param {Array<QueryInvitationsInvitationTypesEnum>} [invitationTypes] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public queryInvitations(companyId: string, q?: string, supplier?: string, responsiblePerson?: string, personOnInvitation?: string, employee?: Array<string>, invitationStartFrom?: string, invitationStartTo?: string, invitationEndFrom?: string, invitationEndTo?: string, state?: Array<QueryInvitationsStateEnum>, trainingState?: QueryInvitationsTrainingStateEnum, invitationTypes?: Array<QueryInvitationsInvitationTypesEnum>, workerStatus?: Array<InvitationDtoWorkerStatusEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).queryInvitations(companyId, q, supplier, responsiblePerson, personOnInvitation, employee, invitationStartFrom, invitationStartTo, invitationEndFrom, invitationEndTo, state, trainingState, invitationTypes, workerStatus, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {string} person 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public resentNotification(invitation: string, person: string, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).resentNotification(invitation, person, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MultipleIdRequest} multipleIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public sendSupplierRisksForApproval(id: string, multipleIdRequest: MultipleIdRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).sendSupplierRisksForApproval(id, multipleIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {SignPersonRequest} signPersonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public signQuestionnaire(invitation: string, signPersonRequest: SignPersonRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).signQuestionnaire(invitation, signPersonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} documentId 
     * @param {UpdateInvitationDocument} updateInvitationDocument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public updateDocument(id: string, documentId: string, updateInvitationDocument: UpdateInvitationDocument, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).updateDocument(id, documentId, updateInvitationDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {CreateUpdateInvitation} createUpdateInvitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public updateInvitation(invitation: string, createUpdateInvitation: CreateUpdateInvitation, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).updateInvitation(invitation, createUpdateInvitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitation 
     * @param {string} person 
     * @param {UpdateInvitedPersonRequest} updateInvitedPersonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationsApi
     */
    public updatePerson(invitation: string, person: string, updateInvitedPersonRequest: UpdateInvitedPersonRequest, options?: RawAxiosRequestConfig) {
        return InvitationsApiFp(this.configuration).updatePerson(invitation, person, updateInvitedPersonRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GenerateZipFileExportTypeEnum = {
    Risks: 'RISKS',
    Attachment: 'ATTACHMENT'
} as const;
export type GenerateZipFileExportTypeEnum = typeof GenerateZipFileExportTypeEnum[keyof typeof GenerateZipFileExportTypeEnum];
/**
 * @export
 */
export const QueryInvitationsStateEnum = {
    InProgress: 'IN_PROGRESS',
    Sent: 'SENT',
    Viewed: 'VIEWED',
    PartiallyFilled: 'PARTIALLY_FILLED',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Approved: 'APPROVED',
    Deleted: 'DELETED',
    Archived: 'ARCHIVED'
} as const;
export type QueryInvitationsStateEnum = typeof QueryInvitationsStateEnum[keyof typeof QueryInvitationsStateEnum];
/**
 * @export
 */
export const QueryInvitationsTrainingStateEnum = {
    Untrained: 'UNTRAINED',
    Partial: 'PARTIAL',
    Trained: 'TRAINED'
} as const;
export type QueryInvitationsTrainingStateEnum = typeof QueryInvitationsTrainingStateEnum[keyof typeof QueryInvitationsTrainingStateEnum];
/**
 * @export
 */
export const QueryInvitationsInvitationTypesEnum = {
    Regular: 'REGULAR',
    OneTime: 'ONE_TIME',
    OneTimeEntry: 'ONE_TIME_ENTRY'
} as const;
export type QueryInvitationsInvitationTypesEnum = typeof QueryInvitationsInvitationTypesEnum[keyof typeof QueryInvitationsInvitationTypesEnum];


/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete specific file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            const localVarPath = `/api/v1/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannerForSubdomain: async (subdomain: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getBannerForSubdomain', 'subdomain', subdomain)
            const localVarPath = `/api/v1/media/{subdomain}/banner`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadFile: async (id: string, fileName: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadFile', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('loadFile', 'fileName', fileName)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('loadFile', 'token', token)
            const localVarPath = `/api/v1/secured-media/{id}/{fileName}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUnsecuredFile: async (id: string, fileName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadUnsecuredFile', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('loadUnsecuredFile', 'fileName', fileName)
            const localVarPath = `/api/v1/media/{id}/{fileName}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UnpairDocumentFromEntityActionEnum} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDocumentFromEntity: async (id: string, action: UnpairDocumentFromEntityActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unpairDocumentFromEntity', 'id', id)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('unpairDocumentFromEntity', 'action', action)
            const localVarPath = `/api/v1/unpair-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {boolean} secured 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia: async (company: string, secured: boolean, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('uploadMedia', 'company', company)
            // verify required parameter 'secured' is not null or undefined
            assertParamExists('uploadMedia', 'secured', secured)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadMedia', 'file', file)
            const localVarPath = `/api/v1/companies/{company}/media`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (secured !== undefined) {
                localVarQueryParameter['secured'] = secured;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete specific file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.deleteFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBannerForSubdomain(subdomain: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBannerForSubdomain(subdomain, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.getBannerForSubdomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadFile(id: string, fileName: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadFile(id, fileName, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.loadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadUnsecuredFile(id: string, fileName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadUnsecuredFile(id, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.loadUnsecuredFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UnpairDocumentFromEntityActionEnum} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpairDocumentFromEntity(id: string, action: UnpairDocumentFromEntityActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpairDocumentFromEntity(id, action, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.unpairDocumentFromEntity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} company 
         * @param {boolean} secured 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedia(company: string, secured: boolean, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadMediaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMedia(company, secured, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.uploadMedia']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete specific file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannerForSubdomain(subdomain: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBannerForSubdomain(subdomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadFile(id: string, fileName: string, token: string, options?: any): AxiosPromise<File> {
            return localVarFp.loadFile(id, fileName, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUnsecuredFile(id: string, fileName: string, options?: any): AxiosPromise<File> {
            return localVarFp.loadUnsecuredFile(id, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UnpairDocumentFromEntityActionEnum} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpairDocumentFromEntity(id: string, action: UnpairDocumentFromEntityActionEnum, options?: any): AxiosPromise<void> {
            return localVarFp.unpairDocumentFromEntity(id, action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {boolean} secured 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedia(company: string, secured: boolean, file: File, options?: any): AxiosPromise<FileUploadMediaResponse> {
            return localVarFp.uploadMedia(company, secured, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @summary Delete specific file
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public deleteFile(id: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).deleteFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getBannerForSubdomain(subdomain: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).getBannerForSubdomain(subdomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} fileName 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public loadFile(id: string, fileName: string, token: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).loadFile(id, fileName, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public loadUnsecuredFile(id: string, fileName: string, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).loadUnsecuredFile(id, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UnpairDocumentFromEntityActionEnum} action 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public unpairDocumentFromEntity(id: string, action: UnpairDocumentFromEntityActionEnum, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).unpairDocumentFromEntity(id, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {boolean} secured 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public uploadMedia(company: string, secured: boolean, file: File, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).uploadMedia(company, secured, file, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UnpairDocumentFromEntityActionEnum = {
    Invitation: 'FROM_INVITATION',
    InvitationActivity: 'FROM_INVITATION_ACTIVITY'
} as const;
export type UnpairDocumentFromEntityActionEnum = typeof UnpairDocumentFromEntityActionEnum[keyof typeof UnpairDocumentFromEntityActionEnum];


/**
 * QCUserApi - axios parameter creator
 * @export
 */
export const QCUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add later
         * @summary Create qcs user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQcsUser: async (userDto: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('createQcsUser', 'userDto', userDto)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Create user out of given employee
         * @param {string} id 
         * @param {CreateUserFromEmployeeRequest} createUserFromEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (id: string, createUserFromEmployeeRequest: CreateUserFromEmployeeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createUser', 'id', id)
            // verify required parameter 'createUserFromEmployeeRequest' is not null or undefined
            assertParamExists('createUser', 'createUserFromEmployeeRequest', createUserFromEmployeeRequest)
            const localVarPath = `/api/v1/employees/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserFromEmployeeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user preferences
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPreferences', 'id', id)
            const localVarPath = `/api/v1/users/{id}/preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get all qcs users
         * @param {string} [q] 
         * @param {Array<QueryQcsCoreUsersStateEnum>} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQcsCoreUsers: async (q?: string, state?: Array<QueryQcsCoreUsersStateEnum>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Get all user from given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {Array<QueryQcsUsersStateEnum>} [state] 
         * @param {Array<QueryQcsUsersRolesEnum>} [roles] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQcsUsers: async (company: string, q?: string, state?: Array<QueryQcsUsersStateEnum>, roles?: Array<QueryQcsUsersRolesEnum>, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryQcsUsers', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/users`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (establishments) {
                localVarQueryParameter['establishments'] = establishments;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add later
         * @summary Update qcs user
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userDto: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('updateUser', 'userDto', userDto)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update preferences
         * @param {string} id 
         * @param {UserPreferences} userPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences: async (id: string, userPreferences: UserPreferences, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserPreferences', 'id', id)
            // verify required parameter 'userPreferences' is not null or undefined
            assertParamExists('updateUserPreferences', 'userPreferences', userPreferences)
            const localVarPath = `/api/v1/users/{id}/preferences`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QCUserApi - functional programming interface
 * @export
 */
export const QCUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QCUserApiAxiosParamCreator(configuration)
    return {
        /**
         * add later
         * @summary Create qcs user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQcsUser(userDto: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQcsUser(userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.createQcsUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Create user out of given employee
         * @param {string} id 
         * @param {CreateUserFromEmployeeRequest} createUserFromEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(id: string, createUserFromEmployeeRequest: CreateUserFromEmployeeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(id, createUserFromEmployeeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get user preferences
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferences(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferences(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.getUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get all qcs users
         * @param {string} [q] 
         * @param {Array<QueryQcsCoreUsersStateEnum>} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryQcsCoreUsers(q?: string, state?: Array<QueryQcsCoreUsersStateEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCoreUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryQcsCoreUsers(q, state, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.queryQcsCoreUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Get all user from given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {Array<QueryQcsUsersStateEnum>} [state] 
         * @param {Array<QueryQcsUsersRolesEnum>} [roles] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryQcsUsers(company: string, q?: string, state?: Array<QueryQcsUsersStateEnum>, roles?: Array<QueryQcsUsersRolesEnum>, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEmployeeUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryQcsUsers(company, q, state, roles, establishments, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.queryQcsUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * add later
         * @summary Update qcs user
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userDto: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary update preferences
         * @param {string} id 
         * @param {UserPreferences} userPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferences(id: string, userPreferences: UserPreferences, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferences(id, userPreferences, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QCUserApi.updateUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QCUserApi - factory interface
 * @export
 */
export const QCUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QCUserApiFp(configuration)
    return {
        /**
         * add later
         * @summary Create qcs user
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQcsUser(userDto: UserDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createQcsUser(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Create user out of given employee
         * @param {string} id 
         * @param {CreateUserFromEmployeeRequest} createUserFromEmployeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(id: string, createUserFromEmployeeRequest: CreateUserFromEmployeeRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createUser(id, createUserFromEmployeeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user preferences
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences(id: string, options?: any): AxiosPromise<UserPreferences> {
            return localVarFp.getUserPreferences(id, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get all qcs users
         * @param {string} [q] 
         * @param {Array<QueryQcsCoreUsersStateEnum>} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQcsCoreUsers(q?: string, state?: Array<QueryQcsCoreUsersStateEnum>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageCoreUserListResponse> {
            return localVarFp.queryQcsCoreUsers(q, state, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Get all user from given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {Array<QueryQcsUsersStateEnum>} [state] 
         * @param {Array<QueryQcsUsersRolesEnum>} [roles] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQcsUsers(company: string, q?: string, state?: Array<QueryQcsUsersStateEnum>, roles?: Array<QueryQcsUsersRolesEnum>, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEmployeeUserListResponse> {
            return localVarFp.queryQcsUsers(company, q, state, roles, establishments, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * add later
         * @summary Update qcs user
         * @param {string} id 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userDto: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(id, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update preferences
         * @param {string} id 
         * @param {UserPreferences} userPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences(id: string, userPreferences: UserPreferences, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserPreferences(id, userPreferences, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QCUserApi - object-oriented interface
 * @export
 * @class QCUserApi
 * @extends {BaseAPI}
 */
export class QCUserApi extends BaseAPI {
    /**
     * add later
     * @summary Create qcs user
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public createQcsUser(userDto: UserDto, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).createQcsUser(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Create user out of given employee
     * @param {string} id 
     * @param {CreateUserFromEmployeeRequest} createUserFromEmployeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public createUser(id: string, createUserFromEmployeeRequest: CreateUserFromEmployeeRequest, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).createUser(id, createUserFromEmployeeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public getUser(id: string, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user preferences
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public getUserPreferences(id: string, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).getUserPreferences(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get all qcs users
     * @param {string} [q] 
     * @param {Array<QueryQcsCoreUsersStateEnum>} [state] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public queryQcsCoreUsers(q?: string, state?: Array<QueryQcsCoreUsersStateEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).queryQcsCoreUsers(q, state, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Get all user from given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {Array<QueryQcsUsersStateEnum>} [state] 
     * @param {Array<QueryQcsUsersRolesEnum>} [roles] 
     * @param {Array<string>} [establishments] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public queryQcsUsers(company: string, q?: string, state?: Array<QueryQcsUsersStateEnum>, roles?: Array<QueryQcsUsersRolesEnum>, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).queryQcsUsers(company, q, state, roles, establishments, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add later
     * @summary Update qcs user
     * @param {string} id 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public updateUser(id: string, userDto: UserDto, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).updateUser(id, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update preferences
     * @param {string} id 
     * @param {UserPreferences} userPreferences 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QCUserApi
     */
    public updateUserPreferences(id: string, userPreferences: UserPreferences, options?: RawAxiosRequestConfig) {
        return QCUserApiFp(this.configuration).updateUserPreferences(id, userPreferences, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryQcsCoreUsersStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryQcsCoreUsersStateEnum = typeof QueryQcsCoreUsersStateEnum[keyof typeof QueryQcsCoreUsersStateEnum];
/**
 * @export
 */
export const QueryQcsUsersStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryQcsUsersStateEnum = typeof QueryQcsUsersStateEnum[keyof typeof QueryQcsUsersStateEnum];
/**
 * @export
 */
export const QueryQcsUsersRolesEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;
export type QueryQcsUsersRolesEnum = typeof QueryQcsUsersRolesEnum[keyof typeof QueryQcsUsersRolesEnum];


/**
 * QuestionnairesApi - axios parameter creator
 * @export
 */
export const QuestionnairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add new language for version
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLanguageForVersion: async (version: string, addLanguageVariant: AddLanguageVariant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('addLanguageForVersion', 'version', version)
            // verify required parameter 'addLanguageVariant' is not null or undefined
            assertParamExists('addLanguageForVersion', 'addLanguageVariant', addLanguageVariant)
            const localVarPath = `/questionnaire-version/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLanguageVariant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyQuestionnaireVersion: async (version: string, copyVersionRequest: CopyVersionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyQuestionnaireVersion', 'version', version)
            // verify required parameter 'copyVersionRequest' is not null or undefined
            assertParamExists('copyQuestionnaireVersion', 'copyVersionRequest', copyVersionRequest)
            const localVarPath = `/questionnaire-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToNewQuestionnaire: async (version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyVersionToNewQuestionnaire', 'version', version)
            // verify required parameter 'copyVersionHolderToNewVersionableRequest' is not null or undefined
            assertParamExists('copyVersionToNewQuestionnaire', 'copyVersionHolderToNewVersionableRequest', copyVersionHolderToNewVersionableRequest)
            const localVarPath = `/questionnaire-versions/{version}/new`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionHolderToNewVersionableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToQuestionnaires: async (version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyVersionToQuestionnaires', 'version', version)
            // verify required parameter 'copyVersionHolderToVersionablesRequest' is not null or undefined
            assertParamExists('copyVersionToQuestionnaires', 'copyVersionHolderToVersionablesRequest', copyVersionHolderToVersionablesRequest)
            const localVarPath = `/questionnaire-versions/{version}/copy`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionHolderToVersionablesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create copy of {language} with specific language defined by payload
         * @param {string} id 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCopyFromLanguageVersion: async (id: string, language: string, addLanguageVariant: AddLanguageVariant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createCopyFromLanguageVersion', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('createCopyFromLanguageVersion', 'language', language)
            // verify required parameter 'addLanguageVariant' is not null or undefined
            assertParamExists('createCopyFromLanguageVersion', 'addLanguageVariant', addLanguageVariant)
            const localVarPath = `/questionnaire-version/{id}/languages/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLanguageVariant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Operation will create questionnaire with where fields \'note\', \'minSuccessRate\' and \'validFrom`, are used for first version
         * @param {string} company 
         * @param {CreateQuestionnaireRequest} createQuestionnaireRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire: async (company: string, createQuestionnaireRequest: CreateQuestionnaireRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createQuestionnaire', 'company', company)
            // verify required parameter 'createQuestionnaireRequest' is not null or undefined
            assertParamExists('createQuestionnaire', 'createQuestionnaireRequest', createQuestionnaireRequest)
            const localVarPath = `/companies/{company}/questionnaires`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuestionnaireRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create new version from version defined by id. payload can change only minSuccessRate, validFrom and note
         * @param {string} questionnaire 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireVersion: async (questionnaire: string, questionnaireVersionDto: QuestionnaireVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaire' is not null or undefined
            assertParamExists('createQuestionnaireVersion', 'questionnaire', questionnaire)
            // verify required parameter 'questionnaireVersionDto' is not null or undefined
            assertParamExists('createQuestionnaireVersion', 'questionnaireVersionDto', questionnaireVersionDto)
            const localVarPath = `/questionnaires/{questionnaire}/versions`
                .replace(`{${"questionnaire"}}`, encodeURIComponent(String(questionnaire)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete questionnaire version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireVersion: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteQuestionnaireVersion', 'version', version)
            const localVarPath = `/questionnaire-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireVersionVariant: async (id: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQuestionnaireVersionVariant', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('deleteQuestionnaireVersionVariant', 'language', language)
            const localVarPath = `/questionnaire-version/{id}/questions/{language}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get specific version of questionnaire
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVersionById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findVersionById', 'id', id)
            const localVarPath = `/questionnaire-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get languages of active version for given questionnaire
         * @param {string} questionnaire 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesForTest: async (questionnaire: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaire' is not null or undefined
            assertParamExists('getLanguagesForTest', 'questionnaire', questionnaire)
            const localVarPath = `/questionnaire/{questionnaire}/languages`
                .replace(`{${"questionnaire"}}`, encodeURIComponent(String(questionnaire)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch questionnaire by its id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionnaireById', 'id', id)
            const localVarPath = `/questionnaires/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get questions for given version
         * @param {string} versionId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions: async (versionId: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getQuestions', 'versionId', versionId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getQuestions', 'language', language)
            const localVarPath = `/questionnaire-version/{versionId}/questions/{language}`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get versions of specific questionnaire
         * @param {string} questionnaireId 
         * @param {boolean} [activeOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions: async (questionnaireId: string, activeOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('getVersions', 'questionnaireId', questionnaireId)
            const localVarPath = `/questionnaires/{questionnaireId}/versions`
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of all questionnaires for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryQuestionnairesStateEnum} [state] 
         * @param {string} [skipId] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQuestionnaires: async (company: string, q?: string, state?: QueryQuestionnairesStateEnum, skipId?: string, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryQuestionnaires', 'company', company)
            const localVarPath = `/companies/{company}/questionnaires`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (skipId !== undefined) {
                localVarQueryParameter['skipId'] = skipId;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Based on request body method will add questions that are without id. Missing questions are removed with related documents. Then the result is sorted according to their positions in request 
         * @param {string} version 
         * @param {string} language 
         * @param {Array<QuestionDto>} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllQuestions: async (version: string, language: string, questionDto: Array<QuestionDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateAllQuestions', 'version', version)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('updateAllQuestions', 'language', language)
            // verify required parameter 'questionDto' is not null or undefined
            assertParamExists('updateAllQuestions', 'questionDto', questionDto)
            const localVarPath = `/questionnaire-version/{version}/questions/{language}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireDto} questionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaire: async (id: string, questionnaireDto: QuestionnaireDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateQuestionnaire', 'id', id)
            // verify required parameter 'questionnaireDto' is not null or undefined
            assertParamExists('updateQuestionnaire', 'questionnaireDto', questionnaireDto)
            const localVarPath = `/questionnaire/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersionOfQuestionnaire: async (id: string, questionnaireVersionDto: QuestionnaireVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVersionOfQuestionnaire', 'id', id)
            // verify required parameter 'questionnaireVersionDto' is not null or undefined
            assertParamExists('updateVersionOfQuestionnaire', 'questionnaireVersionDto', questionnaireVersionDto)
            const localVarPath = `/questionnaire-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnairesApi - functional programming interface
 * @export
 */
export const QuestionnairesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnairesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add new language for version
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLanguageForVersion(version: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLanguageForVersion(version, addLanguageVariant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.addLanguageForVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyQuestionnaireVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyQuestionnaireVersion(version, copyVersionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.copyQuestionnaireVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyVersionToNewQuestionnaire(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyVersionToNewQuestionnaire(version, copyVersionHolderToNewVersionableRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.copyVersionToNewQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyVersionToQuestionnaires(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyVersionToQuestionnaires(version, copyVersionHolderToVersionablesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.copyVersionToQuestionnaires']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * create copy of {language} with specific language defined by payload
         * @param {string} id 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCopyFromLanguageVersion(id: string, language: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCopyFromLanguageVersion(id, language, addLanguageVariant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.createCopyFromLanguageVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Operation will create questionnaire with where fields \'note\', \'minSuccessRate\' and \'validFrom`, are used for first version
         * @param {string} company 
         * @param {CreateQuestionnaireRequest} createQuestionnaireRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnaire(company: string, createQuestionnaireRequest: CreateQuestionnaireRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnaire(company, createQuestionnaireRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.createQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * create new version from version defined by id. payload can change only minSuccessRate, validFrom and note
         * @param {string} questionnaire 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuestionnaireVersion(questionnaire: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuestionnaireVersion(questionnaire, questionnaireVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.createQuestionnaireVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete questionnaire version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnaireVersion(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaireVersion(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.deleteQuestionnaireVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQuestionnaireVersionVariant(id: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQuestionnaireVersionVariant(id, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.deleteQuestionnaireVersionVariant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get specific version of questionnaire
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findVersionById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findVersionById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.findVersionById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get languages of active version for given questionnaire
         * @param {string} questionnaire 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguagesForTest(questionnaire: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionLanguageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguagesForTest(questionnaire, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.getLanguagesForTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch questionnaire by its id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaireById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaireById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.getQuestionnaireById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get questions for given version
         * @param {string} versionId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestions(versionId: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestions(versionId, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.getQuestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get versions of specific questionnaire
         * @param {string} questionnaireId 
         * @param {boolean} [activeOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersions(questionnaireId: string, activeOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQuestionnaireVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersions(questionnaireId, activeOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.getVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list of all questionnaires for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryQuestionnairesStateEnum} [state] 
         * @param {string} [skipId] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryQuestionnaires(company: string, q?: string, state?: QueryQuestionnairesStateEnum, skipId?: string, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQuestionnaireDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryQuestionnaires(company, q, state, skipId, nameOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.queryQuestionnaires']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Based on request body method will add questions that are without id. Missing questions are removed with related documents. Then the result is sorted according to their positions in request 
         * @param {string} version 
         * @param {string} language 
         * @param {Array<QuestionDto>} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllQuestions(version: string, language: string, questionDto: Array<QuestionDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllQuestions(version, language, questionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.updateAllQuestions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireDto} questionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuestionnaire(id: string, questionnaireDto: QuestionnaireDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestionnaire(id, questionnaireDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.updateQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersionOfQuestionnaire(id: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersionOfQuestionnaire(id, questionnaireVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionnairesApi.updateVersionOfQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestionnairesApi - factory interface
 * @export
 */
export const QuestionnairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnairesApiFp(configuration)
    return {
        /**
         * 
         * @summary add new language for version
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLanguageForVersion(version: string, addLanguageVariant: AddLanguageVariant, options?: any): AxiosPromise<void> {
            return localVarFp.addLanguageForVersion(version, addLanguageVariant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyQuestionnaireVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.copyQuestionnaireVersion(version, copyVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToNewQuestionnaire(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.copyVersionToNewQuestionnaire(version, copyVersionHolderToNewVersionableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create questionnaire version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToQuestionnaires(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.copyVersionToQuestionnaires(version, copyVersionHolderToVersionablesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create copy of {language} with specific language defined by payload
         * @param {string} id 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCopyFromLanguageVersion(id: string, language: string, addLanguageVariant: AddLanguageVariant, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createCopyFromLanguageVersion(id, language, addLanguageVariant, options).then((request) => request(axios, basePath));
        },
        /**
         * Operation will create questionnaire with where fields \'note\', \'minSuccessRate\' and \'validFrom`, are used for first version
         * @param {string} company 
         * @param {CreateQuestionnaireRequest} createQuestionnaireRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaire(company: string, createQuestionnaireRequest: CreateQuestionnaireRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createQuestionnaire(company, createQuestionnaireRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create new version from version defined by id. payload can change only minSuccessRate, validFrom and note
         * @param {string} questionnaire 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuestionnaireVersion(questionnaire: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createQuestionnaireVersion(questionnaire, questionnaireVersionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete questionnaire version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireVersion(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQuestionnaireVersion(version, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestionnaireVersionVariant(id: string, language: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteQuestionnaireVersionVariant(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * get specific version of questionnaire
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findVersionById(id: string, options?: any): AxiosPromise<QuestionnaireVersionDto> {
            return localVarFp.findVersionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get languages of active version for given questionnaire
         * @param {string} questionnaire 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesForTest(questionnaire: string, options?: any): AxiosPromise<VersionLanguageResponse> {
            return localVarFp.getLanguagesForTest(questionnaire, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch questionnaire by its id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaireById(id: string, options?: any): AxiosPromise<QuestionnaireDto> {
            return localVarFp.getQuestionnaireById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get questions for given version
         * @param {string} versionId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestions(versionId: string, language: string, options?: any): AxiosPromise<Array<QuestionDto>> {
            return localVarFp.getQuestions(versionId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * Get versions of specific questionnaire
         * @param {string} questionnaireId 
         * @param {boolean} [activeOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(questionnaireId: string, activeOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQuestionnaireVersionDto> {
            return localVarFp.getVersions(questionnaireId, activeOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of all questionnaires for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryQuestionnairesStateEnum} [state] 
         * @param {string} [skipId] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryQuestionnaires(company: string, q?: string, state?: QueryQuestionnairesStateEnum, skipId?: string, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQuestionnaireDto> {
            return localVarFp.queryQuestionnaires(company, q, state, skipId, nameOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Based on request body method will add questions that are without id. Missing questions are removed with related documents. Then the result is sorted according to their positions in request 
         * @param {string} version 
         * @param {string} language 
         * @param {Array<QuestionDto>} questionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllQuestions(version: string, language: string, questionDto: Array<QuestionDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updateAllQuestions(version, language, questionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireDto} questionnaireDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestionnaire(id: string, questionnaireDto: QuestionnaireDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateQuestionnaire(id, questionnaireDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {QuestionnaireVersionDto} questionnaireVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersionOfQuestionnaire(id: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVersionOfQuestionnaire(id, questionnaireVersionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionnairesApi - object-oriented interface
 * @export
 * @class QuestionnairesApi
 * @extends {BaseAPI}
 */
export class QuestionnairesApi extends BaseAPI {
    /**
     * 
     * @summary add new language for version
     * @param {string} version 
     * @param {AddLanguageVariant} addLanguageVariant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public addLanguageForVersion(version: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).addLanguageForVersion(version, addLanguageVariant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create questionnaire version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionRequest} copyVersionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public copyQuestionnaireVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).copyQuestionnaireVersion(version, copyVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create questionnaire version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public copyVersionToNewQuestionnaire(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).copyVersionToNewQuestionnaire(version, copyVersionHolderToNewVersionableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create questionnaire version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public copyVersionToQuestionnaires(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).copyVersionToQuestionnaires(version, copyVersionHolderToVersionablesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create copy of {language} with specific language defined by payload
     * @param {string} id 
     * @param {string} language 
     * @param {AddLanguageVariant} addLanguageVariant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public createCopyFromLanguageVersion(id: string, language: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).createCopyFromLanguageVersion(id, language, addLanguageVariant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Operation will create questionnaire with where fields \'note\', \'minSuccessRate\' and \'validFrom`, are used for first version
     * @param {string} company 
     * @param {CreateQuestionnaireRequest} createQuestionnaireRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public createQuestionnaire(company: string, createQuestionnaireRequest: CreateQuestionnaireRequest, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).createQuestionnaire(company, createQuestionnaireRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create new version from version defined by id. payload can change only minSuccessRate, validFrom and note
     * @param {string} questionnaire 
     * @param {QuestionnaireVersionDto} questionnaireVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public createQuestionnaireVersion(questionnaire: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).createQuestionnaireVersion(questionnaire, questionnaireVersionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete questionnaire version
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public deleteQuestionnaireVersion(version: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).deleteQuestionnaireVersion(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete questionnaire variant of version which was never active
     * @param {string} id 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public deleteQuestionnaireVersionVariant(id: string, language: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).deleteQuestionnaireVersionVariant(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get specific version of questionnaire
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public findVersionById(id: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).findVersionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get languages of active version for given questionnaire
     * @param {string} questionnaire 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public getLanguagesForTest(questionnaire: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).getLanguagesForTest(questionnaire, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch questionnaire by its id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public getQuestionnaireById(id: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).getQuestionnaireById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get questions for given version
     * @param {string} versionId 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public getQuestions(versionId: string, language: string, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).getQuestions(versionId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get versions of specific questionnaire
     * @param {string} questionnaireId 
     * @param {boolean} [activeOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public getVersions(questionnaireId: string, activeOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).getVersions(questionnaireId, activeOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of all questionnaires for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryQuestionnairesStateEnum} [state] 
     * @param {string} [skipId] 
     * @param {boolean} [nameOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public queryQuestionnaires(company: string, q?: string, state?: QueryQuestionnairesStateEnum, skipId?: string, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).queryQuestionnaires(company, q, state, skipId, nameOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Based on request body method will add questions that are without id. Missing questions are removed with related documents. Then the result is sorted according to their positions in request 
     * @param {string} version 
     * @param {string} language 
     * @param {Array<QuestionDto>} questionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public updateAllQuestions(version: string, language: string, questionDto: Array<QuestionDto>, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).updateAllQuestions(version, language, questionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {QuestionnaireDto} questionnaireDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public updateQuestionnaire(id: string, questionnaireDto: QuestionnaireDto, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).updateQuestionnaire(id, questionnaireDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {QuestionnaireVersionDto} questionnaireVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public updateVersionOfQuestionnaire(id: string, questionnaireVersionDto: QuestionnaireVersionDto, options?: RawAxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).updateVersionOfQuestionnaire(id, questionnaireVersionDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryQuestionnairesStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryQuestionnairesStateEnum = typeof QueryQuestionnairesStateEnum[keyof typeof QueryQuestionnairesStateEnum];


/**
 * RiskApi - axios parameter creator
 * @export
 */
export const RiskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} company 
         * @param {CreateRiskRequest} createRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRisk: async (company: string, createRiskRequest: CreateRiskRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createRisk', 'company', company)
            // verify required parameter 'createRiskRequest' is not null or undefined
            assertParamExists('createRisk', 'createRiskRequest', createRiskRequest)
            const localVarPath = `/companies/{company}/risks`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRiskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskVersion: async (id: string, riskVersionDto: RiskVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createRiskVersion', 'id', id)
            // verify required parameter 'riskVersionDto' is not null or undefined
            assertParamExists('createRiskVersion', 'riskVersionDto', riskVersionDto)
            const localVarPath = `/risks/{id}/versions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete risk version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRiskVersion: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRiskVersion', 'id', id)
            const localVarPath = `/risk-versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisk: async (id: string, invitation?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRisk', 'id', id)
            const localVarPath = `/risks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invitation !== undefined) {
                localVarQueryParameter['invitation'] = invitation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersion: async (id: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRiskVersion', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getRiskVersion', 'version', version)
            const localVarPath = `/risks/{id}/versions/{version}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersionDocument: async (id: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRiskVersionDocument', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getRiskVersionDocument', 'version', version)
            const localVarPath = `/risks/{id}/versions/{version}/document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersions: async (id: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRiskVersions', 'id', id)
            const localVarPath = `/risks/{id}/versions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryRisksStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryRisks: async (company: string, q?: string, state?: QueryRisksStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryRisks', 'company', company)
            const localVarPath = `/companies/{company}/risks`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskDto} riskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRisk: async (id: string, riskDto: RiskDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRisk', 'id', id)
            // verify required parameter 'riskDto' is not null or undefined
            assertParamExists('updateRisk', 'riskDto', riskDto)
            const localVarPath = `/risks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskVersion: async (id: string, version: string, riskVersionDto: RiskVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRiskVersion', 'id', id)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateRiskVersion', 'version', version)
            // verify required parameter 'riskVersionDto' is not null or undefined
            assertParamExists('updateRiskVersion', 'riskVersionDto', riskVersionDto)
            const localVarPath = `/risks/{id}/versions/{version}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RiskApi - functional programming interface
 * @export
 */
export const RiskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RiskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} company 
         * @param {CreateRiskRequest} createRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRisk(company: string, createRiskRequest: CreateRiskRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRisk(company, createRiskRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.createRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskVersion(id: string, riskVersionDto: RiskVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskVersion(id, riskVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.createRiskVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete risk version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRiskVersion(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRiskVersion(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.deleteRiskVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRisk(id: string, invitation?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRisk(id, invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.getRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskVersion(id: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskVersion(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.getRiskVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskVersionDocument(id: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskVersionDocument(id, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.getRiskVersionDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskVersions(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRiskVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskVersions(id, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.getRiskVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryRisksStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryRisks(company: string, q?: string, state?: QueryRisksStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRiskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryRisks(company, q, state, nameOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.queryRisks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskDto} riskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRisk(id: string, riskDto: RiskDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRisk(id, riskDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.updateRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskVersion(id: string, version: string, riskVersionDto: RiskVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskVersion(id, version, riskVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RiskApi.updateRiskVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RiskApi - factory interface
 * @export
 */
export const RiskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RiskApiFp(configuration)
    return {
        /**
         * 
         * @param {string} company 
         * @param {CreateRiskRequest} createRiskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRisk(company: string, createRiskRequest: CreateRiskRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createRisk(company, createRiskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskVersion(id: string, riskVersionDto: RiskVersionDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createRiskVersion(id, riskVersionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete risk version
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRiskVersion(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRiskVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisk(id: string, invitation?: string, options?: any): AxiosPromise<RiskDto> {
            return localVarFp.getRisk(id, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersion(id: string, version: string, options?: any): AxiosPromise<RiskVersionDto> {
            return localVarFp.getRiskVersion(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersionDocument(id: string, version: string, options?: any): AxiosPromise<File> {
            return localVarFp.getRiskVersionDocument(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVersions(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRiskVersionDto> {
            return localVarFp.getRiskVersions(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryRisksStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryRisks(company: string, q?: string, state?: QueryRisksStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRiskDto> {
            return localVarFp.queryRisks(company, q, state, nameOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RiskDto} riskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRisk(id: string, riskDto: RiskDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateRisk(id, riskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} version 
         * @param {RiskVersionDto} riskVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskVersion(id: string, version: string, riskVersionDto: RiskVersionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateRiskVersion(id, version, riskVersionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RiskApi - object-oriented interface
 * @export
 * @class RiskApi
 * @extends {BaseAPI}
 */
export class RiskApi extends BaseAPI {
    /**
     * 
     * @param {string} company 
     * @param {CreateRiskRequest} createRiskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public createRisk(company: string, createRiskRequest: CreateRiskRequest, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).createRisk(company, createRiskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RiskVersionDto} riskVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public createRiskVersion(id: string, riskVersionDto: RiskVersionDto, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).createRiskVersion(id, riskVersionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete risk version
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public deleteRiskVersion(id: string, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).deleteRiskVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [invitation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public getRisk(id: string, invitation?: string, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).getRisk(id, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public getRiskVersion(id: string, version: string, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).getRiskVersion(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public getRiskVersionDocument(id: string, version: string, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).getRiskVersionDocument(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public getRiskVersions(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).getRiskVersions(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryRisksStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public queryRisks(company: string, q?: string, state?: QueryRisksStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).queryRisks(company, q, state, nameOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RiskDto} riskDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public updateRisk(id: string, riskDto: RiskDto, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).updateRisk(id, riskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} version 
     * @param {RiskVersionDto} riskVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RiskApi
     */
    public updateRiskVersion(id: string, version: string, riskVersionDto: RiskVersionDto, options?: RawAxiosRequestConfig) {
        return RiskApiFp(this.configuration).updateRiskVersion(id, version, riskVersionDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryRisksStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryRisksStateEnum = typeof QueryRisksStateEnum[keyof typeof QueryRisksStateEnum];


/**
 * SafetyEquipmentApi - axios parameter creator
 * @export
 */
export const SafetyEquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The purpose of this method is to create safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create safety equipment
         * @param {string} company 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSafetyEquipment: async (company: string, safetyEquipmentDto: SafetyEquipmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createSafetyEquipment', 'company', company)
            // verify required parameter 'safetyEquipmentDto' is not null or undefined
            assertParamExists('createSafetyEquipment', 'safetyEquipmentDto', safetyEquipmentDto)
            const localVarPath = `/api/v1/companies/{company}/safety-equipments`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(safetyEquipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide safety equipment for given company. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSafetyEquipment: async (safetyEquipment: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'safetyEquipment' is not null or undefined
            assertParamExists('getSafetyEquipment', 'safetyEquipment', safetyEquipment)
            const localVarPath = `/api/v1/safety-equipments/{safetyEquipment}`
                .replace(`{${"safetyEquipment"}}`, encodeURIComponent(String(safetyEquipment)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all safety equipments. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all safety equipments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySafetyEquipmentStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySafetyEquipment: async (company: string, q?: string, state?: QuerySafetyEquipmentStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('querySafetyEquipment', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/safety-equipments`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSafetyEquipment: async (safetyEquipment: string, safetyEquipmentDto: SafetyEquipmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'safetyEquipment' is not null or undefined
            assertParamExists('updateSafetyEquipment', 'safetyEquipment', safetyEquipment)
            // verify required parameter 'safetyEquipmentDto' is not null or undefined
            assertParamExists('updateSafetyEquipment', 'safetyEquipmentDto', safetyEquipmentDto)
            const localVarPath = `/api/v1/safety-equipments/{safetyEquipment}`
                .replace(`{${"safetyEquipment"}}`, encodeURIComponent(String(safetyEquipment)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(safetyEquipmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SafetyEquipmentApi - functional programming interface
 * @export
 */
export const SafetyEquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SafetyEquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * The purpose of this method is to create safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create safety equipment
         * @param {string} company 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSafetyEquipment(company: string, safetyEquipmentDto: SafetyEquipmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSafetyEquipment(company, safetyEquipmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SafetyEquipmentApi.createSafetyEquipment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide safety equipment for given company. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSafetyEquipment(safetyEquipment: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SafetyEquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSafetyEquipment(safetyEquipment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SafetyEquipmentApi.getSafetyEquipment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all safety equipments. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all safety equipments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySafetyEquipmentStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async querySafetyEquipment(company: string, q?: string, state?: QuerySafetyEquipmentStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSafetyEquipmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.querySafetyEquipment(company, q, state, nameOnly, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SafetyEquipmentApi.querySafetyEquipment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSafetyEquipment(safetyEquipment: string, safetyEquipmentDto: SafetyEquipmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSafetyEquipment(safetyEquipment, safetyEquipmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SafetyEquipmentApi.updateSafetyEquipment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SafetyEquipmentApi - factory interface
 * @export
 */
export const SafetyEquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SafetyEquipmentApiFp(configuration)
    return {
        /**
         * The purpose of this method is to create safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Create safety equipment
         * @param {string} company 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSafetyEquipment(company: string, safetyEquipmentDto: SafetyEquipmentDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createSafetyEquipment(company, safetyEquipmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide safety equipment for given company. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSafetyEquipment(safetyEquipment: string, options?: any): AxiosPromise<SafetyEquipmentDto> {
            return localVarFp.getSafetyEquipment(safetyEquipment, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all safety equipments. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Get all safety equipments for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySafetyEquipmentStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySafetyEquipment(company: string, q?: string, state?: QuerySafetyEquipmentStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSafetyEquipmentDto> {
            return localVarFp.querySafetyEquipment(company, q, state, nameOnly, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update safety equipment. Available only to SCOPE_MANAGER_OSAH role.
         * @summary Update safety equipment for given company
         * @param {string} safetyEquipment 
         * @param {SafetyEquipmentDto} safetyEquipmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSafetyEquipment(safetyEquipment: string, safetyEquipmentDto: SafetyEquipmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSafetyEquipment(safetyEquipment, safetyEquipmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SafetyEquipmentApi - object-oriented interface
 * @export
 * @class SafetyEquipmentApi
 * @extends {BaseAPI}
 */
export class SafetyEquipmentApi extends BaseAPI {
    /**
     * The purpose of this method is to create safety equipment. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Create safety equipment
     * @param {string} company 
     * @param {SafetyEquipmentDto} safetyEquipmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyEquipmentApi
     */
    public createSafetyEquipment(company: string, safetyEquipmentDto: SafetyEquipmentDto, options?: RawAxiosRequestConfig) {
        return SafetyEquipmentApiFp(this.configuration).createSafetyEquipment(company, safetyEquipmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide safety equipment for given company. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Get safety equipment for given company
     * @param {string} safetyEquipment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyEquipmentApi
     */
    public getSafetyEquipment(safetyEquipment: string, options?: RawAxiosRequestConfig) {
        return SafetyEquipmentApiFp(this.configuration).getSafetyEquipment(safetyEquipment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all safety equipments. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Get all safety equipments for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QuerySafetyEquipmentStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyEquipmentApi
     */
    public querySafetyEquipment(company: string, q?: string, state?: QuerySafetyEquipmentStateEnum, nameOnly?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SafetyEquipmentApiFp(this.configuration).querySafetyEquipment(company, q, state, nameOnly, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update safety equipment. Available only to SCOPE_MANAGER_OSAH role.
     * @summary Update safety equipment for given company
     * @param {string} safetyEquipment 
     * @param {SafetyEquipmentDto} safetyEquipmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafetyEquipmentApi
     */
    public updateSafetyEquipment(safetyEquipment: string, safetyEquipmentDto: SafetyEquipmentDto, options?: RawAxiosRequestConfig) {
        return SafetyEquipmentApiFp(this.configuration).updateSafetyEquipment(safetyEquipment, safetyEquipmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QuerySafetyEquipmentStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QuerySafetyEquipmentStateEnum = typeof QuerySafetyEquipmentStateEnum[keyof typeof QuerySafetyEquipmentStateEnum];


/**
 * SupplierApi - axios parameter creator
 * @export
 */
export const SupplierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} company 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplier: async (company: string, supplierDto: SupplierDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createSupplier', 'company', company)
            // verify required parameter 'supplierDto' is not null or undefined
            assertParamExists('createSupplier', 'supplierDto', supplierDto)
            const localVarPath = `/api/v1/companies/{company}/suppliers`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} supplier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplier: async (supplier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplier' is not null or undefined
            assertParamExists('getSupplier', 'supplier', supplier)
            const localVarPath = `/api/v1/suppliers/{supplier}`
                .replace(`{${"supplier"}}`, encodeURIComponent(String(supplier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySuppliersStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySuppliers: async (company: string, q?: string, state?: QuerySuppliersStateEnum, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('querySuppliers', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/suppliers`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} supplier 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplier: async (supplier: string, supplierDto: SupplierDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplier' is not null or undefined
            assertParamExists('updateSupplier', 'supplier', supplier)
            // verify required parameter 'supplierDto' is not null or undefined
            assertParamExists('updateSupplier', 'supplierDto', supplierDto)
            const localVarPath = `/api/v1/suppliers/{supplier}`
                .replace(`{${"supplier"}}`, encodeURIComponent(String(supplier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierApi - functional programming interface
 * @export
 */
export const SupplierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierApiAxiosParamCreator(configuration)
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} company 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplier(company: string, supplierDto: SupplierDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplier(company, supplierDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierApi.createSupplier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} supplier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplier(supplier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplier(supplier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierApi.getSupplier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySuppliersStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async querySuppliers(company: string, q?: string, state?: QuerySuppliersStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSupplierDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.querySuppliers(company, q, state, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierApi.querySuppliers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} supplier 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplier(supplier: string, supplierDto: SupplierDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplier(supplier, supplierDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierApi.updateSupplier']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupplierApi - factory interface
 * @export
 */
export const SupplierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierApiFp(configuration)
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} company 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplier(company: string, supplierDto: SupplierDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createSupplier(company, supplierDto, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} supplier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplier(supplier: string, options?: any): AxiosPromise<SupplierDto> {
            return localVarFp.getSupplier(supplier, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} company 
         * @param {string} [q] 
         * @param {QuerySuppliersStateEnum} [state] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySuppliers(company: string, q?: string, state?: QuerySuppliersStateEnum, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSupplierDto> {
            return localVarFp.querySuppliers(company, q, state, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} supplier 
         * @param {SupplierDto} supplierDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplier(supplier: string, supplierDto: SupplierDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSupplier(supplier, supplierDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierApi - object-oriented interface
 * @export
 * @class SupplierApi
 * @extends {BaseAPI}
 */
export class SupplierApi extends BaseAPI {
    /**
     * tbd
     * @summary create supplier
     * @param {string} company 
     * @param {SupplierDto} supplierDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierApi
     */
    public createSupplier(company: string, supplierDto: SupplierDto, options?: RawAxiosRequestConfig) {
        return SupplierApiFp(this.configuration).createSupplier(company, supplierDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get supplier
     * @param {string} supplier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierApi
     */
    public getSupplier(supplier: string, options?: RawAxiosRequestConfig) {
        return SupplierApiFp(this.configuration).getSupplier(supplier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get suppliers
     * @param {string} company 
     * @param {string} [q] 
     * @param {QuerySuppliersStateEnum} [state] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierApi
     */
    public querySuppliers(company: string, q?: string, state?: QuerySuppliersStateEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SupplierApiFp(this.configuration).querySuppliers(company, q, state, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary update supplier
     * @param {string} supplier 
     * @param {SupplierDto} supplierDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierApi
     */
    public updateSupplier(supplier: string, supplierDto: SupplierDto, options?: RawAxiosRequestConfig) {
        return SupplierApiFp(this.configuration).updateSupplier(supplier, supplierDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QuerySuppliersStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QuerySuppliersStateEnum = typeof QuerySuppliersStateEnum[keyof typeof QuerySuppliersStateEnum];


/**
 * SupplierEmployeeApi - axios parameter creator
 * @export
 */
export const SupplierEmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} supplier 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierEmployee: async (supplier: string, supplierEmployeeDto: SupplierEmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplier' is not null or undefined
            assertParamExists('createSupplierEmployee', 'supplier', supplier)
            // verify required parameter 'supplierEmployeeDto' is not null or undefined
            assertParamExists('createSupplierEmployee', 'supplierEmployeeDto', supplierEmployeeDto)
            const localVarPath = `/api/v1/suppliers/{supplier}/employees`
                .replace(`{${"supplier"}}`, encodeURIComponent(String(supplier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get certificates
         * @param {string} employee 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificates: async (employee: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('getCertificates', 'employee', employee)
            const localVarPath = `/api/v1/supplier-employees/{employee}/certificates`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get data
         * @param {string} employeeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsData: async (employeeId: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getInvitationsData', 'employeeId', employeeId)
            const localVarPath = `/api/v1/supplier-employees/{employeeId}/invitations-data`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsDataFromUserId: async (userId: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInvitationsDataFromUserId', 'userId', userId)
            const localVarPath = `/api/v1/supplier-employees-as-user/{userId}/invitations-data`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierEmployee: async (employee: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('getSupplierEmployee', 'employee', employee)
            const localVarPath = `/api/v1/supplier-employees/{employee}`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierEmployeeProtocol: async (employee: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('getSupplierEmployeeProtocol', 'employee', employee)
            const localVarPath = `/api/v1/supplier-employees/{employee}/protocol`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierFromUserId: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSupplierFromUserId', 'userId', userId)
            const localVarPath = `/api/v1/supplier-employees-as-user/{userId}/supplier`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} supplier 
         * @param {string} [q] 
         * @param {Array<QuerySupplierEmployeesStateEnum>} [state] 
         * @param {QuerySupplierEmployeesRoleEnum} [role] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierEmployees: async (supplier: string, q?: string, state?: Array<QuerySupplierEmployeesStateEnum>, role?: QuerySupplierEmployeesRoleEnum, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplier' is not null or undefined
            assertParamExists('querySupplierEmployees', 'supplier', supplier)
            const localVarPath = `/api/v1/suppliers/{supplier}/employees`
                .replace(`{${"supplier"}}`, encodeURIComponent(String(supplier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary reveal pin
         * @param {string} employee 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revealPin: async (employee: string, invitation?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('revealPin', 'employee', employee)
            const localVarPath = `/api/v1/suppliers-employees/{employee}/reveal-pin`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invitation !== undefined) {
                localVarQueryParameter['invitation'] = invitation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierEmployee: async (employee: string, supplierEmployeeDto: SupplierEmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('updateSupplierEmployee', 'employee', employee)
            // verify required parameter 'supplierEmployeeDto' is not null or undefined
            assertParamExists('updateSupplierEmployee', 'supplierEmployeeDto', supplierEmployeeDto)
            const localVarPath = `/api/v1/supplier-employees/{employee}`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Make visitor as supplier employee
         * @param {string} supplier 
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitorToSupplierEmployee: async (supplier: string, employee: string, supplierEmployeeDto: SupplierEmployeeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplier' is not null or undefined
            assertParamExists('updateVisitorToSupplierEmployee', 'supplier', supplier)
            // verify required parameter 'employee' is not null or undefined
            assertParamExists('updateVisitorToSupplierEmployee', 'employee', employee)
            // verify required parameter 'supplierEmployeeDto' is not null or undefined
            assertParamExists('updateVisitorToSupplierEmployee', 'supplierEmployeeDto', supplierEmployeeDto)
            const localVarPath = `/api/v1/supplier-employees/{employee}/assignToSupplier`
                .replace(`{${"employee"}}`, encodeURIComponent(String(employee)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (supplier !== undefined) {
                localVarQueryParameter['supplier'] = supplier;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierEmployeeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierEmployeeApi - functional programming interface
 * @export
 */
export const SupplierEmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierEmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} supplier 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupplierEmployee(supplier: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupplierEmployee(supplier, supplierEmployeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.createSupplierEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get certificates
         * @param {string} employee 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCertificates(employee: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSupplierCertDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCertificates(employee, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getCertificates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get data
         * @param {string} employeeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationsData(employeeId: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationPersonStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationsData(employeeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getInvitationsData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationsDataFromUserId(userId: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageInvitationPersonStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationsDataFromUserId(userId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getInvitationsDataFromUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplierEmployee(employee: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierEmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplierEmployee(employee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getSupplierEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplierEmployeeProtocol(employee: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplierEmployeeProtocol(employee, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getSupplierEmployeeProtocol']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupplierFromUserId(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupplierFromUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.getSupplierFromUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} supplier 
         * @param {string} [q] 
         * @param {Array<QuerySupplierEmployeesStateEnum>} [state] 
         * @param {QuerySupplierEmployeesRoleEnum} [role] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async querySupplierEmployees(supplier: string, q?: string, state?: Array<QuerySupplierEmployeesStateEnum>, role?: QuerySupplierEmployeesRoleEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSupplierEmployeeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.querySupplierEmployees(supplier, q, state, role, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.querySupplierEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary reveal pin
         * @param {string} employee 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revealPin(employee: string, invitation?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revealPin(employee, invitation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.revealPin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupplierEmployee(employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSupplierEmployee(employee, supplierEmployeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.updateSupplierEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Make visitor as supplier employee
         * @param {string} supplier 
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVisitorToSupplierEmployee(supplier: string, employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVisitorToSupplierEmployee(supplier, employee, supplierEmployeeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupplierEmployeeApi.updateVisitorToSupplierEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupplierEmployeeApi - factory interface
 * @export
 */
export const SupplierEmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierEmployeeApiFp(configuration)
    return {
        /**
         * tbd
         * @summary create supplier
         * @param {string} supplier 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierEmployee(supplier: string, supplierEmployeeDto: SupplierEmployeeDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createSupplierEmployee(supplier, supplierEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get certificates
         * @param {string} employee 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificates(employee: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSupplierCertDto> {
            return localVarFp.getCertificates(employee, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get data
         * @param {string} employeeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsData(employeeId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationPersonStatusResponse> {
            return localVarFp.getInvitationsData(employeeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsDataFromUserId(userId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageInvitationPersonStatusResponse> {
            return localVarFp.getInvitationsDataFromUserId(userId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierEmployee(employee: string, options?: any): AxiosPromise<SupplierEmployeeDto> {
            return localVarFp.getSupplierEmployee(employee, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get supplier
         * @param {string} employee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierEmployeeProtocol(employee: string, options?: any): AxiosPromise<File> {
            return localVarFp.getSupplierEmployeeProtocol(employee, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get data
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierFromUserId(userId: string, options?: any): AxiosPromise<SupplierDto> {
            return localVarFp.getSupplierFromUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary get suppliers
         * @param {string} supplier 
         * @param {string} [q] 
         * @param {Array<QuerySupplierEmployeesStateEnum>} [state] 
         * @param {QuerySupplierEmployeesRoleEnum} [role] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierEmployees(supplier: string, q?: string, state?: Array<QuerySupplierEmployeesStateEnum>, role?: QuerySupplierEmployeesRoleEnum, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSupplierEmployeeDto> {
            return localVarFp.querySupplierEmployees(supplier, q, state, role, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary reveal pin
         * @param {string} employee 
         * @param {string} [invitation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revealPin(employee: string, invitation?: string, options?: any): AxiosPromise<PinResponse> {
            return localVarFp.revealPin(employee, invitation, options).then((request) => request(axios, basePath));
        },
        /**
         * tbd
         * @summary update supplier
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierEmployee(employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSupplierEmployee(employee, supplierEmployeeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Make visitor as supplier employee
         * @param {string} supplier 
         * @param {string} employee 
         * @param {SupplierEmployeeDto} supplierEmployeeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitorToSupplierEmployee(supplier: string, employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVisitorToSupplierEmployee(supplier, employee, supplierEmployeeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierEmployeeApi - object-oriented interface
 * @export
 * @class SupplierEmployeeApi
 * @extends {BaseAPI}
 */
export class SupplierEmployeeApi extends BaseAPI {
    /**
     * tbd
     * @summary create supplier
     * @param {string} supplier 
     * @param {SupplierEmployeeDto} supplierEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public createSupplierEmployee(supplier: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).createSupplierEmployee(supplier, supplierEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get certificates
     * @param {string} employee 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getCertificates(employee: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getCertificates(employee, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get data
     * @param {string} employeeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getInvitationsData(employeeId: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getInvitationsData(employeeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get data
     * @param {string} userId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getInvitationsDataFromUserId(userId: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getInvitationsDataFromUserId(userId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get supplier
     * @param {string} employee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getSupplierEmployee(employee: string, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getSupplierEmployee(employee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get supplier
     * @param {string} employee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getSupplierEmployeeProtocol(employee: string, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getSupplierEmployeeProtocol(employee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get data
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public getSupplierFromUserId(userId: string, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).getSupplierFromUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary get suppliers
     * @param {string} supplier 
     * @param {string} [q] 
     * @param {Array<QuerySupplierEmployeesStateEnum>} [state] 
     * @param {QuerySupplierEmployeesRoleEnum} [role] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public querySupplierEmployees(supplier: string, q?: string, state?: Array<QuerySupplierEmployeesStateEnum>, role?: QuerySupplierEmployeesRoleEnum, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).querySupplierEmployees(supplier, q, state, role, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary reveal pin
     * @param {string} employee 
     * @param {string} [invitation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public revealPin(employee: string, invitation?: string, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).revealPin(employee, invitation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * tbd
     * @summary update supplier
     * @param {string} employee 
     * @param {SupplierEmployeeDto} supplierEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public updateSupplierEmployee(employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).updateSupplierEmployee(employee, supplierEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Make visitor as supplier employee
     * @param {string} supplier 
     * @param {string} employee 
     * @param {SupplierEmployeeDto} supplierEmployeeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierEmployeeApi
     */
    public updateVisitorToSupplierEmployee(supplier: string, employee: string, supplierEmployeeDto: SupplierEmployeeDto, options?: RawAxiosRequestConfig) {
        return SupplierEmployeeApiFp(this.configuration).updateVisitorToSupplierEmployee(supplier, employee, supplierEmployeeDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QuerySupplierEmployeesStateEnum = {
    Invited: 'INVITED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QuerySupplierEmployeesStateEnum = typeof QuerySupplierEmployeesStateEnum[keyof typeof QuerySupplierEmployeesStateEnum];
/**
 * @export
 */
export const QuerySupplierEmployeesRoleEnum = {
    AdminQcs: 'ADMIN_QCS',
    AdminCompany: 'ADMIN_COMPANY',
    ManagerOsah: 'MANAGER_OSAH',
    ManagerWorkplace: 'MANAGER_WORKPLACE',
    Receptionist: 'RECEPTIONIST',
    Tourniquet: 'TOURNIQUET',
    TourniquetTraining: 'TOURNIQUET_TRAINING',
    ExternalManager: 'EXTERNAL_MANAGER',
    ExternalOneTime: 'EXTERNAL_ONE_TIME',
    ExternalWorker: 'EXTERNAL_WORKER',
    RemoteToken: 'REMOTE_TOKEN'
} as const;
export type QuerySupplierEmployeesRoleEnum = typeof QuerySupplierEmployeesRoleEnum[keyof typeof QuerySupplierEmployeesRoleEnum];


/**
 * TrainingsApi - axios parameter creator
 * @export
 */
export const TrainingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add new Language variant for training
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLanguage: async (version: string, addLanguageVariant: AddLanguageVariant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('addLanguage', 'version', version)
            // verify required parameter 'addLanguageVariant' is not null or undefined
            assertParamExists('addLanguage', 'addLanguageVariant', addLanguageVariant)
            const localVarPath = `/training-versions/{version}/languages`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLanguageVariant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change order of slides
         * @param {string} version 
         * @param {string} language 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrder: async (version: string, language: string, updateContainerOrder: UpdateContainerOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('changeOrder', 'version', version)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('changeOrder', 'language', language)
            // verify required parameter 'updateContainerOrder' is not null or undefined
            assertParamExists('changeOrder', 'updateContainerOrder', updateContainerOrder)
            const localVarPath = `/training-versions/{version}/order`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContainerOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create single copy of language version defined by id and language on path
         * @param {string} version 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyLanguage: async (version: string, language: string, addLanguageVariant: AddLanguageVariant, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyLanguage', 'version', version)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('copyLanguage', 'language', language)
            // verify required parameter 'addLanguageVariant' is not null or undefined
            assertParamExists('copyLanguage', 'addLanguageVariant', addLanguageVariant)
            const localVarPath = `/training-versions/{version}/languages/{language}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLanguageVariant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyTrainingVersion: async (version: string, copyVersionRequest: CopyVersionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyTrainingVersion', 'version', version)
            // verify required parameter 'copyVersionRequest' is not null or undefined
            assertParamExists('copyTrainingVersion', 'copyVersionRequest', copyVersionRequest)
            const localVarPath = `/training-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToNewTraining: async (version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyVersionToNewTraining', 'version', version)
            // verify required parameter 'copyVersionHolderToNewVersionableRequest' is not null or undefined
            assertParamExists('copyVersionToNewTraining', 'copyVersionHolderToNewVersionableRequest', copyVersionHolderToNewVersionableRequest)
            const localVarPath = `/training-versions/{version}/new`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionHolderToNewVersionableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToTrainings: async (version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('copyVersionToTrainings', 'version', version)
            // verify required parameter 'copyVersionHolderToVersionablesRequest' is not null or undefined
            assertParamExists('copyVersionToTrainings', 'copyVersionHolderToVersionablesRequest', copyVersionHolderToVersionablesRequest)
            const localVarPath = `/training-versions/{version}/copy`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyVersionHolderToVersionablesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new training
         * @param {string} company 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (company: string, trainingDto: TrainingDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('create', 'company', company)
            // verify required parameter 'trainingDto' is not null or undefined
            assertParamExists('create', 'trainingDto', trainingDto)
            const localVarPath = `/companies/{company}/trainings`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create slide for given version
         * @param {string} version 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlide: async (version: string, slideDto: SlideDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('createSlide', 'version', version)
            // verify required parameter 'slideDto' is not null or undefined
            assertParamExists('createSlide', 'slideDto', slideDto)
            const localVarPath = `/training-versions/{version}/slides`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slideDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create version of training
         * @param {string} training 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion: async (training: string, trainingVersionDto: TrainingVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'training' is not null or undefined
            assertParamExists('createVersion', 'training', training)
            // verify required parameter 'trainingVersionDto' is not null or undefined
            assertParamExists('createVersion', 'trainingVersionDto', trainingVersionDto)
            const localVarPath = `/trainings/{training}/versions`
                .replace(`{${"training"}}`, encodeURIComponent(String(training)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete specific slide
         * @param {string} slide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlide: async (slide: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slide' is not null or undefined
            assertParamExists('deleteSlide', 'slide', slide)
            const localVarPath = `/slides/{slide}`
                .replace(`{${"slide"}}`, encodeURIComponent(String(slide)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete future version of training
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainingVersion: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteTrainingVersion', 'version', version)
            const localVarPath = `/training-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariant: async (version: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('deleteVariant', 'version', version)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('deleteVariant', 'language', language)
            const localVarPath = `/training-versions/{version}/slides/{language}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get training by its id
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (training: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'training' is not null or undefined
            assertParamExists('getById', 'training', training)
            const localVarPath = `/trainings/{training}`
                .replace(`{${"training"}}`, encodeURIComponent(String(training)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get languages of active version for given training
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesForTraining: async (training: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'training' is not null or undefined
            assertParamExists('getLanguagesForTraining', 'training', training)
            const localVarPath = `/trainings/{training}/languages`
                .replace(`{${"training"}}`, encodeURIComponent(String(training)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get slides of given training version. Result can be limited by specifying language of slides
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlides: async (version: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getSlides', 'version', version)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getSlides', 'language', language)
            const localVarPath = `/training-versions/{version}/slides/{language}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get specific training version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingVersion: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getTrainingVersion', 'version', version)
            const localVarPath = `/training-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all of the trainings for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {string} [skipId] 
         * @param {QueryTrainingsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {boolean} [ignoreWithoutQuestionnaireWithLanguage] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTrainings: async (company: string, q?: string, skipId?: string, state?: QueryTrainingsStateEnum, nameOnly?: boolean, ignoreWithoutQuestionnaireWithLanguage?: boolean, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryTrainings', 'company', company)
            const localVarPath = `/companies/{company}/trainings`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (skipId !== undefined) {
                localVarQueryParameter['skipId'] = skipId;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (ignoreWithoutQuestionnaireWithLanguage !== undefined) {
                localVarQueryParameter['ignoreWithoutQuestionnaireWithLanguage'] = ignoreWithoutQuestionnaireWithLanguage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update training
         * @param {string} training 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (training: string, trainingDto: TrainingDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'training' is not null or undefined
            assertParamExists('update', 'training', training)
            // verify required parameter 'trainingDto' is not null or undefined
            assertParamExists('update', 'trainingDto', trainingDto)
            const localVarPath = `/trainings/{training}`
                .replace(`{${"training"}}`, encodeURIComponent(String(training)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update single slide
         * @param {string} slide 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlide: async (slide: string, slideDto: SlideDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slide' is not null or undefined
            assertParamExists('updateSlide', 'slide', slide)
            // verify required parameter 'slideDto' is not null or undefined
            assertParamExists('updateSlide', 'slideDto', slideDto)
            const localVarPath = `/slides/{slide}`
                .replace(`{${"slide"}}`, encodeURIComponent(String(slide)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slideDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update version of training version
         * @param {string} version 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion: async (version: string, trainingVersionDto: TrainingVersionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('updateVersion', 'version', version)
            // verify required parameter 'trainingVersionDto' is not null or undefined
            assertParamExists('updateVersion', 'trainingVersionDto', trainingVersionDto)
            const localVarPath = `/training-versions/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainingVersionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get training versions by its id
         * @param {string} training 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionsPerTraining: async (training: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'training' is not null or undefined
            assertParamExists('versionsPerTraining', 'training', training)
            const localVarPath = `/trainings/{training}/versions`
                .replace(`{${"training"}}`, encodeURIComponent(String(training)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingsApi - functional programming interface
 * @export
 */
export const TrainingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add new Language variant for training
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLanguage(version: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLanguage(version, addLanguageVariant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.addLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change order of slides
         * @param {string} version 
         * @param {string} language 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeOrder(version: string, language: string, updateContainerOrder: UpdateContainerOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeOrder(version, language, updateContainerOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.changeOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create single copy of language version defined by id and language on path
         * @param {string} version 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyLanguage(version: string, language: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyLanguage(version, language, addLanguageVariant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.copyLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyTrainingVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyTrainingVersion(version, copyVersionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.copyTrainingVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyVersionToNewTraining(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyVersionToNewTraining(version, copyVersionHolderToNewVersionableRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.copyVersionToNewTraining']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyVersionToTrainings(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyVersionToTrainings(version, copyVersionHolderToVersionablesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.copyVersionToTrainings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create new training
         * @param {string} company 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(company: string, trainingDto: TrainingDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(company, trainingDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create slide for given version
         * @param {string} version 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSlide(version: string, slideDto: SlideDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSlide(version, slideDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.createSlide']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary create version of training
         * @param {string} training 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVersion(training: string, trainingVersionDto: TrainingVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVersion(training, trainingVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.createVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete specific slide
         * @param {string} slide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSlide(slide: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSlide(slide, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.deleteSlide']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete future version of training
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrainingVersion(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrainingVersion(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.deleteTrainingVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVariant(version: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVariant(version, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.deleteVariant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get training by its id
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(training: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(training, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.getById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get languages of active version for given training
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguagesForTraining(training: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionLanguageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguagesForTraining(training, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.getLanguagesForTraining']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get slides of given training version. Result can be limited by specifying language of slides
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlides(version: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlideDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlides(version, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.getSlides']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get specific training version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingVersion(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingVersion(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.getTrainingVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list all of the trainings for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {string} [skipId] 
         * @param {QueryTrainingsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {boolean} [ignoreWithoutQuestionnaireWithLanguage] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryTrainings(company: string, q?: string, skipId?: string, state?: QueryTrainingsStateEnum, nameOnly?: boolean, ignoreWithoutQuestionnaireWithLanguage?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryTrainings(company, q, skipId, state, nameOnly, ignoreWithoutQuestionnaireWithLanguage, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.queryTrainings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update training
         * @param {string} training 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(training: string, trainingDto: TrainingDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(training, trainingDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update single slide
         * @param {string} slide 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlide(slide: string, slideDto: SlideDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlide(slide, slideDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.updateSlide']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update version of training version
         * @param {string} version 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersion(version: string, trainingVersionDto: TrainingVersionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersion(version, trainingVersionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.updateVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary get training versions by its id
         * @param {string} training 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionsPerTraining(training: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTrainingVersionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionsPerTraining(training, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingsApi.versionsPerTraining']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrainingsApi - factory interface
 * @export
 */
export const TrainingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingsApiFp(configuration)
    return {
        /**
         * 
         * @summary add new Language variant for training
         * @param {string} version 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLanguage(version: string, addLanguageVariant: AddLanguageVariant, options?: any): AxiosPromise<void> {
            return localVarFp.addLanguage(version, addLanguageVariant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change order of slides
         * @param {string} version 
         * @param {string} language 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrder(version: string, language: string, updateContainerOrder: UpdateContainerOrder, options?: any): AxiosPromise<void> {
            return localVarFp.changeOrder(version, language, updateContainerOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create single copy of language version defined by id and language on path
         * @param {string} version 
         * @param {string} language 
         * @param {AddLanguageVariant} addLanguageVariant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyLanguage(version: string, language: string, addLanguageVariant: AddLanguageVariant, options?: any): AxiosPromise<void> {
            return localVarFp.copyLanguage(version, language, addLanguageVariant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionRequest} copyVersionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyTrainingVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.copyTrainingVersion(version, copyVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToNewTraining(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.copyVersionToNewTraining(version, copyVersionHolderToNewVersionableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create training version as copy of version defined in path
         * @param {string} version 
         * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyVersionToTrainings(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.copyVersionToTrainings(version, copyVersionHolderToVersionablesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new training
         * @param {string} company 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(company: string, trainingDto: TrainingDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.create(company, trainingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create slide for given version
         * @param {string} version 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlide(version: string, slideDto: SlideDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createSlide(version, slideDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create version of training
         * @param {string} training 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion(training: string, trainingVersionDto: TrainingVersionDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createVersion(training, trainingVersionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete specific slide
         * @param {string} slide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlide(slide: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSlide(slide, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete future version of training
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainingVersion(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrainingVersion(version, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete questionnaire variant of version which was never active
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariant(version: string, language: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVariant(version, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get training by its id
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(training: string, options?: any): AxiosPromise<TrainingDto> {
            return localVarFp.getById(training, options).then((request) => request(axios, basePath));
        },
        /**
         * get languages of active version for given training
         * @param {string} training 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesForTraining(training: string, options?: any): AxiosPromise<VersionLanguageResponse> {
            return localVarFp.getLanguagesForTraining(training, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get slides of given training version. Result can be limited by specifying language of slides
         * @param {string} version 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlides(version: string, language: string, options?: any): AxiosPromise<Array<SlideDto>> {
            return localVarFp.getSlides(version, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get specific training version
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingVersion(version: string, options?: any): AxiosPromise<TrainingVersionDto> {
            return localVarFp.getTrainingVersion(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all of the trainings for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {string} [skipId] 
         * @param {QueryTrainingsStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {boolean} [ignoreWithoutQuestionnaireWithLanguage] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryTrainings(company: string, q?: string, skipId?: string, state?: QueryTrainingsStateEnum, nameOnly?: boolean, ignoreWithoutQuestionnaireWithLanguage?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTrainingDto> {
            return localVarFp.queryTrainings(company, q, skipId, state, nameOnly, ignoreWithoutQuestionnaireWithLanguage, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update training
         * @param {string} training 
         * @param {TrainingDto} trainingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(training: string, trainingDto: TrainingDto, options?: any): AxiosPromise<void> {
            return localVarFp.update(training, trainingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update single slide
         * @param {string} slide 
         * @param {SlideDto} slideDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlide(slide: string, slideDto: SlideDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSlide(slide, slideDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update version of training version
         * @param {string} version 
         * @param {TrainingVersionDto} trainingVersionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(version: string, trainingVersionDto: TrainingVersionDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVersion(version, trainingVersionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get training versions by its id
         * @param {string} training 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionsPerTraining(training: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTrainingVersionDto> {
            return localVarFp.versionsPerTraining(training, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingsApi - object-oriented interface
 * @export
 * @class TrainingsApi
 * @extends {BaseAPI}
 */
export class TrainingsApi extends BaseAPI {
    /**
     * 
     * @summary add new Language variant for training
     * @param {string} version 
     * @param {AddLanguageVariant} addLanguageVariant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public addLanguage(version: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).addLanguage(version, addLanguageVariant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change order of slides
     * @param {string} version 
     * @param {string} language 
     * @param {UpdateContainerOrder} updateContainerOrder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public changeOrder(version: string, language: string, updateContainerOrder: UpdateContainerOrder, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).changeOrder(version, language, updateContainerOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create single copy of language version defined by id and language on path
     * @param {string} version 
     * @param {string} language 
     * @param {AddLanguageVariant} addLanguageVariant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public copyLanguage(version: string, language: string, addLanguageVariant: AddLanguageVariant, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).copyLanguage(version, language, addLanguageVariant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create training version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionRequest} copyVersionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public copyTrainingVersion(version: string, copyVersionRequest: CopyVersionRequest, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).copyTrainingVersion(version, copyVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create training version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionHolderToNewVersionableRequest} copyVersionHolderToNewVersionableRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public copyVersionToNewTraining(version: string, copyVersionHolderToNewVersionableRequest: CopyVersionHolderToNewVersionableRequest, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).copyVersionToNewTraining(version, copyVersionHolderToNewVersionableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create training version as copy of version defined in path
     * @param {string} version 
     * @param {CopyVersionHolderToVersionablesRequest} copyVersionHolderToVersionablesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public copyVersionToTrainings(version: string, copyVersionHolderToVersionablesRequest: CopyVersionHolderToVersionablesRequest, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).copyVersionToTrainings(version, copyVersionHolderToVersionablesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new training
     * @param {string} company 
     * @param {TrainingDto} trainingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public create(company: string, trainingDto: TrainingDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).create(company, trainingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create slide for given version
     * @param {string} version 
     * @param {SlideDto} slideDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public createSlide(version: string, slideDto: SlideDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).createSlide(version, slideDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create version of training
     * @param {string} training 
     * @param {TrainingVersionDto} trainingVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public createVersion(training: string, trainingVersionDto: TrainingVersionDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).createVersion(training, trainingVersionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete specific slide
     * @param {string} slide 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public deleteSlide(slide: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).deleteSlide(slide, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete future version of training
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public deleteTrainingVersion(version: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).deleteTrainingVersion(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete questionnaire variant of version which was never active
     * @param {string} version 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public deleteVariant(version: string, language: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).deleteVariant(version, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get training by its id
     * @param {string} training 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getById(training: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getById(training, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get languages of active version for given training
     * @param {string} training 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getLanguagesForTraining(training: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getLanguagesForTraining(training, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get slides of given training version. Result can be limited by specifying language of slides
     * @param {string} version 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getSlides(version: string, language: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getSlides(version, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get specific training version
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public getTrainingVersion(version: string, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).getTrainingVersion(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all of the trainings for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {string} [skipId] 
     * @param {QueryTrainingsStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {boolean} [ignoreWithoutQuestionnaireWithLanguage] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public queryTrainings(company: string, q?: string, skipId?: string, state?: QueryTrainingsStateEnum, nameOnly?: boolean, ignoreWithoutQuestionnaireWithLanguage?: boolean, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).queryTrainings(company, q, skipId, state, nameOnly, ignoreWithoutQuestionnaireWithLanguage, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update training
     * @param {string} training 
     * @param {TrainingDto} trainingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public update(training: string, trainingDto: TrainingDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).update(training, trainingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update single slide
     * @param {string} slide 
     * @param {SlideDto} slideDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public updateSlide(slide: string, slideDto: SlideDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).updateSlide(slide, slideDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update version of training version
     * @param {string} version 
     * @param {TrainingVersionDto} trainingVersionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public updateVersion(version: string, trainingVersionDto: TrainingVersionDto, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).updateVersion(version, trainingVersionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get training versions by its id
     * @param {string} training 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public versionsPerTraining(training: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TrainingsApiFp(this.configuration).versionsPerTraining(training, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryTrainingsStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryTrainingsStateEnum = typeof QueryTrainingsStateEnum[keyof typeof QueryTrainingsStateEnum];


/**
 * VisitationsApi - axios parameter creator
 * @export
 */
export const VisitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endVisitation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('endVisitation', 'id', id)
            const localVarPath = `/api/v1/visitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserVisitations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/visitations/previous-visitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide visitation for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get visitation
         * @param {string} visitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitation: async (visitationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitationId' is not null or undefined
            assertParamExists('getVisitation', 'visitationId', visitationId)
            const localVarPath = `/api/v1/visitations/{visitationId}`
                .replace(`{${"visitationId"}}`, encodeURIComponent(String(visitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get trainings, that must be completed for a visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Get incomplete trainings
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incompleteTrainings: async (company: string, visitationPinDto: VisitationPinDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('incompleteTrainings', 'company', company)
            // verify required parameter 'visitationPinDto' is not null or undefined
            assertParamExists('incompleteTrainings', 'visitationPinDto', visitationPinDto)
            const localVarPath = `/api/v1/companies/{company}/visitations/trainings-incomplete`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitationPinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all visitations. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all visitations for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [nameOnly] 
         * @param {string} [supplier] 
         * @param {string} [establishment] 
         * @param {Array<string>} [workplace] 
         * @param {string} [employee] 
         * @param {string} [entryTimeStart] 
         * @param {string} [entryTimeEnd] 
         * @param {string} [exitTimeStart] 
         * @param {string} [exitTimeEnd] 
         * @param {boolean} [hasExited] 
         * @param {Array<QueryVisitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryVisitations: async (company: string, q?: string, nameOnly?: boolean, supplier?: string, establishment?: string, workplace?: Array<string>, employee?: string, entryTimeStart?: string, entryTimeEnd?: string, exitTimeStart?: string, exitTimeEnd?: string, hasExited?: boolean, invitationTypes?: Array<QueryVisitationsInvitationTypesEnum>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryVisitations', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/visitations`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (supplier !== undefined) {
                localVarQueryParameter['supplier'] = supplier;
            }

            if (establishment !== undefined) {
                localVarQueryParameter['establishment'] = establishment;
            }

            if (workplace) {
                localVarQueryParameter['workplace'] = workplace;
            }

            if (employee !== undefined) {
                localVarQueryParameter['employee'] = employee;
            }

            if (entryTimeStart !== undefined) {
                localVarQueryParameter['entryTimeStart'] = (entryTimeStart as any instanceof Date) ?
                    (entryTimeStart as any).toISOString() :
                    entryTimeStart;
            }

            if (entryTimeEnd !== undefined) {
                localVarQueryParameter['entryTimeEnd'] = (entryTimeEnd as any instanceof Date) ?
                    (entryTimeEnd as any).toISOString() :
                    entryTimeEnd;
            }

            if (exitTimeStart !== undefined) {
                localVarQueryParameter['exitTimeStart'] = (exitTimeStart as any instanceof Date) ?
                    (exitTimeStart as any).toISOString() :
                    exitTimeStart;
            }

            if (exitTimeEnd !== undefined) {
                localVarQueryParameter['exitTimeEnd'] = (exitTimeEnd as any instanceof Date) ?
                    (exitTimeEnd as any).toISOString() :
                    exitTimeEnd;
            }

            if (hasExited !== undefined) {
                localVarQueryParameter['hasExited'] = hasExited;
            }

            if (invitationTypes) {
                localVarQueryParameter['invitationTypes'] = invitationTypes;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to read pin and return list of invitations for this person. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Read pin of a person trying to enter/exit to/from company
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPin: async (company: string, visitationPinDto: VisitationPinDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('readPin', 'company', company)
            // verify required parameter 'visitationPinDto' is not null or undefined
            assertParamExists('readPin', 'visitationPinDto', visitationPinDto)
            const localVarPath = `/api/v1/companies/{company}/visitations/read-pin`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitationPinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to evidate visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Evidate visitation of invited person with pin and invitation
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visit: async (company: string, visitationPinDto: VisitationPinDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('visit', 'company', company)
            // verify required parameter 'visitationPinDto' is not null or undefined
            assertParamExists('visit', 'visitationPinDto', visitationPinDto)
            const localVarPath = `/api/v1/companies/{company}/visitations/visit`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitationPinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitationsApi - functional programming interface
 * @export
 */
export const VisitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endVisitation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endVisitation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.endVisitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserVisitations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisitationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserVisitations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.getUserVisitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide visitation for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get visitation
         * @param {string} visitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitation(visitationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitationDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitation(visitationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.getVisitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get trainings, that must be completed for a visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Get incomplete trainings
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incompleteTrainings(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourniquetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incompleteTrainings(company, visitationPinDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.incompleteTrainings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all visitations. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all visitations for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [nameOnly] 
         * @param {string} [supplier] 
         * @param {string} [establishment] 
         * @param {Array<string>} [workplace] 
         * @param {string} [employee] 
         * @param {string} [entryTimeStart] 
         * @param {string} [entryTimeEnd] 
         * @param {string} [exitTimeStart] 
         * @param {string} [exitTimeEnd] 
         * @param {boolean} [hasExited] 
         * @param {Array<QueryVisitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryVisitations(company: string, q?: string, nameOnly?: boolean, supplier?: string, establishment?: string, workplace?: Array<string>, employee?: string, entryTimeStart?: string, entryTimeEnd?: string, exitTimeStart?: string, exitTimeEnd?: string, hasExited?: boolean, invitationTypes?: Array<QueryVisitationsInvitationTypesEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVisitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryVisitations(company, q, nameOnly, supplier, establishment, workplace, employee, entryTimeStart, entryTimeEnd, exitTimeStart, exitTimeEnd, hasExited, invitationTypes, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.queryVisitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to read pin and return list of invitations for this person. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Read pin of a person trying to enter/exit to/from company
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPin(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadPinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPin(company, visitationPinDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.readPin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to evidate visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Evidate visitation of invited person with pin and invitation
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visit(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visit(company, visitationPinDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VisitationsApi.visit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VisitationsApi - factory interface
 * @export
 */
export const VisitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endVisitation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.endVisitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserVisitations(options?: any): AxiosPromise<Array<VisitationDto>> {
            return localVarFp.getUserVisitations(options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide visitation for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get visitation
         * @param {string} visitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitation(visitationId: string, options?: any): AxiosPromise<VisitationDetailDto> {
            return localVarFp.getVisitation(visitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get trainings, that must be completed for a visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Get incomplete trainings
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incompleteTrainings(company: string, visitationPinDto: VisitationPinDto, options?: any): AxiosPromise<TourniquetResponse> {
            return localVarFp.incompleteTrainings(company, visitationPinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all visitations. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
         * @summary Get all visitations for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {boolean} [nameOnly] 
         * @param {string} [supplier] 
         * @param {string} [establishment] 
         * @param {Array<string>} [workplace] 
         * @param {string} [employee] 
         * @param {string} [entryTimeStart] 
         * @param {string} [entryTimeEnd] 
         * @param {string} [exitTimeStart] 
         * @param {string} [exitTimeEnd] 
         * @param {boolean} [hasExited] 
         * @param {Array<QueryVisitationsInvitationTypesEnum>} [invitationTypes] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryVisitations(company: string, q?: string, nameOnly?: boolean, supplier?: string, establishment?: string, workplace?: Array<string>, employee?: string, entryTimeStart?: string, entryTimeEnd?: string, exitTimeStart?: string, exitTimeEnd?: string, hasExited?: boolean, invitationTypes?: Array<QueryVisitationsInvitationTypesEnum>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageVisitationDto> {
            return localVarFp.queryVisitations(company, q, nameOnly, supplier, establishment, workplace, employee, entryTimeStart, entryTimeEnd, exitTimeStart, exitTimeEnd, hasExited, invitationTypes, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to read pin and return list of invitations for this person. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Read pin of a person trying to enter/exit to/from company
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPin(company: string, visitationPinDto: VisitationPinDto, options?: any): AxiosPromise<ReadPinResponse> {
            return localVarFp.readPin(company, visitationPinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to evidate visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
         * @summary Evidate visitation of invited person with pin and invitation
         * @param {string} company 
         * @param {VisitationPinDto} visitationPinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visit(company: string, visitationPinDto: VisitationPinDto, options?: any): AxiosPromise<VisitationDto> {
            return localVarFp.visit(company, visitationPinDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitationsApi - object-oriented interface
 * @export
 * @class VisitationsApi
 * @extends {BaseAPI}
 */
export class VisitationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public endVisitation(id: string, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).endVisitation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public getUserVisitations(options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).getUserVisitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide visitation for given id. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Get visitation
     * @param {string} visitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public getVisitation(visitationId: string, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).getVisitation(visitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get trainings, that must be completed for a visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
     * @summary Get incomplete trainings
     * @param {string} company 
     * @param {VisitationPinDto} visitationPinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public incompleteTrainings(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).incompleteTrainings(company, visitationPinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all visitations. Available to SCOPE_MANAGER_OSAH, MANAGER_WORKPLACE and RECEPTIONIST roles.
     * @summary Get all visitations for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {boolean} [nameOnly] 
     * @param {string} [supplier] 
     * @param {string} [establishment] 
     * @param {Array<string>} [workplace] 
     * @param {string} [employee] 
     * @param {string} [entryTimeStart] 
     * @param {string} [entryTimeEnd] 
     * @param {string} [exitTimeStart] 
     * @param {string} [exitTimeEnd] 
     * @param {boolean} [hasExited] 
     * @param {Array<QueryVisitationsInvitationTypesEnum>} [invitationTypes] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public queryVisitations(company: string, q?: string, nameOnly?: boolean, supplier?: string, establishment?: string, workplace?: Array<string>, employee?: string, entryTimeStart?: string, entryTimeEnd?: string, exitTimeStart?: string, exitTimeEnd?: string, hasExited?: boolean, invitationTypes?: Array<QueryVisitationsInvitationTypesEnum>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).queryVisitations(company, q, nameOnly, supplier, establishment, workplace, employee, entryTimeStart, entryTimeEnd, exitTimeStart, exitTimeEnd, hasExited, invitationTypes, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to read pin and return list of invitations for this person. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
     * @summary Read pin of a person trying to enter/exit to/from company
     * @param {string} company 
     * @param {VisitationPinDto} visitationPinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public readPin(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).readPin(company, visitationPinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to evidate visitation. Available to SCOPE_TOURNIQUET, SCOPE_TOURNIQUET_TRAINING roles.
     * @summary Evidate visitation of invited person with pin and invitation
     * @param {string} company 
     * @param {VisitationPinDto} visitationPinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitationsApi
     */
    public visit(company: string, visitationPinDto: VisitationPinDto, options?: RawAxiosRequestConfig) {
        return VisitationsApiFp(this.configuration).visit(company, visitationPinDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryVisitationsInvitationTypesEnum = {
    Regular: 'REGULAR',
    OneTime: 'ONE_TIME',
    OneTimeEntry: 'ONE_TIME_ENTRY'
} as const;
export type QueryVisitationsInvitationTypesEnum = typeof QueryVisitationsInvitationTypesEnum[keyof typeof QueryVisitationsInvitationTypesEnum];


/**
 * WorkplaceApi - axios parameter creator
 * @export
 */
export const WorkplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * tbd
         * @summary Add training to workplace
         * @param {string} workplace 
         * @param {ReferenceDto} referenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainingToWorkPlace: async (workplace: string, referenceDto: ReferenceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workplace' is not null or undefined
            assertParamExists('addTrainingToWorkPlace', 'workplace', workplace)
            // verify required parameter 'referenceDto' is not null or undefined
            assertParamExists('addTrainingToWorkPlace', 'referenceDto', referenceDto)
            const localVarPath = `/api/v1/workplaces/{workplace}/trainings`
                .replace(`{${"workplace"}}`, encodeURIComponent(String(workplace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change order of trainings
         * @param {string} id 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTrainingOrder: async (id: string, updateContainerOrder: UpdateContainerOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeTrainingOrder', 'id', id)
            // verify required parameter 'updateContainerOrder' is not null or undefined
            assertParamExists('changeTrainingOrder', 'updateContainerOrder', updateContainerOrder)
            const localVarPath = `/api/v1/workplaces/{id}/trainings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContainerOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to create workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Create workplace
         * @param {string} company 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkplace: async (company: string, workplaceDto: WorkplaceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('createWorkplace', 'company', company)
            // verify required parameter 'workplaceDto' is not null or undefined
            assertParamExists('createWorkplace', 'workplaceDto', workplaceDto)
            const localVarPath = `/api/v1/companies/{company}/workplaces`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workplaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list all of the trainings for given workplace
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingsByWorkplace: async (id: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrainingsByWorkplace', 'id', id)
            const localVarPath = `/api/v1/workplaces/{id}/trainings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide workplace for given company. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get workplace for given company
         * @param {string} workplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkplace: async (workplace: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workplace' is not null or undefined
            assertParamExists('getWorkplace', 'workplace', workplace)
            const localVarPath = `/api/v1/workplaces/{workplace}`
                .replace(`{${"workplace"}}`, encodeURIComponent(String(workplace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to provide list of all workplaces. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get all workplaces for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryWorkplaceStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryWorkplace: async (company: string, q?: string, state?: QueryWorkplaceStateEnum, nameOnly?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('queryWorkplace', 'company', company)
            const localVarPath = `/api/v1/companies/{company}/workplaces`
                .replace(`{${"company"}}`, encodeURIComponent(String(company)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (nameOnly !== undefined) {
                localVarQueryParameter['nameOnly'] = nameOnly;
            }

            if (establishments) {
                localVarQueryParameter['establishments'] = establishments;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary remove trainings for given workplace
         * @param {string} workplace 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTrainingFromWorkplace: async (workplace: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workplace' is not null or undefined
            assertParamExists('removeTrainingFromWorkplace', 'workplace', workplace)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTrainingFromWorkplace', 'id', id)
            const localVarPath = `/api/v1/workplaces/{workplace}/trainings/{id}`
                .replace(`{${"workplace"}}`, encodeURIComponent(String(workplace)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purpose of this method is to update workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Update workplace for given company
         * @param {string} workplace 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkplace: async (workplace: string, workplaceDto: WorkplaceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workplace' is not null or undefined
            assertParamExists('updateWorkplace', 'workplace', workplace)
            // verify required parameter 'workplaceDto' is not null or undefined
            assertParamExists('updateWorkplace', 'workplaceDto', workplaceDto)
            const localVarPath = `/api/v1/workplaces/{workplace}`
                .replace(`{${"workplace"}}`, encodeURIComponent(String(workplace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication qcs_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workplaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkplaceApi - functional programming interface
 * @export
 */
export const WorkplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * tbd
         * @summary Add training to workplace
         * @param {string} workplace 
         * @param {ReferenceDto} referenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTrainingToWorkPlace(workplace: string, referenceDto: ReferenceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTrainingToWorkPlace(workplace, referenceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.addTrainingToWorkPlace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Change order of trainings
         * @param {string} id 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeTrainingOrder(id: string, updateContainerOrder: UpdateContainerOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeTrainingOrder(id, updateContainerOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.changeTrainingOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to create workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Create workplace
         * @param {string} company 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkplace(company: string, workplaceDto: WorkplaceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkplace(company, workplaceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.createWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary list all of the trainings for given workplace
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainingsByWorkplace(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrainingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingsByWorkplace(id, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.getTrainingsByWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide workplace for given company. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get workplace for given company
         * @param {string} workplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkplace(workplace: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkplaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkplace(workplace, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.getWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to provide list of all workplaces. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get all workplaces for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryWorkplaceStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryWorkplace(company: string, q?: string, state?: QueryWorkplaceStateEnum, nameOnly?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWorkplaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryWorkplace(company, q, state, nameOnly, establishments, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.queryWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary remove trainings for given workplace
         * @param {string} workplace 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTrainingFromWorkplace(workplace: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTrainingFromWorkplace(workplace, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.removeTrainingFromWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The purpose of this method is to update workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Update workplace for given company
         * @param {string} workplace 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkplace(workplace: string, workplaceDto: WorkplaceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkplace(workplace, workplaceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkplaceApi.updateWorkplace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkplaceApi - factory interface
 * @export
 */
export const WorkplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkplaceApiFp(configuration)
    return {
        /**
         * tbd
         * @summary Add training to workplace
         * @param {string} workplace 
         * @param {ReferenceDto} referenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrainingToWorkPlace(workplace: string, referenceDto: ReferenceDto, options?: any): AxiosPromise<void> {
            return localVarFp.addTrainingToWorkPlace(workplace, referenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change order of trainings
         * @param {string} id 
         * @param {UpdateContainerOrder} updateContainerOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTrainingOrder(id: string, updateContainerOrder: UpdateContainerOrder, options?: any): AxiosPromise<void> {
            return localVarFp.changeTrainingOrder(id, updateContainerOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to create workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Create workplace
         * @param {string} company 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkplace(company: string, workplaceDto: WorkplaceDto, options?: any): AxiosPromise<ReferenceDto> {
            return localVarFp.createWorkplace(company, workplaceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list all of the trainings for given workplace
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainingsByWorkplace(id: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<TrainingDto>> {
            return localVarFp.getTrainingsByWorkplace(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide workplace for given company. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get workplace for given company
         * @param {string} workplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkplace(workplace: string, options?: any): AxiosPromise<WorkplaceDto> {
            return localVarFp.getWorkplace(workplace, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to provide list of all workplaces. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Get all workplaces for given company
         * @param {string} company 
         * @param {string} [q] 
         * @param {QueryWorkplaceStateEnum} [state] 
         * @param {boolean} [nameOnly] 
         * @param {Array<string>} [establishments] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryWorkplace(company: string, q?: string, state?: QueryWorkplaceStateEnum, nameOnly?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageWorkplaceDto> {
            return localVarFp.queryWorkplace(company, q, state, nameOnly, establishments, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary remove trainings for given workplace
         * @param {string} workplace 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTrainingFromWorkplace(workplace: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeTrainingFromWorkplace(workplace, id, options).then((request) => request(axios, basePath));
        },
        /**
         * The purpose of this method is to update workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
         * @summary Update workplace for given company
         * @param {string} workplace 
         * @param {WorkplaceDto} workplaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkplace(workplace: string, workplaceDto: WorkplaceDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkplace(workplace, workplaceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkplaceApi - object-oriented interface
 * @export
 * @class WorkplaceApi
 * @extends {BaseAPI}
 */
export class WorkplaceApi extends BaseAPI {
    /**
     * tbd
     * @summary Add training to workplace
     * @param {string} workplace 
     * @param {ReferenceDto} referenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public addTrainingToWorkPlace(workplace: string, referenceDto: ReferenceDto, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).addTrainingToWorkPlace(workplace, referenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change order of trainings
     * @param {string} id 
     * @param {UpdateContainerOrder} updateContainerOrder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public changeTrainingOrder(id: string, updateContainerOrder: UpdateContainerOrder, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).changeTrainingOrder(id, updateContainerOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to create workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
     * @summary Create workplace
     * @param {string} company 
     * @param {WorkplaceDto} workplaceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public createWorkplace(company: string, workplaceDto: WorkplaceDto, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).createWorkplace(company, workplaceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list all of the trainings for given workplace
     * @param {string} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public getTrainingsByWorkplace(id: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).getTrainingsByWorkplace(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide workplace for given company. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
     * @summary Get workplace for given company
     * @param {string} workplace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public getWorkplace(workplace: string, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).getWorkplace(workplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to provide list of all workplaces. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
     * @summary Get all workplaces for given company
     * @param {string} company 
     * @param {string} [q] 
     * @param {QueryWorkplaceStateEnum} [state] 
     * @param {boolean} [nameOnly] 
     * @param {Array<string>} [establishments] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public queryWorkplace(company: string, q?: string, state?: QueryWorkplaceStateEnum, nameOnly?: boolean, establishments?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).queryWorkplace(company, q, state, nameOnly, establishments, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary remove trainings for given workplace
     * @param {string} workplace 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public removeTrainingFromWorkplace(workplace: string, id: string, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).removeTrainingFromWorkplace(workplace, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purpose of this method is to update workplace. Available to SCOPE_MANAGER_OSAH and MANAGER_WORKPLACE roles.
     * @summary Update workplace for given company
     * @param {string} workplace 
     * @param {WorkplaceDto} workplaceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkplaceApi
     */
    public updateWorkplace(workplace: string, workplaceDto: WorkplaceDto, options?: RawAxiosRequestConfig) {
        return WorkplaceApiFp(this.configuration).updateWorkplace(workplace, workplaceDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const QueryWorkplaceStateEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type QueryWorkplaceStateEnum = typeof QueryWorkplaceStateEnum[keyof typeof QueryWorkplaceStateEnum];


