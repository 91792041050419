import { FC, useMemo } from 'react';
import { createTheme, Slide, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GlobalStyles } from './GlobalStyles';
import { getDateLang, getMuiLang } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';
import { QcsProtocol } from './QcsProtocol';
import { useSelector } from 'react-redux';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { SnackbarProvider } from 'notistack';
import {
  SNACKBAR_AUTO_HIDE_DURATION,
  SNACKBAR_MAX_SNACK,
} from '../../utils/constants';
import '../../utils/chart';

interface Props {
  children: React.ReactNode;
}

export const Providers: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const companyCustomization = useSelector(selectCompanyCustomization);

  const themeMUI = useMemo(() => {
    let theme;

    const colorRegex = /^#[0-9A-F]{6}$/i;

    if (
      companyCustomization?.primaryColor &&
      companyCustomization?.secondaryColor &&
      colorRegex.test(companyCustomization.primaryColor) &&
      colorRegex.test(companyCustomization.secondaryColor)
    ) {
      theme = createTheme({
        palette: {
          primary: {
            main: companyCustomization.primaryColor,
          },
          secondary: {
            main: companyCustomization.secondaryColor,
          },
          background: {
            default: '#f5f6fa',
          },
        },
      });
    } else {
      theme = createTheme({
        palette: {
          primary: {
            main: '#ff7c43',
            contrastText: '#fff',
          },
          secondary: {
            main: '#8a9aae',
            contrastText: '#fff',
          },
          background: {
            default: '#f5f6fa',
          },
        },
      });
    }

    const themeMUI = createTheme(
      theme,
      {
        components: {
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                color: theme.palette.secondary.main,
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              head: {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.dark,
                '& .MuiTableSortLabel-root': {
                  color: `${theme.palette.common.white} !important`,
                },
                '& .MuiTableSortLabel-icon': {
                  color: `${theme.palette.common.white} !important`,
                },
                '& .MuiCheckbox-root': {
                  color: theme.palette.common.white,
                },
                '& .Mui-checked': {
                  color: theme.palette.primary.light,
                },
                '& .MuiCheckbox-indeterminate': {
                  color: theme.palette.primary.light,
                },
              },
            },
          },
          MuiToggleButton: {
            styleOverrides: {
              root: {
                padding: '7px',
                paddingLeft: '0.8rem',
                textAlign: 'left',
                lineHeight: '1.2rem',
                backgroundColor: theme.palette.grey[200],
                '&:hover': {
                  backgroundColor: theme.palette.grey[400],
                },
                '&.Mui-selected': {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                },
              },
            },
          },
        },
      },
      getMuiLang(i18n.language)
    );

    return themeMUI;
  }, [
    companyCustomization?.primaryColor,
    companyCustomization?.secondaryColor,
    i18n.language,
  ]);

  return (
    <ThemeProvider theme={themeMUI}>
      <GlobalStyles />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getDateLang(i18n)}
      >
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          TransitionComponent={Slide}
          maxSnack={SNACKBAR_MAX_SNACK}
          autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
        >
          <QcsProtocol />
          {children}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
