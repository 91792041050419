import { Form } from 'formik';
import { FormikDebounce } from '../../../common/form/FormikDebounce';
import { FC } from 'react';
import { Input } from '../../../common/form/Input';
import { FormContainer } from '../../../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../../../utils/validations';
import { SubmitButton } from '../../../common/form/SubmitButton';
import { SupplierEmployeeAutocomplete } from '../../../common/form/SupplierEmployeeAutocomplete';
import { SaveError } from '../../../common/SaveError';
import { QcsTypography } from '../../../common/basic/QcsTypography';
import { SupplierAutocomplete } from '../../../common/form/SupplierAutocomplete';
import { invitationsApi } from '../../../../utils/api';
import {
  ConfirmInvitationRequest,
  InvitationDtoStateEnum,
  ReferenceDto,
  SupplierDtoStateEnum,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { useAppSelector } from '../../../../store';
import { setErrorStateSnacks } from '../../../../utils/error';
import { selectIdentity } from '../../../../store/entities/identity';
import { getFirstLastNameObj } from '../../../../utils/format';
import { hasRole } from '../../../../utils/roles';
import { useInvitation } from './invitationFunctions';

interface InvitationDetailForm {
  ico: string;
  supplier?: ReferenceDto;
  vat: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  responsiblePerson?: ReferenceDto;
}

export const DetailForm: FC = () => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    saveError,
    setSaveError,
  } = useInvitation();

  const identity = useAppSelector(selectIdentity);

  const handleSubmit = async (data: InvitationDetailForm) => {
    setSaveError('');
    lockInvitation();
    try {
      const data2: ConfirmInvitationRequest = {
        responsiblePerson: data.responsiblePerson!,
        supplier: {
          id: data.supplier?.id,
          name: data.supplier!.name ?? '',
          ico: data.ico,
          vat: data.vat,
          state: SupplierDtoStateEnum.Active,
          address: {
            street: data.street,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,
          },
          note: '',
        },
      };

      await invitationsApi.confirmInvitationDetails(invitation.id!, data2);
      await reloadInvitation();

      enqueueSuccessSnackbar(t('common.editSuccess'));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
    unlockInvitation();
  };

  const disableEdit =
    !hasRole(identity.roles, [
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.ManagerOsah,
    ]) ||
    //Supplier details already checked - NOT IN.
    (invitation.state !== InvitationDtoStateEnum.Sent &&
      invitation.state !== InvitationDtoStateEnum.Viewed);

  return (
    <>
      <FormikDebounce<InvitationDetailForm>
        initialValues={{
          ico: invitation.supplier?.ico ?? '',
          supplier: invitation.supplier
            ? {
                id: invitation.supplier!.id!,
                name: invitation.supplier!.name,
              }
            : undefined,
          vat: invitation.supplier?.vat ?? '',
          street: invitation.supplier?.address?.street ?? '',
          city: invitation.supplier?.address?.city ?? '',
          postalCode: invitation.supplier?.address?.postalCode ?? '',
          country: invitation.supplier?.address?.country ?? '',
          responsiblePerson: invitation.responsiblePerson
            ? {
                id: invitation.responsiblePerson!.id!,
                name: getFirstLastNameObj(invitation.responsiblePerson),
              }
            : undefined,
        }}
        validationSchema={Yup.object().shape({
          street: validations.stringRequired(t),
          city: validations.stringRequired(t),
          postalCode: validations.postalCodeRequired(t),
          country: validations.stringRequired(t),
          responsiblePerson: validations.objectRequired(t),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <QcsTypography variant="h4">
              {t('invitation.supplier')}
            </QcsTypography>
            <Input
              name="ico"
              label={t('invitation.ico')}
              maxLength={14}
              required
              disabled
            />
            <SupplierAutocomplete
              name="supplier"
              label={t('invitation.supplier')}
              required
              disabled
            />
            <Input
              name="vat"
              label={t('invitation.vat')}
              maxLength={14}
              disabled
            />

            <QcsTypography variant="h4">{t('address.title')}</QcsTypography>
            <Input
              name="street"
              label={t('address.street')}
              maxLength={100}
              required
              disabled={disableEdit}
            />
            <Input
              name="city"
              label={t('address.city')}
              maxLength={100}
              required
              disabled={disableEdit}
            />
            <Input
              name="postalCode"
              label={t('address.postalCode')}
              maxLength={128}
              required
              disabled={disableEdit}
            />
            <Input
              name="country"
              label={t('address.country')}
              maxLength={100}
              required
              disabled={disableEdit}
            />

            <QcsTypography variant="h4">
              {t('invitation.responsiblePerson')}
            </QcsTypography>
            <SupplierEmployeeAutocomplete
              name="responsiblePerson"
              label={t('invitation.responsiblePerson')}
              required
              disabled={disableEdit}
              supplierId={invitation.supplier?.id}
              role={SupplierEmployeeDtoRoleEnum.ExternalManager}
              state={[
                SupplierEmployeeDtoStateEnum.Active,
                SupplierEmployeeDtoStateEnum.Invited,
              ]}
            />
            <SubmitButton
              text={t('invitation.detailButtonSave')}
              disabled={disableEdit}
            />
          </FormContainer>
        </Form>
      </FormikDebounce>

      <SaveError error={saveError} />
    </>
  );
};
