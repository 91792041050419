import { QuestionDto } from '@qcs/safety-client';
import { FieldArray, Form, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { SubmitButton } from '../common/form/SubmitButton';
import { QuestionItem } from './QuestionItem';
import { FaIcon } from '../common/FaIcon';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { FormContainer } from '../common/form/FormContainer';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTheme } from '@mui/material';
import { useAppSelector } from '../../store';
import { selectQuestionnaireVersion } from '../../store/entities/questionnaireVersion';

export interface QuestionsForm {
  questions: QuestionDto[];
}

interface DraggableContainerProps {
  isDragging: boolean;
  selected?: boolean;
}

const QuestionsContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const DraggableContainer = styled.div<DraggableContainerProps>(
  ({ theme, isDragging, selected }) => ({
    userSelect: 'none',
    marginBottom: '1rem',
    backgroundColor: selected
      ? isDragging
        ? theme.palette.primary.dark
        : 'none'
      : isDragging
      ? theme.palette.secondary.dark
      : 'none',
    color: selected ? theme.palette.primary.contrastText : 'none',
    borderRadius: isDragging ? '5px' : '0px',
    fontFamily: theme.typography.fontFamily,
  })
);

interface Props {
  onRemoveOldFile: (document: string) => void;
}

export const FormContent: FC<Props> = ({ onRemoveOldFile }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { values, setValues, validateForm, submitForm } =
    useFormikContext<QuestionsForm>();

  const questionnaireVersion = useAppSelector(selectQuestionnaireVersion);
  const editable = questionnaireVersion.editable;

  const reorder = (
    list: QuestionDto[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setValues({ questions: result });
    return result;
  };

  const handleDragEnd = async (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    reorder(
      values.questions,
      dropResult.source.index,
      dropResult.destination.index
    );
  };

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? theme.palette.secondary.light : 'none',
  });

  const getItemStyle = (
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => ({
    ...draggableStyle,
  });

  const handleAddItem = async (push: any) => {
    if (values.questions.length > 0) {
      const errors = await validateForm();
      if (!Object.keys(errors).length) await submitForm();
    }
    push({
      content: '',
      answers: [{ content: '', correct: true }],
    });
  };

  return (
    <Form>
      <FormContainer>
        <FieldArray name="questions">
          {({ remove, push }) => (
            <QuestionsContainer>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {values.questions.map((question, i) => (
                        <Draggable
                          key={`${question}-${i}`}
                          draggableId={i.toString()}
                          index={i}
                          isDragDisabled={!editable}
                        >
                          {(provided, snapshot) => (
                            <DraggableContainer
                              isDragging={snapshot.isDragging}
                              ref={provided.innerRef}
                              style={getItemStyle(
                                provided.draggableProps.style
                              )}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <QuestionItem
                                editable={editable}
                                index={i}
                                remove={remove}
                                onRemoveOldFile={onRemoveOldFile}
                              />
                            </DraggableContainer>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <QcsButton
                sx={{ width: 'fit-content' }}
                variant="outlined"
                disabled={!editable}
                startIcon={<FaIcon icon={faAdd} />}
                onClick={() => {
                  handleAddItem(push);
                }}
              >
                {t('questionnaire.questions.addQuestion')}
              </QcsButton>
            </QuestionsContainer>
          )}
        </FieldArray>
        <SubmitButton disabled={!editable} />
      </FormContainer>
    </Form>
  );
};
