import { SafetyEquipmentDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  safetyEquipmentListActions,
  getSafetyEquipmentList,
  selectSafetyEquipmentList,
} from '../../store/entities/safetyEquipmentList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { SafetyEquipmentFilter } from './SafetyEquipmentFilter';
import { getLangNameObj } from '../../utils/format';

export const SafetyEquipment: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const safetyEquipmentList = useAppSelector(selectSafetyEquipmentList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getSafetyEquipmentList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: SafetyEquipmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('safetyEquipment.types.' + item.type)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: SafetyEquipmentDto) => {
    navigate('/safety-equipment/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/safety-equipment/new');
  };

  return (
    <>
      <SectionHeader
        title="safetyEquipment.title"
        addText="safetyEquipment.add"
        handleAddClick={handleAddClick}
      />
      <Grid<SafetyEquipmentDto>
        headers={[
          { captionStr: 'safetyEquipment.name' },
          {
            captionStr: 'safetyEquipment.type',
            hideOnMobile: true,
          },
          {
            captionStr: 'safetyEquipment.state',
            hideOnMobile: true,
          },
        ]}
        data={safetyEquipmentList}
        gridActions={safetyEquipmentListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
        filter={<SafetyEquipmentFilter query={safetyEquipmentList.query} />}
      />
    </>
  );
};
