import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { SnackMessageType } from '../models/common';

export const useAppSnackbar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccessSnackbar = (message?: SnackMessageType) =>
    enqueueSnackbar(message ?? t('common.saveSuccess'), {
      variant: 'success',
    });

  const enqueueErrorSnackbar = (message?: SnackMessageType) =>
    enqueueSnackbar(message ?? t('common.saveError'), {
      variant: 'error',
    });

  return { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueSnackbar };
};
