import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  children: ReactNode;
}

export const QcsBox = forwardRef<HTMLElement, Props>(
  ({ className, sx, children }, ref) => {
    return (
      <Box className={className} sx={sx} ref={ref}>
        {children}
      </Box>
    );
  }
);
