import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  visitationListActions,
  VisitationListFilter,
} from '../../store/entities/visitationList';
import { GridFilterDateTimeFromTo } from '../common/grid/GridFilterDateTimeFromTo';
import { VisitationFilterSupplier } from './VisitationFilterSupplier';
import { GridFilterWorkplace } from '../common/grid/GridFilterWorkplace';
import { GridFilterEmployee } from '../common/grid/GridFilterEmployee';
import { GridFilterEstablishment } from '../common/grid/GridFilterEstablishment';
import { isExternal } from '../../utils/roles';
import { ReferenceDto } from '@qcs/safety-client';
import { useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { VisitationFilterExitTime } from './VisitationFilterExitTime';

interface EmployeeWrapperProps {
  value?: ReferenceDto;
  onChange: (value?: ReferenceDto) => void;
}

const EmployeeWrapper: FC<EmployeeWrapperProps> = ({ value, onChange }) => {
  return (
    <GridFilterEmployee forInvite={true} value={value} onChange={onChange} />
  );
};

interface Props {
  query: VisitationListFilter;
}

export const VisitationFilter: FC<Props> = ({ query }) => {
  const identity = useAppSelector(selectIdentity);

  return (
    <>
      {!isExternal(identity) && (
        <>
          <VisitationFilterSupplier query={query} />
          <GridFilterItem
            query={query}
            gridActions={visitationListActions}
            name="establishment"
            title="visitation.establishment"
            disableAutoClose={true}
            Component={GridFilterEstablishment}
          />
          <GridFilterItem
            query={query}
            gridActions={visitationListActions}
            name="workplace"
            title="visitation.workplace"
            disableAutoClose={true}
            Component={GridFilterWorkplace}
          />
          <GridFilterItem
            query={query}
            gridActions={visitationListActions}
            name="invitedBy"
            title="visitation.invitedBy"
            disableAutoClose={true}
            Component={EmployeeWrapper}
          />
        </>
      )}
      <GridFilterItem
        query={query}
        gridActions={visitationListActions}
        name="entryTime"
        title="visitation.entryTime"
        disableAutoClose={true}
        Component={GridFilterDateTimeFromTo}
      />
      <VisitationFilterExitTime query={query} />
    </>
  );
};
