import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { RiskVersionDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { riskApi } from '../../utils/api';

type State = BaseType<RiskVersionDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    note: '',
    validFrom: '',
    validTo: '',
    document: '',
    documentName: '',
    editable: true,
    first: false,
  },
};

export const riskVersionSlice = baseSlice({
  name: 'riskVersion',
  initialState,
});

export const selectRiskVersionState = (state: RootState) =>
  state.riskVersion.state;
export const selectRiskVersion = (state: RootState) => state.riskVersion.data;
export const riskVersionActions = riskVersionSlice.actions;

export const getRiskVersion =
  (riskId: string, riskVersionId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return riskApi.getRiskVersion(riskId, riskVersionId);
    };

    getItem({
      dispatch,
      actions: riskVersionActions,
      getData: handleGetData,
    });
  };
