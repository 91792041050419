import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { useEffect, useRef } from 'react';
import { QUICK_DEBOUNCE_TIMEOUT_MS } from '../../../utils/constants';

export function FormikDebounceContent<
  Values extends FormikValues = FormikValues
>(
  props: FormikProps<Values> &
    Pick<FormikConfig<Values>, 'children'> & { skipValidationTimer: boolean }
): JSX.Element {
  const { children, skipValidationTimer, ...rest } = props;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    stopTimer();

    if (skipValidationTimer || rest.isSubmitting) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      rest.validateForm();
    }, QUICK_DEBOUNCE_TIMEOUT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.values]);

  const stopTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return (
    <>
      {!!children && typeof children === 'function' ? children(rest) : children}
    </>
  );
}

export function FormikDebounce<Values extends FormikValues = FormikValues>(
  props: Pick<
    FormikConfig<Values>,
    | 'initialValues'
    | 'onSubmit'
    | 'children'
    | 'validationSchema'
    | 'validate'
    | 'enableReinitialize'
    | 'validateOnChange'
    | 'innerRef'
  >
): JSX.Element {
  const myProps = {
    ...props,
    validateOnMount: true,
    validateOnChange: false,
    children: (childProps: FormikProps<Values>) => (
      <FormikDebounceContent
        {...childProps}
        children={props.children}
        skipValidationTimer={props.validateOnChange === false}
      />
    ),
  };
  return Formik(myProps);
}
