import {
  CopyVersionHolderToNewVersionableRequest,
  CopyVersionHolderToVersionablesRequest,
  TrainingDto,
  TrainingVersionDto,
} from '@qcs/safety-client';
import { Form, useFormikContext } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trainingsApi } from '../../utils/api';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { DatePicker } from '../common/form/DatePicker';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { TrainingAutocomplete } from '../common/form/TrainingAutocomplete';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { getDateForApi, isDateDisabled } from '../../utils/date';
import { ErrorStateType } from '../../models/common';

type CopyVersionToTrainingForm = Omit<
  CopyVersionHolderToVersionablesRequest,
  'trainings'
> &
  CopyVersionHolderToNewVersionableRequest & {
    variant: 'toTrainings' | 'toNewTraining';
    trainings: TrainingDto[];
  };

interface CopyVersionToTrainingModalFormProps {
  versionToCopy?: TrainingVersionDto | null;
}

const CopyVersionToTrainingModalForm: FC<
  CopyVersionToTrainingModalFormProps
> = ({ versionToCopy }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<CopyVersionToTrainingForm>();

  useEffect(() => {
    if (values.variant === 'toNewTraining') {
      setFieldValue('validFrom', getDateForApi(new Date()));
    }
  }, [setFieldValue, values.variant]);

  return (
    <>
      <Select name="variant">
        <QcsMenuItem value={'toTrainings'}>
          {t('training.copy.toTrainings')}
        </QcsMenuItem>
        <QcsMenuItem value={'toNewTraining'}>
          {t('training.copy.toNewTraining')}
        </QcsMenuItem>
      </Select>
      {values.variant === 'toTrainings' && (
        <TrainingAutocomplete
          skipId={versionToCopy?.training}
          multiple
          name="trainings"
          label={t('training.copy.trainings')}
          required
        />
      )}
      {values.variant === 'toNewTraining' && (
        <>
          <Input
            name="name"
            label={t('training.name')}
            maxLength={50}
            required
          />

          <Input
            name="note"
            label={t('training.copy.trainingNote')}
            maxLength={250}
            multiline={true}
            rows={2}
          />
        </>
      )}
      <DatePicker
        name="validFrom"
        label={t('training.validFrom')}
        disabled={values.variant === 'toNewTraining'}
        disablePast
        disableHighlightToday={values.variant === 'toTrainings'}
        shouldDisableDate={
          values.variant === 'toTrainings' ? isDateDisabled : undefined
        }
        required
      />
      <Input
        name="versionNote"
        label={t('training.versionNote')}
        maxLength={250}
        multiline={true}
        rows={2}
      />
    </>
  );
};

interface Props {
  versionToCopy?: TrainingVersionDto | null;
  onClose: () => void;
  reloadTrainingVersionList: () => void;
}

export const CopyVersionToTrainingModal: FC<Props> = ({
  versionToCopy,
  onClose,
  reloadTrainingVersionList,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [versionToCopy]);

  const handleSubmit = async (data: CopyVersionToTrainingForm) => {
    setSaveError('');

    const dataToTrainings: CopyVersionHolderToVersionablesRequest = {
      validFrom: data.validFrom,
      versionNote: data.versionNote,
      versionables: data.trainings!.map((training) => training.id!),
    };

    const dataToNewTraining: CopyVersionHolderToNewVersionableRequest = {
      name: data.name,
      note: data.note,
      validFrom: data.validFrom,
      versionNote: data.versionNote,
    };

    try {
      if (data.variant === 'toTrainings') {
        await trainingsApi.copyVersionToTrainings(
          versionToCopy!.id!,
          dataToTrainings
        );
      } else {
        await trainingsApi.copyVersionToNewTraining(
          versionToCopy!.id!,
          dataToNewTraining
        );
      }
      enqueueSuccessSnackbar(t('training.copy.success'));
      reloadTrainingVersionList();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'training.copy.error'
      );
    }
  };

  return (
    <QcsModal open={!!versionToCopy} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={'training.copy.versionToTraining'} />
        <FormikDebounce<CopyVersionToTrainingForm>
          initialValues={{
            variant: 'toTrainings',
            trainings: [],
            validFrom: '',
            versionNote: '',
            name: '',
            note: '',
          }}
          validationSchema={Yup.object().shape({
            validFrom: Yup.string().when('variant', {
              is: (variant: string) => variant === 'toTrainings',
              then: () => validations.stringDateNotPastOrTodayRequired(t),
              otherwise: () => validations.stringDateNotPastRequired(t),
            }),
            trainings: Yup.array().when('variant', {
              is: (variant: string) => variant === 'toTrainings',
              then: () => validations.arrayNotEmpty(t),
            }),
            name: Yup.string().when('variant', {
              is: (variant: string) => variant === 'toNewTraining',
              then: () => validations.stringRequired(t),
            }),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <CopyVersionToTrainingModalForm />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
