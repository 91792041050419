import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { InvitationCreateForm } from '../../models/invitation';
import { Input } from '../common/form/Input';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { PhoneInput } from '../common/form/PhoneInput';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

export const InvitationCreateStep4C: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        responsiblePersonC: Yup.object({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email: validations.emailOptional(t),
          language: validations.stringRequired(t),
        }),
      })}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormContainer>
          <Input
            name="responsiblePersonC.name"
            label={t('user.name')}
            maxLength={100}
            required
          />
          <Input
            name="responsiblePersonC.lastName"
            label={t('user.lastName')}
            maxLength={100}
            required
          />
          <PhoneInput name="responsiblePersonC.phone" label={t('user.phone')} />
          <Input
            name="responsiblePersonC.email"
            label={t('user.email')}
            maxLength={100}
          />
          <LanguageSelect
            name="responsiblePersonC.language"
            label={t('user.language')}
            languages={SUPPORTED_LANGUAGES}
            allowedLanguages={companyCustomization?.applicationLanguages}
            required
          />

          <InvitationButtonsContainer>
            <QcsButton variant="outlined" onClick={handleGoBack}>
              {t('common.back')}
            </QcsButton>
            <div style={{ width: '100%' }} />
            <InvitationButton type="DELETE" customEvent={onDelete} />
            <InvitationButton type="SAVE" showAlways={true} />
            <InvitationButton type="SEND" showAlways={true} />
          </InvitationButtonsContainer>
        </FormContainer>
      </Form>
    </FormikDebounce>
  );
};
