import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { CreateSupplierEmployee } from './CreateSupplierEmployeeFromVisitor';

interface Props {
  open: boolean;
  onClose: () => void;
  onSupplierSelectClick: (supplier: string) => void;
}

export const InvitationModal: FC<Props> = ({
  open,
  onClose,
  onSupplierSelectClick,
}) => {
  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <CreateSupplierEmployee
          open={false}
          onClose={onClose}
          onSupplierSelectClick={onSupplierSelectClick}
        />
      </QcsModalBox>
    </QcsModal>
  );
};
