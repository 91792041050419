import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';

export const FaIcon = styled(FontAwesomeIcon)(
  ({ width = 24, height = 20, onClick }) => ({
    width: width,
    height: height,
    padding: '2px 0',
    cursor: Boolean(onClick) ? 'pointer' : 'default',
  })
);
