import {
  InvitationDtoStateEnum,
  InvitedPersonDto,
  ReferenceDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../../common/form/FormikDebounce';
import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { validations } from '../../../../utils/validations';
import { SaveError } from '../../../common/SaveError';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { FormContainer } from '../../../common/form/FormContainer';
import { SubmitButton } from '../../../common/form/SubmitButton';
import { SectionHeader } from '../../../common/SectionHeader';
import { setErrorStateSnacks } from '../../../../utils/error';
import {
  getFirstLastNameObj,
  getLangNameObj,
  getWorkplaceNameObj,
} from '../../../../utils/format';
import { Input } from '../../../common/form/Input';
import { QcsAutocomplete } from '../../../common/form/QcsAutocomplete';
import { useInvitation } from './invitationFunctions';

interface InvitedPersonsEditModalForm {
  fullName: string;
  activities: ReferenceDto[];
  workplaces: ReferenceDto[];
}

interface Props {
  data?: InvitedPersonDto;
  onClose: () => void;
  onSave: (data: InvitedPersonDto) => Promise<void>;
}

export const InvitedPersonsEditModal: FC<Props> = ({
  data,
  onClose,
  onSave,
}) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    saveError,
    setSaveError,
  } = useInvitation();

  useEffect(() => {
    setSaveError('');
  }, [data, setSaveError]);

  const handleSubmit = async (formData: InvitedPersonsEditModalForm) => {
    setSaveError('');
    lockInvitation();
    try {
      const data2: InvitedPersonDto = {
        ...data!,
        ...formData,
      };

      await onSave(data2);
      enqueueSuccessSnackbar(t('common.editSuccess'));
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
    unlockInvitation();
  };

  return (
    <QcsModal open={!!data} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.persons.edit" />
        <FormikDebounce<InvitedPersonsEditModalForm>
          initialValues={{
            ...data!,
            workplaces: (data?.workplaces ?? []).map((x) => ({
              id: x.id,
              name: getWorkplaceNameObj(
                i18n,
                x,
                invitation.workplaces?.find((w) => w.workplace?.id === x.id)
                  ?.establishment
              ),
            })),
            fullName: getFirstLastNameObj(data),
          }}
          validationSchema={Yup.object().shape({
            activities: validations.arrayNotEmpty(t),
            workplaces: validations.arrayNotEmpty(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <Input
                name="fullName"
                label={t('invitation.persons.name')}
                disabled
              />
              <QcsAutocomplete
                name="activities"
                label={t('invitation.persons.activities')}
                required={true}
                multiple={true}
                options={invitation.activities?.map((x) => ({
                  id: x.activity?.id,
                  name: x.activity?.name,
                  nameEn: x.activity?.nameEn,
                }))}
                getOptionId={(option) => option.id!}
                getOptionName={(option) => getLangNameObj(i18n, option)}
                loading={false}
                loadingError={false}
              />
              <QcsAutocomplete
                name="workplaces"
                label={t('invitation.persons.workplaces')}
                required={true}
                multiple={true}
                options={invitation.workplaces?.map((x) => ({
                  id: x.workplace?.id,
                  name: getWorkplaceNameObj(i18n, x.workplace, x.establishment),
                }))}
                getOptionId={(option) => option.id!}
                getOptionName={(option) => getLangNameObj(i18n, option)}
                loading={false}
                loadingError={false}
              />

              <SaveError error={saveError} />
              <SubmitButton
                disabled={invitation.state === InvitationDtoStateEnum.Archived}
              />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
