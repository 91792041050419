import { CertificateDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  documentListActions,
  getDocumentList,
  selectDocumentList,
} from '../../store/entities/documentList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { getLangNameObj } from '../../utils/format';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CellYesNo } from '../common/grid/CellYesNo';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { DocumentFilter } from './DocumentFilter';

export const Documents: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const documentList = useAppSelector(selectDocumentList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getDocumentList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: CertificateDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <CellYesNo value={item.expiryDateRequired} hideOnMobile={true} />
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: CertificateDto) => {
    navigate('/documents/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/documents/new');
  };

  return (
    <>
      <SectionHeader
        title="document.title"
        addText="document.add"
        handleAddClick={handleAddClick}
      />
      <Grid<CertificateDto>
        headers={[
          { captionStr: 'document.name' },
          {
            captionStr: 'document.expiryDateRequired',
            hideOnMobile: true,
          },
          {
            captionStr: 'document.state',
            hideOnMobile: true,
          },
        ]}
        data={documentList}
        gridActions={documentListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<DocumentFilter query={documentList.query} />}
      />
    </>
  );
};
