import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';
import { CompanyPageDtoTypeEnum } from '@qcs/safety-client';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import {
  getCompanyPages,
  selectCompanyPages,
  selectCompanyPagesState,
} from '../../store/entities/companyPages';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { companyPagesApi } from '../../utils/api';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { SaveError } from '../common/SaveError';
import { QcsFormControl } from '../common/basic/QcsFormControl';
import { QcsInputLabel } from '../common/basic/QcsInputLabel';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { QcsSelect } from '../common/basic/QcsSelect';
import { QcsTypography } from '../common/basic/QcsTypography';
import { ErrorAlert } from '../common/ErrorAlert';
import { LanguageSelectWithoutFormik } from '../common/form/LanguageSelectWithoutFormik';
import { StyledLoadingButton } from '../common/form/SubmitButton';
import { HtmlEditor } from '../common/html/HtmlEditor';
import { Loader } from '../common/Loader';
import { ContentWrapperColumn } from '../layout/commonLayout';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { ErrorStateType } from '../../models/common';

const AlertWrapper = styled.div(() => ({
  padding: '1rem',
}));

const SelectContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

export const CompanyPages: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyPagesState = useAppSelector(selectCompanyPagesState);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const companyPages = useAppSelector(selectCompanyPages);

  const getHtmlRef = useRef<(() => string) | null>(null);
  const reloadHtmlRef = useRef<(() => void) | null>(null);

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();

  const [pageType, setPageType] = useState<CompanyPageDtoTypeEnum>(
    CompanyPageDtoTypeEnum.SupplierManager
  );
  const [language, setLanguage] = useState<string | undefined>(
    companyCustomization?.applicationLanguages[0]
  );

  useEffect(() => {
    dispatch(getCompanyPages(identityCompanyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const page = useMemo(
    () =>
      companyPages?.find(
        (page) => page.type === pageType && page.language === language
      ),
    [companyPages, language, pageType]
  );

  useEffect(() => {
    reloadHtmlRef.current?.();
    setSaveError('');
  }, [page]);

  const handleTypeChange = (event: SelectChangeEvent<any>) => {
    setPageType(event.target.value);
  };

  const handleLanguageChange = (event: SelectChangeEvent<any>) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = async () => {
    const data = getHtmlRef.current?.();

    if (!page || data === undefined || data.trim() === '') {
      setSaveError(t('settings.companyPages.emptyHtmlError')!);
      return;
    }

    setSaveError('');
    setSaving(true);
    try {
      await companyPagesApi.updateCompanyPage({
        pages: [
          {
            ...page,
            content: data,
          },
        ],
      });
      enqueueSuccessSnackbar(t('common.editSuccess'));
      dispatch(getCompanyPages(identityCompanyId));
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
    setSaving(false);
  };

  if (companyPagesState === FetchState.Error) {
    return (
      <AlertWrapper>
        <ErrorAlert />
      </AlertWrapper>
    );
  }

  if (companyPages === undefined || !language) {
    return (
      <AlertWrapper>
        <Loader />
      </AlertWrapper>
    );
  }

  return (
    <ContentWrapperColumn>
      <QcsTypography variant="h5">
        {t('settings.companyPages.title')}
      </QcsTypography>

      <SelectContainer>
        <QcsFormControl>
          <QcsInputLabel>{t('settings.companyPages.type')}</QcsInputLabel>
          <QcsSelect
            sx={{
              backgroundColor: theme.palette.common.white,
              width: theme.breakpoints.values.sm / 2,
            }}
            label={t('settings.companyPages.type')}
            value={pageType}
            onChange={handleTypeChange}
          >
            {Object.values(CompanyPageDtoTypeEnum).map((x) => (
              <QcsMenuItem key={x} value={x}>
                {t(`settings.companyPages.types.${x}`)}
              </QcsMenuItem>
            ))}
          </QcsSelect>
        </QcsFormControl>

        <LanguageSelectWithoutFormik
          sx={{
            width: theme.breakpoints.values.sm / 2,
          }}
          languages={SUPPORTED_LANGUAGES}
          allowedLanguages={companyCustomization?.applicationLanguages}
          value={language}
          label={t('settings.companyPages.language')}
          onChange={handleLanguageChange}
        />
      </SelectContainer>

      {!!page &&
        (companyPagesState !== FetchState.Loading ? (
          <>
            <HtmlEditor
              html={page.content}
              getHtmlRef={getHtmlRef}
              reloadHtmlRef={reloadHtmlRef}
              allowSelectRisks={false}
            />
            <SaveError error={saveError} />
            <QcsBox sx={{ maxWidth: '980px', width: '100%' }}>
              <StyledLoadingButton
                sx={{ display: 'block' }}
                loading={saving}
                variant="contained"
                onClick={handleSubmit}
              >
                {t('common.save')}
              </StyledLoadingButton>
            </QcsBox>
          </>
        ) : (
          <AlertWrapper>
            <Loader />
          </AlertWrapper>
        ))}
    </ContentWrapperColumn>
  );
};
