import { LoginRequest } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../store';
import { getIdentity } from '../../store/entities/identity';
import { storageResetAction } from '../../store/storeReset';
import { authenticationApi } from '../../utils/api';
import { logout, setMediaToken, setRefreshToken } from '../../utils/storage';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import {
  LoginContainer,
  LoginLink,
  LoginLinkContainer,
  LoginWrapper,
} from './LoginStyles';
import axios from 'axios';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { setErrorState } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

export const Login: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<ErrorStateType>();
  const usernameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    logout();
    dispatch(storageResetAction());

    usernameRef.current?.focus();

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (data: LoginRequest) => {
    setError('');
    try {
      const res = await authenticationApi.accessToken(data);

      dispatch(getIdentity(res.data.accessToken!, true));

      if (location.pathname === '/login') {
        navigate('/');
      }

      setRefreshToken(res.data.refreshToken!);
      setMediaToken(res.data.mediaToken!);
    } catch (err) {
      let badCredentials = false;
      let wrongDomain = false;
      if (axios.isAxiosError(err) && err.response?.status) {
        badCredentials = err.response?.status === 401;
        wrongDomain = err.response?.status === 403;
      }

      setErrorState(
        err,
        setError,
        badCredentials
          ? 'auth.badCredentials'
          : wrongDomain
          ? 'auth.wrongDomain'
          : undefined
      );
    }
  };

  return (
    <LoginWrapper>
      <SectionHeader title="auth.loginTitle" />
      <FormikDebounce<LoginRequest>
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <LoginContainer>
            <Input
              name="username"
              label={t('auth.username')}
              inputRef={usernameRef}
            />
            <div>
              <div>
                <Input
                  name="password"
                  type="password"
                  label={t('auth.password')}
                  fullWidth={true}
                />
              </div>
              <LoginLinkContainer>
                <LoginLink to="/forgotten-password-request">
                  {t('auth.forgottenPassword')}
                </LoginLink>
              </LoginLinkContainer>
            </div>

            <SubmitButton text="auth.loginButton" />
            <SaveError error={error} />
          </LoginContainer>
        </Form>
      </FormikDebounce>
    </LoginWrapper>
  );
};
