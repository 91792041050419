import { InvitationLogEntryResponse } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useAppSelector } from '../../../../store';
import {
  getInvitationHistoryList,
  invitationHistoryListActions,
  selectInvitationHistoryList,
} from '../../../../store/entities/invitationHistoryList';
import { getLangNameObj } from '../../../../utils/format';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { CellDate } from '../../../common/grid/CellDate';
import { Grid } from '../../../common/grid/Grid';
import { useInvitation } from './invitationFunctions';

export const LogTable: FC = () => {
  const { t, i18n, invitation, dispatch } = useInvitation();

  const invitationHistoryList = useAppSelector(selectInvitationHistoryList);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getInvitationHistoryList(invitation.id!, cancelToken));
  };

  const handleRenderData = (item: InvitationLogEntryResponse) => {
    let text = t('invitation.log.types.' + item.type);
    Object.keys(item.params ?? {}).forEach((x) => {
      text = text.replaceAll('{' + x + '}', item.params![x]);
    });

    return (
      <>
        <CellDate value={item.timestamp} showWithTime={true} />
        <QcsTableCell>{getLangNameObj(i18n, item.actionBy)}</QcsTableCell>
        <QcsTableCell>{text}</QcsTableCell>
      </>
    );
  };

  return (
    <Grid<InvitationLogEntryResponse>
      headers={[
        { captionStr: 'invitation.log.timestamp', orderName: 'timestamp' },
        { captionStr: 'invitation.log.actionBy' },
        { captionStr: 'invitation.log.type' },
      ]}
      data={invitationHistoryList}
      gridActions={invitationHistoryListActions}
      renderData={handleRenderData}
      getData={handleGetData}
      search={false}
    />
  );
};
