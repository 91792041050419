import { RootState } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType } from '../baseHelper';
import { TourniquetTraining } from '../../models/tourniquet';

type State = BaseType<TourniquetTraining>;

const initialState: State = {
  state: FetchState.None,
  data: {
    training: {
      id: '',
      name: '',
      nameEn: '',
      languages: [],
    },
    questionnaire: undefined,
    index: 0,
    mode: 'training',
    answerResponse: undefined,
    allPersons: [],
    signQuestionnaire: [],
  },
};

export const tourniquetTrainingSlice = baseSlice({
  name: 'tourniquetTraining',
  initialState,
});

export const selectTourniquetTraining = (state: RootState) =>
  state.tourniquetTraining.data;
export const tourniquetTrainingActions = tourniquetTrainingSlice.actions;
