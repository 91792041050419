import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  invitationListActions,
  InvitationListFilter,
  selectInvitationList,
} from '../../store/entities/invitationList';
import { GridFilterString } from '../common/grid/GridFilterString';
import { GridFilterDateFromTo } from '../common/grid/GridFilterDateFromTo';
import { GridFilterInvitationState } from '../common/grid/GridFilterInvitationState';
import { GridFilterEmployee } from '../common/grid/GridFilterEmployee';
import { GridFilterSupplierEmployee } from '../common/grid/GridFilterSupplierEmployee';
import {
  ReferenceDto,
  SupplierEmployeeDto,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { hasOtherRoleThan, isExternal } from '../../utils/roles';
import { useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { InvitationFilterSupplier } from './InvitationFilterSupplier';
import { GridFilterInvitationWorkerStatus } from '../common/grid/GridFilterInvitationWorkerStatus';

interface SupplierEmployeeWrapperProps {
  value?: SupplierEmployeeDto;
  onChange: (value?: SupplierEmployeeDto) => void;
}

const SupplierEmployeeWrapper: FC<SupplierEmployeeWrapperProps> = ({
  value,
  onChange,
}) => {
  const invitationList = useAppSelector(selectInvitationList);

  return (
    <GridFilterSupplierEmployee
      supplierId={invitationList.query.supplier?.id}
      value={value}
      onChange={onChange}
    />
  );
};

interface EmployeeWrapperProps {
  value?: ReferenceDto;
  onChange: (value?: ReferenceDto) => void;
}

const EmployeeWrapper: FC<EmployeeWrapperProps> = ({ value, onChange }) => {
  return (
    <GridFilterEmployee forInvite={true} value={value} onChange={onChange} />
  );
};

interface Props {
  query: InvitationListFilter;
}

export const InvitationFilter: FC<Props> = ({ query }) => {
  const identity = useAppSelector(selectIdentity);

  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={invitationListActions}
        name="name"
        title="invitation.name"
        disableAutoClose={true}
        Component={GridFilterString}
      />

      {!isExternal(identity) && <InvitationFilterSupplier query={query} />}

      {hasOtherRoleThan(identity.roles, [
        UserDtoRolesEnum.ExternalWorker,
        UserDtoRolesEnum.ExternalOneTime,
      ]) && (
        <GridFilterItem
          query={query}
          gridActions={invitationListActions}
          name="supplierEmployee"
          title="invitation.supplierEmployee"
          disableAutoClose={true}
          Component={SupplierEmployeeWrapper}
        />
      )}

      {!isExternal(identity) && (
        <GridFilterItem
          query={query}
          gridActions={invitationListActions}
          name="employee"
          title="invitation.employee"
          disableAutoClose={true}
          Component={EmployeeWrapper}
        />
      )}

      <GridFilterItem
        query={query}
        gridActions={invitationListActions}
        name="validFrom"
        title="invitation.validFrom"
        disableAutoClose={true}
        Component={GridFilterDateFromTo}
      />
      <GridFilterItem
        query={query}
        gridActions={invitationListActions}
        name="validTo"
        title="invitation.validTo"
        disableAutoClose={true}
        Component={GridFilterDateFromTo}
      />
      <GridFilterItem
        query={query}
        gridActions={invitationListActions}
        name="workerStatus"
        title="invitation.invitationWorkerState"
        disableAutoClose={true}
        Component={GridFilterInvitationWorkerStatus}
      />
      <GridFilterItem
        query={query}
        gridActions={invitationListActions}
        name="state"
        title="invitation.state"
        disableAutoClose={true}
        Component={GridFilterInvitationState}
      />
    </>
  );
};
