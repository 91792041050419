import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { getList, ListFilter, listSlice, ListType } from '../listHelper';
import axios from 'axios';
import { codeListApi } from '../../utils/api';
import { LanguageDto } from '@qcs/safety-client';

export type AppTrainingLangListState = ListType<LanguageDto, ListFilter>;

const initialState: AppTrainingLangListState = {
  state: FetchState.None,
  data: undefined,
  query: {},
};

export const appTrainingLangListSlice = listSlice({
  name: 'appTrainingLangList',
  initialState,
});

export const selectAppTrainingLangList = (state: RootState) =>
  state.appTrainingLangList;
export const appTrainingLangListActions = appTrainingLangListSlice.actions;

export const getAppTrainingLangList = () => (dispatch: AppDispatch) => {
  const cancelToken = axios.CancelToken.source().token;

  const storeState = store.getState();
  const data = selectAppTrainingLangList(storeState);

  const handleGetData = () => {
    return codeListApi.queryLanguages();
  };

  getList({
    dispatch,
    cancelToken,
    state: data.state,
    actions: appTrainingLangListActions,
    getData: handleGetData,
  });
};
