import { VisitationInvitationResponseTypeEnum } from '@qcs/safety-client';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { TOURNIQUET_DIALOG_AUTOCLOSE_MS } from '../../utils/constants';
import { FaIcon } from '../common/FaIcon';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { QcsDialog } from '../common/basic/QcsDialog';
import { QcsDialogActions } from '../common/basic/QcsDialogActions';
import { DialogContent as QcsDialogContent } from '@mui/material';

import { QcsDialogContentText } from '../common/basic/QcsDialogContentText';
import { DialogTitle as QcsDialogTitle } from '@mui/material';


interface Props {
  mode?: VisitationInvitationResponseTypeEnum | 'EMAIL';
  onClose: () => void;
}

export const TourniquetDialog: FC<Props> = ({ mode, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (mode) {
      timeout = setTimeout(onClose, TOURNIQUET_DIALOG_AUTOCLOSE_MS);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [mode, onClose]);

  return (
    <QcsDialog open={!!mode} onClose={onClose}>
      <QcsDialogTitle>{t('tourniquet.title')}</QcsDialogTitle>
      <QcsDialogContent>
        <QcsDialogContentText>
          {t(
            mode === VisitationInvitationResponseTypeEnum.Exit
              ? 'tourniquet.exitMessage'
              : mode === 'EMAIL'
              ? 'tourniquet.sendLinkText'
              : 'tourniquet.enterMessage'
          )}
        </QcsDialogContentText>
      </QcsDialogContent>
      <QcsDialogActions>
        <FaIcon
          onClick={onClose}
          icon={faCircleCheck}
          color={theme.palette.success.main}
          width={50}
          height={50}
        />
      </QcsDialogActions>
    </QcsDialog>
  );
};
