import {
  InvitationRelationResponse,
  SafetyEquipmentDto,
  SafetyEquipmentDtoTypeEnum,
} from '@qcs/safety-client';
import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { getLangNameObj } from '../../../../utils/format';

interface Props {
  item?: InvitationRelationResponse;
  onClose: () => void;
}

export const WorkplaceSafetyEquipmentModal: FC<Props> = ({ item, onClose }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const handleRenderData = (item: SafetyEquipmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell
          sx={{
            color:
              item.type === SafetyEquipmentDtoTypeEnum.Owned
                ? theme.palette.warning.main
                : undefined,
          }}
        >
          {t('safetyEquipment.types.' + item.type)}
        </QcsTableCell>
      </>
    );
  };

  return (
    <QcsModal open={!!item} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.workplace.safetyEquipmentTitle" />
        <GridList<SafetyEquipmentDto>
          headers={[
            { captionStr: 'invitation.workplace.safetyEquipmentName' },
            { captionStr: 'invitation.workplace.safetyEquipmentType' },
          ]}
          data={item?.safetyEquipments ?? []}
          renderData={handleRenderData}
          search={false}
          hidePagination={true}
        />
      </QcsModalBox>
    </QcsModal>
  );
};
