import { FC, useMemo, useState } from 'react';
import { useAppSelector } from '../../store';
import {
  selectIdentity,
  selectIdentityState,
} from '../../store/entities/identity';
import { useTranslation } from 'react-i18next';
import {
  HideMenuButtonWrapper,
  SidebarContainer,
  StyledQcsListItemIcon,
  StyledQcsMenuItem,
  MenuListContainer,
} from './SidebarStyles';
import {
  getMenuNavLinkClassName,
  getMenuNavLinkId,
  hasRole,
  isQcsAdminMode,
  isRemote,
  isTourniquet,
} from '../../utils/roles';
import { mainMenuItems, qcsAdminMenuItems } from './menuItems';
import { QcsListItemText } from '../common/basic/QcsListItemText';
import { FetchState } from '../../store/fetchState';
import { useLocation } from 'react-router';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getMenuHidden, setMenuHidden } from '../../utils/storage';
import { QcsMenuList } from '../common/basic/QcsMenuList';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTooltip } from '../common/basic/QcsTooltip';
import { selectTourniquetTrainings } from '../../store/entities/tourniquetTrainings';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

export const Sidebar: FC = () => {
  const identity = useAppSelector(selectIdentity);
  const identityState = useAppSelector(selectIdentityState);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const { t } = useTranslation();
  const location = useLocation();
  const [hidden, setHidden] = useState(getMenuHidden());

  const mainMenuItemsMemo = useMemo(() => {
    return (isQcsAdminMode(identity) ? qcsAdminMenuItems : mainMenuItems)
      .filter((x) => !x.isDetail)
      .filter((x) => hasRole(identity.roles, x.roles))
      .filter(
        (x) =>
          companyCustomization?.passRisksEnabled === true || x.id !== 'risk'
      )
      .map((x) => {
        const id = getMenuNavLinkId(x.id, identity);

        return (
          <StyledQcsMenuItem
            isHidden={hidden}
            key={id}
            href={x.link}
            useNavLink={true}
            navLinkClassName={getMenuNavLinkClassName(id, location, identity)}
          >
            <StyledQcsListItemIcon>{x.icon}</StyledQcsListItemIcon>
            <QcsListItemText hidden={hidden}>
              {t('mainMenu.' + id)}
            </QcsListItemText>
          </StyledQcsMenuItem>
        );
      });
  }, [companyCustomization?.passRisksEnabled, hidden, identity, location, t]);

  if (identityState !== FetchState.Loaded) {
    return null;
  }

  if (
    isTourniquet(identity) ||
    isRemote(identity) ||
    (tourniquetTrainings?.trainings?.length ?? 0) > 0
  ) {
    return null;
  }

  const handleHideMenuClick = () => {
    setMenuHidden(!hidden);
    setHidden(!hidden);
  };

  return (
    <>
      <SidebarContainer>
        <MenuListContainer>
          <HideMenuButtonWrapper isHidden={hidden}>
            <QcsTooltip
              title={hidden ? t('mainMenu.expand') : t('mainMenu.collapse')}
              placement="top"
            >
              <div>
                <QcsButton onClick={handleHideMenuClick} sx={{ minWidth: 0 }}>
                  {hidden ? (
                    <ArrowForwardIosIcon fontSize="small" />
                  ) : (
                    <ArrowBackIosNewIcon fontSize="small" />
                  )}
                </QcsButton>
              </div>
            </QcsTooltip>
          </HideMenuButtonWrapper>
          <QcsMenuList>{mainMenuItemsMemo}</QcsMenuList>
        </MenuListContainer>
      </SidebarContainer>
    </>
  );
};
