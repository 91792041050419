import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getQuestionnaire,
  selectQuestionnaire,
  selectQuestionnaireState,
} from '../../store/entities/questionnaire';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import {
  getQuestionnaireVersion,
  questionnaireVersionActions,
  selectQuestionnaireVersion,
  selectQuestionnaireVersionState,
} from '../../store/entities/questionnaireVersion';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { QuestionnaireVersionDetailPresentation } from './QuestionnaireVersionDetailPresentation';
import { QuestionnaireVersionDetailData } from './QuestionnaireVersionDetailData';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';

export const QuestionnaireVersionDetail: FC = () => {
  const { questionnaireId, questionnaireVersionId } = useParams();
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const questionnaireVersion = useAppSelector(selectQuestionnaireVersion);
  const questionnaireVersionState = useAppSelector(
    selectQuestionnaireVersionState
  );
  const questionnaire = useAppSelector(selectQuestionnaire);
  const questionnaireState = useAppSelector(selectQuestionnaireState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Use goBack in browser navigator.
    return () => {
      dispatch(tourniquetTrainingActions.default());
      dispatch(tourniquetTrainingsActions.default());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionnaireVersionId === 'new') {
      dispatch(questionnaireVersionActions.default());
    } else {
      dispatch(getQuestionnaireVersion(questionnaireVersionId!));
    }
    dispatch(getQuestionnaire(questionnaireId!));

    setLoading(false);
  }, [questionnaireId, dispatch, questionnaireVersionId]);

  if (
    loading ||
    questionnaireVersionState === FetchState.Loading ||
    questionnaireState === FetchState.Loading
  ) {
    return <Loader />;
  }

  if (
    questionnaireState === FetchState.Error ||
    questionnaireVersionState === FetchState.Error
  ) {
    return <ErrorAlert />;
  }

  if ((tourniquetTrainings?.trainings?.length ?? 0) > 0) {
    return <QuestionnaireVersionDetailPresentation />;
  }

  return (
    <QuestionnaireVersionDetailData
      questionnaire={questionnaire}
      questionnaireVersion={questionnaireVersion}
      questionnaireVersionId={questionnaireVersionId!}
    />
  );
};
