import { SupplierEmployeeDtoStateEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterSelect } from './GridFilterSelect';

const options = Object.values(SupplierEmployeeDtoStateEnum);

interface Props {
  value?: SupplierEmployeeDtoStateEnum;
  onChange: (value?: SupplierEmployeeDtoStateEnum) => void;
}

export const GridFilterSupplierEmployeeState: FC<Props> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <GridFilterSelect
      value={value}
      options={options}
      onChange={onChange}
      getOptionId={(option: SupplierEmployeeDtoStateEnum) => option}
      getOptionName={(option: SupplierEmployeeDtoStateEnum) =>
        t(`entityState.${option}`)
      }
    />
  );
};
