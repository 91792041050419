import { ReferenceDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import {
  getOptionsEmployee,
  selectOptionsEmployee,
  selectOptionsEmployeeState,
} from '../../../store/entities/optionsEmployee';
import { FetchState } from '../../../store/fetchState';
import {
  getFirstLastNameAutocomplete,
  getFirstLastNameObj,
} from '../../../utils/format';
import { QcsAutocompleteWithoutFormik } from '../form/QcsAutocompleteWithoutFormik';

interface Props {
  forInvite?: boolean;
  value?: ReferenceDto;
  onChange: (value?: ReferenceDto) => void;
}

export const GridFilterEmployee: FC<Props> = ({
  forInvite,
  value,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsEmployee = useAppSelector(selectOptionsEmployee);
  const optionsEmployeeState = useAppSelector(selectOptionsEmployeeState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsEmployee(identityCompanyId, forInvite, search, cancelToken)
    );
  };

  const handleChange = (value?: ReferenceDto | ReferenceDto[] | null) => {
    onChange((value as ReferenceDto) ?? undefined);
  };

  return (
    <QcsAutocompleteWithoutFormik
      options={optionsEmployee}
      loading={optionsEmployeeState === FetchState.Loading}
      loadingError={optionsEmployeeState === FetchState.Error}
      loadOptions={loadOptions}
      value={value}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={getFirstLastNameObj}
      getValueIfNotFound={getFirstLastNameAutocomplete}
      onChange={handleChange}
    />
  );
};
