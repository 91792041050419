import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { mediaApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { LinearProgress as  QcsLinearProgress } from '@mui/material';

import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { QcsTypography } from '../common/basic/QcsTypography';
import { setErrorState } from '../../utils/error';
import { FileUploadMediaResponse } from '@qcs/safety-client';
import { checkFileSize } from '../../utils/upload';
import { ErrorStateType } from '../../models/common';

interface Props {
  file: File | null;
  onFileUploaded: (document: FileUploadMediaResponse) => Promise<void>;
  onCloseOnError: () => void;
}

export const DocumentInputUploadModal: FC<Props> = ({
  file,
  onFileUploaded,
  onCloseOnError,
}) => {
  const { t } = useTranslation();
  const [progressValue, setProgressValue] = useState(0);
  const [error, setError] = useState<ErrorStateType>();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);

  useEffect(() => {
    (async () => {
      setError('');
      setProgressValue(0);

      if (!file) {
        return;
      }

      try {
        checkFileSize(file);
        const res = await mediaApi.uploadMedia(identityCompanyId, true, file, {
          onUploadProgress: uploadProgress,
        });

        await onFileUploaded(res.data);
      } catch (err) {
        setErrorState(err, setError, 'common.saveError');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const uploadProgress = (p: any) => {
    let newValue = 0;

    if (p.total && p.loaded) {
      newValue = Math.floor((p.loaded / p.total) * 100);
    }

    if (progressValue !== newValue) {
      setProgressValue(newValue);
    }
  };

  const handleOnClose = () => {
    if (!error) {
      return;
    }

    onCloseOnError();
  };

  return (
    <QcsModal open={!!file} onClose={handleOnClose}>
      <QcsModalBox>
        <QcsTypography variant="h6">{t('html.uploading')}</QcsTypography>
        {error ? (
          <>
            <SaveError error={error} />
            <QcsButton onClick={onCloseOnError}>{t('common.close')}</QcsButton>
          </>
        ) : (
          <QcsLinearProgress variant="determinate" value={progressValue} />
        )}
      </QcsModalBox>
    </QcsModal>
  );
};
