import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { InvitationLogEntryResponse } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { invitationsApi } from '../../utils/api';

export type InvitationHistoryListState = ListType<
  InvitationLogEntryResponse,
  ListFilter
>;

const initialState: InvitationHistoryListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'timestamp',
    sortDesc: true,
  },
};

export const invitationHistoryListSlice = listSlice({
  name: 'invitationHistoryList',
  initialState,
});

export const selectInvitationHistoryList = (state: RootState) =>
  state.invitationHistoryList;
export const invitationHistoryListActions = invitationHistoryListSlice.actions;

export const getInvitationHistoryList =
  (invitationId: string, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectInvitationHistoryList(storeState);

    const handleGetData = (size: number) => {
      return invitationsApi.getInvitationHistory(
        invitationId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: invitationHistoryListActions,
      getData: handleGetData,
    });
  };
