import {
  InvitationDto,
  InvitedPersonDto,
  SafetyEquipmentDto,
  VisitationInvitationResponseTypeEnum,
  VisitationPinDto,
} from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  selectTourniquetIdentity,
  tourniquetIdentityActions,
} from '../../store/entities/tourniquetIdentity';
import { visitationApi } from '../../utils/api';
import { setErrorSnacks } from '../../utils/error';
import { getLangNameObj } from '../../utils/format';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { TourniquetDialog } from './TourniquetDialog';

interface Props {
  invitation: InvitationDto;
  person: InvitedPersonDto;
}

export const TourniquetInvitationOk: FC<Props> = ({ invitation, person }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const dispatch = useAppDispatch();
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const safetyEq = ([] as SafetyEquipmentDto[]).concat(
    ...person.workplaces
      .map((x) => invitation.workplaces?.find((y) => y.workplace?.id === x.id))
      .filter((x) => !!x)
      .map((x) => x?.safetyEquipments ?? [])
  );

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const data: VisitationPinDto = {
        invitation: invitation.id!,
        pin: tourniquetIdentity.pin!,
      };

      await visitationApi.visit(identityCompanyId!, data);
      setModalVisible(true);
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    dispatch(tourniquetIdentityActions.default());
  };

  return (
    <>
      <TourniquetDialog
        mode={
          modalVisible ? VisitationInvitationResponseTypeEnum.Enter : undefined
        }
        onClose={handleModalClose}
      />

      <div style={{ width: '100%' }}>
        <div style={{ marginBottom: '1rem' }}>
          <QcsTypography>{t('tourniquet.noIssue1')}</QcsTypography>
          <ul style={{ paddingLeft: '2rem' }}>
            {person.workplaces.map((x) => (
              <li key={x.id}>{getLangNameObj(i18n, x)}</li>
            ))}
          </ul>
        </div>
        {safetyEq.length === 0 ? undefined : (
          <div style={{ marginBottom: '1rem' }}>
            <QcsTypography>{t('tourniquet.noIssue2')}</QcsTypography>
            <ul style={{ paddingLeft: '2rem' }}>
              {safetyEq.map((x) => (
                <li key={x.id}>{getLangNameObj(i18n, x)}</li>
              ))}
            </ul>
          </div>
        )}
        <QcsLoadingButton
          variant="contained"
          onClick={handleConfirm}
          loading={loading}
          sx={{ display: 'block', margin: '0 auto' }}
        >
          {t('tourniquet.enterPin')}
        </QcsLoadingButton>
      </div>
    </>
  );
};
