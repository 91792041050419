import { useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import _ from 'lodash';
import { useTheme } from '@mui/material';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { Loader } from '../Loader';
import { QcsMenuItem } from '../basic/QcsMenuItem';
import { useTranslation } from 'react-i18next';
import { QcsTextField } from '../basic/QcsTextField';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  options: unknown[];
  loading?: boolean;
  getOptionName?: (option: any) => string;
  getOptionId: (option: any) => string;
  getOptionNameFromId: (option: any) => string;
}

export const PreSelectSingle: FC<Props> = ({
  className,
  name,
  label,
  required,
  options,
  loading,
  getOptionId,
  getOptionName,
  getOptionNameFromId,
}) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<any>();

  const value: any = _.get(values, name);

  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const items = useMemo(
    () =>
      options.map((option: any) => {
        const optionId = getOptionId(option);
        const optionName = getOptionName
          ? getOptionName(option)
          : getOptionNameFromId(optionId);

        return (
          <QcsMenuItem key={optionId} value={optionId}>
            {optionName}
          </QcsMenuItem>
        );
      }),
    [getOptionId, getOptionNameFromId, options, getOptionName]
  );

  const theme = useTheme();

  useEffect(() => {
    options.length === 1 &&
      options[0] &&
      setFieldValue(name, (options as any)[0].id);
  }, [name, options, options.length, setFieldValue]);

  return (
    <QcsFormControl error={wasTouched && hasError}>
      {options.length === 1 ? (
        <QcsTextField
          className={className}
          id={name}
          disabled
          name={name}
          label={label}
          value={getOptionName?.((options as any)[0]) || (options as any)[0].id}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
        />
      ) : (
        <>
          <QcsInputLabel required={required} disabled={options.length === 1}>
            {label}
          </QcsInputLabel>
          <QcsSelect
            required={required}
            className={className}
            sx={{ backgroundColor: theme.palette.common.white }}
            id={name}
            name={name}
            label={label}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={wasTouched && hasError}
            renderValue={(selected) =>
              (options &&
                options.length > 0 &&
                (options as any).find(
                  (option: any) => getOptionId(option) === selected
                ).name) ??
              ''
            }
          >
            <QcsMenuItem key={'undefined'} value={''}>
              <em>{t('common.nonSelected')}</em>
            </QcsMenuItem>
            {loading ? <Loader /> : items}
          </QcsSelect>
        </>
      )}
    </QcsFormControl>
  );
};
