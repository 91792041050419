import { Box, DialogContent, SxProps, Theme } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export const QcsModalBox = forwardRef<HTMLElement, Props>(
  ({ className, sx, children }, ref) => {
    return (
      <DialogContent>
        <Box
          className={className}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 400,
            maxWidth: 'calc(100vw - 1rem)',
            maxHeight: 'calc(100vh - 1rem)',
            backgroundColor: (theme) => theme.palette.background.paper,
            border: (theme) => `2px solid ${theme.palette.common.black}`,
            padding: '2rem',
            boxShadow: 24,
            overflow: 'auto',
            ...sx,
          }}
          ref={ref}
        >
          {children}
        </Box>
      </DialogContent>
    );
  }
);
