import {
  InputBaseComponentProps,
  OutlinedInput,
  SxProps,
  Theme,
} from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  id?: string;
  type?: string;
  placeholder?: string | null;
  endAdornment?: React.ReactNode;
  defaultValue?: unknown;
  autoComplete?: string;
  label?: React.ReactNode;
  error?: boolean;
  value?: unknown;
  disabled?: boolean;
  notched?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const QcsOutlinedInput: FC<Props> = ({
  className,
  sx,
  id,
  type,
  placeholder,
  endAdornment,
  defaultValue,
  autoComplete,
  label,
  error,
  value,
  disabled,
  notched,
  inputComponent,
  onChange,
  onBlur,
}) => {
  return (
    <OutlinedInput
      className={className}
      sx={sx}
      id={id}
      type={type}
      placeholder={placeholder ?? undefined}
      endAdornment={endAdornment}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      label={label}
      error={error}
      value={value}
      disabled={disabled}
      notched={notched}
      inputComponent={inputComponent}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
