import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { ApplicationInfoResult } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { actuatorApi } from '../../utils/api';

type State = BaseType<ApplicationInfoResult>;

const initialState: State = {
  state: FetchState.None,
  data: {},
};

export const versionSlice = baseSlice({
  name: 'version',
  initialState,
  disableReset: true,
});

export const selectVersionState = (state: RootState) => state.version.state;
export const selectVersion = (state: RootState) => state.version.data;
export const versionActions = versionSlice.actions;

export const getVersion = () => (dispatch: AppDispatch) => {
  const versionState = selectVersionState(store.getState());
  if (versionState !== FetchState.None) {
    return;
  }

  const handleGetData = () => {
    return actuatorApi.applicationInfo();
  };

  getItem({
    dispatch,
    actions: versionActions,
    getData: handleGetData,
  });
};
