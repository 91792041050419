import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsTypography } from '../basic/QcsTypography';

export const FormGroupContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  maxWidth: theme.breakpoints.values.md,
  marginBottom: '1.25rem',
}));

interface Props {
  name?: string;
  children: React.ReactNode;
}

export const FormGroup: FC<Props> = ({ name, children }) => {
  const { t } = useTranslation();
  return (
    <FormGroupContainer>
      {!!name && <QcsTypography variant="h6">{t(name)}</QcsTypography>}
      {children}
    </FormGroupContainer>
  );
};
