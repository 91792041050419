import { Alert, AlertColor, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  severity?: AlertColor;
  children: React.ReactNode;
}

const QcsAlert: FC<Props> = ({ className, sx, severity, children }) => {
  return (
    <Alert
      className={className}
      sx={{ width: 'fit-content', ...sx }}
      severity={severity}
    >
      {children}
    </Alert>
  );
};

export default QcsAlert