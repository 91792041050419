import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { SlideList } from './SlideList';
import { SlideDto } from '@qcs/safety-client';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getTrainingSlides,
  selectTrainingSlides,
  selectTrainingSlidesState,
  trainingSlidesActions,
} from '../../../store/entities/trainingSlides';
import { trainingsApi } from '../../../utils/api';
import { DropResult } from 'react-beautiful-dnd';
import { HtmlEditor } from '../../common/html/HtmlEditor';
import { FetchState } from '../../../store/fetchState';
import { QcsTextField } from '../../common/basic/QcsTextField';
import { Loader } from '../../common/Loader';
import { SaveError } from '../../common/SaveError';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { setErrorState } from '../../../utils/error';
import { selectTrainingVersion } from '../../../store/entities/trainingVersion';
import { ErrorStateType } from '../../../models/common';

const ContentContainer = styled.div(() => ({
  padding: '1rem',
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  gap: '1rem',
  alignItems: 'flex-start',
}));

const CornerBlock = styled.div(() => ({
  marginLeft: 'auto',
  display: 'flex',
  gap: '1rem',
  alignItems: 'flex-start',
}));

const EditorContainer = styled.div(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.modal,
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  overflow: 'auto',
  backgroundColor: theme.palette.background.default,
}));

const ControlButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginTop: '1rem',
  gap: '1rem',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const SaveButtonContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
}));

const reorder = (list: SlideDto[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const TrainingSlidesEditor: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trainingId, trainingVersionId, languageCode } = useParams();

  const editorGetHtml = useRef<(() => string) | null>(null);
  const editorReloadHtml = useRef<(() => void) | null>(null);
  const [editorSetFocus, setEditorSetFocus] = useState(true);
  const titleInputRef = useRef<HTMLInputElement>();
  const dispatch = useAppDispatch();
  const trainingSlides = useAppSelector(selectTrainingSlides) as
    | SlideDto[]
    | undefined;
  const trainingSlidesState = useAppSelector(selectTrainingSlidesState);

  const [unsavedChangesFunction, setUnsavedChangesFunction] = useState<
    (() => void) | null
  >(null);
  const [confirmDeleteFunction, setConfirmDeleteFunction] = useState<
    (() => void) | null
  >(null);

  const [loading, setLoading] = useState(true);
  const [initializing, setInitializing] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [missingTitleError, setMissingTitleError] = useState(false);
  const trainingVersion = useAppSelector(selectTrainingVersion);
  const editable = trainingVersion.editable;

  useEffect(() => {
    dispatch(getTrainingSlides(trainingVersionId!, languageCode!));
    setLoading(false);
  }, [dispatch, languageCode, trainingVersionId]);

  const [selectedSlideId, setSelectedSlideId] = useState<string | null>(null);

  useEffect(() => {
    if (initializing && !loading && trainingSlidesState === FetchState.Loaded) {
      setInitializing(false);
      setSelectedSlideId(trainingSlides?.[0]?.id ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingSlidesState, loading]);

  const selectedSlide: SlideDto | undefined = useMemo(
    () => trainingSlides?.find((slide) => slide.id === selectedSlideId),
    [selectedSlideId, trainingSlides]
  );

  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(selectedSlide?.title ?? '');
    setMissingTitleError(false);
    editorReloadHtml.current?.();
    setEditorSetFocus(true);
  }, [selectedSlide]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleSaveSlide = async () => {
    let returnState: 'ok' | 'error' = 'ok';

    setSaveError('');
    setMissingTitleError(false);
    setSaving(true);

    if (!title || title.trim() === '') {
      setMissingTitleError(true);
      setSaving(false);
      return 'error';
    }

    const content = editorGetHtml.current?.();
    const data = {
      ...selectedSlide!,
      title: title,
      content: content ?? '',
    };

    try {
      await trainingsApi.updateSlide(selectedSlideId!, data);
      dispatch(getTrainingSlides(trainingVersionId!, languageCode!));
    } catch (err) {
      setErrorState(err, setSaveError, 'common.saveError');
      returnState = 'error';
    }
    setSaving(false);

    return returnState;
  };

  const handleDeleteSlide = async () => {
    setSaving(true);
    setSaveError('');

    const slidesRollback = trainingSlides;
    const newSlides = trainingSlides?.filter(
      (slide) => slide.id !== selectedSlideId
    );
    dispatch(trainingSlidesActions.success(newSlides ?? []));

    try {
      await trainingsApi.deleteSlide(selectedSlideId!);
    } catch (err) {
      dispatch(trainingSlidesActions.success(slidesRollback ?? []));
      setErrorState(err, setSaveError, 'common.saveError');
    }
    setSaving(false);
  };

  const handleAddSlide = async () => {
    setSaving(true);
    setSaveError('');

    const newSlide = {
      id: '',
      title: t('training.newSlide'),
      content: '',
      language: {
        code: languageCode!,
        flag: '',
        name: '',
      },
    };

    try {
      const newSlideResponse = await trainingsApi.createSlide(
        trainingVersionId!,
        newSlide
      );

      dispatch(
        trainingSlidesActions.success([
          ...(trainingSlides || []),
          { ...newSlide, id: newSlideResponse.data.id! },
        ])
      );

      setSelectedSlideId(newSlideResponse.data.id!);
      titleInputRef.current?.focus();
      titleInputRef.current?.select();
    } catch (err) {
      setErrorState(err, setSaveError, 'common.saveError');
    }
    setSaving(false);
  };

  const handleDragEnd = async (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    setSaving(true);
    setSaveError('');

    const slidesRollback = trainingSlides;

    const result = reorder(
      trainingSlides!,
      dropResult.source.index,
      dropResult.destination.index
    );

    dispatch(trainingSlidesActions.success(result));

    try {
      await trainingsApi.changeOrder(trainingVersionId!, languageCode!, {
        entries: result.map((slide) => slide.id!),
      });
    } catch (err) {
      dispatch(trainingSlidesActions.success(slidesRollback ?? []));
      setErrorState(err, setSaveError, 'common.saveError');
    }
    setSaving(false);
  };

  const handleSelectSlide = (id: string) => {
    setSelectedSlideId(id);
  };

  const handleClose = () => {
    navigate(`/training/${trainingId}/${trainingVersionId}`);
  };

  const checkChanges =
    (f: (...params: any) => any) =>
    (...params: any) => {
      const htmlChanged =
        (selectedSlide &&
          editorGetHtml.current?.() !==
            selectedSlide?.content
              ?.replace(/\n|\t/g, '')
              .replace(/>\s+</g, '><')) ??
        '';
      const titleChanged = selectedSlide && title !== selectedSlide?.title;

      if (editable && (htmlChanged || titleChanged)) {
        setUnsavedChangesFunction(() => () => f(...params));
      } else {
        f(...params);
      }
    };

  const confirmDeleteSlide =
    (f: (...params: any) => any) =>
    (...params: any) => {
      setConfirmDeleteFunction(() => () => f(...params));
    };

  if (initializing || !trainingSlides) {
    return <Loader />;
  }

  return (
    <>
      <UnsavedChangesDialog
        onClose={() => setUnsavedChangesFunction(null)}
        onConfirm={unsavedChangesFunction}
        onSaveSlide={handleSaveSlide}
      />
      <ConfirmDialog
        onClose={() => setConfirmDeleteFunction(null)}
        onConfirm={confirmDeleteFunction}
        title={t('training.confirmDeleteDialog.title')}
        message={t('training.confirmDeleteDialog.message')}
      />

      <EditorContainer>
        <SlideList
          slides={trainingSlides}
          selectedId={selectedSlide?.id}
          onItemClick={checkChanges(handleSelectSlide)}
          onDragEnd={handleDragEnd}
          addSlide={checkChanges(handleAddSlide)}
          isDragDisabled={saving || !editable}
          onCloseEditor={checkChanges(handleClose)}
        />

        <ContentContainer>
          <HeaderContainer>
            {selectedSlide && (
              <QcsTextField
                inputRef={titleInputRef}
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  maxWidth: '25rem',
                }}
                label={t('training.slideTitle')}
                value={title}
                variant="standard"
                onChange={handleTitleChange}
                disabled={!editable}
                fullWidth={true}
              />
            )}

            <CornerBlock>
              {saving || trainingSlidesState === FetchState.Loading ? (
                <Loader />
              ) : saveError ? (
                <SaveError error={saveError} />
              ) : null}
              <QcsButton
                variant="outlined"
                color="error"
                onClick={confirmDeleteSlide(handleDeleteSlide)}
                disabled={saving || !editable}
              >
                {t('training.deleteSlide')}
              </QcsButton>
            </CornerBlock>
          </HeaderContainer>

          {selectedSlide && (
            <div>
              <HtmlEditor
                html={selectedSlide?.content ?? ''}
                getHtmlRef={editorGetHtml}
                reloadHtmlRef={editorReloadHtml}
                allowSelectRisks={true}
                setFocusValue={editorSetFocus}
                setFocusFunc={setEditorSetFocus}
              />

              <ControlButtonsContainer>
                <SaveButtonContainer>
                  {missingTitleError && (
                    <SaveError error={t('training.missingTitleError')} />
                  )}
                  <QcsButton
                    variant="contained"
                    onClick={handleSaveSlide}
                    disabled={saving || !editable}
                  >
                    {t('training.saveSlide')}
                  </QcsButton>
                </SaveButtonContainer>
              </ControlButtonsContainer>
            </div>
          )}
        </ContentContainer>
      </EditorContainer>
    </>
  );
};
