import axios from 'axios';
import {
  ActivityApi,
  AresApi,
  AuthenticationApi,
  CertificateApi,
  CodeListApi,
  CompanyApi,
  CompanyPagesApi,
  EstablishmentApi,
  EmployeeApi,
  QCUserApi,
  SafetyEquipmentApi,
  MediaApi,
  CompanySettingsApi,
  RiskApi,
  SupplierApi,
  SupplierEmployeeApi,
  WorkplaceApi,
  TrainingsApi,
  QuestionnairesApi,
  InvitationsApi,
  EmergencyApi,
  VisitationsApi,
  ForgottenPasswordApi,
  ApplicationInvitationApi,
  DashboardApi,
  InvitationTrainingResourceApi,
  ActuatorApi,
  InvitationTypeVisitsApi,
} from '@qcs/safety-client';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setMediaToken,
  setRefreshToken,
} from './storage';
import { history } from './history';

// @qcs/safety-clien has older version of axios, "api as any" replace with new axios (different types is OK, we dont use configuration)

const BASE_URL = import.meta.env.VITE_API_URL as string;
const USE_SUBDOMAIN = import.meta.env.VITE_USE_SUBDOMAIN as string;

export const BASE_URL_WITH_VERSION = `${BASE_URL}/api/v1`;

export const api = axios.create({
  baseURL: BASE_URL,
});

export const authenticationApi = new AuthenticationApi(
  undefined,
  BASE_URL,
  api as any
);

export const companyApi = new CompanyApi(undefined, BASE_URL, api as any);

export const companyPagesApi = new CompanyPagesApi(
  undefined,
  BASE_URL,
  api as any
);

export const companySettingsApi = new CompanySettingsApi(
  undefined,
  BASE_URL,
  api as any
);

export const aresApi = new AresApi(undefined, BASE_URL, api as any);

export const establishmentApi = new EstablishmentApi(
  undefined,
  BASE_URL,
  api as any
);

export const activityApi = new ActivityApi(undefined, BASE_URL, api as any);

export const riskApi = new RiskApi(undefined, BASE_URL, api as any);

export const certificateApi = new CertificateApi(
  undefined,
  BASE_URL,
  api as any
);

export const qcUserApi = new QCUserApi(undefined, BASE_URL, api as any);

export const safetyEquipmentApi = new SafetyEquipmentApi(
  undefined,
  BASE_URL,
  api as any
);

export const codeListApi = new CodeListApi(undefined, BASE_URL, api as any);

export const employeeApi = new EmployeeApi(undefined, BASE_URL, api as any);

export const supplierApi = new SupplierApi(undefined, BASE_URL, api as any);

export const trainingsApi = new TrainingsApi(undefined, BASE_URL, api as any);

export const questionnairesApi = new QuestionnairesApi(
  undefined,
  BASE_URL,
  api as any
);

export const supplierEmployeeApi = new SupplierEmployeeApi(
  undefined,
  BASE_URL,
  api as any
);

export const workplaceApi = new WorkplaceApi(undefined, BASE_URL, api as any);

export const invitationsApi = new InvitationsApi(
  undefined,
  BASE_URL,
  api as any
);

export const invitationTypeVisitsApi = new InvitationTypeVisitsApi(
  undefined,
  BASE_URL,
  api as any
);

export const mediaApi = new MediaApi(undefined, BASE_URL, api as any);

export const emergencyApi = new EmergencyApi(undefined, BASE_URL, api as any);

export const visitationApi = new VisitationsApi(
  undefined,
  BASE_URL,
  api as any
);

export const forgottenPasswordApi = new ForgottenPasswordApi(
  undefined,
  BASE_URL,
  api as any
);

export const applicationInvitationApi = new ApplicationInvitationApi(
  undefined,
  BASE_URL,
  api as any
);

export const dashboardApi = new DashboardApi(undefined, BASE_URL, api as any);

export const invitationTrainingResourceApi = new InvitationTrainingResourceApi(
  undefined,
  BASE_URL,
  api as any
);

export const actuatorApi = new ActuatorApi(undefined, BASE_URL, api as any);

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    config.headers = Object.assign(Object.assign({}, config.headers), {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(USE_SUBDOMAIN !== 'true' ? { 'x-skip-validation': '4321' } : {}),
    });

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.request && !error.response) {
      // The request was made but no response was received.
    } else if (!error.request && !error.response) {
      // Something happened in setting up the request that triggered an Error.
    } else if (error.response.status === 401) {
      const originalRequest = error.config;

      if (!originalRequest._dontRedirect) {
        const token = getAccessToken();

        if (token && !originalRequest._retry) {
          originalRequest._retry = true;
          await refreshToken();
          return api(originalRequest);
        }

        history.push('/login');
      }
    }
    return Promise.reject(error);
  }
);

const refreshTokenApi = axios.create({
  baseURL: BASE_URL,
});

export const refreshTokenAuthenticationApi = new AuthenticationApi(
  undefined,
  BASE_URL,
  refreshTokenApi as any
);

export const refreshToken = async () => {
  const token = getRefreshToken();
  const res = await refreshTokenAuthenticationApi.refreshToken({
    refreshToken: token!,
  });

  setAccessToken(res.data.accessToken!);
  setRefreshToken(res.data.refreshToken!);
  setMediaToken(res.data.mediaToken!);
};
