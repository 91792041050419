import { RootState } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType } from '../baseHelper';
import { TourniquetResponse } from '@qcs/safety-client';

type State = BaseType<TourniquetResponse>;

const initialState: State = {
  state: FetchState.None,
  data: {
    invitationPerson: {
      id: '',
      name: '',
      lastName: '',
      email: '',
      activities: [],
      workplaces: [],
    },
  },
};

export const tourniquetTrainingsSlice = baseSlice({
  name: 'tourniquetTrainings',
  initialState,
});

export const selectTourniquetTrainings = (state: RootState) =>
  state.tourniquetTrainings.data;
export const tourniquetTrainingsActions = tourniquetTrainingsSlice.actions;
