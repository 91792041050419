import { LanguageDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { codeListApi } from '../../../utils/api';
import * as Yup from 'yup';
import { validations } from '../../../utils/validations';
import { SaveError } from '../../common/SaveError';
import { FormContainer } from '../../common/form/FormContainer';
import { SubmitButton } from '../../common/form/SubmitButton';
import { SectionHeader } from '../../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../common/basic/QcsModalBox';
import { Input } from '../../common/form/Input';
import { FlagSelect } from './FlagSelect';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../../utils/error';
import { ErrorStateType } from '../../../models/common';

interface Props {
  open: boolean;
  selectedLanguage?: LanguageDto | null;
  reloadTrainingLangList: () => void;
  onClose: () => void;
}

export const TrainingLanguageDetailModal: FC<Props> = ({
  open,
  selectedLanguage,
  reloadTrainingLangList,
  onClose,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (data: LanguageDto) => {
    setSaveError('');
    try {
      if (selectedLanguage) {
        await codeListApi.updateLanguage(data.code, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      } else {
        await codeListApi.createLanguage(data);
        enqueueSuccessSnackbar(t('admin.settings.trainingLang.addSuccess'));
      }
      reloadTrainingLangList();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'admin.settings.trainingLang.addError'
      );
    }
  };

  const editing = !!selectedLanguage;

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader
          title={
            editing
              ? 'admin.settings.trainingLang.editing'
              : 'admin.settings.trainingLang.adding'
          }
        />
        <FormikDebounce<LanguageDto>
          initialValues={selectedLanguage ?? { code: '', flag: '', name: '' }}
          validationSchema={Yup.object().shape({
            code: validations.stringRequired(t),
            flag: validations.stringRequired(t),
            name: validations.stringRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <Input
                name="name"
                label={t('admin.settings.trainingLang.name')}
                maxLength={50}
                required
              />
              <FlagSelect
                name="flag"
                label={t('admin.settings.trainingLang.flag')}
                required
              />
              {!editing && (
                <Input
                  name="code"
                  label={t('admin.settings.trainingLang.code')}
                  maxLength={3}
                  required
                />
              )}

              <SaveError error={saveError} />
              <SubmitButton
                text={
                  editing
                    ? 'common.save'
                    : 'admin.settings.trainingLang.addLanguage'
                }
              />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
