import styled from '@emotion/styled';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsCard } from '@s4e/design-system/molecules/blocks/QcsCard';
import { QcsCardContent } from '@s4e/design-system/molecules/blocks/QcsCardContent';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';

export const TourniquetCard = styled(QcsCard)(({ theme }) => ({
  margin: '1rem auto',
  height: 'max-content',

  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}));

export const TourniquetHeader = styled(QcsBox)(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '80px auto 150px',
  gap: '1rem',
  marginBottom: '2rem',
  alignItems: 'center',
}));

export const TourniquetContent = styled(QcsCardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    padding: '1rem',
  },

  [theme.breakpoints.up('md')]: {
    padding: '2rem',
  },
}));

export const TourniquetTrainingRow = styled(QcsBox)(() => ({
  display: 'flex',
  width: '100%',
  gap: '2rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem 0',
}));

export const TourniquetAnswersContainer = styled(QcsBox)(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  gap: '1rem',
  marginBottom: '1rem',
}));

export const LanguageSwitchWrapper = styled(QcsBox)(({}) => ({
  margin: 'auto',
  display: 'flex',
  gap: '1rem',
}));

export const FlagButton = styled(QcsLoadingButton)<{
  selected: boolean;
}>(({ selected, theme }) => ({
  border: selected ? `2px solid ${theme.palette.primary.light}` : 'none',
  background: selected ? theme.palette.divider : 'none',
}));

export const TourniquetSlideCard = styled(QcsCard)(({ theme }) => ({
  width: '100%',
  minHeight: '70vh',
  maxWidth: '980px',
  margin: '0 auto',
  padding: '2rem',

  [theme.breakpoints.down('sm')]: {
    padding: 0,
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}));

export const LanguageContainer = styled(QcsBox)(({}) => ({
  display: 'flex',
  justifyContent: 'end',
  flexWrap: 'wrap',
}));
