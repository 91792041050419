import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { QcsTextField } from '../basic/QcsTextField';
import _ from 'lodash';
import { SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageSelectWithoutFormik } from './LanguageSelectWithoutFormik';
import { SUPPORTED_LANGUAGES } from '../../../utils/i18n';
import { useAppSelector } from '../../../store';
import { selectCompanyCustomization } from '../../../store/entities/companyCustomization';

interface Props {
  isNew: boolean;
  required?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  namePrefix?: string | null;
  label?: React.ReactNode;
  maxLength?: number;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
}

export const InputTextWithLang: FC<Props> = ({
  isNew,
  required,
  type,
  namePrefix,
  label,
  maxLength,
  multiline,
  rows,
  disabled,
}) => {
  const {
    values,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<any>();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [defaultLang] = useState(i18n.resolvedLanguage);
  const [actualLang, setActualLang] = useState(i18n.resolvedLanguage);
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  const nameCs = `${namePrefix ?? ''}name`;
  const valueCsOriginal = _.get(values, nameCs);
  const valueCs = valueCsOriginal || '';
  const wasTouchedCs = !!submitCount || !!_.get(touched, nameCs);
  const errorTextCs = _.get(errors, nameCs) as string;

  const nameEn = `${namePrefix ?? ''}nameEn`;
  const valueEnOriginal = _.get(values, nameEn);
  const valueEn = valueEnOriginal || '';
  const wasTouchedEn = !!submitCount || !!_.get(touched, nameEn);
  const errorTextEn = _.get(errors, nameEn) as string;

  const nameRu = `${namePrefix ?? ''}nameRu`;
  const valueRuOriginal = _.get(values, nameRu);
  const valueRu = valueRuOriginal || '';
  const wasTouchedRu = !!submitCount || !!_.get(touched, nameRu);
  const errorTextRu = _.get(errors, nameRu) as string;

  const nameDe = `${namePrefix ?? ''}nameDe`;
  const valueDeOriginal = _.get(values, nameDe);
  const valueDe = valueDeOriginal || '';
  const wasTouchedDe = !!submitCount || !!_.get(touched, nameDe);
  const errorTextDe = _.get(errors, nameDe) as string;

  const namePl = `${namePrefix ?? ''}namePl`;
  const valuePlOriginal = _.get(values, namePl);
  const valuePl = valuePlOriginal || '';
  const wasTouchedPl = !!submitCount || !!_.get(touched, namePl);
  const errorTextPl = _.get(errors, namePl) as string;

  const wasTouched =
    wasTouchedCs ||
    wasTouchedEn ||
    wasTouchedRu ||
    wasTouchedDe ||
    wasTouchedPl;

  let name = '';
  switch (actualLang) {
    case 'en':
      name = nameEn;
      break;
    case 'cs':
      name = nameCs;
      break;
    case 'ru':
      name = nameRu;
      break;
    case 'de':
      name = nameDe;
      break;
    case 'pl':
      name = namePl;
      break;
  }

  let value = '';
  switch (actualLang) {
    case 'en':
      value = valueEn;
      break;
    case 'cs':
      value = valueCs;
      break;
    case 'ru':
      value = valueRu;
      break;
    case 'de':
      value = valueDe;
      break;
    case 'pl':
      value = valuePl;
      break;
  }

  let errorText = '';
  switch (actualLang) {
    case 'en':
      errorText =
        errorTextEn || errorTextCs || errorTextRu || errorTextDe || errorTextPl;
      break;
    case 'cs':
      errorText =
        errorTextCs || errorTextEn || errorTextRu || errorTextDe || errorTextPl;
      break;
    case 'ru':
      errorText =
        errorTextRu || errorTextCs || errorTextEn || errorTextDe || errorTextPl;
      break;
    case 'de':
      errorText =
        errorTextDe || errorTextCs || errorTextEn || errorTextPl || errorTextRu;
      break;
    case 'pl':
      errorText =
        errorTextPl || errorTextCs || errorTextEn || errorTextDe || errorTextRu;
      break;
  }

  const hasError = !!errorText;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setFieldValue(name, newVal);

    if (
      isNew &&
      actualLang === defaultLang &&
      [valueCs, valueEn, valueRu, valueDe, valuePl].every((x) => x === valueCs)
    ) {
      switch (actualLang) {
        case 'en':
          setFieldValue(nameCs, newVal);
          setFieldValue(nameRu, newVal);
          setFieldValue(nameDe, newVal);
          setFieldValue(namePl, newVal);
          break;
        case 'cs':
          setFieldValue(nameEn, newVal);
          setFieldValue(nameRu, newVal);
          setFieldValue(nameDe, newVal);
          setFieldValue(namePl, newVal);
          break;
        case 'ru':
          setFieldValue(nameEn, newVal);
          setFieldValue(nameCs, newVal);
          setFieldValue(nameDe, newVal);
          setFieldValue(namePl, newVal);
          break;
        case 'de':
          setFieldValue(nameEn, newVal);
          setFieldValue(nameCs, newVal);
          setFieldValue(nameRu, newVal);
          setFieldValue(namePl, newVal);
          break;
        case 'pl':
          setFieldValue(nameEn, newVal);
          setFieldValue(nameCs, newVal);
          setFieldValue(nameRu, newVal);
          setFieldValue(nameDe, newVal);
          break;
      }
    }
  };

  const handleBlur = () => {
    if (!wasTouched) {
      setFieldTouched(nameCs);
      setFieldTouched(nameEn);
      setFieldTouched(nameRu);
      setFieldTouched(nameDe);
      setFieldTouched(namePl);
    }
  };

  const handleLanguageChange = (event: SelectChangeEvent<any>) => {
    setActualLang(event.target.value);
  };

  return (
    <div style={{ display: 'flex' }}>
      <QcsTextField
        required={required}
        sx={{ backgroundColor: theme.palette.common.white }}
        fullWidth={true}
        id={name}
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        helperText={wasTouched && errorText}
        inputProps={{ maxLength }}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
      />
      <LanguageSelectWithoutFormik
        languages={SUPPORTED_LANGUAGES}
        allowedLanguages={companyCustomization?.applicationLanguages}
        value={actualLang}
        label={t('changeLang.lang')}
        onChange={handleLanguageChange}
        hideName={true}
      />
    </div>
  );
};
