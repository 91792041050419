import { ForgottenPasswordRequest } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../store';
import { storageResetAction } from '../../store/storeReset';
import { forgottenPasswordApi } from '../../utils/api';
import { logout } from '../../utils/storage';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import {
  LoginContainer,
  LoginLink,
  LoginLinkContainer,
  LoginWrapper,
} from './LoginStyles';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

export const ForgottenPasswordReq: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorStateType>();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();

  useEffect(() => {
    logout();
    dispatch(storageResetAction());

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (data: ForgottenPasswordRequest) => {
    setError('');
    try {
      await forgottenPasswordApi.requestForgottenPassword(data);
      enqueueSuccessSnackbar(t('auth.emailSuccess'));
      navigate('/');
    } catch (err) {
      setErrorStateSnacks(
        err,
        setError,
        enqueueErrorSnackbar,
        'auth.emailError'
      );
    }
  };

  return (
    <LoginWrapper>
      <SectionHeader title="auth.forgottenPassword" />
      <FormikDebounce<ForgottenPasswordRequest>
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <LoginContainer>
            <div>
              <div>
                <Input
                  name="email"
                  label={t('auth.username')}
                  fullWidth={true}
                />
              </div>
              <LoginLinkContainer>
                <LoginLink to="/">{t('auth.backToLogin')}</LoginLink>
              </LoginLinkContainer>
            </div>

            <SubmitButton text="auth.sendEmail" />
            <SaveError error={error} />
          </LoginContainer>
        </Form>
      </FormikDebounce>
    </LoginWrapper>
  );
};
