import { UserDtoRolesEnum } from '@qcs/safety-client';

import { FaIcon } from '../common/FaIcon';
import {
  faGear,
  faPeopleRoof,
  faIndustry,
  faWarehouse,
  faClipboardUser,
  faRoadBarrier,
  faHelmetSafety,
  faListCheck,
  faTriangleExclamation,
  faTruckField,
  faChartColumn,
  faAddressCard,
  faCalendarDays,
  faUser,
  faBuilding,
  faLaptopCode,
  faArrowRightFromBracket,
  faScrewdriverWrench,
  faUserLock,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';

import { QcsCompanySettings } from '../qcsCompanySettings/QcsCompanySettings';
import { Settings } from '../settings/Settings';
import { Dashboard } from '../dashboard/Dashboard';
import { SafetyEquipment } from '../safetyEquipment/SafetyEquipment';
import { Documents } from '../documents/Documents';
import { Employee } from '../employee/Employee';
import { User } from '../user/User';
import { Establishment } from '../establishment/Establishment';
import { Activity } from '../activity/Activity';
import { Risk } from '../risk/Risk';
import { Workplace } from '../workplace/Workplace';
import { Training } from '../training/Training';
import { Questionnaire } from '../questionnaire/Questionnaire';
import { Supplier } from '../supplier/Supplier';
import { Visitation } from '../visitation/Visitation';
import { Invitation } from '../invitation/Invitation';
import { QcsAdminCompany } from '../qcsAdmin/company/QcsAdminCompany';
import { QcsAdminUser } from '../qcsAdmin/user/QcsAdminUser';
import { QcsAdminSettings } from '../qcsAdmin/settings/QcsAdminSettings';

import { ActivityDetail } from '../activity/ActivityDetail';
import { DocumentDetail } from '../documents/DocumentDetail';
import { QcsAdminCompanyDetail } from '../qcsAdmin/company/QcsAdminCompanyDetail';
import { QcsAdminUserDetail } from '../qcsAdmin/user/QcsAdminUserDetail';
import { SafetyEquipmentDetail } from '../safetyEquipment/SafetyEquipmentDetail';
import { EstablishmentDetail } from '../establishment/EstablishmentDetail';
import { UserDetail } from '../user/UserDetail';
import { Profile } from '../user/Profile';
import { EmployeeDetail } from '../employee/EmployeeDetail';
import { RiskVersionDetail } from '../risk/RiskVersionDetail';
import { RiskDetail } from '../risk/RiskDetail';
import { SupplierDetail } from '../supplier/SupplierDetail';
import { SupplierEmployeeDetail } from '../supplier/SupplierEmployeeDetail';
import { WorkplaceDetail } from '../workplace/WorkplaceDetail';
import { Emergency } from '../emergency/Emergency';
import { TrainingDetail } from '../training/TrainingDetail';
import { TrainingVersionDetail } from '../training/TrainingVersionDetail';
import { TrainingSlidesEditor } from '../training/trainingSlidesEditor/TrainingSlidesEditor';
import { QuestionnaireDetail } from '../questionnaire/QuestionnaireDetail';
import { InvitationCreate } from '../invitation/InvitationCreate';
import { QuestionnaireVersionDetail } from '../questionnaire/QuestionnaireVersionDetail';
import { QuestionnaireQuestions } from '../questionnaire/QuestionnaireQuestions';
import { InvitationDetail } from '../invitation/InvitationDetail';
import { EmergencyNew } from '../emergency/EmergencyNew';
import { EmergencyDetail } from '../emergency/EmergencyDetail';
import { VisitationDetail } from '../visitation/VisitationDetail';
import { VisitationVisitDetail } from '../visitation/VisitationVisitDetail';
import { TrainingExternal } from '../external/TrainingExternal';
import { PinExternal } from '../external/PinExternal';
import { InvitationTypeVisit } from '../invitationTypeVisit/InvitationTypeVisit';
import { InvitationTypeVisitDetail } from '../invitationTypeVisit/InvitationTypeVisitDetail';
import { SupplierEmployeeFromVisitorDetail } from '../supplier/SupplierEmployeeFromVisitor';

interface MenuItem {
  isDetail?: boolean;
  id: string;
  link: string;
  icon?: React.ReactNode;
  roles: UserDtoRolesEnum[];
  component?: React.ReactNode;
}

export const qcsAdminMenuItems: MenuItem[] = [
  {
    id: 'qcscompany',
    link: '',
    icon: <FaIcon icon={faBuilding} />,
    roles: [],
    component: <QcsAdminCompany />,
  },
  {
    isDetail: true,
    id: 'qcscompanyDetail',
    link: 'qcscompany/:companyId',
    roles: [],
    component: <QcsAdminCompanyDetail />,
  },
  {
    id: 'qcsuser',
    link: 'qcsuser',
    icon: <FaIcon icon={faUser} />,
    roles: [],
    component: <QcsAdminUser />,
  },
  {
    isDetail: true,
    id: 'qcsuserDetail',
    link: 'qcsuser/:userId',
    roles: [],
    component: <QcsAdminUserDetail />,
  },
  {
    id: 'qcssettings',
    link: 'qcssettings',
    icon: <FaIcon icon={faGear} />,
    roles: [],
    component: <QcsAdminSettings />,
  },
];

export const mainMenuItems: MenuItem[] = [
  {
    id: 'dashboard',
    link: '',
    icon: <FaIcon icon={faChartColumn} />,

    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <Dashboard />,
  },
  {
    id: 'invitation',
    link: 'invitation',
    icon: <FaIcon icon={faCalendarDays} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <Invitation />,
  },
  {
    isDetail: true,
    id: 'invitationCreate',
    link: 'invitation/new',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <InvitationCreate />,
  },
  {
    isDetail: true,
    id: 'invitationDetail',
    link: 'invitation/:invitationId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <InvitationDetail />,
  },
  {
    isDetail: true,
    id: 'invitationDetailTab',
    link: 'invitation/:invitationId/:tabId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <InvitationDetail />,
  },
  {
    id: 'visitation',
    link: 'visitation',
    icon: <FaIcon icon={faPeopleRoof} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
    ],
    component: <Visitation />,
  },
  {
    isDetail: true,
    id: 'visitationDetail',
    link: 'visitation/:visitationId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
    ],
    component: <VisitationDetail />,
  },
  {
    isDetail: true,
    id: 'visitationDetailVisit',
    link: 'visitation/visit/:invitationId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
    ],
    component: <VisitationVisitDetail />,
  },
  {
    id: 'establishment',
    link: 'establishment',
    icon: <FaIcon icon={faIndustry} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <Establishment />,
  },
  {
    isDetail: true,
    id: 'establishmentDetail',
    link: 'establishment/:establishmentId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <EstablishmentDetail />,
  },
  {
    id: 'workplace',
    link: 'workplace',
    icon: <FaIcon icon={faWarehouse} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <Workplace />,
  },
  {
    isDetail: true,
    id: 'workplaceDetail',
    link: 'workplace/:workplaceId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <WorkplaceDetail />,
  },
  {
    id: 'employee',
    link: 'employee',
    icon: <FaIcon icon={faClipboardUser} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.AdminCompany],
    component: <Employee />,
  },
  {
    isDetail: true,
    id: 'employeeDetail',
    link: 'employee/:employeeId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.AdminCompany],
    component: <EmployeeDetail />,
  },
  {
    id: 'user',
    link: 'user',
    icon: <FaIcon icon={faUser} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.AdminCompany],
    component: <User />,
  },
  {
    isDetail: true,
    id: 'userDetail',
    link: 'user/:userId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.AdminCompany],
    component: <UserDetail />,
  },
  {
    isDetail: true,
    id: 'profile',
    link: 'profile',
    roles: [],
    component: <Profile />,
  },
  {
    id: 'risk',
    link: 'risk',
    icon: <FaIcon icon={faRoadBarrier} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
    ],
    component: <Risk />,
  },
  {
    isDetail: true,
    id: 'riskDetail',
    link: 'risk/:riskId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
    ],
    component: <RiskDetail />,
  },
  {
    isDetail: true,
    id: 'riskVersionDetail',
    link: 'risk/:riskId/:riskVersionId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
    ],
    component: <RiskVersionDetail />,
  },
  {
    id: 'training',
    link: 'training',
    icon: <FaIcon icon={faLaptopCode} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <Training />,
  },
  {
    isDetail: true,
    id: 'trainingDetail',
    link: 'training/:trainingId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <TrainingDetail />,
  },
  {
    isDetail: true,
    id: 'trainingVersionDetail',
    link: 'training/:trainingId/:trainingVersionId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <TrainingVersionDetail />,
  },
  {
    isDetail: true,
    id: 'trainingSlides',
    link: 'training/:trainingId/:trainingVersionId/:languageCode',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <TrainingSlidesEditor />,
  },
  {
    id: 'questionnaire',
    link: 'questionnaire',
    icon: <FaIcon icon={faListCheck} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <Questionnaire />,
  },
  {
    isDetail: true,
    id: 'questionnaireDetail',
    link: 'questionnaire/:questionnaireId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <QuestionnaireDetail />,
  },
  {
    isDetail: true,
    id: 'questionnaireDetail',
    link: 'questionnaire/:questionnaireId/:questionnaireVersionId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <QuestionnaireVersionDetail />,
  },
  {
    isDetail: true,
    id: 'questionnaireQuestions',
    link: 'questionnaire/:questionnaireId/:questionnaireVersionId/:languageCode',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <QuestionnaireQuestions />,
  },
  {
    id: 'safetyEquipment',
    link: 'safety-equipment',
    icon: <FaIcon icon={faHelmetSafety} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <SafetyEquipment />,
  },
  {
    isDetail: true,
    id: 'safetyEquipmentDetail',
    link: 'safety-equipment/:safetyEquipmentId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <SafetyEquipmentDetail />,
  },
  {
    id: 'invitationTypeVisit',
    link: 'visit',
    icon: <FaIcon icon={faUserTie} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <InvitationTypeVisit />,
  },
  {
    isDetail: true,
    id: 'invitationTypeVisitDetail',
    link: 'visit/:supplierId/employee/:supplierEmployeeId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <InvitationTypeVisitDetail />,
  },
  {
    id: 'supplier',
    link: 'supplier',
    icon: <FaIcon icon={faTruckField} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <Supplier />,
  },
  {
    isDetail: true,
    id: 'supplierDetail',
    link: 'supplier/:supplierId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <SupplierDetail />,
  },
  {
    isDetail: true,
    id: 'supplierEmployeeDetail',
    link: 'supplier/:supplierId/employee/:supplierEmployeeId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <SupplierEmployeeDetail />,
  },
  {
    isDetail: true,
    id: 'SupplierEmployeeFromVisitorDetail',
    link: 'supplier/:supplierId/employee-from-visitor/:supplierEmployeeId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <SupplierEmployeeFromVisitorDetail />,
  },
  {
    id: 'supplierExternal',
    link: 'supplier',
    icon: <FaIcon icon={faTruckField} />,
    roles: [UserDtoRolesEnum.ExternalManager],
    component: <SupplierDetail />,
  },
  {
    isDetail: true,
    id: 'supplierExternalEmployeeDetail',
    link: 'supplier/employee/:supplierEmployeeId',
    roles: [UserDtoRolesEnum.ExternalManager],
    component: <SupplierEmployeeDetail />,
  },
  {
    id: 'activity',
    link: 'activity',
    icon: <FaIcon icon={faScrewdriverWrench} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <Activity />,
  },
  {
    isDetail: true,
    id: 'activityDetail',
    link: 'activity/:activityId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <ActivityDetail />,
  },
  {
    id: 'documents',
    link: 'documents',
    icon: <FaIcon icon={faAddressCard} />,
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <Documents />,
  },
  {
    isDetail: true,
    id: 'documentDetail',
    link: 'documents/:documentId',
    roles: [UserDtoRolesEnum.AdminQcs, UserDtoRolesEnum.ManagerOsah],
    component: <DocumentDetail />,
  },
  {
    id: 'emergency',
    link: 'emergency',
    icon: <FaIcon icon={faTriangleExclamation} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <Emergency />,
  },
  {
    isDetail: true,
    id: 'emergencyNew',
    link: 'emergency/new',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <EmergencyNew />,
  },
  {
    isDetail: true,
    id: 'emergencyDetail',
    link: 'emergency/:emergencyId',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <EmergencyDetail />,
  },
  {
    isDetail: true,
    id: 'emergencyDetail2',
    link: 'emergency/:emergencyId/:scrollTo',
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
      UserDtoRolesEnum.ManagerWorkplace,
      UserDtoRolesEnum.Receptionist,
    ],
    component: <EmergencyDetail />,
  },
  {
    id: 'settings',
    link: 'settings',
    icon: <FaIcon icon={faGear} />,
    roles: [
      UserDtoRolesEnum.AdminQcs,
      UserDtoRolesEnum.AdminCompany,
      UserDtoRolesEnum.ManagerOsah,
    ],
    component: <Settings />,
  },
  {
    id: 'qcsCompanySettings',
    link: 'qcscompany-settings',
    icon: <FaIcon icon={faGear} />,
    roles: [UserDtoRolesEnum.AdminQcs],
    component: <QcsCompanySettings />,
  },
  {
    id: 'training',
    link: 'training',
    icon: <FaIcon icon={faLaptopCode} />,
    roles: [
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <TrainingExternal />,
  },
  {
    id: 'pinExternal',
    link: 'pin',
    icon: <FaIcon icon={faUserLock} />,
    roles: [
      UserDtoRolesEnum.ExternalManager,
      UserDtoRolesEnum.ExternalWorker,
      UserDtoRolesEnum.ExternalOneTime,
    ],
    component: <PinExternal />,
  },
];

export const userMenuItems: MenuItem[] = [
  {
    id: 'logout',
    link: 'login',
    icon: <FaIcon icon={faArrowRightFromBracket} />,
    roles: [],
  },
];
