import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { supplierEmployeeApi } from '../../utils/api';

type State = BaseType<SupplierEmployeeDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    lastName: '',
    jobPosition: '',
    phone: '',
    email: '',
    language: '',
    note: '',
    role: SupplierEmployeeDtoRoleEnum.ExternalManager,
    state: SupplierEmployeeDtoStateEnum.Active,
  },
};

export const supplierEmployeeSlice = baseSlice({
  name: 'supplierEmployee',
  initialState,
});

export const selectSupplierEmployeeState = (state: RootState) =>
  state.supplierEmployee.state;
export const selectSupplierEmployee = (state: RootState) =>
  state.supplierEmployee.data;
export const supplierEmployeeActions = supplierEmployeeSlice.actions;

export const getSupplierEmployee =
  (supplierEmployeeId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return supplierEmployeeApi.getSupplierEmployee(supplierEmployeeId);
    };

    getItem({
      dispatch,
      actions: supplierEmployeeActions,
      getData: handleGetData,
    });
  };
