import React, { useState } from 'react';
import styled from '@emotion/styled';
import { LanguageDto } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { StyledQcsTable } from '../common/grid/Grid';
import {
  getTrainingVersion,
  selectTrainingVersion,
  selectTrainingVersionLanguages,
  selectTrainingVersionState,
} from '../../store/entities/trainingVersion';
import { AddLanguageModal } from './AddLanguageModal';
import { FetchState } from '../../store/fetchState';
import { QcsTableBody } from '../common/basic/QcsTableBody';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTableHead } from '../common/basic/QcsTableHead';
import { QcsTableRow } from '../common/basic/QcsTableRow';
import { QcsPaper } from '../common/basic/QcsPaper';
import { QcsTableContainer } from '../common/basic/QcsTableContainer';
import { GridEmpty } from '../common/grid/GridEmpty';
import { GridError } from '../common/grid/GridError';
import { GridLoading } from '../common/grid/GridLoading';
import { FaIcon } from '../common/FaIcon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LanguageDeleteModal } from './LanguageDeleteModal';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  marginBottom: '0.5rem',
}));

export const TrainingSlideLanguages: FC = () => {
  const { t } = useTranslation();
  const trainingVersionLanguages = useAppSelector(
    selectTrainingVersionLanguages
  );
  const trainingVersionState = useAppSelector(selectTrainingVersionState);
  const trainingVersion = useAppSelector(selectTrainingVersion);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trainingId, trainingVersionId } = useParams();
  const [adding, setAdding] = useState(false);
  const [languageToCopy, setLanguageToCopy] = useState<LanguageDto | null>(
    null
  );
  const [languageToDelete, setLanguageToDelete] = useState<string>('');

  const loadTrainingVersion = () => {
    dispatch(getTrainingVersion(trainingVersionId!));
  };

  const handleRowClick = (item: LanguageDto) => () => {
    navigate(`/training/${trainingId}/${trainingVersionId}/${item.code}`);
  };

  const handleAddClick = () => {
    setAdding(true);
  };

  const handleCloseAdding = () => {
    setAdding(false);
    setLanguageToCopy(null);
  };

  const handleAddVersionCopy =
    (language: LanguageDto) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setLanguageToCopy(language);
    };

  const deleteLanguage =
    (languageCode: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setLanguageToDelete(languageCode);
    };

  const handleCloseDeleteLanguageModal = () => {
    setLanguageToDelete('');
  };

  if (!trainingVersionLanguages) {
    return null;
  }

  return (
    <>
      <AddLanguageModal
        open={adding || !!languageToCopy}
        languageToCopy={languageToCopy}
        onClose={handleCloseAdding}
        reloadTrainingVersion={loadTrainingVersion}
      />
      <LanguageDeleteModal
        open={!!languageToDelete}
        languageCode={languageToDelete}
        onClose={handleCloseDeleteLanguageModal}
        reloadTrainingVersionList={loadTrainingVersion}
      />
      <HeaderContainer>
        <QcsTypography variant="h5">{t('training.slides')}</QcsTypography>
        <QcsButton
          disabled={!trainingVersion.editable}
          variant="contained"
          onClick={handleAddClick}
        >
          {t('training.addLanguage')}
        </QcsButton>
      </HeaderContainer>

      <QcsPaper>
        <QcsTableContainer>
          <StyledQcsTable>
            <QcsTableHead>
              <QcsTableRow>
                <QcsTableCell>{t('training.language')}</QcsTableCell>
                <QcsTableCell>{t('training.slidesCount')}</QcsTableCell>
                <QcsTableCell align="center" sx={{ width: '15rem' }}>
                  {t('training.copy.addLanguage')}
                </QcsTableCell>
                <QcsTableCell align="center" sx={{ width: '15rem' }}>
                  {t('training.deleteLanguage')}
                </QcsTableCell>
              </QcsTableRow>
            </QcsTableHead>
            <QcsTableBody>
              {trainingVersionState === FetchState.Loaded &&
                trainingVersionLanguages.map((item, index) => (
                  <QcsTableRow
                    key={index}
                    hover={true}
                    onClick={handleRowClick(item.language)}
                  >
                    <QcsTableCell>
                      <img
                        src={'/flags/' + item.language.flag + '.svg'}
                        height="20"
                      />{' '}
                      {item.language.name}
                    </QcsTableCell>
                    <QcsTableCell>
                      <QcsTypography>{item.slides}</QcsTypography>
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton
                        disabled={!trainingVersion.editable}
                        onClick={handleAddVersionCopy(item.language)}
                      >
                        <FaIcon icon={faCopy} />
                      </QcsButton>
                    </QcsTableCell>
                    <QcsTableCell align="center">
                      <QcsButton
                        disabled={!trainingVersion.editable}
                        onClick={deleteLanguage(item.language.code)}
                      >
                        <DeleteForeverIcon
                          color={
                            !trainingVersion.editable ? undefined : 'error'
                          }
                        />
                      </QcsButton>
                    </QcsTableCell>
                  </QcsTableRow>
                ))}
            </QcsTableBody>
          </StyledQcsTable>
          {trainingVersionState === FetchState.Loading ? (
            <GridLoading />
          ) : trainingVersionState === FetchState.Error ? (
            <GridError />
          ) : trainingVersionLanguages.length === 0 ? (
            <GridEmpty />
          ) : null}
        </QcsTableContainer>
      </QcsPaper>
    </>
  );
};
