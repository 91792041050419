import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  CertificateDto,
  SafetyEquipmentDtoStateEnum,
} from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { certificateApi } from '../../utils/api';

export interface DocumentListFilter extends ListFilter {
  state?: 'ACTIVE' | 'INACTIVE';
}

export type DocumentListState = ListType<CertificateDto, DocumentListFilter>;

const initialState: DocumentListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    state: SafetyEquipmentDtoStateEnum.Active,
    sortBy: 'name',
    sortDesc: false,
  },
};

export const documentListSlice = listSlice({
  name: 'documentList',
  initialState,
});

export const selectDocumentList = (state: RootState) => state.documentList;
export const documentListActions = documentListSlice.actions;

export const getDocumentList =
  (companyId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectDocumentList(storeState);

    const handleGetData = (size: number) => {
      return certificateApi.queryCertificates(
        companyId,
        data.query.q,
        data.query.state,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: documentListActions,
      getData: handleGetData,
    });
  };
