import { CompanyListResponse } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  qcsAdminCompanyListActions,
  getQcsAdminCompanyList,
  selectQcsAdminCompanyList,
} from '../../../store/entities/qcsAdminCompanyList';
import { setQcsAdminLastCompany } from '../../../utils/storage';
import { QcsLoadingButton } from '../../common/basic/QcsLoadingButton';
import { QcsTableCell } from '../../common/basic/QcsTableCell';
import { Grid } from '../../common/grid/Grid';
import { SectionHeader } from '../../common/SectionHeader';
import { QcsAdminCompanyFilter } from './QcsAdminCompanyFilter';
import { getFirstLastNameObj, getLangNameObj } from '../../../utils/format';

const COMPANY_URL = import.meta.env.VITE_COMPANY_URL as string;

export const QcsAdminCompany: FC = () => {
  const { t, i18n } = useTranslation();
  const qcsAdminCompanyList = useAppSelector(selectQcsAdminCompanyList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSwitchToCompany =
    (item: CompanyListResponse) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setQcsAdminLastCompany(item.id!);

      window.location.replace(
        COMPANY_URL.replaceAll('{subdomain}', item.subDomain ?? '')
      );
    };

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getQcsAdminCompanyList(cancelToken));
  };

  const handleRenderData = (item: CompanyListResponse) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.ico}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.vat}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getFirstLastNameObj(item.contact)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact?.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact?.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsLoadingButton
            variant="contained"
            onClick={handleSwitchToCompany(item)}
          >
            {t('admin.company.switch')}
          </QcsLoadingButton>
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: CompanyListResponse) => {
    navigate('/qcscompany/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/qcscompany/new');
  };

  return (
    <>
      <SectionHeader
        title="admin.company.title"
        addText="admin.company.add"
        handleAddClick={handleAddClick}
      />
      <Grid<CompanyListResponse>
        headers={[
          { captionStr: 'admin.company.name', orderName: 'name' },
          {
            captionStr: 'admin.company.ico',
            orderName: 'ico',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.vat',
            orderName: 'vat',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.contactName',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.contactEmail',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.contactPhone',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.state',
            orderName: 'state',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.company.action',
            sx: { textAlign: 'center' },
          },
        ]}
        data={qcsAdminCompanyList}
        gridActions={qcsAdminCompanyListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<QcsAdminCompanyFilter query={qcsAdminCompanyList.query} />}
      />
    </>
  );
};
