import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { QcsToggleButton } from '../common/basic/QcsToggleButton';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { GridFilterItemContainer } from '../common/grid/GridFilterStyles';
import {
  invitationListActions,
  InvitationListFilter,
} from '../../store/entities/invitationList';
import { FormContainer } from '../common/form/FormContainer';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { StyledQcsPopover } from '../common/grid/GridFilterItem';
import { InvitationDtoTypeEnum, ReferenceDto } from '@qcs/safety-client';
import { QcsAutocompleteWithoutFormik } from '../common/form/QcsAutocompleteWithoutFormik';
import { getLangNameObj } from '../../utils/format';
import { FetchState } from '../../store/fetchState';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  getOptionsSupplier,
  selectOptionsSupplier,
  selectOptionsSupplierState,
} from '../../store/entities/optionsSupplier';
import { CancelToken } from 'axios';

interface Props {
  query: InvitationListFilter;
}

export const InvitationFilterSupplier: FC<Props> = ({ query }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsSupplier = useAppSelector(selectOptionsSupplier);
  const optionsSupplierState = useAppSelector(selectOptionsSupplierState);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(getOptionsSupplier(identityCompanyId, search, cancelToken));
  };

  const handleChangeOneTimeOnly = () => {
    dispatch(
      invitationListActions.updateFilter(
        !!query.invitationTypes
          ? {
              invitationTypes: undefined,
            }
          : {
              invitationTypes: [
                InvitationDtoTypeEnum.OneTime,
                InvitationDtoTypeEnum.OneTimeEntry,
              ],
              supplier: undefined,
              supplierEmployee: undefined,
            }
      )
    );
  };

  const handleChange = (value?: ReferenceDto | ReferenceDto[] | null) => {
    dispatch(
      invitationListActions.updateFilter({
        supplier: (value as ReferenceDto) ?? undefined,
        supplierEmployee: undefined,
      })
    );
  };

  const handleClear = () => {
    dispatch(
      invitationListActions.updateFilter({
        invitationTypes: undefined,
        supplier: undefined,
        supplierEmployee: undefined,
      })
    );
  };

  const selected = !!query.invitationTypes || query.supplier !== undefined;

  return (
    <>
      <QcsToggleButton value="" selected={selected} onChange={handleOpenMenu}>
        {t('invitation.supplier')}
        <ArrowDropDownIcon />
      </QcsToggleButton>
      <StyledQcsPopover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <GridFilterItemContainer>
          <FormContainer>
            <QcsFormControlLabel
              control={
                <QcsCheckbox
                  checked={!!query.invitationTypes}
                  onChange={handleChangeOneTimeOnly}
                />
              }
              label={t('invitation.oneTimeFilter')}
            />
            <QcsAutocompleteWithoutFormik
              options={optionsSupplier}
              loading={optionsSupplierState === FetchState.Loading}
              loadingError={optionsSupplierState === FetchState.Error}
              loadOptions={loadOptions}
              value={query.supplier}
              getOptionId={(option: ReferenceDto) => option.id!}
              getOptionName={(option: ReferenceDto) =>
                getLangNameObj(i18n, option)
              }
              onChange={handleChange}
              disabled={!!query.invitationTypes}
            />
          </FormContainer>
        </GridFilterItemContainer>
        <GridFilterItemContainer>
          <QcsButton disabled={!selected} onClick={handleClear}>
            {t('common.clearFilter')}
          </QcsButton>
        </GridFilterItemContainer>
      </StyledQcsPopover>
    </>
  );
};
