import { FC } from 'react';
import { QcsTypography } from '../common/basic/QcsTypography';

interface Props {
  title: string;
  hasMarginBottom?: boolean;
}

export const TourniquetCaption: FC<Props> = ({ title, hasMarginBottom }) => {
  return (
    <QcsTypography
      variant="h4"
      sx={{ marginBottom: hasMarginBottom ? '1.5rem' : 0 }}
      align="center"
    >
      {title}
    </QcsTypography>
  );
};
