import {
  faPeopleGroup,
  faPerson,
  faPersonCircleCheck,
  faPersonCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  DashboardInvitationStatsResponse,
  ReferenceDto,
} from '@qcs/safety-client';
import { useFormikContext } from 'formik';
import { FC, useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getDashboard,
  selectDashboard,
  selectDashboardState,
} from '../../store/entities/dashboard';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { QcsTypography } from '../common/basic/QcsTypography';
import { ErrorAlert } from '../common/ErrorAlert';
import { Loader } from '../common/Loader';
import { DashboardNumber } from './DashboardNumber';
import { DashboardItemContainer } from './DashboardStyles';

export interface DashboardEstablishmentForm {
  establishment?: ReferenceDto;
}

export const DashboardContent: FC = () => {
  const { t } = useTranslation();
  const dashboard = useAppSelector(selectDashboard);
  const dashboardState = useAppSelector(selectDashboardState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const { values } = useFormikContext<DashboardEstablishmentForm>();

  useEffect(() => {
    dispatch(getDashboard(identityCompanyId, values.establishment?.id));
    setLoading(false);
  }, [dispatch, identityCompanyId, values.establishment?.id]);

  const getData = (
    stats?: DashboardInvitationStatsResponse,
    allowedToEnter?: number
  ) => {
    const data = {
      labels: [
        t('dashboard.inProgress'),
        t('dashboard.sent'),
        t('dashboard.viewed'),
        t('dashboard.partiallyFilled'),
        t('dashboard.approved'),
        t('dashboard.allowedToEnter'),
      ],
      datasets: [
        {
          data: [
            stats?.inProgress ?? 0,
            stats?.sent ?? 0,
            stats?.viewed ?? 0,
            stats?.partiallyFilled ?? 0,
            stats?.approved ?? 0,
            allowedToEnter ?? 0,
          ],
          backgroundColor: [
            '#ff6384',
            '#ff9f40',
            '#ffcd56',
            '#4bc0c0',
            '#36a2eb',
            '#9966ff',
          ],
        },
      ],
    };

    return data;
  };

  if (loading || dashboardState === FetchState.Loading) {
    return <Loader />;
  }

  if (dashboardState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const companyData = getData(
    dashboard.companyStats,
    dashboard.allowedToEnterCompany
  );
  const establishmentData = getData(
    dashboard.establishmentStats,
    dashboard.allowedToEnter
  );

  return (
    <>
      <DashboardItemContainer sx={{ gridArea: 'company' }}>
        <QcsTypography variant="h6">
          {t('dashboard.company')} - {t('dashboard.invitation')}
        </QcsTypography>
        <Pie
          data={companyData}
          options={{ plugins: { legend: { position: 'bottom' } } }}
        />
      </DashboardItemContainer>
      <DashboardItemContainer sx={{ gridArea: 'establishment' }}>
        <QcsTypography variant="h6">
          {t('dashboard.establishment')} - {t('dashboard.invitation')}
        </QcsTypography>
        <Pie
          data={establishmentData}
          options={{ plugins: { legend: { position: 'bottom' } } }}
        />
      </DashboardItemContainer>
      <DashboardNumber
        name="myPersonsOnPremises"
        value={dashboard.myPersonsOnPremises}
        icon={faPerson}
        color="#ff6384"
      />
      <DashboardNumber
        name="personsOnPremises"
        value={dashboard.personsOnPremises}
        icon={faPeopleGroup}
        color="#ff9f40"
      />
      <DashboardNumber
        name="totalWorkers"
        value={dashboard.totalWorkers}
        icon={faPersonCircleCheck}
        color="#ffcd56"
      />
      <DashboardNumber
        name="validInvitations"
        value={dashboard.validInvitations}
        icon={faPersonCircleExclamation}
        color="#4bc0c0"
      />
    </>
  );
};
