import { FC, Fragment } from 'react';

interface Props {
  text: string;
}

export const RepairLineBreaks: FC<Props> = ({ text }) => (
  <>
    {text
      .replaceAll('\r\n', '\n')
      .replaceAll('\r', '\n')
      .split('\n')
      .map((x, i) => (
        <Fragment key={i}>
          {x}
          <br />
        </Fragment>
      ))}
  </>
);
