import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { WorkplaceDto, WorkplaceDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { workplaceApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<WorkplaceDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsWorkplaceSlice = baseSlice({
  name: 'optionsWorkplace',
  initialState,
});

export const selectOptionsWorkplaceState = (state: RootState) =>
  state.optionsWorkplace.state;
export const selectOptionsWorkplace = (state: RootState) =>
  state.optionsWorkplace.data;
export const optionsWorkplaceActions = optionsWorkplaceSlice.actions;

export const getOptionsWorkplace =
  (
    companyId: string,
    search: string | undefined,
    estabilishments: string[] | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await workplaceApi.queryWorkplace(
        companyId,
        search,
        WorkplaceDtoStateEnum.Active,
        true,
        estabilishments,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsWorkplaceActions,
      getData: handleGetData,
    });
  };
