import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import {
  getOptionsActivity,
  selectOptionsActivity,
  selectOptionsActivityState,
} from '../../../store/entities/optionsActivity';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

interface Props {
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  oneTimeEntry?: boolean;
  excludeIds?: string[];
}

export const ActivityAutocomplete: FC<Props> = ({
  multiple,
  name,
  label,
  disabled,
  required,
  oneTimeEntry,
  excludeIds,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsActivity = useAppSelector(selectOptionsActivity);
  const optionsActivityState = useAppSelector(selectOptionsActivityState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsActivity(identityCompanyId, oneTimeEntry, search, cancelToken)
    );
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={
        excludeIds
          ? optionsActivity.filter((x) => !excludeIds?.includes(x.id!))
          : optionsActivity
      }
      loading={optionsActivityState === FetchState.Loading}
      loadingError={optionsActivityState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
    />
  );
};
