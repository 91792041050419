import { FC } from 'react';
import { TourniquetTraining } from '../tourniquet/TourniquetTraining';
import { useInvitation } from './Detail/Tabs/invitationFunctions';
import { tourniquetTrainingActions } from '../../store/entities/tourniquetTraining';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';

export const InvitationPresentation: FC = () => {
  const { lockInvitation, reloadInvitation, unlockInvitation, dispatch } =
    useInvitation();

  const handleEndTraining = async () => {
    dispatch(tourniquetTrainingActions.default());
    dispatch(tourniquetTrainingsActions.default());

    lockInvitation();
    try {
      await reloadInvitation();
    } finally {
      unlockInvitation();
    }
  };

  return <TourniquetTraining onEndTraining={handleEndTraining} />;
};
