import { InvitedPersonDto } from '@qcs/safety-client';
import { FC, useEffect } from 'react';
import { invitationsApi } from '../../../../utils/api';
import { setErrorStateSnacks } from '../../../../utils/error';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsDialog } from '../../../common/basic/QcsDialog';
import { QcsDialogActions } from '../../../common/basic/QcsDialogActions';
import { DialogContent as QcsDialogContent } from '@mui/material';

import { QcsDialogContentText } from '../../../common/basic/QcsDialogContentText';
import { DialogTitle as QcsDialogTitle } from '@mui/material';

import { RepairLineBreaks } from '../../../common/RepairLineBreaks';
import { SaveError } from '../../../common/SaveError';
import { useInvitation } from './invitationFunctions';

interface Props {
  data?: InvitedPersonDto;
  onClose: () => void;
}

export const InvitedPersonsOverride: FC<Props> = ({ data, onClose }) => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
    saveError,
    setSaveError,
  } = useInvitation();

  useEffect(() => {
    setSaveError('');
  }, [data, setSaveError]);

  const handleConfirm = async () => {
    setIsSaving(true);
    setSaveError('');
    lockInvitation();
    try {
      await invitationsApi.overridePersonRequirements(
        invitation.id!,
        data!.id!
      );
      await reloadInvitation();

      enqueueSuccessSnackbar(t('common.editSuccess'));
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.editError'
      );
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsDialog open={!!data} onClose={onClose}>
      <QcsDialogTitle>{t('invitation.persons.overrideTitle')}</QcsDialogTitle>
      <QcsDialogContent>
        <QcsDialogContentText>
          <RepairLineBreaks text={t('invitation.persons.overrideMessage')} />
        </QcsDialogContentText>
        <SaveError error={saveError} />
      </QcsDialogContent>
      <QcsDialogActions>
        <QcsButton onClick={onClose} disabled={isSaving}>
          {t('common.back')}
        </QcsButton>
        <QcsButton onClick={handleConfirm} disabled={isSaving}>
          {t('invitation.persons.overrideConfirm')}
        </QcsButton>
      </QcsDialogActions>
    </QcsDialog>
  );
};
