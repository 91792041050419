import styled from '@emotion/styled';
import { Search } from '../Search';

export const GridContainer = styled.div(({}) => ({
  width: '100%',
}));

export const GridToolbarContainer = styled.div(({}) => ({
  display: 'flex',
  marginBottom: '0.5rem',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const StyledSearch = styled(Search)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginLeft: 'auto',
  },
}));
