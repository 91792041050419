import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TrainingVersionDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getTrainingVersionList,
  selectTrainingVersionList,
  trainingVersionListActions,
} from '../../store/entities/trainingVersionList';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTypography } from '../common/basic/QcsTypography';
import { CellDate } from '../common/grid/CellDate';
import { Grid } from '../common/grid/Grid';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { FaIcon } from '../common/FaIcon';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CopyVersionModal } from './CopyVersionModal';
import { CopyVersionToTrainingModal } from './CopyVersionToTrainingModal';
import { trainingListActions } from '../../store/entities/trainingList';
import { TrainingVersionDeleteModal } from './TrainingVersionDeleteModal';
import { useTheme } from '@mui/material';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
}));

export const TrainingVersion: FC = () => {
  const { t } = useTranslation();
  const trainingVersionList = useAppSelector(selectTrainingVersionList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { trainingId } = useParams();
  const [versionToCopy, setVersionToCopy] = useState<TrainingVersionDto | null>(
    null
  );
  const [versionToCopyToTraining, setVersionToCopyToTraining] =
    useState<TrainingVersionDto | null>(null);
  const [trainingVersionToDelete, setTrainingVersionToDelete] =
    useState<string>('');

  useEffect(() => {
    dispatch(trainingVersionListActions.reload(true));
  }, [dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getTrainingVersionList(trainingId!, cancelToken));
  };

  const handleDeleteVersion =
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setTrainingVersionToDelete(id);
    };

  const handleAddVersionCopy =
    (version: TrainingVersionDto) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setVersionToCopy(version);
    };

  const handleAddVersionCopyToTraining =
    (version: TrainingVersionDto) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setVersionToCopyToTraining(version);
    };

  const handleRenderData = (item: TrainingVersionDto) => {
    return (
      <>
        <QcsTableCell>
          {item.active && (
            <FaIcon icon={faCalendarCheck} color={theme.palette.primary.main} />
          )}
        </QcsTableCell>
        <CellDate value={item.validFrom} />
        <CellDate value={item.validTo} />
        <QcsTableCell align="center">
          <QcsButton onClick={handleAddVersionCopy(item)}>
            <FaIcon icon={faCopy} />
          </QcsButton>
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsButton onClick={handleAddVersionCopyToTraining(item)}>
            <FaIcon icon={faCopy} />
          </QcsButton>
        </QcsTableCell>
        <QcsTableCell align="center">
          <QcsButton
            disabled={!item.editable}
            onClick={handleDeleteVersion(item.id!)}
          >
            <DeleteForeverIcon color={!item.editable ? undefined : 'error'} />
          </QcsButton>
        </QcsTableCell>
      </>
    );
  };

  const handleReloadTrainingVersionList = () => {
    dispatch(trainingVersionListActions.reload(true));
    dispatch(trainingListActions.reload(true));
  };

  const handleCloseDeleteVersionModal = () => {
    setTrainingVersionToDelete('');
  };

  const handleRowClick = (item: TrainingVersionDto) => {
    navigate(`/training/${trainingId}/${item.id}`);
  };

  const handleAddClick = () => {
    navigate(`/training/${trainingId}/new`);
  };

  return (
    <>
      <CopyVersionModal
        versionToCopy={versionToCopy}
        onClose={() => setVersionToCopy(null)}
        reloadTrainingVersionList={handleReloadTrainingVersionList}
      />
      <CopyVersionToTrainingModal
        versionToCopy={versionToCopyToTraining}
        onClose={() => setVersionToCopyToTraining(null)}
        reloadTrainingVersionList={handleReloadTrainingVersionList}
      />
      <TrainingVersionDeleteModal
        open={!!trainingVersionToDelete}
        onClose={handleCloseDeleteVersionModal}
        trainingVersionId={trainingVersionToDelete}
        reloadTrainingList={handleReloadTrainingVersionList!}
      />
      <HeaderContainer>
        <QcsTypography variant="h5">{t('training.version')}</QcsTypography>
        <QcsButton variant="contained" onClick={handleAddClick}>
          {t('training.addVersion')}
        </QcsButton>
      </HeaderContainer>
      <Grid<TrainingVersionDto>
        headers={[
          { sx: { width: '56px' } },
          { captionStr: 'training.validFrom', orderName: 'validFrom' },
          { captionStr: 'training.validTo', orderName: 'validTo' },
          {
            captionStr: 'training.copy.addVersion',
            sx: { textAlign: 'center' },
          },
          {
            captionStr: 'training.copy.versionToTraining',
            sx: { textAlign: 'center' },
          },
          {
            captionStr: 'training.deleteVersion',
            sx: { textAlign: 'center' },
          },
        ]}
        data={trainingVersionList}
        gridActions={trainingVersionListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        search={false}
      />
    </>
  );
};
