import { useFormikContext } from 'formik';
import { FC } from 'react';
import { QcsTextField } from '../basic/QcsTextField';
import _ from 'lodash';
import { useTheme } from '@mui/material';

interface Props {
  className?: string;
  fullWidth?: boolean;
  required?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  name: string;
  label?: React.ReactNode;
  maxLength?: number;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
}

export const Input: FC<Props> = ({
  className,
  fullWidth,
  required,
  type,
  name,
  label,
  maxLength,
  multiline,
  rows,
  disabled,
  min,
  max,
  inputRef,
  autoFocus,
}) => {
  const { values, errors, touched, submitCount, handleChange, handleBlur } =
    useFormikContext<any>();

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const theme = useTheme();

  //Disable change value by wheel on number inputs.
  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (type !== 'number') {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    (e.target as any).blur();
  };

  return (
    <QcsTextField
      className={className}
      required={required}
      sx={{ backgroundColor: theme.palette.common.white }}
      fullWidth={fullWidth}
      id={name}
      type={type}
      name={name}
      label={label}
      value={value === null || value === undefined ? '' : value}
      onChange={handleChange}
      onBlur={handleBlur}
      onWheel={handleWheel}
      error={wasTouched && hasError}
      helperText={wasTouched && errorText}
      inputProps={{ maxLength, min, max }}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      inputRef={inputRef}
      autoFocus={autoFocus}
    />
  );
};
