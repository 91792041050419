import { FC, useEffect, useState } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { VisitationAddModalPin } from './VisitationAddModalPin';
import { VisitationAddModalOneTime } from './VisitationAddModalOneTime';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const VisitationAddModal: FC<Props> = ({ open, onClose }) => {
  const [isOneTime, setIsOneTime] = useState(false);

  useEffect(() => {
    setIsOneTime(false);
  }, [open]);

  const handleOneTimeClick = () => {
    setIsOneTime(true);
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        {isOneTime ? (
          <VisitationAddModalOneTime open={open} />
        ) : (
          <VisitationAddModalPin
            open={open}
            onClose={onClose}
            onOneTimeClick={handleOneTimeClick}
          />
        )}
      </QcsModalBox>
    </QcsModal>
  );
};
