import { InvitationDtoStateEnum, UserDtoRolesEnum } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store';
import { selectIdentity } from '../../../store/entities/identity';
import { hasOtherRoleThan } from '../../../utils/roles';
import { GridFilterSelectMultiple } from './GridFilterSelectMultiple';

interface Props {
  value?: InvitationDtoStateEnum[];
  onChange: (value?: InvitationDtoStateEnum[]) => void;
}

export const GridFilterInvitationState: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const identity = useAppSelector(selectIdentity);

  const isNotExternalManager = hasOtherRoleThan(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
  ]);

  const options = Object.values(InvitationDtoStateEnum).filter(
    (x) => isNotExternalManager || x !== InvitationDtoStateEnum.InProgress
  );

  return (
    <GridFilterSelectMultiple
      value={value ?? []}
      options={options}
      onChange={onChange}
      getOptionId={(option: InvitationDtoStateEnum) => option}
      getOptionName={(option: InvitationDtoStateEnum) =>
        t(`invitation.states.${option}`)
      }
    />
  );
};
