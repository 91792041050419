import { ActivityDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  activityListActions,
  getActivityList,
  selectActivityList,
} from '../../store/entities/activityList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { getLangNameObj } from '../../utils/format';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTooltipContent } from '../common/basic/QcsTooltipContent';
import { QcsTypography } from '../common/basic/QcsTypography';
import { CellYesNo } from '../common/grid/CellYesNo';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { ActivityFilter } from './ActivityFilter';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

export const Activity: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const activityList = useAppSelector(selectActivityList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getActivityList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: ActivityDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        {companyCustomization?.passRisksEnabled && (
          <CellYesNo value={item.riskRequired} hideOnMobile={true} />
        )}
        <CellYesNo
          value={item.certificates ? item.certificates.length !== 0 : false}
          hideOnMobile={true}
        />
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: ActivityDto) => {
    navigate('/activity/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/activity/new');
  };

  const handleGetRowTooltip = (item: ActivityDto) => {
    if (!item.note) return '';
    return (
      <QcsTooltipContent>
        <QcsTypography variant="h6">{t('activity.note')}</QcsTypography>
        <QcsTypography variant="body2">{item.note}</QcsTypography>
      </QcsTooltipContent>
    );
  };

  return (
    <>
      <SectionHeader
        title="activity.title"
        addText="activity.add"
        handleAddClick={handleAddClick}
      />
      <Grid<ActivityDto>
        headers={[
          { captionStr: 'activity.name', orderName: 'name' },
          companyCustomization?.passRisksEnabled
            ? {
                captionStr: 'activity.riskRequired',
                orderName: 'riskRequired',
                hideOnMobile: true,
              }
            : undefined,
          {
            captionStr: 'activity.certificateRequired',
            orderName: 'certificateRequired',
            hideOnMobile: true,
          },
          {
            captionStr: 'activity.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={activityList}
        gridActions={activityListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        getRowTooltip={handleGetRowTooltip}
        filter={<ActivityFilter query={activityList.query} />}
      />
    </>
  );
};
