import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { RiskVersionDto } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { riskApi } from '../../utils/api';

export type RiskVersionListState = ListType<RiskVersionDto, ListFilter>;

const initialState: RiskVersionListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'validFrom',
    sortDesc: false,
  },
};

export const riskVersionListSlice = listSlice({
  name: 'riskVersionList',
  initialState,
});

export const selectRiskVersionList = (state: RootState) =>
  state.riskVersionList;
export const riskVersionListActions = riskVersionListSlice.actions;

export const getRiskVersionList =
  (riskId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectRiskVersionList(storeState);

    const handleGetData = (size: number) => {
      return riskApi.getRiskVersions(
        riskId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: riskVersionListActions,
      getData: handleGetData,
    });
  };
