import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import {
  ReferenceDto,
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
} from '@qcs/safety-client';
import {
  getOptionsSupplierEmployee,
  optionsSupplierEmployeeActions,
  selectOptionsSupplierEmployee,
  selectOptionsSupplierEmployeeState,
} from '../../../store/entities/optionsSupplierEmployee';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import {
  getFirstLastNameObj,
  getFirstLastNameSupplierEmployee,
} from '../../../utils/format';
import { SUPPLIER_VISIT_FAKE_ID } from '../../../utils/constants';
import {
  getOptionsInvitationTypeVisit,
  optionsInvitationTypeVisitActions,
  selectOptionsInvitationTypeVisit,
  selectOptionsInvitationTypeVisitState,
} from '../../../store/entities/optionsInvitationTypeVisit';
import { selectIdentityCompanyId } from '../../../store/entities/identity';

interface Props {
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  supplierId?: string;
  role?: SupplierEmployeeDtoRoleEnum;
  state?: SupplierEmployeeDtoStateEnum[];
  excludeIds?: string[];
}

export const SupplierEmployeeAutocomplete: FC<Props> = ({
  multiple,
  name,
  label,
  disabled,
  required,
  supplierId,
  role,
  state,
  excludeIds: filterIds,
}) => {
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsSupplierEmployee = useAppSelector(selectOptionsSupplierEmployee);
  const optionsSupplierEmployeeState = useAppSelector(
    selectOptionsSupplierEmployeeState
  );
  const optionsInvitationTypeVisit = useAppSelector(
    selectOptionsInvitationTypeVisit
  );
  const optionsInvitationTypeVisitState = useAppSelector(
    selectOptionsInvitationTypeVisitState
  );

  const isVisit = supplierId === SUPPLIER_VISIT_FAKE_ID;
  const loadState = isVisit
    ? optionsInvitationTypeVisitState
    : optionsSupplierEmployeeState;
  const options: ReferenceDto[] = isVisit
    ? optionsInvitationTypeVisit
    : (optionsSupplierEmployee as ReferenceDto[]);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    if (!supplierId) {
      dispatch(optionsSupplierEmployeeActions.default());
      dispatch(optionsInvitationTypeVisitActions.default());
    } else if (isVisit) {
      dispatch(
        getOptionsInvitationTypeVisit(identityCompanyId, search, cancelToken)
      );
    } else {
      dispatch(
        getOptionsSupplierEmployee(supplierId, search, role, state, cancelToken)
      );
    }
  };

  const handleGetValueIfNotFound = (option: ReferenceDto) =>
    isVisit
      ? option
      : (getFirstLastNameSupplierEmployee(
          option as SupplierEmployeeDto
        ) as ReferenceDto);

  return (
    <QcsAutocomplete<ReferenceDto>
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={
        filterIds ? options.filter((x) => !filterIds?.includes(x.id!)) : options
      }
      loading={loadState === FetchState.Loading}
      loadingError={loadState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option) => option.id!}
      getOptionName={getFirstLastNameObj}
      getValueIfNotFound={handleGetValueIfNotFound}
    />
  );
};
