import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { InvitationCreateForm } from '../../models/invitation';

interface Props {
  type: 'SAVE' | 'SEND' | 'DELETE';
  showAlways?: boolean;
  customEvent?: () => Promise<void>;
}

export const InvitationButton: FC<Props> = ({
  type,
  showAlways,
  customEvent,
}) => {
  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue, submitForm, setSubmitting } =
    useFormikContext<InvitationCreateForm>();

  const handleClick = async () => {
    if (customEvent) {
      setSubmitting(true);
      try {
        await customEvent();
      } finally {
        setSubmitting(false);
      }

      return;
    }

    setFieldValue('mode', type);
    try {
      await submitForm();
    } finally {
      setFieldValue('mode', undefined);
    }
  };

  if (!values.inProgress && !showAlways) {
    return <div />;
  }

  return (
    <QcsLoadingButton
      variant={type === 'SEND' ? 'contained' : 'outlined'}
      color={type === 'DELETE' ? 'error' : 'primary'}
      disabled={isSubmitting}
      loading={isSubmitting}
      onClick={handleClick}
    >
      {t(
        type === 'SAVE'
          ? 'common.save'
          : type === 'SEND'
          ? 'common.send'
          : 'common.delete'
      )}
    </QcsLoadingButton>
  );
};
