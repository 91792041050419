import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import { UserDto, UserDtoStateEnum } from '@qcs/safety-client';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { qcUserApi } from '../../utils/api';

export interface QcsAdminUserListFilter extends ListFilter {
  state?: UserDtoStateEnum[];
}

export type QcsAdminUserListState = ListType<UserDto, QcsAdminUserListFilter>;

const initialState: QcsAdminUserListState = {
  state: FetchState.None,
  data: undefined,
  query: {
    sortBy: 'name',
    sortDesc: false,
    state: [UserDtoStateEnum.Active],
  },
};

export const qcsAdminUserListSlice = listSlice({
  name: 'qcsAdminUserList',
  initialState,
});

export const selectQcsAdminUserList = (state: RootState) =>
  state.qcsAdminUserList;
export const qcsAdminUserListActions = qcsAdminUserListSlice.actions;

export const getQcsAdminUserList =
  (cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectQcsAdminUserList(storeState);

    const handleGetData = (size: number) => {
      return qcUserApi.queryQcsCoreUsers(
        data.query.q,
        data.query.state,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: qcsAdminUserListActions,
      getData: handleGetData,
    });
  };
