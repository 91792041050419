import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsLoadingButton } from '../basic/QcsLoadingButton';

interface StyledLoadingButtonProps {
  ignoreMarginLeft?: boolean;
}

export const StyledLoadingButton = styled(QcsLoadingButton)<StyledLoadingButtonProps>(({ theme, ignoreMarginLeft }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'fit-content',
    marginLeft: ignoreMarginLeft ? '0' : 'auto',
  },
}));

interface Props {
  className?: string;
  disabled?: boolean;
  text?: string | null;
  ignoreMarginLeft?: boolean;
  onClick?: () => void;
}

export const SubmitButton: FC<Props> = ({ className, disabled, text, ignoreMarginLeft, onClick }) => {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  return (
    <StyledLoadingButton
      className={className}
      variant="contained"
      type="submit"
      disabled={disabled || isSubmitting}
      loading={isSubmitting}
      ignoreMarginLeft={ignoreMarginLeft}
      onClick={onClick}
    >
      {t(text ?? 'common.save')}
    </StyledLoadingButton>
  );
};
