import { InvitationDto } from '@qcs/safety-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirstLastNameObj, joinStrings } from '../../utils/format';
import { QcsTypography } from '../common/basic/QcsTypography';
import { TourniquetInvitationContainer } from './TourniquetInvitationContainer';

interface Props {
  invitation: InvitationDto;
}

export const TourniquetInvitationMyCompany: FC<Props> = ({ invitation }) => {
  const { t } = useTranslation();

  const m1 = t('tourniquet.issueMyCompany1')?.split('{0}');
  const m2 = t('tourniquet.issueMyCompany2')?.split('{0}');

  return (
    <TourniquetInvitationContainer icon="error">
      <QcsTypography>
        {m1?.[0]}
        <strong>
          {joinStrings(
            getFirstLastNameObj(invitation.responsiblePerson),
            invitation.responsiblePerson?.phone,
            invitation.responsiblePerson?.email
          )}
        </strong>
        {m1?.[1]}
      </QcsTypography>
      <QcsTypography>
        {m2?.[0]}
        <strong>
          {joinStrings(
            getFirstLastNameObj(invitation.employee),
            invitation.employee?.phone,
            invitation.employee?.email
          )}
        </strong>
        {m2?.[1]}
      </QcsTypography>
    </TourniquetInvitationContainer>
  );
};
