import { useMemo, useEffect } from 'react';
import { useAppSelector } from '../store';
import { selectCompanyCustomization } from '../store/entities/companyCustomization';
import { selectSubdomainCustomization } from '../store/entities/subdomainCustomization';
import { BASE_URL_WITH_VERSION } from '../utils/api';
import { generateManifest } from '../utils/pwa';
import Logo from '../images/logo.svg';
import { selectIdentity } from '../store/entities/identity';

export default function usePwaManifest() {
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const subdomainCustomization = useAppSelector(selectSubdomainCustomization);

  const logoSrc = useMemo(() => {
    if (companyCustomization?.bannerUrl) {
      return `${BASE_URL_WITH_VERSION}${companyCustomization.bannerUrl}`;
    }

    if (subdomainCustomization) {
      return subdomainCustomization;
    }

    return Logo;
  }, [companyCustomization?.bannerUrl, subdomainCustomization]);

  useEffect(() => {
    if (!identity?.id || !logoSrc) {
      return;
    }

    const manifest = generateManifest(identity?.id, logoSrc, 'S4E');

    const blob = new Blob([JSON.stringify(manifest)], {
      type: 'application/json',
    });

    const manifestURL = URL.createObjectURL(blob);
    const link: HTMLLinkElement =
      document.querySelector('link[rel="manifest"]') ||
      document.createElement('link');
    link.rel = 'manifest';
    link.href = manifestURL;

    document.head.appendChild(link);

    return () => {
      URL.revokeObjectURL(manifestURL);
    };
  }, [companyCustomization?.companyName, identity, logoSrc]);
  return null;
}
