import { InvitedPersonDto } from '@qcs/safety-client';
import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { setErrorSnacks } from '../../../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { invitationsApi } from '../../../../utils/api';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { useInvitation } from './invitationFunctions';

interface Props {
  personToDelete?: InvitedPersonDto;
  onClose: () => void;
}

export const InvitedPersonsDeleteModal: FC<Props> = ({
  personToDelete,
  onClose,
}) => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const confirmDelete = async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.deletePerson(invitation.id!, personToDelete!.id!);
      await reloadInvitation();

      enqueueSuccessSnackbar(t('invitation.persons.deleteSuccess'));
      onClose();
    } catch (err) {
      setErrorSnacks(
        err,
        enqueueErrorSnackbar,
        'invitation.persons.deleteError'
      );
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsModal open={!!personToDelete} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={t('invitation.tabs.askDeletePerson')} />
        <QcsBox sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <QcsLoadingButton
            loading={isSaving}
            variant="contained"
            onClick={confirmDelete}
          >
            {t('common.yes')}
          </QcsLoadingButton>
          <QcsLoadingButton
            loading={isSaving}
            variant="outlined"
            onClick={onClose}
          >
            {t('common.no')}
          </QcsLoadingButton>
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
