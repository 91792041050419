import { FC } from 'react';
import {
  qcsAdminUserListActions,
  QcsAdminUserListFilter,
} from '../../../store/entities/qcsAdminUserList';
import { GridFilterUserState } from '../../common/grid/GridFilterUserState';
import { GridFilterItem } from '../../common/grid/GridFilterItem';

interface Props {
  query: QcsAdminUserListFilter;
}

export const QcsAdminUserFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={qcsAdminUserListActions}
        name="state"
        title="admin.user.state"
        Component={GridFilterUserState}
        disableAutoClose
      />
    </>
  );
};
