import { Form, useFormikContext } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SubmitButton } from '../common/form/SubmitButton';
import { InvitationCreateForm } from '../../models/invitation';
import { SaveError } from '../common/SaveError';
import { FormGroup } from '../common/form/FormGroup';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { Input } from '../common/form/Input';
import { aresApi } from '../../utils/api';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { PhoneInput } from '../common/form/PhoneInput';
import { setErrorState } from '../../utils/error';
import { InvitationButton } from './InvitationButton';
import { InvitationButtonsContainer } from './InvitationButtonsContainer';
import { ErrorStateType } from '../../models/common';
import { SupplierEmployeeDtoRoleEnum } from '@qcs/safety-client';
import { QcsTypography } from '../common/basic/QcsTypography';

interface Props {
  invitationData: InvitationCreateForm;
  handleGoBack: () => void;
  handleSubmit: (data: InvitationCreateForm) => void;
  onDelete: () => Promise<void>;
}

const FormContent: FC<Pick<Props, 'handleGoBack' | 'onDelete'>> = ({
  handleGoBack,
  onDelete,
}) => {
  const { t } = useTranslation();
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const [aresLoading, setAresLoading] = useState(false);
  const [aresError, setAresError] = useState<ErrorStateType>();
  const { values, setFieldValue } = useFormikContext<InvitationCreateForm>();

  const handleLoadFromAres = async () => {
    setAresLoading(true);
    setAresError('');
    try {
      const res = await aresApi.fetchSubjectDetailsFromAres(
        values.supplierB!.ico!
      );
      // filter out null values
      const data = Object.fromEntries(
        Object.entries(res.data).filter(([, value]) => value !== null)
      );
      setFieldValue('supplierB', { ...values.supplierB, ...data });
    } catch (err) {
      setErrorState(err, setAresError, 'admin.company.aresError');
    }
    setAresLoading(false);
  };

  return (
    <div>
      <Form>
        <FormContainer>
          <QcsBox
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'auto',
                sm: 'auto max-content',
              },
              gridTemplateRows: { xs: 'auto auto', sm: 'auto' },
              rowGap: { xs: '1rem', sm: 0 },
              columnGap: { xs: 0, sm: '1rem' },
            }}
          >
            <Input
              name="supplierB.ico"
              label={t('supplier.ico')}
              maxLength={14}
              required
            />
            <QcsLoadingButton
              variant="contained"
              onClick={handleLoadFromAres}
              disabled={!values.supplierB?.ico}
              loading={aresLoading}
              sx={{ height: '3.5rem' }}
            >
              {t('admin.company.loadFromAres')}
            </QcsLoadingButton>
          </QcsBox>
          <SaveError error={aresError} />

          <Input
            name="supplierB.name"
            label={t('supplier.name')}
            maxLength={100}
            required
          />
          <Input
            name="supplierB.vat"
            label={t('supplier.vat')}
            maxLength={14}
          />

          <FormGroup name="address.title">
            <Input
              name="supplierB.address.street"
              label={t('address.street')}
              maxLength={100}
              required
            />
            <Input
              name="supplierB.address.city"
              label={t('address.city')}
              maxLength={100}
              required
            />
            <Input
              name="supplierB.address.postalCode"
              label={t('address.postalCode')}
              maxLength={128}
              required
            />
            <Input
              name="supplierB.address.country"
              label={t('address.country')}
              maxLength={100}
              required
            />
          </FormGroup>

          <Input
            name="supplierB.note"
            label={t('supplier.note')}
            maxLength={250}
            multiline={true}
            rows={4}
          />

          <FormGroup name="invitation.responsibleSupplierPerson">
            <QcsTypography variant="subtitle1">
              {'(' +
                t('user.role.' + SupplierEmployeeDtoRoleEnum.ExternalManager) +
                ')'}
            </QcsTypography>
            <Input
              name="responsiblePersonB.name"
              label={t('user.name')}
              maxLength={100}
              required
            />
            <Input
              name="responsiblePersonB.lastName"
              label={t('user.lastName')}
              maxLength={100}
              required
            />
            <Input
              name="responsiblePersonB.jobPosition"
              label={t('user.jobPosition')}
              maxLength={100}
            />
            <PhoneInput
              name="responsiblePersonB.phone"
              label={t('user.phone')}
            />
            <Input
              name="responsiblePersonB.email"
              label={t('user.email')}
              maxLength={100}
              required
            />
            <LanguageSelect
              name="responsiblePersonB.language"
              label={t('user.language')}
              languages={SUPPORTED_LANGUAGES}
              allowedLanguages={companyCustomization?.applicationLanguages}
              required
            />
          </FormGroup>

          <InvitationButtonsContainer>
            <QcsButton variant="outlined" onClick={handleGoBack}>
              {t('common.back')}
            </QcsButton>
            <div style={{ width: '100%' }} />
            <InvitationButton type="DELETE" customEvent={onDelete} />
            <InvitationButton type="SAVE" />
            <SubmitButton text={t('common.nextStep')} />
          </InvitationButtonsContainer>
        </FormContainer>
      </Form>
    </div>
  );
};

export const InvitationCreateStep4B: FC<Props> = ({
  invitationData,
  handleGoBack,
  handleSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <FormikDebounce<InvitationCreateForm>
      initialValues={invitationData}
      validationSchema={Yup.object().shape({
        supplierB: Yup.object({
          name: validations.stringRequired(t),
          ico: validations.companyNoRequired(t),
          vat: validations.companyVatNoOptional(t),
          address: Yup.object({
            street: validations.stringRequired(t),
            city: validations.stringRequired(t),
            postalCode: validations.postalCodeRequired(t),
            country: validations.stringRequired(t),
          }),
        }),
        responsiblePersonB: Yup.object({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email: validations.emailRequired(t),
          language: validations.stringRequired(t),
        }),
      })}
      onSubmit={handleSubmit}
    >
      <FormContent handleGoBack={handleGoBack} onDelete={onDelete} />
    </FormikDebounce>
  );
};
