import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';
import { CenterLayoutWrapper } from '../layout/LayoutStyles';

export const ErrorAlert: FC = () => {
  const { t } = useTranslation();

  return (
    <CenterLayoutWrapper>
      <QcsAlert severity="error">{t('common.unknownError')}</QcsAlert>
    </CenterLayoutWrapper>
  );
};
