import {
  VisitationInvitationResponse,
  VisitationPinDto,
} from '@qcs/safety-client';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  selectTourniquetIdentity,
  tourniquetIdentityActions,
} from '../../store/entities/tourniquetIdentity';
import { tourniquetTrainingsActions } from '../../store/entities/tourniquetTrainings';
import { visitationApi } from '../../utils/api';
import { setErrorSnacks } from '../../utils/error';
import {
  getFirstLastNameObj,
  getLangNameObj,
  joinStrings,
  joinStrings2,
} from '../../utils/format';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { GridList } from '../common/grid/GridList';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import { TourniquetCaption } from './TourniquetCaption';
import {
  TourniquetCard,
  TourniquetContent,
  TourniquetHeader,
} from './TourniquetStyles';
import styled from '@emotion/styled';

const MobileHeader = styled.div(({ theme }) => ({
  display: 'block',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DesktopHeader = styled(TourniquetHeader)(({ theme }) => ({
  display: 'none',
  gridTemplateColumns: '40px auto 40px',

  [theme.breakpoints.up('md')]: {
    display: 'grid',
  },
}));

interface GridItem {
  id: string;
  invitationName: string;
  workplaces: string;
  employeeDesktop: string;
  employeeMobile: string;
  responsiblePerson: string;
  data: VisitationInvitationResponse;
}

export const TourniquetInvitationList: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const data = useMemo(() => {
    const personEmployeeId = tourniquetIdentity.employeeId;

    return (tourniquetIdentity.allData ?? []).map<GridItem>((x) => {
      const person = x.invitation?.persons?.find(
        (p) => p.employeeId === personEmployeeId
      );

      return {
        id: x.invitation?.id ?? '',
        invitationName: x.invitation?.name ?? '',
        workplaces:
          joinStrings2(
            person?.workplaces?.map((w) => getLangNameObj(i18n, w))
          ) ?? '',
        employeeDesktop: joinStrings(
          getFirstLastNameObj(x.invitation?.employee),
          x.invitation?.employee?.phone
        ),
        employeeMobile: getFirstLastNameObj(x.invitation?.employee),
        responsiblePerson: getFirstLastNameObj(x.invitation?.responsiblePerson),
        data: x,
      };
    });
  }, [i18n, tourniquetIdentity.allData, tourniquetIdentity.employeeId]);

  const handleRenderData = (item: GridItem) => {
    const handleConfirm = async () => {
      setLoading(true);
      try {
        const data: VisitationPinDto = {
          invitation: item.id,
          pin: tourniquetIdentity.pin ?? '',
        };

        const incompleteTrainingsRes = await visitationApi.incompleteTrainings(
          identityCompanyId,
          data
        );

        dispatch(
          tourniquetTrainingsActions.success(incompleteTrainingsRes.data)
        );

        dispatch(
          tourniquetIdentityActions.success({
            ...tourniquetIdentity,
            data: item.data,
            allPersons: [
              {
                id: incompleteTrainingsRes.data.invitationPerson.id!,
                name: getFirstLastNameObj(
                  incompleteTrainingsRes.data.invitationPerson
                ),
              },
            ],
            employeeId: incompleteTrainingsRes.data.invitationPerson.employeeId,
          })
        );
      } catch (err) {
        setErrorSnacks(err, enqueueErrorSnackbar);
      }
      setLoading(false);
    };

    return (
      <>
        <QcsTableCell>{item.invitationName}</QcsTableCell>
        <QcsTableCell>{item.workplaces}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.employeeDesktop}</QcsTableCell>
        <QcsTableCell showOnlyOnMobile={true}>
          {item.employeeMobile}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {item.responsiblePerson}
        </QcsTableCell>
        <QcsTableCell align="right">
          <QcsLoadingButton
            variant="contained"
            loading={loading}
            onClick={handleConfirm}
          >
            {t('common.confirm')}
          </QcsLoadingButton>
        </QcsTableCell>
      </>
    );
  };

  const handleAbort = () => {
    dispatch(tourniquetIdentityActions.default());
  };

  return (
    <TourniquetButtonContent backOnClickMobile={handleAbort}>
      <TourniquetCard>
        <TourniquetContent>
          <MobileHeader>
            <TourniquetCaption title={t('tourniquet.title')} />
          </MobileHeader>
          <DesktopHeader>
            <QcsButton
              sx={{ width: '80px' }}
              variant="outlined"
              color="error"
              onClick={handleAbort}
            >
              {t('common.back')}
            </QcsButton>
            <TourniquetCaption title={t('tourniquet.title')} />
          </DesktopHeader>
          <GridList<GridItem>
            headers={[
              { captionStr: 'tourniquet.invitationName' },
              { captionStr: 'tourniquet.workplaces' },
              { captionStr: 'tourniquet.employee' },
              {
                captionStr: 'tourniquet.responsiblePerson',
                hideOnMobile: true,
              },
              {},
            ]}
            data={data}
            renderData={handleRenderData}
            search={false}
            hidePagination={true}
          />
        </TourniquetContent>
      </TourniquetCard>
    </TourniquetButtonContent>
  );
};
