import { RootState } from '../index';
import { FetchState } from '../fetchState';
import { baseSlice, BaseType } from '../baseHelper';
import { TourniquetTrainingSlides } from '../../models/tourniquet';

type State = BaseType<TourniquetTrainingSlides>;

const initialState: State = {
  state: FetchState.None,
  data: {
    slides: [],
  },
};

export const tourniquetTrainingSlidesSlice = baseSlice({
  name: 'tourniquetTrainingSlides',
  initialState,
});

export const selectTourniquetTrainingSlides = (state: RootState) =>
  state.tourniquetTrainingSlides.data;
export const tourniquetTrainingSlidesActions =
  tourniquetTrainingSlidesSlice.actions;
