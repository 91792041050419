import { FC, useEffect, useState } from 'react';
import { supplierEmployeeApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { Loader } from '../common/Loader';
import { DetailHeader } from '../common/DetailHeader';
import { QcsTypography } from '../common/basic/QcsTypography';
import { setErrorState } from '../../utils/error';
import { useAppSelector } from '../../store';
import { selectIdentity } from '../../store/entities/identity';
import { ErrorStateType } from '../../models/common';

export const PinExternal: FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const [pin, setPin] = useState('');
  const identity = useAppSelector(selectIdentity);

  useEffect(() => {
    (async () => {
      try {
        const pin = await supplierEmployeeApi.revealPin(identity.id);
        setPin(pin.data.pin!);
      } catch (err) {
        setErrorState(err, setError);
      }

      setLoading(false);
    })();
  }, [identity.id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <DetailHeader title="mainMenu.pinExternal" />
      <SaveError error={error} />
      {pin && <QcsTypography variant="h4">{pin}</QcsTypography>}
    </>
  );
};
