import { FC, useEffect, useRef, useState } from 'react';
import { QcsTextField } from '../basic/QcsTextField';
import { DEBOUNCE_TIMEOUT_MS } from '../../../utils/constants';

interface Props {
  value?: string;
  onChange: (value?: string) => void;
}

export const GridFilterString: FC<Props> = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value ?? '');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onChange(event.target.value);
    }, DEBOUNCE_TIMEOUT_MS);
  };

  const handleBlur = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLocalValue(localValue);
  };

  const handleKeyDown = (event: any) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setLocalValue(localValue);
  };

  return (
    <QcsTextField
      fullWidth={true}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    />
  );
};
