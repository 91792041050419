import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  supplierListActions,
  SupplierListFilter,
} from '../../store/entities/supplierList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: SupplierListFilter;
}

export const SupplierFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={supplierListActions}
        name="state"
        title="supplier.state"
        Component={GridFilterState}
      />
    </>
  );
};
