import {
  CreateQuestionnaireRequest,
  QuestionnaireDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  questionnaireActions,
  getQuestionnaire,
  selectQuestionnaire,
  selectQuestionnaireState,
} from '../../store/entities/questionnaire';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { SubmitButton } from '../common/form/SubmitButton';
import { Input } from '../common/form/Input';
import { questionnairesApi } from '../../utils/api';
import { questionnaireListActions } from '../../store/entities/questionnaireList';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { SaveError } from '../common/SaveError';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { TrainingAutocomplete } from '../common/form/TrainingAutocomplete';
import { FormGroup } from '../common/form/FormGroup';
import { DatePicker } from '../common/form/DatePicker';
import { QuestionnaireVersion } from './QuestionnaireVersion';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { getDateForApi } from '../../utils/date';
import { setErrorStateSnacks } from '../../utils/error';
import { fixNameInitValue, getLangNameObj } from '../../utils/format';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

type Validator = {
  name?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameEn?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameRu?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameDe?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  namePl?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  validFrom?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  minSuccessRate?: Yup.NumberSchema<number, Yup.AnyObject, undefined, ''>;
};

export const QuestionnaireDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/questionnaire');
  const { questionnaireId } = useParams();
  const questionnaire = useAppSelector(selectQuestionnaire);
  const questionnaireState = useAppSelector(selectQuestionnaireState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    if (questionnaireId === 'new') {
      dispatch(questionnaireActions.default());
    } else {
      dispatch(getQuestionnaire(questionnaireId!));
    }
    setLoading(false);
  }, [questionnaireId, dispatch]);

  const handleSubmit = async (data: CreateQuestionnaireRequest) => {
    setSaveError('');

    try {
      if (questionnaireId === 'new') {
        await questionnairesApi.createQuestionnaire(identityCompanyId, data);
        enqueueSuccessSnackbar(t('questionnaire.addSuccess'));
      } else if (questionnaireId) {
        await questionnairesApi.updateQuestionnaire(questionnaireId, data);
        enqueueSuccessSnackbar(t('questionnaire.updateSuccess'));
      }

      dispatch(questionnaireListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        questionnaireId === 'new'
          ? 'questionnaire.addError'
          : 'common.editError'
      );
    }
  };

  if (loading || questionnaireState === FetchState.Loading) {
    return <Loader />;
  }

  if (questionnaireState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {};

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }
  if (questionnaireId === 'new') {
    validator['validFrom'] = validations.stringDateNotPastRequired(t);
    validator['minSuccessRate'] = validations.intRequiredMinMax(1, 100, t);
  }

  return (
    <>
      <DetailHeader
        title={getLangNameObj(i18n, questionnaire)}
        backTo={backTo}
      />
      <FormikDebounce<CreateQuestionnaireRequest>
        initialValues={{
          ...questionnaire,
          ...fixNameInitValue(i18n, questionnaire),
          validFrom: getDateForApi(new Date())!,
          minSuccessRate: 0,
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={questionnaireId === 'new'}
              label={t('questionnaire.name')}
              maxLength={50}
              required
            />

            <Select name="state" label={t('questionnaire.state')}>
              <QcsMenuItem value={QuestionnaireDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={QuestionnaireDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>

            {questionnaireId !== 'new' && (
              <TrainingAutocomplete
                multiple
                name="trainings"
                label={t('questionnaire.trainings')}
                disabled
              />
            )}

            <Input
              name="note"
              label={t('questionnaire.note')}
              maxLength={250}
              multiline={true}
              rows={4}
            />

            {questionnaireId === 'new' && (
              <FormGroup name="questionnaire.firstVersion">
                <DatePicker
                  name="validFrom"
                  label={t('questionnaire.validFrom')}
                  disablePast
                  disabled
                  required
                />
                <DatePicker
                  name="validTo"
                  label={t('questionnaire.validTo')}
                  disabled
                />
                <Input
                  name="minSuccessRate"
                  label={t('questionnaire.minSuccessRate')}
                  type="number"
                />
              </FormGroup>
            )}

            <SaveError error={saveError} />
            <SubmitButton />
          </FormContainer>
        </Form>
      </FormikDebounce>
      {questionnaireId !== 'new' && <QuestionnaireVersion />}
    </>
  );
};
