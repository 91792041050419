import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import { GridFilterState } from '../common/grid/GridFilterState';
import {
  workplaceListActions,
  WorkplaceListFilter,
} from '../../store/entities/workplaceList';
import { GridFilterEstablishment } from '../common/grid/GridFilterEstablishment';

interface Props {
  query: WorkplaceListFilter;
}

export const WorkplaceFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={workplaceListActions}
        name="state"
        title="workplace.state"
        Component={GridFilterState}
      />
      <GridFilterItem
        query={query}
        gridActions={workplaceListActions}
        name="establishment"
        title="workplace.establishment"
        Component={GridFilterEstablishment}
      />
    </>
  );
};
