import { AppDispatch, RootState, store } from '../index';
import { FetchState } from '../fetchState';
import {
  getList,
  getListSort,
  ListFilter,
  listSlice,
  ListType,
} from '../listHelper';
import { CancelToken } from 'axios';
import { supplierEmployeeApi } from '../../utils/api';
import { TrainingExternalItem } from '../../models/external';

export type TrainingExternalListState = ListType<
  TrainingExternalItem,
  ListFilter
>;

const initialState: TrainingExternalListState = {
  state: FetchState.None,
  data: undefined,
  query: {},
};

export const trainingExternalListSlice = listSlice({
  name: 'trainingExternalList',
  initialState,
});

export const selectTrainingExternalList = (state: RootState) =>
  state.trainingExternalList;
export const trainingExternalListActions = trainingExternalListSlice.actions;

export const getTrainingExternalListByEmployeeId =
  (employeeId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectTrainingExternalList(storeState);

    const handleGetData = (size: number) => {
      return supplierEmployeeApi.getInvitationsData(
        employeeId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: trainingExternalListActions,
      getData: handleGetData,
    });
  };

export const getTrainingExternalListByUserId =
  (userId: string, cancelToken: CancelToken) => (dispatch: AppDispatch) => {
    const storeState = store.getState();
    const data = selectTrainingExternalList(storeState);

    const handleGetData = (size: number) => {
      return supplierEmployeeApi.getInvitationsDataFromUserId(
        userId,
        data.query.page,
        size,
        getListSort(data),
        { cancelToken: cancelToken as any }
      );
    };

    getList({
      dispatch,
      cancelToken,
      state: data.state,
      actions: trainingExternalListActions,
      getData: handleGetData,
    });
  };
