import { DialogContentText } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const QcsDialogContentText: FC<Props> = ({ className, children }) => {
  return (
    <DialogContentText className={className}>{children}</DialogContentText>
  );
};
