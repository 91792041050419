import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { SupplierDto, SupplierDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { supplierApi, supplierEmployeeApi } from '../../utils/api';

type State = BaseType<SupplierDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    ico: '',
    vat: '',
    state: SupplierDtoStateEnum.Active,
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
    },
    note: '',
  },
};

export const supplierSlice = baseSlice({
  name: 'supplier',
  initialState,
});

export const selectSupplierState = (state: RootState) => state.supplier.state;
export const selectSupplier = (state: RootState) => state.supplier.data;
export const supplierActions = supplierSlice.actions;

export const getSupplier = (supplierId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return supplierApi.getSupplier(supplierId);
  };

  getItem({
    dispatch,
    actions: supplierActions,
    getData: handleGetData,
  });
};

export const getSupplierFromUserId =
  (userId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return supplierEmployeeApi.getSupplierFromUserId(userId);
    };

    getItem({
      dispatch,
      actions: supplierActions,
      getData: handleGetData,
    });
  };
