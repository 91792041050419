import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getOptionsQuestionnaire,
  selectOptionsQuestionnaire,
  selectOptionsQuestionnaireState,
} from '../../../store/entities/optionsQuestionnaire';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

interface Props {
  skipId?: string;
  className?: string;
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

export const QuestionnaireAutocomplete: FC<Props> = ({
  skipId,
  multiple,
  name,
  label,
  disabled,
  required,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsQuestionnaire = useAppSelector(selectOptionsQuestionnaire);
  const optionsQuestionnaireState = useAppSelector(
    selectOptionsQuestionnaireState
  );

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsQuestionnaire(identityCompanyId, search, skipId, cancelToken)
    );
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={optionsQuestionnaire}
      loading={optionsQuestionnaireState === FetchState.Loading}
      loadingError={optionsQuestionnaireState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option?.id || ''}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
    />
  );
};
