import { Card, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const QcsCard: FC<Props> = ({ className, sx, children }) => {
  return (
    <Card className={className} sx={sx}>
      {children}
    </Card>
  );
};
