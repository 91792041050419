import { FormControl } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  error?: boolean;
  children: React.ReactNode;
}

export const QcsFormControl: FC<Props> = ({ className, error, children }) => {
  return (
    <FormControl className={className} error={error}>
      {children}
    </FormControl>
  );
};
