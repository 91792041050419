import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { ErrorStateType } from '../../models/common';
import { questionnairesApi } from '../../utils/api';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';

interface Props {
  open: boolean;
  languageCode: string;
  onClose: () => void;
  reloadTrainingVersionList: () => void;
}

export const LanguageDeleteModal: FC<Props> = ({
  open,
  languageCode,
  onClose,
  reloadTrainingVersionList,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const { questionnaireVersionId } = useParams();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleDeleteLanguage = async () => {
    try {
      await questionnairesApi.deleteQuestionnaireVersionVariant(
        questionnaireVersionId!,
        languageCode
      );
      enqueueSuccessSnackbar(t('training.slideDeleted'));
      reloadTrainingVersionList();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'training.slideDeleteError'
      );
    }
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={t('training.askDeleteLanguage')} />
        <QcsBox sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <QcsButton variant="contained" onClick={handleDeleteLanguage}>
            {t('common.yes')}
          </QcsButton>
          <QcsButton variant="outlined" onClick={onClose}>
            {t('common.no')}
          </QcsButton>
        </QcsBox>
        <QcsBox sx={{ display: 'flex', m: 1, justifyContent: 'center' }}>
          <SaveError error={saveError} />
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
