import { Cookies } from 'react-cookie';

const ACCESS_TOKEN_KEY = 'qcs-access-token';
const REFRESH_TOKEN_KEY = 'qcs-refresh-token';
const MEDIA_TOKEN_KEY = 'qcs-media-token';
const QCS_ADMIN_LAST_COMPANY = 'qcs-admin-last-company';
const QCS_ADMIN_LAST_COMPANY_NAME = 'qcs-admin-last-company-name';
const MENU_HIDDEN = 'qcs-menu-hidden';

const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN as string;

const cookies = new Cookies();
const setOptions = {
  domain: COOKIE_DOMAIN,
  path: '/',
  maxAge: 30 * 24 * 60 * 60, //30 days in seconds
};

export const logout = () => {
  cookies.remove(ACCESS_TOKEN_KEY, setOptions);
  cookies.remove(REFRESH_TOKEN_KEY, setOptions);
  cookies.remove(MEDIA_TOKEN_KEY, setOptions);
  cookies.remove(QCS_ADMIN_LAST_COMPANY, setOptions);
  cookies.remove(QCS_ADMIN_LAST_COMPANY_NAME, setOptions);
};

export const getAccessToken = (): string | null =>
  cookies.get(ACCESS_TOKEN_KEY);

export const setAccessToken = (token: string) => {
  cookies.set(ACCESS_TOKEN_KEY, token, setOptions);
};

export const getRefreshToken = (): string | null =>
  cookies.get(REFRESH_TOKEN_KEY);

export const setRefreshToken = (token: string) => {
  cookies.set(REFRESH_TOKEN_KEY, token, setOptions);
};

export const getMediaToken = (): string | null => cookies.get(MEDIA_TOKEN_KEY);

export const setMediaToken = (token: string) => {
  cookies.set(MEDIA_TOKEN_KEY, token, setOptions);
};

export const getQcsAdminLastCompany = (): string | null =>
  cookies.get(QCS_ADMIN_LAST_COMPANY);

export const setQcsAdminLastCompany = (id: string | null | undefined) => {
  if (id) {
    cookies.set(QCS_ADMIN_LAST_COMPANY, id, setOptions);
  } else {
    cookies.remove(QCS_ADMIN_LAST_COMPANY, setOptions);
  }
};

export const getMenuHidden = () => cookies.get(MENU_HIDDEN) === 'true';

export const setMenuHidden = (value: boolean) => {
  cookies.set(MENU_HIDDEN, value ? 'true' : 'false', setOptions);
};
