import {
  InvitationDto,
  SupplierEmployeeDtoRoleEnum,
  SupplierEmployeeDtoStateEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../common/form/FormikDebounce';
import { FC, useEffect } from 'react';
import { invitationsApi } from '../../../utils/api';
import { setErrorStateSnacks } from '../../../utils/error';
import { Box, Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../common/basic/QcsModalBox';
import { DatePicker } from '../../common/form/DatePicker';
import { FormContainer } from '../../common/form/FormContainer';
import { Input } from '../../common/form/Input';
import { SubmitButton } from '../../common/form/SubmitButton';
import { SaveError } from '../../common/SaveError';
import { SectionHeader } from '../../common/SectionHeader';
import {
  invitationGetUpdateDto,
  useInvitation,
} from './Tabs/invitationFunctions';
import * as Yup from 'yup';
import { validations } from '../../../utils/validations';
import { useAppSelector } from '../../../store';
import { selectIdentity } from '../../../store/entities/identity';
import { hasRole } from '../../../utils/roles';
import { EmployeeAutocomplete } from '../../common/form/EmployeeAutocomplete';
import { getFirstLastNameObj } from '../../../utils/format';
import { SupplierEmployeeAutocomplete } from '../../common/form/SupplierEmployeeAutocomplete';
import { useRef } from 'react';
import { Checkbox } from '../../common/form/Checkbox';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const InvitationEditGeneralModal: FC<Props> = ({ open, onClose }) => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    saveError,
    setSaveError,
  } = useInvitation();
  const identity = useAppSelector(selectIdentity);

  // Ref to track which button was clicked
  const submitButtonType = useRef<'save' | 'saveAndSend' | null>(null);

  useEffect(() => {
    setSaveError('');
  }, [open, setSaveError]);

  const handleSubmit = async (data: InvitationDto) => {
    setSaveError('');
    lockInvitation();
    try {

      const data2 = invitationGetUpdateDto(data);
      data2.sendNotification = submitButtonType.current === 'saveAndSend';
      await invitationsApi.updateInvitation(invitation.id!, data2);

      await reloadInvitation();
      enqueueSuccessSnackbar(t('common.saveSuccess'));
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    unlockInvitation();
  };

  const disableEdit = !hasRole(identity.roles, [
    UserDtoRolesEnum.ManagerOsah,
    UserDtoRolesEnum.ManagerWorkplace,
  ]);

  const disableEditResponsiblePerson = !hasRole(identity.roles, [
    UserDtoRolesEnum.ManagerOsah,
    UserDtoRolesEnum.Receptionist,
    UserDtoRolesEnum.AdminCompany,
    UserDtoRolesEnum.ManagerWorkplace,
  ]);

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.editTitle" />
        <FormikDebounce<InvitationDto>
          initialValues={{
            ...invitation,
            disableVisitationNotification: invitation.disableVisitationNotification ?? false,
            employee: {
              ...invitation.employee!,
              name: getFirstLastNameObj(invitation.employee),
            },
          }}
          validationSchema={Yup.object({
            validFrom: validations.stringDateNotPastIfChangedRequired(
              t,
              invitation.validFrom!
            ),
            validTo: Yup.string()
              .nullable()
              .when('validFrom', ([validFrom], schema) =>
                schema.test({
                  test: (validTo: string | null | undefined) => {
                    return !validTo || validFrom <= validTo;
                  },
                  message: t('errors.dateToBeforeFrom')!,
                })
              ),
            employee: validations.objectRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <DatePicker
                name="validFrom"
                label={t('training.validFrom')}
                required={true}
                disablePast={true}
                disabled={disableEdit}
              />
              <DatePicker
                name="validTo"
                label={t('training.validTo')}
                disablePast={true}
                disabled={disableEdit}
              />
              <Input
                name="noteInternal"
                label={t('invitation.noteInternal')}
                maxLength={250}
                multiline={true}
                rows={4}
                disabled={disableEdit}
              />
              <Input
                name="note"
                label={t('invitation.note')}
                maxLength={250}
                multiline={true}
                rows={4}
                disabled={disableEdit}
              />
              <EmployeeAutocomplete
                name="employee"
                label={t('invitation.employee')}
                required
                forInvite={true}
              />
              <SupplierEmployeeAutocomplete
                name="responsiblePerson"
                label={t('invitation.responsibleSupplierPerson')}
                required
                disabled={disableEditResponsiblePerson}
                supplierId={invitation.supplier?.id}
                role={SupplierEmployeeDtoRoleEnum.ExternalManager}
                state={[
                  SupplierEmployeeDtoStateEnum.Active,
                  SupplierEmployeeDtoStateEnum.Invited,
                ]}
              />
              <Checkbox
                name="disableVisitationNotification"
                label={t('common.disableVisitationNotification')}
              />
              <SaveError error={saveError} />

              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <SubmitButton
                  ignoreMarginLeft
                  text="common.saveAndSend"
                  onClick={() => (submitButtonType.current = 'saveAndSend')}
                />
                <SubmitButton
                  text="common.save"
                  onClick={() => (submitButtonType.current = 'save')}
                />
              </Box>
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};