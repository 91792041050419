import { UserDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  qcsAdminUserListActions,
  getQcsAdminUserList,
  selectQcsAdminUserList,
} from '../../../store/entities/qcsAdminUserList';
import { QcsTableCell } from '../../common/basic/QcsTableCell';
import { Grid } from '../../common/grid/Grid';
import { SectionHeader } from '../../common/SectionHeader';
import { QcsAdminUserFilter } from './QcsAdminUserFilter';

export const QcsAdminUser: FC = () => {
  const { t } = useTranslation();
  const qcsAdminUserList = useAppSelector(selectQcsAdminUserList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getQcsAdminUserList(cancelToken));
  };

  const handleRenderData = (item: UserDto) => {
    return (
      <>
        <QcsTableCell>{item.name}</QcsTableCell>
        <QcsTableCell>{item.lastName}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: UserDto) => {
    navigate('/qcsuser/' + item.id);
  };

  const handleAddClick = () => {
    navigate('/qcsuser/new');
  };

  return (
    <>
      <SectionHeader
        title="admin.user.title"
        addText="admin.user.add"
        handleAddClick={handleAddClick}
      />
      <Grid<UserDto>
        headers={[
          { captionStr: 'admin.user.name', orderName: 'name' },
          { captionStr: 'admin.user.lastName', orderName: 'lastName' },
          {
            captionStr: 'admin.user.email',
            orderName: 'email',
            hideOnMobile: true,
          },
          {
            captionStr: 'admin.user.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={qcsAdminUserList}
        gridActions={qcsAdminUserListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<QcsAdminUserFilter query={qcsAdminUserList.query} />}
      />
    </>
  );
};
