import { useFormikContext } from 'formik';
import { FC, useEffect, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import _ from 'lodash';
import { QcsMenuItem } from '../basic/QcsMenuItem';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { LanguageDto } from '@qcs/safety-client';
import { useTranslation } from 'react-i18next';
import { SelectItem } from './Select';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  languages?: LanguageDto[];
  allowedLanguages?: string[];
  notAllowedLanguages?: string[];
  hideNonSelected?: boolean;
}

export const LanguageSelect: FC<Props> = ({
  className,
  name,
  label,
  disabled,
  required,
  languages,
  allowedLanguages,
  notAllowedLanguages,
  hideNonSelected,
}) => {
  const { t } = useTranslation();

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<any>();

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  useEffect(() => {
    if (
      (allowedLanguages && !allowedLanguages?.includes(value)) ||
      notAllowedLanguages?.includes(values)
    ) {
      setFieldValue(name, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedLanguages, notAllowedLanguages]);

  const flagsMemo = useMemo(() => {
    if (!languages) {
      return null;
    }

    let lang = [...languages].sort((a, b) => a.name.localeCompare(b.name));

    if (allowedLanguages) {
      lang = lang.filter((x) => allowedLanguages.includes(x.code));
    }

    if (notAllowedLanguages) {
      lang = lang.filter((x) => !notAllowedLanguages.includes(x.code));
    }

    return lang.map((l) => (
      <QcsMenuItem key={l.code} value={l.code}>
        <SelectItem>
          <img src={'/flags/' + l.flag + '.svg'} height="20" loading="lazy" />
          <div>{l.name}</div>
        </SelectItem>
      </QcsMenuItem>
    ));
  }, [languages, allowedLanguages, notAllowedLanguages]);

  if (
    value &&
    ((allowedLanguages && !allowedLanguages?.includes(value)) ||
      notAllowedLanguages?.includes(values))
  ) {
    return null;
  }

  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel required={required} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        required={required}
        className={className}
        sx={{ backgroundColor: (theme) => theme.palette.common.white }}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        disabled={disabled}
      >
        {!hideNonSelected && (
          <QcsMenuItem key={'undefined'} value={''}>
            <em>{t('common.nonSelected')}</em>
          </QcsMenuItem>
        )}
        {flagsMemo}
      </QcsSelect>
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
