import styled from '@emotion/styled';
import { SupplierCertDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getSupplierEmployeeDocumentList,
  selectSupplierEmployeeDocumentList,
  supplierEmployeeDocumentListActions,
} from '../../store/entities/supplierEmployeeDocumentList';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { QcsTypography } from '../common/basic/QcsTypography';
import { CellDate } from '../common/grid/CellDate';
import { Grid } from '../common/grid/Grid';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { PROTOCOL_MEDIA } from '../../utils/constants';
import { getLangNameObj } from '../../utils/format';

const HeaderContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
}));

export const SupplierEmployeeDocument: FC = () => {
  const { t, i18n } = useTranslation();
  const supplierEmployeeDocumentList = useAppSelector(
    selectSupplierEmployeeDocumentList
  );
  const dispatch = useAppDispatch();
  const { supplierEmployeeId } = useParams();

  useEffect(() => {
    dispatch(supplierEmployeeDocumentListActions.reload(true));
  }, [dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getSupplierEmployeeDocumentList(supplierEmployeeId!, cancelToken));
  };

  const handleRenderData = (item: SupplierCertDto) => {
    const url = !!item.documentUrl
      ? PROTOCOL_MEDIA +
        (item.documentUrl.startsWith('/')
          ? item.documentUrl.substring(1)
          : item.documentUrl)
      : undefined;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.certificate)}</QcsTableCell>
        <QcsTableCell align="center">
          {!!url && (
            <QcsButton href={url}>
              <InsertDriveFileIcon />
            </QcsButton>
          )}
        </QcsTableCell>
        <CellDate value={item.created} />
        <CellDate value={item.validUntil} />
      </>
    );
  };

  return (
    <div>
      <HeaderContainer>
        <QcsTypography variant="h5">
          {t('supplierEmployeeDocument.title')}
        </QcsTypography>
      </HeaderContainer>
      <Grid<SupplierCertDto>
        headers={[
          { captionStr: 'supplierEmployeeDocument.name' },
          {
            captionStr: 'supplierEmployeeDocument.link',
            sx: { textAlign: 'center' },
          },
          {
            captionStr: 'supplierEmployeeDocument.created',
            orderName: 'created',
          },
          {
            captionStr: 'supplierEmployeeDocument.validTo',
            orderName: 'validUntil',
          },
        ]}
        data={supplierEmployeeDocumentList}
        gridActions={supplierEmployeeDocumentListActions}
        renderData={handleRenderData}
        getData={handleGetData}
        search={false}
      />
    </div>
  );
};
