import {
  InvitationDtoStateEnum,
  InvitationDtoTypeEnum,
  VisitationPinDto,
} from '@qcs/safety-client';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import {
  selectTourniquetIdentity,
  tourniquetIdentityActions,
} from '../../store/entities/tourniquetIdentity';
import {
  selectTourniquetTrainings,
  tourniquetTrainingsActions,
} from '../../store/entities/tourniquetTrainings';
import { invitationsApi, visitationApi } from '../../utils/api';
import { setErrorSnacks } from '../../utils/error';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { TourniquetButtonContent } from './TourniquetButtonContent';
import { TourniquetCaption } from './TourniquetCaption';
import { TourniquetInvitationMyCompany } from './TourniquetInvitationMyCompany';
import { TourniquetInvitationOk } from './TourniquetInvitationOk';
import { TourniquetInvitationTheirCompany } from './TourniquetInvitationTheirCompany';
import { TourniquetInvitationTrainings } from './TourniquetInvitationTrainings';
import {
  TourniquetCard,
  TourniquetContent,
  TourniquetHeader,
} from './TourniquetStyles';
import {
  getFirstLastNameObj,
  invitaitonTrainingIsValid,
  invitationCertificateIsApproved,
  invitationTrainingIsCompleted,
} from '../../utils/format';
import styled from '@emotion/styled';

const MobileHeader = styled.div(({ theme }) => ({
  display: 'block',
  marginBottom: '1rem',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DesktopHeader = styled(TourniquetHeader)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'grid',
  },
}));

export const TourniquetInvitation: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const tourniquetIdentity = useAppSelector(selectTourniquetIdentity);
  const tourniquetTrainings = useAppSelector(selectTourniquetTrainings);
  const dispatch = useAppDispatch();
  const { enqueueErrorSnackbar } = useAppSnackbar();

  const invitation = tourniquetIdentity.data!.invitation!;
  const state = invitation?.state;

  const personEmployeeId = tourniquetIdentity.employeeId;
  const person = invitation?.persons?.find(
    (p) => p.employeeId === personEmployeeId
  );

  const invalid =
    state === InvitationDtoStateEnum.Deleted ||
    state === InvitationDtoStateEnum.Archived;

  const isOneTime =
    invitation.type === InvitationDtoTypeEnum.OneTime ||
    invitation.type === InvitationDtoTypeEnum.OneTimeEntry;

  const notApproved =
    !isOneTime &&
    !invalid &&
    (state === InvitationDtoStateEnum.Sent ||
      state === InvitationDtoStateEnum.Viewed);

  const personDocumentNotUploaded =
    person?.documents?.some((x) => !x.document) ?? false;
  const personDocumentNotApproved =
    person?.documents?.some(
      (x) => !!x.document && !invitationCertificateIsApproved(x)
    ) ?? false;

  const workplaces =
    person?.workplaces
      .map((x) => invitation.workplaces?.find((y) => y.workplace?.id === x.id))
      .filter((x) => !!x) ?? [];
  const workplaceRiskNotApproved =
    !isOneTime &&
    workplaces.some((x) => x?.risks?.some((x) => !x.approvedOn) == true);

  const activities =
    person?.activities
      .map((x) => invitation.activities?.find((y) => y.activity?.id === x.id))
      .filter((x) => !!x) ?? [];
  const activityNotUploaded =
    !isOneTime &&
    activities.some((x) => !x?.document && x?.activity?.riskRequired);
  const activityNotApproved =
    !isOneTime && activities.some((x) => !!x?.document && !x.approvedOn);

  const issueMyCompany =
    notApproved ||
    personDocumentNotUploaded ||
    workplaceRiskNotApproved ||
    activityNotApproved;
  const issueTheirCompany =
    invalid || personDocumentNotApproved || activityNotUploaded;
  const issueAnyCompany = issueMyCompany || issueTheirCompany;

  const incompleteTrainings =
    tourniquetTrainings.trainings?.some(
      (x) => !invitationTrainingIsCompleted(x) || !invitaitonTrainingIsValid(x)
    ) ?? false;

  const conditionOverride =
    incompleteTrainings && !!tourniquetIdentity.data?.conditionOverride;
  const issueTrainings = incompleteTrainings && !conditionOverride;

  const issue = issueAnyCompany || issueTrainings;

  const handleAbort = () => {
    dispatch(
      tourniquetIdentityActions.success({
        ...tourniquetIdentity,
        data: undefined,
      })
    );
  };

  const handleReload = async () => {
    setLoading(true);
    try {
      const data: VisitationPinDto = {
        invitation: invitation.id ?? '',
        pin: tourniquetIdentity.pin ?? '',
      };

      const resp = await invitationsApi.getInvitationById(invitation.id!);

      const incompleteTrainingsRes = await visitationApi.incompleteTrainings(
        identityCompanyId,
        data
      );

      dispatch(tourniquetTrainingsActions.success(incompleteTrainingsRes.data));
      dispatch(
        tourniquetIdentityActions.success({
          ...tourniquetIdentity,
          data: { ...tourniquetIdentity.data, invitation: resp.data },
          allData: tourniquetIdentity.allData?.map((x) => {
            if (x.invitation?.id === invitation.id) {
              return { ...x, invitation: resp.data };
            }
            return x;
          }),
          allPersons: [
            {
              id: incompleteTrainingsRes.data.invitationPerson.id!,
              name: getFirstLastNameObj(
                incompleteTrainingsRes.data.invitationPerson
              ),
            },
          ],
          employeeId: incompleteTrainingsRes.data.invitationPerson.employeeId,
        })
      );
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setLoading(false);
  };

  const caption = useMemo(
    () => (
      <TourniquetCaption
        title={
          issue
            ? issueAnyCompany
              ? t('tourniquet.issueCompanyTitle', {
                  invitationName: invitation.name,
                })
              : t('tourniquet.issueTrainingsTitle')
            : t('tourniquet.noIssueTitle')
        }
      />
    ),
    [invitation.name, issue, issueAnyCompany, t]
  );

  return (
    <TourniquetButtonContent backOnClickMobile={handleAbort}>
      <TourniquetCard>
        <TourniquetContent>
          <MobileHeader>
            {caption}
            {issue && (
              <div
                style={{
                  width: 'max-content',
                  marginLeft: 'auto',
                  marginTop: '0.2rem',
                }}
              >
                <QcsLoadingButton
                  variant="contained"
                  onClick={handleReload}
                  loading={loading}
                >
                  {t('tourniquet.checkAgain')}
                </QcsLoadingButton>
              </div>
            )}
          </MobileHeader>
          <DesktopHeader>
            <QcsButton variant="outlined" onClick={handleAbort}>
              {t('common.back')}
            </QcsButton>
            {caption}
            {issue && (
              <QcsLoadingButton
                variant="contained"
                onClick={handleReload}
                loading={loading}
                sx={{ width: '100%' }}
              >
                {t('tourniquet.checkAgain')}
              </QcsLoadingButton>
            )}
          </DesktopHeader>

          {issueMyCompany && (
            <TourniquetInvitationMyCompany invitation={invitation} />
          )}
          {issueTheirCompany && (
            <TourniquetInvitationTheirCompany invitation={invitation} />
          )}
          {issueTrainings && (
            <TourniquetInvitationTrainings
              invitation={invitation}
              invitationPerson={tourniquetTrainings.invitationPerson}
            />
          )}
          {!issue && (
            <TourniquetInvitationOk invitation={invitation} person={person!} />
          )}
        </TourniquetContent>
      </TourniquetCard>
    </TourniquetButtonContent>
  );
};
