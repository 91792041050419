import { useFormikContext } from 'formik';
import { FC } from 'react';
import { QcsCheckbox } from '@s4e/design-system/atoms/form/QcsCheckbox';
import _ from 'lodash';
import { QcsFormControlLabel } from '../basic/QcsFormControlLabel';
import { SaveError } from '../SaveError';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
}

export const Checkbox: FC<Props> = ({ className, name, label, disabled }) => {
  const { values, errors, handleChange } = useFormikContext<any>();

  const value = _.get(values, name);
  const errorText = _.get(errors, name) as string;

  return (
    <>
      <SaveError error={errorText} />
      <QcsFormControlLabel
        control={
          <QcsCheckbox
            className={className}
            id={name}
            name={name}
            checked={value}
            onChange={handleChange}
            disabled={disabled}
          />
        }
        label={label}
      />
    </>
  );
};
