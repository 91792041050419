import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { UserDto, UserDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { qcUserApi } from '../../utils/api';

type State = BaseType<UserDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    state: UserDtoStateEnum.Invited,
    language: 'cs',
    roles: [],
    establishments: [],
  },
};

export const userSlice = baseSlice({
  name: 'user',
  initialState,
});

export const selectUserState = (state: RootState) => state.user.state;
export const selectUser = (state: RootState) => state.user.data;
export const userActions = userSlice.actions;

export const getUser = (userId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return qcUserApi.getUser(userId);
  };

  getItem({
    dispatch,
    actions: userActions,
    getData: handleGetData,
  });
};
