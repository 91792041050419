import styled from '@emotion/styled';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Radio } from '@mui/material';
import { AnswerDto } from '@qcs/safety-client';
import { FieldArray, useFormikContext } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { QcsFormHelperText } from '../common/basic/QcsFormHelperText';
import { FaIcon } from '../common/FaIcon';
import { Input } from '../common/form/Input';
import { ImageInput } from './ImageInput';
import { QuestionsForm } from './QuestionnaireQuestions';

const QuestionContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  padding: '1rem',
}));

const QuestionWrapper = styled.div(() => ({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
}));

const AnswersContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const AnswerWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

const StyledIconButton = styled(QcsButton)(() => ({
  height: '3rem',
}));

const StyledInput = styled(Input)(() => ({
  width: '100%',
}));

interface Props {
  index: number;
  remove: (index: number) => void;
  onRemoveOldFile: (document: string) => void;
  editable?: boolean;
}

export const QuestionItem: FC<Props> = ({
  index: i,
  remove,
  onRemoveOldFile,
  editable,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, validateField } =
    useFormikContext<QuestionsForm>();
  const lastRef = useRef<HTMLInputElement>(null);
  const [selectLastAnswer, setSelectLastAnswer] = useState<number>();
  const lastAnswerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    lastRef.current?.focus();
  }, []);

  useEffect(() => {
    if (selectLastAnswer) {
      lastAnswerRef.current?.focus();
    }
  }, [selectLastAnswer]);

  const handleRadioChange = (i: number, j: number) => () => {
    for (let a = 0; a < (values.questions[i].answers?.length ?? 0); a++) {
      if (values.questions[i].answers?.[a]) {
        setFieldValue(`questions.${i}.answers.${a}.correct`, false);
      }
    }
    setFieldValue(`questions.${i}.answers.${j}.correct`, true);
    validateField(`questions.${i}.answers`);
  };

  return (
    <QuestionContainer>
      <QuestionWrapper>
        <StyledInput
          name={`questions.${i}.content`}
          label={`${t('questionnaire.questions.questionNo')} ${i + 1}`}
          required
          disabled={!editable}
          inputRef={
            i === values.questions.length - 1 && !values.questions[i].id
              ? lastRef
              : undefined
          }
        />
        <StyledIconButton disabled={!editable} onClick={() => remove(i)}>
          <DeleteForeverIcon color={!editable ? undefined : 'error'} />
        </StyledIconButton>
      </QuestionWrapper>

      <ImageInput
        disabled={!editable}
        questionIndex={i}
        onRemoveOldFile={onRemoveOldFile}
      />

      <FieldArray name={`questions.${i}.answers`}>
        {({ remove, push }) => (
          <AnswersContainer>
            {values.questions[i]?.answers &&
              values.questions[i]?.answers!.map((_, j) => (
                <AnswerWrapper key={j}>
                  <Radio
                    disabled={!editable}
                    key={j}
                    checked={!!values.questions[i].answers![j].correct}
                    onChange={handleRadioChange(i, j)}
                  />
                  <StyledInput
                    name={`questions.${i}.answers.${j}.content`}
                    label={`${t('questionnaire.questions.answerNo')} ${j + 1}`}
                    required
                    disabled={!editable}
                    inputRef={
                      j === values.questions[i].answers!.length - 1
                        ? lastAnswerRef
                        : undefined
                    }
                  />
                  <StyledIconButton
                    disabled={!editable}
                    onClick={() => remove(j)}
                  >
                    <DeleteForeverIcon
                      color={!editable ? undefined : 'error'}
                    />
                  </StyledIconButton>
                </AnswerWrapper>
              ))}

            <QcsFormHelperText error>
              {(!Array.isArray((errors?.questions?.[i] as any)?.answers) &&
                (errors?.questions?.[i] as any)?.answers) ??
                null}
            </QcsFormHelperText>

            <QcsButton
              variant="outlined"
              disabled={!editable}
              sx={{ width: 'fit-content' }}
              onClick={() => {
                push({ content: '', correct: false } as AnswerDto);
                setSelectLastAnswer(values.questions[i].answers!.length);
              }}
              startIcon={<FaIcon icon={faAdd} />}
            >
              {t('questionnaire.questions.addAnswer')}
            </QcsButton>
          </AnswersContainer>
        )}
      </FieldArray>
    </QuestionContainer>
  );
};
