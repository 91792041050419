import {
  InvitationDocumentResponse,
  InvitationTrainingResponse,
  ReferenceDto,
  SupplierEmployeeDto,
} from '@qcs/safety-client';
import { format, parseISO } from 'date-fns';
import { i18n } from 'i18next';

export const getFirstLastName = (
  name?: string | null,
  lastName?: string | null
): string => {
  if (!name && !lastName) {
    return '';
  }

  if (name && lastName) {
    return `${name} ${lastName}`;
  }

  if (name) {
    return name;
  }

  return lastName!;
};

export const getFirstLastNameObj = (data?: {
  name?: string | null;
  lastName?: string | null;
}): string => {
  return getFirstLastName(data?.name, data?.lastName);
};

export const getFirstLastNameAutocomplete = (
  data: ReferenceDto
): ReferenceDto => {
  const res = {
    ...data,
    name: getFirstLastNameObj(data),
  };
  return res;
};

export const getFirstLastNameSupplierEmployee = (
  data: SupplierEmployeeDto
): SupplierEmployeeDto => {
  const res = {
    ...data,
    name: getFirstLastNameObj(data),
  };
  return res;
};

export const joinStrings = (...strings: Array<string | null | undefined>) =>
  strings.filter((x) => !!x).join(', ');

export const joinStrings2 = (
  strings: Array<string | null | undefined> | null | undefined
) => strings?.filter((x) => !!x).join(', ');

export const firstUpper = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getWorkplaceName = (
  i18n: i18n,
  workplaceName?: string | null,
  workplaceNameEn?: string | null,
  establishmentName?: string | null,
  establishmentNameEn?: string | null
) =>
  getLangName(i18n, establishmentName, establishmentNameEn)
    ? `${getLangName(i18n, workplaceName, workplaceNameEn)} | ${getLangName(
        i18n,
        establishmentName,
        establishmentNameEn
      )}`
    : getLangName(i18n, workplaceName, workplaceNameEn);

export const getWorkplaceNameObj = (
  i18n: i18n,
  workplace?: { name?: string | null; nameEn?: string | null },
  establishment?: { name?: string | null; nameEn?: string | null }
) =>
  getWorkplaceName(
    i18n,
    workplace?.name,
    workplace?.nameEn,
    establishment?.name,
    establishment?.nameEn
  );

export const getLangName = (
  i18n: i18n,
  name?: string | null,
  nameEn?: string | null,
  nameDe?: string | null,
  namePl?: string | null,
  nameRu?: string | null
): string => {
  if (i18n.resolvedLanguage === 'en') {
    return nameEn ?? name ?? '';
  }
  if (i18n.resolvedLanguage === 'pl') {
    return namePl ?? name ?? '';
  }
  if (i18n.resolvedLanguage === 'de') {
    return nameDe ?? name ?? '';
  }
  if (i18n.resolvedLanguage === 'ru') {
    return nameRu ?? name ?? '';
  }

  return name ? name : nameEn ? nameEn : '';
};

export const getLangNameObj = (
  i18n: i18n,
  data?: {
    name?: string | null;
    nameEn?: string | null;
    nameDe?: string | null;
    namePl?: string | null;
    nameRu?: string | null;
  }
): string => {
  return getLangName(
    i18n,
    data?.name,
    data?.nameEn,
    data?.nameDe,
    data?.namePl,
    data?.nameRu
  );
};

export const getLangNameInputName = (
  i18n: i18n,
  name?: string | null,
  nameEn?: string | null,
  nameDe?: string | null,
  namePl?: string | null,
  nameRu?: string | null
): string => {
  if (i18n.resolvedLanguage === 'en') {
    return nameEn ? 'nameEn' : 'name';
  }
  if (i18n.resolvedLanguage === 'pl') {
    return namePl ? 'namePl' : 'name';
  }
  if (i18n.resolvedLanguage === 'de') {
    return nameDe ? 'nameDe' : 'name';
  }
  if (i18n.resolvedLanguage === 'ru') {
    return nameRu ? 'nameRu' : 'name';
  }

  return name ? 'name' : 'nameEn';
};

export const getLangNameInputNameObj = (
  i18n: i18n,
  data?: {
    name?: string | null;
    nameEn?: string | null;
    nameDe?: string | null;
    namePl?: string | null;
    nameRu?: string | null;
  }
): string => {
  return getLangNameInputName(
    i18n,
    data?.name,
    data?.nameEn,
    data?.nameDe,
    data?.namePl,
    data?.nameRu
  );
};

export const invitationCertificateIsApproved = (
  document: InvitationDocumentResponse
) => {
  //Not approved.
  if (!document.approvedOn) {
    return false;
  }

  //Approved, not expired.
  if (!document.certificate?.expiryDateRequired) {
    return true;
  }

  //Approved, but expired.
  if (!document.validTo) {
    return false;
  }

  return (
    parseISO(document.validTo) >= parseISO(format(new Date(), 'yyyy-MM-dd'))
  );
};

export const invitationCertificateIsExpired = (
  document: InvitationDocumentResponse
) => {
  //Without expiration
  if (!document.validTo) {
    return true;
  }
  return (
    parseISO(document.validTo!) >= parseISO(format(new Date(), 'yyyy-MM-dd'))
  );
};

export const invitationTrainingIsCompleted = (
  training: InvitationTrainingResponse
) =>
  !!training.trainingCompleted &&
  (!training.questionnaire || !!training.questionnaireCompleted);

export const invitaitonTrainingIsValid = (
  training: InvitationTrainingResponse
) =>
  !!training.trainingValidUntil &&
  parseISO(training.trainingValidUntil) > new Date();

export const fixNameInitValue = (
  i18n: i18n,
  obj: { name?: string; nameEn?: string }
) => {
  if (i18n.resolvedLanguage === 'en') {
    return {
      name: obj.name ? obj.name : obj.nameEn,
      nameEn: obj.nameEn,
    };
  }

  return {
    name: obj.name,
    nameEn: obj.nameEn ? obj.nameEn : obj.name,
  };
};
