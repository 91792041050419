import { CreateCompanyRequest, CompanyDtoStateEnum } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  appTrainingLangListActions,
  getAppTrainingLangList,
  selectAppTrainingLangList,
} from '../../../store/entities/appTrainingLangList';
import {
  getQcsAdminCompany,
  qcsAdminCompanyActions,
  selectQcsAdminCompany,
  selectQcsAdminCompanyState,
} from '../../../store/entities/qcsAdminCompany';
import { qcsAdminCompanyListActions } from '../../../store/entities/qcsAdminCompanyList';
import { FetchState } from '../../../store/fetchState';
import { aresApi, companyApi } from '../../../utils/api';
import { setQcsAdminLastCompany } from '../../../utils/storage';
import { SaveError } from '../../common/SaveError';
import { QcsLoadingButton } from '../../common/basic/QcsLoadingButton';
import { QcsMenuItem } from '../../common/basic/QcsMenuItem';
import { DetailHeader } from '../../common/DetailHeader';
import { ErrorAlert } from '../../common/ErrorAlert';
import { Checkbox } from '../../common/form/Checkbox';
import { DatePicker } from '../../common/form/DatePicker';
import { FormContainer } from '../../common/form/FormContainer';
import { FormGroup } from '../../common/form/FormGroup';
import { Input } from '../../common/form/Input';
import { Select } from '../../common/form/Select';
import { SubmitButton } from '../../common/form/SubmitButton';
import { Loader } from '../../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../../utils/validations';
import { LanguageSelectMultiple } from '../../common/form/LanguageSelectMultiple';
import { SUPPORTED_LANGUAGES } from '../../../utils/i18n';
import { LanguageSelect } from '../../common/form/LanguageSelect';
import { useAppSnackbar } from '../../../hooks/useAppSnackbar';
import { PhoneInput } from '../../common/form/PhoneInput';
import { setErrorState, setErrorStateSnacks } from '../../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { ErrorStateType } from '../../../models/common';
import { useBack } from '../../../hooks/useBack';

const COMPANY_URL = import.meta.env.VITE_COMPANY_URL as string;

export const QcsAdminCompanyDetail: FC = () => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/');
  const { companyId } = useParams();
  const appTrainingLangList = useAppSelector(selectAppTrainingLangList);
  const company = useAppSelector(selectQcsAdminCompany);
  const companyState = useAppSelector(selectQcsAdminCompanyState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [aresLoading, setAresLoading] = useState(false);
  const [aresError, setAresError] = useState<ErrorStateType>();

  useEffect(() => {
    dispatch(appTrainingLangListActions.reload(false));

    if (companyId === 'new') {
      dispatch(qcsAdminCompanyActions.default());
    } else {
      dispatch(getQcsAdminCompany(companyId!));
    }
    setLoading(false);
  }, [companyId, dispatch]);

  useEffect(() => {
    if (appTrainingLangList.state === FetchState.None && companyId === 'new') {
      dispatch(getAppTrainingLangList());
    }
  }, [appTrainingLangList.state, dispatch, companyId]);

  const handleSubmit = async (data: CreateCompanyRequest) => {
    setSaveError('');

    try {
      if (companyId === 'new') {
        await companyApi.createCompany(data);
        enqueueSuccessSnackbar(t('admin.company.addSuccess'));
      } else {
        await companyApi.updateCompany(companyId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(qcsAdminCompanyListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        companyId === 'new' ? 'admin.company.addError' : 'common.editError'
      );
    }
  };

  if (loading || companyState === FetchState.Loading) {
    return <Loader />;
  }

  if (companyState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const handleSwitchToCompany = async () => {
    setQcsAdminLastCompany(company.id!);

    window.location.replace(
      COMPANY_URL.replaceAll('{subdomain}', company.subDomain ?? '')
    );
  };

  const validationSchemaBase = Yup.object().shape({
    ico: validations.companyNoRequired(t),
    name: validations.stringRequired(t),
    vat: validations.companyVatNoOptional(t),
    address: Yup.object({
      street: validations.stringRequired(t),
      city: validations.stringRequired(t),
      postalCode: validations.postalCodeRequired(t),
      country: validations.stringRequired(t),
    }),
    contact: Yup.object({
      name: validations.stringRequired(t),
      lastName: validations.stringRequired(t),
      phone: validations.phoneRequired(t),
      email: validations.emailRequired(t),
    }),
  });

  const validationSchemaNewCompany = validationSchemaBase.concat(
    Yup.object().shape({
      subDomain: validations.stringRequired(t),
      subDomainAgain: validations
        .stringRequired(t)
        .oneOf([Yup.ref('subDomain')], t('errors.subDomainMatch')!),
      companySettings: Yup.object({
        licenseValidFrom: validations.stringDateNotPastRequired(t),
        licenseValidTo: Yup.string().when(
          'licenseValidFrom',
          ([licenseValidFrom], schema) =>
            schema.test({
              test: (licenseValidTo: string | null | undefined) => {
                return !licenseValidTo || licenseValidFrom <= licenseValidTo;
              },
              message: t('errors.dateToBeforeFrom')!,
            })
        ),
        maxEstablishments: validations.intPositive(t),
        maxUsers: validations.intPositive(t),
        maxTraining: validations.intPositive(t),
        applicationLanguages: validations.arrayNotEmpty(t),
        defaultLanguage: validations.stringRequired(t),
        testLanguages: validations.arrayNotEmpty(t),
      }),
    })
  );

  return (
    <>
      <DetailHeader title="admin.company.detailTitle" backTo={backTo} />
      <FormikDebounce<CreateCompanyRequest>
        initialValues={{
          ...company,
          subDomain: '',
          subDomainAgain: '',
          companySettings: {
            maxEstablishments: 1,
            maxUsers: 1,
            maxTraining: 1,
            passRisksEnabled: false,
            smsEnabled: false,
            applicationLanguages: [],
            testLanguages: [],
            licenseValidFrom: '',
            licenseValidTo: '',
            defaultLanguage: '',
          },
        }}
        validationSchema={
          companyId === 'new'
            ? validationSchemaNewCompany
            : validationSchemaBase
        }
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => {
          const handleLoadFromAres = async () => {
            setAresLoading(true);
            setAresError('');

            try {
              const res = await aresApi.fetchSubjectDetailsFromAres(values.ico);

              // filter out null values
              const data = Object.fromEntries(
                Object.entries(res.data).filter(([, value]) => value !== null)
              );
              setValues({ ...values, ...data });
            } catch (err) {
              setErrorState(err, setAresError, 'admin.company.aresError');
            }

            setAresLoading(false);
          };

          return (
            <Form>
              <FormContainer>
                <QcsBox
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'auto',
                      sm: 'auto max-content',
                    },
                    gridTemplateRows: { xs: 'auto auto', sm: 'auto' },
                    rowGap: { xs: companyId === 'new' ? '1rem' : 0, sm: 0 },
                    columnGap: { xs: 0, sm: companyId === 'new' ? '1rem' : 0 },
                  }}
                >
                  <Input
                    name="ico"
                    label={t('admin.company.ico')}
                    maxLength={14}
                    required
                  />

                  {companyId === 'new' && (
                    <QcsLoadingButton
                      onClick={handleLoadFromAres}
                      disabled={!values.ico}
                      loading={aresLoading}
                      variant="contained"
                      sx={{ height: '3.5rem' }}
                    >
                      {t('admin.company.loadFromAres')}
                    </QcsLoadingButton>
                  )}
                </QcsBox>
                <SaveError error={aresError} />

                <Input
                  name="name"
                  label={t('admin.company.name')}
                  maxLength={100}
                  required
                />
                <Input
                  name="vat"
                  label={t('admin.company.vat')}
                  maxLength={14}
                />

                <FormGroup name="address.title">
                  <Input
                    name="address.street"
                    label={t('address.street')}
                    maxLength={100}
                    required
                  />
                  <Input
                    name="address.city"
                    label={t('address.city')}
                    maxLength={100}
                    required
                  />
                  <Input
                    name="address.postalCode"
                    label={t('address.postalCode')}
                    maxLength={128}
                    required
                  />
                  <Input
                    name="address.country"
                    label={t('address.country')}
                    maxLength={100}
                    required
                  />
                </FormGroup>

                <FormGroup name="admin.company.contactPerson">
                  <Input
                    name="contact.name"
                    label={t('admin.company.contactPersonName')}
                    maxLength={100}
                    required
                  />
                  <Input
                    name="contact.lastName"
                    label={t('admin.company.contactPersonLastName')}
                    maxLength={100}
                    required
                  />
                  <PhoneInput
                    name="contact.phone"
                    label={t('admin.company.phone')}
                    required
                  />
                  <Input
                    name="contact.email"
                    label={t('admin.company.email')}
                    maxLength={100}
                    required
                  />
                </FormGroup>

                <Select name="state" label={t('admin.company.state')}>
                  <QcsMenuItem value={CompanyDtoStateEnum.Active}>
                    {t('entityState.ACTIVE')}
                  </QcsMenuItem>
                  <QcsMenuItem value={CompanyDtoStateEnum.Inactive}>
                    {t('entityState.INACTIVE')}
                  </QcsMenuItem>
                  <QcsMenuItem value={CompanyDtoStateEnum.PreActivated}>
                    {t('entityState.PRE_ACTIVATED')}
                  </QcsMenuItem>
                </Select>

                <Input
                  name="note"
                  label={t('admin.company.note')}
                  maxLength={250}
                  multiline={true}
                  rows={4}
                />

                {companyId === 'new' && (
                  <>
                    <DatePicker
                      name="companySettings.licenseValidFrom"
                      label={t('admin.company.licenseValidFrom')}
                      disablePast
                      required
                    />
                    <DatePicker
                      name="companySettings.licenseValidTo"
                      label={t('admin.company.licenseValidTo')}
                      disablePast
                    />
                    <Input
                      name="subDomain"
                      label={t('admin.company.subDomain')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="subDomainAgain"
                      label={t('admin.company.subDomainAgain')}
                      maxLength={100}
                      required
                    />
                    <Input
                      name="companySettings.maxEstablishments"
                      label={t('admin.company.maxEstablishments')}
                      type="number"
                      min={1}
                      required
                    />
                    <Input
                      name="companySettings.maxUsers"
                      label={t('admin.company.maxUsers')}
                      type="number"
                      min={1}
                      required
                    />
                    <Input
                      name="companySettings.maxTraining"
                      label={t('admin.company.maxTraining')}
                      type="number"
                      min={1}
                      required
                    />
                    <Checkbox
                      name="companySettings.passRisksEnabled"
                      label={t('admin.company.passRisksEnabled')}
                    />
                    <Checkbox
                      name="companySettings.smsEnabled"
                      label={t('admin.company.smsEnabled')}
                    />
                    <LanguageSelectMultiple
                      name="companySettings.applicationLanguages"
                      label={t('admin.company.applicationLanguages')}
                      languages={SUPPORTED_LANGUAGES}
                      required
                    />

                    <LanguageSelect
                      name="companySettings.defaultLanguage"
                      label={t('admin.company.defaultApplicationLanguage')}
                      languages={SUPPORTED_LANGUAGES}
                      allowedLanguages={
                        values.companySettings.applicationLanguages
                      }
                      required
                    />

                    <LanguageSelectMultiple
                      name="companySettings.testLanguages"
                      label={t('admin.company.testLanguages')}
                      languages={appTrainingLangList.data?.content}
                      required
                    />
                  </>
                )}

                <SaveError error={saveError} />
                <div style={{ display: 'flex' }}>
                  {companyId !== 'new' && (
                    <QcsLoadingButton onClick={handleSwitchToCompany}>
                      {t('admin.company.switchButton')}
                    </QcsLoadingButton>
                  )}
                  <SubmitButton />
                </div>
              </FormContainer>
            </Form>
          );
        }}
      </FormikDebounce>
    </>
  );
};
