import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  InvitationTypeVisitListResponse,
  ReferenceDto,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { invitationTypeVisitsApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';
import { getFirstLastNameObj } from '../../utils/format';

type State = BaseType<InvitationTypeVisitListResponse[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsInvitationTypeVisitSlice = baseSlice({
  name: 'optionsInvitationTypeVisit',
  initialState,
});

export const selectOptionsInvitationTypeVisitState = (state: RootState) =>
  state.optionsInvitationTypeVisit.state;
export const selectOptionsInvitationTypeVisit = (state: RootState) =>
  state.optionsInvitationTypeVisit.data.map(
    (x) =>
      ({
        id: x.supplierEmployeeId,
        name: [getFirstLastNameObj(x), x.company]
          .filter((x) => !!x)
          .join(' | '),
      } as ReferenceDto)
  );
export const optionsInvitationTypeVisitActions =
  optionsInvitationTypeVisitSlice.actions;

export const getOptionsInvitationTypeVisit =
  (companyId: string, search: string | undefined, cancelToken: CancelToken) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await invitationTypeVisitsApi.queryInvitationTypeVisit(
        companyId,
        search,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsInvitationTypeVisitActions,
      getData: handleGetData,
    });
  };
