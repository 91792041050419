import { useFormikContext } from 'formik';
import { FC, useMemo, useState } from 'react';
import { QcsDatePicker } from '../basic/QcsDatePicker';
import _ from 'lodash';
import { isValid, parseISO } from 'date-fns';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getDateForApi } from '../../../utils/date';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  shouldDisableDate?: (day: Date) => boolean;
  disableHighlightToday?: boolean;
}

export const DatePicker: FC<Props> = ({
  className,
  name,
  label,
  required,
  disabled,
  disablePast,
  shouldDisableDate,
  disableHighlightToday,
}) => {
  const { values, errors, touched, submitCount, setFieldValue, handleBlur } =
    useFormikContext<any>();

  const [invalidDate, setInvalidDate] = useState(false);

  const { t } = useTranslation();

  const value = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  //useMemo for disable flickering if "invalidDate" changed
  const dateValue = useMemo(() => (value ? parseISO(value) : null), [value]);

  const handleDateChange = (value?: Date | null) => {
    if (isValid(value) || !value) {
      setInvalidDate(false);
      setFieldValue(name, getDateForApi(value));
    } else {
      setInvalidDate(true);
    }
  };

  const theme = useTheme();

  return (
    <QcsDatePicker
      className={className}
      sx={{ backgroundColor: theme.palette.common.white }}
      id={name}
      name={name}
      label={label}
      value={dateValue}
      onChange={handleDateChange}
      onBlur={handleBlur}
      error={invalidDate || (wasTouched && hasError)}
      required={required}
      helperText={
        (invalidDate && t('errors.date')) || (wasTouched && errorText)
      }
      disabled={disabled}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      disableHighlightToday={disableHighlightToday}
    />
  );
};
