import {
  EstablishmentDto,
  EstablishmentDtoStateEnum,
  UserDto,
  UserDtoStateEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import {
  getUser,
  selectUser,
  selectUserState,
} from '../../store/entities/user';
import { FetchState } from '../../store/fetchState';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { Select } from '../common/form/Select';
import { Loader } from '../common/Loader';
import { UserRoleSelect } from '../common/form/UserRoleSelect';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CancelToken } from 'axios';
import {
  establishmentListActions,
  getEstablishmentList,
  selectEstablishmentList,
} from '../../store/entities/establishmentList';
import { selectIdentity } from '../../store/entities/identity';
import { Grid } from '../common/grid/Grid';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { EstablishmentFilter } from '../establishment/EstablishmentFilter';
import { PhoneInput } from '../common/form/PhoneInput';
import { getFirstLastNameObj, getLangNameObj } from '../../utils/format';
import { isExternal } from '../../utils/roles';

export const Profile: FC = () => {
  const { t, i18n } = useTranslation();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const user = useAppSelector(selectUser);
  const userState = useAppSelector(selectUserState);
  const establishmentList = useAppSelector(selectEstablishmentList);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const isExternalVal = isExternal(identity);

  useEffect(() => {
    dispatch(getUser(identity.id));
    setLoading(false);
  }, [identity.id, dispatch]);

  const handleSubmit = () => {
    //Nothing.
  };

  const handleEstablishmentGetData = (cancelToken: CancelToken) => {
    dispatch(getEstablishmentList(identity.companyId, cancelToken));
  };

  const handleIsRowDisabled = (item: EstablishmentDto) =>
    item.state !== EstablishmentDtoStateEnum.Active;

  const handleEstablishmentRenderData = (item: EstablishmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getFirstLastNameObj(item.contact)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  if (loading || userState === FetchState.Loading) {
    return <Loader />;
  }

  if (userState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="user.profile" />
      <FormikDebounce<UserDto> initialValues={user} onSubmit={handleSubmit}>
        <Form>
          <FormContainer>
            <Input name="name" label={t('user.name')} disabled={true} />
            <Input name="lastName" label={t('user.lastName')} disabled={true} />
            <UserRoleSelect
              name="roles"
              label={t('user.roles')}
              disabled={true}
            />
            <PhoneInput name="phone" label={t('user.phone')} disabled={true} />
            <Input name="email" label={t('user.email')} disabled={true} />

            {!isExternalVal && (
              <div>
                <QcsTypography variant="h6">
                  {t('user.detailEstablishmentSelect')}:
                </QcsTypography>
                <Grid<EstablishmentDto>
                  headers={[
                    { captionStr: 'establishment.name' },
                    {
                      captionStr: 'establishment.contactName',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.email',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.phone',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.state',
                      hideOnMobile: true,
                    },
                  ]}
                  //establishmentList is used by Establishment.tsx - must have same filter (actual) or split store
                  data={establishmentList}
                  gridActions={establishmentListActions}
                  renderData={handleEstablishmentRenderData}
                  isRowDisabled={handleIsRowDisabled}
                  getData={handleEstablishmentGetData}
                  checkbox={true}
                  checkedItems={user.establishments}
                  filter={
                    <EstablishmentFilter query={establishmentList.query} />
                  }
                />
              </div>
            )}

            {!isExternalVal && (
              <Input name="office" label={t('user.office')} disabled={true} />
            )}

            <LanguageSelect
              name="language"
              label={t('user.language')}
              languages={SUPPORTED_LANGUAGES}
              allowedLanguages={companyCustomization?.applicationLanguages}
              disabled={true}
            />

            {!isExternalVal && (
              <Select name="state" label={t('user.state')} disabled={true}>
                <QcsMenuItem value={UserDtoStateEnum.Active}>
                  {t('entityState.ACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={UserDtoStateEnum.Inactive}>
                  {t('entityState.INACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={UserDtoStateEnum.Invited}>
                  {t('entityState.INVITED')}
                </QcsMenuItem>
              </Select>
            )}

            {!isExternalVal && (
              <Input
                name="note"
                label={t('user.note')}
                multiline={true}
                rows={4}
                disabled={true}
              />
            )}
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
