import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import {
  documentListActions,
  DocumentListFilter,
} from '../../store/entities/documentList';
import { GridFilterState } from '../common/grid/GridFilterState';

interface Props {
  query: DocumentListFilter;
}

export const DocumentFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={documentListActions}
        name="state"
        title="document.state"
        Component={GridFilterState}
      />
    </>
  );
};
