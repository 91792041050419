import { VisitationDto } from '@qcs/safety-client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { ErrorStateType } from '../../models/common';
import { useAppDispatch } from '../../store';
import { visitationListActions } from '../../store/entities/visitationList';
import { visitationApi } from '../../utils/api';
import { setErrorStateSnacks } from '../../utils/error';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { SaveError } from '../common/SaveError';
import { SectionHeader } from '../common/SectionHeader';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';

interface Props {
  data?: VisitationDto;
  onClose: () => void;
}

export const VisitationExitModal: FC<Props> = ({ data, onClose }) => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useAppSnackbar();
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<ErrorStateType>();
  const dispatch = useAppDispatch();

  const confirm = async () => {
    setError('');
    setSaving(true);
    try {
      await visitationApi.endVisitation(data!.id!);

      dispatch(visitationListActions.reload(true));
      dispatch(invitationTypeVisitListActions.reload(true));
      onClose();
    } catch (err) {
      setErrorStateSnacks(err, setError, enqueueErrorSnackbar);
    }
    setSaving(false);
  };

  return (
    <QcsModal open={!!data} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title={t('visitation.exitConfirm')} />
        <SaveError error={error} />
        <QcsBox
          sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: '2rem' }}
        >
          <QcsLoadingButton
            loading={isSaving}
            variant="contained"
            onClick={confirm}
          >
            {t('common.yes')}
          </QcsLoadingButton>
          <QcsLoadingButton
            loading={isSaving}
            variant="outlined"
            onClick={onClose}
          >
            {t('common.no')}
          </QcsLoadingButton>
        </QcsBox>
      </QcsModalBox>
    </QcsModal>
  );
};
