import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { QcsSelect } from '../basic/QcsSelect';
import _ from 'lodash';
import { CheckboxInMenuItem, MenuItemWithCheckbox } from './formStyles';
import { useTheme } from '@mui/material';
import { QcsFormControl } from '../basic/QcsFormControl';
import { QcsInputLabel } from '../basic/QcsInputLabel';
import { QcsFormHelperText } from '../basic/QcsFormHelperText';
import { Loader } from '../Loader';
import { joinStrings2 } from '../../../utils/format';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  options: unknown[];
  loading?: boolean;
  getOptionId: (option: any) => string;
  getOptionNameFromId: (option: any) => string;
}

export const SelectMultiple: FC<Props> = ({
  className,
  name,
  label,
  disabled,
  required,
  options,
  loading,
  getOptionId,
  getOptionNameFromId,
}) => {
  const { values, errors, touched, submitCount, handleChange, handleBlur } =
    useFormikContext<any>();

  const value: string[] = _.get(values, name);
  const wasTouched = !!submitCount || !!_.get(touched, name);
  const errorText = _.get(errors, name) as string;
  const hasError = !!errorText;

  const items = useMemo(
    () =>
      options.map((option: any) => {
        const optionId = getOptionId(option);
        const optionName = getOptionNameFromId(optionId);

        return (
          <MenuItemWithCheckbox key={optionId} value={optionId}>
            <CheckboxInMenuItem checked={value.some((id) => id === optionId)} />
            {optionName}
          </MenuItemWithCheckbox>
        );
      }),
    [getOptionId, getOptionNameFromId, options, value]
  );

  const theme = useTheme();

  return (
    <QcsFormControl error={wasTouched && hasError}>
      <QcsInputLabel required={required} disabled={disabled}>
        {label}
      </QcsInputLabel>
      <QcsSelect
        multiple
        required={required}
        className={className}
        sx={{ backgroundColor: theme.palette.common.white }}
        id={name}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={wasTouched && hasError}
        disabled={disabled}
        renderValue={(selected) =>
          joinStrings2(selected.map((s: string) => getOptionNameFromId(s)))
        }
      >
        {loading ? <Loader /> : items}
      </QcsSelect>
      <QcsFormHelperText>{wasTouched && errorText}</QcsFormHelperText>
    </QcsFormControl>
  );
};
