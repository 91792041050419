import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { QuestionDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { questionnairesApi } from '../../utils/api';

type State = BaseType<QuestionDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const questionnaireQuestionsSlice = baseSlice({
  name: 'questionnaireQuestions',
  initialState,
});

export const selectQuestionnaireQuestionsState = (state: RootState) =>
  state.questionnaireQuestions.state;
export const selectQuestionnaireQuestions = (state: RootState) =>
  state.questionnaireQuestions.data
    .slice()
    .sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
export const questionnaireQuestionsActions =
  questionnaireQuestionsSlice.actions;

export const getQuestionnaireQuestions =
  (questionnaireVersionId: string, languageCode: string) =>
  (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return questionnairesApi.getQuestions(
        questionnaireVersionId,
        languageCode
      );
    };

    getItem({
      dispatch,
      actions: questionnaireQuestionsActions,
      getData: handleGetData,
    });
  };
