import {
  SafetyEquipmentDto,
  SafetyEquipmentDtoStateEnum,
  SafetyEquipmentDtoTypeEnum,
  WorkplaceDto,
} from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  safetyEquipmentActions,
  getSafetyEquipment,
  selectSafetyEquipment,
  selectSafetyEquipmentState,
} from '../../store/entities/safetyEquipment';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { activityApi, safetyEquipmentApi } from '../../utils/api';
import { safetyEquipmentListActions } from '../../store/entities/safetyEquipmentList';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { FormContainer } from '../common/form/FormContainer';
import { DetailHeader } from '../common/DetailHeader';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { SafetyEquipmentUpdateModal } from './SafetyEquipmentUpdateModal';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType, Validator } from '../../models/common';
import { fixNameInitValue } from '../../utils/format';
import { useBack } from '../../hooks/useBack';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';

interface SafetyEquipmentDetailForm extends SafetyEquipmentDto {
  saveAndNew?: boolean;
}

export const SafetyEquipmentDetail: FC = () => {
  const { i18n, t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/safety-equipment');
  const { safetyEquipmentId } = useParams();
  const safetyEquipment = useAppSelector(selectSafetyEquipment);
  const safetyEquipmentState = useAppSelector(selectSafetyEquipmentState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [dataForConfirmUpdate, setDataForConfirmUpdate] =
    useState<SafetyEquipmentDto>();
  const [listUsedWorkplace, setListUsedWorkplace] = useState<WorkplaceDto[]>(
    []
  );
  const formikRef = useRef<FormikProps<SafetyEquipmentDetailForm> | null>(null);
  const companyCustomization = useAppSelector(selectCompanyCustomization);

  useEffect(() => {
    if (safetyEquipmentId === 'new') {
      dispatch(safetyEquipmentActions.default());
    } else {
      dispatch(getSafetyEquipment(safetyEquipmentId!));
    }
    setLoading(false);
  }, [safetyEquipmentId, dispatch]);

  const handleSubmit = async (data: SafetyEquipmentDetailForm) => {
    setSaveError('');

    try {
      if (safetyEquipmentId === 'new') {
        await safetyEquipmentApi.createSafetyEquipment(identityCompanyId, data);
        enqueueSuccessSnackbar(t('safetyEquipment.addSuccess'));
      } else {
        const deactivate =
          safetyEquipment.state === SafetyEquipmentDtoStateEnum.Active &&
          data.state === SafetyEquipmentDtoStateEnum.Inactive;

        let workplaces: WorkplaceDto[] = [];
        if (deactivate) {
          const workplacesRes =
            await activityApi.getWorkplacesForSafetyEquipment(
              safetyEquipment.id!
            );
          workplaces = workplacesRes.data;
        }

        if ((workplaces?.length ?? 0) > 0) {
          setListUsedWorkplace(workplaces);
          setDataForConfirmUpdate(data);
          return;
        } else {
          await safetyEquipmentApi.updateSafetyEquipment(
            safetyEquipmentId!,
            data
          );
          enqueueSuccessSnackbar(t('common.editSuccess'));
        }
      }
      dispatch(safetyEquipmentListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        safetyEquipmentId === 'new'
          ? 'safetyEquipment.addError'
          : 'common.editError'
      );
    }
  };

  const handleUpdateModalClose = async (update: boolean) => {
    if (update) {
      await safetyEquipmentApi.updateSafetyEquipment(
        safetyEquipmentId!,
        dataForConfirmUpdate!
      );
      enqueueSuccessSnackbar(t('common.editSuccess'));
      dispatch(safetyEquipmentListActions.reload(true));
      goBack();
    }

    setDataForConfirmUpdate(undefined);
    setListUsedWorkplace([]);
  };

  if (loading || safetyEquipmentState === FetchState.Loading) {
    return <Loader />;
  }

  if (safetyEquipmentState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {};

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }

  return (
    <>
      <SafetyEquipmentUpdateModal
        listUsedWorkplace={listUsedWorkplace}
        onClose={handleUpdateModalClose}
      />

      <DetailHeader title="safetyEquipment.detailTitle" backTo={backTo} />
      <FormikDebounce<SafetyEquipmentDetailForm>
        initialValues={{
          ...safetyEquipment,
          ...fixNameInitValue(i18n, safetyEquipment),
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        <Form>
          <FormContainer>
            <InputTextWithLang
              isNew={safetyEquipmentId === 'new'}
              label={t('safetyEquipment.name')}
              required
            />
            <Select name="type" label={t('safetyEquipment.type')}>
              <QcsMenuItem value={SafetyEquipmentDtoTypeEnum.Owned}>
                {t('safetyEquipment.types.OWNED')}
              </QcsMenuItem>
              <QcsMenuItem value={SafetyEquipmentDtoTypeEnum.Borrowed}>
                {t('safetyEquipment.types.BORROWED')}
              </QcsMenuItem>
              <QcsMenuItem
                value={SafetyEquipmentDtoTypeEnum.BorrowedReturnable}
              >
                {t('safetyEquipment.types.BORROWED_RETURNABLE')}
              </QcsMenuItem>
            </Select>
            <Select name="state" label={t('safetyEquipment.state')}>
              <QcsMenuItem value={SafetyEquipmentDtoStateEnum.Active}>
                {t('entityState.ACTIVE')}
              </QcsMenuItem>
              <QcsMenuItem value={SafetyEquipmentDtoStateEnum.Inactive}>
                {t('entityState.INACTIVE')}
              </QcsMenuItem>
            </Select>

            <SubmitAndNewButtons id={safetyEquipmentId} error={saveError} />
          </FormContainer>
        </Form>
      </FormikDebounce>
    </>
  );
};
