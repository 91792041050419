import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ErrorStateType } from '../../models/common';
import { useAppDispatch } from '../../store';
import { getIdentity } from '../../store/entities/identity';
import { storageResetAction } from '../../store/storeReset';
import { authenticationApi } from '../../utils/api';
import { setErrorState } from '../../utils/error';
import { logout, setMediaToken, setRefreshToken } from '../../utils/storage';
import { Loader } from '../common/Loader';
import { SaveError } from '../common/SaveError';

export const RemoteLinkPage: FC = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorStateType>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      logout();
      dispatch(storageResetAction());

      setError('');
      try {
        if (!token) {
          throw new Error();
        }

        const res = await authenticationApi.remoteToken(token, {
          _dontRedirect: true,
        } as any);

        dispatch(getIdentity(res.data.accessToken!, true));

        navigate('/');

        setRefreshToken(res.data.refreshToken!);
        setMediaToken(res.data.mediaToken!);
      } catch (err) {
        setErrorState(err, setError, 'auth.badLink');
      }
      setLoading(false);
    })();

    // don't fire on any change
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <SaveError error={error} />;
  }

  return null;
};
