import {
  InvitationDtoStateEnum,
  RiskWithFileResponse,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { GridList } from '../../../common/grid/GridList';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { BASE_URL_WITH_VERSION, invitationsApi } from '../../../../utils/api';
import { getMediaToken } from '../../../../utils/storage';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppSelector } from '../../../../store';
import { selectIdentity } from '../../../../store/entities/identity';
import { setErrorSnacks } from '../../../../utils/error';
import { getLangNameObj } from '../../../../utils/format';
import { hasRole } from '../../../../utils/roles';
import { useInvitation } from './invitationFunctions';

interface Props {
  workplaceId?: string;
  onClose: () => void;
}

export const WorkplaceRiskModal: FC<Props> = ({ workplaceId, onClose }) => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const mediaToken = getMediaToken();
  const identity = useAppSelector(selectIdentity);

  const workplace = invitation.workplaces?.find(
    (x) => x.workplace?.id === workplaceId
  );

  const handleRenderData = (item: RiskWithFileResponse) => {
    const downloadDisabled = !item.file?.path;
    const approveDisabled =
      downloadDisabled ||
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ExternalManager,
        UserDtoRolesEnum.ManagerWorkplace,
      ]) ||
      invitation.state === InvitationDtoStateEnum.Archived;
    const alreadyApproved = !!item.approvedOn;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.risk)}</QcsTableCell>
        <QcsTableCell align="right">
          <QcsTooltip
            title={t('invitation.tabs.tooltips.downloadRiskDocument')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDownload(item)}
                disabled={downloadDisabled}
                loading={isSaving}
              >
                <CloudDownloadIcon
                  color={downloadDisabled ? undefined : 'success'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.tabs.tooltips.approveRisk')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleApprove(item)}
                disabled={approveDisabled || alreadyApproved}
                loading={isSaving}
              >
                <CheckCircleIcon
                  color={
                    approveDisabled
                      ? undefined
                      : alreadyApproved
                      ? 'success'
                      : 'warning'
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleDownload = (item: RiskWithFileResponse) => () => {
    if (!item.file?.path) {
      return;
    }

    const url = `${BASE_URL_WITH_VERSION}${item.file.path}?token=${mediaToken}`;
    const link = document.createElement('a');
    link.href = url;
    // Taget for no "same-origin" - some browser can block download and redirect to file.
    link.target = '_blank';
    link.download = item.file!.fileName!;
    link.click();
  };

  const handleApprove = (item: RiskWithFileResponse) => async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveRisks(invitation.id!, {
        workplacesWithRisks: {
          [workplace!.workplace!.id]: [item.risk!.id!],
        },
      });
      await reloadInvitation();
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsModal open={!!workplace} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.workplace.riskTitle" />
        <GridList<RiskWithFileResponse>
          headers={[{ captionStr: 'invitation.workplace.riskName' }, {}]}
          data={workplace?.risks ?? []}
          renderData={handleRenderData}
          search={false}
          hidePagination={true}
        />
      </QcsModalBox>
    </QcsModal>
  );
};
