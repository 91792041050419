import {
  EstablishmentDto,
  EmployeeDto,
  EmployeeDtoStateEnum,
  EstablishmentDtoStateEnum,
} from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { FetchState } from '../../store/fetchState';
import { employeeApi } from '../../utils/api';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { CancelToken } from 'axios';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { Grid } from '../common/grid/Grid';
import {
  employeeActions,
  getEmployee,
  selectEmployee,
  selectEmployeeState,
} from '../../store/entities/employee';
import { employeeListActions } from '../../store/entities/employeeList';
import { QcsFormHelperText } from '../common/basic/QcsFormHelperText';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import styled from '@emotion/styled';
import { CreateUserModal } from './CreateUserModal';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { EstablishmentFilter } from '../establishment/EstablishmentFilter';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateUser,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { userListActions } from '../../store/entities/userList';
import { PhoneInput } from '../common/form/PhoneInput';
import { setErrorStateSnacks } from '../../utils/error';
import { getFirstLastNameObj, getLangNameObj } from '../../utils/format';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';
import { allEstablishmentListActions, getAllEstablishmentList, selectAllEstablishmentList } from '../../store/entities/allEstablishmentList';

interface EmployeeDetailForm extends EmployeeDto {
  saveAndNew?: boolean;
}

const UserButton = styled(QcsButton)(() => ({
  width: 'fit-content',
}));

export const EmployeeDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack, navigate } = useBack('/employee');
  const { employeeId } = useParams();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const employee = useAppSelector(selectEmployee);
  const employeeState = useAppSelector(selectEmployeeState);
  const establishmentList = useAppSelector(selectAllEstablishmentList);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateUser = useAppSelector(
    selectCompanyChecksCanCreateUser
  );
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [creatingUser, setCreatingUser] = useState(false);
  const formikRef = useRef<FormikProps<EmployeeDetailForm> | null>(null);

  useEffect(() => {
    if (employeeId === 'new') {
      dispatch(employeeActions.default());
    } else {
      dispatch(getEmployee(employeeId!));
      dispatch(getCompanyChecks(identityCompanyId!));
    }
    setLoading(false);
  }, [employeeId, identityCompanyId, dispatch]);

  const handleSubmit = async (data: EmployeeDetailForm) => {
    setSaveError('');

    try {
      if (employeeId === 'new') {
        await employeeApi.createEmployee(identityCompanyId, data);
        enqueueSuccessSnackbar(t('employee.addSuccess'));
      } else {
        await employeeApi.updateEmployee(employeeId!, data);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(employeeListActions.reload(true));
      dispatch(userListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        employeeId === 'new' ? 'employee.addError' : 'common.editError'
      );
    }
  };

  const handleReloadData = () => {
    dispatch(employeeListActions.reload(true));
    dispatch(userListActions.reload(true));
  };

  const handleEstablishmentGetData = (cancelToken: CancelToken) => {
    dispatch(getAllEstablishmentList(identityCompanyId, cancelToken));
  };

  const handleIsRowDisabled = (item: EstablishmentDto) =>
    item.state !== EstablishmentDtoStateEnum.Active;

  const handleEstablishmentRenderData = (item: EstablishmentDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {getFirstLastNameObj(item.contact)}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.email}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>{item.contact.phone}</QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleCreateUser = () => {
    setCreatingUser(true);
  };

  const handleCloseCreatingUser = () => {
    setCreatingUser(false);
  };

  const handleGoToUser = (userId: string) => {
    navigate(`/user/${userId}`, {
      state: { back: `/employee/${employeeId}` },
    });
  };

  if (
    loading ||
    employeeState === FetchState.Loading ||
    (employeeId !== 'new' && companyChecksState === FetchState.Loading)
  ) {
    return <Loader />;
  }

  if (
    employeeState === FetchState.Error ||
    (employeeId !== 'new' && companyChecksState === FetchState.Error)
  ) {
    return <ErrorAlert />;
  }

  return (
    <>
      <CreateUserModal
        open={creatingUser}
        onClose={handleCloseCreatingUser}
        reloadData={handleReloadData}
      />

      <DetailHeader title="employee.detailTitle" backTo={backTo} />
      {establishmentList.data?.content.length === 0 && (
        <QcsBox sx={{ pb: '1rem' }}>
          <QcsAlert severity="warning">
            {t('user.establishmentRequired')}
          </QcsAlert>
        </QcsBox>
      )}
      <FormikDebounce<EmployeeDetailForm>
        initialValues={employee}
        validationSchema={Yup.object().shape({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email: validations.emailRequired(t),
          establishments: validations.arrayNotEmpty(t),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ values, touched, errors, setFieldValue, setFieldTouched }) => (
          <Form>
            <FormContainer>
              {employeeId !== 'new' && !values.user && (
                <UserButton
                  variant="contained"
                  onClick={handleCreateUser}
                  disabled={!companyChecksCanCreateUser}
                >
                  {t('employee.createUser')}
                </UserButton>
              )}
              {values.user && (
                <UserButton
                  variant="contained"
                  onClick={() => handleGoToUser(values.user!)}
                >
                  {t('employee.goToUser')}
                </UserButton>
              )}
              <Input
                name="name"
                label={t('employee.name')}
                maxLength={100}
                required
              />
              <Input
                name="lastName"
                label={t('employee.lastName')}
                maxLength={100}
                required
              />
              <PhoneInput name="phone" label={t('employee.phone')} />
              <Input
                name="email"
                label={t('employee.email')}
                maxLength={100}
                required
              />

              <div>
                <QcsTypography variant="h6">
                  {t('employee.detailEstablishmentSelect')}:
                </QcsTypography>
                <Grid<EstablishmentDto>
                  headers={[
                    { captionStr: 'establishment.name', orderName: 'name' },
                    {
                      captionStr: 'establishment.contactName',
                      orderName: 'contact.name',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.contactEmail',
                      orderName: 'contact.email',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.contactPhone',
                      orderName: 'contact.phone',
                      hideOnMobile: true,
                    },
                    {
                      captionStr: 'establishment.state',
                      orderName: 'state',
                      hideOnMobile: true,
                    },
                  ]}
                  //establishmentList is used by Establishment.tsx - must have same filter (actual) or split store
                  data={establishmentList}
                  gridActions={allEstablishmentListActions}
                  renderData={handleEstablishmentRenderData}
                  isRowDisabled={handleIsRowDisabled}
                  getData={handleEstablishmentGetData}
                  checkbox={true}
                  checkedItems={values.establishments}
                  changeChecked={(items) => {
                    setFieldTouched('establishments', true);
                    setFieldValue('establishments', items);
                  }}
                  filter={
                    <EstablishmentFilter query={establishmentList.query} />
                  }
                />
                {touched.establishments && !!errors.establishments && (
                  <QcsFormHelperText error={true}>
                    {errors.establishments}
                  </QcsFormHelperText>
                )}
              </div>

              <Input
                name="office"
                label={t('employee.office')}
                maxLength={100}
              />
              <Input
                name="jobPosition"
                label={t('employee.jobPosition')}
                maxLength={100}
              />
              <Select name="state" label={t('employee.state')}>
                <QcsMenuItem value={EmployeeDtoStateEnum.Active}>
                  {t('entityState.ACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={EmployeeDtoStateEnum.Inactive}>
                  {t('entityState.INACTIVE')}
                </QcsMenuItem>
              </Select>
              <Input
                name="note"
                label={t('employee.note')}
                maxLength={250}
                multiline={true}
                rows={4}
              />
              <SubmitAndNewButtons id={employeeId} error={saveError} />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
