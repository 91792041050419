import {
  SupplierEmployeeDtoStateEnum,
  SupplierEmployeeDto,
  SupplierEmployeeDtoRoleEnum,
  UserDtoRolesEnum,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';

import {
  getSupplier,
  getSupplierFromUserId,
  selectSupplier,
  selectSupplierState,
} from '../../store/entities/supplier';
import { supplierEmployeeListActions } from '../../store/entities/supplierEmployeeList';
import { FetchState } from '../../store/fetchState';
import { applicationInvitationApi, supplierEmployeeApi } from '../../utils/api';
import { SaveError } from '../common/SaveError';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { DetailHeader } from '../common/DetailHeader';
import { ErrorAlert } from '../common/ErrorAlert';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { Select } from '../common/form/Select';
import { SubmitButton } from '../common/form/SubmitButton';
import { Loader } from '../common/Loader';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { QcsLoadingButton } from '../common/basic/QcsLoadingButton';
import {
  getSupplierEmployee,
  selectSupplierEmployee,
  selectSupplierEmployeeState,
  supplierEmployeeActions,
} from '../../store/entities/supplierEmployee';
import { QcsTextField } from '../common/basic/QcsTextField';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import styled from '@emotion/styled';
import { SUPPORTED_LANGUAGES } from '../../utils/i18n';
import { SupplierEmployeeDocument } from './SupplierEmployeeDocument';
import { PhoneInput } from '../common/form/PhoneInput';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import { setErrorSnacks, setErrorState } from '../../utils/error';
import { hasRole } from '../../utils/roles';
import { selectIdentity } from '../../store/entities/identity';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { SupplierEmployeeProtocol } from './SupplierEmployeeProtocol';
import { ErrorStateType } from '../../models/common';
import { useBack } from '../../hooks/useBack';

const PinContainer = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto max-content',
  gap: '1rem',
}));

export const SupplierEmployeeDetailRole = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  overflow: 'hidden',
}));

export const SupplierEmployeeDetail: FC = () => {
  const { t } = useTranslation();
  const { supplierId, supplierEmployeeId } = useParams();
  const identity = useAppSelector(selectIdentity);
  const { backTo, goBack } = useBack(
    hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])
      ? '/supplier'
      : `/supplier/${supplierId}`
  );
  const supplier = useAppSelector(selectSupplier);
  const supplierState = useAppSelector(selectSupplierState);
  const supplierEmployee = useAppSelector(selectSupplierEmployee);
  const supplierEmployeeState = useAppSelector(selectSupplierEmployeeState);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const [resendInvitationLoading, setResendInvitationLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [pin, setPin] = useState('');
  const [pinLoading, setPinLoading] = useState(false);
  const [pinError, setPinError] = useState<ErrorStateType>();
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [currentRole, setRole] = useState(supplierEmployee.role);


  useEffect(() => {
    setRole(supplierEmployee.role as any);
  },[supplierEmployee.role])
    
  useEffect(() => {
    if (supplierEmployeeId === 'new') {
      dispatch(supplierEmployeeActions.default());
      setRole(supplierEmployee.role as any);
    } else {
      dispatch(getSupplierEmployee(supplierEmployeeId!));
      setRole(supplierEmployee.role as any);
    }

    if (hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager])) {
      dispatch(getSupplierFromUserId(identity.id));
    } else {
      dispatch(getSupplier(supplierId!));
    }

    setLoading(false);
  }, [supplierEmployeeId, supplierId, dispatch, identity.roles, identity.id]);

  const handleSubmit = async (data: SupplierEmployeeDto) => {
    console.log(data);
    setSaveError('');
    
    if (currentRole === UserDtoRolesEnum.ExternalManager && data.email === '') {

      data.email = null as any as string;
    }
    try {
      if (supplierEmployeeId === 'new') {
        await supplierEmployeeApi.createSupplierEmployee(supplier.id!, data);
      } else {
        await supplierEmployeeApi.updateSupplierEmployee(
          supplierEmployeeId!,
          data
        );
      }

      dispatch(supplierEmployeeListActions.reload(true));
      goBack();
    } catch (err) {
      setErrorState(err, setSaveError);
    }
  };

  const handleRevealOrHidePin = async () => {
    if (pin) {
      setPin('');
      return;
    }

    setPinLoading(true);
    setPinError('');
    try {
      const pin = await supplierEmployeeApi.revealPin(supplierEmployeeId!);
      setPin(pin.data.pin!);
    } catch (err) {
      setErrorState(err, setPinError);
    }
    setPinLoading(false);
  };

  const handleResendInvitation = async () => {
    try {
      setResendInvitationLoading(true);
      await applicationInvitationApi.resendApplicationInvitation(
        supplierEmployee.id!
      );
      enqueueSuccessSnackbar(t('user.invitationSendOk'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar, 'user.invitationSendError');
    } finally {
      setResendInvitationLoading(false);
    }
  };

  if (
    loading ||
    supplierEmployeeState === FetchState.Loading ||
    supplierState === FetchState.Loading
  ) {
    return <Loader />;
  }

  if (
    supplierEmployeeState === FetchState.Error ||
    supplierState === FetchState.Error
  ) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader title="supplierEmployee.detailTitle" backTo={backTo} />
      <FormikDebounce<SupplierEmployeeDto>
        initialValues={{
          ...supplierEmployee,
          language:
            supplierEmployeeId === 'new'
              ? companyCustomization?.defaultLanguage ?? ''
              : supplierEmployee.language,
        }}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          name: validations.stringRequired(t),
          lastName: validations.stringRequired(t),
          phone: validations.phoneOptional(t),
          email:
            currentRole === UserDtoRolesEnum.ExternalManager
              ? validations.emailRequired(t)
              : validations.emailOptional(t),
          language: validations.stringRequired(t),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormContainer>
            <QcsTextField
              label={t('supplierEmployee.supplier')}
              value={supplier.name}
              sx={{ backgroundColor: (theme) => theme.palette.common.white }}
              disabled
            />
            <Input
              name="name"
              label={t('supplierEmployee.name')}
              maxLength={100}
              required
            />
            <Input
              name="lastName"
              label={t('supplierEmployee.lastName')}
              maxLength={100}
              required
            />
            <Input
              name="jobPosition"
              label={t('supplierEmployee.jobPosition')}
              maxLength={100}
            />
            <Select
              onChange={(e: any) => {
                setRole(e.target.value);
              }}
              name="role"
              label={t('supplierEmployee.role')}
            >
              <QcsMenuItem value={SupplierEmployeeDtoRoleEnum.ExternalManager}>
                <SupplierEmployeeDetailRole>
                  <EngineeringIcon />
                  <div>
                    {t(
                      'user.role.' + SupplierEmployeeDtoRoleEnum.ExternalManager
                    )}
                  </div>
                </SupplierEmployeeDetailRole>
              </QcsMenuItem>
              <QcsMenuItem value={SupplierEmployeeDtoRoleEnum.ExternalWorker}>
                <SupplierEmployeeDetailRole>
                  <ConstructionIcon />
                  <div>
                    {t(
                      'user.role.' + SupplierEmployeeDtoRoleEnum.ExternalWorker
                    )}
                  </div>
                </SupplierEmployeeDetailRole>
              </QcsMenuItem>
            </Select>
            <PhoneInput name="phone" label={t('supplierEmployee.phone')} />
            <Input
              name="email"
              label={t('supplierEmployee.email')}
              maxLength={100}
              required={currentRole === UserDtoRolesEnum.ExternalManager}
            />
            <LanguageSelect
              name="language"
              label={t('user.language')}
              languages={SUPPORTED_LANGUAGES}
              allowedLanguages={companyCustomization?.applicationLanguages}
              required
            />
            {supplierEmployeeId !== 'new' && (
              <QcsBox
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto max-content',
                  gap: '1rem',
                }}
              >
                <Select name="state" label={t('supplierEmployee.state')}>
                  <QcsMenuItem
                    value={SupplierEmployeeDtoStateEnum.Active}
                    disabled={
                      supplierEmployee.state ===
                      SupplierEmployeeDtoStateEnum.Invited
                    }
                  >
                    {t('entityState.ACTIVE')}
                  </QcsMenuItem>
                  <QcsMenuItem
                    value={SupplierEmployeeDtoStateEnum.Inactive}
                    disabled={
                      supplierEmployee.state ===
                      SupplierEmployeeDtoStateEnum.Invited
                    }
                  >
                    {t('entityState.INACTIVE')}
                  </QcsMenuItem>
                  <QcsMenuItem
                    value={SupplierEmployeeDtoStateEnum.Invited}
                    disabled={
                      supplierEmployee.state !==
                      SupplierEmployeeDtoStateEnum.Invited
                    }
                  >
                    {t('entityState.INVITED')}
                  </QcsMenuItem>
                </Select>
                <QcsLoadingButton
                  loading={resendInvitationLoading}
                  onClick={handleResendInvitation}
                  disabled={
                    supplierEmployee.state !==
                    SupplierEmployeeDtoStateEnum.Invited
                  }
                >
                  {t('supplierEmployee.sendInvitation')}
                </QcsLoadingButton>
              </QcsBox>
            )}

            <Input
              name="note"
              label={t('supplierEmployee.note')}
              maxLength={250}
              multiline={true}
              rows={4}
            />

            {supplierEmployeeId !== 'new' && (
              <>
                <PinContainer>
                  <QcsTextField
                    label={t('supplierEmployee.pin')}
                    value={pin || '******'}
                    sx={{
                      backgroundColor: (theme) => theme.palette.common.white,
                    }}
                    disabled
                  />
                  <QcsLoadingButton
                    onClick={handleRevealOrHidePin}
                    loading={pinLoading}
                  >
                    {pin
                      ? t('supplierEmployee.hidePin')
                      : t('supplierEmployee.revealPin')}
                  </QcsLoadingButton>
                </PinContainer>
                <SaveError error={pinError} />
              </>
            )}

            <SaveError error={saveError} />
            <SubmitButton />
          </FormContainer>
        </Form>
      </FormikDebounce>
      {supplierEmployeeId !== 'new' && <SupplierEmployeeProtocol />}
      {supplierEmployeeId !== 'new' && <SupplierEmployeeDocument />}
    </>
  );
};
