import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { QcsToggleButton } from '../basic/QcsToggleButton';
import { GridActions, ListFilter } from '../../../store/listHelper';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { QcsPopover } from '../basic/QcsPopover';
import { useAppDispatch } from '../../../store';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { GridFilterItemContainer } from './GridFilterStyles';
import styled from '@emotion/styled';

export const StyledQcsPopover = styled(QcsPopover)(() => ({
  '& .MuiPaper-root': {
    width: '20rem',
  },
}));

interface Props<U extends ListFilter> {
  query: U;
  gridActions: GridActions;
  name: string;
  title: string;
  disableAutoClose?: boolean;
  Component: FC<{
    value?: any;
    onChange: (value?: any) => void;
  }>;
}

export function GridFilterItem<U extends ListFilter>({
  query,
  gridActions,
  name,
  title,
  disableAutoClose,
  Component,
}: Props<U>) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const value = _.get(query, name);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: any) => {
    dispatch(gridActions.updateFilter({ [name]: value }));

    if (!disableAutoClose) {
      handleCloseMenu();
    }
  };

  const handleClear = () => {
    _.isArray(value) && handleChange([]);
    !_.isArray(value) && handleChange(undefined);
  };

  const selected =
    (_.isArray(value) && value.length !== 0) ||
    (!_.isArray(value) && value !== undefined);

  return (
    <>
      <QcsToggleButton value="" selected={selected} onChange={handleOpenMenu}>
        {t(title)}
        <ArrowDropDownIcon />
      </QcsToggleButton>
      <StyledQcsPopover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <GridFilterItemContainer>
          <Component value={value} onChange={handleChange} />
        </GridFilterItemContainer>
        <GridFilterItemContainer>
          <QcsButton disabled={!selected} onClick={handleClear}>
            {t('common.clearFilter')}
          </QcsButton>
        </GridFilterItemContainer>
      </StyledQcsPopover>
    </>
  );
}
