import { UserDtoRolesEnum } from '@qcs/safety-client';
import { Identity } from '../models/auth';
import { Location } from '@remix-run/router';

export const hasRole = (
  identityRoles?: UserDtoRolesEnum[],
  requiredRoles?: UserDtoRolesEnum[],
  requiredAll?: boolean
): boolean => {
  if (identityRoles === undefined || identityRoles.length === 0) {
    return false;
  }

  if (requiredRoles === undefined || requiredRoles.length === 0) {
    return true;
  }

  if (requiredAll) {
    return requiredRoles.every((x) => identityRoles.includes(x));
  } else {
    return requiredRoles.some((x) => identityRoles.includes(x));
  }
};

export const hasOtherRoleThan = (
  identityRoles?: UserDtoRolesEnum[],
  excludeRoles?: UserDtoRolesEnum[]
): boolean => {
  const requiredRoles = Object.values(UserDtoRolesEnum).filter(
    (x) => !(excludeRoles ?? []).includes(x)
  );
  return hasRole(identityRoles, requiredRoles);
};

export const isQcsAdminMode = (identity: Identity): boolean =>
  !identity.companyId && hasRole(identity.roles, [UserDtoRolesEnum.AdminQcs]);

export const isQcsAdminCompanyMode = (identity: Identity): boolean =>
  !!identity.companyId && hasRole(identity.roles, [UserDtoRolesEnum.AdminQcs]);

export const isTourniquet = (identity: Identity) =>
  hasRole(identity.roles, [
    UserDtoRolesEnum.Tourniquet,
    UserDtoRolesEnum.TourniquetTraining,
  ]);

export const isExternal = (identity: Identity) =>
  hasRole(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
    UserDtoRolesEnum.ExternalWorker,
    UserDtoRolesEnum.ExternalOneTime,
  ]);

export const isRemote = (identity: Identity) =>
  hasRole(identity.roles, [UserDtoRolesEnum.RemoteToken]);

export const getMenuNavLinkId = (id: string, identity: Identity) => {
  if (id === 'visitation' && isExternal(identity)) {
    return 'visitationExternal';
  }

  if (id === 'training' && isExternal(identity)) {
    return 'trainingExternal';
  }

  return id;
};

export const getMenuNavLinkClassName = (
  id: string,
  location: Location,
  identity: Identity
) => {
  if (
    id === 'qcscompany' &&
    location.pathname.toLowerCase().startsWith('/qcscompany')
  ) {
    return 'active';
  }

  if (
    id === 'invitation' &&
    location.pathname === '/' &&
    isExternal(identity)
  ) {
    return 'active';
  }

  return undefined;
};
