import { TableHead } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const QcsTableHead: FC<Props> = ({ className, children }) => {
  return <TableHead className={className}>{children}</TableHead>;
};
