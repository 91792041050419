import styled from '@emotion/styled';
import { Tooltip, SxProps, Theme } from '@mui/material';
import { FC, ReactElement } from 'react';

export const InnerTooltipWrapper = styled.div(() => ({
  display: 'inline-block',
}));

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  title?: React.ReactNode;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  followCursor?: boolean;
  children: ReactElement;
}

export const QcsTooltip: FC<Props> = ({
  className,
  sx,
  title,
  placement,
  followCursor,
  children,
}) => {
  return (
    <Tooltip
      className={className}
      sx={sx}
      title={title ?? ''}
      placement={placement}
      followCursor={followCursor}
    >
      {children}
    </Tooltip>
  );
};
