import { AddLanguageVariant, LanguageDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { trainingsApi } from '../../utils/api';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { LanguageSelect } from '../common/form/LanguageSelect';
import { FetchState } from '../../store/fetchState';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getAppTrainingLangList,
  selectAppTrainingLangList,
} from '../../store/entities/appTrainingLangList';
import { selectTrainingVersionLanguages } from '../../store/entities/trainingVersion';
import {
  getCompanyCustomization,
  selectCompanyCustomizationState,
  selectCompanyCustomizationTrainingLangCodes,
} from '../../store/entities/companyCustomization';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

interface AddLanguageForm {
  languageToCopy: string;
  language?: string;
}

interface Props {
  open: boolean;
  languageToCopy?: LanguageDto | null;
  onClose: () => void;
  reloadTrainingVersion: () => void;
}

export const AddLanguageModal: FC<Props> = ({
  open,
  languageToCopy,
  onClose,
  reloadTrainingVersion,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { trainingVersionId } = useParams();
  const dispatch = useAppDispatch();
  const appTrainingLangList = useAppSelector(selectAppTrainingLangList);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomizationState = useAppSelector(
    selectCompanyCustomizationState
  );
  const companyCustomizationTrainingLangCodes = useAppSelector(
    selectCompanyCustomizationTrainingLangCodes
  );
  const trainingVersionLanguages = useAppSelector(
    selectTrainingVersionLanguages
  );
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  useEffect(() => {
    if (companyCustomizationState === FetchState.None) {
      dispatch(getCompanyCustomization(identityCompanyId));
    }
    if (appTrainingLangList.state === FetchState.None) {
      dispatch(getAppTrainingLangList());
    }
  }, [
    appTrainingLangList.state,
    companyCustomizationState,
    dispatch,
    identityCompanyId,
  ]);

  const handleSubmit = async (formData: AddLanguageForm) => {
    setSaveError('');
    const data: AddLanguageVariant = {
      language: appTrainingLangList.data?.content.find(
        (x) => x.code === formData.language
      ) ?? { code: '', flag: '', name: '' },
    };

    try {
      if (languageToCopy) {
        await trainingsApi.copyLanguage(
          trainingVersionId!,
          languageToCopy.code,
          data
        );
        enqueueSuccessSnackbar(t('training.copy.success'));
      } else {
        await trainingsApi.addLanguage(trainingVersionId!, data);
        enqueueSuccessSnackbar(t('training.addLanguageSuccess'));
      }

      reloadTrainingVersion();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        languageToCopy ? 'training.copy.error' : 'training.addLanguageError'
      );
    }
  };

  const trainingLanguages = useMemo(
    () =>
      appTrainingLangList.data?.content.filter((x) =>
        companyCustomizationTrainingLangCodes?.includes(x.code)
      ),
    [appTrainingLangList.data?.content, companyCustomizationTrainingLangCodes]
  );

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader
          title={
            languageToCopy
              ? 'training.copy.addLanguage'
              : 'training.addLanguage'
          }
        />
        <FormikDebounce<AddLanguageForm>
          initialValues={{
            language: '',
            languageToCopy: languageToCopy?.code ?? '',
          }}
          validationSchema={Yup.object().shape({
            language: validations.stringRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              {!!languageToCopy && (
                <LanguageSelect
                  name="languageToCopy"
                  label={t('training.copy.languageToCopy')}
                  languages={trainingLanguages}
                  disabled
                />
              )}
              <LanguageSelect
                name="language"
                label={t('training.language')}
                languages={trainingLanguages}
                notAllowedLanguages={trainingVersionLanguages?.map(
                  (trainingVersionLanguage) =>
                    trainingVersionLanguage.language.code
                )}
                required
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
