import { TrainingDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  trainingListActions,
  getTrainingList,
  selectTrainingList,
} from '../../store/entities/trainingList';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { QcsTableCell } from '../common/basic/QcsTableCell';
import { Grid } from '../common/grid/Grid';
import { SectionHeader } from '../common/SectionHeader';
import { TrainingFilter } from './TrainingFilter';
import {
  getCompanyChecks,
  selectCompanyChecksCanCreateTraining,
  selectCompanyChecksState,
} from '../../store/entities/companyChecks';
import { FetchState } from '../../store/fetchState';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { getLangNameObj, joinStrings2 } from '../../utils/format';

export const Training: FC = () => {
  const { t, i18n } = useTranslation();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const trainingList = useAppSelector(selectTrainingList);
  const companyChecksState = useAppSelector(selectCompanyChecksState);
  const companyChecksCanCreateTraining = useAppSelector(
    selectCompanyChecksCanCreateTraining
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getCompanyChecks(identityCompanyId!));
    setLoading(false);
  }, [identityCompanyId, dispatch]);

  const handleGetData = (cancelToken: CancelToken) => {
    dispatch(getTrainingList(identityCompanyId, cancelToken));
  };

  const handleRenderData = (item: TrainingDto) => {
    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item)}</QcsTableCell>
        <QcsTableCell>
          {joinStrings2(
            item.questionnaire?.map((x) => getLangNameObj(i18n, x))
          )}
        </QcsTableCell>
        <QcsTableCell hideOnMobile={true}>
          {t('entityState.' + item.state)}
        </QcsTableCell>
      </>
    );
  };

  const handleRowClick = (item: TrainingDto) => {
    navigate(`/training/${item.id}`);
  };

  const handleAddClick = () => {
    navigate('/training/new');
  };

  if (loading || companyChecksState === FetchState.Loading) {
    return <Loader />;
  }

  if (companyChecksState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <SectionHeader
        title="training.title"
        addText="training.add"
        addDisabled={!companyChecksCanCreateTraining}
        handleAddClick={handleAddClick}
      />
      <Grid<TrainingDto>
        headers={[
          { captionStr: 'training.name', orderName: 'name' },
          {
            captionStr: 'training.questionnaires',
            orderName: 'questionnaire',
          },
          {
            captionStr: 'training.state',
            orderName: 'state',
            hideOnMobile: true,
          },
        ]}
        data={trainingList}
        gridActions={trainingListActions}
        renderData={handleRenderData}
        onRowClick={handleRowClick}
        getData={handleGetData}
        filter={<TrainingFilter query={trainingList.query} />}
      />
    </>
  );
};
