import {
  InvitationActivityDto,
  UserDtoRolesEnum,
  InvitationDtoStateEnum,
} from '@qcs/safety-client';
import { FC, useState } from 'react';
import { QcsTableCell } from '../../../common/basic/QcsTableCell';
import { GridList } from '../../../common/grid/GridList';
import { QcsLoadingButton } from '../../../common/basic/QcsLoadingButton';
import { CellYesNo } from '../../../common/grid/CellYesNo';
import { hasOtherRoleThan, hasRole, isExternal } from '../../../../utils/roles';
import { useAppSelector } from '../../../../store';
import { selectIdentity } from '../../../../store/entities/identity';
import { invitationsApi } from '../../../../utils/api';
import { ActivityCertificateModal } from './ActivityCertificateModal';
import { setErrorSnacks } from '../../../../utils/error';
import {
  InnerTooltipWrapper,
  QcsTooltip,
} from '../../../common/basic/QcsTooltip';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ActivityAddModal } from './ActivityAddModal';
import { ActivityDeleteModal } from './ActivityDeleteModal';
import { FaIcon } from '../../../common/FaIcon';
import {
  faAddressCard,
  faRoadBarrier,
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material';
import { ActivityRiskModal } from './ActivityRiskModal';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { ActivityPersonModal } from './ActivityPersonModal';
import {
  getLangNameObj,
  invitationCertificateIsApproved,
} from '../../../../utils/format';
import { useInvitation } from './invitationFunctions';
import { selectCompanyCustomization } from '../../../../store/entities/companyCustomization';

export const ActivityTable: FC = () => {
  const {
    t,
    i18n,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
  } = useInvitation();

  const theme = useTheme();
  const identity = useAppSelector(selectIdentity);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const [riskModal, setRiskModal] = useState<InvitationActivityDto>();
  const [personModal, setPersonModal] = useState<InvitationActivityDto>();
  const [certificateModal, setCertificateModal] =
    useState<InvitationActivityDto>();
  const [checkedItems, setCheckedItems] = useState<InvitationActivityDto[]>([]);
  const [activityToDelete, setActivityToDelete] =
    useState<InvitationActivityDto>();
  const [activityAddModal, setActivityAddModal] = useState<boolean>(false);

  const handleRenderData = (item: InvitationActivityDto) => {
    const showRiskDisabled = !item.activity?.riskRequired;
    const showRiskOk =
      !showRiskDisabled &&
      !!item.document &&
      (!hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ManagerWorkplace,
      ]) ||
        !!item.approvedOn);
    const personOk =
      invitation.persons?.some(
        (person) =>
          person.activities?.some(
            (activity) => activity.id === item.activity?.id
          ) === true
      ) === true;
    const showCertificateDisabled =
      (item.activity?.certificates?.length ?? 0) < 1;
    const showCertificateOk =
      !showCertificateDisabled &&
      (invitation.persons ?? []).every((person) =>
        (person.documents ?? [])
          .filter(
            (document) =>
              item?.activity?.certificates?.includes(
                document.certificate?.id ?? ''
              ) === true
          )
          .every((document) =>
            isExternal(identity)
              ? !!document.document
              : !!document.document && invitationCertificateIsApproved(document)
          )
      );

    const deleteDisabled =
      !hasRole(identity.roles, [
        UserDtoRolesEnum.ManagerOsah,
        UserDtoRolesEnum.ManagerWorkplace,
      ]) ||
      //At least one activity must left.
      (invitation.activities ?? []).length < 2 ||
      //After delete no person with no activity.
      invitation.persons?.some(
        (person) =>
          person.activities?.some(
            (activity) => activity.id === item.activity?.id
          ) === true && person.activities.length === 1
      ) === true;

    return (
      <>
        <QcsTableCell>{getLangNameObj(i18n, item.activity)}</QcsTableCell>
        {companyCustomization?.passRisksEnabled && (
          <CellYesNo value={item.activity?.riskRequired} hideOnMobile={true} />
        )}
        <QcsTableCell align="right">
          <QcsTooltip
            title={t('invitation.tabs.tooltips.showRisk')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleShowRisk(item)}
                loading={isSaving}
                disabled={showRiskDisabled}
              >
                <FaIcon
                  icon={faRoadBarrier}
                  color={
                    showRiskDisabled
                      ? undefined
                      : showRiskOk
                      ? theme.palette.success.main
                      : theme.palette.warning.main
                  }
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          <QcsTooltip
            title={t('invitation.workplace.personTitle')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleShowPerson(item)}
                loading={isSaving}
              >
                <ManageAccountsOutlinedIcon
                  color={personOk ? 'success' : 'warning'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>

          {/* SHOW CERTIFICATE */}
          {
            <QcsTooltip
              title={t('invitation.tabs.tooltips.showCertificate')}
              placement="top"
            >
              <InnerTooltipWrapper>
                <QcsLoadingButton
                  onClick={handleShowCertificate(item)}
                  loading={isSaving}
                  disabled={showCertificateDisabled}
                >
                  <FaIcon
                    icon={faAddressCard}
                    color={
                      showCertificateDisabled
                        ? undefined
                        : showCertificateOk
                        ? theme.palette.success.main
                        : theme.palette.warning.main
                    }
                  />
                </QcsLoadingButton>
              </InnerTooltipWrapper>
            </QcsTooltip>
          }

          {/* DELETE WORKPLACE */}
          <QcsTooltip
            title={t('invitation.activity.deleteActivity')}
            placement="top"
          >
            <InnerTooltipWrapper>
              <QcsLoadingButton
                onClick={handleDeleteActivity(item)}
                loading={isSaving}
                disabled={deleteDisabled}
              >
                <DeleteForeverIcon
                  color={deleteDisabled ? undefined : 'error'}
                />
              </QcsLoadingButton>
            </InnerTooltipWrapper>
          </QcsTooltip>
        </QcsTableCell>
      </>
    );
  };

  const handleChangeChecked = (items: InvitationActivityDto[]) => {
    setCheckedItems(items);
  };

  const handleItemToSelectObject = (item: InvitationActivityDto) => item;

  const handleSelectObjectGetId = (item: InvitationActivityDto) => item.id!;

  const handleCheckDisabled = (item: InvitationActivityDto) =>
    !item.activity?.riskRequired || !item.document;

  const handleApprove = async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.approveActivities(invitation.id!, {
        entries: checkedItems.map((x) => x.id!),
      });
      await reloadInvitation();
      enqueueSuccessSnackbar(t('invitation.activity.approveSuccess'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleSend = async () => {
    setIsSaving(true);
    lockInvitation();
    try {
      await invitationsApi.sendSupplierRisksForApproval(invitation.id!, {
        entries: checkedItems.map((x) => x.id!),
      });
      await reloadInvitation();
      enqueueSuccessSnackbar(t('invitation.activity.sendSuccess'));
    } catch (err) {
      setErrorSnacks(err, enqueueErrorSnackbar);
    }
    setIsSaving(false);
    unlockInvitation();
  };

  const handleShowRisk =
    (item: InvitationActivityDto) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setRiskModal(item);
    };

  const handleCloseRisk = () => {
    setRiskModal(undefined);
  };

  const handleShowPerson =
    (item: InvitationActivityDto) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setPersonModal(item);
    };

  const handleClosePerson = () => {
    setPersonModal(undefined);
  };

  const handleShowCertificate =
    (item: InvitationActivityDto) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setCertificateModal(item);
    };

  const handleCloseCertificate = () => {
    setCertificateModal(undefined);
  };

  const handleDeleteActivity =
    (item: InvitationActivityDto) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setActivityToDelete(item);
    };

  const handleCloseDeleteActivity = () => {
    setActivityToDelete(undefined);
  };

  const handleAddActivity = () => {
    setActivityAddModal(true);
  };

  const handleCloseAddActivity = () => {
    setActivityAddModal(false);
  };

  const showApproveButton = hasRole(identity.roles, [
    UserDtoRolesEnum.ManagerOsah,
    UserDtoRolesEnum.ManagerWorkplace,
    UserDtoRolesEnum.ExternalManager,
  ]);

  const showAddButton = hasOtherRoleThan(identity.roles, [
    UserDtoRolesEnum.ExternalManager,
    UserDtoRolesEnum.ExternalWorker,
  ]);

  return (
    <>
      <ActivityCertificateModal
        item={certificateModal}
        onClose={handleCloseCertificate}
      />
      <ActivityDeleteModal
        activity={activityToDelete}
        onClose={handleCloseDeleteActivity}
      />
      <ActivityAddModal
        open={activityAddModal}
        onClose={handleCloseAddActivity}
      />
      <ActivityRiskModal itemId={riskModal?.id} onClose={handleCloseRisk} />
      <ActivityPersonModal item={personModal} onClose={handleClosePerson} />

      <QcsBox
        sx={{
          display: 'flex',
          justifyContent:
            showApproveButton && showAddButton
              ? 'space-between'
              : showApproveButton
              ? 'flex-start'
              : 'flex-end',
        }}
      >
        {showApproveButton && (
          <>
            {hasRole(identity.roles, [UserDtoRolesEnum.ExternalManager]) ? (
              <QcsLoadingButton
                variant="contained"
                onClick={handleSend}
                disabled={
                  invitation.state === InvitationDtoStateEnum.Archived ||
                  checkedItems.length < 1
                }
                loading={isSaving}
                sx={{ mb: '1rem' }}
              >
                {t('invitation.activity.send')}
              </QcsLoadingButton>
            ) : (
              <QcsLoadingButton
                variant="contained"
                onClick={handleApprove}
                disabled={
                  invitation.state === InvitationDtoStateEnum.Archived ||
                  checkedItems.length < 1
                }
                loading={isSaving}
                sx={{ mb: '1rem' }}
              >
                {t('invitation.activity.approve')}
              </QcsLoadingButton>
            )}
          </>
        )}
        {showAddButton && (
          <QcsLoadingButton
            variant="contained"
            onClick={handleAddActivity}
            loading={isSaving}
            sx={{ mb: '1rem' }}
          >
            {t('activity.add')}
          </QcsLoadingButton>
        )}
      </QcsBox>

      <GridList<InvitationActivityDto, InvitationActivityDto>
        headers={[
          { captionStr: 'invitation.activity.name' },
          companyCustomization?.passRisksEnabled
            ? {
                captionStr: 'invitation.activity.riskRequired',
                hideOnMobile: true,
              }
            : undefined,
          {},
        ]}
        data={invitation.activities ?? []}
        renderData={handleRenderData}
        search={false}
        hidePagination={true}
        checkbox={true}
        checkedItems={checkedItems}
        changeChecked={handleChangeChecked}
        itemToSelectObject={handleItemToSelectObject}
        selectObjectGetId={handleSelectObjectGetId}
        isCheckDisabled={handleCheckDisabled}
      />
    </>
  );
};
