import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  ActivityDto,
  ActivityDtoStateEnum,
  ReferenceDto,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { activityApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<ActivityDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsActivitySlice = baseSlice({
  name: 'optionsActivity',
  initialState,
});

export const selectOptionsActivityState = (state: RootState) =>
  state.optionsActivity.state;
export const selectOptionsActivity = (state: RootState) =>
  state.optionsActivity.data.map(
    (x) => ({ id: x.id, name: x.name, nameEn: x.nameEn } as ReferenceDto)
  );
export const optionsActivityActions = optionsActivitySlice.actions;

export const getOptionsActivity =
  (
    companyId: string,
    oneTimeEntry: boolean | undefined,
    search: string | undefined,
    cancelToken: CancelToken
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await activityApi.queryActivities(
        companyId,
        search,
        undefined, //riskRequired
        undefined, //certificateRequired
        ActivityDtoStateEnum.Active,
        oneTimeEntry,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsActivityActions,
      getData: handleGetData,
    });
  };
