import { ReferenceDto } from '@qcs/safety-client';
import { CancelToken } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import {
  getOptionsEstablishment,
  selectOptionsEstablishment,
  selectOptionsEstablishmentState,
} from '../../../store/entities/optionsEstablishment';
import { FetchState } from '../../../store/fetchState';
import { getLangNameObj } from '../../../utils/format';
import { QcsAutocompleteWithoutFormik } from '../form/QcsAutocompleteWithoutFormik';

interface Props {
  value?: ReferenceDto[];
  onChange: (value?: ReferenceDto[]) => void;
}

export const GridFilterEstablishmentMultiple: FC<Props> = ({
  value,
  onChange,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsEstablishment = useAppSelector(selectOptionsEstablishment);
  const optionsEstablishmentState = useAppSelector(
    selectOptionsEstablishmentState
  );

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(
      getOptionsEstablishment(identityCompanyId, search, cancelToken)
    );
  };

  const handleChange = (value?: ReferenceDto | ReferenceDto[] | null) => {
    onChange((value as ReferenceDto[]) ?? undefined);
  };

  return (
    <QcsAutocompleteWithoutFormik
      options={optionsEstablishment}
      loading={optionsEstablishmentState === FetchState.Loading}
      loadingError={optionsEstablishmentState === FetchState.Error}
      loadOptions={loadOptions}
      value={value}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
      onChange={handleChange}
      multiple={true}
    />
  );
};
