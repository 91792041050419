import styled from '@emotion/styled';
import React, { FC, Suspense } from 'react';
import { QcsPaper } from '../basic/QcsPaper';
import { Loader } from '../Loader';

const HtmlEditorLazy = React.lazy(() => import('./lazy/HtmlEditorLazy'));

const LoaderWraper = styled.div(() => ({
  margin: '1rem',
}));

interface Props {
  html?: string | null;
  getHtmlRef: React.MutableRefObject<(() => string) | null>;
  reloadHtmlRef: React.MutableRefObject<(() => void) | null>;
  allowSelectRisks: boolean;
  setFocusValue?: boolean;
  setFocusFunc?: (value: boolean) => void;
}

export const HtmlEditor: FC<Props> = ({
  html,
  getHtmlRef,
  reloadHtmlRef,
  allowSelectRisks,
  setFocusValue,
  setFocusFunc,
}) => {
  return (
    <QcsPaper sx={{ maxWidth: '980px' }}>
      <Suspense
        fallback={
          <LoaderWraper>
            <Loader />
          </LoaderWraper>
        }
      >
        <HtmlEditorLazy
          html={html}
          getHtmlRef={getHtmlRef}
          reloadHtmlRef={reloadHtmlRef}
          allowSelectRisks={allowSelectRisks}
          setFocusValue={setFocusValue}
          setFocusFunc={setFocusFunc}
        />
      </Suspense>
    </QcsPaper>
  );
};
