import { ReferenceDto } from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { workplaceApi } from '../../utils/api';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../common/basic/QcsModalBox';
import { TrainingAutocomplete } from '../common/form/TrainingAutocomplete';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { ErrorStateType } from '../../models/common';

interface AddTrainingForm {
  training?: ReferenceDto | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
  reloadTrainings: () => void;
  excludeIds: string[];
}

export const AddTrainingModal: FC<Props> = ({
  open,
  onClose,
  reloadTrainings,
  excludeIds,
}) => {
  const { t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { workplaceId } = useParams();
  const [saveError, setSaveError] = useState<ErrorStateType>();

  useEffect(() => {
    setSaveError('');
  }, [open]);

  const handleSubmit = async (data: AddTrainingForm) => {
    setSaveError('');
    try {
      await workplaceApi.addTrainingToWorkPlace(workplaceId!, data.training!);
      enqueueSuccessSnackbar(t('workplaceTraining.addSuccess'));
      reloadTrainings();
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'workplaceTraining.addError'
      );
    }
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="workplaceTraining.add" />
        <FormikDebounce<AddTrainingForm>
          initialValues={{ training: null }}
          validationSchema={Yup.object().shape({
            training: validations.objectRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <TrainingAutocomplete
                name="training"
                label={t('workplaceTraining.training')}
                required
                excludeIds={excludeIds}
                ignoreWithoutQuestionnaireWithLanguage
              />

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
