import { WorkplaceDto, WorkplaceDtoStateEnum } from '@qcs/safety-client';
import { Form, FormikProps } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  workplaceActions,
  getWorkplace,
  selectWorkplace,
  selectWorkplaceState,
} from '../../store/entities/workplace';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { FetchState } from '../../store/fetchState';
import { Input } from '../common/form/Input';
import { workplaceApi } from '../../utils/api';
import { workplaceListActions } from '../../store/entities/workplaceList';
import { Checkbox } from '../common/form/Checkbox';
import { Select } from '../common/form/Select';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { Loader } from '../common/Loader';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import * as Yup from 'yup';
import { validations } from '../../utils/validations';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import { SafetyEquipmentAutocomplete } from '../common/form/SafetyEquipmentAutocomplete';
import { WorkplaceTraining } from './WorkplaceTraining';
import { RiskAutocomplete } from '../common/form/RiskAutocomplete';
import styled from '@emotion/styled';
import { SafetyEquipmentSelectTableModal } from './SafetyEquipmentSelectTableModal';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { FaIcon } from '../common/FaIcon';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { InputWithButtonContainer } from '../common/form/formStyles';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { setErrorStateSnacks } from '../../utils/error';
import { SubmitAndNewButtons } from '../common/form/SubmitAndNewButtons';
import { InputTextWithLang } from '../common/form/InputTextWithLang';
import { ErrorStateType } from '../../models/common';
import { selectCompanyCustomization } from '../../store/entities/companyCustomization';
import { fixNameInitValue } from '../../utils/format';
import { useBack } from '../../hooks/useBack';

type Validator = {
  name?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameEn?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameRu?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  nameDe?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  namePl?: Yup.StringSchema<string, Yup.AnyObject, undefined, ''>;
  establishment: Yup.ObjectSchema<object, Yup.AnyObject, object, ''>;
  risks:
    | Yup.ISchema<any, any, any, any>
    | Yup.ArraySchema<any[] | undefined, Yup.AnyObject, undefined, ''>;
};
interface WorkplaceDetailForm extends WorkplaceDto {
  saveAndNew?: boolean;
}

const WorkplaceTrainingWrapper = styled.div(() => ({
  marginTop: '2rem',
}));

export const WorkplaceDetail: FC = () => {
  const { i18n, t } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const { backTo, goBack } = useBack('/workplace');
  const { workplaceId } = useParams();
  const workplace = useAppSelector(selectWorkplace);
  const workplaceState = useAppSelector(selectWorkplaceState);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const companyCustomization = useAppSelector(selectCompanyCustomization);
  const dispatch = useAppDispatch();
  const [openSafetyEquipmentTable, setOpenSafetyEquipmentTable] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const formikRef = useRef<FormikProps<Partial<WorkplaceDetailForm>> | null>(
    null
  );

  useEffect(() => {
    if (workplaceId === 'new') {
      dispatch(workplaceActions.default());
    } else {
      dispatch(getWorkplace(workplaceId!));
    }
    setLoading(false);
  }, [workplaceId, dispatch]);

  const handleSubmit = async (data: Partial<WorkplaceDetailForm>) => {
    setSaveError('');

    try {
      if (workplaceId === 'new') {
        await workplaceApi.createWorkplace(
          identityCompanyId,
          data as WorkplaceDto
        );
        enqueueSuccessSnackbar(t('workplace.addSuccess'));
      } else {
        await workplaceApi.updateWorkplace(workplaceId!, data as WorkplaceDto);
        enqueueSuccessSnackbar(t('common.editSuccess'));
      }

      dispatch(workplaceListActions.reload(true));

      if (data.saveAndNew) {
        formikRef.current?.resetForm();
      } else {
        goBack();
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        workplaceId === 'new' ? 'workplace.addError' : 'common.editError'
      );
    }
  };

  if (loading || workplaceState === FetchState.Loading) {
    return <Loader />;
  }

  if (workplaceState === FetchState.Error) {
    return <ErrorAlert />;
  }

  const validator: Validator = {
    establishment: validations.objectRequired(t),
    risks: Yup.array().when('riskRequired', {
      is: (riskRequired: string) => riskRequired,
      then: () => validations.arrayNotEmpty(t),
    }),
  };

  if (companyCustomization?.applicationLanguages.includes('cs')) {
    validator['name'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('en')) {
    validator['nameEn'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('de')) {
    validator['nameDe'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('pl')) {
    validator['namePl'] = validations.stringRequired(t);
  }
  if (companyCustomization?.applicationLanguages.includes('ru')) {
    validator['nameRu'] = validations.stringRequired(t);
  }

  return (
    <>
      <DetailHeader title="workplace.detailTitle" backTo={backTo} />
      <FormikDebounce<Partial<WorkplaceDetailForm>>
        initialValues={{
          ...workplace,
          ...fixNameInitValue(i18n, workplace),
          riskRequired: companyCustomization?.passRisksEnabled
            ? workplace.riskRequired
            : false,
          risks: companyCustomization?.passRisksEnabled ? workplace.risks : [],
        }}
        validationSchema={Yup.object().shape(validator)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <InputTextWithLang
                isNew={workplaceId === 'new'}
                label={t('workplace.name')}
                required
              />

              <EstablishmentAutocomplete
                name="establishment"
                label={t('workplace.establishment')}
                required
              />

              <Checkbox
                name="riskRequired"
                label={t('workplace.riskRequired')}
                disabled={!companyCustomization?.passRisksEnabled}
              />

              <RiskAutocomplete
                multiple
                name="risks"
                label={t('workplace.risks')}
                required={!!values.riskRequired}
                disabled={!companyCustomization?.passRisksEnabled}
              />

              <InputWithButtonContainer>
                <SafetyEquipmentAutocomplete
                  multiple
                  name="safetyEquipments"
                  label={t('workplace.safetyEquipments')}
                />
                <QcsButton onClick={() => setOpenSafetyEquipmentTable(true)}>
                  <FaIcon icon={faTableList} />
                </QcsButton>
              </InputWithButtonContainer>

              <SafetyEquipmentSelectTableModal
                name="safetyEquipments"
                open={openSafetyEquipmentTable}
                onClose={() => {
                  setOpenSafetyEquipmentTable(false);
                }}
              />

              <Select name="state" label={t('workplace.state')}>
                <QcsMenuItem value={WorkplaceDtoStateEnum.Active}>
                  {t('entityState.ACTIVE')}
                </QcsMenuItem>
                <QcsMenuItem value={WorkplaceDtoStateEnum.Inactive}>
                  {t('entityState.INACTIVE')}
                </QcsMenuItem>
              </Select>

              <Input
                name="note"
                label={t('workplace.note')}
                multiline={true}
                rows={4}
              />

              <SubmitAndNewButtons id={workplaceId} error={saveError} />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>

      {workplaceId !== 'new' && (
        <WorkplaceTrainingWrapper>
          <WorkplaceTraining />
        </WorkplaceTrainingWrapper>
      )}
    </>
  );
};
