import { FC } from 'react';
import { GridFilterItem } from '../common/grid/GridFilterItem';
import { userListActions, UserListFilter } from '../../store/entities/userList';
import { GridFilterUserRole } from '../common/grid/GridFilterUserRole';
import { GridFilterUserState } from '../common/grid/GridFilterUserState';
import { GridFilterEstablishmentMultiple } from '../common/grid/GridFilterEstablishmentMultiple';

interface Props {
  query: UserListFilter;
}

export const UserFilter: FC<Props> = ({ query }) => {
  return (
    <>
      <GridFilterItem
        query={query}
        gridActions={userListActions}
        name="roles"
        title="user.roles"
        Component={GridFilterUserRole}
        disableAutoClose
      />
      <GridFilterItem
        query={query}
        gridActions={userListActions}
        name="state"
        title="user.state"
        Component={GridFilterUserState}
        disableAutoClose
      />
      <GridFilterItem
        query={query}
        gridActions={userListActions}
        name="establishments"
        title="user.establishments"
        disableAutoClose={true}
        Component={GridFilterEstablishmentMultiple}
      />
    </>
  );
};
