import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import {
  getOptionsRisk,
  selectOptionsRisk,
  selectOptionsRiskState,
} from '../../../store/entities/optionsRisk';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { getLangNameObj } from '../../../utils/format';
import { useTranslation } from 'react-i18next';

interface Props {
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  excludeIds?: string[];
}

export const RiskAutocomplete: FC<Props> = ({
  multiple,
  name,
  label,
  disabled,
  required,
  excludeIds: filterIds,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsRisk = useAppSelector(selectOptionsRisk);
  const optionsRiskState = useAppSelector(selectOptionsRiskState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(getOptionsRisk(identityCompanyId, search, cancelToken));
  };

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={
        filterIds
          ? optionsRisk.filter((x) => !filterIds?.includes(x.id!))
          : optionsRisk
      }
      loading={optionsRiskState === FetchState.Loading}
      loadingError={optionsRiskState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
    />
  );
};
