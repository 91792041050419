import styled from '@emotion/styled';
import {
  HEADER_HEIGHT,
  MIN_HEIGHT_FOR_FIXED_HEADER,
} from '../../utils/constants';
import { Theme } from '@mui/material';

export const mediaForFixedHeader = (theme: Theme) =>
  `@media (min-width: ${theme.breakpoints.values.sm}px) and (min-height: ${MIN_HEIGHT_FOR_FIXED_HEADER})`;

export const LayoutWrapper = styled.div(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'visible',
  backgroundColor: theme.palette.background.default,
}));

export const LayoutContainer = styled.div(({ theme }) => ({
  minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
  display: 'flex',

  [mediaForFixedHeader(theme)]: {
    height: `calc(100vh - ${HEADER_HEIGHT})`,
  },
}));

export const ContentContainerWrapper = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `auto min(100%, ${theme.breakpoints.values.xl}px) auto`,
  gridTemplateAreas: '". content-container ."',
  minHeight: `calc(100vh - ${HEADER_HEIGHT} - 3px)`,
  height: '100%',
  width: '100%',
  marginTop: '3px',
  overflowX: 'auto',

  [mediaForFixedHeader(theme)]: {
    height: '100%',
  },
}));

export const ContentContainer = styled.div(() => ({
  gridArea: 'content-container',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1.5rem',
}));

export const CenterLayoutWrapper = styled.div(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FooterContainer = styled.div(() => ({
  margin: 'auto auto -0.75rem auto',
  paddingTop: '2rem',
  fontSize: '0.8rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));
