import { FC } from 'react';
import { useAppSelector } from '../../store';
import { selectTourniquetTraining } from '../../store/entities/tourniquetTraining';
import { TourniquetTrainingOverview } from './TourniquetTrainingOverview';
import { TourniquetTrainingQuestionnaire } from './TourniquetTrainingQuestionnaire';
import { TourniquetTrainingSign } from './TourniquetTrainingSign';
import { TourniquetTrainingSlides } from './TourniquetTrainingSlides';

interface Props {
  onEndTraining: (success?: boolean) => void;
}

export const TourniquetTraining: FC<Props> = ({ onEndTraining }) => {
  const tourniquetTraining = useAppSelector(selectTourniquetTraining);

  if (tourniquetTraining.mode === 'training') {
    return <TourniquetTrainingSlides onEndTraining={onEndTraining} />;
  } else if (tourniquetTraining.mode === 'trainingSign') {
    return <TourniquetTrainingSign onEndTraining={onEndTraining} />;
  } else if (tourniquetTraining.mode === 'questionnaire') {
    return <TourniquetTrainingQuestionnaire onEndTraining={onEndTraining} />;
  } else if (tourniquetTraining.mode === 'overview') {
    return <TourniquetTrainingOverview onEndTraining={onEndTraining} />;
  }

  return null;
};
