import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { TrainingDto, TrainingDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { trainingsApi } from '../../utils/api';
import { AUTOCOMPLETE_MAX_ITEMS } from '../../utils/constants';
import { CancelToken } from 'axios';

type State = BaseType<TrainingDto[]>;

const initialState: State = {
  state: FetchState.None,
  data: [],
};

export const optionsTrainingSlice = baseSlice({
  name: 'optionsTraining',
  initialState,
});

export const selectOptionsTrainingState = (state: RootState) =>
  state.optionsTraining.state;
export const selectOptionsTraining = (state: RootState) =>
  state.optionsTraining.data;
export const optionsTrainingActions = optionsTrainingSlice.actions;

export const getOptionsTraining =
  (
    companyId: string,
    search: string | undefined,
    skipId: string | undefined,
    cancelToken: CancelToken,
    ignoreWithoutQuestionnaireWithLanguage: boolean
  ) =>
  (dispatch: AppDispatch) => {
    const handleGetData = async () => {
      const response = await trainingsApi.queryTrainings(
        companyId,
        search,
        skipId,
        TrainingDtoStateEnum.Active,
        true,
        ignoreWithoutQuestionnaireWithLanguage,
        0,
        AUTOCOMPLETE_MAX_ITEMS,
        ['name', 'asc'],
        { cancelToken: cancelToken as any }
      );
      return { data: response.data.content ?? [] };
    };

    getItem({
      dispatch,
      actions: optionsTrainingActions,
      getData: handleGetData,
    });
  };
