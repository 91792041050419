import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FetchState } from '../../../store/fetchState';
import { selectIdentityCompanyId } from '../../../store/entities/identity';
import { ReferenceDto } from '@qcs/safety-client';
import {
  getOptionsSupplier,
  selectOptionsSupplier,
  selectOptionsSupplierState,
} from '../../../store/entities/optionsSupplier';
import { QcsAutocomplete } from './QcsAutocomplete';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import { getLangNameObj } from '../../../utils/format';
import { SUPPLIER_VISIT_FAKE_ID } from '../../../utils/constants';

interface Props {
  multiple?: boolean;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  allowVisit?: boolean;
}

export const SupplierAutocomplete: FC<Props> = ({
  multiple,
  name,
  label,
  disabled,
  required,
  allowVisit,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const optionsSupplier = useAppSelector(selectOptionsSupplier);
  const optionsSupplierState = useAppSelector(selectOptionsSupplierState);

  const loadOptions = (
    search: string | undefined,
    cancelToken: CancelToken
  ) => {
    dispatch(getOptionsSupplier(identityCompanyId, search, cancelToken));
  };

  const data = useMemo(() => {
    if (allowVisit) {
      return [
        {
          id: SUPPLIER_VISIT_FAKE_ID,
          name: t('invitationTypeVisit.detailTitle'),
        },
        ...optionsSupplier,
      ];
    }

    return optionsSupplier;
  }, [optionsSupplier, allowVisit, t]);

  return (
    <QcsAutocomplete
      multiple={multiple}
      name={name}
      label={label}
      required={required}
      disabled={disabled}
      options={data}
      loading={optionsSupplierState === FetchState.Loading}
      loadingError={optionsSupplierState === FetchState.Error}
      loadOptions={loadOptions}
      getOptionId={(option: ReferenceDto) => option.id!}
      getOptionName={(option: ReferenceDto) => getLangNameObj(i18n, option)}
    />
  );
};
