import { useFormikContext } from 'formik';
import { FC, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { DocumentInputUploadModal } from '../risk/DocumentInputUploadModal';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsFormHelperText } from '../common/basic/QcsFormHelperText';
import { PROTOCOL_SECURED_MEDIA } from '../../utils/constants';
import styled from '@emotion/styled';
import { Link as  QcsLink } from '@mui/material';

import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FileUploadMediaResponse } from '@qcs/safety-client';
import { Variant } from '@mui/material/styles/createTypography';
import { useTheme } from '@mui/material';

const UploadFileButtonContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'center',
}));

interface Props {
  titleVariant: Variant;
  required?: boolean;
  disabled?: boolean;
  onRemoveOldFile: (document: string) => void;
}

export const InvitationAttachments: FC<Props> = ({
  titleVariant,
  required,
  disabled,
  onRemoveOldFile,
}) => {
  const { values, touched, errors, submitCount, setFieldValue } =
    useFormikContext<any>();
  const { t } = useTranslation();
  const theme = useTheme();
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const attachments = _.get(values, 'attachments');
  const wasTouched = !!submitCount || !!_.get(touched, 'attachments');
  const errorText = _.get(errors, 'attachments') as string;

  const handleUploadFile = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);
    fileRef.current.value = '';
  };

  const handleFileUploaded = async (document: FileUploadMediaResponse) => {
    if (!selectedFile) {
      return;
    }

    setFieldValue(
      'attachments',
      attachments ? [...attachments, document] : [document]
    );
    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const attachmentsMemo = useMemo(() => {
    const handleFileRemove = (item: FileUploadMediaResponse) => {
      onRemoveOldFile(item.id!);
      setFieldValue(
        'attachments',
        attachments.filter((x: FileUploadMediaResponse) => x.id !== item.id)
      );
    };

    return attachments?.map((x: FileUploadMediaResponse) => (
      <QcsBox
        key={x.id}
        sx={{ display: 'flex', alignItems: 'center', gap: '1em' }}
      >
        <QcsLink
          href={`${PROTOCOL_SECURED_MEDIA}${x.id}/${x.fileName}`}
          variant="body1"
          sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}
        >
          {decodeURIComponent(x.fileName!.replace(/\+/g, ' '))}
        </QcsLink>
        <QcsButton onClick={() => handleFileRemove(x)} disabled={disabled}>
          <DeleteForeverIcon color={disabled ? undefined : 'error'} />
        </QcsButton>
      </QcsBox>
    ));
  }, [
    attachments,
    disabled,
    onRemoveOldFile,
    setFieldValue,
    theme.palette.text.primary,
  ]);

  return (
    <>
      <QcsTypography
        variant={titleVariant}
        sx={{
          color:
            wasTouched && !!errorText ? theme.palette.error.main : undefined,
        }}
      >
        {t('invitation.attachments')}
        {required && ' *'}
      </QcsTypography>
      <DocumentInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />
      {attachmentsMemo}
      <UploadFileButtonContainer>
        <QcsButton
          onClick={handleUploadFile}
          variant="contained"
          disabled={disabled}
        >
          {t('common.uploadFile')}
        </QcsButton>
        <QcsTypography>{t('common.fileMaxSize')}</QcsTypography>
        {wasTouched && !!errorText && (
          <QcsFormHelperText error={true}>{errorText}</QcsFormHelperText>
        )}
      </UploadFileButtonContainer>
    </>
  );
};
