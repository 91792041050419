import { FC } from 'react';
import { UserDtoRolesEnum } from '@qcs/safety-client';
import { SelectMultiple } from './SelectMultiple';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

export const UserRoleSelect: FC<Props> = ({
  className,
  name,
  label,
  disabled,
  required,
}) => {
  const { t } = useTranslation();

  const roles = Object.values(UserDtoRolesEnum).filter(
    (role) =>
      role !== UserDtoRolesEnum.AdminQcs &&
      role !== UserDtoRolesEnum.ExternalManager &&
      role !== UserDtoRolesEnum.ExternalWorker &&
      role !== UserDtoRolesEnum.ExternalOneTime &&
      role !== UserDtoRolesEnum.RemoteToken
  );

  return (
    <SelectMultiple
      className={className}
      name={name}
      label={label}
      disabled={disabled}
      required={required}
      options={roles}
      getOptionId={(role: string) => role}
      getOptionNameFromId={(role: string) => t(`user.role.${role}`)}
    />
  );
};
