import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { Form } from 'formik';
import { FormikDebounce } from '../../../common/form/FormikDebounce';
import * as Yup from 'yup';
import { validations } from '../../../../utils/validations';
import { useTranslation } from 'react-i18next';
import { FormContainer } from '../../../common/form/FormContainer';
import { DatePicker } from '../../../common/form/DatePicker';
import { SubmitButton } from '../../../common/form/SubmitButton';

interface InvitedPersonsDocumentDateModalForm {
  date?: string;
}

interface Props {
  open: boolean;
  onClose: (date?: string) => void;
}

export const InvitedPersonsDocumentDateModal: FC<Props> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (data: InvitedPersonsDocumentDateModalForm) => {
    onClose(data.date);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <QcsModal open={open} onClose={handleClose}>
      <QcsModalBox>
        <SectionHeader title="invitation.persons.documentTitle" />
        <FormikDebounce<InvitedPersonsDocumentDateModalForm>
          initialValues={{
            date: undefined,
          }}
          validationSchema={Yup.object().shape({
            date: validations.stringDateNotPastRequired(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <DatePicker
                name="date"
                label={t('invitation.persons.documentValidTo')}
                required={true}
                disablePast
              />
              <SubmitButton />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
