import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QcsTypography } from '../common/basic/QcsTypography';
import { EstablishmentAutocomplete } from '../common/form/EstablishmentAutocomplete';
import {
  DashboardContent,
  DashboardEstablishmentForm,
} from './DashboardContent';
import { DashboardContainer, DashboardHeader } from './DashboardStyles';

export const Dashboard: FC = () => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    //Nothing.
  };

  return (
    <FormikDebounce<DashboardEstablishmentForm>
      initialValues={{
        establishment: undefined,
      }}
      onSubmit={handleSubmit}
    >
      <Form>
        <DashboardContainer>
          <DashboardHeader>
            <QcsTypography variant="h4">{t('dashboard.title')}</QcsTypography>
            <EstablishmentAutocomplete
              sx={{ minWidth: '18rem' }}
              name="establishment"
              label={t('dashboard.establishment')}
              showEmptyOption
              emptyOptionLabel={t('dashboard.allEstablishments')}
              required
            />
          </DashboardHeader>
          <DashboardContent />
        </DashboardContainer>
      </Form>
    </FormikDebounce>
  );
};
