import { ActivityDto } from '@qcs/safety-client';
import { FC } from 'react';
import { Modal as QcsModal } from '@mui/material';

import { QcsModalBox } from '../../../common/basic/QcsModalBox';
import { SectionHeader } from '../../../common/SectionHeader';
import { setErrorStateSnacks } from '../../../../utils/error';
import * as Yup from 'yup';
import { invitationsApi } from '../../../../utils/api';
import { Form } from 'formik';
import { FormikDebounce } from '../../../common/form/FormikDebounce';
import { FormContainer } from '../../../common/form/FormContainer';
import { validations } from '../../../../utils/validations';
import { SubmitButton } from '../../../common/form/SubmitButton';
import { SaveError } from '../../../common/SaveError';
import { ActivityAutocomplete } from '../../../common/form/ActivityAutocomplete';
import { invitationGetUpdateDto, useInvitation } from './invitationFunctions';

interface ActivityAddModalForm {
  activities: ActivityDto[];
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ActivityAddModal: FC<Props> = ({ open, onClose }) => {
  const {
    t,
    invitation,
    lockInvitation,
    reloadInvitation,
    unlockInvitation,
    enqueueSuccessSnackbar,
    enqueueErrorSnackbar,
    isSaving,
    setIsSaving,
    saveError,
    setSaveError,
  } = useInvitation();

  const handleSubmit = async (data: ActivityAddModalForm) => {
    setSaveError('');
    setIsSaving(true);
    lockInvitation();
    try {
      const data2 = invitationGetUpdateDto(invitation);
      const data3 = {
        ...data2,
        activities: [
          ...data2.activities,
          ...data.activities?.map((x) => ({
            id: x.id!,
            name: x.name,
            nameEn: x.nameEn,
          })),
        ],
      };

      await invitationsApi.updateInvitation(invitation.id!, data3);
      await reloadInvitation();

      enqueueSuccessSnackbar(t('common.saveSuccess'));
      onClose();
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
    setIsSaving(false);
    unlockInvitation();
  };

  return (
    <QcsModal open={open} onClose={onClose}>
      <QcsModalBox>
        <SectionHeader title="activity.add" />
        <FormikDebounce<ActivityAddModalForm>
          initialValues={{
            activities: [],
          }}
          validationSchema={Yup.object().shape({
            activities: validations.arrayNotEmpty(t),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormContainer>
              <ActivityAutocomplete
                name="activities"
                label={t('invitation.activity.name')}
                required
                excludeIds={invitation.activities?.map(
                  (x) => x.activity?.id ?? ''
                )}
                multiple
              />
              <SaveError error={saveError} />
              <SubmitButton disabled={isSaving} />
            </FormContainer>
          </Form>
        </FormikDebounce>
      </QcsModalBox>
    </QcsModal>
  );
};
