import { SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  fontSize?: number | string;
  variant?: Variant;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  children: React.ReactNode;
}

export const QcsTypography: FC<Props> = ({
  className,
  sx,
  fontSize,
  variant,
  align,
  children,
}) => {
  return (
    <Typography
      className={className}
      sx={sx}
      fontSize={fontSize}
      variant={variant}
      align={align}
    >
      {children}
    </Typography>
  );
};
