import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { VisitationDetailDto } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { visitationApi } from '../../utils/api';

type State = BaseType<VisitationDetailDto>;

const initialState: State = {
  state: FetchState.None,
  data: {},
};

export const visitationSlice = baseSlice({
  name: 'visitation',
  initialState,
});

export const selectVisitationState = (state: RootState) =>
  state.visitation.state;
export const selectVisitation = (state: RootState) => state.visitation.data;
export const visitationActions = visitationSlice.actions;

export const getVisitation =
  (visitationId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return visitationApi.getVisitation(visitationId);
    };

    getItem({
      dispatch,
      actions: visitationActions,
      getData: handleGetData,
    });
  };
