import { useFormikContext } from 'formik';
import { FC, useRef, useState } from 'react';
import _ from 'lodash';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { useTranslation } from 'react-i18next';
import { DocumentInputUploadModal } from './DocumentInputUploadModal';
import { QcsTypography } from '../common/basic/QcsTypography';
import { QcsFormHelperText } from '../common/basic/QcsFormHelperText';
import { PROTOCOL_SECURED_MEDIA } from '../../utils/constants';
import styled from '@emotion/styled';
import { Link as  QcsLink } from '@mui/material';

import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FileUploadMediaResponse } from '@qcs/safety-client';
import { useTheme } from '@mui/material';

const UploadFileButtonContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'center',
}));

interface Props {
  required?: boolean;
  disabled?: boolean;
  onRemoveOldFile: (document: string) => void;
}

export const DocumentInput: FC<Props> = ({
  required,
  disabled,
  onRemoveOldFile,
}) => {
  const { values, touched, errors, submitCount, setFieldValue } =
    useFormikContext<any>();
  const { t } = useTranslation();
  const theme = useTheme();
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const document = _.get(values, 'document');
  const documentName = _.get(values, 'documentName');
  const wasTouched = !!submitCount || !!_.get(touched, 'document');
  const errorText = _.get(errors, 'document') as string;

  const handleUploadFile = () => {
    fileRef.current?.click();
  };

  const handleFileChange = (files: FileList | null) => {
    if (!fileRef.current || !files) {
      return;
    }

    const file = files[0];
    setSelectedFile(file);
    fileRef.current.value = '';
  };

  const handleFileUploaded = async (document: FileUploadMediaResponse) => {
    if (!selectedFile) {
      return;
    }

    setFieldValue('document', document.id);
    setFieldValue('documentName', document.fileName);
    setSelectedFile(null);
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
  };

  const handleFileRemove = () => {
    onRemoveOldFile(document);
    setFieldValue('document', '');
  };

  return (
    <>
      <QcsTypography
        variant="h6"
        sx={{
          color:
            wasTouched && !!errorText ? theme.palette.error.main : undefined,
        }}
      >
        {t('risk.document')}
        {required && ' *'}
      </QcsTypography>
      <DocumentInputUploadModal
        file={selectedFile}
        onFileUploaded={handleFileUploaded}
        onCloseOnError={handleFileCancel}
      />
      <input
        type="file"
        ref={fileRef}
        onChange={({ target }) => handleFileChange(target.files)}
        hidden
      />
      {!!document && !!documentName && (
        <QcsBox sx={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
          <QcsLink
            href={`${PROTOCOL_SECURED_MEDIA}${document}/${documentName}`}
            variant="body1"
            sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}
          >
            {decodeURIComponent(documentName.replace(/\+/g, ' '))}
          </QcsLink>
          <QcsButton onClick={handleFileRemove} disabled={disabled}>
            <DeleteForeverIcon color={disabled ? undefined : 'error'} />
          </QcsButton>
        </QcsBox>
      )}
      <UploadFileButtonContainer>
        <QcsButton
          onClick={handleUploadFile}
          variant="contained"
          disabled={disabled || !!document}
        >
          {t('common.uploadFile')}
        </QcsButton>
        <QcsTypography>{t('common.fileMaxSize')}</QcsTypography>
        {wasTouched && !!errorText && (
          <QcsFormHelperText error={true}>{errorText}</QcsFormHelperText>
        )}
      </UploadFileButtonContainer>
    </>
  );
};
