import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import { EmployeeDto, EmployeeDtoStateEnum } from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { employeeApi } from '../../utils/api';

type State = BaseType<EmployeeDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    establishments: [],
    jobPosition: '',
    office: '',
    note: '',
    state: EmployeeDtoStateEnum.Active,
  },
};

export const employeeSlice = baseSlice({
  name: 'employee',
  initialState,
});

export const selectEmployeeState = (state: RootState) => state.employee.state;
export const selectEmployee = (state: RootState) => state.employee.data;
export const employeeActions = employeeSlice.actions;

export const getEmployee = (employeeId: string) => (dispatch: AppDispatch) => {
  const handleGetData = () => {
    return employeeApi.getEmployee(employeeId);
  };

  getItem({
    dispatch,
    actions: employeeActions,
    getData: handleGetData,
  });
};
