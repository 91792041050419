import { AppDispatch, RootState } from '../index';
import { FetchState } from '../fetchState';
import {
  SafetyEquipmentDto,
  SafetyEquipmentDtoStateEnum,
  SafetyEquipmentDtoTypeEnum,
} from '@qcs/safety-client';
import { baseSlice, BaseType, getItem } from '../baseHelper';
import { safetyEquipmentApi } from '../../utils/api';

type State = BaseType<SafetyEquipmentDto>;

const initialState: State = {
  state: FetchState.None,
  data: {
    id: '',
    name: '',
    nameEn: '',
    type: SafetyEquipmentDtoTypeEnum.Owned,
    stateChangeEnabled: true,
    state: SafetyEquipmentDtoStateEnum.Active,
  },
};

export const safetyEquipmentSlice = baseSlice({
  name: 'safetyEquipment',
  initialState,
});

export const selectSafetyEquipmentState = (state: RootState) =>
  state.safetyEquipment.state;
export const selectSafetyEquipment = (state: RootState) =>
  state.safetyEquipment.data;
export const safetyEquipmentActions = safetyEquipmentSlice.actions;

export const getSafetyEquipment =
  (safetyEquipmentId: string) => (dispatch: AppDispatch) => {
    const handleGetData = () => {
      return safetyEquipmentApi.getSafetyEquipment(safetyEquipmentId);
    };

    getItem({
      dispatch,
      actions: safetyEquipmentActions,
      getData: handleGetData,
    });
  };
