import {
  InvitationReferenceDtoInvitationTypeEnum,
  UserDtoRolesEnum,
  VisitationDetailDto,
} from '@qcs/safety-client';
import { Form } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import {
  getVisitation,
  selectVisitation,
  selectVisitationState,
} from '../../store/entities/visitation';
import { FetchState } from '../../store/fetchState';
import { Input } from '../common/form/Input';
import { ErrorAlert } from '../common/ErrorAlert';
import { DetailHeader } from '../common/DetailHeader';
import { FormContainer } from '../common/form/FormContainer';
import { Loader } from '../common/Loader';
import { QcsBox } from '@s4e/design-system/atoms/layout/QcsBox';
import { FaIcon } from '../common/FaIcon';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import styled from '@emotion/styled';
import { DateTimePicker } from '../common/form/DateTimePicker';
import { QcsTooltip } from '../common/basic/QcsTooltip';
import { QcsTypography } from '../common/basic/QcsTypography';
import {
  getLangNameInputNameObj,
  getLangNameObj,
  joinStrings2,
} from '../../utils/format';
import { hasRole } from '../../utils/roles';
import { selectIdentity } from '../../store/entities/identity';
import { useBack } from '../../hooks/useBack';

const InnerTooltipWrapper = styled.div(() => ({
  display: 'flex',
}));

const RowWithExternalLink = styled(QcsBox)(() => ({
  display: 'flex',
  gap: '1em',
  '& > .MuiTextField-root': {
    flex: '1 0 auto',
  },
}));

const MyInput: FC<{ name: string; label: string }> = ({ name, label }) => {
  const { t } = useTranslation();
  return <Input name={name} label={t(label)} disabled={true} />;
};

const MyInputExternal: FC<{
  name: string;
  label: string;
  tooltip: string;
  link: string;
  linkRoles: UserDtoRolesEnum[];
}> = ({ name, label, tooltip, link, linkRoles }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { visitationId } = useParams();
  const identity = useAppSelector(selectIdentity);

  const linkDisabled = !hasRole(identity.roles, linkRoles);
  const linkIsInvalid = link.split('/').find((x) => x === 'undefined');

  const handleClick = () => {
    navigate(link, {
      state: { back: `/visitation/${visitationId}` },
    });
  };

  if (linkIsInvalid || linkDisabled) {
    return <MyInput name={name} label={label} />;
  }

  return (
    <RowWithExternalLink>
      <MyInput name={name} label={label} />
      <QcsTooltip title={t(tooltip)} placement="top">
        <InnerTooltipWrapper>
          <QcsButton onClick={handleClick}>
            <FaIcon icon={faUpRightFromSquare} />
          </QcsButton>
        </InnerTooltipWrapper>
      </QcsTooltip>
    </RowWithExternalLink>
  );
};

interface VisitationDetailDtoForm extends VisitationDetailDto {
  workplacesName?: string;
}

export const VisitationDetail: FC = () => {
  const { t, i18n } = useTranslation();
  const { backTo } = useBack('/visitation');
  const { visitationId } = useParams();
  const visitation = useAppSelector(selectVisitation);
  const visitationState = useAppSelector(selectVisitationState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const isOneTime =
    visitation.visitation?.invitation?.invitationType ===
      InvitationReferenceDtoInvitationTypeEnum.OneTime ||
    visitation.visitation?.invitation?.invitationType ===
      InvitationReferenceDtoInvitationTypeEnum.OneTimeEntry;
  const linkEmployee = isOneTime ? 'visit' : 'supplier';
  const linkInvitation = isOneTime ? 'person' : '';

  useEffect(() => {
    dispatch(getVisitation(visitationId!));
    setLoading(false);
  }, [visitationId, dispatch]);

  const handleSubmit = () => {
    //Nothing.
  };

  if (loading || visitationState === FetchState.Loading) {
    return <Loader />;
  }

  if (visitationState === FetchState.Error) {
    return <ErrorAlert />;
  }

  return (
    <>
      <DetailHeader
        title={getLangNameObj(i18n, visitation.visitation?.invitation)}
        backTo={backTo}
      />
      <FormikDebounce<VisitationDetailDtoForm>
        initialValues={{
          ...visitation,
          workplacesName: joinStrings2(
            visitation.visitation?.workplaces?.map((x) =>
              getLangNameObj(i18n, x)
            )
          ),
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              <MyInputExternal
                name="visitation.invitationPerson.name"
                label="visitation.invitationPerson"
                tooltip="visitation.tooltips.linkToSupplierEmployee"
                link={`/${linkEmployee}/${visitation.visitation?.supplier?.id}/employee/${visitation.visitation?.invitationPerson?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                  UserDtoRolesEnum.ManagerOsah,
                  UserDtoRolesEnum.ManagerWorkplace,
                  UserDtoRolesEnum.Receptionist,
                ]}
              />
              <MyInputExternal
                name="visitation.supplier.name"
                label="visitation.supplier"
                tooltip="visitation.tooltips.linkToSupplier"
                link={
                  `/${linkEmployee}/${visitation.visitation?.supplier?.id}` +
                  (isOneTime
                    ? `/employee/${visitation.visitation?.invitationPerson?.id}`
                    : '')
                }
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                  UserDtoRolesEnum.ManagerOsah,
                  UserDtoRolesEnum.ManagerWorkplace,
                  UserDtoRolesEnum.Receptionist,
                ]}
              />
              <MyInputExternal
                name={`visitation.establishment.${getLangNameInputNameObj(
                  i18n,
                  values.visitation?.establishment
                )}`}
                label="visitation.establishment"
                tooltip="visitation.tooltips.linkToEstablishment"
                link={`/establishment/${visitation.visitation?.establishment?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                  UserDtoRolesEnum.ManagerOsah,
                  UserDtoRolesEnum.ManagerWorkplace,
                  UserDtoRolesEnum.Receptionist,
                ]}
              />
              <MyInput name="workplacesName" label="visitation.workplace" />
              <MyInputExternal
                name="visitation.invitedBy.name"
                label="visitation.invitedBy"
                tooltip="visitation.tooltips.linkToEmployee"
                link={`/employee/${visitation.visitation?.invitedBy?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                ]}
              />
              <MyInputExternal
                name="entryUser.name"
                label="visitation.registeredPersonEntrance"
                tooltip="visitation.tooltips.linkToEmployee"
                link={`/employee/${visitation.entryUser?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                ]}
              />
              <MyInput
                name="visitation.note"
                label="common.notes"
              />
              
              <DateTimePicker
                name="visitation.entryTime"
                label={t('visitation.entryTime')}
                disabled={true}
              />
              <MyInputExternal
                name="exitUser.name"
                label="visitation.registeredPersonExit"
                tooltip="visitation.tooltips.linkToEmployee"
                link={`/employee/${visitation.exitUser?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                ]}
              />
              <DateTimePicker
                name="visitation.exitTime"
                label={t('visitation.exitTime')}
                disabled={true}
              />
              {/* INVITATION DETAIL */}
              <QcsTypography variant="h4">
                {t('visitation.invitationDetail.title')}
              </QcsTypography>
              <MyInputExternal
                name="visitation.invitation.name"
                label="visitation.invitation"
                tooltip="visitation.tooltips.linkToInvitation"
                link={`/invitation/${visitation.visitation?.invitation?.id}/${linkInvitation}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                  UserDtoRolesEnum.ManagerOsah,
                  UserDtoRolesEnum.ManagerWorkplace,
                  UserDtoRolesEnum.Receptionist,
                  UserDtoRolesEnum.ExternalManager,
                  UserDtoRolesEnum.ExternalWorker,
                  UserDtoRolesEnum.ExternalOneTime,
                ]}
              />
              <MyInputExternal
                name="visitation.supplierEmployee.name"
                label="visitation.invitationDetail.responsibleSupplierPerson"
                tooltip="visitation.tooltips.linkToSupplierEmployee"
                link={`/${linkEmployee}/${visitation.visitation?.supplier?.id}/employee/${visitation.visitation?.supplierEmployee?.id}`}
                linkRoles={[
                  UserDtoRolesEnum.AdminQcs,
                  UserDtoRolesEnum.AdminCompany,
                  UserDtoRolesEnum.ManagerOsah,
                  UserDtoRolesEnum.ManagerWorkplace,
                  UserDtoRolesEnum.Receptionist,
                ]}
              />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>
    </>
  );
};
