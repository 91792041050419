import { FC } from 'react';
import styled from '@emotion/styled';
import QcsAlert from '@s4e/design-system/molecules/blocks/QcsAlert';

import { useTranslation } from 'react-i18next';

const GridEmptyContainer = styled.div(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

interface Props {
  className?: string;
}

export const GridEmpty: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <GridEmptyContainer className={className}>
      <QcsAlert severity="info">{t('common.tableEmpty')}</QcsAlert>
    </GridEmptyContainer>
  );
};
