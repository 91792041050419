import { TableBody } from '@mui/material';
import { FC } from 'react';

interface Props {
  className?: string;
  component?: any;
  children: React.ReactNode;
}

export const QcsTableBody: FC<Props> = ({ className, component, children }) => {
  return (
    <TableBody className={className} component={component}>
      {children}
    </TableBody>
  );
};
