import { TFunction } from 'i18next';
import * as Yup from 'yup';
import libphonenumber from 'google-libphonenumber';
import { getDateForApi } from './date';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

const objectRequired = (t: TFunction) =>
  Yup.object().nullable().required(t('errors.required')!);

const stringRequired = (t: TFunction) =>
  Yup.string().trim().required(t('errors.required')!);

const stringMin = (min: number, t: TFunction) =>
  Yup.string()
    .required()
    .min(min, t('errors.requiredMin').replaceAll('{0}', min.toString()));

const idRequired = (t: TFunction) =>
  Yup.number()
    .required(t('errors.required')!)
    .moreThan(0, t('errors.required')!);

const intOptional = (t: TFunction) => Yup.number().integer(t('errors.int')!);

const intRequired = (t: TFunction) =>
  intOptional(t).required(t('errors.required')!);

const intPositiveOrZero = (t: TFunction) =>
  intRequired(t).moreThan(-1, t('errors.required')!);

const intPositive = (t: TFunction) =>
  intRequired(t).moreThan(0, t('errors.intPositive')!);

const intPositiveOptional = (t: TFunction) =>
  intOptional(t).moreThan(0, t('errors.intPositive')!).nullable();

const intOptionalMinMax = (min: number, max: number, t: TFunction) =>
  intOptional(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

const intRequiredMinMax = (min: number, max: number, t: TFunction) =>
  intRequired(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

const numberOptional = () => Yup.number();

const numberRequired = (t: TFunction) =>
  numberOptional().required(t('errors.required')!);

const numberPositive = (t: TFunction) =>
  numberRequired(t).moreThan(0, t('errors.required')!);

const numberOptionalMinMax = (min: number, max: number, t: TFunction) =>
  numberOptional()
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

const numberRequiredMinMax = (min: number, max: number, t: TFunction) =>
  numberRequired(t)
    .min(min, valueFromTo(min, max, t))
    .max(max, valueFromTo(min, max, t));

const dateRequired = (t: TFunction) =>
  Yup.date().required(t('errors.required')!).nullable();

const stringDateNotPast = (t: TFunction) => {
  const today = getDateForApi(new Date());
  return Yup.string().test(
    'dateNotPast',
    t('errors.dateNotPast')!,
    (date) => !date || date >= today!
  );
};

const stringDateNotPastRequired = (t: TFunction) =>
  stringDateNotPast(t).required(t('errors.required')!);

const stringDateNotPastIfChanged = (t: TFunction, initialValue: string) => {
  const today = getDateForApi(new Date());
  return Yup.string().test(
    'dateNotPast',
    t('errors.dateNotPast')!,
    (date) => !date || date >= today! || date === initialValue
  );
};

const stringDateNotPastIfChangedRequired = (
  t: TFunction,
  initialValue: string
) =>
  stringDateNotPastIfChanged(t, initialValue).required(t('errors.required')!);

const stringDateNotPastOrToday = (t: TFunction) => {
  const today = getDateForApi(new Date());
  return Yup.string().test(
    'dateNotPastOrToday',
    t('errors.dateNotPastOrToday')!,
    (date) => !date || date > today!
  );
};

const stringDateNotPastOrTodayRequired = (t: TFunction) =>
  stringDateNotPastOrToday(t).required(t('errors.required')!);

const stringDateNotPastOrTodayIfChanged = (
  t: TFunction,
  initialValue: string
) => {
  const today = getDateForApi(new Date());
  return Yup.string().test(
    'dateNotPastOrToday',
    t('errors.dateNotPastOrToday')!,
    (date) => !date || date > today! || date === initialValue
  );
};

const stringDateNotPastOrTodayIfChangedRequired = (
  t: TFunction,
  initialValue: string
) =>
  stringDateNotPastOrTodayIfChanged(t, initialValue).required(
    t('errors.required')!
  );

const arrayNotEmpty = (t: TFunction) =>
  Yup.array().min(1, t('errors.arrayNotEmpty')!);

const phoneOptional = (t: TFunction) =>
  Yup.string()
    .nullable()
    .test('phone', t('errors.phone')!, (value) => {
      if (!value) {
        return true;
      }

      try {
        const number = phoneUtil.parse(value.trim());
        if (!phoneUtil.isValidNumber(number)) {
          return false;
        }
      } catch (err) {
        return false;
      }
      return true;
    });

const phoneRequired = (t: TFunction) =>
  phoneOptional(t).required(t('errors.required')!);

const emailOptional = (t: TFunction) =>
  Yup.string().nullable().email(t('errors.email')!);

const emailRequired = (t: TFunction) =>
  emailOptional(t).required(t('errors.required')!);

const urlOptional = (t: TFunction) => Yup.string().url(t('errors.url')!);

const urlRequired = (t: TFunction) =>
  urlOptional(t).required(t('errors.required')!);

const postalCodeOptional = (t: TFunction) =>
  Yup.string().max(14, t('errors.postalCode')!);

const postalCodeRequired = (t: TFunction) =>
  postalCodeOptional(t).required(t('errors.required')!);

const identificationNumberOptional = (t: TFunction) =>
  Yup.string()
    .test(
      'identificationNumber',
      t('errors.identificationNumber')!,
      (value) => {
        if (!value) {
          return true;
        }
        if (!/^[0-9]{6}\/[0-9]{3,4}$/.test(value)) {
          return false;
        }
        return validateIdentificationNumber(value.slice(0, 6) + value.slice(7));
      }
    )
    .nullable();

const companyNoOptional = (t: TFunction) =>
  Yup.string().max(14, t('errors.companyNo')!);

const companyNoRequired = (t: TFunction) =>
  companyNoOptional(t).required(t('errors.required')!);

const companyVatNoOptional = (t: TFunction) =>
  Yup.string().max(14, t('errors.companyVatNo')!);

const companyVatNoRequired = (t: TFunction) =>
  companyVatNoOptional(t).required(t('errors.required')!);

const validateIdentificationNumber = (value: string): boolean => {
  try {
    const number = value.trim();

    if (number.length !== 9 && number.length !== 10) return false;

    const day = parseInt(number.slice(4, 6));
    const ending = number.slice(6);
    if (day < 1 || day > 31) return false;
    if (ending === '000') return false;

    if (number.length === 9 && parseInt(number.slice(0, 2)) >= 54) {
      return false;
    }

    if (number.length === 10) {
      const numberBase = parseInt(number.slice(0, -1));
      const lastDigit = parseInt(number[number.length - 1]);
      const residue = numberBase % 11;

      if (residue === 10) {
        return lastDigit === 0;
      }
      return numberBase % 11 === lastDigit;
    }
    return true;
  } catch (err) {}
  return false;
};

const passwordStrength = (
  t: TFunction,
  minLength: number,
  minDigitsCount: number,
  minCapitalLetters: number,
  minLowerCaseLetters: number
) =>
  stringRequired(t).test(
    'passwordStrength',
    t('errors.passNotStrong')!,
    (value) => {
      if (!value) {
        return false;
      }

      try {
        if (value.length < minLength) {
          return false;
        }

        if (value.replace(/\D/g, '').length < minDigitsCount) {
          return false;
        }

        if (value.replace(/[^A-Z]/g, '').length < minCapitalLetters) {
          return false;
        }

        if (value.replace(/[^a-z]/g, '').length < minLowerCaseLetters) {
          return false;
        }

        return true;
      } catch (err) {
        return false;
      }
    }
  );

const valueFromTo = (min: number, max: number, t: TFunction) =>
  t('errors.valueFromTo')
    .replaceAll('{0}', min.toString())
    .replaceAll('{1}', max.toString());

export const validations = {
  objectRequired,
  stringRequired,
  stringMin,
  idRequired,
  intOptional,
  intRequired,
  intPositiveOrZero,
  intPositive,
  intPositiveOptional,
  intOptionalMinMax,
  intRequiredMinMax,
  numberOptional,
  numberRequired,
  numberPositive,
  numberOptionalMinMax,
  numberRequiredMinMax,
  dateRequired,
  stringDateNotPast,
  stringDateNotPastRequired,
  stringDateNotPastIfChanged,
  stringDateNotPastIfChangedRequired,
  stringDateNotPastOrToday,
  stringDateNotPastOrTodayRequired,
  stringDateNotPastOrTodayIfChanged,
  stringDateNotPastOrTodayIfChangedRequired,
  arrayNotEmpty,
  phoneOptional,
  phoneRequired,
  emailOptional,
  emailRequired,
  urlOptional,
  urlRequired,
  postalCodeOptional,
  postalCodeRequired,
  identificationNumberOptional,
  companyNoOptional,
  companyNoRequired,
  companyVatNoOptional,
  companyVatNoRequired,
  passwordStrength,
};
